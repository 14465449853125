import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-btn-fa',
  templateUrl: './btn-fa.component.html',
  styleUrls: ['./btn-fa.component.scss'],
})
export class BtnFaComponent implements OnInit {
  @Input()
  iconName: string;

  @Input()
  size: 'sm' | 'md' | 'lg';

  @Input()
  color: 'default' | 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info';

  constructor() {
    this.size = 'sm';
    this.color = 'default';
  }

  ngOnInit(): void {}
}
