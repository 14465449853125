<app-panel-subsection
  class="template-edit__subsection"
  title="Data Fields"
  [titleTooltip]="'Template Checklists allow users to fill pre-designed forms for each assignment.'"
  [backgroundTransparent]="true"
  [headerRightTemplate]="formsOnOffLayout"
>
  <ng-container *ngIf="selectedTemplate?.allowForms">
    <div class="assignment-checklists">
      <div class="assignment-forms__lists">
        <app-template-checklist
          *ngFor="let list of groups; trackBy: trackBy"
          [templateId]="selectedTemplate?.id"
          [groups]="groups"
          [group]="list"
        ></app-template-checklist>
      </div>
    </div>
    <div class="d-flex justify-content-center" #origin>
      <button
        class="btn btn-sm btn-outline-light"
        app-icon-button
        [icon]="IconType['plus']"
        (click)="showChecklistCreatePage(origin)"
      >
        Add data fields
      </button>
    </div>
  </ng-container>
</app-panel-subsection>

<ng-template #formsOnOffLayout>
  <div
    role="button"
    class="layout-switch-btn"
    (click)="changeSwitch()"
    [ngClass]="{
      'layout-switch-btn-on': selectedTemplate?.allowForms === true,
      'layout-switch-btn-off': !selectedTemplate?.allowForms
    }"
  >
    <span>{{ selectedTemplate?.allowForms ? 'ON' : 'OFF' }}</span>
  </div>
</ng-template>
