import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/_shared.module';
import { StoreModule } from '@ngrx/store';
import { metaReducers, reducers } from './store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'src/environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { WorkspacesEffects } from './store/workspaces/workspaces.effects';
import { ErrorsEffects } from './store/errors/errors.effects';
import { LoaderInterceptor } from './core/interceptors/loader.interceptor';
import { NodeListPanelEffects } from './store/node-list-panel/node-list-panel.effects';
import { WorkspaceUserMetaEffects } from './store/workspace-user-meta/workspace-user-meta.effects';
import { ArchivePopoverEffects } from './store/archive-popover/archive-popover.effects';
import { AuthEffects } from './store/auth/auth.effects';
import { MomentDatePipe } from './shared/pipes/moment-date.pipe';
import { ConcatNounsPipe } from './shared/pipes/concat-nouns.pipe';
import { TagsEffects } from './store/tags/tags.effects';
import { NodeTagsEffects } from './store/node-tags/node-tags.effects';
import { WidgetTypesEffects } from './store/widget-types/widget-types.effects';
import { TemplatesEffects } from './store/templates/templates.effects';
import { WorkspaceUsersEffects } from './store/workspace-users/workspace-users.effects';
import { WidgetsEffects } from './store/widgets/widgets.effects';
import { CalendarEffects } from './store/calendar/calendar.effects';
import { TemplateWidgetsEffects } from './store/template-widgets/template-widgets.effects';
import { ArchivesEffects } from './store/archives/archives.effects';
import { TemplateTagsEffects } from './store/template-tags/template-tags.effects';
import { WorkspaceTemplatesEffects } from './store/workspace-templates/workspace-templates.effects';
import { WorkspaceGroupsEffects } from './store/workspace-groups/workspace-groups.effects';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { WidgetTypeLabelPipe } from './shared/pipes/widget-type-label.pipe';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomDateParserFormatterService } from './core/services/custom-date-parser-formatter.service';
import { GanttsEffects } from './store/gantts/gantts.effects';
import { TagsPopoverEffects } from './store/tags-popover/tags-popover.effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { RouterEffects } from './store/router/router.effects';
import { CustomSerializer } from './store/router/custom-route-serializer';
import { NgIdleModule } from '@ng-idle/core';
import { WorkspaceSharesEffects } from './store/workspace-shares/workspace-shares.effects';
import { SharedViewEffects } from './store/shared-view/shared-view.effects';
import { WorkspaceUrlsEffects } from './store/workspace-urls/workspace-urls.effects';
import { TimesheetsEffects } from './store/timesheets/timesheets.effects';
import { ContentLoaderEffects } from './store/content-loader/content-loader.effects';
import { FormattedFigurePipe } from './shared/pipes/formatted-figure.pipe';
import { SelectedOptionPipe } from './shared/pipes/selected-option.pipe';
import { NodeRatesEffects } from './store/node-rates/node-rates.effects';
import { ChatsEffects } from './store/chats/chats.effects';
import { WorkspaceUserGroupsEffects } from './store/workspace-user-groups/workspace-user-groups.effects';
import { RolePermissionsEffects } from './store/role-permissions/role-permissions.effects';
import { AppLayoutEffects } from './store/app-layout/app-layout.effects';
import { AppConfig } from './core/services/app-config.service';
import { TokenInterceptor } from './core/interceptors/token.interceptor';
import { LOADER_ROUTES } from './shared/components/content-loader/loader.service';
import { loaderRoutes } from './shared/components/content-loader/loader.routes';
import { NodesEffects } from './store/nodes/nodes.effects';
import { NodeWidgetsEffects } from './store/node-widgets/node-widgets.effects';
import { NodeRateValuesEffects } from './store/node-rate-values/node-rate-values.effects';
import { NodeWidgetRowsEffects } from './store/node-widget-rows/node-widget-rows.effects';
import { LayoutTemplatesEffects } from './store/layout-templates/layout-templates.effects';
import { NgxPrintModule } from 'ngx-print';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { WorkspaceCommonModule } from './features/workspace-common/workspace-common.module';

export function initializeApp(appConfig: AppConfig) {
  return () => appConfig.load();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    NgbModule,
    SharedModule,
    NgxPrintModule,
    NgbDropdownModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    // Instrumentation must be imported after importing StoreModule (config is optional)
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),

    NgIdleModule.forRoot(),
    EffectsModule.forRoot([
      AppLayoutEffects,
      NodesEffects,
      NodeWidgetsEffects,
      NodeWidgetRowsEffects,
      WorkspacesEffects,
      ErrorsEffects,
      NodeListPanelEffects,
      WorkspaceUserMetaEffects,
      ArchivePopoverEffects,
      AuthEffects,
      TagsEffects,
      NodeTagsEffects,
      TemplateTagsEffects,
      WidgetTypesEffects,
      TemplatesEffects,
      LayoutTemplatesEffects,
      WorkspaceUsersEffects,
      WidgetsEffects,
      CalendarEffects,
      TemplateWidgetsEffects,
      ArchivesEffects,
      WorkspaceTemplatesEffects,
      WorkspaceGroupsEffects,
      GanttsEffects,
      TagsPopoverEffects,
      RouterEffects,
      WorkspaceSharesEffects,
      SharedViewEffects,
      WorkspaceUrlsEffects,
      TimesheetsEffects,
      ContentLoaderEffects,
      NodeRatesEffects,
      NodeRateValuesEffects,
      ChatsEffects,
      WorkspaceUserGroupsEffects,
      RolePermissionsEffects,
    ]),
    StoreRouterConnectingModule.forRoot({
      serializer: CustomSerializer,
    }),
    WorkspaceCommonModule,
  ],
  providers: [
    // AppConfig,
    // { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AppConfig], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: LOADER_ROUTES, useValue: loaderRoutes },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatterService },

    MomentDatePipe,
    ConcatNounsPipe,
    CurrencyPipe,
    WidgetTypeLabelPipe,
    DecimalPipe,
    FormattedFigurePipe,
    SelectedOptionPipe,
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
