<div class="main-container">
  <app-panel-subsection
    [title]="type === nodeTypes.asset ? 'Assigned Resources' : 'Assigned Forms'"
    [titleTooltip]="
      type === nodeTypes.asset
        ? 'Only allowed Asset Templates will be shown on Assignment Card.'
        : 'Only allowed Element Templates will be shown on Assignment Card.'
    "
    [backgroundTransparent]="true"
    [headerRightTemplate]="allowedTemplatesOnOffLayout"
  >
    <div class="properties-panel__brick-items">
      <ng-container *ngIf="type === nodeTypes.asset; else forms">
        <span
          *ngFor="let template of selectedAssetTemplates"
          app-selectable-brick
          [label]="template.title"
          [color]="template.colorTheme"
          [icon]="template.icon"
          sizeType="md"
          class="properties-panel__brick"
          (click)="openTemplatesPopover()"
        ></span>
      </ng-container>
      <ng-template #forms>
        <span
          *ngFor="let template of selectedFormTemplates"
          app-selectable-brick
          [label]="template.title"
          [color]="template.colorTheme"
          [icon]="template.icon"
          sizeType="md"
          class="properties-panel__brick"
          (click)="openTemplatesPopover()"
        ></span>
      </ng-template>

      <span (click)="openTemplatesPopover()">
        <app-square-button-add></app-square-button-add>
      </span>
    </div>
  </app-panel-subsection>

  <ng-template #allowedTemplatesOnOffLayout>
    <div
      role="button"
      class="layout-switch-btn"
      (click)="changeSwitch()"
      [ngClass]="{
        'layout-switch-btn-on': true === true,
        'layout-switch-btn-off': !true
      }"
    >
      <span>{{ true ? 'ON' : 'OFF' }}</span>
    </div>
  </ng-template>
</div>
