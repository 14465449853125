import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../store';
import {
  selectNodeListPanelSelectedNode,
  selectNodeListPanelSelectedNodes,
} from '../../../../store/node-list-panel/node-list-panel.selectors';
import { animate, style, transition, trigger } from '@angular/animations';
import { defaultAnimationTiming } from '../../../../core/constants/animation';
import { selectLoggedInWorkspaceUser } from '../../../../store/workspace-users/workspace-users.selectors';
import { PermissionType } from '../../../../core/constants/permission-type';
import { NodeType } from '../../../../core/constants/node-type';
import { WorkspaceUserModel } from '../../../../core/models/workspace-user.model';
import { NodeModel } from '../../../../core/models/node.model';
import { NodesActions } from '../../../../store/nodes';
import { NodeUtils } from '../../../../core/utils/node.util';
import { NodeListPanelActions } from '../../../../store/node-list-panel';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmPopupArchiveNodeComponent } from '../../../../shared/modals/confirm-popup-archive-node/confirm-popup-archive-node.component';
import { projectsRootFolderName } from '../../../../core/constants/root-folder-names';
import { ArchivePopoverActions } from '../../../../store/archive-popover';
import { TagsPickerComponent } from '../../../../shared/modals/tags-picker/tags-picker.component';
import { TagModel } from '../../../../core/models/tag.model';
import { NodeTagsActions } from '../../../../store/node-tags';
import { NodeTemplatePickerComponent } from '../../../../shared/modals/node-template-picker/node-template-picker.component';
import { NodeTemplateModel } from '../../../../core/models/node-template.model';
import { FolderPickerComponent } from '../../../../shared/modals/folder-picker/folder-picker.component';
import { DatePickerComponent } from '../../../../shared/modals/date-picker/date-picker.component';
import { ngbDateUtil } from '../../../../core/utils/ngb-date.util';

@Component({
  selector: 'app-multi-select-controls',
  templateUrl: 'multi-select-controls.component.html',
  styleUrls: ['multi-select-controls.component.scss'],
  animations: [
    trigger('multiSelectControls', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(100%)' }),
        animate(defaultAnimationTiming, style({ opacity: 1, transform: 'translateY(0)' })),
        //style({ position: 'relative' }),
      ]),
      transition(':leave', [
        style({ position: 'absolute', opacity: 1, transform: 'translateY(0)' }),
        animate(defaultAnimationTiming, style({ opacity: 0, transform: 'translateY(100%)' })),
      ]),
    ]),
  ],
})
export class MultiSelectControlsComponent implements OnDestroy {
  private readonly subscription: Subscription = new Subscription();

  public readonly PermissionType = PermissionType;
  public readonly NodeType = NodeType;

  public loggedInUser: WorkspaceUserModel;
  public folderNode: NodeModel;
  public selectedNodes: NodeModel[];

  public show: boolean;

  constructor(private store: Store<AppState>, private modalService: NgbModal) {
    this.selectedNodes = [];

    this.subscription.add(
      this.store.pipe(select(selectLoggedInWorkspaceUser)).subscribe(loggedInUser => {
        this.loggedInUser = loggedInUser;
      }),
    );

    this.subscription.add(
      this.store.pipe(select(selectNodeListPanelSelectedNode)).subscribe(node => {
        this.folderNode = node;
      }),
    );

    this.subscription.add(
      this.store.pipe(select(selectNodeListPanelSelectedNodes)).subscribe(nodes => {
        this.selectedNodes = nodes;
        this.show = this.selectedNodes.length > 0;
      }),
    );
  }

  public onAssign() {
    if (this.folderNode.nodeType !== NodeType.projectFolder) {
      return; // Only Assign Projects
    }
    const modelRef = this.modalService.open(DatePickerComponent, {
      size: 'md',
      backdrop: 'static',
    });

    modelRef.componentInstance.minSelection = 1;
    modelRef.componentInstance.maxSelection = 0;

    modelRef.result
      .then((result: NgbDateStruct[]) => {
        this.store.dispatch(
          NodesActions.addMultipleAssignmentsRequest({
            nodeIds: this.selectedNodes.map(n => n.id),
            dateKeys: result.map(dt => ngbDateUtil.covertToDatePickerDateStr(dt)),
          }),
        );
        this.onCancel();
      })
      .catch(res => {});
  }

  public onTags() {
    const modelRef = this.modalService.open(TagsPickerComponent, {
      size: 'xl',
      backdrop: 'static',
    });
    modelRef.componentInstance.instructions =
      'The selected tags will <strong class="text-danger">replace existing tags</strong> for the selected items.';

    modelRef.componentInstance.selected = this.selectedNodes.reduce((list, node) => {
      return [...list, ...node.__tags];
    }, []);

    modelRef.result
      .then((result: TagModel[]) => {
        this.store.dispatch(
          NodeTagsActions.addTagsToMultipleNodesRequest({
            nodeIds: this.selectedNodes.map(n => n.id),
            tagIds: (result || []).map(t => t.id),
            replace: true,
          }),
        );
        this.onCancel();
      })
      .catch(res => {});
  }

  public onTemplates() {
    const modelRef = this.modalService.open(NodeTemplatePickerComponent, {
      size: 'md',
      backdrop: 'static',
    });
    modelRef.componentInstance.types = [NodeUtils.getChildNodeType(this.folderNode.nodeType)];
    modelRef.componentInstance.selected = [];
    modelRef.componentInstance.minSelection = 1;
    modelRef.componentInstance.maxSelection = 1;
    modelRef.result
      .then((result: NodeTemplateModel[]) => {
        if (result.length === 0) {
          return;
        }
        this.store.dispatch(
          NodesActions.applyTemplateToNodesRequest({
            nodeTemplateId: result[0]?.id,
            nodeIds: this.selectedNodes.map(n => n.id),
          }),
        );
        this.onCancel();
      })
      .catch(res => {});
  }

  moveNodes() {
    const modelRef = this.modalService.open(FolderPickerComponent, {
      size: 'xs',
      backdrop: 'static',
    });

    modelRef.componentInstance.types = [this.folderNode.nodeType];
    modelRef.componentInstance.disabled = [];
    modelRef.componentInstance.selected = [];
    modelRef.componentInstance.minSelection = 1;
    modelRef.componentInstance.maxSelection = 1;
    modelRef.result
      .then((result: NodeModel[]) => {
        this.store.dispatch(
          NodesActions.moveNodesRequest({
            nodeIds: this.selectedNodes.map(n => n.id),
            toParentId: result[0]?.id,
            sortIndex: 0,
          }),
        );
      })
      .catch(res => {});
  }

  public onArchive() {
    this.store.dispatch(
      ArchivePopoverActions.checkReferenceCountRequest({
        nodeIds: this.selectedNodes.map(n => n.id),
      }),
    );

    const typeName =
      this.folderNode.nodeType === NodeType.projectFolder ? projectsRootFolderName : 'Elements';

    const modelRef = this.modalService.open(ConfirmPopupArchiveNodeComponent, {
      size: 'md',
      backdrop: 'static',
    });
    modelRef.componentInstance.title = `Archive ${typeName}`;
    modelRef.componentInstance.message = `Do you want to archive the selected ${typeName}?`;
    modelRef.result.then((result: boolean) => {
      if (result) {
        this.store.dispatch(
          NodesActions.archiveNodesRequest({
            nodeIds: this.selectedNodes.map(n => n.id),
          }),
        );
        this.onCancel();
      }
    });
  }

  public onCancel() {
    this.store.dispatch(NodeListPanelActions.cancelSelection());
  }

  public addLeaf() {
    this.store.dispatch(
      NodesActions.addNodeRequest({
        parentNode: this.folderNode,
        nodeType: NodeUtils.getChildNodeType(this.folderNode.nodeType),
      }),
    );
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
