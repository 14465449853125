import { createSelector } from '@ngrx/store';
import { selectWorkspaceLinkGroups } from './workspace-groups.selectors';
import { selectWorkspaceUrlsByGroupId } from '../workspace-urls/workspace-urls.selectors';
import { WorkspaceGroupModel } from '../../core/models/workspace-group.model';

export const selectWorkspaceLinkViewGroups = createSelector(
  selectWorkspaceLinkGroups,
  selectWorkspaceUrlsByGroupId,
  (linkGroups, urlsByGroupId): WorkspaceGroupModel[] => {
    return (linkGroups || [])
      .filter(group => group != null)
      .map(group => {
        return {
          ...group,
          links: urlsByGroupId[group.id] || [],
        };
      });
  },
);
