import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NodeTemplateModel } from '../../../core/models/node-template.model';
import { Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { defaultPlacements } from '../../../core/constants/placements';
import { WorkspaceUserMetaActions } from '../../../store/workspace-user-meta';

@Component({
  selector: 'app-layout-metric-item',
  templateUrl: './layout-metric-item.component.html',
  styleUrls: ['./layout-metric-item.component.scss'],
})
export class LayoutMetricItemComponent implements OnInit, OnDestroy {
  @Input() template: NodeTemplateModel;
  @Input() count: number;

  filterLayoutIds: number[];
  defaultPlacements = defaultPlacements;

  subscription = new Subscription();

  constructor(private store: Store) {
    // this.subscription.add(
    //   this.store.pipe(select(selectAssignmentFilterLayoutIds)).subscribe(filterLayoutIds => {
    //     this.filterLayoutIds = filterLayoutIds;
    //   }),
    // );
  }

  ngOnInit(): void {}

  toggleFilterTemplate() {
    let updatedLayoutIds = [];
    if ((this.filterLayoutIds || []).indexOf(this.template?.id) > -1) {
      updatedLayoutIds = this.filterLayoutIds.filter(id => id !== this.template?.id);
    } else {
      updatedLayoutIds = [...(this.filterLayoutIds || []), this.template?.id];
    }
    this.store.dispatch(
      WorkspaceUserMetaActions.changeAssignmentFilterLayoutIdsRequest({ ids: updatedLayoutIds }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
