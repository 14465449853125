import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { NodeModel } from '../models/node.model';
import { map } from 'rxjs/operators';
import { NodeType } from '../constants/node-type';
import { ResponseMessage } from '../models/response-message.model';

@Injectable({
  providedIn: 'root',
})
export class NodeService {
  private selectedLayoutAssignmentSubject = new Subject<NodeModel>();
  private financialCostSubject = new Subject<{
    totalCost: number;
    totalRevenue: number;
    contribution: number;
  }>();

  constructor(private http: HttpClient) {}

  /*
   * @deprecated: DO NOT USE. Once a workspace gets large this API request will crash the browser
   */
  // public loadWorkspaceNodeTree(slug: string): Observable<NodeModel[]> {
  //   return this.http.get<NodeModel[]>(`${environment.apiBaseUrl}/workspace/${slug}/tree`);
  // }

  // this should do be moved to helper service
  public setSelectedLayoutAssignment(assignment: NodeModel): void {
    return this.selectedLayoutAssignmentSubject.next(assignment);
  }
  // this should do be moved to helper service
  public getSelectedLayoutAssignment(): Observable<NodeModel> {
    return this.selectedLayoutAssignmentSubject.asObservable();
  }
  // this should do be moved to helper service
  public setFinancialCosts(costs: {
    totalCost: number;
    totalRevenue: number;
    contribution: number;
  }) {
    this.financialCostSubject.next(costs);
  }
  // this should do be moved to helper service
  public getFinancialCosts(): Observable<{
    totalCost: number;
    totalRevenue: number;
    contribution: number;
  }> {
    return this.financialCostSubject.asObservable();
  }
  // this should do be moved to helper service
  public clearFinancialCosts() {
    this.financialCostSubject.next({
      totalCost: 0,
      totalRevenue: 0,
      contribution: 0,
    });
  }

  public loadWorkspaceArchivedNodeTree(slug: string): Observable<NodeModel[]> {
    let nodeTypes = [
      NodeType.projectFolder,
      NodeType.project,
      NodeType.assetFolder,
      NodeType.asset,
      NodeType.elementFolder,
      NodeType.element,
      NodeType.folderShortcut,
    ];
    return this.http.get<NodeModel[]>(
      `${environment.apiBaseUrl}/workspace/${slug}/tree?archived=1&nodeTypes=[${nodeTypes.join(
        ',',
      )}]`,
    );
  }

  public getWorkspaceNode(nodeId: number): Observable<NodeModel> {
    return this.http.get<NodeModel>(`${environment.apiBaseUrl}/workspace/node/${nodeId}`);
  }

  public addFolderShortcut(sourceId: number, targetId: number): Observable<NodeModel> {
    return this.http
      .post<ResponseMessage<NodeModel>>(
        `${environment.apiBaseUrl}/workspace/${sourceId}/shortcut/add/${targetId}`,
        {},
      )
      .pipe(map(response => response.data));
  }

  public addWorkspaceNode(
    slug: string,
    nodeType: NodeType,
    title: string,
    parentNodeId?: number,
    nodeTemplateId?: number,
  ): Observable<NodeModel> {
    return this.http.post<NodeModel>(`${environment.apiBaseUrl}/workspace/${slug}/node/add`, {
      nodeType,
      title,
      parentNode: parentNodeId,
      nodeTemplate: nodeTemplateId,
    });
  }

  public updateWorkspaceNode(nodeId: number, nodeProps: Partial<NodeModel>): Observable<NodeModel> {
    let props: any = { ...nodeProps };

    // API expects update props match the Database Entity fields
    if (props.allowedTemplateIds) {
      props.allowedTemplates = props.allowedTemplateIds;
      delete props.allowedTemplateIds;
    }
    return this.http
      .patch<{ data: NodeModel }>(`${environment.apiBaseUrl}/workspace/node/${nodeId}/update`, {
        ...props,
      })
      .pipe(map(response => response.data));
  }

  public updateWorkspaceNodeNotification(
    nodeId: number,
    notifications: string,
  ): Observable<NodeModel> {
    return this.http
      .patch<{ data: NodeModel }>(`${environment.apiBaseUrl}/workspace/node/${nodeId}/update`, {
        notifications,
      })
      .pipe(map(response => response.data));
  }

  public updateNodeMeta(nodeId: number, metaData: any): Observable<NodeModel> {
    return this.http
      .patch<{ data: NodeModel }>(
        `${environment.apiBaseUrl}/workspace/node/${nodeId}/meta/update`,
        {
          nodeMeta: metaData,
        },
      )
      .pipe(map(response => response.data));
  }

  public removeWorkspaceNodeProfile(nodeId: number): Observable<NodeModel> {
    return this.http
      .delete<{ data: NodeModel }>(
        `${environment.apiBaseUrl}/workspace/node/${nodeId}/profile/remove`,
      )
      .pipe(map(response => response.data));
  }

  public removeWorkspaceNode(nodeId: number): Observable<object> {
    return this.http.delete(`${environment.apiBaseUrl}/workspace/node/${nodeId}/remove`);
  }

  public removeWorkspaceNodes(slug: string, nodeIds: number[]): Observable<object> {
    const options = {
      params: new HttpParams().set('ids', JSON.stringify(nodeIds)),
    };
    return this.http.delete(`${environment.apiBaseUrl}/workspace/${slug}/node/remove`, options);
  }

  public archiveWorkspaceNodes(nodeIds: number[], slug: string): Observable<object> {
    const options = {
      params: new HttpParams().set('ids', JSON.stringify(nodeIds)),
    };
    return this.http.patch(`${environment.apiBaseUrl}/workspace/${slug}/node/archive`, {}, options);
  }

  public archiveWorkspaceNode(nodeId: number): Observable<object> {
    return this.http.patch(`${environment.apiBaseUrl}/workspace/node/${nodeId}/archive`, {});
  }

  public restoreArchivedWorkspaceNode(nodeId: number): Observable<object> {
    return this.http.patch(`${environment.apiBaseUrl}/workspace/node/${nodeId}/archive`, {
      archived: false,
    });
  }

  public applyTemplateToChildNodes(parentNodeId: number, templateId): Observable<any> {
    return this.http
      .patch<ResponseMessage<any>>(
        `${environment.apiBaseUrl}/workspace/node/${parentNodeId}/template/${templateId}/update/descendents`,
        {},
      )
      .pipe(map(response => response.data));
  }

  public applyTemplateToNodes(
    slug: string,
    nodeIds: number[],
    templateId: number,
  ): Observable<NodeModel[]> {
    const options = {
      params: new HttpParams().set('ids', JSON.stringify(nodeIds)),
    };
    return this.http
      .patch<ResponseMessage<any>>(
        `${environment.apiBaseUrl}/workspace/${slug}/node/template/${templateId}/change`,
        {},
        options,
      )
      .pipe(map(response => response.data.nodes));
  }

  public moveWorkspaceNode(nodeId: number, toParentNodeId: number, sortIndex: number = 0) {
    return this.http.put(
      `${environment.apiBaseUrl}/workspace/node/${nodeId}/move/${toParentNodeId}`,
      { sortIndex },
    );
  }

  public moveWorkspaceNodes(
    slug: string,
    nodeIds: number[],
    toParentNodeId: number,
    sortIndex: number = 0,
  ): Observable<object> {
    const options = {
      params: new HttpParams().set('ids', JSON.stringify(nodeIds)),
    };
    return this.http.patch(
      `${environment.apiBaseUrl}/workspace/${slug}/node/move/${toParentNodeId}`,
      { sortIndex },
      options,
    );
  }

  public sortWorkspaceNodes(ids: number[]) {
    return this.http.post(`${environment.apiBaseUrl}/workspace/node/sort`, { ids });
  }

  public getAssignmentReferenceCount(nodeIds: number[]): Observable<number> {
    const options = {
      params: new HttpParams()
        .set('nodeType', `[${NodeType.assignment}, ${NodeType.assignmentElement}]`)
        .set('descendants', '1')
        .set('ids', JSON.stringify(nodeIds)),
    };
    return this.http.get(`${environment.apiBaseUrl}/workspace/node/reference-count`, options).pipe(
      map((counts: { count: number }[]) => {
        return counts.reduce((sum, item) => {
          return sum + +item.count; // count property is a string so have to prepend it with + sign
        }, 0);
      }),
    );
  }

  /**
   * @deprecated: Legacy methods to be replaced/removed
   */
  private _addWorkspaceNode(slug: string, serverNodeProps: any): Observable<NodeModel> {
    return this.http.post<NodeModel>(`${environment.apiBaseUrl}/workspace/${slug}/node/add`, {
      ...serverNodeProps,
    });
  }

  /**
   * @deprecated: Legacy methods to be replaced/removed
   */
  public addNode(
    slug: string,
    isFolder: boolean,
    nodeProps: Partial<NodeModel>,
  ): Observable<NodeModel> {
    const serverNodeType = NodeType.element;

    // there is some incompatibility between nodeProps and serverNodeProps,
    // so have to specify individual props such as title and colorTheme.
    return this._addWorkspaceNode(slug, {
      title: nodeProps.title,
      colorTheme: nodeProps.colorTheme,
      nodeType: serverNodeType,
      parentNode: nodeProps.parentNodeId,
    });
  }

  /**
   * @deprecated: Legacy methods to be replaced/removed
   */
  public patchNode(nodeId: number, nodeProps: Partial<NodeModel>): Observable<NodeModel> {
    let props: any = { ...nodeProps };
    // API expects update props match the Database Entity fields
    if (props.allowedTemplateIds) {
      props.allowedTemplates = props.allowedTemplateIds;
      delete props.allowedTemplateIds;
    }
    return this.http
      .patch<{ data: NodeModel }>(`${environment.apiBaseUrl}/workspace/node/${nodeId}/update`, {
        ...props,
      })
      .pipe(map(response => response.data));
  }

  public copyNode(nodeId: number) {
    return this.http
      .post<ResponseMessage<any>>(`${environment.apiBaseUrl}/workspace/node/${nodeId}/copy`, {})
      .pipe(map(response => response.data));
  }
}
