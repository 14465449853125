import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ColorType } from '../../../../core/constants/color-type';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../store';
import { Subscription } from 'rxjs';
import { selectNodeListPanelSelectedNodeIds } from '../../../../store/node-list-panel/node-list-panel.selectors';
import { NodeListPanelActions } from '../../../../store/node-list-panel';
import { TagModel } from '../../../../core/models/tag.model';
import { OverlayPopoverService } from '../../../../shared/components/overlay-popover/overlay-popover.service';
import {
  selectContentPanelLayoutTemplateViewMode,
  selectContentPanelTagsViewMode,
  selectContentPanelWidgetsViewMode,
} from '../../../../store/workspace-user-meta/workspace-user-meta.selectors';
import { ToggleViewMode } from '../../../../core/constants/toggle-view-mode';
import { NodeViewPopoverComponent } from '../../../workspace/modals/node-view-popover/node-view-popover.component';
import { NodeViewActions } from '../../../../store/node-view';
import { TagsPickerComponent } from '../../../../shared/modals/tags-picker/tags-picker.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NodeTagsActions } from '../../../../store/node-tags';
import { NodeModel } from '../../../../core/models/node.model';
import { NodeType } from '../../../../core/constants/node-type';
import { IconType } from '../../../../core/constants/icon-type';

@Component({
  selector: 'app-leaf-list-item',
  templateUrl: './leaf-list-item.component.html',
  styleUrls: ['./leaf-list-item.component.scss'],
})
export class LeafListItemComponent implements OnInit, OnDestroy {
  private readonly subscription = new Subscription();

  public readonly NodeType = NodeType;
  public readonly ColorType = ColorType;
  public readonly IconType = IconType;
  
  @Input() node: NodeModel;
  @Input() colorTheme: ColorType;
  @Input() isDraggingOfSelected: boolean;
  @Input() readonly: boolean;

  // template: TemplateModel;
  // allowedLayoutTemplates: TemplateModel[];
  // widgets: NodeWidgetValueModel[];

  selectedLeafIds: number[] = [];
  tagsViewMode: ToggleViewMode;
  widgetsViewMode: ToggleViewMode;
  layoutTemplateViewMode: ToggleViewMode;

  constructor(
    private store: Store<AppState>,
    private modalService: NgbModal,
    private popoverService: OverlayPopoverService,
  ) {}

  ngOnInit() {
    this.subscription.add(
      this.store.pipe(select(selectNodeListPanelSelectedNodeIds)).subscribe(leafIds => {
        this.selectedLeafIds = leafIds;
      }),
    );

    this.subscription.add(
      this.store.pipe(select(selectContentPanelTagsViewMode)).subscribe(mode => {
        this.tagsViewMode = mode;
      }),
    );

    this.subscription.add(
      this.store.pipe(select(selectContentPanelWidgetsViewMode)).subscribe(mode => {
        this.widgetsViewMode = mode;
      }),
    );
    this.subscription.add(
      this.store.pipe(select(selectContentPanelLayoutTemplateViewMode)).subscribe(mode => {
        this.layoutTemplateViewMode = mode;
      }),
    );
  }

  openNodeView() {
    this.store.dispatch(
      NodeViewActions.openNodeView({
        nodeId: this.node.id,
      }),
    );
    this.popoverService.open({
      content: NodeViewPopoverComponent,
    });
  }

  toggleLeaf(event) {
    event.stopPropagation();
    if (this.readonly) {
      return;
    }
    this.store.dispatch(NodeListPanelActions.toggleSelectedNode({ node: this.node }));
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  openTagsPopover(event) {
    event.stopPropagation();
    if (this.readonly) {
      return;
    }

    const modelRef = this.modalService.open(TagsPickerComponent, {
      size: 'xl',
      backdrop: 'static',
    });
    modelRef.componentInstance.selected = this.node.tags || [];
    modelRef.result
      .then((result: TagModel[]) => {
        this.store.dispatch(
          NodeTagsActions.addMultipleTagsToNodeRequest({
            nodeId: this.node.id,
            tagIds: (result || []).map(t => t.id),
            groupId: undefined,
            replace: true,
          }),
        );
      })
      .catch(res => {});
  }

  itemsTrackBy(index, item) {
    if (!item) {
      return null;
    }
    return item.id;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
