import { Component, Input, OnInit } from '@angular/core';
import { FileModel } from '../../../core/models/file.model';
import { ColorType } from '../../../core/constants/color-type';

@Component({
  selector: 'app-node-avatar-editable',
  templateUrl: './node-avatar-editable.component.html',
  styleUrls: ['./node-avatar-editable.component.scss'],
})
export class NodeAvatarEditableComponent implements OnInit {
  @Input() profile: FileModel;
  @Input() profileIcon: { id: string; src: string };
  @Input() initials: string;
  @Input() fullName: string;
  @Input() colorTheme: ColorType;

  constructor() {}

  ngOnInit(): void {}
}
