<div class="template-calculation-create">
  <form [formGroup]="form" (ngSubmit)="submit()">
    <div class="template-calculation-create__options">
      <app-popover-subsection-row [title]="'Calculation Field A'">
        <app-popover-subsection [title]="'Title'" [transparentBackground]="true" class="layout-mr">
          <input
            #widgetATitleInput
            type="text"
            class="form-control"
            id="template-calculation-create-title-A"
            placeholder="Title"
            required
            formControlName="widgetATitle"
            [ngClass]="
              widgetATitle.invalid && (widgetATitle.dirty || isSubmitted) ? 'is-invalid' : ''
            "
            (keydown)="onButtonKeyDown($event)"
          />
          <div class="invalid-feedback">
            Please provide a title
          </div>
          <div class="mb-3"></div>
        </app-popover-subsection>
        <app-popover-subsection [title]="'Widget Type'" [transparentBackground]="true">
          <select
            class="form-control"
            id="widget-A-type"
            [formControlName]="'widgetAType'"
            [ngClass]="
              f['widgetAType'].invalid && (f['widgetAType'].dirty || isSubmitted)
                ? 'is-invalid'
                : ''
            "
          >
            <option
              *ngFor="let widgetTypeOption of availableCalculationFieldWidgetTypeOptions"
              [ngValue]="widgetTypeOption.id"
            >{{ widgetTypeOption.label }}</option
            >
          </select>
          <div class="invalid-feedback">
            Please select a Widget Type
          </div>
          <div class="mb-3"></div>
        </app-popover-subsection>
      </app-popover-subsection-row>

      <app-popover-subsection-row [title]="'Calculation Field B'">
        <app-popover-subsection [title]="'Title'" [transparentBackground]="true" class="layout-mr">
          <input
            type="text"
            class="form-control"
            id="template-calculation-create-title-B"
            placeholder="Title"
            required
            formControlName="widgetBTitle"
            [ngClass]="
              widgetBTitle.invalid && (widgetBTitle.dirty || isSubmitted) ? 'is-invalid' : ''
            "
            (keydown)="onButtonKeyDown($event)"
          />
          <div class="invalid-feedback">
            Please provide a title
          </div>
          <div class="mb-3"></div>
        </app-popover-subsection>
        <app-popover-subsection [title]="'Widget Type'" [transparentBackground]="true">
          <select
            class="form-control"
            id="widget-B-type"
            [formControlName]="'widgetBType'"
            [ngClass]="
              f['widgetBType'].invalid && (f['widgetBType'].dirty || isSubmitted)
                ? 'is-invalid'
                : ''
            "
          >
            <option
              *ngFor="let widgetTypeOption of availableCalculationFieldWidgetTypeOptions"
              [ngValue]="widgetTypeOption.id"
            >{{ widgetTypeOption.label }}</option
            >
          </select>
          <div class="invalid-feedback">
            Please select a Widget Type
          </div>
          <div class="mb-3"></div>
        </app-popover-subsection>
      </app-popover-subsection-row>

      <app-popover-subsection-row [title]="'Calculation Field C'">
        <app-popover-subsection [title]="'Title'" [transparentBackground]="true" class="layout-mr">
          <input
            type="text"
            class="form-control"
            id="template-calculation-create-title-C"
            placeholder="Title"
            required
            formControlName="widgetCTitle"
            [ngClass]="
              widgetCTitle.invalid && (widgetCTitle.dirty || isSubmitted) ? 'is-invalid' : ''
            "
            (keydown)="onButtonKeyDown($event)"
          />
          <div class="invalid-feedback">
            Please provide a title
          </div>
          <div class="mb-3"></div>
        </app-popover-subsection>
        <app-popover-subsection [title]="'Calculation Method'" [transparentBackground]="true">
          <select
            class="form-control"
            id="widget-C-type"
            [formControlName]="'calculationMethod'"
            [ngClass]="
              f['calculationMethod'].invalid && (f['calculationMethod'].dirty || isSubmitted)
                ? 'is-invalid'
                : ''
            "
          >
            <option
              *ngFor="let calculationMethodOption of availableCalculationMethodOptions"
              [ngValue]="calculationMethodOption.id"
            >{{ calculationMethodOption.label }}</option
            >
          </select>
          <div class="invalid-feedback">
            Please select a Calculation Method
          </div>
          <div class="mb-3"></div>
        </app-popover-subsection>
      </app-popover-subsection-row>
    </div>

    <!-- <div class="pb-3"></div> -->

    <div class="template-calculation-create__actions">
      <span>
        <button type="submit" class="btn btn-primary btn-sm layout-mr">Save</button>
        <button type="button" class="btn btn-outline-light btn-sm" (click)="close()">Cancel</button>
      </span>
    </div>
  </form>
</div>
