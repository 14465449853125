import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../store';
import { NodeType } from '../../../core/constants/node-type';
import { AbstractPickerComponent, ListItem } from '../_abstract-picker/abstract-picker.component';
import { IconType } from '../../../core/constants/icon-type';
import { selectAvailableNodeTemplateOptionsWithProps } from '../../../store/templates/templates.selectors';
import { take } from 'rxjs/operators';
import { OptionModel } from '../../../core/models/option.model';

@Component({
  templateUrl: './assigned-template-picker.component.html',
  styleUrls: ['./assigned-template-picker.component.scss'],
})
export class AssignedTemplatePickerComponent extends AbstractPickerComponent<OptionModel>
  implements OnInit, OnDestroy {
  public readonly IconType = IconType;
  public readonly NodeType = NodeType;

  @Input()
  public type: NodeType;
  public templateOptions: OptionModel[] = [];
  constructor(public activeModal: NgbActiveModal, private store: Store<AppState>) {
    super(activeModal);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.subscription.add(
      this.store
        .pipe(select(selectAvailableNodeTemplateOptionsWithProps(this.type)), take(1))
        .subscribe(options => {
          this.templateOptions = options;
          this.updateList(this.templateOptions);
        }),
    );
  }

  public search(keywords) {
    this.keywords = keywords;
  }

  public isEqual(itemA: OptionModel, itemB: OptionModel): boolean {
    return itemA.id === itemB.id;
  }

  public add() {
    throw new Error('Not implemented');
  }

  public edit(listItem: ListItem<OptionModel>) {
    throw new Error('Not implemented');
  }

  public trackBy(index, item: ListItem<OptionModel>) {
    return item.model?.id;
  }

  protected sortItems() {
    // Use the default sort order of the 'flatTree'
    return;
  }
}
