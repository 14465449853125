import { ActionReducer, ActionReducerMap, INIT, MetaReducer } from '@ngrx/store';
import { nodeListPanelReducer } from './../store/node-list-panel/node-list-panel.reducer';
import { NodeListPanelState } from './../store/node-list-panel/node-list-panel.state';
import { PropertiesPanelState } from './properties-panel/properties-panel.state';
import { propertiesPanelReducer } from './properties-panel/properties-panel.reducer';
import { StatusBarState } from './status-bar/status-bar.state';
import { statusBarReducer } from './status-bar/status-bar.reducer';
import { WorkspacesState } from './workspaces/workspaces.state';
import { workspacesReducer } from './workspaces/workspaces.reducer';
import { ErrorsState } from './errors/errrors.state';
import { errorsReducer } from './errors/errors.reducer';
import { WorkspaceUserMetaState } from './workspace-user-meta/workspace-user-meta.state';
import { workspaceUserMetaReducer } from './workspace-user-meta/workspace-user-meta.reducer';
import { ArchivePopoverState } from './archive-popover/archive-popover.state';
import { archivePopoverReducer } from './archive-popover/archive-popover.reducer';
import { AuthState } from './auth/auth.state';
import { AuthReducer } from './auth/auth.reducer';
import { tagsPopoverReducer } from './tags-popover/tags-popover.reducer';
import { TagsPopoverState } from './tags-popover/tags-popover.state';
import { TagsState } from './tags/tags.state';
import { tagsReducer } from './tags/tags.reducer';
import { NodeTagsState } from './node-tags/node-tags.state';
import { nodeTagsReducer } from './node-tags/node-tags.reducer';
import { WidgetTypesState } from './widget-types/widget-types.state';
import { widgetTypesReducer } from './widget-types/widget-types.reducer';
import { WidgetEditPopoverState } from './widget-edit-popover/widget-edit-popover.state';
import { widgetEditPopoverReducer } from './widget-edit-popover/widget-edit-popover.reducer';
import { TemplatesState } from './templates/templates.state';
import { templatesReducer } from './templates/templates.reducer';
import { WorkspaceUsersState } from './workspace-users/workspace-users.state';
import { workspaceUsersReducer } from './workspace-users/workspace-users.reducer';
import { TemplatesPopoverState } from './templates-popover/templates-popover.state';
import { templatesPopoverReducer } from './templates-popover/templates-popover.reducer';
import { WidgetsState } from './widgets/widgets.state';
import { widgetsReducer } from './widgets/widgets.reducer';
import { CalendarState } from './calendar/calendar.state';
import { calendarReducer } from './calendar/calendar.reducer';
import { WidgetsPopoverState } from './widgets-popover/widgets-popover.state';
import { widgetsPopoverReducer } from './widgets-popover/widgets-popover.reducer';
import { TemplateWidgetsState } from './template-widgets/template-widgets.state';
import { templateWidgetsReducer } from './template-widgets/template-widgets.reducer';
import { ArchivesState } from './archives/archives.state';
import { archivesReducer } from './archives/archives.reducer';
import { ArchivesPopoverState } from './archives-popover/archives-popover.state';
import { archivesPopoverReducer } from './archives-popover/archives-popover.reducer';
import { storageMetaReducer } from './local-storage/local-storage.reducer';
import { FiguresEditPopoverState } from './figures-edit-popover/figures-edit-popover.state';
import { figuresEditPopoverReducer } from './figures-edit-popover/figures-edit-popover.reducer';
import { TemplateTagsState } from './template-tags/template-tags.state';
import { templateTagsReducer } from './template-tags/template-tags.reducer';
import { DndState } from './dnd/dnd.state';
import { dndReducer } from './dnd/dnd.reducer';
import { GroupTagsPopoverState } from './group-tags-popover/group-tags-popover.state';
import { groupTagsPopoverReducer } from './group-tags-popover/group-tags-popover.reducer';
import { WorkspaceTemplatesState } from './workspace-templates/workspace-templates.state';
import { workspaceTemplatesReducer } from './workspace-templates/workspace-templates.reducer';
import { WorkspaceGroupsState } from './workspace-groups/workspace-groups.state';
import { workspaceGroupsReducer } from './workspace-groups/workspace-groups.reducer';
import { TemplateFiguresPopoverState } from './template-figures-popover/template-figures-popover.state';
import { templatefiguresPopoverReducer } from './template-figures-popover/template-figures-popover.reducer';
import { GanttsState } from './gantts/gantts.state';
import { ganttsReducer } from './gantts/gantts.reducer';
import * as fromRouter from '@ngrx/router-store';
import { GenericPopoverState } from './generic-popover/generic-popover.state';
import { genericPopoverReducer } from './generic-popover/generic-popover.reducer';
import { workspaceSharesReducer } from './workspace-shares/workspace-shares.reducer';
import { WorkspaceSharesState } from './workspace-shares/workspace-shares.state';
import { SharedViewState } from './shared-view/shared-view.state';
import { sharedViewReducer } from './shared-view/shared-view.reducer';
import { NodeViewState } from './node-view/node-view.state';
import { nodeViewReducer } from './node-view/node-view.reducer';
import { WorkspaceUrlsState } from './workspace-urls/workspace-urls.state';
import { workspaceUrlsReducer } from './workspace-urls/workspace-urls.reducer';
import { GroupLinksPopoverState } from './group-links-popover/group-links-popover.state';
import { groupLinksPopoverReducer } from './group-links-popover/group-links-popover.reducer';
import { TimesheetsState } from './timesheets/timesheets.state';
import { timesheetsReducer } from './timesheets/timesheets.reducer';
import { NodeRatesState } from './node-rates/node-rates.state';
import { nodeRatesReducer } from './node-rates/node-rates.reducer';
import { RateEditPopoverState } from './rate-edit-popover/rate-edit-popver.state';
import { rateEditPopoverReducer } from './rate-edit-popover/rate-edit-popover.reducer';
import { ChatsState } from './chats/chats.state';
import { chatsReducer } from './chats/chats.reducer';
import { ChatbarState } from './chatbar/chatbar.state';
import { chatbarReducer } from './chatbar/chatbar.reducer';
import { WorkspaceUserGroupsState } from './workspace-user-groups/workspace-user-groups.state';
import { workspaceUserGroupsReducer } from './workspace-user-groups/workspace-user-groups.reducer';
import { UserGroupsPopoverState } from './user-groups-popover/user-groups-popover.state';
import { userGroupsPopoverReducer } from './user-groups-popover/user-groups-popover.reducer';
import { RolePermissionsState } from './role-permissions/role-permissions.state';
import { rolePermissionsReducer } from './role-permissions/role-permissions.reducer';
import { AppLayoutState } from './app-layout/app-layout.state';
import { appLayoutReducer } from './app-layout/app-layout.reducer';
import { environment } from '../../environments/environment';
import { AuthActions } from './auth';
import { NodesState } from './nodes/nodes.state';
import { nodesReducer } from './nodes/nodes.reducer';
import { NodeWidgetsState } from './node-widgets/node-widgets.state';
import { nodeWidgetsReducer } from './node-widgets/node-widgets.reducer';
import { NodeRateValuesState } from './node-rate-values/node-rate-values.state';
import { nodeRateValuesReducer } from './node-rate-values/node-rate-values.reducer';
import { NodeWidgetRowsState } from './node-widget-rows/node-widget-rows.state';
import { nodeWidgetRowsReducer } from './node-widget-rows/node-widget-rows.reducer';
import { NodeGroupsState } from './node-groups/node-groups.state';
import { nodeGroupsReducer } from './node-groups/node-groups.reducer';
import { LayoutTemplateState } from './layout-templates/layout-templates.state';
import { layoutTemplatesReducer } from './layout-templates/layout-templates.reducer';
export interface AppState {
  appLayout: AppLayoutState;
  nodes: NodesState;
  nodeRates: NodeRatesState;
  nodeRateValues: NodeRateValuesState;
  nodeTags: NodeTagsState;
  nodeGroups: NodeGroupsState;
  nodeWidgets: NodeWidgetsState;
  nodeWidgetRows: NodeWidgetRowsState;

  archivePopover: ArchivePopoverState;
  archives: ArchivesState;
  archivesPopover: ArchivesPopoverState;
  auth: AuthState;

  calendar: CalendarState;
  chatbar: ChatbarState;
  chats: ChatsState;
  contentPanel: NodeListPanelState;
  dnd: DndState;
  errors: ErrorsState;
  figuresEditPopover: FiguresEditPopoverState;
  gantts: GanttsState;
  groupLinksPopover: GroupLinksPopoverState;
  groupTagsPopover: GroupTagsPopoverState;
  genericPopover: GenericPopoverState;
  nodeView: NodeViewState;
  propertiesPanel: PropertiesPanelState;
  rateEditPopover: RateEditPopoverState;
  rolePermissions: RolePermissionsState;
  router: fromRouter.RouterReducerState;
  sharedView: SharedViewState;
  statusBar: StatusBarState;
  tags: TagsState;
  tagsPopover: TagsPopoverState;
  templateFiguresPopover: TemplateFiguresPopoverState;
  templates: TemplatesState;
  layoutTemplates: LayoutTemplateState;
  templatesPopover: TemplatesPopoverState;
  templateTags: TemplateTagsState;
  templateWidgets: TemplateWidgetsState;
  timesheets: TimesheetsState;
  userGroupsPopover: UserGroupsPopoverState;
  widgetEditPopover: WidgetEditPopoverState;
  widgets: WidgetsState;
  widgetTypes: WidgetTypesState;
  widgetsPopover: WidgetsPopoverState;
  workspaceGroups: WorkspaceGroupsState;
  workspaces: WorkspacesState;
  workspaceShares: WorkspaceSharesState;
  workspaceTemplates: WorkspaceTemplatesState;
  workspaceUrls: WorkspaceUrlsState;
  workspaceUserGroups: WorkspaceUserGroupsState;
  workspaceUserMeta: WorkspaceUserMetaState;
  workspaceUsers: WorkspaceUsersState;
}

export const reducers: ActionReducerMap<AppState> = {
  router: fromRouter.routerReducer,
  appLayout: appLayoutReducer,
  nodes: nodesReducer,
  nodeWidgets: nodeWidgetsReducer,
  nodeGroups: nodeGroupsReducer,
  nodeWidgetRows: nodeWidgetRowsReducer,
  nodeRates: nodeRatesReducer,
  nodeRateValues: nodeRateValuesReducer,
  nodeTags: nodeTagsReducer,
  archivePopover: archivePopoverReducer,
  archives: archivesReducer,
  archivesPopover: archivesPopoverReducer,
  auth: AuthReducer,
  calendar: calendarReducer,
  chatbar: chatbarReducer,
  chats: chatsReducer,
  contentPanel: nodeListPanelReducer,
  dnd: dndReducer,
  errors: errorsReducer,
  figuresEditPopover: figuresEditPopoverReducer,
  gantts: ganttsReducer,
  groupLinksPopover: groupLinksPopoverReducer,
  groupTagsPopover: groupTagsPopoverReducer,
  genericPopover: genericPopoverReducer,
  nodeView: nodeViewReducer,
  propertiesPanel: propertiesPanelReducer,
  rateEditPopover: rateEditPopoverReducer,
  rolePermissions: rolePermissionsReducer,
  sharedView: sharedViewReducer,
  statusBar: statusBarReducer,
  tags: tagsReducer,
  tagsPopover: tagsPopoverReducer,
  templateFiguresPopover: templatefiguresPopoverReducer,
  templates: templatesReducer,
  layoutTemplates: layoutTemplatesReducer,
  templatesPopover: templatesPopoverReducer,
  templateTags: templateTagsReducer,
  templateWidgets: templateWidgetsReducer,
  timesheets: timesheetsReducer,
  userGroupsPopover: userGroupsPopoverReducer,
  widgetEditPopover: widgetEditPopoverReducer,
  widgets: widgetsReducer,
  widgetsPopover: widgetsPopoverReducer,
  widgetTypes: widgetTypesReducer,
  workspaceGroups: workspaceGroupsReducer,
  workspaces: workspacesReducer,
  workspaceShares: workspaceSharesReducer,
  workspaceTemplates: workspaceTemplatesReducer,
  workspaceUrls: workspaceUrlsReducer,
  workspaceUserGroups: workspaceUserGroupsReducer,
  workspaceUserMeta: workspaceUserMetaReducer,
  workspaceUsers: workspaceUsersReducer,
};

export function logout(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    if (action != null && action.type === AuthActions.logoutRequest.type) {
      return reducer(undefined, { type: INIT });
    }
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<AppState>[] = !environment.production
  ? [storageMetaReducer, logout]
  : [storageMetaReducer, logout];
