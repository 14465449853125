import { SharedLinkMetaModel } from '../../core/models/shared-link-meta.model';
import { AssignmentLayoutMode } from '../../core/constants/assignment-layout-mode';
import { CardLayoutType } from '../../core/constants/card-layout-type';
import { defaultSortingOption } from '../../core/models/sorting-option.model';
import { AssignmentViewType } from '../../core/constants/assignment-view-type';
import { NodeModel } from '../../core/models/node.model';
import { NodeTemplateModel } from '../../core/models/node-template.model';
import { WorkspaceGroupModel } from '../../core/models/workspace-group.model';

export class SharedViewState {
  serverNodesById: { [id: number]: NodeModel };
  serverNodeIds: number[];
  hash: string;
  metaData: SharedLinkMetaModel;
  serverNodeTemplatesById: { [id: number]: NodeTemplateModel };
  serverNodeTemplateIds: number[];
  serverGroupsById: { [id: number]: WorkspaceGroupModel };
  serverGroupsIds: number[];
  serverTagsById: { [id: number]: any };
  serverTagsIds: number[];
  serverWidgetsById: { [id: number]: any };
  serverWidgetsIds: number[];
  // timeSheetsByProjectIds: { [id: number]: any };
}

const initialMeta: SharedLinkMetaModel = {
  assignmentFilterPrimaryTagIds: [],
  assignmentFilterProjectIds: [],
  startDate: undefined,
  endDate: undefined,
  nullDate: false,
  assignmentSorting: defaultSortingOption.id,
  assignmentViewType: AssignmentViewType.relaxed,
  assignmentLayout: AssignmentLayoutMode.schedule,
  assignmentCardLayout: CardLayoutType.twoColumns,
  assignmentViewWidgetsNodeTypes: [],
  assignmentViewTagsNodeTypes: [],
};

export const initialSharedViewState: SharedViewState = {
  serverNodesById: {},
  serverNodeIds: [],
  hash: undefined,
  metaData: initialMeta,
  serverNodeTemplatesById: {},
  serverNodeTemplateIds: [],
  serverGroupsById: {},
  serverGroupsIds: [],
  serverTagsById: {},
  serverTagsIds: [],
  serverWidgetsById: {},
  serverWidgetsIds: [],
  // timeSheetsByProjectIds: {},
};
