import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store';
import { NodeTemplateModel } from '../../../core/models/node-template.model';
import { LayoutTemplatesActions } from '../../../store/layout-templates';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IconType } from '../../../core/constants/icon-type';
import { IconPickerComponent } from '../icon-picker/icon-picker.component';

@Component({
  templateUrl: './layout-template-add.component.html',
  styleUrls: ['./layout-template-add.component.scss'],
})
export class LayoutTemplateAddComponent implements OnInit, OnDestroy {
  @Input()
  public template: NodeTemplateModel;
  public readonly readonly = true;
  public readonly modalTitle = 'Add Layout Template';
  public layoutTemplateAddForm: UntypedFormGroup;
  public fileTypes = 'image/*';
  public objectUrl: string;
  constructor(
    public activeModal: NgbActiveModal,
    private store: Store<AppState>,
    private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
  ) {
    //
  }

  public get f() {
    return this.layoutTemplateAddForm.controls;
  }

  ngOnInit(): void {
    this.layoutTemplateAddForm = this.formBuilder.group({
      title: [this.template?.title, [Validators.required]],
      description: [this.template?.tooltip, Validators.required],
      icon: [this.template?.icon],
      thumbnail: [null],
    });
  }

  public onIconPicker() {
    const modelRef = this.modalService.open(IconPickerComponent, {
      size: 'md',
      backdrop: 'static',
    });
    modelRef.componentInstance.selected = this.f?.icon?.value
      ? IconType[this.f?.icon?.value]
      : IconType['not-available'];
    modelRef.result
      .then((result: string) => {
        if (result) {
          this.f?.icon?.setValue(IconType[result]);
        }
      })
      .catch(res => {});
  }

  public imagePreview(event) {
    const file: File = (event.target as HTMLInputElement).files[0];

    // for now
    this.layoutTemplateAddForm?.get('thumbnail').setValue(file);
    this.objectUrl = URL.createObjectURL(this.layoutTemplateAddForm?.get('thumbnail').value);
  }

  public onSubmit() {
    if (this.layoutTemplateAddForm?.invalid) {
      return;
    }
    this.store.dispatch(
      LayoutTemplatesActions.addLayoutTemplateRequest({
        nodeTemplateId: this.template.id,
        payload: {
          title: this.f?.title?.value,
          tooltip: this.f?.description?.value,
          sortIndex: this.template?.sortIndex,
          metaData: {
            icon: this.f?.icon?.value,
            colorTheme: this.template?.colorTheme,
            style: null,
          },
          profile: this.f?.thumbnail?.value,
        },
      }),
    );
    this.activeModal.close();
  }

  public ngOnDestroy(): void {
    //
  }
}
