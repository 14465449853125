<div class="rich-search">
  <button
    *ngIf="isFiltered == true"
    type="button"
    class="btn btn-link rich-search__btn-reset"
    (click)="reset()"
  >
    <i class="fal fa-times"></i>
  </button>
  <input
    #richSearch
    type="text"
    class="rich-search__input form-control"
    [placeholder]="searchPlaceholder ? searchPlaceholder : 'Search'"
    [ngModel]="searchModel"
    (ngModelChange)="searchModeChange($event)"
    (selectItem)="selectItem($event)"
    [ngbTypeahead]="search"
    (focus)="focus$.next($any($event).target.value)"
    (click)="click$.next($any($event).target.value)"
    [resultTemplate]="resultTemplate"
    [inputFormatter]="formatter"
    #typeaheadInstance="ngbTypeahead"
    container="body"
  />
  <div
    class="rich-search__filters"
    *ngIf="
      showFilters ||
      (
        selectedSearchItems
        | richSearchSelectedIds
          : [
              RichSearchType.template,
              RichSearchType.layout,
              RichSearchType.tag,
              RichSearchType.folder,
              RichSearchType.unassignedDate
            ]
      ).length > 0
    "
  >
    <app-rich-search-filter
      #templateFilter
      *ngIf="(itemOptions | richSearchFilterOptions: [RichSearchType.template]).length > 0"
      id="rich-search-template-dropdown"
      class="rich-search__filter-dropdown "
      [type]="RichSearchType.template"
      [selectedEntityIds]="selectedSearchItems | richSearchSelectedIds: [RichSearchType.template]"
      [entityOptions]="itemOptions | richSearchFilterOptions: [RichSearchType.template]"
      (changeOptionIds)="changeFilterOptionIds($event, RichSearchType.template)"
    >
    </app-rich-search-filter>

    <app-rich-search-filter
      #layoutFilter
      *ngIf="(itemOptions | richSearchFilterOptions: [RichSearchType.layout]).length > 0"
      id="rich-search-layout-template-dropdown"
      class="rich-search__filter-dropdown "
      [type]="RichSearchType.layout"
      [selectedEntityIds]="selectedSearchItems | richSearchSelectedIds: [RichSearchType.layout]"
      [entityOptions]="itemOptions | richSearchFilterOptions: [RichSearchType.layout]"
      (changeOptionIds)="changeFilterOptionIds($event, RichSearchType.layout)"
    >
    </app-rich-search-filter>

    <app-rich-search-filter
      #tagFilter
      *ngIf="(itemOptions | richSearchFilterOptions: [RichSearchType.tag]).length > 0"
      id="rich-search-tag-dropdown"
      class="rich-search__filter-dropdown"
      [type]="RichSearchType.tag"
      [selectedEntityIds]="selectedSearchItems | richSearchSelectedIds: [RichSearchType.tag]"
      [entityOptions]="itemOptions | richSearchFilterOptions: [RichSearchType.tag]"
      (changeOptionIds)="changeFilterOptionIds($event, RichSearchType.tag)"
    >
    </app-rich-search-filter>

    <app-rich-search-filter
      #folderFilter
      *ngIf="(itemOptions | richSearchFilterOptions: [RichSearchType.folder]).length > 0"
      id="rich-search-folder-dropdown"
      class="rich-search__filter-dropdown"
      [type]="RichSearchType.folder"
      [selectedEntityIds]="selectedSearchItems | richSearchSelectedIds: [RichSearchType.folder]"
      [entityOptions]="itemOptions | richSearchFilterOptions: [RichSearchType.folder]"
      (changeOptionIds)="changeFilterOptionIds($event, RichSearchType.folder)"
    >
    </app-rich-search-filter>

    <app-rich-search-filter
      #unassignedFilter
      *ngIf="(itemOptions | richSearchFilterOptions: [RichSearchType.unassignedDate]).length > 0"
      id="rich-search-unassigned-date-dropdown"
      class="rich-search__filter-dropdown"
      [type]="RichSearchType.unassignedDate"
      [selectedEntityIds]="
        selectedSearchItems | richSearchSelectedIds: [RichSearchType.unassignedDate]
      "
      [entityOptions]="itemOptions | richSearchFilterOptions: [RichSearchType.unassignedDate]"
      (changeOptionIds)="changeFilterOptionIds($event, RichSearchType.unassignedDate)"
    >
    </app-rich-search-filter>
  </div>
</div>

<ng-template #resultTemplate let-result="result" let-term="term">
  <div class="rich-search-result">
    <i
      *ngIf="result.icon"
      class="rich-search-result__icon fal"
      [ngClass]="[
        result.icon ? 'fa-' + (result.icon | iconString) : '',
        result.iconColor ? 'color--' + (result.iconColor | colorString) : ''
      ]"
    ></i>
    <span class="rich-search-result__type-label">
      {{ (result.type | richSearchTypeLabel) ? (result.type | richSearchTypeLabel) + ': ' : '' }}
    </span>
    {{ result.entity.title }}
  </div>
</ng-template>
