<div *ngIf="node" class="folder-tree-node">
  <div>
    <div class="folder-tree-node__header" [ngStyle]="{ 'padding-left.px': node.depth * 10 }">
      <div class="folder-tree-node__header-content">
        <span
          *ngIf="node.parentNodeId != null && readonly == false && siblingNodeIds.length > 0"
          cdkDragHandle
          class="folder-tree-node__drag-handle-icon fal fa-arrows-alt-v"
        ></span>

        <!--<app-folder-expansion-->
        <!--  [node]="node"-->
        <!--  [expand]="isExpanded"-->
        <!--  [disabled]="childrenNodes.length == 0"-->
        <!--&gt;</app-folder-expansion>-->

        <div
          class="folder-tree-node__name"
          [ngClass]="[node ? '' : 'folder-tree-node__name--root']"
          (click)="openNodeListPanel(node)"
        >
          <ng-container [ngSwitch]="node.nodeType">
            <i
              *ngSwitchCase="NodeType.favouritesFolder"
              class="folder-tree-node__icon fa-solid fa-star favourite-color"
              [ngClass]="[
                node.reference?.colorTheme
                  ? 'color--' + (node.reference.colorTheme | colorString)
                  : ''
              ]"
            ></i>

            <i
              *ngSwitchCase="NodeType.folderShortcut"
              class="folder-tree-node__icon fa-solid"
              [ngClass]="[
                      node.reference?.colorTheme ? 'color--'+(node.reference.colorTheme | colorString) : '',
                      node.reference?.nodeType === NodeType.projectFolder ? 'fa-share-from-square' : 'fa-share',
                    ]"
            ></i>

            <i
              *ngSwitchDefault
              class="folder-tree-node__icon fa-solid"
              [ngClass]="[
                      node.colorTheme ? 'color--'+(node.colorTheme | colorString) : '',
                      node.nodeType === NodeType.projectFolder ? 'fa-folder' : 'fa-folder-grid',
                    ]"
            ></i>
          </ng-container>

          {{ node.nodeType == NodeType.folderShortcut ? node.reference?.title : node.title }}
        </div>

        <app-context-buttons
          *ngIf="node.nodeType != NodeType.favouritesFolder"
          class="folder-tree-node__context-buttons"
          [node]="node"
        ></app-context-buttons>
      </div>
    </div>

    <ul
      *ngIf="node"
      cdkDropList
      class="list-unstyled folder-tree-node__children"
      [ngClass]="{ hidden: !isExpanded }"
      [id]="node.id.toString()"
      [cdkDropListConnectedTo]="siblingNodeIds"
    >
      <li class="folder-tree-node__child" *ngFor="let childNode of childrenNodes">
        <div
          cdkDrag
          [cdkDragData]="childNode"
          [cdkDragDisabled]="
            childNode.parentNodeId == null || readonly == true || childrenNodes.length < 2
          "
          (cdkDragDropped)="onNodeDropped($event)"
        >
          <app-folder-tree-node [node]="childNode" [readonly]="readonly"></app-folder-tree-node>
        </div>
      </li>
    </ul>
  </div>
</div>
