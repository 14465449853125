import { createSelector } from '@ngrx/store';
import {
  selectAssignmentOverlayViewMode,
  selectUserMetaLastAssignmentLayout,
} from '../workspace-user-meta/workspace-user-meta.selectors';
import { selectRouterInfoAssignmentLayout } from './router.selectors';
import { AssignmentLayoutMode } from '../../core/constants/assignment-layout-mode';
import { AssignmentDisplayMode } from '../../core/constants/assignment-display-mode';

export const selectRouterDerivedAssignmentLayout = createSelector(
  selectRouterInfoAssignmentLayout,
  selectUserMetaLastAssignmentLayout,
  (routerInfoLayout, userMetaLayout) => {
    // To prevent old layout data or dirty data
    const validUserMetaLayout = AssignmentLayoutMode[userMetaLayout]
      ? userMetaLayout
      : AssignmentLayoutMode.schedule;

    return (routerInfoLayout as AssignmentLayoutMode) || validUserMetaLayout;
  },
);

export const selectIsGanttChartLayout = createSelector(
  selectRouterDerivedAssignmentLayout,
  layout => layout === AssignmentLayoutMode.chart,
);
export const selectRouterDerivedAssignmentOverlayViewMode = createSelector(
  selectAssignmentOverlayViewMode,
  selectRouterDerivedAssignmentLayout,
  (viewMode, layout) => {
    return layout === AssignmentLayoutMode.chart ? AssignmentDisplayMode.Calender : viewMode;
  },
);
