import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { NodeTemplateModel } from '../../../core/models/node-template.model';
import { TemplatesActions } from '../../../store/templates';

@Component({
  selector: 'app-template-pricing',
  templateUrl: './template-pricing.component.html',
  styleUrls: ['./template-pricing.component.scss'],
})
export class TemplatePricingComponent implements OnInit, OnDestroy {
  private readonly subscription = new Subscription();
  @Input() selectedTemplate: NodeTemplateModel;
  @Input() type: 'revenue' | 'cost' | 'financial' = 'financial';
  @Input() title: string;

  constructor(private store: Store) {}

  ngOnInit(): void {
    //
  }

  isSwitchedOn() {
    if (this.type === 'revenue') {
      return this.selectedTemplate?.allowRevenue;
    } else if (this.type === 'cost') {
      return this.selectedTemplate?.allowCosts;
    } else if (this.type === 'financial') {
      return this.selectedTemplate?.allowRates;
    }
  }

  changeSwitch(): void {
    let templateProps = {};
    if (this.type === 'revenue') {
      templateProps = {
        allowRevenue: !this.selectedTemplate?.allowRevenue,
      };
    } else if (this.type === 'cost') {
      templateProps = {
        allowCosts: !this.selectedTemplate?.allowCosts,
      };
    } else if (this.type === 'financial') {
      templateProps = {
        allowRates: !this.selectedTemplate?.allowRates,
      };
    }

    this.store.dispatch(
      TemplatesActions.updateNodeTemplateRequest({
        templateId: this.selectedTemplate.id,
        templateProps,
      }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
