import { createAction, props } from '@ngrx/store';
import { WorkspaceUserModel } from '../../core/models/workspace-user.model';
import { RoleType } from '../../core/constants/role-type';

export const loadCurrentWorkspaceUsersRequest = createAction(
  '[Workspace users] load current workspace users request',
  props<{ workspaceSlug: string }>(),
);

export const loadCurrentWorkspaceUsersSuccess = createAction(
  '[Workspace users] load current workspace users success',
  props<{ users: WorkspaceUserModel[] }>(),
);

export const inviteWorkspaceUserRequest = createAction(
  '[Workspace users] invite workspace user request',
  props<{ email: string }>(),
);

export const inviteWorkspaceUserSuccess = createAction(
  '[Workspace users] invite workspace user success',
  props<{ user: WorkspaceUserModel }>(),
);

export const deleteWorkspaceUserRequest = createAction(
  '[Workspace users] delete workspace user request',
  props<{ id: number, userId: number }>(),
);

export const patchWorkspaceUserRoleRequest = createAction(
  '[Auth] patch workspace user role request',
  props<{ id: number; role: RoleType }>(),
);

export const patchWorkspaceUserRoleSuccess = createAction(
  '[Auth] patch workspace user role success',
  props<{ user: WorkspaceUserModel }>(),
);

export const updateWorkspaceUserBelongedGroupsRequest = createAction(
  '[Auth] update workspace user belonged groups request',
  props<{ userId: number; groupIds: number[] }>(),
);

export const updateWorkspaceUserBelongedGroupsSuccess = createAction(
  '[Auth] update workspace user belonged groups success',
  props<{ user: WorkspaceUserModel }>(),
);
