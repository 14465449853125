import { Action, createReducer, on } from '@ngrx/store';
import { ArchivesState, initialArchivesState } from './archives.state';
import { ArchivesActions } from './index';

const reducer = createReducer(
  initialArchivesState,
  on(ArchivesActions.loadArchivedNodeTreeSuccess, (state, { nodes }) => {
    return {
      ...state,
      archivedNodeTree: nodes,
    };
  }),
  on(
    ArchivesActions.loadArchivedAssignmentTreeSuccess,
    (state, { nodes }): ArchivesState => {
      return {
        ...state,
        archivedAssignmentTree: nodes,
      };
    },
  ),
  on(
    ArchivesActions.unloadArchiveRequest,
    (state): ArchivesState => {
      return initialArchivesState;
    },
  ),
);

export function archivesReducer(state: ArchivesState, action: Action) {
  return reducer(state, action);
}
