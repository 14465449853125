<button
  class="edit-status btn"
  [ngbTooltip]="statusToolTip"
  [placement]="defaultPlacements"
  triggers="click"
  container="body"
  [autoClose]="'outside'"
>
  <app-workspace-user class="edit-status__avatar" [user]="editedBy" [isActive]="true"
                      [size]="'sm'"></app-workspace-user>
  <span class="ml-2 ap-status-label">
    <ng-container *ngIf="showIcon">
      <i [ngClass]="icon" class="layout-mr-sm"></i>
    </ng-container>
    {{ label }}
    {{ editedDate | niceDate: 'day-only' }}
  </span>
</button>

<ng-template #statusToolTip>
  {{ actionText ? actionText : label }}
  <span class="font-italic">{{ editedDate | niceDate }}</span>
  <app-workspace-user [user]="editedBy" [isActive]="true" [size]="'sm'"></app-workspace-user>
</ng-template>
