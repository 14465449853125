import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { catchError, concatMap, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { ErrorsActions } from '../errors';
import { WorkspaceUrlsActions } from './index';
import { WorkspaceUrlsService } from '../../core/services/workspace-urls.service';
import { selectAssignmentDateRangeWithCurrentSlug } from '../workspace-user-meta/workspace-user-meta.selectors';
import { selectWorkspacesCurrentSlug } from '../workspaces/workspaces.selectors';

@Injectable()
export class WorkspaceUrlsEffects {
  constructor(
    private actions$: Actions,
    private workspaceUrlsService: WorkspaceUrlsService,
    private store: Store,
  ) {}

  loadCurrentWorkspaceUrlsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceUrlsActions.loadCurrentWorkspaceUrlsRequest),
      mergeMap(action =>
        this.workspaceUrlsService.loadWorkspaceUrlsBySlug(action.workspaceSlug).pipe(
          map(urls =>
            WorkspaceUrlsActions.loadCurrentWorkspaceUrlsSuccess({
              urls,
            }),
          ),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  addWorkspaceUrlRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceUrlsActions.addWorkspaceUrlRequest),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      mergeMap(([action, slug]) =>
        this.workspaceUrlsService
          .addWorkspaceUrl(slug, {
            title: action.title,
            url: action.url,
            group: action.groupId,
            sortIndex: action.sortIndex,
          })
          .pipe(
            map(url => {
              return WorkspaceUrlsActions.addWorkspaceUrlSuccess({ url });
            }),
            catchError(error => {
              return of(ErrorsActions.goToErrorPage({ error }));
            }),
          ),
      ),
    ),
  );

  updateWorkspaceUrlRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceUrlsActions.updateWorkspaceUrlRequest),
      mergeMap(action =>
        this.workspaceUrlsService.updateWorkspaceUrl(action.urlId, action.props).pipe(
          map(url => {
            return WorkspaceUrlsActions.updateWorkspaceUrlSuccess({ url });
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  deleteWorkspaceUrlRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceUrlsActions.deleteWorkspaceUrlRequest),
      mergeMap(action =>
        this.workspaceUrlsService.deleteWorkspaceUrl(action.urlId).pipe(
          switchMap(() => {
            return EMPTY;
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  sortWorkspaceUrlsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceUrlsActions.sortWorkspaceUrlsRequest),
      mergeMap(action =>
        this.workspaceUrlsService.sortUrls(action.urlIds).pipe(
          switchMap(() => {
            return EMPTY;
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );
}
