import { ElementRef, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export class LazyScroller {
  public readonly subject: BehaviorSubject<LazyScroller>;
  constructor(public readonly scopeName: string, public element: ElementRef) {
    this.subject = new BehaviorSubject<LazyScroller>(this);
  }
}

interface IScrollerScope {
  [scope: string]: LazyScroller;
}

@Injectable({ providedIn: 'root' })
export class LazyScrollerService implements OnDestroy {
  private readonly DEFAULT_SCOPE: string = '__default';
  private readonly scrollers: IScrollerScope;
  constructor() {
    this.scrollers = {};
  }

  public set(scopeName: string, element: ElementRef) {
    scopeName = scopeName || this.DEFAULT_SCOPE;
    if (this.scrollers[scopeName] == null) {
      this.scrollers[scopeName] = new LazyScroller(scopeName, element);
    } else {
      this.scrollers[scopeName].element = element;
    }
    this.scrollers[scopeName].subject.next(this.scrollers[scopeName]);
  }

  public get(scopeName: string): BehaviorSubject<LazyScroller> {
    scopeName = scopeName || this.DEFAULT_SCOPE;
    if (this.scrollers[scopeName] == null) {
      this.set(scopeName, null); // Always ensure there is a Subject for the requested scope
    }
    return this.scrollers[scopeName].subject;
  }

  public update(scopeName: string): void {
    scopeName = scopeName || this.DEFAULT_SCOPE;
    if (this.scrollers[scopeName] != null) {
      this.scrollers[scopeName].subject.next(this.scrollers[scopeName]);
    }
  }

  public unset(scopeName: string) {
    scopeName = scopeName || this.DEFAULT_SCOPE;
    if (this.scrollers[scopeName]) {
      this.scrollers[scopeName].subject.complete();
      delete this.scrollers[scopeName];
    }
  }

  public ngOnDestroy(): void {
    Object.values(this.scrollers).forEach(s => {
      s.subject.complete();
    });
  }
}
