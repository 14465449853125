import { Component, Input, OnInit } from '@angular/core';
import { WidgetType } from '../../../core/constants/widget-type';
import { NodeTemplateWidgetModel } from '../../../core/models/node-template-widget.model';

@Component({
  selector: 'app-assignment-header-field',
  templateUrl: './assignment-header-field.component.html',
  styleUrls: ['./assignment-header-field.component.scss'],
})
export class AssignmentHeaderFieldComponent implements OnInit {
  public readonly WidgetType = WidgetType;

  @Input()
  widget: NodeTemplateWidgetModel;

  @Input()
  invertColor: boolean;

  constructor() {}

  ngOnInit() {}
}
