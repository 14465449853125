import { Component } from '@angular/core';
import { TableComponent } from '../../table.component';
import { AbstractTableEditableCellComponent } from '../abstract-table-editable-cell.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ColorType } from '../../../../core/constants/color-type';
import { ColorPickerComponent } from '../../../modals/color-picker/color-picker.component';

@Component({
  selector: 'app-z-table-editable-address',
  templateUrl: './table-editable-address.component.html',
  styleUrls: ['./table-editable-address.component.scss'],
})
export class TableEditableAddressComponent extends AbstractTableEditableCellComponent {
  constructor(public table: TableComponent, private modalService: NgbModal) {
    super(table);
  }

  onColorPicker() {
    this.onStartEdit();
    const modelRef = this.modalService.open(ColorPickerComponent, {
      size: 'md',
    });
    modelRef.componentInstance.selected = this.cell.value
      ? ColorType[this.cell.value]
      : ColorType['not-available'];
    modelRef.result
      .then((result?: string) => {
        if (result) {
          this.cell.value = ColorType[result];
          this.onEndEdit();
        }
      })
      .catch(res => {});
  }
}
