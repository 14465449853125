import { Pipe, PipeTransform } from '@angular/core';
import { IconType } from '../../core/constants/icon-type';

@Pipe({
  name: 'iconString',
})
export class IconStringPipe implements PipeTransform {
  transform(value: IconType): any {
    return value ? IconType[value] : IconType[IconType['not-available']];
  }
}
