import { Component, OnInit } from '@angular/core';
import { TableComponent } from '../../table.component';
import { AbstractTableEditableCellComponent } from '../abstract-table-editable-cell.component';
import { NodeModel } from '../../../../core/models/node.model';
import { WidgetType } from '../../../../core/constants/widget-type';
import { defaultPlacements } from '../../../../core/constants/placements';

@Component({
  selector: 'app-z-table-editable-assignment-menu',
  templateUrl: './table-editable-assignment-menu.component.html',
  styleUrls: ['./table-editable-assignment-menu.component.scss'],
})
export class TableEditableAssignmentMenuComponent extends AbstractTableEditableCellComponent
  implements OnInit {
  public readonly WidgetType = WidgetType;
  public readonly defaultPlacements = defaultPlacements;

  public assignment: NodeModel;

  constructor(public table: TableComponent) {
    super(table);
  }
  public ngOnInit(): void {
    super.ngOnInit();
    this.assignment = this.options?.assignment as NodeModel;
  }
}
