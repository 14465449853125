<span
  *ngIf="value?.length > limit"
  class="ellipse-tooltip"
  [ngbTooltip]="value"
  [placement]="defaultPlacements"
  triggers="click"
  container="body"
  [autoClose]="'outside'"
  (click)="onClick($event)"
>...</span
>
