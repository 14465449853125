import { LoaderRoute } from './loader.service';

export const loaderRoutes: LoaderRoute[] = [
  {
    path: /\/auth\/active/g,
    ignore: true,
  },
  {
    path: /\/api\/workspace\/[0-9a-zA-z\-_]+\/chat\/unread-comments/g,
    ignore: true,
  },
  {
    scope: 'workspace-content',
    path: /\/api\/workspace\/[0-9a-zA-z\-_]+\/tree\?archived=1/g,
  },
  {
    scope: 'chat',
    path: /\/api\/workspace\/[0-9a-zA-z\-_]+\/chat\/channels/g,
  },
  {
    scope: 'chat',
    path: /\/api\/workspace\/[0-9a-zA-z\-_]+\/chat\/channel\/add\/[0-9a-zA-z\-_]+/g,
  },
  {
    scope: 'workspace-content',
    path: /\/api\/workspace\/[0-9a-zA-z\-_]+\/tags/g,
  },
  {
    scope: ['modal', ''],
    path: /\/api\/workspace\/[0-9a-zA-z\-_]+\/tag\/add/g,
  },
  {
    scope: 'node-list-panel',
    path: /\/api\/workspace\/[0-9a-zA-z\-_]+\/node\/add/g,
  },
  {
    scope: ['node-edit-panel', ''],
    path: /\/api\/workspace\/[0-9a-zA-z\-_]+\/node\/template\/[0-9]+\/change/g,
  },
  {
    scope: 'workspace-content',
    path: /\/api\/workspace\/[0-9a-zA-z\-_]+\/assignment\/search\?archived=1/g,
  },
  {
    scope: 'workspace-content',
    path: /\/api\/workspace\/[0-9a-zA-z\-_]+\/assignment\/search/g,
  },
  {
    scope: ['modal', ''],
    path: /\/api\/workspace\/node\/[0-9]+\/update/g,
  },
  {
    scope: [''],
    path: /\/api\/workspace\/node\/[0-9]+\/tag\/add\/[0-9]+/g,
  },
  {
    scope: 'modal',
    path: /\/api\/workspace\/node\/[0-9]+\/tag\/[0-9]+\/remove/g,
  },
  {
    scope: ['modal', ''],
    path: /\/api\/workspace\/node\/[0-9]+\/rate\/add/g,
  },
  {
    scope: ['modal', ''],
    path: /\/api\/workspace\/node\/[0-9]+\/rate\/[0-9]+\/remove/g,
  },
  {
    scope: ['modal', ''],
    path: /\/api\/workspace\/node\/rate\/[0-9]+\/update/g,
  },
  {
    scope: 'modal',
    path: /\/api\/workspace\/node\/reference-count/g,
  },
  {
    scope: '',
    path: /\/api\/workspace\/node\/[0-9]+\/archive/g,
  },
  {
    scope: ['modal', ''],
    path: /\/api\/workspace\/node-template\/[0-9]+\/widget\/[0-9]+\/add/g,
  },
  {
    scope: ['modal', ''],
    path: /\/api\/workspace\/node-template\/[0-9]+\/widget\/[0-9]+\/remove/g,
  },
  {
    scope: 'chat',
    path: /\/api\/workspace\/chat\/channel\/[0-9]+\/comments/g,
  },
  {
    scope: ['workspace-content', 'sidebar'],
    path: /\/api\/workspace\/[0-9a-zA-z\-_]+\/refresh\/[0-9a-zA-z\-_]+/g,
  },
  {
    scope: '',
    path: /\/sort/g,
  },
  {
    scope: '',
    path: /\/api\/workspace\/[0-9a-zA-z\-_]+\/assignment\/add\/[0-9]+/g,
  },
];
