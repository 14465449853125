import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { iconTypeKeys } from '../../../core/constants/icon-type';

@Component({
  templateUrl: 'icon-picker.component.html',
  styleUrls: ['icon-picker.component.scss'],
})
export class IconPickerComponent implements OnInit {
  public readonly iconTypeKeys = iconTypeKeys;

  public icons: string[];

  @Input()
  public selected: string;

  public keywords: string;

  constructor(public activeModal: NgbActiveModal) {
    this.icons = iconTypeKeys;
  }

  public ngOnInit() {}

  public onChange(colour: string) {
    this.selected = colour;
  }

  public search(keywords) {
    this.keywords = keywords;
    const lowerCaseKeyword = keywords.toLowerCase();
    this.icons = iconTypeKeys.filter(i => {
      return i.toLowerCase().indexOf(lowerCaseKeyword) > -1;
    });
  }

  public onClose() {
    this.activeModal.close(this.selected);
  }
}
