import { Component, Input, OnInit } from '@angular/core';
import { scaleAnimationFactory } from '../animations/scale.animation';
import { NodeModel } from '../../../core/models/node.model';
import { NodeType } from '../../../core/constants/node-type';

@Component({
  selector: 'app-node-type-icon',
  templateUrl: './node-type-icon.component.html',
  styleUrls: ['./node-type-icon.component.scss'],
  animations: [scaleAnimationFactory({ triggerName: 'animationTrigger' })],
})
export class NodeTypeIconComponent implements OnInit {
  public readonly NodeType = NodeType;

  @Input()
  public node: NodeModel;

  constructor() {}

  ngOnInit(): void {}
}
