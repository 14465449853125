import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar.component';
import { SidebarToggleComponent } from './sidebar-toggle/sidebar-toggle.component';
import { ContentLoaderModule } from '../../content-loader/content-loader.module';
import { SidebarToggleBarComponent } from './sidebar-toggle-bar/sidebar-toggle-bar.component';
import { SidebarHeaderComponent } from './sidebar-header/sidebar-header.component';
import { SidebarFooterComponent } from './sidebar-footer/sidebar-footer.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [CommonModule, ContentLoaderModule, NgbModule],
  declarations: [
    SidebarComponent,
    SidebarHeaderComponent,
    SidebarFooterComponent,
    SidebarToggleComponent,
    SidebarToggleBarComponent,
  ],
  exports: [SidebarComponent, SidebarHeaderComponent, SidebarFooterComponent],
  providers: [],
})
export class SidebarModule {}
