<div
  class="chat-comment"
  *ngIf="comment?.comment || (comment?.uploads)[0]"
  [ngClass]="[fromRight ? 'chat-comment--from-right' : 'chat-comment--from-left']"
>
  <div class="chat-comment__avatar">
    <app-comment-user-avatar
      class="chat-comment__user-avatar"
      [user]="comment?.editedBy"
      [editedDate]="comment?.editedDate"
    ></app-comment-user-avatar>
  </div>

  <div class="chat-comment__details">
    <app-chat-comment-date [comment]="comment" class="chat-comment__date"></app-chat-comment-date>
    <app-chat-comment-bubble
      [comment]="comment"
      [fromRight]="fromRight"
      [selectedCommentForEdit]="selectedCommentForEdit"
    ></app-chat-comment-bubble>
    <app-chat-comment-image
      class="layout-mt chat-comment__image"
      [file]="comment.uploads[0]"
      [comment]="comment"
      [selectedCommentForEdit]="selectedCommentForEdit"
    ></app-chat-comment-image>
  </div>

  <!-- comment actions -->
  <span
    *ngIf="currentUserId === comment?.createdBy?.userId"
    aria-label="Comment actions"
    class="chat-comment__actions"
    role="button"
    [ngbPopover]="actionPopUp"
    triggers="click contextmenu"
    [autoClose]="true"
    container="body"
  >
    <i class="fal fa-ellipsis-vertical"></i
    ></span>

  <ng-template #actionPopUp>
    <ul class="popover-menu">
      <li class="popover-menu__item" (click)="editMessage()">
        <span>{{ selectedCommentForEdit?.id === comment?.id ? 'Cancel edit' : 'Edit' }}</span>
      </li>
      <li class="popover-menu__item" (click)="deleteMessage()">
        <span>Delete</span>
      </li>
    </ul>
  </ng-template>
</div>
