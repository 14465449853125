import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { OverlayPopoverService } from '../overlay-popover/overlay-popover.service';
import { LinkEditPopoverComponent } from '../../../features/workspace/link-edit-popover/link-edit-popover.component';
import { Store } from '@ngrx/store';
import { GenericPopoverActions } from '../../../store/generic-popover';
import { GenericPage } from '../../../core/constants/generic-page';
import { UrlListWidgetModel } from '../../../core/models/url-list-widget.model';
import { Subscription } from 'rxjs';
import { LinkModel } from '../../../core/models/link.model';
import { LinkEditModel } from '../../../core/models/link-edit.model';

@Component({
  selector: 'app-link-list-field',
  templateUrl: './link-list-field.component.html',
  styleUrls: ['./link-list-field.component.scss'],
})
export class LinkListFieldComponent implements OnInit, OnDestroy {
  @Input() widget: UrlListWidgetModel;

  @Output() changeValue: EventEmitter<any> = new EventEmitter();

  subscription = new Subscription();

  constructor(private popoverService: OverlayPopoverService, private store: Store) {}

  ngOnInit(): void {}

  editLink(link: LinkModel, origin, event) {
    event.stopPropagation();
    this._openLinkEditPopover(origin);
    const data: LinkEditModel = {
      page: GenericPage.edit,
      links: this.widget?.value || [],
      selectedLink: link,
    };
    this.store.dispatch(
      GenericPopoverActions.setDataToGenericPopover({
        data,
      }),
    );
  }

  private _openLinkEditPopover(origin) {
    this.subscription.add(
      this.popoverService
        .open<LinkModel[]>({ content: LinkEditPopoverComponent, origin })
        .afterClosed()
        .subscribe(({ type, data }) => {
          if (type === 'updateAndClose') {
            this.changeValue.emit(data);
          }
        }),
    );
  }

  showLinkAddPage(origin) {
    this._openLinkEditPopover(origin);
    const data: LinkEditModel = { page: GenericPage.create, links: this.widget?.value || [] };
    this.store.dispatch(
      GenericPopoverActions.setDataToGenericPopover({
        data,
      }),
    );
  }

  goToLink(url) {
    window.open(url, '_blank');
  }

  trackBy(index, link: LinkModel) {
    return link.value;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
