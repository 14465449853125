import {Component, Input, OnInit} from '@angular/core';
import {UserModel} from '../../../../core/models/user.model';
import {defaultPlacements} from '../../../../core/constants/placements';

@Component({
  selector: 'app-comment-user-avatar',
  templateUrl: './comment-user-avatar.component.html',
  styleUrls: ['./comment-user-avatar.component.scss'],
})
export class CommentUserAvatarComponent implements OnInit {
  @Input() user: UserModel;
  @Input() editedDate: string;
  @Input() avatarSize: string;

  defaultPlacements = defaultPlacements;

  constructor() {
  }

  ngOnInit(): void {
  }
}
