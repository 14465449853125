<div class="popover-subsection" [ngClass]="{'popover-subsection--transparent-background': transparentBackground}">
  <div class="popover-subsection__header">
    <h2 class="popover-subsection__title">{{ title }}</h2>
    <span class="popover-subsection__header-toolbar">
      <ng-container *ngTemplateOutlet="headerToolbarTemplate"></ng-container>
    </span>
  </div>
  <div class="popover-subsection__body">
    <ng-content></ng-content>
  </div>
</div>
