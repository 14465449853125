import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { IconType } from '../../../core/constants/icon-type';

@Component({
  selector: 'app-assignment-menu-row',
  templateUrl: './assignment-menu-row.component.html',
  styleUrls: ['./assignment-menu-row.component.scss'],
})
export class AssignmentMenuRowComponent implements OnInit {
  @Input() createdDate: string;
  @Input() icon: IconType;
  @Input() title: string;
  @Input() toolbarTemplate: TemplateRef<any>;
  @Input() readonly: boolean;

  constructor() {}

  ngOnInit(): void {}
}
