import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { WidgetService } from '../../core/services/widget.service';
import { NodeWidgetRowsActions } from './index';
import { catchError, concatMap, mergeMap } from 'rxjs/operators';
import { ErrorsActions } from '../errors';
import { of } from 'rxjs';
import { NodesActions } from '../nodes';
import { NodeWidgetsActions } from '../node-widgets';
import { NodeGroupsActions } from '../node-groups';

@Injectable({ providedIn: 'root' })
export class NodeWidgetRowsEffects {
  constructor(private actions$: Actions, private widgetService: WidgetService) {}

  updateWidgetRowValueRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NodeWidgetRowsActions.updateWidgetRowValueRequest),
      mergeMap(action =>
        this.widgetService
          .updateNodeWidget(action.nodeId, action.widgetId, {
            value: action.value,
          })
          .pipe(
            concatMap(({ widgetValue, groupValue }) => {
              return [
                NodeWidgetsActions.updateNodeWidgetSuccess({ nodeWidget: widgetValue }),
                NodeGroupsActions.updateNodeGroupSuccess({
                  nodeGroup: groupValue,
                }),
                NodeWidgetRowsActions.updateWidgetRowValueSuccess({
                  nodeId: action.nodeId,
                  widgetId: action.widgetId,
                  rowNumber: widgetValue.rowId,
                  widgetValue,
                  groupValue,
                  referenceId: widgetValue.referenceId,
                }),
                NodesActions.updateNodeEditedByUserDate({
                  nodeIds: [action.nodeId],
                }),
              ];
            }),
            catchError(error => {
              return of(ErrorsActions.goToErrorPage({ error }));
            }),
          ),
      ),
    ),
  );
}
