import { OptionModel } from '../models/option.model';
import { projectsRootFolderName } from './root-folder-names';

export enum GanttChartGroupByType {
  projects = 'projects',
  primaryTags = 'primaryTags',
}

export const ganttChartGroupByOptions: OptionModel[] = [
  {
    id: GanttChartGroupByType.projects,
    label: projectsRootFolderName,
  },
  {
    id: GanttChartGroupByType.primaryTags,
    label: 'Primary Tags',
  },
];
