<!-- <input
  #input
  [type]="editable ? 'date' : 'text'"
  class="z-table__input z-table__input--date"
  [ngClass]="{ 'z-table__input--empty': editable && !cell?.value }"
  [ngModel]="cell.value"
  [disabled]="!editable"
  (focus)="onStartEdit()"
  (blur)="onChange($event)"
  (keyup.enter)="onChange($event)"
  (keyup.escape)="onEscapeKey()"
  (change)="onChange($event)"
  placeholder=" - "
/> -->

<!-- this is hide from the UI -->
<div class="date-picker_form-group--hidden form-group">
  <div class="input-group">
    <input
      name="datepicker"
      class="form-control"
      ngbDatepicker
      [ngModel]="cell.value"
      #datepicker="ngbDatepicker"
      [autoClose]="'outside'"
      (dateSelect)="onDateSelection($event)"
      [container]="'body'"
      [disabled]="!editable"
    />
  </div>
</div>

<div
  class="date-picker_label_with_toggle"
  [ngClass]="{
    'date-picker_label_with_toggle--readonly': !editable
  }"
  (click)="datepicker.toggle()"
>
  {{ cell?.value ? (cell.value | momentDate: 'fullMediumDate') : '-' }}
</div>
