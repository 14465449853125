const dollarToRoundedCents = (dollarValue): number => {
  return Math.round(dollarValue * 100);
};

const centsToDollarStr = (centValue): string => {
  if (centValue === null || centValue === undefined) {
    return null;
  }
  return ((centValue || 0) / 100).toFixed(2);
};

export { dollarToRoundedCents, centsToDollarStr };
