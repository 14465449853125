import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../store';
import {Router} from '@angular/router';
import {selectPropertiesPanelIsOpen} from '../../../store/properties-panel/properties-panel.selectors';
import {WorkspaceUserModel} from '../../../core/models/workspace-user.model';
import {PermissionType} from '../../../core/constants/permission-type';
import {selectLoggedInWorkspaceUser} from '../../../store/workspace-users/workspace-users.selectors';
import {AppLayoutActions, AppLayoutSelectors} from '../../../store/app-layout';
import {SideBarMode} from '../../../shared/components/_layout/sidebar/sidebar.component';
import {AuthActions} from "../../../store/auth";

@Component({
  selector: 'app-root-folder-list',
  templateUrl: 'root-folder-list.component.html',
  styleUrls: ['root-folder-list.component.scss'],
})
export class RootFolderListComponent implements OnInit, OnDestroy {
  private readonly subscription: Subscription = new Subscription();

  public readonly PermissionType = PermissionType;

  public isSidebarOpen: boolean;
  public isContentPanelOpen: boolean;
  public isPropertiesPanelOpen: boolean;
  public isWorkspaceCalendarOpen: boolean;

  public user: WorkspaceUserModel;

  constructor(
    private router: Router,
    private store: Store<AppState>) {
  }

  public ngOnInit() {
    this.subscription.add(
      this.store.pipe(select(AppLayoutSelectors.selectSidebarLeft)).subscribe(sidebar => {
        this.isSidebarOpen = !sidebar.collapsed;
      }),
    );

    this.subscription.add(
      this.store.pipe(select(AppLayoutSelectors.selectSidebarSubLeft)).subscribe(sidebar => {
        this.isContentPanelOpen = !sidebar.collapsed;
      }),
    );

    this.subscription.add(
      this.store.pipe(select(selectPropertiesPanelIsOpen)).subscribe(isOpen => {
        this.isPropertiesPanelOpen = isOpen;
      }),
    );

    this.subscription.add(
      this.store.pipe(select(selectLoggedInWorkspaceUser)).subscribe(user => {
        this.user = user;
      }),
    );

    this.subscription.add(
      this.store.pipe(select(AppLayoutSelectors.selectWorkspaceCalendarOpen)).subscribe(open => {
        this.isWorkspaceCalendarOpen = open;
      }),
    );
  }

  public openSettings(event) {
    this.store.dispatch(
      AppLayoutActions.openSidebarLeft({fullCollapse: true, mode: SideBarMode.Settings}),
    );
    this.store.dispatch(AppLayoutActions.setSidebarSubLeft({collapsed: true, disable: true}));
    // this.router.navigate(['ws', this.workspace.slug, 'content', 'schedule']);
  }

  public logout(event) {
    this.store.dispatch(AuthActions.logoutRequest());
    this.router.navigate(['/public/login']);
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
