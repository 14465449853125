import { NodeModel } from '../../core/models/node.model';

export interface NodeListPanelState {
  selectedNode: NodeModel;
  selectedNodeIds: number[];
  searchKeyword: string;
  filterTagIds: number[];
  filterFolderIds: number[];
  filterTemplateIds: number[];
  filterLayoutTemplateIds: number[];
  filterUnassignedDateKeys: string[];
  isDraggingOfSelected: boolean;
}

export const initialContentPanelState: NodeListPanelState = {
  selectedNode: undefined,
  selectedNodeIds: [],
  searchKeyword: undefined,
  filterTagIds: [],
  filterFolderIds: [],
  filterTemplateIds: [],
  filterLayoutTemplateIds: [],
  filterUnassignedDateKeys: [],
  isDraggingOfSelected: false,
};
