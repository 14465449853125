import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { NodeModel } from '../../../core/models/node.model';
import { NodesActions } from '../../../store/nodes';

@Component({
  selector: 'app-assignment-lock',
  templateUrl: './assignment-lock.component.html',
  styleUrls: ['./assignment-lock.component.scss'],
})
export class AssignmentLockComponent {
  @Input()
  assignment: NodeModel;

  @Input()
  disabled: boolean;

  constructor(private store: Store) {
    this.disabled = false;
  }

  toggleLock(assignment: NodeModel) {
    if (this.disabled === true) {
      return;
    }
    this.store.dispatch(
      NodesActions.updateAssignmentReadonlyRequest({
        nodeId: assignment.id,
        readonly: !assignment.readOnly,
      }),
    );
  }
}
