<div class="assignment-progress" [ngClass]="{'assignment-progress--two-rows': twoRows }">
  <div class="assignment-progress__left-section">
    <div class="assignment-progress__empty-bar">
      <span class="assignment-progress__progress"
            [style.width.%]="progress.progressRate | progressStyleWidth"></span>
    </div>
    <span class="assignment-progress__progress-percentage"
          [ngClass]="{ 'assignment-progress__progress-percentage--positive' : progress.progressRate > 0}">{{ (progress.progressRate > 0) ? (progress.progressRate | percent) : '0%'}}</span>
  </div>
  <div class="assignment-progress__right-section">
    {{progress.completed}} of {{progress.total}} Tasks Complete
  </div>
</div>
