import { Component, OnInit, ViewChild } from '@angular/core';
import { FileUploaderComponent } from '../file-uploader/file-uploader.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { UploadService } from '../../../core/services/upload.service';
import { environment } from '../../../../environments/environment';
import { FileModel } from '../../../core/models/file.model';
import { ErrorsActions } from '../../../store/errors';
import { NodesActions } from '../../../store/nodes';

@Component({
  selector: 'app-node-avatar-upload-modal',
  templateUrl: './node-avatar-upload-modal.component.html',
  styleUrls: ['./node-avatar-upload-modal.component.scss'],
})
export class NodeAvatarUploadModalComponent implements OnInit {
  @ViewChild(FileUploaderComponent, { static: false })
  fileUploader: FileUploaderComponent;

  public profile: FileModel; // passed by component instance
  public nodeId: number;
  public profileIcon: { id: string; src: string };

  isLoading: boolean;

  isUploaderLoading: boolean;

  constructor(
    public activeModal: NgbActiveModal,
    private store: Store,
    private uploadService: UploadService,
  ) {}

  ngOnInit() {}

  uploadFiles(fileBlob: Blob) {
    this.isUploaderLoading = true;
    this.uploadService
      .uploadFiles(
        `${environment.apiBaseUrl}/workspace/node/${this.nodeId}/profile/add`,
        fileBlob,
        `profile-image-${this.nodeId}`,
      )
      .subscribe(
        (files: FileModel[]) => {
          if (files.length && files[0] != null) {
            if (this.profileIcon) {
              this.store.dispatch(
                NodesActions.updateNodeMetaRequest({
                  nodeId: this.nodeId,
                  metaData: { profileIcon: null },
                }),
              );
            }
            this.store.dispatch(
              NodesActions.updateNodeProfileSuccess({
                id: this.nodeId,
                profile: files[0],
              }),
            );
            this.activeModal.close();
          }
          this.isUploaderLoading = false;
        },
        error => {
          this.store.dispatch(ErrorsActions.goToErrorPage({ error: error }));
          this.isUploaderLoading = false;
        },
      );
  }

  savePredefineProfileIcon(item: { id: string; src: string }) {
    if (this.profile) {
      this.store.dispatch(
        NodesActions.removeNodeProfileRequest({
          id: this.nodeId,
        }),
      );
    }
    this.store.dispatch(
      NodesActions.updateNodeMetaRequest({
        nodeId: this.nodeId,
        metaData: { profileIcon: item },
      }),
    );
    this.close();
  }

  close() {
    this.activeModal.close();
  }
}
