import { NodeRateValueModel } from '../../core/models/node-rate-value.model';

export class NodeRateValuesState {
  byId: { [id: number]: NodeRateValueModel };
  byNodeId: { [nodeId: number]: NodeRateValueModel[] };
}

export const initialNodeRateValuesState: NodeRateValuesState = {
  byId: {},
  byNodeId: {},
};
