<ng-template #menuContent>
  <ng-content></ng-content>
</ng-template>

<div class="z-table-row-menu">
  <button type="button" class="btn btn-menu fal fa-bars"
          [ngbPopover]="menuContent"
          [autoClose]="true"
          container="body"></button>
</div>
