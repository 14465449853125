import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutoFocusDirective } from './auto-focus.directive';
import { ClickOutsideDirective } from './click-outside.directive';
import { LazyScrollerElementDirective } from './lazy-scroller/lazy-scroller-element.directive';
import { LazyScrollerElementComponent } from './lazy-scroller/lazy-scroller-element.component';
import { LazyScrollerDirective } from './lazy-scroller/lazy-scroller.directive';
import { InputEmptyToNullDirective } from './input-empty-to-null.directive';

@NgModule({
  declarations: [
    AutoFocusDirective,
    ClickOutsideDirective,
    InputEmptyToNullDirective,
    LazyScrollerDirective,
    LazyScrollerElementDirective,
    LazyScrollerElementComponent,
  ],
  imports: [CommonModule],
  exports: [
    AutoFocusDirective,
    ClickOutsideDirective,
    InputEmptyToNullDirective,
    LazyScrollerDirective,
    LazyScrollerElementDirective,
    LazyScrollerElementComponent,
  ],
})
export class DirectivesModule {}
