import { Component, OnInit } from '@angular/core';
import { TableComponent } from '../../table.component';
import { AbstractTableEditableCellComponent } from '../abstract-table-editable-cell.component';
import { momentDateFormat } from '../../../../shared/pipes/moment-date.pipe';

@Component({
  selector: 'app-z-table-editable-time',
  templateUrl: './table-editable-time.component.html',
  styleUrls: ['./table-editable-time.component.scss'],
})
export class TableEditableTimeComponent extends AbstractTableEditableCellComponent
  implements OnInit {
  constructor(public table: TableComponent) {
    super(table);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.cell.value = this.cell.value || '';
  }

  public onEndEdit(): void {
    const time = momentDateFormat(this.cell.value, 'timeFull');
    // generate current date as YYYY-MM-DD
    const date = this.cell.options?.assignment?.date ?? new Date().toISOString().split('T')[0];
    this.cell.value = `${date} ${time}`;
    super.onEndEdit();
  }
}
