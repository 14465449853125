import { Component } from '@angular/core';
import { TableComponent } from '../../table.component';
import { AbstractTableEditableCellComponent } from '../abstract-table-editable-cell.component';

@Component({
  selector: 'app-z-table-editable-select',
  templateUrl: './table-editable-select.component.html',
  styleUrls: ['./table-editable-select.component.scss'],
})
export class TableEditableSelectComponent extends AbstractTableEditableCellComponent {
  protected defaultOptions = {
    list: [],
  };
  constructor(public table: TableComponent) {
    super(table);
  }
}
