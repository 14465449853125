export const DATE_KEY_FORMAT = 'YYYY-MM-DD';

export const TIME = 'h:mm A';

export const TIME_FULL = 'HH:mm:ss';

export const THIS_YEAR_DATE = 'D MMM';

export const THIS_YEAR_DATE_TIME = 'D MMM [at] h:mm A';

export const DATE_TIME = 'D MMM YYYY [at] h:mm A';

export const SERVER_DATE_TIME = 'YYYY-MM-DD HH:mm:ss';

export const EXTRA_SHORT_DATE = 'D';

export const SHORT_DATE = 'D MMM YY';

export const MEDIUM_DATE = 'D MMM YYYY';
export const DATE_PICKER_DATE = MEDIUM_DATE;

export const LONG_DATE = 'Do MMMM YYYY';

export const FULL_DATE = 'dddd, Do MMMM YYYY';

export const FULL_DATE_SIMPLE = 'ddd, DD/MM/YYYY';

export const FULL_DATE_TIME = 'dddd, Do MMMM YYYY, h:mm A';

export const FULL_DATE_TIME_SIMPLE = 'ddd, DD/MM/YYYY, h:mm A';

export const FULL_MEDIUM_DATE = 'ddd, D MMM YYYY';

export const WEEK_MONTH = 'MMM [W]w';

export const CALENDAR_DISPLAY_DAYS = 7;

export type DateFormatType =
  | 'time'
  | 'timeFull'
  | 'dateTime'
  | 'shortDate'
  | 'mediumDate'
  | 'longDate'
  | 'fullDate'
  | 'fullMediumDate'
  | 'fullDateTime'
  | 'serverDateTime'
  | string;

export const dateFormatMap: { [key in DateFormatType]: string } = {
  time: TIME,
  timeFull: TIME_FULL,
  dateTime: DATE_TIME,
  extraShortDate: EXTRA_SHORT_DATE,
  shortDate: SHORT_DATE,
  mediumDate: MEDIUM_DATE,
  longDate: LONG_DATE,
  fullDate: FULL_DATE,
  fullDateSimple: FULL_DATE_SIMPLE,
  fullMediumDate: FULL_MEDIUM_DATE,
  weekMonth: WEEK_MONTH,
  fullDateTime: FULL_DATE_TIME,
  fullDateTimeSimple: FULL_DATE_TIME_SIMPLE,
  serverDateTime: SERVER_DATE_TIME,
};
