import { Action, createReducer, on } from '@ngrx/store';
import { ErrorsState, initialErrorState } from './errrors.state';
import { ErrorsActions } from './index';

const reducer = createReducer(
  initialErrorState,
  on(ErrorsActions.goToErrorPage, (state, { error }) => {
    const errorMessage = error.error
      ? (error.error.message || error.error.messages)
      : error.message;

    let messages: string[];
    if (Array.isArray(errorMessage)) {
      messages = errorMessage;
    } else {
      messages = [errorMessage];
    }
    return {
      ...state,
      messages,
    };
  }),
);

export function errorsReducer(state: ErrorsState, action: Action) {
  return reducer(state, action);
}
