import { RichSearchItemModel } from '../models/rich-search-item.model';
import { RichSearchType } from '../constants/rich-search-type';

const addSearchItem = (
  item: RichSearchItemModel,
  existingItems: RichSearchItemModel[],
): RichSearchItemModel[] => {
  if (item.type === RichSearchType.keyword) {
    return [item, ...existingItems.filter(item => item.type !== RichSearchType.keyword)];
  } else {
    return [
      ...existingItems.filter(
        selectedItem =>
          selectedItem.type !== item.type || selectedItem.entity.id !== item.entity.id,
      ),
      item,
    ];
  }
};

const removeKeyboardSearchItem = (existingItems: RichSearchItemModel[]): RichSearchItemModel[] => {
  const changedItems = [...existingItems.filter(item => item.type !== RichSearchType.keyword)];
  return changedItems;
};

const getRichSearchTypeLabel = (type: RichSearchType): string => {
  if (type === RichSearchType.keyword) {
    return '';
  } else if (type === RichSearchType.tag) {
    return 'tag';
  } else if (type === RichSearchType.folder) {
    return 'folder';
  } else if (type === RichSearchType.template) {
    return 'template';
  } else if (type === RichSearchType.layout) {
    return 'layout';
  } else if (type === RichSearchType.unassignedDate) {
    return 'unassigned';
  }
  return '';
};

export { addSearchItem, removeKeyboardSearchItem, getRichSearchTypeLabel };
