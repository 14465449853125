import { createAction, props } from '@ngrx/store';
import { ISideBar, IWorkspaceContent } from './app-layout.state';

export const setWorkspaceContent = createAction(
  '[AppLayout] set workspace content',
  props<IWorkspaceContent>(),
);

export const setSidebarLeft = createAction('[AppLayout] set sidebar left', props<ISideBar>());
export const setSidebarLeftCollapsed = createAction(
  '[AppLayout] set sidebar left collapsed',
  props<{ collapsed: boolean }>(),
);
export const openSidebarLeft = createAction('[AppLayout] open sidebar left', props<ISideBar>());
export const closeSidebarLeft = createAction('[AppLayout] close sidebar left');

export const setSidebarSubLeft = createAction(
  '[AppLayout] set sidebar sub left',
  props<ISideBar>(),
);
export const openSidebarSubLeft = createAction(
  '[AppLayout] open sidebar sub left',
  props<ISideBar>(),
);
export const closeSidebarSubLeft = createAction('[AppLayout] close sidebar sub left');

export const setSidebarRight = createAction('[AppLayout] set sidebar right', props<ISideBar>());
export const setSidebarRightCollapsed = createAction(
  '[AppLayout] set sidebar right collapsed',
  props<{ collapsed: boolean }>(),
);
export const openSidebarRight = createAction('[AppLayout] open sidebar right', props<ISideBar>());
export const closeSidebarRight = createAction('[AppLayout] close sidebar right');

export const setSidebarSubRight = createAction(
  '[AppLayout] set sidebar sub right',
  props<ISideBar>(),
);
export const openSidebarSubRight = createAction(
  '[AppLayout] open sidebar sub right',
  props<ISideBar>(),
);
export const closeSidebarSubRight = createAction('[AppLayout] close sidebar sub right');

export const setWorkspaceCalendarOpen = createAction(
  '[AppLayout] set workspace schedule-layout open',
  props<{ open: boolean }>(),
);
