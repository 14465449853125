<li class="folder-list-item" *ngIf="folder">
  <div class="folder-list-item__header">
    <h2 class="folder-list-item__title" (click)="switchFolder()">
      <i
        class="fa-solid layout-mr-sm"
        [ngClass]="[
          folder.nodeType === NodeType.projectFolder ? 'fa-folder' : 'fa-folder-gear',
          folder.colorTheme ? 'color--' + (folder.colorTheme | colorString) : ''
        ]"
      ></i>
      {{ folder.title }}
    </h2>

    <div class="folder-list-item__info">
      <span class="folder-list-item__info-badge badge badge-pill badge-secondary">
        {{ folder.children.length }}
      </span>
    </div>
  </div>
</li>
