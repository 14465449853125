import { NodeEditableProps } from '../../core/models/node.model';

export interface PropertiesPanelState {
  isOpen: boolean;
  nodeId: number;
  initialFocus: keyof NodeEditableProps;
}

export const initialPropertiesPanelState: PropertiesPanelState = {
  isOpen: false,
  nodeId: undefined,
  initialFocus: undefined,
};
