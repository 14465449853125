import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { ErrorsActions } from '../errors';
import { NodeTemplateService } from '../../core/services/node-template.service';
import { TemplatesActions } from './index';
import { TemplatesPopoverActions } from '../templates-popover';
import { select, Store } from '@ngrx/store';
import { WorkspaceGroupsActions } from '../workspace-groups';
import { TemplateWidgetsActions } from '../template-widgets';
import { NodeType } from '../../core/constants/node-type';
import { TemplateTagsActions } from '../template-tags';
import { WidgetsActions } from '../widgets';
import { selectWorkspacesCurrentSlug } from '../workspaces/workspaces.selectors';
import { NodeTemplateModel } from '../../core/models/node-template.model';
// import { NodesActions, NodesSelectors } from '../nodes';
// import { NodeModel } from '../../core/models/node.model';

@Injectable()
export class TemplatesEffects {
  constructor(
    private actions$: Actions,
    private nodeTemplateService: NodeTemplateService,
    private store: Store,
  ) {}

  addNodeTemplateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TemplatesActions.addNodeTemplateSuccess),
      concatMap(action => [
        WorkspaceGroupsActions.addWorkspaceGroupsSuccess({ groups: action.template.groups }),
        TemplateTagsActions.addTemplateTagsSuccess({
          templateTags: action.template.tags,
          groupId: null,
          replace: false,
        }),
        TemplateWidgetsActions.addTemplateWidgetsSuccess({
          templateWidgets: action.template.widgets,
          groupId: null,
          replace: false,
        }),
      ]),
      catchError(error => {
        return of(ErrorsActions.goToErrorPage({ error }));
      }),
    ),
  );

  addNodeTemplatesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TemplatesActions.addNodeTemplatesSuccess),
      concatMap(action =>
        action.templates.map(template => {
          return TemplatesActions.addNodeTemplateSuccess({ template });
        }),
      ),
      catchError(error => {
        return of(ErrorsActions.goToErrorPage({ error }));
      }),
    ),
  );

  refreshNodeTemplatesRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TemplatesActions.refreshNodeTemplatesRequest),
      mergeMap(action =>
        this.nodeTemplateService.loadNodeTemplates(action.workspaceSlug).pipe(
          switchMap(serverNodeTemplates => {
            return of(
              TemplatesActions.refreshNodeTemplatesSuccess({ nodeTemplates: serverNodeTemplates }),
            );
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  loadNodeTemplatesRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TemplatesActions.loadNodeTemplatesRequest),
      mergeMap(action =>
        this.nodeTemplateService.loadNodeTemplates(action.workspaceSlug, action.nodeTypes).pipe(
          switchMap(serverNodeTemplates => {
            return of(TemplatesActions.addNodeTemplatesSuccess({ templates: serverNodeTemplates }));
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  addNodeTemplateRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TemplatesActions.addNodeTemplateRequest),
      mergeMap(action =>
        this.nodeTemplateService
          .addNodeTemplate(action.workspaceSlug, action.title, action.serverNodeType)
          .pipe(
            switchMap((template: NodeTemplateModel) => {
              return of(
                TemplatesActions.addNodeTemplateSuccess({ template }),
                TemplatesPopoverActions.selectTemplatePopoverTemplate({
                  templateId: template.id,
                }),
              );
            }),
            catchError(error => {
              return of(ErrorsActions.goToErrorPage({ error }));
            }),
          ),
      ),
    ),
  );

  // addNodeTemplateRequest$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(TemplatesActions.addNodeTemplateRequest),
  //     concatMap(action =>
  //       of(action).pipe(withLatestFrom(this.store.pipe(select(NodesSelectors.selectRootNodes)))),
  //     ),
  //     // NodesSelectors.selectRootNodes
  //     mergeMap(([action, nodes]) =>
  //       this.nodeTemplateService
  //         .addNodeTemplate(action.workspaceSlug, action.title, action.serverNodeType)
  //         .pipe(
  //           switchMap((template: NodeTemplateModel) => {
  //             // Don't generate folders for Assignment Layouts
  //             if (template.nodeType === NodeType.element) {
  //               return of(
  //                 TemplatesActions.addNodeTemplateSuccess({ template }),
  //                 TemplatesPopoverActions.selectTemplatePopoverTemplate({
  //                   templateId: template.id,
  //                 }),
  //                 NodesActions.addNodeRequest({
  //                   parentNode: (nodes as NodeModel[])?.find(
  //                     x => x?.nodeType === NodeType.elementFolder,
  //                   ),
  //                   nodeTemplate: template,
  //                   nodeType: action.serverNodeType,
  //                   title: `${action.title} (Blank)`,
  //                   disableOpenPropertyPanel: true,
  //                 }),
  //               );
  //             }
  //             return of(
  //               TemplatesActions.addNodeTemplateSuccess({ template }),
  //               TemplatesPopoverActions.selectTemplatePopoverTemplate({
  //                 templateId: template.id,
  //               }),
  //             );
  //           }),
  //           catchError(error => {
  //             return of(ErrorsActions.goToErrorPage({ error }));
  //           }),
  //         ),
  //     ),
  //   ),
  // );

  copyNodeTemplateRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TemplatesActions.copyNodeTemplateRequest),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      mergeMap(([action, workspaceSlug]) =>
        this.nodeTemplateService.copyNodeTemplate(action.templateId).pipe(
          concatMap((template: NodeTemplateModel) => [
            WidgetsActions.loadWidgetsRequest({ workspaceSlug }),
            TemplatesActions.addNodeTemplateSuccess({ template }),
            TemplatesPopoverActions.selectTemplatePopoverTemplate({
              templateId: template.id,
            }),
          ]),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  updateNodeTemplateRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TemplatesActions.updateNodeTemplateRequest),
      mergeMap(action =>
        this.nodeTemplateService.updateNodeTemplate(action.templateId, action.templateProps).pipe(
          switchMap(() => {
            return EMPTY;
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  setDefaultNodeTemplateRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TemplatesActions.setDefaultNodeTemplateRequest),
      mergeMap(action =>
        this.nodeTemplateService.setDefaultNodeTemplate(action.templateId).pipe(
          switchMap(() => {
            return EMPTY;
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  archiveNodeTemplateRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TemplatesActions.archiveNodeTemplateRequest),
      mergeMap(action =>
        this.nodeTemplateService.archiveNodeTemplate(action.templateId).pipe(
          switchMap(() => {
            return EMPTY;
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  deleteNodeTemplateRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TemplatesActions.deleteNodeTemplateRequest),
      mergeMap(action =>
        this.nodeTemplateService.removeNodeTemplate(action.templateId).pipe(
          switchMap(() => {
            return EMPTY;
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  sortNodeTemplatesRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TemplatesActions.sortNodeTemplatesRequest),
      mergeMap(action =>
        this.nodeTemplateService.sortNodeTemplates(action.templateIds).pipe(
          switchMap(() => {
            return EMPTY;
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );
}
