import { Component } from '@angular/core';
import { TableComponent } from '../../table.component';
import { AbstractTableEditableCellComponent } from '../abstract-table-editable-cell.component';
import { NodeTemplateModel } from '../../../../core/models/node-template.model';
import { NodeType } from '../../../../core/constants/node-type';
import { defaultPlacements } from '../../../../core/constants/placements';

@Component({
  selector: 'app-z-table-editable-asset-profile',
  templateUrl: './table-editable-asset-profile.component.html',
  styleUrls: ['./table-editable-asset-profile.component.scss'],
})
export class TableEditableAssetProfileComponent extends AbstractTableEditableCellComponent {
  public readonly NodeType = NodeType;
  public readonly MAX_DISPLAY = 5;
  public readonly defaultPlacements = defaultPlacements;

  constructor(public table: TableComponent) {
    super(table);
  }

  trackBy(item: NodeTemplateModel) {
    return item.id;
  }
}
