import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  WorkspaceUserGroupEditableProps,
  WorkspaceUserGroupModel,
} from '../models/workspace-user-group.model';
import { ResponseMessage } from '../models/response-message.model';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class WorkspaceUserGroupsService {
  constructor(private http: HttpClient) {}

  public loadWorkspaceUserGroupsBySlug(
    workspaceSlug: string,
  ): Observable<WorkspaceUserGroupModel[]> {
    return this.http
      .get<ResponseMessage<WorkspaceUserGroupModel[]>>(
        `${environment.apiBaseUrl}/workspace/${workspaceSlug}/user-groups`,
      )
      .pipe(map(response => response.data));
  }

  public addWorkspaceUserGroup(
    workspaceSlug: string,
    props: Partial<WorkspaceUserGroupEditableProps>,
  ): Observable<WorkspaceUserGroupModel> {
    return this.http
      .post<ResponseMessage<WorkspaceUserGroupModel>>(
        `${environment.apiBaseUrl}/workspace/${workspaceSlug}/user-group/add`,
        {
          ...props,
        },
      )
      .pipe(map(response => response.data));
  }

  public updateWorkspaceUserGroup(
    groupId: number,
    props: Partial<WorkspaceUserGroupEditableProps>,
  ): Observable<WorkspaceUserGroupModel> {
    return this.http
      .patch<ResponseMessage<WorkspaceUserGroupModel>>(
        `${environment.apiBaseUrl}/workspace/user-group/${groupId}/update`,
        {
          ...props,
        },
      )
      .pipe(map(response => response.data));
  }

  public deleteWorkspaceUserGroup(groupId: number): Observable<object> {
    return this.http.delete(`${environment.apiBaseUrl}/workspace/user-group/${groupId}/remove`);
  }
}
