import { createAction, props } from '@ngrx/store';
import { ChatChannelCommentModel } from '../../core/models/chat-channel-comment.model';

export const loadUnreadCommentsRequest = createAction('[Chats] load unread comments request');

export const loadUnreadCommentsSuccess = createAction(
  '[Chats] load unread comments success',
  props<{ comments: ChatChannelCommentModel[] }>(),
);

export const markChannelAsReadRequest = createAction(
  '[Chats] mark channel as read request',
  props<{ channelId: number }>(),
);

export const loadChannelCommentsRequest = createAction(
  '[Chats] load channel comments request',
  props<{ channelId: number }>(),
);

export const loadChannelCommentsSuccess = createAction(
  '[Chats] load channel comments success',
  props<{ comments: ChatChannelCommentModel[] }>(),
);

export const addCommentToChannelRequest = createAction(
  '[Chats] add comment to channel request',
  props<{ channelId: number; comment: string }>(),
);

export const addCommentToChannelSuccess = createAction(
  '[Chats] add comment to channel success',
  props<{ comment: ChatChannelCommentModel }>(),
);

export const addAttachmentToChannelRequest = createAction(
  '[Chats] add attachment to channel request',
  props<{ channelId: number; file: File; comment: string }>(),
);

export const addAttachmentToChannelSuccess = createAction(
  '[Chats] add attachment to channel success',
  props<{ comment: ChatChannelCommentModel }>(),
);

export const updateCommentInChannelRequest = createAction(
  '[Chats] update comment to channel request',
  props<{ channelId: number; commentId: string; comment: string }>(),
);

export const updateCommentInChannelSuccess = createAction(
  '[Chats] update comment to channel success',
  props<{ comment: ChatChannelCommentModel }>(),
);

export const updateAttachmentToChannelRequest = createAction(
  '[Chats] update attachment to channel request',
  props<{ channelId: number; commentId: string; file: File; comment: string }>(),
);

export const updateAttachmentToChannelSuccess = createAction(
  '[Chats] update attachment to channel success',
  props<{ comment: ChatChannelCommentModel }>(),
);

export const deleteCommentInChannelRequest = createAction(
  '[Chats] delete comment in channel request',
  props<{ channelId: number; commentId: string }>(),
);

export const deleteCommentInChannelSuccess = createAction(
  '[Chats] delete comment in channel success',
  props<{ channelId: number; commentId: string }>(),
);

export const deleteNodeChannelRequest = createAction(
  '[Chats] delete node channel request',
  props<{ channelId: number }>(),
);

export const deleteNodeChannelSuccess = createAction(
  '[Chats] delete node channel success',
  props<{ channelId: number }>(),
);
