import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ResponseMessage } from '../models/response-message.model';
import { map } from 'rxjs/operators';
import { NodeTemplateWidgetModel } from '../models/node-template-widget.model';
import { WidgetType } from '../constants/widget-type';
import { NodeType } from '../constants/node-type';
import { CalculationMethodType } from '../constants/calculation-method.type';
import { WidgetModel } from '../models/widget.model';
import { WorkspaceGroupModel } from '../models/workspace-group.model';

@Injectable({ providedIn: 'root' })
export class TemplateWidgetService {
  constructor(private http: HttpClient) {}

  public addTemplateWidgets(
    templateId: number,
    widgetIds: number[],
    groupId?: number,
    replace?: boolean,
  ): Observable<NodeTemplateWidgetModel[]> {
    return this.http
      .post<ResponseMessage<NodeTemplateWidgetModel[]>>(
        `${environment.apiBaseUrl}/workspace/node-template/${templateId}/add-widgets`,
        {
          widgets: widgetIds,
          group: groupId,
          replace,
        },
      )
      .pipe(map(response => response.data));
  }

  public createNewTemplateGroupWidget(
    templateId: number,
    props: {
      title: string;
      widgetType: WidgetType;
      nodeType: NodeType;
      group: number;
      choices?: string[];
    },
  ): Observable<NodeTemplateWidgetModel> {
    return this.http
      .post<ResponseMessage<NodeTemplateWidgetModel>>(
        `${environment.apiBaseUrl}/workspace/node-template/${templateId}/widget/add`,
        {
          ...props,
          calculationMethod: 0, // temp fix of api error
        },
      )
      .pipe(map(response => response.data));
  }

  public createNewCalculationWidget(
    templateId: number,
    props: {
      title: string;
      widgetATitle: string;
      widgetAType: WidgetType;
      widgetBTitle: string;
      widgetBType: WidgetType;
      calculationMethod: CalculationMethodType;
      sortIndex?: number;
    },
  ): Observable<
    {
      widget: WidgetModel;
      widgetA: WidgetModel;
      widgetB: WidgetModel;
      group: WorkspaceGroupModel;
    } & NodeTemplateWidgetModel
  > {
    return this.http
      .post<ResponseMessage<any>>(
        `${environment.apiBaseUrl}/workspace/node-template/${templateId}/calculation-widget/add`,
        {
          ...props,
        },
      )
      .pipe(map(response => response.data));
  }

  public removeTemplateWidget(
    templateId: number,
    widgetId: number,
  ): Observable<ResponseMessage<any>> {
    return this.http.delete<ResponseMessage<any>>(
      `${environment.apiBaseUrl}/workspace/node-template/${templateId}/widget/${widgetId}/remove`,
    );
  }

  public archiveTemplateWidget(
    templateId: number,
    widgetId: number,
  ): Observable<ResponseMessage<any>> {
    return this.http.delete<ResponseMessage<any>>(
      `${environment.apiBaseUrl}/workspace/node-template/${templateId}/widget/${widgetId}/archive`,
    );
  }

  public sortTemplateWidgets(templateId: number, widgetIds: number[]): Observable<any> {
    return this.http
      .post<ResponseMessage<any>>(
        `${environment.apiBaseUrl}/workspace/node-template/${templateId}/widget/sort`,
        { ids: widgetIds },
      )
      .pipe(map(response => response.data));
  }
}
