import { Component, Input, OnInit } from '@angular/core';
import { defaultPlacements } from '../../../core/constants/placements';

@Component({
  selector: 'app-btn-tooltip',
  templateUrl: './btn-tooltip.component.html',
  styleUrls: ['./btn-tooltip.component.scss'],
})
export class BtnTooltipComponent implements OnInit {
  @Input() text: string;

  defaultPlacements = defaultPlacements;

  constructor() {}

  ngOnInit(): void {}
}
