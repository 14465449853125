import { AppState } from '../index';
import { createSelector } from '@ngrx/store';
import { WidgetType } from '../../core/constants/widget-type';

export const selectWidgetTypesState = (state: AppState) => state.widgetTypes;

export const selectWidgetTypes = createSelector(selectWidgetTypesState, state => state.widgetTypes);

export const selectAvailableWidgetTypes = createSelector(selectWidgetTypes, widgetTypes =>
  widgetTypes.filter(
    type =>
      [
        WidgetType.textbox,
        WidgetType.textarea,
        WidgetType.email,
        WidgetType.phone,
        WidgetType.url,
        WidgetType.urlList,
        WidgetType.date,
        WidgetType.number,
        WidgetType.currency,
        WidgetType.checkbox,
        WidgetType.address,
        WidgetType.dateTime,
        WidgetType.time,
        WidgetType.projectList,
        WidgetType.assetList,
        WidgetType.heading,
        WidgetType.lineSeparator,
        WidgetType.subHeading,
        WidgetType.paragraph,
      ].indexOf(type.id as number) >= 0,
  ),
);
