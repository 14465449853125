<button
  type="button"
  class="btn-tooltip btn"
  [placement]="defaultPlacements"
  [ngbTooltip]="text"
  triggers="click"
  [autoClose]="'outside'"
>
  <app-icon-info></app-icon-info>
</button>
