import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { DATE_KEY_FORMAT, DATE_PICKER_DATE } from '../constants/date-format.constants';
import { ngbDateUtil } from '../utils/ngb-date.util';

@Injectable({
  providedIn: 'root',
})
export class CustomDateParserFormatterService extends NgbDateParserFormatter {
  parse(value: string): NgbDateStruct | null {
    if (value) {
      const momentDate = moment(value, DATE_PICKER_DATE);
      const ngbDate = ngbDateUtil.convertToNgbDate(momentDate.format(DATE_KEY_FORMAT));
      return ngbDate;
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? ngbDateUtil.covertToDatePickerDateStr(date) : '';
  }
}
