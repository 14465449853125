import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { ResponseMessage } from '../models/response-message.model';
import { LayoutTemplateModel, LayoutTemplatePayload } from '../models/layout-template.model';

@Injectable({ providedIn: 'root' })
export class LayoutTemplateService {
  constructor(private http: HttpClient) {}

  public loadLayoutTemplates(slug: string): Observable<LayoutTemplateModel[]> {
    return this.http
      .get<ResponseMessage<LayoutTemplateModel[]>>(
        `${environment.apiBaseUrl}/workspace/${slug}/layout-templates/all`,
      )
      .pipe(map(response => response.data as LayoutTemplateModel[]));
  }

  public createLayoutTemplate(
    layoutTemplateId: number,
    payload: LayoutTemplatePayload,
  ): Observable<LayoutTemplateModel> {
    const payloadE = {
      title: payload?.title,
      tooltip: payload?.tooltip,
      sortIndex: payload?.sortIndex,
      icon: payload?.metaData?.icon,
      colorTheme: payload?.metaData?.colorTheme,
    };
    return this.http
      .post<ResponseMessage<LayoutTemplateModel>>(
        `${environment.apiBaseUrl}/workspace/layout-template/${layoutTemplateId}/create`,
        payloadE,
      )
      .pipe(map(response => response.data));
  }

  public updateLayoutTemplate(
    layoutTemplateId: number,
    payload: LayoutTemplatePayload,
  ): Observable<LayoutTemplateModel> {
    return this.http
      .put<ResponseMessage<LayoutTemplateModel>>(
        `${environment.apiBaseUrl}/workspace/layout-template/${layoutTemplateId}/update`,
        payload,
      )
      .pipe(map(response => response.data));
  }

  public updateLayoutTemplateMeta(
    layoutTemplateId: number,
    payload: LayoutTemplatePayload,
  ): Observable<LayoutTemplateModel> {
    const payloadE = {
      icon: payload?.metaData?.icon,
      colorTheme: payload?.metaData?.colorTheme,
      style: payload?.metaData?.style,
    };
    return this.http
      .put<ResponseMessage<LayoutTemplateModel>>(
        `${environment.apiBaseUrl}/workspace/layout-template/${layoutTemplateId}/meta/update`,
        payloadE,
      )
      .pipe(map(response => response.data));
  }

  public installLayoutTemplate(slug: string, layoutTemplateId: number) {
    return this.http.put(
      `${environment.apiBaseUrl}/workspace/${slug}/layout-template/${layoutTemplateId}/install`,
      {},
    );
  }

  public removeLayoutTemplate(layoutTemplateId: number): Observable<object> {
    return this.http.delete(
      `${environment.apiBaseUrl}/workspace/layout-template/${layoutTemplateId}/remove`,
    );
  }
}
