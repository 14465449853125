<ul class="popover-menu">
  <ng-container [ngSwitch]="node.nodeType">
    <ng-container *ngSwitchCase="NodeType.folderShortcut">

      <li class="popover-menu__info-title popover-menu__info-title--primary">
        Shortcut Options</li>

      <li class="popover-menu__item" (click)="openNodeListPanel()">
        <i class="fal fa-folder-open layout-mr-lg popover_icon_style"></i>
        Open</li>

      <li
        *ngIf="loggedInUser | can: PermissionType.projectEditor"
        class="popover-menu__item"
        (click)="moveFolder()"
      >
        <i class="fal fa-up-down-left-right layout-mr-lg popover_icon_style"></i>
        Move
      </li>

      <li
        *ngIf="loggedInUser | can: PermissionType.projectEditor"
        class="popover-menu__item"
        (click)="archiveFolder()"
      >
        <i class="fal fa-remove layout-mr-lg popover_icon_style"></i>
        Remove Shortcut
      </li>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <li class="popover-menu__info-title popover-menu__info-title--primary">
        Folder Options
      </li>

      <li class="popover-menu__item" (click)="openNodeListPanel()">
        <i class="fal fa-folder-open layout-mr-lg popover_icon_style"></i>
        Open
      </li>

      <li
        *ngIf="!isRootFolder && loggedInUser | can: PermissionType.projectEditor"
        class="popover-menu__item"
        (click)="moveFolder()"
      >
        <i class="fal fa-up-down-left-right layout-mr-lg popover_icon_style"></i>
        Move
      </li>

      <li
        *ngIf="!isRootFolder && loggedInUser | can: PermissionType.projectEditor"
        class="popover-menu__item"
        (click)="addShortcut()"
      >
        <i class="fal fa-share layout-mr-lg popover_icon_style"></i>
        Make Shortcut
      </li>

      <li
        *ngIf="loggedInUser | can: PermissionType.projectEditor"
        class="popover-menu__item"
        (click)="editProperties()"
      >
        <i class="fal fa-edit layout-mr-lg popover_icon_style"></i>
        Edit Properties
      </li>

            <li
        *ngIf="loggedInUser | can: PermissionType.projectEditor"
        class="popover-menu__item"
        (click)="addFolder()"
      >
        <i class="fal fa-folder-plus layout-mr-lg popover_icon_style"></i>
        New Folder
      </li>
      <li
        *ngIf="loggedInUser | can: PermissionType.projectEditor"
        class="popover-menu__item"
        (click)="addLeaf()"
      >
        <i class="fal fa-add layout-mr-lg popover_icon_style"></i>
        New {{ childNodeType | nodeTypeLabel }}
      </li>

      <ng-container *ngIf="(loggedInUser | can: PermissionType.projectEditor) && isRootFolder">
        <li class="popover-menu__divider"></li>
        <li class="popover-menu__item" (click)="importExport()">
          <i class="fal fa-file-export layout-mr-lg popover_icon_style"></i>
          Import/Export
        </li>
      </ng-container>

      <ng-container *ngIf="!isRootFolder">
        <li class="popover-menu__divider"></li>
        <li
          class="popover-menu__item"
          (click)="archiveFolder()"
          *ngIf="loggedInUser | can: PermissionType.projectEditor"
        >
          <i class="fal fa-archive layout-mr-lg popover_icon_style"></i>
          Archive
        </li>
      </ng-container>
    </ng-container>
  </ng-container>
</ul>
