import { createAction, props } from '@ngrx/store';
import { NodeEditableProps, NodeModel } from '../../core/models/node.model';
import { NodeType } from '../../core/constants/node-type';
import { FileModel } from '../../core/models/file.model';
import { NodeTemplateModel } from '../../core/models/node-template.model';
import { TagType } from '../../core/constants/tag-type';
import { NodeTagModel } from '../../core/models/node-tag.model';
import { NoteType } from '../../core/constants/note-type';
import { NoteModel } from '../../core/models/note.model';

/*
 * @deprecated: DO NOT USE. Once a workspace gets large this API request will crash the browser
 */
// export const loadNodeTreeRequest = createAction(
//   '[Nodes] load node tree request',
//   props<{ slug: string }>(),
// );

export const loadNodesSuccess = createAction(
  '[Nodes] load node tree success',
  props<{ nodes: NodeModel[]; clearState?: boolean }>(),
);

export const unloadNodesRequest = createAction('[Nodes] unload node tree request');

export const addFolderShortcutRequest = createAction(
  '[Nodes] add folder shortcut request',
  props<{
    sourceId: number;
    targetId: number;
  }>(),
);

export const addFolderShortcutSuccess = createAction(
  '[Nodes] add folder shortcut success',
  props<{ node: NodeModel }>(),
);

export const addNodeRequest = createAction(
  '[Nodes] add node request',
  props<{
    nodeType: NodeType;
    title?: string;
    parentNode?: NodeModel;
    nodeTemplate?: NodeTemplateModel;
    // disableOpenPropertyPanel?: boolean;
  }>(),
);

export const addMultipleNodesSuccess = createAction(
  '[Nodes] add multiple nodes success',
  props<{ nodes: NodeModel[] }>(),
);

export const updateNodeProfileSuccess = createAction(
  '[Nodes] update node profile success',
  props<{
    id: number;
    profile: FileModel;
  }>(),
);

export const updateNodeMetaRequest = createAction(
  '[Nodes] update node meta request',
  props<{ nodeId: number; metaData: object }>(),
);

export const updateNodeMetaSuccess = createAction(
  '[Nodes] update node meta success',
  props<{ nodeId: number; nodeMeta: object }>(),
);

export const removeNodeProfileRequest = createAction(
  '[NodeTree - Nodes] remove node profile request',
  props<{ id: number }>(),
);

export const removeNodeProfileSuccess = createAction(
  '[NodeTree - Nodes] remove node profile success',
  props<{ node: NodeModel }>(),
);

export const updateNodeRequest = createAction(
  '[Nodes] update node request',
  props<{ nodeId: number; nodeProps: Partial<NodeEditableProps> }>(),
);

export const applyTemplateToNodesRequest = createAction(
  '[Nodes] apply nodes template request',
  props<{ nodeIds: number[]; nodeTemplateId: number }>(),
);

export const removeNodeRequest = createAction(
  '[Nodes] remove node request',
  props<{ node: NodeModel }>(),
);

export const archiveNodesRequest = createAction(
  '[Nodes] archive nodes request',
  props<{ nodeIds: number[] }>(),
);

export const archiveNodesSuccess = createAction(
  '[Nodes] archive nodes success',
  props<{ nodeIds: number[] }>(),
);

export const moveNodesRequest = createAction(
  '[Nodes] nodes move  request',
  props<{
    nodeIds: number[];
    toParentId: number;
    sortIndex: number;
  }>(),
);

export const sortNodesRequest = createAction(
  '[Nodes] sort nodes request',
  props<{ nodeIds: number[] }>(),
);

export const updateSearchKeywordRequest = createAction(
  '[Nodes] update search keyword request',
  props<{ searchKeyword: string }>(),
);

export const addNodeNoteRequest = createAction(
  '[Nodes] add node note request',
  props<{ nodeId: number; note: string; noteType: NoteType }>(),
);

export const addNodeNoteSuccess = createAction(
  '[Nodes] add node note success',
  props<{ nodeId: number; note: NoteModel }>(),
);

export const updateNodeEditedByUserDate = createAction(
  '[NodeTree - Nodes] update edited by user and date',
  props<{ nodeIds: number[] }>(),
);

export const updateNodeEditedByUserDateSuccess = createAction(
  '[NodeTree - Nodes] update edited by user and date success',
  props<{ nodes: NodeModel[]; editedDate: string; editedBy }>(),
);

/**
 * New Assignments store design
 */
export const loadAssignmentsByProjectsRequest = createAction(
  '[Assignments] load assignments by projects request',
  props<{ projects?: NodeModel[]; includeWidgets?: boolean }>(),
);

export const loadAssignmentsByDateRangeRequest = createAction(
  '[Assignments] load assignments by date range request',
  props<{
    startDate?: string;
    endDate?: string;
    includeWidgets?: boolean;
    nullDate?: boolean;
  }>(),
);

export const loadAssignmentsSuccess = createAction(
  '[Assignments] load assignments success',
  props<{ nodes: NodeModel[] }>(),
);

export const unloadAssignmentsRequest = createAction('[Assignments] unload assignments request');

export const sortAssignmentsRequest = createAction(
  '[Assignments] sort assignments request',
  props<{ nodeIds: number[] }>(),
);

export const moveAssignmentsRequest = createAction(
  '[Assignments] move assignments between dates request',
  props<{
    assignmentIds: number[];
    toDateKey: string;
    sortIndex?: number;
  }>(),
);

export const addMultipleAssignmentsRequest = createAction(
  '[Assignments] add multiple assignments request',
  props<{ nodeIds: number[]; dateKeys: string[] }>(),
);

export const addMultipleAssignmentsSuccess = createAction(
  '[Assignments] add multiple assignments success',
  props<{ nodes: NodeModel[] }>(),
);

export const removeMultipleAssignments = createAction(
  '[Assignments] remove multiple assignments request',
  props<{ nodes: NodeModel[] }>(),
);

export const applyTemplateToAssignmentsRequest = createAction(
  '[Assignments] apply nodes assignments request',
  props<{ nodeIds: number[]; nodeTemplateId: number; setSelectedLayoutAssignment?: boolean }>(),
);

export const applyGroupTagToAssignmentRequest = createAction(
  '[Assignments] apply group tag to assignment request',
  props<{ assignmentId: number; tagId: number; groupId: number; tagType: TagType }>(),
);

export const applyGroupTagToAssignmentSuccess = createAction(
  '[Assignments] apply group tag to assignment success',
  props<{ assignmentId: number; nodeTag: NodeTagModel }>(),
);

export const addAssignmentElementsRequest = createAction(
  '[Assignments] add assignment elements request',
  props<{ assignmentId: number; nodeIds: number[] }>(),
);

export const addAssignmentElementsSuccess = createAction(
  '[Assignments] add assignment elements success',
  props<{ assignmentId: number; nodeIds: number[] }>(),
);

export const copyAssignmentRequest = createAction(
  '[Assignments] copy assignment request',
  props<{
    assignmentId: number;
    dateKeys: string[];
    primaryTags: boolean;
    assets: boolean;
    elements: boolean;
    rates: boolean;
  }>(),
);

export const copyAssignmentSuccess = createAction(
  '[Assignments] copy assignment success',
  props<{ nodes: NodeModel[] }>(),
);

export const copyNodeRequest = createAction(
  '[Assignments] copy node request',
  props<{
    nodeId: number;
  }>(),
);

export const removeAssignmentElementsByBehaviourType = createAction(
  '[Assignments] remove assignment elements by behaviour type',
  props<{ assignmentId: number; nodeIds: number[] }>(),
);

export const replaceAssignmentElementsRequest = createAction(
  '[Assignments] replace assignment elements request',
  props<{
    assignmentId: number;
    nodeIds: number[];
    removeByNodeTemplateIds: number[];
    setSelectedLayoutAssignment?: boolean;
  }>(),
);

export const replaceAssignmentElementsSuccess = createAction(
  '[Assignments] replace assignment elements success',
  props<{
    assignmentId: number;
    nodeIds: number[];
  }>(),
);

export const setSelectedLayoutAssignmentRequest = createAction(
  '[Assignments] call selected layout assignment request',
  props<{
    assignment: NodeModel;
    status: boolean;
  }>(),
);

export const removeAssignmentElementsByNodeTemplate = createAction(
  '[Assignments] remove assignment elements by node template',
  props<{ assignmentId: number; nodeTemplateIds: number[] }>(),
);

export const updateAssignmentEditedBy = createAction(
  '[Assignments] update assignment edited by',
  props<{ nodeIds: number[] }>(),
);

export const updateAssignmentEditedBySuccess = createAction(
  '[Assignments] update assignment edited by success',
  props<{ nodeIds: number[]; editedDate: string; editedBy }>(),
);

export const archiveAssignmentsRequest = createAction(
  '[Assignments] archive assignments request',
  props<{ nodeIds: number[] }>(),
);

export const archiveAssignmentsSuccess = createAction(
  '[Assignments] archive assignments success',
  props<{ nodeIds: number[] }>(),
);

export const updateAssignmentReadonlyRequest = createAction(
  '[Assignments] update assignment readonly request',
  props<{ nodeId: number; readonly: boolean }>(),
);

export const updateAssignmentReadonlySuccess = createAction(
  '[Assignments] update assignment readonly success',
  props<{ nodeId: number; readonly: boolean }>(),
);

export const toggleSelectedAssignment = createAction(
  '[Assignments] toggle selected assignment',
  props<{ assignmentId: number }>(),
);

export const selectAllAssignmentsByDate = createAction(
  '[Assignments] select all assignments by date',
  props<{ dateKey: string }>(),
);

export const unselectAllAssignmentsByDate = createAction(
  '[Assignments] unselect all assignments by date',
  props<{ dateKey: string }>(),
);

export const clearSelectedAssignments = createAction('[Assignments] clear selected assignments');
