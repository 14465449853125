<input
  #input
  type="time"
  [id]="elementId"
  [attr.list]="elementId"
  class="z-table__input z-table__input--text"
  [ngClass]="{ 'z-table__input--empty': editable && !cell?.value }"
  [(ngModel)]="cell.value"
  [placeholder]="!cell.value ? 'Empty' : ''"
  [disabled]="!editable"
  (focus)="onStartEdit()"
  (blur)="onEndEdit()"
  (keyup.enter)="onEnterKey()"
  (keyup.escape)="onEscapeKey()"
/>
