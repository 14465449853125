import { AppState } from '../index';
import { createSelector } from '@ngrx/store';
import { WorkspaceGroupType } from '../../core/constants/workspace-group-type';
import { NodeUtils } from '../../core/utils/node.util';
import { WorkspaceGroupModel } from '../../core/models/workspace-group.model';

const selectWorkspaceGroupsState = (state: AppState) => state.workspaceGroups;

export const selectWorkspaceGroupsById = createSelector(
  selectWorkspaceGroupsState,
  state => state.byId,
);

export const selectWorkspaceGroupsByType = createSelector(
  selectWorkspaceGroupsState,
  state => state.byType,
);

export const selectWorkspaceGroupsByNodeTemplateId = createSelector(
  selectWorkspaceGroupsState,
  state => state.byTemplate,
);

export const selectWorkspaceGroupsByNodeId = createSelector(
  selectWorkspaceGroupsState,
  state => state.byNode,
);

export const selectGroupsSearchKeyword = createSelector(
  selectWorkspaceGroupsState,
  state => state.searchKeyword,
);

export const selectGroupsByNodeId = (nodeId: number) =>
  createSelector(selectWorkspaceGroupsState, state => {
    return state.byNode[nodeId] || [];
  });

export const selectRateGroupsByNodeId = (nodeId: number) =>
  createSelector(selectWorkspaceGroupsState, state => {
    return (state.byNode[nodeId] || []).filter(g => g.type === WorkspaceGroupType.nodeRate);
  });

export const selectRateValueGroupsByNodeId = (nodeId: number) =>
  createSelector(selectWorkspaceGroupsState, state => {
    return (state.byNode[nodeId] || []).filter(g => g.type === WorkspaceGroupType.claimPeriod);
  });

export const selectWorkspaceClaimPeriodGroups = createSelector(
  selectWorkspaceGroupsState,
  state => state.byType[WorkspaceGroupType.claimPeriod] || [],
);

export const selectWorkspaceLinkGroups = createSelector(
  selectWorkspaceGroupsState,
  state => state.byType[WorkspaceGroupType.workspaceLink] || [],
);

export const selectWorkspacePrimaryTagGroups = createSelector(
  selectWorkspaceGroupsState,
  state => state.byType[WorkspaceGroupType.primaryTag] || [],
);

export const selectWorkspaceStampGroups = createSelector(
  selectWorkspaceGroupsState,
  state => state.byType[WorkspaceGroupType.stamp] || [],
);

export const selectWorkspaceCalculationGroups = createSelector(
  selectWorkspaceGroupsState,
  state => state.byType[WorkspaceGroupType.calculationJoin] || [],
);

export const selectGroupsFilteredByType = (groupTypes: WorkspaceGroupType[]) =>
  createSelector(selectWorkspaceGroupsByType, byType => {
    return groupTypes
      .filter(groupType => byType[groupType] != null)
      .reduce((list: WorkspaceGroupModel[], nodeType) => {
        return [...list, ...byType[nodeType]];
      }, [])
      .sort(NodeUtils.sortByIndex);
  });

export const selectGroupsFilteredByTypeWithKeyword = (groupTypes: WorkspaceGroupType[]) =>
  createSelector(
    selectGroupsFilteredByType(groupTypes),
    selectGroupsSearchKeyword,
    (nodes: WorkspaceGroupModel[], keywords) => {
      if (!keywords) {
        return nodes;
      }
      const lowerCaseKeyword = keywords.toLowerCase();
      return nodes.filter(tag => tag.title.toLowerCase().indexOf(lowerCaseKeyword) >= 0);
    },
  );
