import { Action, createReducer, on } from '@ngrx/store';
import { GanttsState, initialGanttsState } from './gantts.state';
import { GanttsActions } from './index';
import { WorkspaceUserMetaActions } from '../workspace-user-meta';

const reducer = createReducer(
  initialGanttsState,
  on(GanttsActions.loadGanttsSuccess, (state, { gantts }) => {
    const byId = gantts.reduce((byId, gantt) => {
      return {
        ...byId,
        [gantt.id]: { ...gantt },
      };
    }, {});

    return {
      ...state,
      byId,
    };
  }),
  on(GanttsActions.createGanttSuccess, (state, { gantt }) => {
    return {
      ...state,
      byId: {
        ...state.byId,
        [gantt.id]: gantt,
      },
    };
  }),

  on(GanttsActions.updateGanttRequest, (state, { ganttId, ganttProps }) => {
    return {
      ...state,
      byId: {
        ...state.byId,
        [ganttId]: {
          ...state.byId[ganttId],
          ...ganttProps,
        },
      },
    };
  }),
  on(GanttsActions.deleteGanttRequest, (state, { ganttId }) => {
    let newState = {
      ...state,
      byId: {
        ...state.byId,
        [ganttId]: undefined,
      },
    };

    delete newState.byId[ganttId];
    return newState;
  }),

  on(GanttsActions.changeGanttScopedProjectIds, (state, { projectIds }) => {
    return {
      ...state,
      scopedProjectIds: projectIds,
    };
  }),
  on(WorkspaceUserMetaActions.cancelAssignmentFiltersRequest, state => {
    return {
      ...state,
      scopedProjectIds: [],
    };
  }),
);

export function ganttsReducer(state: GanttsState, action: Action) {
  return reducer(state, action);
}
