import { Component, Input, OnInit } from '@angular/core';
import { NodeModel } from '../../../../../core/models/node.model';
import { WidgetType } from '../../../../../core/constants/widget-type';
import { NodeType } from '../../../../../core/constants/node-type';

@Component({
  selector: 'app-node-view',
  templateUrl: './node-view.component.html',
  styleUrls: ['./node-view.component.scss'],
})
export class NodeViewComponent implements OnInit {
  public readonly NodeType = NodeType;
  public readonly WidgetType = WidgetType;

  @Input()
  public node: NodeModel;

  constructor() {}

  ngOnInit(): void {}

  openLink(url: string) {
    window.open(url, '_blank');
  }

  trackById(index, item) {
    return item.id;
  }
}
