import { AppState } from '../index';
import { createSelector } from '@ngrx/store';

const selectNodeWidgetsState = (state: AppState) => state.nodeWidgets;

export const selectNodeWidgetsById = createSelector(
  selectNodeWidgetsState,
  state => state?.nodeWidgetsById,
);

export const selectNodeWidgetsByNodeId = createSelector(
  selectNodeWidgetsState,
  state => state?.nodeWidgetsByNodeId,
);
