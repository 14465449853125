import { AppState } from '../index';
import { createSelector } from '@ngrx/store';
import { NodeType } from '../../core/constants/node-type';
import { NodeModel } from '../../core/models/node.model';
import { NodeUtils } from '../../core/utils/node.util';
import { NodeTemplateModel } from '../../core/models/node-template.model';

export const selectNodesState = (state: AppState) => state.nodes;

export const selectNodesById = createSelector(selectNodesState, state => state.nodesById);
export const selectRootNodesById = createSelector(selectNodesState, state => state.rootNodesById);
export const selectNodesByNodeType = createSelector(
  selectNodesState,
  state => state.nodesByNodeType,
);

export const selectNodesByParentId = createSelector(
  selectNodesState,
  state => state.nodesByParentId,
);

export const selectNodesByTemplateId = createSelector(
  selectNodesState,
  state => state.nodesByTemplateId,
);

export const selectNodesSearchKeyword = createSelector(
  selectNodesState,
  state => state.searchKeyword,
);

export const selectRootNodes = createSelector(
  selectRootNodesById,
  selectNodesByParentId,
  (rootNodesById, nodesByParentId) => {
    return Object.values(rootNodesById)
      .reduce((list: NodeModel[], node: NodeModel) => {
        if (!node) {
          return list;
        }
        return [
          ...list,
          {
            ...node,
            children: NodeUtils.getDescendants([node], nodesByParentId),
          },
        ];
      }, [])
      .sort(NodeUtils.sortByIndex);
  },
);

export const selectAllNodes = createSelector(selectNodesById, byId => {
  return Object.values(byId)
    .filter(n => n != null)
    .sort(NodeUtils.sortByIndex);
});

export const selectAllNodesWithKeyword = createSelector(
  selectNodesById,
  selectNodesSearchKeyword,
  (nodesById, keywords) => {
    if (!keywords) {
      return [];
    }
    const lowerCaseKeyword = keywords.toLowerCase();
    return Object.values(nodesById)
      .filter((n: NodeModel) => n.title.toLowerCase().indexOf(lowerCaseKeyword) >= 0)
      .map(n => {
        return {
          ...n,
          parent: nodesById[n.parentNodeId],
          reference: nodesById[n.referenceNodeId],
        };
      })
      .sort(NodeUtils.sortByIndex);
  },
);

export const selectNodesFilteredByNodeType = (
  nodeTypes: NodeType[],
  nodeTemplates?: NodeTemplateModel[],
) =>
  createSelector(selectNodesByNodeType, selectNodesById, (byNodeType, nodesById) => {
    let list = nodeTypes.reduce((list: NodeModel[], nodeType) => {
      if (byNodeType[nodeType] == null) {
        return list;
      }
      return [...list, ...byNodeType[nodeType]];
    }, []);

    if (nodeTemplates && nodeTemplates.length) {
      list = list.filter(n => nodeTemplates.some(nt => nt.id === n.nodeTemplateId));
    }
    return list
      ?.map(n => {
        return {
          ...n,
          parent: nodesById[n.parentNodeId],
          reference: nodesById[n.referenceNodeId],
        };
      })
      .sort(NodeUtils.sortByParentNode);
  });

export const selectNodesFilteredByNodeTypeWithKeyword = (
  nodeTypes: NodeType[],
  nodeTemplates?: NodeTemplateModel[],
) =>
  createSelector(
    selectNodesFilteredByNodeType(nodeTypes, nodeTemplates),
    selectNodesSearchKeyword,
    (nodes: NodeModel[], keywords) => {
      if (!keywords) {
        return nodes;
      }
      const lowerCaseKeyword = keywords.toLowerCase();
      return nodes.filter(tag => tag.title.toLowerCase().indexOf(lowerCaseKeyword) >= 0);
    },
  );

export const selectNodesFilteredByParentId = (parentId: number) =>
  createSelector(selectNodesByParentId, selectNodesById, (byParentId, nodesById) => {
    return [...(byParentId[parentId] || [])]
      .map(n => {
        return {
          ...n,
          parent: nodesById[n.parentNodeId],
          children: NodeUtils.getDescendants([n], byParentId),
          reference: nodesById[n.referenceNodeId],
        };
      })
      .sort(NodeUtils.sortByIndex);
  });

export const selectRootNodeFilteredByNodeType = (nodeType: NodeType) =>
  createSelector(selectRootNodes, rootNodes => {
    return rootNodes.find((n: NodeModel) => n.nodeType == nodeType);
  });

/**
 * New Assignments store design
 */
export const selectAssignmentsById = createSelector(
  selectNodesState,
  state => state.assignmentsById,
);

export const selectAssignmentsByParentId = createSelector(
  selectNodesState,
  state => state.assignmentsByParentId,
);

export const selectAssignmentsByDate = createSelector(
  selectNodesState,
  state => state.assignmentsByDate,
);

export const selectNewAssignmentIds = createSelector(
  selectNodesState,
  state => state.newAssignmentIds,
);

export const selectAssignmentSelectedIds = createSelector(
  selectNodesState,
  state => state.selectedIds,
);

export const selectAssignmentSelectedNodes = createSelector(selectNodesState, state =>
  state.selectedIds.map(id => state.assignmentsById[id]),
);

export const selectReferenceNodesByDateCount = createSelector(
  selectAssignmentsById,
  (assignmentsById): { [key: string]: number } => {
    const map = Object.values(assignmentsById)
      .filter(n => n != null)
      .reduce((map, node) => {
        const id = node.referenceNodeId;
        const dateKey = node.date ? node.date : NodeUtils.awaitingAssignmentsDateKey;
        const key = `${id}:${dateKey}`;
        return {
          ...map,
          [key]: map[key] ? ++map[key] : 1,
        };
      }, {});
    return map;
  },
);

export const selectAssignmentsSelectedStatusByDate = (dateKey: string) =>
  createSelector(
    selectAssignmentsByDate,
    selectAssignmentSelectedIds,
    (assignmentsByDate, selectedIds) => {
      if (!selectedIds?.length) {
        return false;
      }
      const assignmentIds =
        assignmentsByDate[dateKey]
          ?.filter(fItem => fItem?.nodeType === NodeType.assignment)
          ?.map((assignment: NodeModel) => assignment.id) || [];
      const result = assignmentIds.every(id => selectedIds.includes(id));
      return result;
    },
  );
