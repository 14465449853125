<div
  #input
  class="z-table__input z-table__input--assignment-lock"
  [ngClass]="[
        editable == false ? 'z-table__input--disabled' : '',
        cell.value == false ? 'z-table__input--locked' : 'z-table__input--unlocked',
      ]"
>
  <app-assignment-lock [assignment]="assignment" [disabled]="!editable"></app-assignment-lock>
</div>
