import { Action, createReducer, on } from '@ngrx/store';
import { initialNodeRatesState, NodeRatesState } from './node-rates.state';
import { NodeRatesActions } from './index';
import { NodeRateModel } from '../../core/models/node-rate.model';

const addToState = (state: NodeRatesState, nodeRate: NodeRateModel): NodeRatesState => {
  return {
    ...state,

    byId: {
      ...state.byId,
      [nodeRate.id]: nodeRate,
    },

    byNodeId: {
      ...state.byNodeId,
      [nodeRate.nodeId]: [
        ...(state.byNodeId[nodeRate.nodeId] || []).filter(rate => rate.id !== nodeRate.id),
        nodeRate,
      ],
    },
  };
};

const removeFromState = (state: NodeRatesState, nodeRate: NodeRateModel): NodeRatesState => {
  return {
    ...state,

    byId: {
      ...state.byId,
      [nodeRate.id]: undefined,
    },

    byNodeId: {
      ...state.byNodeId,
      [nodeRate.nodeId]: (state.byNodeId[nodeRate.nodeId] || []).filter(
        rate => rate.id !== nodeRate.id,
      ),
    },
  };
};

const reducer = createReducer(
  initialNodeRatesState,

  on(
    NodeRatesActions.loadNodeRatesFromNodeTree,
    (state, { nodeRates }): NodeRatesState => {
      return nodeRates.reduce((state, nodeRate) => {
        return addToState(state, nodeRate);
      }, state);
    },
  ),

  on(
    NodeRatesActions.addNodeRateSuccess,
    (state, { rate }): NodeRatesState => {
      return addToState(state, rate);
    },
  ),

  on(
    NodeRatesActions.updateNodeRateRequest,
    (state, { rateId, rateProps }): NodeRatesState => {
      return addToState(state, {
        ...state.byId[rateId],
        ...rateProps,
      });
    },
  ),

  on(
    NodeRatesActions.deleteNodeRateRequest,
    (state, { nodeId, rateId }): NodeRatesState => {
      return removeFromState(state, state.byId[rateId]);
    },
  ),

  on(NodeRatesActions.sortNodeRateRequest, (state, { rateIds }) => {
    let idx = 0;
    return rateIds.reduce((state, id) => {
      return addToState(state, {
        ...state.byId[id],
        sortIndex: idx++,
      });
    }, state);
  }),
);

export function nodeRatesReducer(state: NodeRatesState, action: Action) {
  return reducer(state, action);
}
