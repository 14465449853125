import { Action, createReducer, on } from '@ngrx/store';
import { initialNodeViewState, NodeViewState } from './node-view.state';
import { NodeViewActions } from './index';

const reducer = createReducer(
  initialNodeViewState,
  on(NodeViewActions.openNodeView, (state, { nodeId }) => {
    return {
      ...initialNodeViewState,
      nodeId,
    };
  }),
);

export function nodeViewReducer(state: NodeViewState, action: Action) {
  return reducer(state, action);
}
