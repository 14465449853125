import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { WorkspaceUserModel } from '../../../../core/models/workspace-user.model';
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-sidebar-profile',
  templateUrl: './sidebar-profile.component.html',
  styleUrls: ['./sidebar-profile.component.scss'],
})
export class SidebarProfileComponent implements OnInit {
  @Input() isCollapsed: boolean;
  @Input() user: WorkspaceUserModel;

  @HostBinding('class.sidebar-profile') blockClass = true;

  constructor() {}

  ngOnInit() {}

  protected readonly environment = environment;
}
