import { Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { LoaderParams, LoaderService } from './loader.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-content-loader',
  templateUrl: 'content-loader.component.html',
  styleUrls: ['content-loader.component.scss'],
})
export class ContentLoaderComponent implements OnInit, OnDestroy {
  @Input() scope: string;
  @Input() positionAbsolute = true;
  private subscription: Subscription;

  public isLoading: boolean;
  public options: AnimationOptions = {
    path: 'assets/lottie/loader.json',
  };

  constructor(private loaderService: LoaderService, private zone: NgZone) {}

  public ngOnInit(): void {
    this.subscription = this.loaderService
      .watchLoader(this.scope)
      .pipe(filter(x => x != null))
      .subscribe((params: LoaderParams) => {
        // These shenanigans are purely for ngBootstraps "bugged-out" modals.
        // Ref: ERROR Error: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked.
        this.zone.run(() => {
          setTimeout(() => {
            this.isLoading = params.isLoading;
          });
        });
      });
  }

  public onClick(event) {
    event.preventDefault();
  }

  public ngOnDestroy(): void {
    if (this.subscription instanceof Subscription) {
      this.subscription.unsubscribe();
    }
  }
}
