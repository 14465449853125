import { ChatbarPage } from '../../core/constants/chatbar-page';

export class ChatbarState {
  page: ChatbarPage;
  currentChannelId: number;
}

export const initialChatbarState: ChatbarState = {
  page: ChatbarPage.allChannels,
  currentChannelId: undefined,
};
