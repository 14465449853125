import { ServerWorkspaceUserGroupModel } from '../models/server-workspace-user-group.model';
import { WorkspaceUserGroupModel } from '../models/workspace-user-group.model';
import { RoleType } from '../constants/role-type';
import { PermissionType } from '../constants/permission-type';

const convertServerWorkspaceUserGroup = (
  group: ServerWorkspaceUserGroupModel,
): WorkspaceUserGroupModel => {
  if (Array.isArray(group.permissions)) {
    const role: RoleType = (group.permissions || []).filter(
      item => !!RoleType[item],
    )[0] as RoleType;
    const permissions: PermissionType[] = (group.permissions || []).filter(
      item => !RoleType[item],
    ) as PermissionType[];
    return {
      ...group,
      role,
      permissions,
    };
  } else {
    console.warn('group.permissions is not an array: ', group);
    return {
      ...group,
      role: RoleType.role_member,
      permissions: [],
    };
  }
};

export { convertServerWorkspaceUserGroup };
