import { createAction, props } from '@ngrx/store';
import { WorkspaceGroupModel } from '../../core/models/workspace-group.model';
import { WorkspaceGroupType } from '../../core/constants/workspace-group-type';
import { WidgetType } from '../../core/constants/widget-type';
import { NodeTemplateWidgetModel } from '../../core/models/node-template-widget.model';

export const loadWorkspaceGroupsRequest = createAction(
  '[WorkspaceGroups] load workspace groups request',
  props<{ workspaceSlug: string }>(),
);

export const loadWorkspaceGroupsSuccess = createAction(
  '[WorkspaceGroups] load workspace groups success',
  props<{ groups: WorkspaceGroupModel[] }>(),
);

export const addWorkspaceGroupRequest = createAction(
  '[WorkspaceGroups] add workspace group request',
  props<{
    title: string;
    groupType: WorkspaceGroupType;
    nodeId?: number;
    templateId?: number;
    attachChild?: number;
  }>(),
);

export const addWorkspaceGroupsSuccess = createAction(
  '[WorkspaceGroups] add workspace groups success',
  props<{ groups: WorkspaceGroupModel[] }>(),
);

export const updateWorkspaceGroupRequest = createAction(
  '[WorkspaceGroups] update workspace group request',
  props<{ groupId: number; title: string }>(),
);

export const updateWorkspaceGroupSuccess = createAction(
  '[WorkspaceGroups] update workspace group success',
  props<{ group: WorkspaceGroupModel }>(),
);

export const deleteWorkspaceGroupRequest = createAction(
  '[WorkspaceGroups] delete workspace group request',
  props<{ group: WorkspaceGroupModel }>(),
);

export const sortWorkspaceGroupsRequest = createAction(
  '[WorkspaceGroups] sort workspace groups request',
  props<{ groupIds: number[] }>(),
);

export const addWorkspaceGroupWidgetRequest = createAction(
  '[WorkspaceGroups] add workspace group widget request',
  props<{
    title: string;
    widgetType: WidgetType;
    groupId: number;
  }>(),
);

export const addWorkspaceGroupWidgetSuccess = createAction(
  '[WorkspaceGroups] add workspace group widget success',
  props<{
    groupId: number;
    widget: NodeTemplateWidgetModel;
  }>(),
);

export const deleteWorkspaceGroupWidgetRequest = createAction(
  '[WorkspaceGroups] delete workspace group widget request',
  props<{
    groupId: number;
    widgetId: number;
  }>(),
);

export const updateSearchKeywordRequest = createAction(
  '[WorkspaceGroups] update search keyword request',
  props<{ searchKeyword: string }>(),
);
