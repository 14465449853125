import { Pipe, PipeTransform } from '@angular/core';
import { WorkspaceGroupType } from '../../core/constants/workspace-group-type';

@Pipe({
  name: 'groupItemName',
})
export class GroupItemNamePipe implements PipeTransform {
  transform(groupType: WorkspaceGroupType, isPlural = false): string {
    if (groupType === WorkspaceGroupType.primaryTag) {
      return isPlural ? 'Primary Tags' : 'Primary Tag';
    } else if (groupType === WorkspaceGroupType.stamp) {
      return isPlural ? 'Stamps' : 'Stamp';
    }
    return null;
  }
}
