import { createAction, props } from '@ngrx/store';
import {
  NodeTemplateEditableProps,
  NodeTemplateModel,
} from '../../core/models/node-template.model';
import { NodeType } from '../../core/constants/node-type';

export const refreshNodeTemplatesRequest = createAction(
  '[Templates] refresh node templates request',
  props<{ workspaceSlug: string }>(),
);

export const refreshNodeTemplatesSuccess = createAction(
  '[Templates] refresh node templates success',
  props<{ nodeTemplates: NodeTemplateModel[] }>(),
);

export const loadNodeTemplatesRequest = createAction(
  '[Templates] load node templates request',
  props<{ workspaceSlug: string; nodeTypes?: NodeType[] }>(),
);

export const loadNodeTemplatesSuccess = createAction(
  '[Templates] load node templates success',
  props<{ serverNodeTemplates: NodeTemplateModel[] }>(),
);

export const addNodeTemplateRequest = createAction(
  '[Templates] add node template request',
  props<{
    workspaceSlug: string;
    title: string;
    serverNodeType: NodeType;
    parentFolderId: number;
  }>(),
);

export const addNodeTemplateSuccess = createAction(
  '[Templates] add node template success',
  props<{ template: NodeTemplateModel }>(),
);

export const addNodeTemplatesSuccess = createAction(
  '[Templates] add node templates success',
  props<{ templates: NodeTemplateModel[] }>(),
);

export const copyNodeTemplateRequest = createAction(
  '[Templates] copy node template request',
  props<{ workspaceSlug: string; templateId: number; serverNodeType: NodeType }>(),
);

export const updateNodeTemplateRequest = createAction(
  '[Templates] update node template request',
  props<{ templateId: number; templateProps: Partial<NodeTemplateEditableProps> }>(),
);

export const setDefaultNodeTemplateRequest = createAction(
  '[Templates] set default node template request',
  props<{ templateId: number; currentDefaultTemplateId: number }>(),
);

export const archiveNodeTemplateRequest = createAction(
  '[Templates] archive node template request',
  props<{ templateId: number; serverNodeType: NodeType }>(),
);

export const archiveNodeTemplateSuccess = createAction(
  '[Templates] archive node template success',
  props<{ templateId: number; serverNodeType: NodeType }>(),
);

export const deleteNodeTemplateRequest = createAction(
  '[Templates] delete node template request',
  props<{ templateId: number; serverNodeType: NodeType }>(),
);

export const deleteNodeTemplateSuccess = createAction(
  '[Templates] delete node template success',
  props<{ templateId: number; serverNodeType: NodeType }>(),
);

export const sortNodeTemplatesRequest = createAction(
  '[Templates] sort node templates request',
  props<{ templateIds: number[]; serverNodeType: NodeType }>(),
);

export const updateSearchKeywordRequest = createAction(
  '[Templates] update search keyword request',
  props<{ searchKeyword: string }>(),
);

export const updateNodeTemplateProfileSuccess = createAction(
  '[Templates] update node template profile success',
  props<{ templateId: number; templateProps: Partial<NodeTemplateEditableProps> }>(),
);
