import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { WidgetService } from '../../core/services/widget.service';
import { catchError, concatMap, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { ErrorsActions } from '../errors';
import { WidgetsActions } from './index';
import { WidgetModel } from '../../core/models/widget.model';
import { TemplateWidgetsActions } from '../template-widgets';
import { select, Store } from '@ngrx/store';
import { selectWorkspacesCurrentSlug } from '../workspaces/workspaces.selectors';

@Injectable()
export class WidgetsEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private widgetService: WidgetService,
  ) {}

  loadWidgetsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WidgetsActions.loadWidgetsRequest),
      mergeMap(action =>
        this.widgetService.loadWidgets(action.workspaceSlug).pipe(
          map(widgets => {
            return WidgetsActions.loadWidgetsSuccess({ widgets });
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  addWidgetRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WidgetsActions.addWidgetRequest),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      mergeMap(([action, slug]) =>
        this.widgetService
          .addWidget(slug, action.title, action.widgetType, action.nodeType, action.choices)
          .pipe(
            map((widget: WidgetModel) => {
              return WidgetsActions.addWidgetSuccess({ widget });
            }),
            catchError(error => {
              return of(ErrorsActions.goToErrorPage({ error }));
            }),
          ),
      ),
    ),
  );

  updateWidgetRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WidgetsActions.updateWidgetRequest),
      mergeMap(action =>
        this.widgetService.updateWidget(action.widgetId, action.widgetProps).pipe(
          switchMap(() => {
            return EMPTY;
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  deleteWidgetRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        WidgetsActions.deleteWidgetRequest,
        TemplateWidgetsActions.removeTemplateGroupWidgetAndWidgetItselfRequest,
      ),
      mergeMap(action =>
        this.widgetService.removeWidget(action.widgetId).pipe(
          switchMap(() => {
            return EMPTY;
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );
}
