import { Pipe, PipeTransform } from '@angular/core';
import { SharedLinkModel } from '../../core/models/shared-link.model';
import { environment } from '../../../environments/environment';

@Pipe({
  name: 'sharedLinkUrl',
})
export class SharedLinkUrlPipe implements PipeTransform {
  transform(sharedLink: SharedLinkModel): string {
    return `${environment.clientUrl}/shared-view/${sharedLink.hash}`;
  }
}
