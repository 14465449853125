import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SizeType } from '../../../core/constants/size-type';

@Component({
  selector: 'app-assignment-card-add',
  templateUrl: './assignment-card-add.component.html',
  styleUrls: ['./assignment-card-add.component.scss'],
})
export class AssignmentCardAddComponent implements OnInit {
  @Input() size: SizeType;
  @Output() addEvent = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  addAssignment() {
    this.addEvent.emit();
  }
}
