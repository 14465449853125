<span class="layout-metric-item" [ngbPopover]="popContent" [placement]="defaultPlacements">
  <span class="layout-metric-item__icon fal" [ngClass]="['fa-' + (template?.icon | iconString)]"></span>
  <span class="layout-metric-item__description">
    <span class="layout-metric-item__count">{{count}}</span>
    <span class="layout-metric-item__title">{{template?.title}}</span>
  </span>
</span>

<ng-template #popContent>
  <ul class="popover-menu">
    <li class="popover-menu__info-title popover-menu__info-title--primary">{{template?.title}}</li>
    <li class="popover-menu__item"
        (click)="toggleFilterTemplate()">{{(this.filterLayoutIds || []).indexOf(this.template?.id) > -1 ? 'Remove from Filters' : 'Add to Filters'}}</li>
  </ul>
</ng-template>
