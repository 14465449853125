<div
  *ngIf="loggedInUser | can: PermissionType.projectEditor"
  class="multi-select-controls"
  [ngClass]="{ 'multi-select-controls--show': show }"
>
  <div
    @multiSelectControls
    *ngIf="show"
    class="w-100 d-flex flex-column align-content-center justify-content-around"
  >
    <div class="w-100 d-flex align-content-center justify-content-around">
      <button
        *ngIf="folderNode.nodeType === NodeType.projectFolder"
        class="btn btn-primary btn-sm layout-mr-sm"
        type="button"
        (click)="onAssign()"
      >
        Assign
      </button>

      <button class="btn btn-primary btn-sm layout-mr-sm" type="button" (click)="onTags()">
        Tags
      </button>

      <button class="btn btn-primary btn-sm layout-mr-sm" type="button" (click)="onTemplates()">
        Templates
      </button>

      <button class="btn btn-primary btn-sm" type="button" (click)="moveNodes()">
        Move
      </button>
    </div>

    <div class="w-100 d-flex align-content-center justify-content-around my-2">
      <app-btn-fa size="sm" (click)="onArchive()">
        Archive
      </app-btn-fa>
      <app-btn-fa size="sm" (click)="onCancel()">
        Cancel selection
      </app-btn-fa>
    </div>
  </div>

  <div
    @multiSelectControls
    *ngIf="!show"
    class="w-100 d-flex align-content-center justify-content-around text-truncate"
  >
    <app-btn-fa iconName="circle-plus" (click)="addLeaf()" [color]="'primary'">
      Add
      <ng-container *ngIf="folderNode?.nodeType === NodeType.projectFolder"> Assignment</ng-container>
      <ng-container *ngIf="folderNode?.nodeType === NodeType.assetFolder"> Resource</ng-container>
      <ng-container *ngIf="folderNode?.nodeType === NodeType.elementFolder"> Form</ng-container>
    </app-btn-fa>
  </div>
</div>
