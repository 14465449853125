<div class="context-buttons">
  <ng-container *ngIf="loggedInUser | can: PermissionType.projectEditor">
    <ng-template #popContent>
      <app-folder-menu
        *ngIf="NodeUtils.isFolder(node) === true"
        [node]="node"
        [isRootFolder]="!node.parentNodeId"
      ></app-folder-menu>
      <app-leaf-menu *ngIf="NodeUtils.isFolder(node) === false" [node]="node"></app-leaf-menu>
    </ng-template>

    <button
      type="button"
      class="option-icon-button btn"
      [ngClass]="NodeUtils.isFolder(node) === false ? 'option-icon-button--leaf' : ''"
      [placement]="defaultPlacements"
      [ngbPopover]="popContent"
      [autoClose]="true"
      container="body"
    >
      <span class="fal fa-bars option-icon-button__icon"></span>
    </button>

  </ng-container>

  <!-- remove shortcut to diary
  <app-context-button-chat
    *ngIf="NodeUtils.isFolder(node) === false"
    class="context-buttons__button"
    [nodeId]="node.id"
    [isChatChannelActive]="chatActiveChannelNodeIdSet.has(node.id)"
  ></app-context-button-chat>
  -->
</div>
