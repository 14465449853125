import { Action, createReducer, on } from '@ngrx/store';
import { initialTemplatesState, TemplatesState } from './templates.state';
import { TemplatesActions } from './index';

const reducer = createReducer(
  initialTemplatesState,

  on(TemplatesActions.refreshNodeTemplatesSuccess, (state, { nodeTemplates }) => {
    let newState = { ...initialTemplatesState };

    nodeTemplates.forEach(serverNodeTemplate => {
      const { widgets, ...template } = serverNodeTemplate;
      newState = {
        byId: { ...newState.byId, [template.id]: template },
        idsByServerNodeType: {
          ...newState.idsByServerNodeType,
          [template.nodeType]: [
            ...(newState.idsByServerNodeType[template.nodeType]
              ? newState.idsByServerNodeType[template.nodeType]
              : []),
            template.id,
          ],
        },
        searchKeyword: undefined,
      };
    });
    return newState;
  }),

  on(TemplatesActions.addNodeTemplateSuccess, (state, { template }) => {
    return {
      ...state,
      byId: {
        ...state.byId,
        [template.id]: { ...template },
      },
      idsByServerNodeType: {
        ...state.idsByServerNodeType,
        [template.nodeType]: [
          ...state.idsByServerNodeType[template.nodeType].filter(id => id !== template.id),
          template.id,
        ],
      },
    };
  }),

  on(TemplatesActions.updateNodeTemplateRequest, (state, { templateId, templateProps }) => {
    return {
      ...state,
      byId: {
        ...state.byId,
        [templateId]: { ...state.byId[templateId], ...templateProps },
      },
    };
  }),

  on(
    TemplatesActions.setDefaultNodeTemplateRequest,
    (state, { templateId, currentDefaultTemplateId }) => {
      return {
        ...state,
        byId: {
          ...state.byId,
          [templateId]: { ...state.byId[templateId], isDefault: true },
          [currentDefaultTemplateId]: { ...state.byId[currentDefaultTemplateId], isDefault: false },
        },
      };
    },
  ),

  on(
    TemplatesActions.archiveNodeTemplateRequest,
    TemplatesActions.archiveNodeTemplateSuccess,
    TemplatesActions.deleteNodeTemplateRequest,
    TemplatesActions.deleteNodeTemplateSuccess,
    (state, { templateId, serverNodeType }) => {
      return {
        ...state,
        byId: {
          ...state.byId,
          [templateId]: undefined,
        },
        idsByServerNodeType: {
          ...state.idsByServerNodeType,
          [serverNodeType]: state.idsByServerNodeType[serverNodeType].filter(
            id => id !== templateId,
          ),
        },
      };
    },
  ),
  on(TemplatesActions.sortNodeTemplatesRequest, (state, { templateIds, serverNodeType }) => {
    return {
      ...state,
      idsByServerNodeType: {
        ...state.idsByServerNodeType,
        [serverNodeType]: templateIds,
      },
    };
  }),

  on(TemplatesActions.updateSearchKeywordRequest, (state, { searchKeyword }) => ({
    ...state,
    searchKeyword,
  })),

  on(TemplatesActions.updateNodeTemplateProfileSuccess, (state, { templateId, templateProps }) => {
    return {
      ...state,
      byId: {
        ...state.byId,
        [templateId]: { ...state.byId[templateId], ...templateProps },
      },
    };
  }),
);

export function templatesReducer(state: TemplatesState, action: Action) {
  return reducer(state, action);
}
