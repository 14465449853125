import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResponseMessage } from '../models/response-message.model';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { NodeRateEditableProps, NodeRateModel } from '../models/node-rate.model';

@Injectable({ providedIn: 'root' })
export class NodeRateService {
  constructor(private http: HttpClient) {}

  public addRate(
    nodeId: number,
    rateType: number,
    groupId: number,
    title: string,
    value: number,
    quantity: number,
    total: number,
    tooltip: string,
    units: string,
    sortIndex: number,
  ): Observable<NodeRateModel> {
    return this.http
      .post<ResponseMessage<NodeRateModel>>(
        `${environment.apiBaseUrl}/workspace/node/${nodeId}/rate/add`,
        {
          group: groupId,
          rateType,
          title,
          value,
          quantity,
          total,
          tooltip,
          units,
          sortIndex,
        },
      )
      .pipe(map(response => response.data));
  }

  public updateRate(
    rateId: number,
    rateProps: Partial<NodeRateEditableProps>,
  ): Observable<NodeRateModel> {
    // API expects update props match the Database Entity fields
    let props: any = { ...rateProps };
    if (props.groupId) {
      props.group = props.groupId;
      delete props.groupId;
    }
    return this.http
      .patch<ResponseMessage<NodeRateModel>>(
        `${environment.apiBaseUrl}/workspace/node/rate/${rateId}/update`,
        {
          ...props,
        },
      )
      .pipe(map(response => response.data));
  }

  public removeRate(nodeId: number, rateId: number): Observable<ResponseMessage<any>> {
    return this.http.delete<ResponseMessage<any>>(
      `${environment.apiBaseUrl}/workspace/node/${nodeId}/rate/${rateId}/remove`,
    );
  }

  public sortRates(nodeId: number, rateIds: number[]): Observable<ResponseMessage<any>> {
    return this.http
      .post<ResponseMessage<any>>(`${environment.apiBaseUrl}/workspace/node/${nodeId}/rate/sort`, {
        ids: rateIds,
      })
      .pipe(map(response => response.data));
  }
}
