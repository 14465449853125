import {Component, Input, OnInit} from '@angular/core';
import {UserModel} from '../../../core/models/user.model';
import {defaultPlacements} from '../../../core/constants/placements';

@Component({
  selector: 'app-edit-status',
  templateUrl: './edit-status.component.html',
  styleUrls: ['./edit-status.component.scss'],
})
export class EditStatusComponent implements OnInit {
  @Input() editedBy: UserModel;
  @Input() editedDate: string;
  @Input() actionText: string;
  @Input() icon: string = 'fal fa-calendar-pen';
  @Input() label: string = 'Edited';
  @Input() showIcon: boolean = true; // New boolean input to show/hide icon
  defaultPlacements = defaultPlacements;

  constructor() {
  }

  ngOnInit(): void {
  }
}
