import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { CustomViewNodeType } from '../../../core/constants/custom-view-node-type';
import { AssignmentViewType } from '../../../core/constants/assignment-view-type';
import { defaultPlacements } from '../../../core/constants/placements';
import { WidgetType } from '../../../core/constants/widget-type';
import { ProgressModel } from '../../../core/models/progress.model';
import { ToggleViewMode } from '../../../core/constants/toggle-view-mode';
import { NodeModel } from '../../../core/models/node.model';
import { NodeUtils } from '../../../core/utils/node.util';

@Component({
  selector: 'app-assignment-header',
  templateUrl: './assignment-header.component.html',
})
export class AssignmentHeaderComponent implements OnInit {
  public readonly awaitingAssignmentDateKey = NodeUtils.awaitingAssignmentsDateKey;
  public readonly ViewType = AssignmentViewType;
  public readonly CustomViewNodeType = CustomViewNodeType;
  public readonly defaultPlacements = defaultPlacements;
  public readonly WidgetType = WidgetType;

  @Input()
  public assignment: NodeModel;

  @Input() progress: ProgressModel;

  @Input() viewType: AssignmentViewType;
  @Input() isExpanded: boolean;
  @Input() isSelected: boolean;
  @Input() isSelectable: boolean;
  @Input() isClickable: boolean;
  @Input() showUncheckedBtn: boolean;
  @Input() readonly: boolean;
  @Input() widgetsNodeTypes: CustomViewNodeType[];
  @Input() tagsNodeTypes: CustomViewNodeType[];
  @Input() menuRef: TemplateRef<any>;
  @Input() hideExpansion: boolean;
  @Input() hideLock: boolean;
  @Input() progressViewMode: ToggleViewMode; // whether to show the progress regardless of other settings
  @Input() showHeaderDetails: boolean; // show the header details even if isExpanded is false

  @Output() toggleSelectEvent = new EventEmitter();
  @Output() changeExpanded = new EventEmitter();
  @Output() clickTitleEvent = new EventEmitter();
  @Output() toggleLockEvent = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  toggleExpanded() {
    this.changeExpanded.emit();
  }

  trackWidgetsBy(index, widget) {
    return widget.id;
  }

  toggleAssignmentSelection() {
    if (this.readonly) {
      return;
    }
    this.toggleSelectEvent.emit();
  }

  clickTitle() {
    this.clickTitleEvent.emit();
  }

  toggleLock() {
    if (this.readonly) {
      return;
    }
    this.toggleLockEvent.emit();
  }
}
