import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NodeTagsActions } from './index';
import { catchError, concatMap, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { ErrorsActions } from '../errors';
import { NodeTagService } from '../../core/services/node-tag.service';
import { select, Store } from '@ngrx/store';
import { NodeTagModel } from '../../core/models/node-tag.model';
import { selectWorkspacesCurrentSlug } from '../workspaces/workspaces.selectors';

@Injectable({ providedIn: 'root' })
export class NodeTagsEffects {
  constructor(
    private actions$: Actions,
    private nodeTagService: NodeTagService,
    private store: Store,
  ) {}

  addNodeTagRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NodeTagsActions.addNodeTagRequest),
      mergeMap(action =>
        this.nodeTagService.addNodeTag(action.nodeId, action.tagId, action.groupId).pipe(
          map((nodeTag: NodeTagModel) => {
            return NodeTagsActions.addNodeTagSuccess({
              nodeTag,
            });
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  removeNodeTagRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NodeTagsActions.removeNodeTagRequest),
      mergeMap(action =>
        this.nodeTagService.removeNodeTag(action.nodeId, action.joinId).pipe(
          switchMap(() => {
            return EMPTY;
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  clearNodeTagsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NodeTagsActions.clearNodeTagsRequest),
      mergeMap(action =>
        this.nodeTagService.clearNodeTags(action.nodeId).pipe(
          switchMap(() => {
            return EMPTY;
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  addMultipleTagsToNodeRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NodeTagsActions.addMultipleTagsToNodeRequest),
      mergeMap(action =>
        this.nodeTagService
          .addNodeTags(action.nodeId, action.tagIds, action.groupId, action.replace)
          .pipe(
            concatMap((nodeTags: NodeTagModel[]) => [
              NodeTagsActions.addMultipleTagsToNodeSuccess({
                nodeId: action.nodeId,
                nodeTags,
                groupId: action.groupId,
                replace: true,
              }),
            ]),
            catchError(error => {
              return of(ErrorsActions.goToErrorPage({ error }));
            }),
          ),
      ),
    ),
  );

  addTagsToMultipleNodesRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NodeTagsActions.addTagsToMultipleNodesRequest),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      mergeMap(([action, slug]) =>
        this.nodeTagService
          .addTagsToMultipleNodes(slug, action.nodeIds, action.tagIds, action.replace)
          .pipe(
            concatMap((nodeTags: NodeTagModel[]) => [
              NodeTagsActions.addTagsToMultipleNodesSuccess({
                nodeIds: action.nodeIds,
                nodeTags,
                replace: true,
              }),
            ]),
            catchError(error => {
              return of(ErrorsActions.goToErrorPage({ error }));
            }),
          ),
      ),
    ),
  );
}
