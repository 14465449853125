<ng-template #modalFooter>
  <div class="modal-picker-validation" *ngIf="validationMessage">
    <span class="modal-picker-validation__error">{{ validationMessage }}</span>
  </div>

  <button
    ngbAutofocus
    type="button"
    class="btn btn-sm btn-outline-light"
    aria-label="Close"
    (click)="activeModal.dismiss(false)"
  >
    Cancel
  </button>

  <button type="button" class="btn btn-sm btn-primary" aria-label="Close" (click)="onSubmit()">
    Select
  </button>
</ng-template>

<app-modal title="Assigned Template" [footerTemplate]="modalFooter">
  <app-modal-instructions
    [html]="instructions"
    [template]="instructionsTemplate"
  ></app-modal-instructions>

  <ol class="item-picker__list item-picker__list--tree list-unstyled">
    <li
      *ngFor="let item of list; trackBy: trackBy"
      class="item-picker__list-item"
      [ngStyle]="{ 'margin-left.rem': item.model.depth * 2 }"
    >
      <div
        class="list-item__brick"
        app-selectable-brick
        [icon]="
          item.model.nodeType === NodeType.favouritesFolder
            ? IconType['star']
            : IconType['folder-open']
        "
        [label]="item.model.label"
        [isChecked]="item.selected"
        [readonly]="item.disabled"
        (click)="toggle(item)"
        [id]="item.model.id"
        [rightColTemplate]="checkBox"
      ></div>
      <ng-template #checkBox>
        <app-selectable-input
          [id]="item.model.id"
          [checked]="item.selected"
          [type]="maxSelection === 1 ? 'radio' : 'checkbox'"
        ></app-selectable-input>
      </ng-template>
    </li>
  </ol>
</app-modal>
