<div
  #input
  class="z-table__input z-table__input--assignment-tag"
  [ngClass]="[
    'background--' +
      (cell.value?.tag?.colorTheme ? (cell.value.tag.colorTheme | colorString) : 'transparent'),
    editable == false ? 'z-table__input--disabled' : '',
    !cell.value?.tag ? 'z-table__input--empty' : ''
  ]"
  (click)="editable && onStartEdit()"
>
  <ng-container *ngIf="cell.value?.tag">
    <span
      class="fal"
      [ngClass]="[
        'fa-' + (cell.value?.tag?.icon | iconString),
        cell.value?.tag?.icon ? '' : 'invisible'
      ]"
    ></span>
    <span>{{ cell.value.tag?.title }}</span>
  </ng-container>

  <span class="assignment-tag__empty-text" *ngIf="!cell.value?.tag">{{ 'Empty' }}</span>
</div>
