<div class="chat-file-preview" *ngIf="filePreview">
  <div class="chat-file-preview__left-col">
    <div class="chat-file-preview__img-container">
      <img alt="chat-icon" class="chat-file-preview__img" [src]="filePreview.objectUrl | safe" />
    </div>
    <div class="chat-file-preview__info-container">
      <div class="chat-file-preview__info-title">{{ filePreview?.file?.name }}</div>
      <div class="chat-file-preview__info-size" *ngIf="filePreview?.file?.size">
        {{ filePreview.file.size | bytesToSize }}
      </div>
    </div>
  </div>

  <button
    aria-label="Remove file preview"
    class="chat-file-preview__btn-remove btn fal fa-times"
    (click)="removePreview()"
  ></button>
</div>
