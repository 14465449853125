import { Action, createReducer, on } from '@ngrx/store';
import { initialTagsPopoverState, TagsPopoverState } from './tags-popover.state';
import { TagsPopoverActions } from './index';
import { TagsPopoverPage } from '../../core/constants/tags-popover-page';

const reducer = createReducer<TagsPopoverState>(
  initialTagsPopoverState,

  on(TagsPopoverActions.resetTagEditPage, state => ({
    ...initialTagsPopoverState,
  })),

  on(TagsPopoverActions.showNodeTagsPage, (state, { nodeId, templateId, tagType }) => ({
    ...initialTagsPopoverState,
    page: TagsPopoverPage.nodeTags,
    nodeId,
    templateId,
    tagType,
  })),
  on(
    TagsPopoverActions.showNodeGroupTagsPage,
    (state, { nodeId, templateId, tagType, groupId }) => {
      const newState = {
        ...initialTagsPopoverState,
        page: TagsPopoverPage.nodeGroupTags,
        nodeId,
        templateId,
        tagType,
        groupId,
      };

      return newState;
    },
  ),
  on(TagsPopoverActions.switchNodeTagGroup, (state, { groupId }) => {
    return {
      ...state,
      groupId,
    };
  }),

  on(TagsPopoverActions.showTemplateTagsPage, (state, { templateId, tagType, groupId }) => ({
    ...initialTagsPopoverState,
    page: TagsPopoverPage.tags,
    templateId,
    groupId,
    tagType: tagType,
  })),

  on(TagsPopoverActions.goBackPreviousPage, state => ({
    ...initialTagsPopoverState,
    nodeId: state.nodeId,
    templateId: state.templateId,
    tagType: state.tagType,
    page: state.previousPage,
  })),

  on(
    TagsPopoverActions.showTagCreatePage,
    (state, { nodeId, templateId, tagType, previousPage }) => ({
      ...state,
      page: TagsPopoverPage.tagCreate,
      previousPage,
      tagId: undefined,
      tagType: tagType,
      nodeId,
      templateId,
    }),
  ),
  on(
    TagsPopoverActions.showTagEditPage,
    (state, { nodeId, templateId, tagId, tagType, previousPage, allowDelete }) => ({
      ...state,
      page: TagsPopoverPage.tagEdit,
      previousPage,
      tagId,
      tagType: tagType,
      nodeId,
      templateId,
      allowDelete,
    }),
  ),
  on(TagsPopoverActions.updateSearchKeywordRequest, (state, { searchKeyword }) => ({
    ...state,
    searchKeyword,
  })),
);

export function tagsPopoverReducer(state: TagsPopoverState, action: Action) {
  return reducer(state, action);
}
