import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ResponseMessage } from '../models/response-message.model';
import { map } from 'rxjs/operators';
import { TimesheetEditableProps, TimesheetModel } from '../models/timesheet.model';
import { NodeModel } from '../models/node.model';

@Injectable({ providedIn: 'root' })
export class TimesheetService {
  constructor(private http: HttpClient) {}

  public loadTimesheetsByDateRange(
    slug: string,
    startDate: string,
    endDate: string,
    nullDate = false,
  ): Observable<TimesheetModel[]> {
    const options = {
      params: new HttpParams(),
    };

    if (!nullDate) {
      if (startDate?.length) {
        options.params = options?.params.append('startDate', startDate);
      }
      if (endDate?.length) {
        options.params = options?.params.append('endDate', endDate);
      }
    }

    return this.http
      .get<ResponseMessage<TimesheetModel[]>>(
        `${environment.apiBaseUrl}/workspace/${slug}/timesheet/search`,
        options,
      )
      .pipe(map(response => response.data as TimesheetModel[]));
  }

  public loadTimesheetsByProjects(
    slug: string,
    projects: NodeModel[],
  ): Observable<TimesheetModel[]> {
    const options = {
      params: new HttpParams().set('projects', JSON.stringify(projects.map(p => p.id))),
    };
    return this.http
      .get<ResponseMessage<TimesheetModel[]>>(
        `${environment.apiBaseUrl}/workspace/${slug}/timesheet/search`,
        options,
      )
      .pipe(map(response => response.data as TimesheetModel[]));
  }

  public addTimesheet(
    slug: string,
    assetId: number,
    assignmentId: number,
    editableProps: Partial<TimesheetEditableProps>,
  ): Observable<TimesheetModel> {
    return this.http
      .post<ResponseMessage<TimesheetModel>>(
        `${environment.apiBaseUrl}/workspace/${slug}/timesheet/add`,
        {
          asset: assetId,
          assignment: assignmentId,
          ...editableProps,
        },
      )
      .pipe(map(res => res.data));
  }

  public updateTimesheet(
    timesheetId: number,
    timesheetProps: Partial<TimesheetEditableProps>,
  ): Observable<TimesheetModel> {
    return this.http
      .patch<ResponseMessage<TimesheetModel>>(
        `${environment.apiBaseUrl}/workspace/timesheet/${timesheetId}/update`,
        {
          ...timesheetProps,
        },
      )
      .pipe(map(res => res.data));
  }

  public removeTimesheet(timesheetId: number): Observable<object> {
    return this.http.delete(`${environment.apiBaseUrl}/workspace/timesheet/${timesheetId}/remove`);
  }
}
