import { createAction, props } from '@ngrx/store';
import { WorkspaceEditableProps, WorkspaceModel } from '../../core/models/workspace.model';
import { WorkspaceMetaModel } from '../../core/models/workspace-meta.model';
import { WorkspaceTemplateModel } from '../../core/models/workspace-template.model';
import { WorkspaceCacheKey } from '../../core/constants/workspace-cache-key';

export const loadDashboardRequest = createAction('[Workspaces] load dashboard request');

export const loadDashboardSuccess = createAction(
  '[Workspaces] load dashboard success',
  props<{ owned: WorkspaceModel[]; shared: WorkspaceModel[] }>(),
);

export const addWorkspaceRequest = createAction(
  '[Workspaces] add workspace request',
  props<{ props: WorkspaceEditableProps; workspaceTemplateId?: number }>(),
);

export const addWorkspaceSuccess = createAction(
  '[Workspaces] add workspace success',
  props<{ workspace: WorkspaceModel }>(),
);

export const copyWorkspaceRequest = createAction(
  '[Workspaces] copy workspace request',
  props<{ title: string; slug: string }>(),
);

export const copyWorkspaceSuccess = createAction(
  '[Workspaces] copy workspace success',
  props<{ slug: string; workspace: WorkspaceModel }>(),
);

export const saveWorkspaceAsTemplateRequest = createAction(
  '[Workspaces] save workspace as template request',
  props<{ props: WorkspaceEditableProps; slug: string }>(),
);

export const saveWorkspaceAsTemplateSuccess = createAction(
  '[Workspaces] save workspace as template success',
  props<{ template: WorkspaceTemplateModel }>(),
);

export const deleteWorkspaceRequest = createAction(
  '[Workspaces] delete workspace request',
  props<{ slug: string }>(),
);

export const deleteCurrentWorkspaceRequest = createAction(
  '[Workspaces] delete current workspace request',
  props<{ slug: string }>(),
);

export const deleteWorkspaceSuccess = createAction(
  '[Workspaces] delete workspace success',
  props<{ slug: string }>(),
);
export const leaveWorkspaceRequest = createAction(
  '[Workspaces] leave workspace request',
  props<{ slug: string }>(),
);

export const leaveWorkspaceSuccess = createAction(
  '[Workspaces] leave workspace success',
  props<{ slug: string }>(),
);

export const updateWorkspaceRequest = createAction(
  '[Workspaces] update workspace request',
  props<{ slug: string; props: WorkspaceEditableProps }>(),
);

export const updateWorkspaceSuccess = createAction(
  '[Workspaces] update workspace success',
  props<{ slug: string; workspace: WorkspaceModel }>(),
);

export const patchWorkspaceMetaRequest = createAction(
  '[Workspaces] patch workspace meta request',
  props<{ slug: string; metaData: Partial<WorkspaceMetaModel> }>(),
);

export const patchWorkspaceMetaSuccess = createAction(
  '[Workspaces] patch workspace meta success',
  props<{ slug: string; metaData: Partial<WorkspaceMetaModel> }>(),
);

export const setCurrentWorkspace = createAction(
  '[Workspaces] set current workspace',
  props<{ slug: string }>(),
);

export const unloadCurrentWorkspace = createAction('[Workspaces] unset current workspace');

export const loadWorkspaceWithNodeTreeRequest = createAction(
  '[Workspaces] load workspace with node tree request',
);

export const loadWorkspaceWithNodeTreeSuccess = createAction(
  '[Workspaces] load workspace with node tree success',
);

export const refreshWorkspaceCacheRequest = createAction(
  '[Workspaces] refresh workspace cache request',
  props<{ key: WorkspaceCacheKey }>(),
);

export const refreshWorkspaceCacheSuccess = createAction(
  '[Workspaces] refresh workspace cache success',
  props<{ key: WorkspaceCacheKey }>(),
);
