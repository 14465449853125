import { TimesheetModel } from '../../core/models/timesheet.model';

export class TimesheetsState {
  timesheetsById: { [id: number]: TimesheetModel };
  timesheetsByNodeId: { [id: number]: TimesheetModel[] };
  timesheetsByParentNodeId: { [id: number]: TimesheetModel[] };
}

export const initialTimesheetsState: TimesheetsState = {
  timesheetsById: {},
  timesheetsByNodeId: {},
  timesheetsByParentNodeId: {},
};
