<div class="chat-channels">
  <div class="chat-channels__body">
    <!-- search bar -->
    <div class="chat-channels__search">
      <!-- input with formCtrl -->
      <input
        class="form-control"
        type="text"
        placeholder="Search Channels..."
        [formControl]="searchControl"
      />
    </div>

    <ng-container *ngIf="pinnedChannels?.length">
      <label class="chat-channel-label mt-2 ml-4">Pinned</label>

      <app-chat-channel-item
        [channel]="channel"
        *ngFor="let channel of pinnedChannels; trackBy: trackBy"
        (channelActionEvent)="onChannelActionChange($event)"
      ></app-chat-channel-item>
    </ng-container>

    <ng-container *ngIf="newEntryChannels?.length">
      <label class="chat-channel-label mt-2 ml-4">New Entries</label>

      <app-chat-channel-item
        [channel]="channel"
        *ngFor="let channel of newEntryChannels; trackBy: trackBy"
        (channelActionEvent)="onChannelActionChange($event)"
      ></app-chat-channel-item>
    </ng-container>

    <ng-container *ngIf="otherAllChannels?.length">
      <label class="chat-channel-label mt-2 ml-4">All Channels</label>

      <app-chat-channel-item
        [channel]="channel"
        *ngFor="let channel of otherAllChannels; trackBy: trackBy"
        (channelActionEvent)="onChannelActionChange($event)"
      ></app-chat-channel-item>
    </ng-container>

    <ng-container *ngIf="!allChannels?.length">
      <app-no-data [type]="noDataTypeIds.chatChannels"></app-no-data>
    </ng-container>
    <div class="pb-3"></div>
  </div>
  <!-- <app-content-loader scope="chat"></app-content-loader> -->
</div>
