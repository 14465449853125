import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-btn-check',
  templateUrl: './btn-check.component.html',
  styleUrls: ['./btn-check.component.scss'],
})
export class BtnCheckComponent implements OnInit {
  @Input() id: string;
  @Input() isChecked: boolean;
  @Input() isDisabled: boolean = false;
  @Output() toggleSelectionEvent = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  toggle() {
    this.toggleSelectionEvent.emit();
  }
}
