import { createAction, props } from '@ngrx/store';

export const toggleDateRequest = createAction(
  '[Calendar] toggle date request',
  props<{ date: string }>(),
);

export const selectDate = createAction('[Calendar] select date request', props<{ date: string }>());

export const unselectDate = createAction(
  '[Calendar] unselect date request',
  props<{ date: string }>(),
);

export const toggleAwaitingAssignmentsPin = createAction(
  '[Calendar] toggle awaiting assignments pin',
);

export const clearDateSelection = createAction('[Calendar] clear date selection');
