import { Action, createReducer, on } from '@ngrx/store';
import { CalendarState, initialCalendarState } from './calendar.state';
import { CalendarActions } from './index';
import { WorkspaceUserMetaActions } from '../workspace-user-meta';
import { ErrorsActions } from '../errors';

const unselectDateInCalendarState = (state: CalendarState, date: string): CalendarState => {
  const index = state.selectedDates.indexOf(date);
  let selectedDates = state.selectedDates;
  if (index > -1) {
    selectedDates = [...selectedDates.slice(0, index), ...selectedDates.slice(index + 1)];
  }
  return {
    ...state,
    selectedDates,
  };
};

const reducer = createReducer<CalendarState>(
  initialCalendarState,
  on(
    CalendarActions.selectDate,
    (state, { date }): CalendarState => {
      return {
        ...state,
        selectedDates: [...state.selectedDates, date],
      };
    },
  ),

  on(
    CalendarActions.unselectDate,
    (state, { date }): CalendarState => {
      return unselectDateInCalendarState(state, date);
    },
  ),

  // on(
  //   AssignmentsActions.unselectAssignment,
  //   (state, { dateKey }): CalendarState => {
  //     return unselectDateInCalendarState(state, dateKey);
  //   },
  // ),

  on(
    CalendarActions.clearDateSelection,
    WorkspaceUserMetaActions.changeAssignmentDateRangeRequest,
    state => ({
      ...initialCalendarState,
    }),
  ),

  on(CalendarActions.toggleAwaitingAssignmentsPin, state => {
    return {
      ...state,
      isAwaitingAssignmentsPinned: !state.isAwaitingAssignmentsPinned,
    };
  }),
);

export function calendarReducer(state: CalendarState, action: Action) {
  return reducer(state, action);
}
