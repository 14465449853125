import { Action, createReducer, on } from '@ngrx/store';
import { initialTimesheetsState, TimesheetsState } from './timesheets.state';
import { TimesheetsActions } from './index';
import { TimesheetModel } from '../../core/models/timesheet.model';

const addTimesheetToState = (
  state: TimesheetsState,
  timesheet: TimesheetModel,
): TimesheetsState => {
  const newState = {
    ...(state || initialTimesheetsState),

    timesheetsById: {
      ...state.timesheetsById,
      [timesheet.id]: timesheet,
    },

    timesheetsByNodeId: {
      ...state.timesheetsByNodeId,
      [timesheet.assetId]: [
        ...(state.timesheetsByNodeId[timesheet.assetId] || []).filter(
          ts => ts.assetId != timesheet.assetId,
        ),
        timesheet,
      ],
    },

    timesheetsByParentNodeId: {
      ...state.timesheetsByParentNodeId,
      [timesheet.assignmentId]: [
        ...(state.timesheetsByParentNodeId[timesheet.assignmentId] || []).filter(
          ts => ts.assignmentId != timesheet.assignmentId,
        ),
        timesheet,
      ],
    },
  };
  return newState;
};

const reducer = createReducer(
  initialTimesheetsState,

  on(TimesheetsActions.loadTimesheetsSuccess, (state, { timesheets }) => {
    return timesheets.reduce((newState, ts) => {
      return addTimesheetToState(newState, ts);
    }, state);
  }),

  // on(TimesheetsActions.addTimesheetRequest, (state, { assignmentId, assetId, timesheetProps }) => {
  //   return addTimesheetToState(state, {
  //     id: -1, // temp. Id
  //     assetId,
  //     assignmentId,
  //     ...timesheetProps,
  //   } as TimesheetModel);
  // }),

  on(TimesheetsActions.addTimesheetSuccess, (state, { timesheet }) => {
    return addTimesheetToState(state, timesheet);
  }),

  on(TimesheetsActions.updateTimesheetRequest, (state, { timesheetId, timesheetProps }) => {
    return addTimesheetToState(state, {
      ...state.timesheetsById[timesheetId],
      ...timesheetProps,
    } as TimesheetModel);
  }),
);

export function timesheetsReducer(state: TimesheetsState, action: Action) {
  return reducer(state, action);
}
