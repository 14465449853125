import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { NodeModel } from '../../../core/models/node.model';
import { NodeTemplateModel } from '../../../core/models/node-template.model';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../store';
import { NodesSelectors } from '../../../store/nodes';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-assignment-asset-fields',
  templateUrl: './assignment-asset-fields.component.html',
  styleUrls: [
    './assignment-asset-fields.component.scss',
    '../assignment-field/assignment-field.component.scss',
  ],
})
export class AssignmentAssetFieldsComponent implements OnDestroy {
  private readonly subscription = new Subscription();

  @Input() nodeTemplates: NodeTemplateModel[];
  @Input() readonly: boolean;
  @Input() showProfilePhoto: boolean;
  @Input() displayAsList: boolean;

  @Output() editEvent = new EventEmitter<NodeTemplateModel>();
  @Output() nodeViewEvent = new EventEmitter<NodeModel>();

  referenceNodesByDateCount: { [key: string]: number };

  constructor(private store: Store<AppState>) {
    this.subscription.add(
      this.store
        .pipe(select(NodesSelectors.selectReferenceNodesByDateCount))
        .subscribe(referenceNodesMap => {
          this.referenceNodesByDateCount = referenceNodesMap;
        }),
    );
  }

  requestEdit(event, template: NodeTemplateModel) {
    event.stopPropagation();
    if (this.readonly) {
      return;
    }
    this.editEvent.emit(template);
  }

  nodeView(event: any, node: NodeModel) {
    this.nodeViewEvent.emit(node);
  }

  trackBy(index, item: any) {
    return item.id;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
