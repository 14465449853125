import { GroupLinksPopoverPage } from '../../core/constants/group-links-popover-page';

export class GroupLinksPopoverState {
  page: GroupLinksPopoverPage;
  groupId: number;
  linkId: number;
}

export const initialGroupLinksPopoverState: GroupLinksPopoverState = {
  page: undefined,
  groupId: undefined,
  linkId: undefined,
};
