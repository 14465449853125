import { Component, OnInit } from '@angular/core';
import { TableComponent } from '../../table.component';
import { AbstractTableEditableCellComponent } from '../abstract-table-editable-cell.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AssignedTemplatePickerComponent } from '../../../../shared/modals/assigned-template-picker/assigned-template-picker.component';
import { NodeTemplateModel } from '../../../../core/models/node-template.model';
import { OptionModel } from '../../../../core/models/option.model';
import { NodesActions } from '../../../../store/nodes';
import { AppState } from '../../../../store';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-z-table-editable-assigned-template',
  templateUrl: './table-editable-assigned-template.component.html',
  styleUrls: ['./table-editable-assigned-template.component.scss'],
})
export class TableEditableAssignedTemplateComponent extends AbstractTableEditableCellComponent
  implements OnInit {
  constructor(
    public readonly table: TableComponent,
    private readonly modalService: NgbModal,
    private readonly store: Store<AppState>,
  ) {
    super(table);
  }

  openPicker() {
    const modelRef = this.modalService.open(AssignedTemplatePickerComponent, {
      size: 'xs',
      backdrop: 'static',
    });

    const selectedTemplate: NodeTemplateModel = this.cell?.options?.node?.reference
      ?.nodeTemplate as NodeTemplateModel;

    modelRef.componentInstance.type = this.cell?.options?.node?.nodeType;
    modelRef.componentInstance.selected = [
      {
        id: selectedTemplate?.id,
        label: selectedTemplate?.title,
      },
    ];

    modelRef.componentInstance.minSelection = 1;
    modelRef.componentInstance.maxSelection = 1;
    modelRef.result
      .then((result: OptionModel[]) => {
        this.store.dispatch(
          NodesActions.applyTemplateToNodesRequest({
            nodeIds: [this.cell?.options?.node?.id],
            nodeTemplateId: result[0]?.id as number,
          }),
        );
      })
      .catch(res => {});
  }
}
