import { RoleType } from '../constants/role-type';

const roleTypeLabel = (role: RoleType): string => {
  switch (role) {
    case RoleType.role_admin:
      return 'Admin';
    case RoleType.role_manager:
      return 'Manager';
    case RoleType.role_allocator:
      return 'Allocator';
    case RoleType.role_member:
      return 'Member';
    default:
      return null;
  }
};

export { roleTypeLabel };
