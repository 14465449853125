import { ChatChannelModel } from '../../core/models/chat-channel.model';
import { ChatChannelCommentModel } from '../../core/models/chat-channel-comment.model';

export class ChatsState {
  channelsById: ChatChannelsById;
  channelCommentsById: ChatChannelCommentsById;
  commentIdsByChannelId: ChatChannelCommentIdsByChannelId;
  channelIds: number[];
}

export const initialChatsState: ChatsState = {
  channelsById: {},
  channelCommentsById: {},
  commentIdsByChannelId: {},
  channelIds: [],
};

export class ChatChannelsById {
  [id: number]: ChatChannelModel;
}

export class ChatChannelCommentsById {
  [id: number]: ChatChannelCommentModel;
}

export class ChatChannelCommentIdsByChannelId {
  [id: number]: string[];
}
