import { Action, createReducer, on } from '@ngrx/store';
import {
  initialWorkspaceUserGroupsState,
  WorkspaceUserGroupsState,
} from './workspace-user-groups.state';
import { WorkspaceUserGroupsActions } from './index';
import { WorkspaceUserGroupModel } from '../../core/models/workspace-user-group.model';

const addGroupToWorkspaceUserGroupsState = (
  state: WorkspaceUserGroupsState,
  group: WorkspaceUserGroupModel,
): WorkspaceUserGroupsState => {
  const newState = {
    ...state,
    byId: {
      ...state.byId,
      [group.id]: group,
    },
    ids: [...state.ids, group.id],
  };
  return newState;
};

const addGroupsToWorkspaceUserGroupsState = (
  state: WorkspaceUserGroupsState,
  groups: WorkspaceUserGroupModel[],
): WorkspaceUserGroupsState => {
  return (groups || []).reduce((newState, group) => {
    return addGroupToWorkspaceUserGroupsState(newState, group);
  }, state);
};

const updateGroupInWorkspaceUserGroupsState = (
  state: WorkspaceUserGroupsState,
  group: WorkspaceUserGroupModel,
): WorkspaceUserGroupsState => {
  const newState = {
    ...state,
    byId: {
      ...state.byId,
      [group.id]: group,
    },
  };
  return newState;
};

const deleteGroupFromWorkspaceUserGroupsState = (
  state: WorkspaceUserGroupsState,
  groupId: number,
): WorkspaceUserGroupsState => {
  return {
    ...state,
    byId: {
      ...state.byId,
      [groupId]: undefined,
    },
    ids: state.ids.filter(id => id !== groupId),
  };
};

const reducer = createReducer(
  initialWorkspaceUserGroupsState,
  on(WorkspaceUserGroupsActions.loadCurrentWorkspaceUserGroupsSuccess, (state, { groups }) => {
    const newState: WorkspaceUserGroupsState = {
      ...state,
      ids: [], // reset ids to empty
    };
    return addGroupsToWorkspaceUserGroupsState(newState, groups);
  }),
  on(WorkspaceUserGroupsActions.addWorkspaceUserGroupSuccess, (state, { group }) => {
    const newState = addGroupToWorkspaceUserGroupsState(state, group);
    return newState;
  }),
  on(WorkspaceUserGroupsActions.updateWorkspaceUserGroupSuccess, (state, { group }) => {
    const newState = updateGroupInWorkspaceUserGroupsState(state, group);
    return newState;
  }),
  on(WorkspaceUserGroupsActions.deleteWorkspaceUserGroupRequest, (state, { groupId }) => {
    const newState = deleteGroupFromWorkspaceUserGroupsState(state, groupId);
    return newState;
  }),
);

export function workspaceUserGroupsReducer(state: WorkspaceUserGroupsState, action: Action) {
  return reducer(state, action);
}
