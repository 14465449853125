import { Component } from '@angular/core';
import { TableComponent } from '../../table.component';
import { AbstractTableEditableCellComponent } from '../abstract-table-editable-cell.component';
import { NodeTagModel } from '../../../../core/models/node-tag.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppState } from '../../../../store';
import { Store } from '@ngrx/store';
import { NodeModel } from '../../../../core/models/node.model';
import { TagModel } from '../../../../core/models/tag.model';
import { NodeTagsActions } from '../../../../store/node-tags';
import { TagsPickerComponent } from '../../../../shared/modals/tags-picker/tags-picker.component';

@Component({
  selector: 'app-z-table-editable-assignment-tags',
  templateUrl: './table-editable-assignment-tags.component.html',
  styleUrls: ['./table-editable-assignment-tags.component.scss'],
})
export class TableEditableAssignmentTagsComponent extends AbstractTableEditableCellComponent {
  constructor(
    public table: TableComponent,
    private modalService: NgbModal,
    private store: Store<AppState>,
  ) {
    super(table);
  }

  public onStartEdit() {
    const assignment = this.options?.assignment as NodeModel;

    const modelRef = this.modalService.open(TagsPickerComponent, {
      size: 'xl',
      backdrop: 'static',
    });
    modelRef.componentInstance.selected = assignment.tags || [];
    modelRef.result
      .then((result: TagModel[]) => {
        this.store.dispatch(
          NodeTagsActions.addMultipleTagsToNodeRequest({
            nodeId: assignment.id,
            tagIds: (result || []).map(t => t.id),
            groupId: undefined,
            replace: true,
          }),
        );
      })
      .catch(res => {});
  }

  trackBy(index, item: NodeTagModel) {
    return item.id;
  }
}
