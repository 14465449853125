<div class="sticky-panel-header">
  <button
    type="button"
    class="btn sticky-panel-header__btn-close fal fa-times"
    (click)="close()"
  ></button>
  <div class="sticky-panel-header__title">
    {{ title }}
  </div>
</div>
