<ng-template #modalFooter>
  <button type="button" class="btn btn-sm btn-outline-light" (click)="activeModal.dismiss()">
    Cancel
  </button>

  <button ngbAutofocus type="button" class="btn btn-sm btn-primary" (click)="onClose()">
    <i class="fal fa-check layout-mr-sm"></i>
    Select color
  </button>
</ng-template>

<app-modal title="Colour Selections" [footerTemplate]="modalFooter">
  <div class="colour-picker">
    <div *ngFor="let colour of colours" class="col-2 p-2" (click)="onChange(colour)">
      <div class="color" [ngClass]="'color--' + colour">
        <svg
          *ngIf="colour === selected && colour !== 'not-available'"
          height="48"
          width="48"
          viewBox="-12 -12 48 48"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/>
        </svg>
      </div>
      <div class="text-center">
        <span>{{ colour !== 'not-available' ? colour?.replace('color-', '#') : colour }}</span>
      </div>
    </div>
  </div>
</app-modal>
