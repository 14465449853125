import { AppState } from '../index';
import { createSelector } from '@ngrx/store';
import { NodeType } from '../../core/constants/node-type';
import { NodeTemplateModel } from '../../core/models/node-template.model';

const selectNodeTemplatesState = (state: AppState) => state.templates;

export const selectNodeTemplatesSearchKeyword = createSelector(
  selectNodeTemplatesState,
  state => state.searchKeyword,
);

export const selectNodeTemplatesById = createSelector(
  selectNodeTemplatesState,
  state => state.byId,
);

export const selectNodeTemplateIdsByNodeType = createSelector(
  selectNodeTemplatesState,
  state => state.idsByServerNodeType,
);

export const selectNodeTemplatesByServerNodeType = (nodeTypes: NodeType[]) =>
  createSelector(
    selectNodeTemplateIdsByNodeType,
    selectNodeTemplatesById,
    (idsByServerNodeType, templatesById) => {
      return nodeTypes
        .filter(nodeType => idsByServerNodeType[nodeType] != null)
        .reduce((list, nodeType) => {
          return [...list, ...idsByServerNodeType[nodeType]];
        }, [])
        .map(id => templatesById[id]);
    },
  );

export const selectNodeTemplatesByNodeTypeWithKeyword = (serverNodeTypes: NodeType[]) =>
  createSelector(
    selectNodeTemplatesByServerNodeType(serverNodeTypes),
    selectNodeTemplatesSearchKeyword,
    (templates: NodeTemplateModel[], keywords) => {
      if (!keywords) {
        return templates;
      }
      const lowerCaseKeyword = keywords.toLowerCase();
      return templates.filter(tag => tag.title.toLowerCase().indexOf(lowerCaseKeyword) >= 0);
    },
  );

const selectNodeTemplateIdsByTypeFactory = (serverNodeTypes: NodeType[]) => {
  return createSelector(selectNodeTemplateIdsByNodeType, idsByServerNodeType => {
    return serverNodeTypes
      .filter(nodeType => idsByServerNodeType[nodeType] != null)
      .reduce((list, nodeType) => {
        return [...list, ...idsByServerNodeType[nodeType]];
      }, []);
  });
};

export const selectProjectNodeTemplateIds = selectNodeTemplateIdsByTypeFactory([NodeType.project]);

export const selectAssignmentNodeTemplateIds = selectNodeTemplateIdsByTypeFactory([
  NodeType.assignment,
]);

export const selectAssetNodeTemplateIds = selectNodeTemplateIdsByTypeFactory([NodeType.asset]);

export const selectElementNodeTemplateIds = selectNodeTemplateIdsByTypeFactory([NodeType.element]);

// export const selectProjectAndAssignmentNodeTemplateIds = createSelector(
//   selectProjectNodeTemplateIds,
//   selectAssignmentNodeTemplateIds,
//   (projectNodeTemplateIds, assignmentNodeTemplateIds) => {
//     return [...projectNodeTemplateIds, ...assignmentNodeTemplateIds];
//   },
// );

const selectNodeTemplateListFactory = selectNodeTemplateIds =>
  createSelector(selectNodeTemplateIds, selectNodeTemplatesById, (templateIds: number[], byId) => {
    return (templateIds || []).filter(id => byId[id] != null).map(id => byId[id]);
  });

export const selectProjectNodeTemplateList = selectNodeTemplateListFactory(
  selectProjectNodeTemplateIds,
);

export const selectAssignmentNodeTemplateList = selectNodeTemplateListFactory(
  selectAssignmentNodeTemplateIds,
);

export const selectAssetNodeTemplateList = selectNodeTemplateListFactory(
  selectAssetNodeTemplateIds,
);

export const selectElementNodeTemplateList = selectNodeTemplateListFactory(
  selectElementNodeTemplateIds,
);

const selectDefaultTemplateIdFactory = nodeTemplateListSelector => {
  return createSelector(nodeTemplateListSelector, (templateList: NodeTemplateModel[]) => {
    const template = (templateList || []).filter(template => template.isDefault)[0];
    return template?.id;
  });
};

export const selectDefaultAssignmentTemplateId = selectDefaultTemplateIdFactory(
  selectAssignmentNodeTemplateList,
);

export const selectDefaultProjectTemplateId = selectDefaultTemplateIdFactory(
  selectProjectNodeTemplateList,
);

export const selectDefaultAssetTemplateId = selectDefaultTemplateIdFactory(
  selectAssetNodeTemplateList,
);

export const selectDefaultElementTemplateId = selectDefaultTemplateIdFactory(
  selectElementNodeTemplateList,
);

export const getNodeTemplateListSelector = (nodeType: NodeType) => {
  switch (nodeType) {
    case NodeType.assignment:
    case NodeType.assignmentFolder:
      return selectAssignmentNodeTemplateList;

    case NodeType.project:
    case NodeType.projectFolder:
      return selectProjectNodeTemplateList;

    case NodeType.asset:
    case NodeType.assetFolder:
      return selectAssetNodeTemplateList;

    case NodeType.element:
    case NodeType.elementFolder:
      return selectElementNodeTemplateList;
  }
  throw new Error(`Unknown NodeType to get Node Template list: ${nodeType}`);
};

export const selectAvailableNodeTemplateOptionsWithProps = (nodeType: NodeType) =>
  createSelector(
    selectNodeTemplateIdsByNodeType,
    selectNodeTemplatesById,
    (templateIdsByNodeType, templatesById) => {
      const templateIds = templateIdsByNodeType[nodeType] ? templateIdsByNodeType[nodeType] : [];
      return templateIds
        .map(id => templatesById[id])
        .filter(template => !!template)
        .map(template => {
          return {
            id: template.id,
            label: template.title,
          };
        });
    },
  );
