import { OptionModel } from '../models/option.model';
import { IconType } from './icon-type';

export enum AssignmentViewType {
  compact = 1,
  medium = 2,
  mediumToRelaxed,
  relaxed = 4,
  custom = 5,
}

export const calendarViewOptions: OptionModel[] = [
  {
    id: AssignmentViewType.compact,
    label: 'Compact',
    tooltipText: 'Best for managing bookings',
    icon: IconType['grip-lines'],
  },
  {
    id: AssignmentViewType.medium,
    label: 'Focused',
    tooltipText: 'Great for resource scheduling',
    icon: IconType['expand'],
  },
  {
    id: AssignmentViewType.relaxed,
    label: 'Maximised',
    tooltipText: 'Expand cards to display everything',
    icon: IconType['window-maximize'],
  },
  // {
  //   id: AssignmentViewType.custom,
  //   label: 'Custom',
  // },
];

export const availableViewTypes = calendarViewOptions.map(option => option.id);
