import { AppState } from '../index';
import { createSelector } from '@ngrx/store';

const selectTemplateTagsState = (state: AppState) => state.templateTags;

export const selectTemplateTagsById = createSelector(
  selectTemplateTagsState,
  state => state.templateTagsById,
);

export const selectTemplateTagsByTemplateId = createSelector(
  selectTemplateTagsState,
  state => state.templateTagsByTemplateId,
);

export const selectTemplateTagsByTemplateByType = createSelector(
  selectTemplateTagsState,
  state => state.templateTagsByTemplateByType,
);

export const selectTemplateTagsByGroupId = createSelector(
  selectTemplateTagsState,
  state => state.templateTagsByGroupId,
);
