import { PermissionType } from '../../core/constants/permission-type';
import { RoleType } from '../../core/constants/role-type';

export class RolePermissionsState {
  permissionsByRole: PermissionsByRole;
}

export const initialRolePermissionState: RolePermissionsState = {
  permissionsByRole: {},
};

// Have to use type since interface does not support [x in enum]: any
export type PermissionsByRole = {
  [role in RoleType]?: PermissionType[];
};
