import { Pipe, PipeTransform } from '@angular/core';
import { RichSearchType } from '../../core/constants/rich-search-type';
import { getRichSearchTypeLabel } from '../../core/utils/rich-search.util';

@Pipe({
  name: 'richSearchTypeLabel',
})
export class RichSearchTypeLabelPipe implements PipeTransform {
  transform(type: RichSearchType): string {
    return getRichSearchTypeLabel(type);
  }
}
