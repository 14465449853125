<app-panel-subsection
  class="template-edit__subsection"
  [title]="title"
  [titleTooltip]="
    'Allows revenue tracking for assignment Layouts and cost tracking for asset layouts.'
  "
  [backgroundTransparent]="true"
  [headerRightTemplate]="figuresOnOffLayout"
>
</app-panel-subsection>

<ng-template #figuresOnOffLayout>
  <div
    role="button"
    class="layout-switch-btn"
    (click)="changeSwitch()"
    [ngClass]="{
      'layout-switch-btn-on': isSwitchedOn(),
      'layout-switch-btn-off': !isSwitchedOn()
    }"
  >
    <span>{{ isSwitchedOn() ? 'ON' : 'OFF' }}</span>
  </div>
</ng-template>
