import { TagsPopoverPage } from '../../core/constants/tags-popover-page';
import { TagType } from '../../core/constants/tag-type';

export class TagsPopoverState {
  page: TagsPopoverPage;
  previousPage?: TagsPopoverPage;
  templateId: number;
  nodeId?: number; // Only for node tags and node-group tags popovers
  groupId?: number; // Only for node-group tags popover
  tagId: number;
  tagType: TagType;
  searchKeyword: string;
  allowDelete: boolean;
}

export const initialTagsPopoverState: TagsPopoverState = {
  page: undefined,
  previousPage: undefined,
  templateId: undefined,
  nodeId: undefined,
  groupId: undefined,
  tagId: undefined,
  tagType: undefined,
  searchKeyword: undefined,
  allowDelete: false,
};
