<div class="assignment-checklist">
  <app-panel-subsection
    [title]="group.title"
    [headerToolbarTemplate]="assignmentChecklistToolbar"
    [backgroundTransparent]="false"
  >
    <table class="ap-table ap-table--bg-creamy table table-hover">
      <thead class="thead-light">
        <tr>
          <th scope="col">#</th>
          <th scope="col">Label</th>
          <th scope="col">Type</th>
          <th scope="col ">Actions</th>
        </tr>
      </thead>
      <tbody cdkDropList (cdkDropListDropped)="sortQuestionWidgets($event)">
        <tr
          class="assignment-checklist__task-row"
          *ngFor="let templateWidget of group?.widgets; index as index; trackBy: trackBy"
          cdkDrag
        >
          <!-- id -->
          <td>{{ index + 1 }}</td>
          <!-- label -->
          <td class="assignment-checklist__task-col-label">
            <span
              cdkDragHandle
              class="assignment-checklist__task-icon-move  fal fa-arrows-alt-v"
            ></span
            >{{ templateWidget.widget?.title }}
          </td>
          <!-- type -->
          <td>{{ templateWidget.widget?.widgetType | widgetTypeLabel }}</td>
          <!-- actions -->
          <td>
            <span>
              <app-btn-edit
                (click)="openChangeQuestionWidgetPopover(templateWidget)"
              ></app-btn-edit>
            </span>
          </td>
        </tr>

        <tr *ngIf="group?.widgets?.length === 0">
          <td colspan="4" class="assignment-checklist__no-content">
            There are no items in the Assignment Checklist yet.
          </td>
        </tr>
      </tbody>
    </table>
    <div class="assignment-checklist__footer">
      <button class="btn btn-sm btn-primary" (click)="showQuestionWidgetCreatePage()">
        Add Item
      </button>
    </div>
  </app-panel-subsection>
</div>

<ng-template #assignmentChecklistToolbar>
  <div class="assignment-checklist__toolbar">
    <button
      type="button"
      class="btn assignment-checklist__btn fal fa-edit"
      (click)="editAssignmentChecklist()"
    ></button>

    <button
      type="button"
      class="btn assignment-checklist__btn fal fa-plus"
      (click)="showQuestionWidgetCreatePage()"
    ></button>

    <button
      *ngIf="groupIds.indexOf(group.id) > 0"
      type="button"
      class="btn template-tag-group__btn fal fa-arrow-up"
      (click)="moveGroupUp(group.id)"
    ></button>

    <button
      *ngIf="groupIds.indexOf(group.id) < groups.length - 1"
      type="button"
      class="btn template-tag-group__btn fal fa-arrow-down"
      (click)="moveGroupDown(group.id)"
    ></button>
  </div>
</ng-template>
