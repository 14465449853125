import { WidgetModel } from '../../core/models/widget.model';

export class WidgetsState {
  byId: { [id: number]: WidgetModel };
  searchKeyword: string;
}

export const initialWidgetsState = {
  byId: {},
  searchKeyword: undefined,
};
