<div class="modal-header">
  <h4 class="modal-title">
    {{ file?.title }}
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss(false)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="image-modal__img_container">
    <img class="image-modal__img" [src]="file?.src" [alt]="file?.title"/>
  </div>

  <div class="apply-tags__button-group text-center">
    <div class="btn-group" role="group">
      <button type="button" class="btn btn-outline-light" (click)="activeModal.dismiss()">
        Close
      </button>
    </div>
  </div>
</div>
