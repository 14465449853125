<div class="sidebar-folder-trees">
  <app-sidebar-section
    *ngIf="favouritesRootFolder && favouritesRootFolder.children?.length"
    title="Favourites"
    [rootFolder]="favouritesRootFolder"
    [isCollapsed]="!isSidebarOpen"
  >
    <app-folder-tree-node
      [node]="favouritesRootFolder"
      [readonly]="!(loggedInUser | can: PermissionType.projectEditor)"
    ></app-folder-tree-node>
  </app-sidebar-section>

  <app-sidebar-section
    *ngIf="projectRootFolder"
    title="Assignments"
    [rootFolder]="projectRootFolder"
    [isCollapsed]="!isSidebarOpen"
  >
    <app-folder-tree-node
      [node]="projectRootFolder"
      [readonly]="!(loggedInUser | can: PermissionType.projectEditor)"
    ></app-folder-tree-node>
  </app-sidebar-section>

  <app-sidebar-section
    *ngIf="assetRootFolder"
    title="Resources"
    [rootFolder]="assetRootFolder"
    [isCollapsed]="!isSidebarOpen"
  >
    <app-folder-tree-node
      [node]="assetRootFolder"
      [readonly]="!(loggedInUser | can: PermissionType.assetEditor)"
    ></app-folder-tree-node>
  </app-sidebar-section>

  <app-sidebar-section
    *ngIf="elementRootFolder"
    title="Forms"
    [rootFolder]="elementRootFolder"
    [isCollapsed]="!isSidebarOpen"
  >
    <app-folder-tree-node
      [node]="elementRootFolder"
      [readonly]="!(loggedInUser | can: PermissionType.elementEditor)"
    ></app-folder-tree-node>
  </app-sidebar-section>
</div>
