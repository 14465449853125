import { NodeTagModel } from '../../core/models/node-tag.model';

export interface NodeTagsByNodeId {
  [nodeId: number]: NodeTagModel[];
}

export class NodeTagsState {
  nodeTagsById: { [joinId: number]: NodeTagModel };
  nodeTagsByNodeId: NodeTagsByNodeId;
}

export const initialNodeTagsState: NodeTagsState = {
  nodeTagsById: {},
  nodeTagsByNodeId: {},
};
