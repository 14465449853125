<div
  #input
  [ngClass]="[
    editable == false ? 'z-table__input--disabled' : '',
    cell.value?.length === 0 ? 'z-table__input--empty' : ''
  ]"
  class="z-table__input--assignment-templates"
  (click)="editable && onStartEdit()"
>
  <ng-container *ngFor="let item of cell.value; trackBy: trackBy">
    <div
      #input
      class="z-table__input z-table__input--assignment-template"
      [ngClass]="[
        'background--' + (item?.colorTheme ? (item?.colorTheme | colorString) : 'transparent'),
        editable == false ? 'z-table__input--disabled' : '',
        !item ? 'z-table__input--empty' : ''
      ]"
    >
      <ng-container *ngIf="item">
        <span
          class="fal mr-1"
          [ngClass]="['fa-' + (item?.icon | iconString), item?.icon ? '' : 'invisible']"
        ></span>
        <span>{{ item?.title }}</span>
      </ng-container>

      <span class="assignment-template__empty-text" *ngIf="!item">{{ 'Empty' }}</span>
    </div>
  </ng-container>

  <span class="z-table__input--empty" *ngIf="cell.value?.length === 0">
    {{ 'Empty' }}
  </span>
</div>
