import { WorkspaceUrlModel } from '../../core/models/workspace-url.model';

export class WorkspaceUrlsState {
  byId: { [id: number]: WorkspaceUrlModel };
  byGroupId: { [id: number]: WorkspaceUrlModel[] };
}

export const initialWorkspaceUrlsState: WorkspaceUrlsState = {
  byId: {},
  byGroupId: {},
};
