import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { WorkspaceModel } from '../models/workspace.model';
import { WorkspaceUserMetaState } from '../../store/workspace-user-meta/workspace-user-meta.state';
import { WorkspaceCacheKey } from '../constants/workspace-cache-key';
import { ResponseMessage } from '../models/response-message.model';
import { map } from 'rxjs/operators';

export interface PaginationConfig {
  page: number;
  results: number;
}

export interface NodeSearchConfig extends PaginationConfig {
  nodeTypes: number[];
  workspaceId: number;
  archived: boolean;
  keywords?: string;
}

@Injectable({ providedIn: 'root' })
export class WorkspaceService {
  constructor(private http: HttpClient) {}

  public loadWorkspace(slug: string): Observable<WorkspaceModel> {
    // todo: make these params optional
    const options = {
      params: new HttpParams()
        .set('users', 'true')
        .set('user-groups', 'true')
        .set('groups', 'true')
        .set('tags', 'true')
        .set('widgets', 'true')
        .set('shares', 'true')
        .set('urls', 'true')
        .set('templates', 'true')
        .set('tree', 'true'),
    };
    return this.http
      .get<ResponseMessage<WorkspaceModel>>(`${environment.apiBaseUrl}/workspace/${slug}`, options)
      .pipe(map(response => response.data));
  }

  public getWorkspaceUserMeta(workspaceSlug: string): Observable<WorkspaceUserMetaState> {
    return this.http.get<WorkspaceUserMetaState>(
      `${environment.apiBaseUrl}/user/workspace/${workspaceSlug}/meta`,
    );
  }

  public updateWorkspaceUserMeta(
    workspaceSlug: string,
    data: Partial<WorkspaceUserMetaState>,
  ): Observable<any> {
    return this.http.put(
      `${environment.apiBaseUrl}/user/workspace/${workspaceSlug}/meta/update`,
      data,
    );
  }

  public updateWorkspaceMeta(
    workspaceSlug: string,
    data: Partial<WorkspaceUserMetaState>,
  ): Observable<any> {
    return this.http.put(`${environment.apiBaseUrl}/workspace/${workspaceSlug}/meta/update`, data);
  }

  public refreshWorkspaceCache(workspaceSlug: string, key: WorkspaceCacheKey): Observable<any> {
    return this.http.patch(
      `${environment.apiBaseUrl}/workspace/${workspaceSlug}/refresh/${key}`,
      {},
    );
  }

  public emptyArchive(workspaceSlug: string): Observable<any> {
    return this.http.delete(
      `${environment.apiBaseUrl}/workspace/${workspaceSlug}/empty-archive`,
      {},
    );
  }
}
