import { createAction, props } from '@ngrx/store';
import {
  NodeGroupValueEditableProps,
  NodeGroupValueModel,
} from '../../core/models/node-group-value.model';

export const loadNodeGroupsFromNodeTree = createAction(
  '[NodeGroups] load node groups from node tree success',
  props<{ nodeGroups: NodeGroupValueModel[] }>(),
);

export const updateNodeGroupRequest = createAction(
  '[NodeGroups] update node group request',
  props<{
    nodeId: number;
    groupId: number;
    groupProps: Partial<NodeGroupValueEditableProps>;
  }>(),
);
export const updateNodeGroupSuccess = createAction(
  '[NodeGroups] update node group success',
  props<{ nodeGroup: NodeGroupValueModel }>(),
);
