import { Injectable } from '@angular/core';
import { OptionsService } from '../../core/services/options.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { RolePermissionsActions } from './index';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ErrorsActions } from '../errors';
import { of } from 'rxjs';

@Injectable()
export class RolePermissionsEffects {
  constructor(private optionsService: OptionsService, private actions$: Actions) {}

  loadRolePermissionsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RolePermissionsActions.loadRolePermissionsRequest),
      mergeMap(action =>
        this.optionsService.loadRolePermissions().pipe(
          map(rolePermissions => {
            return RolePermissionsActions.loadRolePermissionsSuccess({
              permissionsByRole: rolePermissions,
            });
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );
}
