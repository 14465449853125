import {Component, Input, TemplateRef} from '@angular/core';
import {ModalComponent, ModalSize, ModalTheme} from '../modal.component';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-header',
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss'],
})
export class ModalHeaderComponent {
  public readonly ModalSize = ModalSize;
  public readonly ModalTheme = ModalTheme;

  @Input()
  public template: TemplateRef<any>;

  @Input()
  public title: string;

  @Input()
  public iconUrl: string;

  constructor(public parent: ModalComponent, public activeModal: NgbActiveModal) {
    this.title = '';
  }
}
