import { Action, createReducer, on } from '@ngrx/store';
import { initialNodesState, NodesState } from './nodes.state';
import { NodesActions } from './index';
import { NodeModel } from '../../core/models/node.model';
import { WorkspacesActions } from '../workspaces';
import { NodeUtils } from '../../core/utils/node.util';
import { NodeType } from '../../core/constants/node-type';

const addNodeToState = (state: NodesState, node: NodeModel): NodesState => {
  // We need to remove the existing one so that byParent, byTemplate, byType are accurate
  if (state.nodesById[node.id]) {
    state = removeNodeFromState(state, state.nodesById[node.id]);
  }

  const model = {
    ...(state.nodesById[node.id] || {}),
    ...node,
  };

  return {
    ...(state || initialNodesState),

    rootNodesById: !model.parentNodeId
      ? {
          ...state.rootNodesById,
          [model.id]: model,
        }
      : state.rootNodesById,

    nodesById: {
      ...state.nodesById,
      [model.id]: model,
    },

    nodesByNodeType: {
      ...state.nodesByNodeType,
      [model.nodeType]: [
        ...(state.nodesByNodeType[model.nodeType] || []).filter(n => n.id !== model.id),
        model,
      ],
    },

    nodesByParentId: model.parentNodeId
      ? {
          ...state.nodesByParentId,
          [model.parentNodeId]: [
            ...(state.nodesByParentId[model.parentNodeId] || []).filter(n => n.id !== model.id),
            model,
          ],
        }
      : state.nodesByParentId,

    nodesByTemplateId: model.nodeTemplateId
      ? {
          ...state.nodesByTemplateId,
          [model.nodeTemplateId]: [
            ...(state.nodesByTemplateId[model.nodeTemplateId] || []).filter(n => n.id !== model.id),
            model,
          ],
        }
      : state.nodesByTemplateId,
  };
};

const removeNodeFromState = (state: NodesState, node: NodeModel): NodesState => {
  return {
    ...(state || initialNodesState),

    rootNodesById: !node.parentNodeId
      ? {
          ...state.rootNodesById,
          [node.id]: undefined,
        }
      : state.rootNodesById,

    nodesById: {
      ...state.nodesById,
      [node.id]: undefined,
    },

    nodesByNodeType: {
      ...state.nodesByNodeType,
      [node.nodeType]: [
        ...(state.nodesByNodeType[node.nodeType] || []).filter(n => n.id !== node.id),
      ],
    },

    nodesByParentId: node.parentNodeId
      ? {
          ...state.nodesByParentId,
          [node.parentNodeId]: [
            ...(state.nodesByParentId[node.parentNodeId] || []).filter(n => n.id !== node.id),
          ],
        }
      : state.nodesByParentId,

    nodesByTemplateId: node.nodeTemplateId
      ? {
          ...state.nodesByTemplateId,
          [node.nodeTemplateId]: [
            ...(state.nodesByTemplateId[node.nodeTemplateId] || []).filter(n => n.id !== node.id),
          ],
        }
      : state.nodesByTemplateId,
  };
};

/**
 * New Assignments store design
 */
const addAssignmentToState = (state: NodesState, node: NodeModel): NodesState => {
  const model = {
    ...(state.assignmentsById[node.id] || {}),
    ...node,
  };
  const dateKey = node.date || NodeUtils.awaitingAssignmentsDateKey;
  return {
    ...(state || initialNodesState),

    assignmentsById: {
      ...state.assignmentsById,
      [model.id]: model,
    },

    assignmentsByParentId: model.parentNodeId
      ? {
          ...state.assignmentsByParentId,
          [model.parentNodeId]: [
            ...(state.assignmentsByParentId[model.parentNodeId] || []).filter(
              n => n.id !== model.id,
            ),
            model,
          ],
        }
      : state.assignmentsByParentId,

    assignmentsByDate: {
      ...state.assignmentsByDate,
      [dateKey]: [
        ...(state.assignmentsByDate[dateKey] || []).filter(n => n.id !== model.id),
        model,
      ],
    },
  };
};

const removeAssignmentFromState = (state: NodesState, node: NodeModel): NodesState => {
  const dateKey = node.date || NodeUtils.awaitingAssignmentsDateKey;
  return {
    ...(state || initialNodesState),

    assignmentsById: {
      ...state.assignmentsById,
      [node.id]: undefined,
    },

    assignmentsByParentId: node.parentNodeId
      ? {
          ...state.assignmentsByParentId,
          [node.parentNodeId]: [
            ...(state.assignmentsByParentId[node.parentNodeId] || []).filter(n => n.id !== node.id),
          ],
        }
      : state.assignmentsByParentId,

    assignmentsByDate: {
      ...state.assignmentsByDate,
      [dateKey]: [...(state.assignmentsByDate[dateKey] || []).filter(n => n.id !== node.id)],
    },
  };
};

const reducer = createReducer<NodesState>(
  initialNodesState,

  on(NodesActions.loadNodesSuccess, (state, { nodes }) => {
    let processNodes = (state, node: NodeModel) => {
      if (node.children != null && node.children.length) {
        state = node.children.reduce((state, node) => {
          return processNodes(state, node);
        }, state);
      }
      return addNodeToState(state, node);
    };

    return nodes.reduce((state, node) => {
      return processNodes(state, node);
    }, state);
  }),

  on(WorkspacesActions.unloadCurrentWorkspace, NodesActions.unloadNodesRequest, state => {
    return {
      ...state,
      rootNodesById: {},
      nodesById: {},
      nodesByNodeType: {},
      nodesByParentId: {},
      nodesByTemplateId: {},
      searchKeyword: undefined,
    };
  }),

  on(NodesActions.addFolderShortcutSuccess, (state, { node }) => {
    return addNodeToState(state, node);
  }),

  on(NodesActions.addMultipleNodesSuccess, (state, { nodes }) => {
    return (nodes || []).reduce((state, node) => {
      return addNodeToState(state, node);
    }, state);
  }),

  on(NodesActions.removeNodeRequest, (state, { node }) => {
    return removeNodeFromState(state, node);
  }),

  on(NodesActions.archiveNodesSuccess, (state, { nodeIds }) => {
    return nodeIds.reduce((state, id) => {
      return removeNodeFromState(state, state.nodesById[id]);
    }, state);
  }),

  on(NodesActions.sortNodesRequest, (state, { nodeIds }) => {
    let idx = 0;
    return nodeIds.reduce((state, id) => {
      return addNodeToState(state, {
        ...state.nodesById[id],
        sortIndex: idx++,
      });
    }, state);
  }),

  on(NodesActions.updateSearchKeywordRequest, (state, { searchKeyword }) => ({
    ...state,
    searchKeyword,
  })),

  on(NodesActions.updateNodeEditedByUserDateSuccess, (state, { nodes, editedDate, editedBy }) => {
    return (nodes || []).reduce((state, node) => {
      return addNodeToState(state, {
        ...state.nodesById[node.id],
        editedBy,
        editedDate,
      });
    }, state);
  }),

  on(NodesActions.moveNodesRequest, (state, { nodeIds, toParentId, sortIndex }) => {
    let parentNode = state.nodesById[toParentId] || null;
    if (parentNode == null) {
      throw new Error(`Parent node doesn't exist: ${toParentId}`);
    }

    // Test for Parent <==> child swap.
    // eg. Parent Folder has been moved inside a Child Folder
    if (parentNode.parentNodeId) {
      const childParentSwapId = nodeIds.find(id => {
        return id === parentNode.parentNodeId;
      });

      if (childParentSwapId != null) {
        const n = state.nodesById[childParentSwapId];
        parentNode = {
          ...parentNode,
          parentNodeId: n.parentNodeId,
          depth: n.depth,
          path: n.path,
          sortIndex: n.sortIndex,
        };
      }
    }

    // We also need to refactor path and depth for all nodes and descendants
    const nodes = nodeIds.reduce((list, id) => {
      const n = state.nodesById[id];
      return [
        ...list,
        {
          ...n,
          parentNodeId: parentNode.id,
          depth: parentNode.depth + 1,
          path: `${parentNode.path}${parentNode.id}/`,
          sortIndex,
        },
      ];
    }, []);

    const descendants = NodeUtils.getDescendants(nodes, state.nodesByParentId)
      .filter(n => n.id !== parentNode.id) // Possible this is a Parent <==> Child swap
      .reduce((list, node) => {
        return [
          ...list,
          {
            ...node,
            parentNodeId: node.parent.id,
            depth: node.parent.depth + 1,
            path: `${node.parent.path}${node.parent.id}/`,
          },
        ];
      }, []);

    return [parentNode, ...nodes, ...descendants].reduce((state, n) => {
      return addNodeToState(state, n);
    }, state);
  }),

  on(NodesActions.addNodeNoteSuccess, (state, { nodeId, note }) => {
    let node = null;

    // Assignment
    if (state.assignmentsById[nodeId] != null) {
      node = state.assignmentsById[nodeId];
      return addAssignmentToState(state, {
        ...node,
        __notes: [...(node.__notes || []).filter(n => n.noteType !== note.noteType), note],
      });
    }

    // Node
    node = state.nodesById[nodeId];
    return addNodeToState(state, {
      ...node,
      __notes: [...(node.__notes || []).filter(n => n.noteType !== note.noteType), note],
    });
  }),

  on(NodesActions.updateNodeProfileSuccess, (state, { id, profile }) => {
    let node = null;

    // Assignment
    if (state.assignmentsById[id] != null) {
      node = state.assignmentsById[id];
      return addAssignmentToState(state, {
        ...node,
        profile,
      });
    }

    // Node
    node = state.nodesById[id];
    return addNodeToState(state, {
      ...node,
      profile,
    });
  }),

  on(NodesActions.updateNodeMetaSuccess, (state, { nodeId, nodeMeta }) => {
    let node = null;

    // Assignment
    if (state.assignmentsById[nodeId] != null) {
      node = state.assignmentsById[nodeId];
      return addAssignmentToState(state, {
        ...node,
        nodeMeta,
      });
    }

    // Node
    node = state.nodesById[nodeId];
    return addNodeToState(state, {
      ...node,
      nodeMeta,
    });
  }),

  on(NodesActions.removeNodeProfileRequest, (state, { id }) => {
    let node = null;

    // Assignment
    if (state.assignmentsById[id] != null) {
      node = state.assignmentsById[id];
      return addAssignmentToState(state, {
        ...node,
        profile: null,
      });
    }

    // Node
    node = state.nodesById[id];
    return addNodeToState(state, {
      ...node,
      profile: null,
    });
  }),

  /**
   * New Assignments store design
   */
  on(NodesActions.loadAssignmentsSuccess, (state, { nodes }) => {
    let processAssignments = (state, node: NodeModel) => {
      if (node.children != null && node.children.length) {
        state = node.children.reduce((state, node) => {
          return processAssignments(state, node);
        }, state);
      }
      return addAssignmentToState(state, node);
    };

    return nodes.reduce((state, node) => {
      return processAssignments(state, node);
    }, state);
  }),

  on(NodesActions.unloadAssignmentsRequest, state => {
    return {
      ...state,
      assignmentsById: {},
      assignmentsByParentId: {},
      assignmentsByDate: {},
    };
  }),

  on(NodesActions.sortAssignmentsRequest, (state, { nodeIds }) => {
    let idx = 0;
    return nodeIds.reduce((state, id) => {
      return addAssignmentToState(state, {
        ...state.assignmentsById[id],
        sortIndex: idx++,
      });
    }, state);
  }),

  on(
    NodesActions.addMultipleAssignmentsSuccess,
    NodesActions.copyAssignmentSuccess,
    (state, { nodes }) => {
      return nodes.reduce((state, node) => {
        return addAssignmentToState(state, node);
      }, state);
    },
  ),

  on(NodesActions.removeMultipleAssignments, (state, { nodes }) => {
    return nodes.reduce((state, node) => {
      return removeAssignmentFromState(state, node);
    }, state);
  }),

  on(NodesActions.updateAssignmentEditedBySuccess, (state, { nodeIds, editedBy, editedDate }) => {
    if (nodeIds.length == 0) {
      return state;
    }
    return nodeIds.reduce((state, id) => {
      return addAssignmentToState(state, {
        ...state.assignmentsById[id],
        editedBy,
        editedDate,
      });
    }, state);
  }),

  on(NodesActions.archiveAssignmentsSuccess, (state, { nodeIds }) => {
    return nodeIds.reduce((state, id) => {
      return removeAssignmentFromState(state, state.assignmentsById[id]);
    }, state);
  }),

  on(NodesActions.updateAssignmentReadonlyRequest, (state, { nodeId, readonly }) => {
    return addAssignmentToState(state, {
      ...state.assignmentsById[nodeId],
      readOnly: readonly,
    });
  }),

  on(NodesActions.toggleSelectedAssignment, (state, { assignmentId }) => {
    let ids = [];
    if (state.selectedIds.indexOf(assignmentId) === -1) {
      ids = [...state.selectedIds, assignmentId];
    } else {
      ids = state.selectedIds.filter(id => id !== assignmentId);
    }

    return {
      ...state,
      selectedIds: ids,
    };
  }),

  on(NodesActions.selectAllAssignmentsByDate, (state, { dateKey }) => {
    let ids = [...state.selectedIds];
    const assignments =
      state.assignmentsByDate[dateKey]?.filter(fItem => fItem?.nodeType === NodeType.assignment) ||
      [];

    assignments.forEach(assignment => {
      ids.push(assignment.id);
    });

    return {
      ...state,
      selectedIds: ids,
    };
  }),

  on(NodesActions.unselectAllAssignmentsByDate, (state, { dateKey }) => {
    const assignments =
      state.assignmentsByDate[dateKey]?.filter(fItem => fItem?.nodeType === NodeType.assignment) ||
      [];
    const idsToRemove = assignments.map(assignment => assignment.id);

    const selectedIds = state.selectedIds.filter(id => !idsToRemove.includes(id));

    return {
      ...state,
      selectedIds,
    };
  }),

  on(NodesActions.clearSelectedAssignments, state => {
    return {
      ...state,
      selectedIds: [],
    };
  }),

  on(NodesActions.moveAssignmentsRequest, (state, { assignmentIds, toDateKey, sortIndex }) => {
    return assignmentIds.reduce((newState, id) => {
      if (newState.assignmentsById[id] == null) {
        return newState;
      }

      const fromDateKey = newState.assignmentsById[id].date;
      const assignment = {
        ...newState.assignmentsById[id],
        date: toDateKey,
        sortIndex: sortIndex || newState.assignmentsById[id].sortIndex,
      };

      const childNodes = newState.assignmentsByParentId[id];

      if (childNodes?.length) {
        // Update child nodes
        const updatedChildNodes = childNodes.map(child => {
          const childAssignment = {
            ...newState.assignmentsById[child?.id],
            date: toDateKey,
          };
          return childAssignment;
        });

        // Update newState with updated child nodes
        updatedChildNodes?.forEach(childAssignment => {
          newState = {
            ...newState,
            assignmentsById: {
              ...newState.assignmentsById,
              [childAssignment.id]: childAssignment,
            },
            assignmentsByDate: {
              ...newState.assignmentsByDate,
              [fromDateKey]: (newState.assignmentsByDate[fromDateKey] || []).filter(
                node => node.id !== childAssignment.id,
              ),
              [toDateKey]:
                newState.assignmentsByDate[toDateKey] == null
                  ? [childAssignment]
                  : [...newState.assignmentsByDate[toDateKey], childAssignment],
            },
          };
        });
      }

      return {
        ...newState,
        assignmentsById: {
          ...newState.assignmentsById,
          [id]: assignment,
        },
        assignmentsByDate: {
          ...newState.assignmentsByDate,
          [fromDateKey]: (newState.assignmentsByDate[fromDateKey] || []).filter(
            node => node.id !== id,
          ),
          [toDateKey]:
            newState.assignmentsByDate[toDateKey] == null
              ? [assignment]
              : [...newState.assignmentsByDate[toDateKey], assignment],
        },
      };
    }, state);
  }),
);

export function nodesReducer(state: NodesState, action: Action) {
  return reducer(state, action);
}
