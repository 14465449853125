import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../store';
import { Router } from '@angular/router';
import { AppLayoutActions, AppLayoutSelectors } from '../../../store/app-layout';
import { SideBarMode } from '../../../shared/components/_layout/sidebar/sidebar.component';
import { selectCurrentWorkspace } from '../../../store/workspaces/workspaces.selectors';
import { selectUserMetaLastAssignmentLayout } from '../../../store/workspace-user-meta/workspace-user-meta.selectors';
import { WorkspaceModel } from '../../../core/models/workspace.model';
import { AssignmentLayoutMode } from '../../../core/constants/assignment-layout-mode';
import { PermissionType } from '../../../core/constants/permission-type';
import { WorkspaceUserModel } from '../../../core/models/workspace-user.model';
import { selectLoggedInWorkspaceUser } from '../../../store/workspace-users/workspace-users.selectors';
import { WorkspaceCacheKey } from '../../../core/constants/workspace-cache-key';
import { WorkspacesActions } from '../../../store/workspaces';

@Component({
  selector: 'app-workspace-settings',
  templateUrl: 'workspace-settings.component.html',
  styleUrls: ['workspace-settings.component.scss'],
})
export class WorkspaceSettingsComponent implements OnDestroy {
  private readonly subscription: Subscription = new Subscription();

  public readonly PermissionType = PermissionType;
  public readonly WorkspaceCacheKey = WorkspaceCacheKey;

  public user: WorkspaceUserModel;
  public workspace: WorkspaceModel;

  public lastLayout: AssignmentLayoutMode;
  public isSidebarOpen: boolean;

  constructor(private router: Router, private store: Store<AppState>) {
    this.subscription.add(
      this.store.pipe(select(selectLoggedInWorkspaceUser)).subscribe(user => {
        this.user = user;
      }),
    );

    this.subscription.add(
      this.store.pipe(select(selectCurrentWorkspace)).subscribe(workspace => {
        this.workspace = workspace;
      }),
    );

    this.subscription.add(
      this.store.pipe(select(selectUserMetaLastAssignmentLayout)).subscribe(layout => {
        this.lastLayout = layout;
      }),
    );

    this.subscription.add(
      this.store.pipe(select(AppLayoutSelectors.selectSidebarLeft)).subscribe(sidebar => {
        this.isSidebarOpen = !sidebar.collapsed;
      }),
    );
  }

  public onRefreshCache(key: WorkspaceCacheKey, event: any) {
    event.preventDefault();
    this.store.dispatch(WorkspacesActions.refreshWorkspaceCacheRequest({ key }));
  }

  public openTemplateSettings() {
    this.store.dispatch(AppLayoutActions.openSidebarSubLeft({ mode: SideBarMode.Templates }));
  }

  public openRootFolderList(event) {
    this.store.dispatch(
      AppLayoutActions.openSidebarLeft({ fullCollapse: false, mode: SideBarMode.FolderList }),
    );

    this.store.dispatch(
      AppLayoutActions.setSidebarSubLeft({
        disable: false,
        mode: SideBarMode.NodeList,
      }),
    );

    this.router.navigate(['ws', this.workspace.slug, 'content', 'schedule']);
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
