import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ENTER } from '@angular/cdk/keycodes';
import { select, Store } from '@ngrx/store';
import { WidgetType } from '../../../../core/constants/widget-type';
import { CurrencyMaskInputMode } from 'ngx-currency';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { merge, Observable, Subject, Subscription } from 'rxjs';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { dollarToRoundedCents } from '../../../../core/utils/financial.util';
import { NodeTemplateWidgetModel } from '../../../../core/models/node-template-widget.model';
import { momentDateFormat } from '../../../../shared/pipes/moment-date.pipe';
import { AppState } from '../../../../store';
import { selectAssets, selectProjects } from '../../../../store/nodes/node-enrichment.selectors';
import { WidgetSelectionList } from '../../../../core/models/widget.model';

@Component({
  selector: 'app-widget-field',
  templateUrl: './widget-field.component.html',
  styleUrls: ['./widget-field.component.scss'],
})
export class WidgetFieldComponent implements OnInit, OnDestroy {
  private readonly subscription: Subscription = new Subscription();

  public readonly WidgetType = WidgetType;
  public readonly CurrencyMaskInputMode = CurrencyMaskInputMode;

  @ViewChild('typeaheadInstance')
  public instance: NgbTypeahead;

  @Input()
  public widget: NodeTemplateWidgetModel;

  @Input()
  public form: UntypedFormGroup;

  @Input()
  public readonly: boolean;

  @Output()
  public closeEvent = new EventEmitter();

  @Output()
  public changeValueEvent = new EventEmitter();

  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  public widgetOptions: WidgetSelectionList[] = [];

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    if (this.widget.widget.widgetType === WidgetType.projectList) {
      this.subscription.add(
        this.store.pipe(select(selectProjects)).subscribe(allAssets => {
          this.widgetOptions = allAssets?.records?.map(item => {
            return {
              id: item?.id,
              label: item?.title,
            };
          });
        }),
      );
    }

    if (this.widget.widget.widgetType === WidgetType.assetList) {
      this.subscription.add(
        this.store.pipe(select(selectAssets)).subscribe(allAssets => {
          this.widgetOptions = allAssets?.map(item => {
            return {
              id: item?.id,
              label: item?.title,
            };
          });
        }),
      );
    }
  }

  updateWidgetValue() {
    if (this.readonly) {
      return;
    }
    const id = this.widget.id;
    const controlValue = this.form.controls[id].value;

    // if (!controlValue && typeof controlValue !== 'boolean') {
    //   return;
    // }

    if (this.widget.widget.widgetType === WidgetType.currency) {
      if (controlValue * 100 !== this.widget.value && this.form.controls[id].valid) {
        const roundedCentValue = dollarToRoundedCents(controlValue);
        this.changeValueEvent.emit(roundedCentValue);
      }
    } else if (this.widget.widget.widgetType === WidgetType.dateTime) {
      const dateTime = momentDateFormat(controlValue, 'serverDateTime');
      this.changeValueEvent.emit(dateTime);
    } else if (this.widget.widget.widgetType === WidgetType.time) {
      const time = momentDateFormat(controlValue, 'timeFull');
      this.changeValueEvent.emit(time);
    } else {
      if (controlValue !== this.widget.value && this.form.controls[id].valid) {
        this.changeValueEvent.emit(controlValue);
      }
    }
  }

  changeValue(value) {
    if (this.readonly) {
      return;
    }
    this.changeValueEvent.emit(value);
  }

  onButtonKeyDown(event) {
    if (this.readonly) {
      return;
    }
    if (event.keyCode === ENTER) {
      event.preventDefault();
      this.updateWidgetValue();
      this.closeEvent.emit();
    }
  }

  selectInputText(inputRef: HTMLInputElement) {
    if (inputRef.value === '0.00') {
      inputRef.select();
    }
  }

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(
      filter(() => {
        return !this.instance?.isPopupOpen();
      }),
    );

    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(
        term =>
          (term === ''
            ? this.widget.widget.choices
            : this.widget.widget.choices.filter(
                v => v.toLowerCase().indexOf(term.toLowerCase()) > -1,
              )
          ).slice(0, 200), // limit the item number in case of extreme large number of choices.
      ),
    );
  };

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
