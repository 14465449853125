import { Pipe, PipeTransform } from '@angular/core';
import { ChannelType } from '../../core/constants/channel-type';
import { ChatChannelViewModel } from '../../core/models/chat-channel-view.model';
import { NodeUtils } from '../../core/utils/node.util';

@Pipe({
  name: 'chatChannelTypeLabel',
})
export class ChatChannelTypeLabelPipe implements PipeTransform {
  transform(channel: ChatChannelViewModel): string {
    if (channel) {
      if (channel.channelType === ChannelType.workspace) {
        return 'Workspace';
      } else if (channel.channelType === ChannelType.node) {
        return NodeUtils.getNodeTypeLabel(channel.node?.nodeType);
      }
    }
  }
}
