import { Component, OnInit } from '@angular/core';
import { TableComponent } from '../../table.component';
import { AbstractTableEditableCellComponent } from '../abstract-table-editable-cell.component';
import { DecimalPipe } from '@angular/common';
import { TableEditableNumberComponent } from '../table-editable-number/table-editable-number.component';
import { NodeTemplateWidgetModel } from '../../../../core/models/node-template-widget.model';
import { WidgetUtils } from '../../../../core/utils/widget.util';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store';
import { NodeModel } from '../../../../core/models/node.model';
import { NodeWidgetRowsActions } from '../../../../store/node-widget-rows';
import { NodeWidgetValueModel } from '../../../../core/models/node-widget-value.model';
import { WidgetType } from '../../../../core/constants/widget-type';

@Component({
  selector: 'app-z-table-editable-assignment-calculation',
  templateUrl: './table-editable-assignment-calculation.component.html',
  styleUrls: ['./table-editable-assignment-calculation.component.scss'],
})
export class TableEditableAssignmentCalculationComponent extends TableEditableNumberComponent
  implements OnInit {
  private readonly ROW_NUMBER = 0;

  constructor(
    public table: TableComponent,
    protected decimalPipe: DecimalPipe,
    private store: Store<AppState>,
  ) {
    super(table, decimalPipe);
  }

  public ngOnInit(): void {
    AbstractTableEditableCellComponent.prototype.ngOnInit.call(this);

    const widget = this.cell.value as NodeTemplateWidgetModel;

    this.displayValue = WidgetUtils.getNormalizedNumericWidgetValue(widget, this.ROW_NUMBER);

    this.removeFormatting();
    this.addFormatting();
  }

  public onEndEdit() {
    this.removeFormatting();

    const widget = this.cell.value as NodeTemplateWidgetModel;
    const assignment = this.options?.assignment as NodeModel;

    const finalValue =
      this.displayValue && widget?.widget.widgetType === WidgetType.currency
        ? this.displayValue * 100
        : this.displayValue;

    const row = {
      ...widget.values?.[this.ROW_NUMBER],
      value: finalValue,
    } as NodeWidgetValueModel;

    this.cell.value = {
      ...widget,
      rows: widget.values?.splice(this.ROW_NUMBER, 1, row),
    };

    this.store.dispatch(
      NodeWidgetRowsActions.updateWidgetRowValueRequest({
        nodeId: assignment.id,
        widgetId: widget.id,
        value: finalValue,
        rowNumber: this.ROW_NUMBER,
        referenceId: null,
      }),
    );

    this.addFormatting();
    AbstractTableEditableCellComponent.prototype.onEndEdit.call(this);
  }
}
