import { AppState } from '../index';
import { createSelector } from '@ngrx/store';

export const selectModalArchiveState = (state: AppState) => state.archivePopover;

export const selectModalArchiveIsLoading = createSelector(
  selectModalArchiveState,
  state => state.isLoading,
);

export const selectModalArchiveReferenceCount = createSelector(
  selectModalArchiveState,
  state => state.referenceCount,
);

export const selectModalArchiveReferenceCountRequired = createSelector(
  selectModalArchiveState,
  state => state.referenceCountRequired,
);
