import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { WorkspaceModel } from '../models/workspace.model';
import { PermissionsByRole } from '../../store/role-permissions/role-permissions.state';

@Injectable({ providedIn: 'root' })
export class OptionsService {
  private options: any;
  private readonly optionsSubject: BehaviorSubject<any>;

  public get Options() {
    // Lazy load the Options when requested
    if (!this.optionsSubject.value) {
      this.getOptions()
        .pipe(first())
        .subscribe();
    }
    return this.optionsSubject;
  }

  constructor(private http: HttpClient) {
    this.optionsSubject = new BehaviorSubject<WorkspaceModel>(this.options);
  }

  public getOptions(): Observable<any> {
    return this.http.get<any>(`${environment.apiBaseUrl}/options`).pipe(
      map(options => {
        for (let i = 0, l = options.genderTypes.length; i < l; i++) {
          options.genderTypes[i] = { ...options.genderTypes[i] };
        }

        return options;
      }),
    );
  }

  public loadRolePermissions(): Observable<PermissionsByRole> {
    return this.http.get<PermissionsByRole>(
      `${environment.apiBaseUrl}/options/workspace-roles-permissions`,
    );
  }
}
