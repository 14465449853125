<div class="z-table">
  <div class="z-table-search">
    <div class="z-table-search__title">
      <ng-content select=".assignment-z-table__title"></ng-content>
    </div>

    <button
      *ngIf="!hideEditTable"
      class="btn btn-sm hide-on-sm hide-on-md mr-2"
      (click)="editTable()"
      [ngClass]="tableEditable ? 'btn-outline-light' : 'btn-primary'"
    >
      <i class="fal layout-mr-sm" [ngClass]="tableEditable ? 'fa-check' : 'fa-edit'"></i>
      <span class="hide-on-sm hide-on-md">Edit table</span>
    </button>

    <!-- <button
       *ngIf="showGeneratePdfAction"
       class="btn btn-sm hide-on-sm hide-on-md ml-2"
       [ngClass]="'btn-primary'"
       (click)="downloadAsPdf()"
     >
       <span class="hide-on-sm hide-on-md"> Generate PDF</span>
     </button>-->

    <button
      class="btn btn-outline-light btn-sm hide-on-sm hide-on-md mr-2"
      [ngbPopover]="tableMenu"
      [autoClose]="true"
      container="body"
    >
      <i class="fal fa-ellipsis-stroke-vertical layout-mr-sm"></i>
      <span class="hide-on-sm hide-on-md">Table options</span>
    </button>

    <div class="z-table-search__group-by z-table-stats" *ngIf="!hideStats">
      <!-- display columns count -->
      <ng-template #columnsSelectedMenu>
        <ul class="popover-menu">
          <li
            class="popover-menu__item"
            *ngFor="let hc of headerColumns; let hi = index; trackBy: trackByFn"
            [id]="'header-column' + hi"
            (click)="showHideColumn(hc)"
          >
            <app-btn-check [isChecked]="!hc?.collapsed"></app-btn-check>
            <span class="ml-2">{{ hc?.label ? hc.label : '-' }}</span>
          </li>
        </ul>
      </ng-template>
      <span
        class="btn btn-outline-light btn-sm hide-on-sm hide-on-md mr-2"
        [ngbPopover]="columnsSelectedMenu"
        triggers="click contextmenu"
        [autoClose]="'outside'"
        container="body"
        placement="auto left"
        popoverClass="z-table-stats__item--column-selected-popup"
      >
        <i class="fal fa-table-columns layout-mr-sm"></i>
        Columns: <span class="stats-value">{{ displayColumnCount }} selected</span>
      </span>

      <!-- group by -->
      <ng-template #groupBySelectedMenu>
        <ul class="popover-menu">
          <li
            class="popover-menu__item"
            *ngFor="let gc of groupAbleColumns; let hi = index; trackBy: trackByFn"
            [id]="'group-by-column' + hi"
            (click)="setGroupByColumn(gc)"
          >
            <!-- if summarise column is selected, hide the groupBy value -->
            <app-btn-check
              [isChecked]="!currentSummariseColumn && currentGroupColumn?.id === gc?.id"
            ></app-btn-check>
            <span class="ml-2">{{ gc?.label ? gc.label : '-' }}</span>
          </li>
        </ul>
      </ng-template>
      <span
        class="btn btn-outline-light btn-sm hide-on-sm hide-on-md mr-2"
        (contextmenu)="onColumnContextMenu($event)"
        [ngbPopover]="groupBySelectedMenu"
        triggers="click contextmenu"
        [autoClose]="'outside'"
        container="body"
        placement="bottom left"
        popoverClass="z-table-stats__item--column-selected-popup"
      >
        <!-- if summarise column is selected, hide the groupBy value -->
        <i class="fal fa-object-group layout-mr-sm"></i>
        Grouped by:
        <span class="stats-value">{{
          currentGroupColumn && !currentSummariseColumn ? currentGroupColumn?.label : 'empty'
          }}</span>
      </span>

      <!-- Comment Out Summarise By
      <ng-template #summariseBySelectedMenu>
        <ul class="popover-menu">
          <li
            class="popover-menu__item"
            *ngFor="let sc of summariseColumns; let hi = index; trackBy: trackByFn"
            [id]="'summarise-by-column' + hi"
            (click)="setSummariseByColumn(sc)"
          >
            <app-btn-check [isChecked]="currentSummariseColumn?.id === sc?.id"></app-btn-check>
            <span class="ml-2">{{ sc?.label ? sc.label : '-' }}</span>
          </li>
        </ul>
      </ng-template>
      <span
        class="z-table-stats__item z-table-stats__item--groupby"
        (contextmenu)="onColumnContextMenu($event)"
        [ngbPopover]="summariseBySelectedMenu"
        triggers="click contextmenu"
        [autoClose]="'outside'"
        container="body"
        placement="bottom left"
        popoverClass="z-table-stats__item--column-selected-popup"
      >
        Summarise by:
        <span class="stats-value">{{
          currentSummariseColumn ? currentSummariseColumn?.label : 'empty'
        }}</span>
      </span>
      -->
      <!-- groups hidden -->
      <ng-template #groupsCollapsedMenu>
        <ul class="popover-menu">
          <li class="popover-menu__item" (click)="showAllGroups()">
            <span>Unhide all</span>
          </li>
        </ul>
      </ng-template>
      <span
        class="btn btn-outline-light btn-sm hide-on-sm hide-on-md mr-2"
        *ngIf="groupsCollapsed > 0"
        (contextmenu)="onColumnContextMenu($event)"
        [ngbPopover]="groupsCollapsedMenu"
        triggers="click contextmenu"
        [autoClose]="true"
        container="body"
      >
        <i class="fal fa-eye-slash layout-mr-sm"></i>
        Groups hidden: <span class="stats-value">{{ groupsCollapsed }}</span>
      </span>

      <!-- columns hidden -->
      <ng-template #columnsCollapsedMenu>
        <ul class="popover-menu">
          <li class="popover-menu__item" (click)="showAllColumns()">
            <span>
              <i class="fal fa-eye layout-mr-sm"></i>
              Show all</span>
          </li>
        </ul>
      </ng-template>
      <span
        class="btn btn-outline-light btn-sm hide-on-sm hide-on-md mr-2"
        *ngIf="columnsCollapsed > 0"
        (contextmenu)="onColumnContextMenu($event)"
        [ngbPopover]="columnsCollapsedMenu"
        triggers="click contextmenu"
        [autoClose]="true"
        container="body"
      >
        <i class="fal fa-eye-slash layout-mr-sm"></i>
        Columns hidden: <span class="stats-value">{{ columnsCollapsed }}</span>
      </span>

      <!-- rows hidden -->
      <ng-template #rowsCollapsedMenu>
        <ul class="popover-menu">
          <li class="popover-menu__item" (click)="showAllRows()">
            <span>
              <i class="fal fa-eye layout-mr-sm"></i>
              Show all</span>
          </li>
        </ul>
      </ng-template>
      <span
        class="btn btn-outline-light btn-sm hide-on-sm hide-on-md mr-2"
        *ngIf="rowsCollapsed > 0"
        (contextmenu)="onColumnContextMenu($event)"
        [ngbPopover]="rowsCollapsedMenu"
        triggers="click contextmenu"
        [autoClose]="true"
        container="body"
      >
        <i class="fal fa-eye-slash layout-mr-sm"></i>
        Rows hidden: <span class="stats-value">{{ rowsCollapsed }}</span>
      </span>
    </div>

    <div class="z-table-search__search" *ngIf="!hideSearch">
      <app-z-table-search (searchEvent)="onSearch($event)"></app-z-table-search>
    </div>
  </div>

  <div class="z-table-header">
    <div
      class="z-table__row z-table-header__row"
      cdkDropList
      cdkDropListOrientation="horizontal"
      (cdkDropListDropped)="onMoveColumn($event)"
    >
      <div class="z-table__col z-table-header__col z-table__row-control">
        &nbsp;
        <!-- this is for layout and visual balance. Has no logical purpose. -->
      </div>

      <ng-container *ngFor="let col of headerColumns; trackBy: trackColumn; let colIdx = index">
        <div
          [id]="'col_' + col.id"
          class="z-table__col z-table-header__col"
          cdkDrag
          [cdkDragData]="col"
          [cdkDragDisabled]="col.editor === TableEditor.Spacer"
          cdkDragLockAxis="x"
          [ngClass]="[
            col | zTableColumnClasses: col,
            currentGroupColumn === col ? 'z-table-header__col--group' : ''
          ]"
        >
          <ng-template #columnMenu>
            <ul class="popover-menu">
              <li class="popover-menu__item" (click)="toggleColumn(col)">
                <span *ngIf="col.collapsed">Show</span>
                <span *ngIf="!col.collapsed">Hide</span>
              </li>
              <ng-container *ngIf="col.groupable">
                <li class="popover-menu__divider"></li>
                <li
                  *ngIf="groupBy != col.id"
                  class="popover-menu__item"
                  (click)="toggleGroupBy(col)"
                >
                  Group
                </li>
                <li
                  *ngIf="groupBy == col.id"
                  class="popover-menu__item"
                  (click)="toggleGroupBy(col)"
                >
                  Ungroup
                </li>
              </ng-container>
            </ul>
          </ng-template>

          <div
            class="z-table__col-label"
            *ngIf="col.editor !== TableEditor.Spacer"
            (contextmenu)="onColumnContextMenu($event)"
            [ngbPopover]="columnMenu"
            triggers="click contextmenu"
            [autoClose]="true"
            container="body"
          >
            {{ col.label }}
          </div>

          <div
            class="z-table__col-control"
            *ngIf="col.editor !== TableEditor.Spacer"
            (click)="toggleSort(col)"
          >
            <i
              class="fal"
              *ngIf="col.sortable === true"
              [ngClass]="{
                'fa-sort': currentSortColumn?.id !== col.id,
                'fa-sort-down': currentSortColumn?.id === col.id && currentSortDescending,
                'fa-sort-up': currentSortColumn?.id === col.id && !currentSortDescending
              }"
            ></i>
          </div>
        </div>
      </ng-container>

      <div
        class="z-table__col z-table-header__col z-table__row-menu"
        [ngClass]="{ 'z-table__row-menu-with--add-row-btn': allowNewRow }"
      >
        <ng-template #tableMenu>
          <ul class="popover-menu">
            <li class="popover-menu__info-title popover-menu__info-title--primary">
              Display data
            </li>
            <li class="popover-menu__item" (click)="showAllRows()">
              <i class="fal fa-table-rows layout-mr-lg popover_icon_style"></i>
              Show all rows
            </li>
            <li class="popover-menu__item" (click)="showAllColumns()">
              <i class="fal fa-table-columns layout-mr-lg popover_icon_style"></i>
              Show all columns
            </li>
            <li class="popover-menu__item" (click)="showAllRows(); showAllColumns()">
              <i class="fal fa-table layout-mr-lg popover_icon_style"></i>
              Show all
            </li>
            <li class="popover-menu__divider"></li>
            <li class="popover-menu__info-title popover-menu__info-title--primary">
              Table actions
            </li>
            <li class="popover-menu__item" (click)="exportToExcel()">
              <i class="fal fa-file-csv layout-mr-lg popover_icon_style"></i>
              Export table data
            </li>
            <li class="popover-menu__item" (click)="downloadAsPdf()">
              <i class="fal fa-file-pdf layout-mr-lg popover_icon_style"></i>
              Generate PDF
            </li>
            <li
              class="popover-menu__item"
              (click)="lockUnlockAssignments()"
              *ngIf="showAssignmentLockIcon"
            >
              <i class="fal fa-lock layout-mr-lg popover_icon_style"></i>
              {{ isAllAssignmentsLocked ? 'Unlock assignments' : 'Lock assignments' }}
            </li>
            <ng-container *ngIf="groupBy">
              <li class="popover-menu__divider"></li>
              <li class="popover-menu__item" (click)="unGroup()">
                <i class="fal fa-object-ungroup layout-mr-lg popover_icon_style"></i>
                Ungroup
              </li>
            </ng-container>
            <ng-container *ngIf="allowNewRow">
              <li class="popover-menu__divider"></li>
              <li class="popover-menu__item" (click)="onNewRow()">
                <i class="fal fa-plus layout-mr-lg popover_icon_style"></i>
                Add row
              </li>
            </ng-container>
          </ul>
        </ng-template>
        <button
          type="button"
          class="btn btn-menu fa fa-plus-circle"
          (click)="onNewRow()"
          *ngIf="allowNewRow"
        ></button>
        <!--   <span
             class="z-table__empty-add-row&#45;&#45;btn"
             role="button"
             (click)="onNewRow()"
             *ngIf="allowNewRow"
           >
             <i class="z-table__empty-add-row&#45;&#45;btn-icon fa fa-plus-circle" aria-hidden="true"></i>
           </span>-->
      </div>
    </div>
  </div>

  <div
    class="z-table-body"
    cdkDropList
    (cdkDropListDropped)="onMoveGroup($event)"
    *ngIf="bodyGroups?.length"
  >
    <ng-container *ngFor="let group of bodyGroups; trackBy: trackGroup; let groupIdx = index">
      <div
        class="z-table__group z-table-body__group"
        [ngClass]="{ 'z-table__group--empty': group.id === zTableProp.EmptyGroupId }"
        cdkDrag
        [cdkDragDisabled]="!currentGroupColumn || group.id === zTableProp.EmptyGroupId"
        [cdkDragData]="group"
        cdkDragLockAxis="y"
        cdkDragPreviewContainer="parent"
      >
        <div
          *ngIf="currentGroupColumn && group.id !== zTableProp.EmptyGroupId"
          class="z-table__group-header"
        >
          <div class="z-table__group-control" cdkDragHandle>
            <div class="z-table__group-control-move">
              <i class="fal fa-arrows-alt-v"></i>
            </div>
          </div>
          <div class="z-table__group-label" (click)="toggleGroupRows(group)">
            {{ group.id || 'Empty' }}
          </div>
        </div>

        <div
          *ngIf="group?.rows?.length"
          class="z-table__group-rows"
          cdkDropList
          (cdkDropListDropped)="onMoveRow($event, group)"
        >
          <ng-container *ngFor="let row of group.rows; trackBy: trackRow; let rowIdx = index">
            <div
              [id]="row.id || 'row_' + rowIdx"
              class="z-table__row z-table-body__row"
              [ngClass]="{
                'z-table-body__row--last':
                  group?.rows?.length && group?.rows?.length - 1 === rowIdx,
                'z-table__row--hidden': row.collapsed === true,
                'z-table__row--empty': row.id === zTableProp.EmptyRowId
              }"
              cdkDrag
              [cdkDragDisabled]="row.id === zTableProp.EmptyRowId"
              [cdkDragData]="row"
              cdkDragLockAxis="y"
              cdkDragPreviewContainer="parent"
            >
              <div
                class="z-table__col z-table-body__col z-table__row-control"
                cdkDragHandle
                (click)="toggleRow(row)"
              >
                <div class="z-table__row-control-move">
                  <i class="fal fa-eye"></i>
                </div>
              </div>

              <ng-container *ngFor="let cell of row.columns; let colIdx = index">
                <div
                  [id]="'cell_' + headerColumns[colIdx].id"
                  class="z-table__col z-table-body__col"
                  [ngClass]="cell | zTableColumnClasses: headerColumns[colIdx]"
                  [title]="headerColumns[colIdx].label"
                >
                  <app-z-table-editors
                    [rowIndex]="rowIdx"
                    [group]="group"
                    [row]="row"
                    [column]="headerColumns[colIdx]"
                    [cell]="cell"
                    [sortDescending]="currentSortDescending"
                    (startEdit)="onStartEdit($event)"
                    (endEdit)="onEndEdit($event)"
                    [tableEditable]="tableEditable"
                  >
                  </app-z-table-editors>
                </div>
              </ng-container>

              <div class="z-table__col z-table-body__col z-table__row-menu">
                <ng-container *ngIf="rowMenu">
                  <app-z-table-row-menu *ngIf="row.id !== zTableProp.EmptyRowId">
                    <ng-container *ngTemplateOutlet="rowMenu; context: { row: row, index: rowIdx }">
                    </ng-container>
                  </app-z-table-row-menu>
                </ng-container>
              </div>
              <app-content-loader [scope]="'z-table-row-' + row.id"></app-content-loader>
            </div>
          </ng-container>
        </div>
      </div>

      <div
        class="z-table__group-total"
        [ngClass]="{
          summary: currentSummariseColumn
        }"
        *ngIf="showTotalsRows && headerColumns?.length && group?.rows?.length"
      >
        <div class="z-table__row z-table-group-total__row">
          <div class="z-table__col z-table-group-total__col z-table__row-control">&nbsp;</div>

          <ng-container *ngFor="let col of headerColumns; trackBy: trackColumn; let colIdx = index">
            <div
              class="z-table__col z-table-group-total__col"
              [ngClass]="col | zTableColumnClasses: col"
            >
              <div class="z-table__col-label z-table-group-total__col-label">
                {{ group.totals[colIdx] | number: '1.2-2'
                }}<!-- todo: formatting/pipe needs to be configurable -->
              </div>
            </div>
          </ng-container>

          <div class="z-table__col z-table-group-total__col z-table__row-menu">&nbsp;</div>
        </div>
      </div>

      <ng-container *ngIf="!group?.rows?.length">
        <div class="z-table__empty-table">
          <p class="z-table__empty-message">
            This table is empty...
            <span class="z-table__empty-add-row" *ngIf="allowNewRow">
              Add a new row
              <span role="button" (click)="onNewRow()">
                <i class="z-table__empty-add-row--icon fa fa-plus-circle" aria-hidden="true"></i>
              </span>
            </span>
          </p>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <div class="z-table-footer" *ngIf="footerColumns?.length">
    <div class="z-table__row z-table-footer__row">
      <div class="z-table__col z-table-footer__col z-table__row-control">
        &nbsp;
        <!-- this is for layout and visual balance. Has no logical purpose. -->
      </div>
      <ng-container *ngFor="let col of footerColumns; let colIdx = index">
        <div
          class="z-table__col z-table-footer__col"
          [ngClass]="col | zTableColumnClasses: headerColumns[colIdx]"
        >
          {{ col.value }}
        </div>
      </ng-container>
      <div class="z-table__col z-table-footer__col z-table__row-menu">
        &nbsp;
        <!-- this is for layout and visual balance. Has no logical purpose. -->
      </div>
    </div>
  </div>

  <div class="z-table-pages" *ngIf="pageCount > 1">
    <ul class="z-table-pages__list">
      <li
        class="btn btn-outline-light mr-2"
        (click)="prevPage()"
        [ngClass]="{
          disabled: page === PAGINATION_PAGE_NUMBER_1
        }"
      >
        &lt; Prev
      </li>

      <li
        *ngIf="page - PAGINATION_VISIBLE_PAGES > 0"
        class="btn btn-outline-light mr-2"
        (click)="gotoPage(PAGINATION_PAGE_NUMBER_1)"
      >
        First...
      </li>

      <li
        class="btn mr-2"
        *ngFor="let pg of pageList; let idx = index; trackBy: trackByFn"
        [ngClass]="{
          'btn-primary': pg == page,
          'btn-outline-light': pg !== page
        }"
        (click)="gotoPage(pg)"
      >
        {{ pg }}
      </li>

      <li
        *ngIf="page + PAGINATION_VISIBLE_PAGES < pageCount"
        class="btn btn-outline-light mr-2"
        (click)="gotoPage(pageCount)"
      >
        ...Last
      </li>

      <li class="btn btn-outline-light mr-2" (click)="nextPage()">
        Next &gt;
      </li>
    </ul>
  </div>
</div>
