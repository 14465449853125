import { TemplateTagModel } from '../../core/models/template-tag.model';

export interface TemplateTagsState {
  templateTagsById: { [joinId: number]: TemplateTagModel };
  templateTagsByTemplateId: { [templateId: number]: TemplateTagModel[] };
  templateTagsByTemplateByType: {
    [templateId: number]: { [type: number]: TemplateTagModel[] };
  };
  templateTagsByGroupId: { [groupId: number]: TemplateTagModel[] };
}

export const initialTemplateTagsState = {
  templateTagsById: {},
  templateTagsByTemplateId: {},
  templateTagsByTemplateByType: {},
  templateTagsByGroupId: {},
};
