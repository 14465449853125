export enum GanttChartCustomColumnType {
  label = 'label',
  // startDate = 'startDate',
  // endDate = 'endDate',
  dateRange = 'dateRange',
  available = 'available',
  budget = 'budget',
  cost = 'cost',
  target = 'target',
  complete = 'complete',
  assigned = 'assigned',
}
