import { AppState } from '../index';
import { createSelector } from '@ngrx/store';
import { selectNodeTagsByNodeId } from '../node-tags/node-tags.selectors';
import { selectAllTagIds, selectAllTags, selectTagsById } from '../tags/tags.selectors';
import { SelectionType } from '../../core/constants/selection-type';
import {
  selectTemplateTagsByGroupId,
  selectTemplateTagsByTemplateByType,
  selectTemplateTagsByTemplateId,
} from '../template-tags/template-tags.selectors';
import { selectNodeTemplatesById } from '../templates/templates.selectors';
import { TagType } from '../../core/constants/tag-type';

const selectTagsPopoverState = (state: AppState) => state.tagsPopover;

export const selectTagsPopoverPage = createSelector(selectTagsPopoverState, state => state.page);

export const selectTagsPopoverPreviousPage = createSelector(
  selectTagsPopoverState,
  state => state.previousPage,
);

export const selectTagsPopoverSearchKeyword = createSelector(
  selectTagsPopoverState,
  state => state.searchKeyword,
);

export const selectTagsPopoverAllowDelete = createSelector(
  selectTagsPopoverState,
  state => state.allowDelete,
);

export const selectTagsPopoverNodeId = createSelector(
  selectTagsPopoverState,
  state => state.nodeId,
);

export const selectTagsPopoverGroupId = createSelector(
  selectTagsPopoverState,
  state => state.groupId,
);

export const selectTagsPopoverTemplateId = createSelector(
  selectTagsPopoverState,
  state => state.templateId,
);

export const selectTagsPopoverTemplate = createSelector(
  selectTagsPopoverTemplateId,
  selectNodeTemplatesById,
  (templateId, byId) => byId?.[templateId],
);

export const selectTagsPopoverTagId = createSelector(selectTagsPopoverState, state => state.tagId);

export const selectTagsPopoverTagType = createSelector(
  selectTagsPopoverState,
  state => state.tagType,
);

export const selectTagsPopoverSelectionType = createSelector(selectTagsPopoverTagType, tagType => {
  if (tagType === TagType.stamp || tagType === TagType.primary) {
    return SelectionType.single;
  } else {
    return SelectionType.multiple;
  }
});

export const selectTagsPopoverTag = createSelector(
  selectTagsPopoverTagId,
  selectTagsById,
  (tagId, tagsById) => {
    return tagId && tagsById[tagId];
  },
);

/*
export const selectTagsPopoverNodeTagIds = createSelector(
  selectTagsPopoverNodeId,
  selectSortedTagIdsByNodeId,
  (nodeId, tagIdsByNodeId) => {
    return tagIdsByNodeId[nodeId] ? tagIdsByNodeId[nodeId] : [];
  },
);
*/

export const selectTagsPopoverNodeTags = createSelector(
  selectTagsPopoverNodeId,
  selectNodeTagsByNodeId,
  (nodeId, nodeTagsByNodeId) => {
    return nodeTagsByNodeId[nodeId] ? nodeTagsByNodeId[nodeId] : [];
  },
);

export const selectTagsPopoverTemplateTags = createSelector(
  selectTagsPopoverTemplateId,
  selectTagsPopoverGroupId,
  selectTagsPopoverTagType,
  selectTemplateTagsByTemplateByType,
  selectTemplateTagsByGroupId,
  (templateId, groupId, tagType, templateTagsByTemplateByType, templateTagsByGroupId) => {
    if (groupId && groupId > 0) {
      return templateTagsByGroupId[groupId] ? templateTagsByGroupId[groupId] : [];
    }
    return templateTagsByTemplateByType[templateId] &&
      templateTagsByTemplateByType[templateId][tagType]
      ? templateTagsByTemplateByType[templateId][tagType]
      : [];
  },
);

createSelector(selectTagsPopoverTagType, selectAllTags, (tagType, allTags) => {
  return allTags.filter(tag => tag.tagType === tagType);
});
export const selectTagsPopoverAvailableTagsForTemplate = createSelector(
  selectTagsPopoverTemplateId,
  selectNodeTemplatesById,
  selectTagsPopoverTagType,
  selectTagsById,
  selectAllTagIds,

  (templateId, templatesById, tagType, tagsById, allTagIds) => {
    const template = templatesById[templateId];
    if (template) {
      let tagTypeSet = [];
      switch (tagType) {
        case TagType.stamp:
          tagTypeSet = [TagType.stamp];
          break;

        case TagType.primary:
        case TagType.general:
        default:
          tagTypeSet = [TagType.primary, TagType.general];
          break;
      }

      return allTagIds
        .filter(id => tagsById[id] && tagTypeSet.indexOf(tagsById[id].tagType) >= 0)
        .map(id => tagsById[id])
        .sort((a, b) => {
          if (a.tagType > b.tagType) {
            return -1;
          } else if (a.tagType < b.tagType) {
            return 1;
          } else {
            if (a.title > b.title) {
              return 1;
            } else {
              return -1;
            }
          }
        });
    } else {
      return [];
    }
  },
);

export const selectTagsPopoverAvailableTagsForTemplateWithKeyword = createSelector(
  selectTagsPopoverAvailableTagsForTemplate,
  selectTagsPopoverSearchKeyword,
  (availableTags, keyword) => {
    if (!keyword) {
      return availableTags;
    }
    const lowerCaseKeyword = keyword.toLowerCase();
    return availableTags.filter(
      tag =>
        tag.title.toLowerCase().indexOf(lowerCaseKeyword) >= 0 ||
        (tag.searchTerms ? tag.searchTerms.indexOf(lowerCaseKeyword) >= 0 : false),
    );
  },
);

export const selectTagsPopoverAvailableTagsForNode = createSelector(
  selectTagsPopoverTemplateId,
  selectTemplateTagsByTemplateId,
  selectTagsById,
  selectTagsPopoverTagType,
  selectNodeTemplatesById,
  (templateId, templateTagsByTemplateId, tagsById, tagType, nodeTemplatesById) => {
    const template = nodeTemplatesById[templateId];
    let templateTags = templateTagsByTemplateId[templateId]
      ? templateTagsByTemplateId[templateId]
      : [];
    return templateTags
      .filter(tag => tag && tag.tagType === tagType)
      .map(templateTag => {
        return { ...templateTag, tag: tagsById[templateTag.id] };
      });
  },
);
