import { createAction, props } from '@ngrx/store';
import { WorkspaceGroupType } from '../../core/constants/workspace-group-type';
import { TagType } from '../../core/constants/tag-type';

export const showTagGroupCreatePage = createAction(
  '[Group Tags Popover] show  tag group create page',
  props<{ groupType: WorkspaceGroupType; templateId: number }>(),
);

export const showTagGroupEditPage = createAction(
  '[Group Tags Popover] show  tag group edit page',
  props<{ groupId: number }>(),
);

export const showTagCreatePage = createAction(
  '[Group Tags Popover] show  tag create page',
  props<{ groupId: number; tagType: TagType }>(),
);

export const showTagEditPage = createAction(
  '[Group Tags Popover] show  tag edit page',
  props<{ tagId: number; tagType: TagType; groupId: number }>(),
);
