<div
  class="shared-link"
  (click)="selectLink()"
  [ngClass]="{
    'shared-link--selected': selectedLink?.id === sharedLink?.id
  }"
>
  <div class="shared-link__heading">
    <div class="shared-link__title-wrapper">
      <i class="fal fa-share-alt mr-2"></i>
      <span>{{ sharedLink?.metaData?.title ?? 'Schedule share link' }}</span>
      <app-edit-status
        [editedDate]="sharedLink.createdDate"
        [actionText]="'Link generated'"
        [showIcon]="false"
        [label]="'Generated'"
        class="status"
      ></app-edit-status>
    </div>
    <div class="shared-link__url-wrapper">
      <div class="shared-link__url">
        {{ sharedLink | sharedLinkUrl }}
      </div>
      <div class="shared-link__actions-inline">
        <button
          class="shared-link__btn btn fal fa-copy"
          [cdkCopyToClipboard]="sharedLink | sharedLinkUrl"
          [ngbTooltip]="'Copy to Clipboard'"
          container="body"
        ></button>
        <a
          class="shared-link__btn btn fal fa-external-link"
          (click)="openLink(sharedLink.hash)"
          [ngbTooltip]="'Navigate to the Link'"
          container="body"
        ></a>
        <a
          class="shared-link__btn btn fal fa-trash"
          (click)="remove()"
          [ngbTooltip]="'Remove the Link'"
          container="body"
        ></a>
      </div>
    </div>
  </div>
</div>
