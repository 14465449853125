import { OptionModel } from '../models/option.model';

export enum GanttChartViewType {
  compact = 1,
  relaxed,
  custom,
}

export const ganttChartViewOptions: OptionModel[] = [
  {
    id: GanttChartViewType.compact,
    label: 'Compact',
  },
  {
    id: GanttChartViewType.relaxed,
    label: 'Relaxed',
  },
  {
    id: GanttChartViewType.custom,
    label: 'Custom',
  },
];
