<ngb-accordion>
  <ngb-panel [disabled]="disabled" *ngIf="showOptions?.includes('Forms')">
    <ng-template ngbPanelTitle>
      <div class="row align-items-center">
        <div class="col-auto mx-2">
          <i class="fal fal-icon fa-clipboard-list-check"></i>
        </div>
        <div class="col text-truncate layout-title">
          <label class="m-1 text-truncate">Forms</label>
          <p class="m-1 text-truncate sub-text">Create forms to capture data</p>
        </div>
      </div>
    </ng-template>
    <ng-template ngbPanelContent>
      <app-allowed-templates
        [type]="nodeTypes.element"
        [selectedTemplate]="selectedTemplate"
        [selectedTemplates]="selectedTemplates"
        [selectedAssetTemplates]="selectedAssetTemplates"
        [selectedFormTemplates]="selectedFormTemplates"
      ></app-allowed-templates>
    </ng-template>
  </ngb-panel>

  <ngb-panel [disabled]="disabled" *ngIf="showOptions?.includes('Financials')">
    <ng-template ngbPanelTitle>
      <div class="row align-items-center">
        <div class="col-auto mx-2">
          <i class="fal fal-icon fa-sack-dollar"></i>
        </div>
        <div class="col text-truncate layout-title">
          <label class="m-1 text-truncate">Financials</label>
          <p class="m-1 text-truncate sub-text">Record cost and revenue</p>
        </div>
      </div>
    </ng-template>
    <ng-template ngbPanelContent>
      <div class="template-overlay-settings-item">
        <app-template-pricing
          [selectedTemplate]="selectedTemplate"
          [type]="'revenue'"
          [title]="'Revenue Records'"
        ></app-template-pricing>
        <app-template-pricing
          [selectedTemplate]="selectedTemplate"
          [type]="'cost'"
          [title]="'Cost Records'"
        ></app-template-pricing>
      </div>
    </ng-template>
  </ngb-panel>

  <ngb-panel [disabled]="disabled" *ngIf="showOptions?.includes('Status Tags')">
    <ng-template ngbPanelTitle>
      <div class="row align-items-center">
        <div class="col-auto mx-2">
          <i class="fal fal-icon fa-check-double"></i>
        </div>
        <div class="col text-truncate layout-title">
          <label class="m-1 text-truncate">Status Tags</label>
          <p class="m-1 text-truncate sub-text">Add status groups</p>
        </div>
      </div>
    </ng-template>
    <ng-template ngbPanelContent>
      <app-template-group-tags
        [selectedTemplate]="selectedTemplate"
        [groupType]="WorkspaceGroupType.stamp"
        [groups]="stampTagGroups"
      ></app-template-group-tags>
    </ng-template>
  </ngb-panel>

  <ngb-panel [disabled]="disabled" *ngIf="showOptions?.includes('Calculations')">
    <ng-template ngbPanelTitle>
      <div class="row align-items-center">
        <div class="col-auto mx-2">
          <i class="fal fal-icon fa-calculator-simple"></i>
        </div>
        <div class="col text-truncate layout-title">
          <label class="m-1 text-truncate">Calculations</label>
          <p class="m-1 text-truncate sub-text">Add figures and calculations</p>
        </div>
      </div>
    </ng-template>
    <ng-template ngbPanelContent>
      <app-template-figures
        [selectedTemplate]="selectedTemplate"
        [calculationWidgets]="calculationWidgets"
        [calIds]="calIds"
      ></app-template-figures>
    </ng-template>
  </ngb-panel>

  <ngb-panel [disabled]="disabled" *ngIf="showOptions?.includes('Gantt Chart')">
    <ng-template ngbPanelTitle>
      <div class="row align-items-center">
        <div class="col-auto mx-2">
          <i class="fal fal-icon fa-calculator-simple"></i>
        </div>
        <div class="col text-truncate layout-title">
          <label class="m-1 text-truncate">Gantt Chart</label>
          <p class="m-1 text-truncate sub-text">Track using Gantt chart</p>
        </div>
      </div>
    </ng-template>
    <ng-template ngbPanelContent>
      <app-template-gantt-chart></app-template-gantt-chart>
    </ng-template>
  </ngb-panel>
</ngb-accordion>
