<div
  class="assignment-body"
  [ngClass]="[ locked ? 'assignment-body--locked' : '']"
>
  <div class="assignment-body__card" *ngIf="!hideFirstCard">
    <ng-content></ng-content>
  </div>

  <div class="assignment-body__card " *ngIf="secondCardTemplate" [ngClass]="{'layout-mt-lg': !hideFirstCard}">
    <ng-container *ngTemplateOutlet="secondCardTemplate"></ng-container>
  </div>
</div>
