import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthActions } from '../../../store/auth';
import { AvatarUploadModalComponent } from '../avatar-upload-modal/avatar-upload-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-profile-photo-menu',
  templateUrl: './profile-photo-menu.component.html',
})
export class ProfilePhotoMenuComponent implements OnInit {
  constructor(private store: Store, private modalService: NgbModal) {}

  ngOnInit(): void {}

  changePhoto() {
    this.modalService.open(AvatarUploadModalComponent, { size: 'sm' });
  }

  removePhoto() {
    this.store.dispatch(AuthActions.removeCurrentUserProfileRequest());
  }
}
