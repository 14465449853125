import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { SaveStatus } from '../../../core/constants/save-status';

@Component({
  selector: 'app-popover-layout',
  templateUrl: './popover-layout.component.html',
  styleUrls: ['./popover-layout.component.scss'],
})
export class PopoverLayoutComponent implements OnInit {
  @Input() title: string;
  @Input() previousPage: string;
  @Input() hideCloseButton: boolean;
  @Input() saveStatus: SaveStatus;
  @Input() embedded: boolean;

  @Input() iconTemplateRef: TemplateRef<any>;
  @Output() closePopover = new EventEmitter();
  @Output() goPrevious = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  close() {
    this.closePopover.emit();
  }

  previous() {
    this.goPrevious.emit();
  }
}
