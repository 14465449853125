import { Action, createReducer, on } from '@ngrx/store';
import { ArchivesPopoverActions } from './index';
import { ArchivesActions } from '../archives';
import { ArchivesPopoverState, initialArchivesPopoverState } from './archives-popover.state';

const reducer = createReducer(
  initialArchivesPopoverState,
  on(ArchivesPopoverActions.openArchivesPopover, state => {
    return {
      ...initialArchivesPopoverState,
    };
  }),
  on(
    ArchivesActions.restoreArchivedNodeFromArchivesPopoverRequest,
    ArchivesActions.deleteArchivedNodeFromArchivesPopoverRequest,
    (state): ArchivesPopoverState => {
      return {
        ...state,
        hasArchiveChanged: true,
      };
    },
  ),

  on(
    ArchivesActions.restoreArchivedNodeFromArchivesPopoverRequest,
    ArchivesActions.deleteArchivedNodeFromArchivesPopoverRequest,
    (state, { nodeId, isAssignment }) => {
      if (isAssignment) {
        return {
          ...state,
          inProcessAssignmentIds:
            state.inProcessAssignmentIds.indexOf(nodeId) >= 0
              ? state.inProcessAssignmentIds
              : [...state.inProcessAssignmentIds, nodeId],
        };
      } else {
        return {
          ...state,
          inProcessNodeIds:
            state.inProcessNodeIds.indexOf(nodeId) >= 0
              ? state.inProcessNodeIds
              : [...state.inProcessNodeIds, nodeId],
        };
      }
    },
  ),
  on(
    ArchivesActions.loadArchivedNodeTreeSuccess,
    (state): ArchivesPopoverState => {
      return {
        ...state,
        inProcessNodeIds: [],
      };
    },
  ),
  on(
    ArchivesActions.loadArchivedNodeTreeSuccess,
    (state): ArchivesPopoverState => {
      return {
        ...state,
        inProcessAssignmentIds: [],
      };
    },
  ),
);

export function archivesPopoverReducer(state: ArchivesPopoverState, action: Action) {
  return reducer(state, action);
}
