import { Action, createReducer, on } from '@ngrx/store';
import { initialWorkspaceUsersState, WorkspaceUsersState } from './workspace-users.state';
import { WorkspaceUsersActions } from './index';
import { getRandomAvatarColor } from './workspace-users.util';
import { AuthActions } from '../auth';
import { WorkspaceUserMetaActions } from '../workspace-user-meta';

const reducer = createReducer(
  initialWorkspaceUsersState,
  on(
    WorkspaceUsersActions.loadCurrentWorkspaceUsersSuccess,
    (state, { users }): WorkspaceUsersState => {
      let usersById = {};
      let userIds = [];
      users.forEach(user => {
        usersById = {
          ...usersById,
          [user.userId]: {
            ...user,
            metaData: {
              ...user.metaData,
              avatarColor: getRandomAvatarColor(),
            },
          },
        };
        userIds = [...userIds, user.userId];
      });
      return {
        ...state,
        currentWorkspaceUserIds: userIds,
        currentWorkspaceUsersById: usersById,
      };
    },
  ),
  on(
    WorkspaceUsersActions.inviteWorkspaceUserRequest,
    (state): WorkspaceUsersState => {
      return {
        ...state,
        isSendingInvitation: true,
      };
    },
  ),
  on(
    WorkspaceUsersActions.inviteWorkspaceUserSuccess,
    (state, { user }): WorkspaceUsersState => {
      const userIds =
        state.currentWorkspaceUserIds.indexOf(user.userId) >= 0
          ? state.currentWorkspaceUserIds
          : [...state.currentWorkspaceUserIds, user.userId];

      return {
        ...state,
        currentWorkspaceUserIds: userIds,
        currentWorkspaceUsersById: {
          ...state.currentWorkspaceUsersById,
          [user.userId]: user,
        },
        isSendingInvitation: false,
      };
    },
  ),
  on(
    WorkspaceUsersActions.deleteWorkspaceUserRequest,
    (state, { id, userId }): WorkspaceUsersState => {
      return {
        ...state,
        currentWorkspaceUserIds: state.currentWorkspaceUserIds.filter(uId => uId !== userId),
        currentWorkspaceUsersById: {
          ...state.currentWorkspaceUsersById,
          [userId]: undefined,
        },
      };
    },
  ),
  on(
    WorkspaceUsersActions.patchWorkspaceUserRoleSuccess,
    WorkspaceUsersActions.updateWorkspaceUserBelongedGroupsSuccess,
    (state, { user }) => {
      return {
        ...state,
        currentWorkspaceUsersById: {
          ...state.currentWorkspaceUsersById,
          [user.userId]: user,
        },
      };
    },
  ),

  on(AuthActions.patchCurrentUserProfile, (state, { profile, currentUserId }) => {
    return {
      ...state,
      currentWorkspaceUsersById: {
        ...state.currentWorkspaceUsersById,
        [currentUserId]: {
          ...state.currentWorkspaceUsersById[currentUserId],
          profile,
        },
      },
    };
  }),

  on(
    WorkspaceUserMetaActions.updateWorkspaceUserProfileIcon,
    (state, { profileIcon, currentUserId }) => {
      return {
        ...state,
        currentWorkspaceUsersById: {
          ...state.currentWorkspaceUsersById,
          [currentUserId]: {
            ...state.currentWorkspaceUsersById[currentUserId],
            metaData: {
              ...state.currentWorkspaceUsersById[currentUserId].metaData,
              profileIcon,
            },
          },
        },
      };
    },
  ),
);

export function workspaceUsersReducer(state: WorkspaceUsersState, action: Action) {
  return reducer(state, action);
}
