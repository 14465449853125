import { NodeType } from './node-type';
import { OptionModel } from '../models/option.model';
import { elementsRootFolderName } from './root-folder-names';

export enum CustomViewNodeType {
  project = NodeType.project,
  assignment = NodeType.assignment,
  asset = NodeType.asset,
  element = NodeType.element,
}

export const viewNodeTypeOptions: OptionModel[] = [
  // {
  //   id: CustomViewNodeType.project,
  //   label: assignmentsRootFolderName,
  // },
  // {
  //   id: CustomViewNodeType.asset,
  //   label: assetsRootFolderName,
  // },
  {
    id: CustomViewNodeType.element,
    label: elementsRootFolderName,
  },
];
