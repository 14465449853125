import { Action, createReducer, on } from '@ngrx/store';
import { ArchivePopoverState, initialArchivePopoverState } from './archive-popover.state';
import { ArchivePopoverActions } from './index';

const reducer = createReducer(
  initialArchivePopoverState,
  on(ArchivePopoverActions.checkReferenceCountRequest, state => ({
    ...state,
    referenceCountRequired: true,
    referenceCount: undefined,
    isLoading: true,
  })),

  on(ArchivePopoverActions.checkReferenceCountSuccess, (state, { referenceCount }) => ({
    ...state,
    referenceCount,
    isLoading: false,
  })),

  on(ArchivePopoverActions.noReferenceCountRequired, state => ({
    ...initialArchivePopoverState,
  })),
);

export function archivePopoverReducer(state: ArchivePopoverState, action: Action) {
  return reducer(state, action);
}
