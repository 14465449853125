import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-truncate-text',
  templateUrl: './truncate-text.component.html',
  styleUrls: ['./truncate-text.component.scss'],
})
export class TruncateTextComponent implements OnInit {
  @Input() value: string;
  @Input() limit: number;
  @Input() bulletPoints: boolean;

  constructor() {}

  ngOnInit(): void {}
}
