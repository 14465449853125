import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TagsActions } from './index';
import { catchError, concatMap, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { TagService } from '../../core/services/tag.service';
import { EMPTY, of } from 'rxjs';
import { ErrorsActions } from '../errors';
import { TagModel } from '../../core/models/tag.model';
import { TemplateTagsActions } from '../template-tags';
import { NodeTagsActions } from '../node-tags';
import { select, Store } from '@ngrx/store';
import { selectWorkspacesCurrentSlug } from '../workspaces/workspaces.selectors';

@Injectable()
export class TagsEffects {
  constructor(private actions$: Actions, private store: Store, private tagService: TagService) {}

  loadTagsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TagsActions.loadTagsRequest),
      mergeMap(action =>
        this.tagService.loadTags(action.workspaceSlug).pipe(
          map(tags => {
            return TagsActions.loadTagsSuccess({ tags });
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  addTagRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TagsActions.addTagRequest),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      mergeMap(([action, slug]) =>
        this.tagService
          .addTag(
            slug,
            action.title,
            action.colorTheme,
            action.icon,
            action.tagType,
            action.searchTerms,
            action.sortIndex,
          )
          .pipe(
            map((tag: TagModel) => {
              return TagsActions.addTagSuccess({ tag });
            }),
            catchError(error => {
              return of(ErrorsActions.goToErrorPage({ error }));
            }),
          ),
      ),
    ),
  );

  updateTagRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TagsActions.updateTagRequest),
      mergeMap(action =>
        this.tagService.updateTag(action.tagId, action.tagProps).pipe(
          switchMap(() => {
            return EMPTY;
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  deleteTagRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TagsActions.deleteTagRequest),
      mergeMap(action =>
        this.tagService.removeTag(action.tagId).pipe(
          concatMap(() => [
            TemplateTagsActions.removeTemplateTagsByTagId({
              tagId: action.tagId,
            }),
            NodeTagsActions.removeNodeTagsByTagId({
              tagId: action.tagId,
            }),
          ]),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  sortTagsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TagsActions.sortTagsRequest),
      mergeMap(action =>
        this.tagService.sortTags(action.tagIds).pipe(
          switchMap(() => {
            return EMPTY;
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );
}
