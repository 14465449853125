<aside
  class="sidebar"
  [ngClass]="{
    'sidebar--sm': size === SideBarSize.Small,
    'sidebar--md': size === SideBarSize.Medium,
    'sidebar--lg': size === SideBarSize.Large,
    'sidebar--xl': size === SideBarSize.XLarge,
    'sidebar--left': position === SideBarPosition.Left,
    'sidebar--right': position === SideBarPosition.Right,
    'sidebar--full-collapse': fullCollapse,
    'sidebar--collapsed': collapsed || disable,
    'sidebar--disable': disable
  }"
>
  <app-sidebar-toggle
    class="sidebar__toggle"
    [position]="position"
    (toggleEvent)="onToggle($event)"
  ></app-sidebar-toggle>

  <app-sidebar-toggle-bar
    class="sidebar__toggle-bar"
    [position]="position"
    (toggleEvent)="onToggle($event)"
  ></app-sidebar-toggle-bar>

  <app-sidebar-header class="sidebar__header" *ngIf="headerTemplate" [template]="headerTemplate">
  </app-sidebar-header>

  <app-sidebar-footer class="sidebar__footer" *ngIf="footerTemplate" [template]="footerTemplate">
  </app-sidebar-footer>

  <div class="sidebar__body">
    <ng-content></ng-content>
  </div>

  <app-content-loader
    class="sidebar__loader"
    scope="sidebar-{{ position === SideBarPosition.Left ? 'left' : 'right' }}"
  ></app-content-loader>
</aside>
