<div class="assignment-asset-fields">
  <ng-container *ngFor="let template of nodeTemplates; trackBy: trackBy">
    <div class="assignment-field w-100" [ngClass]="readonly ? 'assignment-field--readonly' : ''">
      <div class="assignment-field__content">
        <div class="assignment-section-title">{{ template.title }}</div>
        <div class="assignment-field__values">
          <div
            class="assignment-asset-field"
            (click)="requestEdit($event, template)"
            [ngClass]="[
              displayAsList
                ? 'assignment-asset-field--display-as-list'
                : 'assignment-asset-field--display-not-as-list'
            ]"
          >
            <ng-container *ngFor="let node of template.nodes; trackBy: trackBy">
              <app-assignment-asset
                class="assignment-asset-field__item"
                [node]="node.reference"
                [showProfilePhoto]="showProfilePhoto"
                [displayAsList]="displayAsList"
                [isDuplicate]="
                  template?.allowDuplicates &&
                  referenceNodesByDateCount[node.referenceNodeId + ':' + node.date] > 1
                "
                (viewEvent)="nodeView($event, node)"
              ></app-assignment-asset>
            </ng-container>
          </div>
        </div>
      </div>
      <!-- JO2b1eNW - related ticket -->
      <!-- <span
        class="assignment-field__edit-icon fal fa-edit"
        (click)="requestEdit($event, template)"
      ></span> -->
    </div>
  </ng-container>
</div>
