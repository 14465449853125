import { createAction, props } from '@ngrx/store';
import { NodeType } from '../../core/constants/node-type';

export const showWidgetsPopover = createAction(
  '[Widgets Popover] show widgets popover',
  props<{ templateId: number }>(),
);

export const showWidgetCreatePopover = createAction(
  '[Widgets Popover] show widget create popover',
  props<{ templateId: number; nodeType: NodeType; selectedWidgetType: number }>(),
);

export const showWidgetChangePopover = createAction(
  '[Widgets Popover] show widget change popover',
  props<{ templateId: number; widgetId: number }>(),
);

export const goBackWidgetsPage = createAction(
  '[Widgets Popover] go back widgets page',
  props<{ templateId: number }>(),
);

export const showWidgetCreatePage = createAction(
  '[Widgets Popover] show widget-create page',
  props<{ selectedWidgetType: number }>(),
);

export const showWidgetChangePage = createAction(
  '[Widgets Popover] show widget-change page',
  props<{ widgetId: number }>(),
);

export const resetWidgetEditPage = createAction('[Widgets Popover] reset widget-edit page');
