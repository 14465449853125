<ng-template #groupToolbar>
  <div class="template-tag-group__toolbar">
    <button
      #groupEditOrigin
      type="button"
      class="btn template-tag-group__btn fal fa-edit"
      (click)="editGroup()"
    ></button>
    <button
      *ngIf="groupIds.indexOf(group.id) > 0"
      type="button"
      class="btn template-tag-group__btn fal fa-arrow-up"
      (click)="moveGroupUp(group.id)"
    ></button>
    <button
      *ngIf="groupIds.indexOf(group.id) < groups.length - 1"
      type="button"
      class="btn template-tag-group__btn fal fa-arrow-down"
      (click)="moveGroupDown(group.id)"
    ></button>
  </div>
</ng-template>

<div class="template-tag-group">
  <app-panel-subsection
    [title]="group?.title"
    [headerToolbarTemplate]="groupToolbar"
    [backgroundTransparent]="false"
  >
    <ul cdkDropList (cdkDropListDropped)="sortTags($event)" class="template-tag-group__list">
      <li
        cdkDrag
        class="template-tag-group__list-item"
        *ngFor="let tag of group?.tags; trackBy: trackTagBy"
      >
        <div cdkDragHandle class="template-tag-group__icon-move  fal fa-arrows-alt-v"></div>
        <div
          app-selectable-brick
          [color]="tag?.tag?.colorTheme"
          [icon]="tag?.tag?.icon"
          [label]="tag?.tag?.title"
          class="template-tag-group__list-tag"
          (click)="openTagsPopover()"
        ></div>
      </li>

      <li *ngIf="group.tags?.length === 0" class="template-tag-group__no-content">
        There are no {{ group?.type | groupItemName: true }} in this group yet.
      </li>
    </ul>

    <div class="template-tag-group__footer">
      <button class="btn btn-sm btn-primary" (click)="openTagsPopover()">
        Select {{ tagType | tagTypeLabel }}
      </button>
    </div>
  </app-panel-subsection>
</div>
