import { AppState } from '../index';
import { createSelector } from '@ngrx/store';
import { NodeUtils } from '../../core/utils/node.util';

const selectWorkspaceUrlsState = (state: AppState) => state.workspaceUrls;

export const selectWorkspaceUrlsById = createSelector(
  selectWorkspaceUrlsState,
  state => state.byId,
);

export const selectWorkspaceUrlsByGroupId = createSelector(
  selectWorkspaceUrlsState,
  state => state.byGroupId,
);

export const selectWorkspaceUrls = createSelector(selectWorkspaceUrlsState, state =>
  Object.values(state.byId).sort(NodeUtils.sortByIndex),
);
