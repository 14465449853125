<div *ngIf="file" class="file-uploader-thumb">
  <div class="file-uploader-list-item-preview">
    <div *ngIf="file.preview" class="ico-preview">
      <div
        class="file-uploader-list-item-preview"
        [ngStyle]="{ 'background-image': 'url(' + file.preview + ')' }"
      ></div>
    </div>

    <div *ngIf="!file.preview" class="ico-preview" (click)="onClick($event, file)">
      <app-file-lazy-loader *ngIf="isImage(file)" [file]="file"></app-file-lazy-loader>

      <div
        *ngIf="!isImage(file)"
        class="ico-preview"
        [ngClass]="{
          'is-audio': isAudio(file),
          'is-video': isVideo(file),
          'is-doc': isDocument(file)
        }"
      >
        <div class="ico-preview-title">{{ file.type }}</div>
      </div>
    </div>
  </div>
  <button *ngIf="sortable" class="sort-handle file-uploader-list-item-move" type="button">
    <!--    <fa-icon [icon]="faArrowsAlt"></fa-icon>-->
  </button>
  <button
    *ngIf="removable"
    type="button"
    class="file-uploader-list-item-cancel"
    (click)="onRemove($event, file)"
  >
    X
  </button>
</div>
