import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CustomViewNodeType} from '../../../core/constants/custom-view-node-type';
import {AssignmentViewType} from '../../../core/constants/assignment-view-type';
import {CardLayoutType} from '../../../core/constants/card-layout-type';
import {NodeModel} from '../../../core/models/node.model';
import {NodeTemplateWidgetModel} from '../../../core/models/node-template-widget.model';
import {NodeTagModel} from '../../../core/models/node-tag.model';
import {NodeTemplateModel} from '../../../core/models/node-template.model';
import {BehaviourType} from '../../../core/constants/behaviour-type';
import {WidgetType} from '../../../core/constants/widget-type';

@Component({
  selector: 'app-assignment-element-fields',
  templateUrl: './assignment-element-fields.component.html',
  styleUrls: ['./assignment-element-fields.component.scss'],
})
export class AssignmentElementFieldsComponent {
  public readonly ViewType = AssignmentViewType;
  public readonly CustomViewNodeType = CustomViewNodeType;
  public readonly CardLayoutType = CardLayoutType;
  public readonly behaviourTypes = BehaviourType;
  public readonly WidgetType = WidgetType;

  @Input() nodeTemplates: NodeTemplateModel[];
  @Input() showDuplication: boolean;
  @Input() readonly: boolean;
  @Input() fieldNodeType: CustomViewNodeType;

  @Input() assignmentViewType: AssignmentViewType;
  @Input() widgetsNodeTypes: CustomViewNodeType[];
  @Input() tagsNodeTypes: CustomViewNodeType[];
  @Input() cardLayout: CardLayoutType;

  @Output() editEvent = new EventEmitter<NodeTemplateModel>();
  @Output() nodeViewEvent = new EventEmitter<{
    node: NodeModel;
    templateBehaviourType: BehaviourType;
  }>();

  constructor() {
  }

  requestEdit(event, template: NodeTemplateModel) {
    event.stopPropagation();
    if (this.readonly) {
      return;
    }
    this.editEvent.emit(template);
  }

  nodeView(event, node: NodeModel, templateBehaviourType: BehaviourType) {
    event.stopPropagation();
    this.nodeViewEvent.emit({node, templateBehaviourType});
  }

  getFormStatus(item: NodeTemplateModel): string {
    const result = item?.nodes?.every(node => {
      // Check if every widget in each node has a non-empty value
      return node?.reference?.widgets
        .filter(
          widget =>
            widget?.widget?.widgetType !== WidgetType.heading &&
            widget?.widget?.widgetType !== WidgetType.lineSeparator &&
            widget?.widget?.widgetType !== WidgetType.subHeading &&
            widget?.widget?.widgetType !== WidgetType.paragraph,
        )
        .every(widget => widget?.value !== undefined && widget?.value !== null);
    });
    return result ? 'Complete' : 'In progress';
  }

  getBackgroundColor(item: NodeTemplateModel): string {
    return this.getFormStatus(item) === 'Complete' ? '#D7F0EB' : '#FCF8DB';
  }

  trackBy(item: NodeTemplateModel) {
    return item.id;
  }

  nodeTrackBy(item: NodeModel) {
    return item.id;
  }

  widgetsTrackBy(item: NodeTemplateWidgetModel) {
    return item.id;
  }

  tagsTrackBy(item: NodeTagModel) {
    return item.id;
  }
}
