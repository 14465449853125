import { UserGroupsPopoverPage } from '../../core/constants/user-groups-popover-page';

export class UserGroupsPopoverState {
  page: UserGroupsPopoverPage;
  groupId: number;
}

export const initialUserGroupsPopoverState: UserGroupsPopoverState = {
  page: undefined,
  groupId: undefined,
};
