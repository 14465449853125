import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { defaultPlacements } from '../../../core/constants/placements';
import { SizeType } from '../../../core/constants/size-type';

@Component({
  selector: 'app-btn-menu',
  templateUrl: './btn-menu.component.html',
  styleUrls: ['./btn-menu.component.scss'],
})
export class BtnMenuComponent implements OnInit {
  @Input() menuContentTemplate: TemplateRef<any>;
  @Input() size: SizeType;
  defaultPlacements = defaultPlacements;

  constructor() {}

  ngOnInit(): void {}
}
