import { OptionModel } from '../models/option.model';

export enum RoleType {
  role_admin = 'role_admin',
  role_manager = 'role_manager',
  role_allocator = 'role_allocator',
  role_member = 'role_member',
}

export const roleTypeKeys: string[] = Object.keys(RoleType);

export const roleTypeOptions: OptionModel[] = [
  {
    id: RoleType.role_admin,
    label: 'Admin',
  },
  {
    id: RoleType.role_manager,
    label: 'Manager',
  },
  {
    id: RoleType.role_allocator,
    label: 'Allocator',
  },
  {
    id: RoleType.role_member,
    label: 'Member',
  },
];
