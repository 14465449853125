import { WorkspaceGroupModel } from '../../core/models/workspace-group.model';
import { NodeTemplateModel } from '../../core/models/node-template.model';

export const extractWorkspaceGroupsFromServerNodeTemplates = (templates: NodeTemplateModel[]) => {
  let groups: WorkspaceGroupModel[] = [];
  (templates || []).forEach(template => {
    groups = [...groups, ...(template.groups || [])];
  });

  return groups;
};
