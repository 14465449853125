import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbDate, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { ngbDateUtil } from '../../../../core/utils/ngb-date.util';
import { TableComponent } from '../../table.component';
import { AbstractTableEditableCellComponent } from '../abstract-table-editable-cell.component';

@Component({
  selector: 'app-z-table-editable-date',
  templateUrl: './table-editable-date.component.html',
  styleUrls: ['./table-editable-date.component.scss'],
})
export class TableEditableDateComponent extends AbstractTableEditableCellComponent
  implements OnInit {
  @ViewChild(NgbInputDatepicker) datePicker: NgbInputDatepicker;

  constructor(public table: TableComponent) {
    super(table);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.cell.value = this.cell.value || '';
  }

  // deprecated - input box approach removed
  // public onChange(event) {
  //   this.previousValue = this.cell.value;
  //   this.cell.value = event.target.value;
  //   this.input && this.input.nativeElement.blur();
  // }

  onDateSelection(date: NgbDate) {
    this.previousValue = this.cell.value;
    this.cell.value = ngbDateUtil.convertToDateKey(date);
    this.input && this.input.nativeElement.blur();
    this.datePicker.close();
    super.onEndEdit();
  }
}
