import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map, take } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../store';
import { selectUserIsLogin } from '../../store/auth/auth.selectors';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private store: Store<AppState>,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | boolean {
    return this.store.pipe(
      select(selectUserIsLogin),
      concatMap(isLogin => {
        if (!isLogin) {
          return this.authService.hasOpenServerSession().pipe(
            map(user => {
              if (user) {
                return true;
              }
            }),
            catchError((err: HttpErrorResponse) => {
              console.error('AuthGuard error: ', err);
              this.router.navigate(['/public/login']);
              return of(false);
            }),
          );
        }

        return of(true);
      }),
      take(1), // take 1 value from the Observable then complete
    );
  }
}
