import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { NoData, NoDataTypeIds } from '../../../core/constants/no-data-settings';
import { getOptionById } from '../../../core/utils/no-data-settings.util';

@Component({
  selector: 'app-no-data',
  templateUrl: './no-data.component.html',
  styleUrls: ['./no-data.component.scss'],
})
export class NoDataComponent implements OnInit, OnDestroy {
  @Output() buttonClick = new EventEmitter<void>(); // Output event for button click
  @Input() type: NoDataTypeIds = NoDataTypeIds.default; // Default value for type
  public settings: NoData;
  private readonly subscription = new Subscription();

  constructor() {}

  ngOnInit(): void {
    // Initialize component
    this.settings = getOptionById(this.type); // Get option by ID
  }

  public onBtnClick(): void {
    this.buttonClick.emit(); // Emit button click event
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe(); // Proper cleanup
  }
}
