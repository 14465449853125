import { Component, OnDestroy, OnInit } from '@angular/core';
import { OverlayPopoverRef } from '../../../shared/components/overlay-popover/overlay-popover-ref';
import { Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { selectGenericPopoverData } from '../../../store/generic-popover/generic-popover.selectors';
import { LinkEditModel } from '../../../core/models/link-edit.model';
import { GenericPage } from '../../../core/constants/generic-page';

@Component({
  selector: 'app-link-edit-popover',
  templateUrl: './link-edit-popover.component.html',
  styleUrls: ['./link-edit-popover.component.scss'],
})
export class LinkEditPopoverComponent implements OnInit, OnDestroy {
  title: string;

  subscription = new Subscription();

  constructor(private popoverRef: OverlayPopoverRef, private store: Store) {}

  ngOnInit(): void {
    this.subscription.add(
      this.store.pipe(select(selectGenericPopoverData)).subscribe((data: LinkEditModel) => {
        this.title = data.page === GenericPage.create ? 'Add Link' : 'Edit Link';
      }),
    );
  }

  close() {
    this.popoverRef.close();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
