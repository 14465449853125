import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { colorTypeKeys } from '../../../core/constants/color-type';

@Component({
  templateUrl: 'color-picker.component.html',
  styleUrls: ['color-picker.component.scss'],
})
export class ColorPickerComponent implements OnInit {
  public readonly colours: string[] = colorTypeKeys;

  @Input()
  public selected: string;

  constructor(public activeModal: NgbActiveModal) {}

  public ngOnInit() {}

  public onChange(colour: string) {
    this.selected = colour;
  }

  public onClose() {
    this.activeModal.close(this.selected);
  }
}
