import { NodeModel, NodeModelsById } from '../../core/models/node.model';

export class NodesState {
  rootNodesById: NodeModelsById;
  nodesById: NodeModelsById;
  nodesByNodeType: { [id: number]: NodeModel[] };
  nodesByParentId: { [id: number]: NodeModel[] };
  nodesByTemplateId: { [id: number]: NodeModel[] };
  searchKeyword: string;

  /**
   * New Assignments store design
   */
  assignmentsById: NodeModelsById;
  assignmentsByParentId: { [id: number]: NodeModel[] };
  assignmentsByDate: { [dateKey: string]: NodeModel[] };
  newAssignmentIds: number[];
  selectedIds: number[];
}

export const initialNodesState: NodesState = {
  rootNodesById: {},
  nodesById: {},
  nodesByNodeType: {},
  nodesByParentId: {},
  nodesByTemplateId: {},
  searchKeyword: undefined,

  /**
   * New Assignments store design
   */
  assignmentsById: {},
  assignmentsByParentId: {},
  assignmentsByDate: {},
  newAssignmentIds: [],
  selectedIds: [],
};
