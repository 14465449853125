<div class="leaf-widget-list">
  <ng-container *ngIf="widgets?.length > 0">
    <form [formGroup]="form">
      <div
        class="leaf-widget-list__list"
        [ngClass]="{ 'leaf-widget-list__list--horizontal': isHorizontal }"
      >
        <app-leaf-widget-item
          class="leaf-widget-list__item"
          [form]="form"
          [widget]="widget"
          [isEdit]="isEdit"
          *ngFor="let widget of widgets; trackBy: trackBy"
        >
          <app-widget-field
            [form]="form"
            [widget]="widget"
            [readonly]="readonly"
            (closeEvent)="close()"
            (changeValueEvent)="changeWidgetValue(widget, $event)"
          ></app-widget-field>
        </app-leaf-widget-item>
        <ng-container *ngTemplateOutlet="appendixTemplateRef"> </ng-container>
      </div>
    </form>
  </ng-container>
  <ng-container *ngIf="!widgets || widgets?.length === 0">
    <div class="leaf-widget-list__no-content">
      There are no any widgets yet. You can add widgets in Manage Templates modal
    </div>
  </ng-container>
</div>
