import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { WorkspaceUrlEditableProps, WorkspaceUrlModel } from '../models/workspace-url.model';
import { ResponseMessage } from '../models/response-message.model';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class WorkspaceUrlsService {
  constructor(private http: HttpClient) {}

  public loadWorkspaceUrlsBySlug(workspaceSlug: string): Observable<WorkspaceUrlModel[]> {
    return this.http
      .get<ResponseMessage<WorkspaceUrlModel[]>>(
        `${environment.apiBaseUrl}/workspace/${workspaceSlug}/urls`,
      )
      .pipe(map(response => response.data));
  }

  public addWorkspaceUrl(
    workspaceSlug: string,
    props: {
      title: string;
      url: string;
      group: number;
      sortIndex: number;
    },
  ): Observable<WorkspaceUrlModel> {
    return this.http
      .post<ResponseMessage<WorkspaceUrlModel>>(
        `${environment.apiBaseUrl}/workspace/${workspaceSlug}/url/add`,
        props,
      )
      .pipe(map(response => response.data));
  }

  public updateWorkspaceUrl(
    urlId: number,
    urlProps: Partial<WorkspaceUrlEditableProps>,
  ): Observable<WorkspaceUrlModel> {
    // API expects update props match the Database Entity fields
    let props: any = { ...urlProps };
    if (props.groupId) {
      props.group = props.groupId;
      delete props.groupId;
    }
    return this.http
      .patch<ResponseMessage<WorkspaceUrlModel>>(
        `${environment.apiBaseUrl}/workspace/url/${urlId}/update`,
        {
          ...props,
        },
      )
      .pipe(map(response => response.data));
  }

  public deleteWorkspaceUrl(id: number) {
    return this.http.delete(`${environment.apiBaseUrl}/workspace/url/${id}/remove`);
  }

  public sortUrls(ids: number[]): Observable<any> {
    return this.http
      .post<ResponseMessage<any>>(`${environment.apiBaseUrl}/workspace/url/sort`, { ids })
      .pipe(map(response => response.data));
  }
}
