<app-popover-layout
  [title]="node?.title"
  [iconTemplateRef]="iconTemplate"
  (closePopover)="close()"
>
  <app-node-view [node]="node"></app-node-view>
</app-popover-layout>


<ng-template #iconTemplate>
  <span class="node-view-popover__icon-lock fal fa-lock-alt "></span>
</ng-template>


