import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilePreview } from '../../../../../shared/components/file-attachment/file-attachment.component';

@Component({
  selector: 'app-chat-file-preview',
  templateUrl: './chat-file-preview.component.html',
  styleUrls: ['./chat-file-preview.component.scss'],
})
export class ChatFilePreviewComponent implements OnInit {
  @Input() filePreview: FilePreview;

  @Output() removeEvent = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
    //
  }

  removePreview() {
    this.removeEvent.emit();
  }
}
