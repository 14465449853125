import { AppState } from '../index';
import { createSelector } from '@ngrx/store';

export const selectTagsState = (state: AppState) => state.tags;

export const selectTagsById = createSelector(selectTagsState, state => state.tagsById);

export const selectAllTagIds = createSelector(selectTagsState, state => state.ids);

export const selectTagsSearchKeyword = createSelector(
  selectTagsState,
  state => state.searchKeyword,
);

export const selectAllTags = createSelector(selectTagsById, selectAllTagIds, (byId, allIds) => {
  return allIds.map(id => byId[id]).filter(value => !!value);
});

export const selectAllTagsWithKeyword = createSelector(
  selectAllTags,
  selectTagsSearchKeyword,
  (tags, keywords) => {
    if (!keywords) {
      return tags;
    }
    const lowerCaseKeyword = keywords.toLowerCase();
    return tags.filter(
      tag =>
        tag.title.toLowerCase().indexOf(lowerCaseKeyword) >= 0 ||
        (tag.searchTerms ? tag.searchTerms.toLowerCase().indexOf(lowerCaseKeyword) >= 0 : false),
    );
  },
);
