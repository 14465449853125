import { createAction, props } from '@ngrx/store';

export const openPropertiesPanel = createAction(
  '[Properties Panel] open properties panel',
  props<{ nodeId: number }>(),
);

export const togglePropertiesPanel = createAction(
  '[Properties Panel] toggle properties panel',
  props<{ nodeId: number }>(),
);

export const close = createAction('[Properties Panel] close');
export const reset = createAction('[Properties Panel] reset');
