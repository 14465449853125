<div class="workspace-users">
  <app-workspace-user
    class="workspace-users__user"
    *ngFor="let user of users | slice: 0:MAX_DISPLAY; index as index; trackBy: trackBy"
    [user]="user"
    [isActive]="user.isActive"
  ></app-workspace-user>

  <div class="workspace-users__hidden" *ngIf="users.length > MAX_DISPLAY">
    <i class="fal fa-plus"></i> {{ users.length - MAX_DISPLAY }}
  </div>
</div>
