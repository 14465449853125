import { Action, createReducer, on } from '@ngrx/store';
import { initialLayoutTemplatesState, LayoutTemplateState } from './layout-templates.state';
import { LayoutTemplatesActions } from './index';

const reducer = createReducer<LayoutTemplateState>(
  initialLayoutTemplatesState,

  on(LayoutTemplatesActions.loadLayoutTemplatesSuccess, (state, { layoutTemplates }) => {
    const byId = layoutTemplates?.reduce((byId, layout) => {
      return {
        ...byId,
        [layout.id]: { ...layout },
      };
    }, {});
    const ids = layoutTemplates?.map(layout => layout.id);
    return {
      ...initialLayoutTemplatesState,
      layoutTemplatesById: byId,
      ids,
    };
  }),

  on(LayoutTemplatesActions.addLayoutTemplateSuccess, (state, { layoutTemplate }) => {
    return {
      ...state,
      layoutTemplatesById: {
        ...state.layoutTemplatesById,
        [layoutTemplate.id]: { ...layoutTemplate },
      },
      ids: [...state.ids, layoutTemplate.id], // @todo: we may need to sort tags by sortIndex and name
    };
  }),

  on(
    LayoutTemplatesActions.uploadLayoutTemplateProfileSuccess,
    (state, { layoutTemplateId, file }) => {
      return {
        ...state,
        layoutTemplatesById: {
          ...state.layoutTemplatesById,
          [layoutTemplateId]: { ...state.layoutTemplatesById[layoutTemplateId], profile: file },
        },
      };
    },
  ),

  on(LayoutTemplatesActions.installLayoutTemplateSuccess, (state, { layoutTemplateId }) => {
    return {
      ...state,
      layoutTemplatesById: {
        ...state.layoutTemplatesById,
        [layoutTemplateId]: { ...state.layoutTemplatesById[layoutTemplateId], installed: true },
      },
    };
  }),

  on(LayoutTemplatesActions.deleteLayoutTemplateSuccess, (state, { layoutTemplateId }) => {
    return {
      ...state,
      layoutTemplatesById: {
        ...state.layoutTemplatesById,
        [layoutTemplateId]: undefined,
      },
      ids: [...state.ids.filter(id => id !== layoutTemplateId)],
    };
  }),

  on(LayoutTemplatesActions.updateSearchKeywordRequest, (state, { searchKeyword }) => ({
    ...state,
    searchKeyword,
  })),
);

export function layoutTemplatesReducer(state: LayoutTemplateState, action: Action) {
  return reducer(state, action);
}
