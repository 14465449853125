import { Component, OnDestroy, OnInit } from '@angular/core';
import { OverlayPopoverRef } from '../../../../shared/components/overlay-popover/overlay-popover-ref';
import { Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { NodeModel } from '../../../../core/models/node.model';
import { WidgetType } from '../../../../core/constants/widget-type';
import { selectNodeViewNode } from '../../../../store/node-view/node-view.selectors';
import { BehaviourType } from '../../../../core/constants/behaviour-type';

@Component({
  templateUrl: './node-view-popover.component.html',
  styleUrls: ['./node-view-popover.component.scss'],
})
export class NodeViewPopoverComponent implements OnInit, OnDestroy {
  private readonly subscription = new Subscription();

  public readonly WidgetType = WidgetType;

  public node: NodeModel;

  constructor(private store: Store, private popoverRef: OverlayPopoverRef) {
    if (this.popoverRef?.data?.templateBehaviourType === BehaviourType?.single) {
      this.node = {
        ...this.popoverRef?.data?.node,
        widgets: this.popoverRef?.data?.node?.reference?.widgets,
      };
    } else if (this.popoverRef?.data?.type === 'additionalFields') {
      this.node = {
        ...this.popoverRef?.data?.node,
        widgets: this.popoverRef?.data?.widgets,
      };
    } else {
      this.subscription.add(
        this.store.pipe(select(selectNodeViewNode)).subscribe(nodeModel => {
          this.node = nodeModel;
        }),
      );
    }
  }

  ngOnInit(): void {}

  close() {
    this.popoverRef.close();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
