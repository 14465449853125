import { Pipe, PipeTransform } from '@angular/core';
import { PermissionType } from '../../core/constants/permission-type';
import { WorkspaceUserModel } from '../../core/models/workspace-user.model';
import { canUser } from '../../core/utils/permissions.util';

@Pipe({
  name: 'can',
})
export class CanPipe implements PipeTransform {
  transform(
    user: WorkspaceUserModel,
    permissionType: PermissionType,
    permissionObject?: any, // Could be used when more fine-grained permission control is needed
  ): boolean {
    return canUser(user, permissionType, permissionObject);
  }
}
