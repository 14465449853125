import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import {
  selectCurrentWorkspace,
  selectCurrentWorkspaceCardLayout,
  selectWorkspacesCurrentSlug,
} from '../workspaces/workspaces.selectors';
import { ErrorsActions } from '../errors';
import { WorkspaceSharesActions } from './index';
import { WorkspaceSharesService } from '../../core/services/workspace-shares.service';
import { SharedLinkMetaModel } from '../../core/models/shared-link-meta.model';
import {
  selectAssignmentDateRange,
  selectAssignmentSorting,
  selectAssignmentViewTagsNodeTypes,
  selectAssignmentViewType,
  selectAssignmentViewWidgetsNodeTypes,
  selectAwaitingAssignmentsViewMode,
} from '../workspace-user-meta/workspace-user-meta.selectors';
import { selectRouterInfoAssignmentLayout } from '../router/router.selectors';

@Injectable()
export class WorkspaceSharesEffects {
  constructor(
    private actions$: Actions,
    private workspaceSharesService: WorkspaceSharesService,
    private store: Store,
  ) {}

  loadSharedLinksRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceSharesActions.loadSharedLinksRequest),
      mergeMap(action =>
        this.workspaceSharesService.loadSharedLinks(action.workspaceSlug).pipe(
          map(sharedLinks => {
            return WorkspaceSharesActions.loadSharedLinksSuccess({ sharedLinks });
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  shareCurrentViewRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceSharesActions.shareCurrentViewRequest),
      concatMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(selectWorkspacesCurrentSlug)),
            this.store.pipe(select(selectAssignmentDateRange)),
            this.store.pipe(select(selectRouterInfoAssignmentLayout)),
            this.store.pipe(select(selectAssignmentViewType)),
            this.store.pipe(select(selectAssignmentViewWidgetsNodeTypes)),
            this.store.pipe(select(selectAssignmentViewTagsNodeTypes)),
            this.store.pipe(select(selectAssignmentSorting)),
            this.store.pipe(select(selectAwaitingAssignmentsViewMode)),
            this.store.pipe(select(selectCurrentWorkspaceCardLayout)),
            this.store.pipe(select(selectCurrentWorkspace)),
          ),
        ),
      ),
      mergeMap(
        ([
          action,
          slug,
          dateRange,
          layout,
          viewType,
          assignmentViewWidgetsNodeTypes,
          assignmentViewTagsNodeTypes,
          sorting,
          awaitingAssignmentsViewMode,
          cardLayout,
          currentWorkspace,
        ]) => {
          const meta: SharedLinkMetaModel = {
            assignmentFilterPrimaryTagIds: [],
            assignmentFilterProjectIds: [],
            startDate: action?.assignmentDateRange?.start || dateRange.start,
            endDate: action?.assignmentDateRange?.end || dateRange.end,
            nullDate: awaitingAssignmentsViewMode === 'show',
            assignmentSorting: sorting,
            assignmentViewType: viewType,
            assignmentViewWidgetsNodeTypes,
            assignmentViewTagsNodeTypes,
            assignmentLayout: layout,
            assignmentCardLayout: cardLayout,
            // slug: currentWorkspace?.title,
          };

          if (action?.title) {
            meta.title = action.title;
          }

          return this.workspaceSharesService.shareLink(slug, meta).pipe(
            map(sharedLink => WorkspaceSharesActions.shareCurrentViewSuccess({ sharedLink })),
            catchError(error => {
              return of(ErrorsActions.goToErrorPage({ error }));
            }),
          );
        },
      ),
    ),
  );

  deleteWidgetRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceSharesActions.removeSharedLinkRequest),
      mergeMap(action =>
        this.workspaceSharesService.removeSharedLink(action.hash).pipe(
          switchMap(() => {
            return EMPTY;
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );
}
