<div
  class="sidebar-header"
  [ngClass]="{
    'sidebar-header--subtitle': subTitle,
    'sidebar-header--back': enableBack,
    'sidebar-header--badge': badge,
    'sidebar-header--template': template,
    'sidebar-header--collapsed': parent.collapsed
  }"
>
  <ng-container *ngTemplateOutlet="template"></ng-container>

  <ng-container *ngIf="!template">
    <div class="sidebar-header__body">
      <div class="sidebar-header__first-col" *ngIf="enableBack">
        <button
          *ngIf="enableBack"
          type="button"
          class="sidebar-header__btn-back btn fal fa-chevron-left"
          (click)="onBack($event)"
        >
          <span class="sr-only">Back</span>
        </button>
      </div>
      <div class="sidebar-header__center-col" *ngIf="title || subTitle">
        <h2
          class="sidebar-header__title"
          [ngClass]="{ 'text-truncate': truncateTitle }"
          *ngIf="title"
        >
          {{ title }}
        </h2>
        <h4 class="sidebar-header__subtitle" *ngIf="subTitle">
          {{ subTitle }}
        </h4>
      </div>
      <div class="sidebar-header__last-col" *ngIf="badge">
        <div class="sidebar-header__badge badge badge--inverted-secondary" *ngIf="badge">
          {{ badge }}
        </div>
      </div>
    </div>
  </ng-container>
</div>
