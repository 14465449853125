import { AppState } from '../index';
import { createSelector } from '@ngrx/store';
import {
  selectChatChanelCommentsById,
  selectChatChannelsById,
  selectChatCommentIdsByChannelId,
} from '../chats/chats.selectors';
import { selectCurrentWorkspaceUsersById } from '../workspace-users/workspace-users.selectors';
import { populateModificationInfo } from '../workspace-users/workspace-users.util';
import { selectCurrentWorkspace } from '../workspaces/workspaces.selectors';
import { convertToChannelViewModel } from '../chats/chats.util';
import { NodesSelectors } from '../nodes';

const selectChatbarState = (state: AppState) => state.chatbar;

export const selectChatbarPage = createSelector(selectChatbarState, state => state.page);

export const selectChatbarCurrentChannelId = createSelector(
  selectChatbarState,
  state => state.currentChannelId,
);

export const selectChatbarCurrentChannel = createSelector(
  selectChatbarCurrentChannelId,
  selectChatChannelsById,
  (id, byId) => {
    return byId[id];
  },
);

export const selectChatbarCurrentChannelViewModel = createSelector(
  selectChatbarCurrentChannel,
  NodesSelectors.selectNodesById,
  selectCurrentWorkspace,
  selectCurrentWorkspaceUsersById,
  (channel, nodesById, currentWorkspace, usersById) => {
    if (!channel) {
      return undefined;
    }

    return convertToChannelViewModel(channel, nodesById, currentWorkspace, usersById);
  },
);

export const selectChatbarCurrentWorkspaceChannelComments = createSelector(
  selectChatbarCurrentChannelId,
  selectChatCommentIdsByChannelId,
  selectChatChanelCommentsById,
  (channelId, commentIdsByChannelId, commentsById) => {
    return (commentIdsByChannelId[channelId] || [])
      .map(id => commentsById[id])
      .filter(comment => !!comment);
  },
);

export const selectChatbarCurrentWorkspaceChannelCommentViewModels = createSelector(
  selectChatbarCurrentWorkspaceChannelComments,
  selectCurrentWorkspaceUsersById,
  (comments, usersById) => {
    return comments
      .map(comment => {
        return {
          ...comment,
          ...populateModificationInfo(comment, usersById),
        };
      })
      .sort((a, b) => {
        if (a.createdDate > b.createdDate) {
          return 1;
        } else if (a.createdDate < b.createdDate) {
          return -1;
        }
        return 0;
      });
  },
);
