import { createAction, props } from '@ngrx/store';
import {
  WorkspaceUserGroupEditableProps,
  WorkspaceUserGroupModel,
} from '../../core/models/workspace-user-group.model';

export const loadCurrentWorkspaceUserGroupsRequest = createAction(
  '[Workspace user groups] load current workspace user groups request',
  props<{ workspaceSlug: string }>(),
);

export const loadCurrentWorkspaceUserGroupsSuccess = createAction(
  '[Workspace user groups] load current workspace users success',
  props<{ groups: WorkspaceUserGroupModel[] }>(),
);

export const addWorkspaceUserGroupRequest = createAction(
  '[Workspace user groups] add workspace user group request',
  props<{
    workspaceSlug: string;
    props: Partial<WorkspaceUserGroupEditableProps>;
  }>(),
);

export const addWorkspaceUserGroupSuccess = createAction(
  '[Workspace user groups] add workspace user group success',
  props<{
    group: WorkspaceUserGroupModel;
  }>(),
);

export const updateWorkspaceUserGroupRequest = createAction(
  '[Workspace user groups] update workspace user group request',
  props<{
    groupId: number;
    props: Partial<WorkspaceUserGroupEditableProps>;
  }>(),
);

export const updateWorkspaceUserGroupSuccess = createAction(
  '[Workspace user groups] update workspace user group success',
  props<{
    group: WorkspaceUserGroupModel;
  }>(),
);

export const deleteWorkspaceUserGroupRequest = createAction(
  '[Workspace user groups] delete workspace user group request',
  props<{
    groupId: number;
  }>(),
);

export const deleteWorkspaceUserGroupSuccess = createAction(
  '[Workspace user groups] delete workspace user group success',
  props<{
    groupId: number;
  }>(),
);
