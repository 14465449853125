import { AppState } from '../index';
import { createSelector } from '@ngrx/store';
import { selectTemplateWidgetData } from '../template-widgets/template-widgets.selectors';

const selectTemplateFiguresPopoverState = (state: AppState) => state.templateFiguresPopover;

export const selectTemplateFiguresPopoverPage = createSelector(
  selectTemplateFiguresPopoverState,
  state => state.page,
);

export const selectTemplateFiguresPopoverTemplateId = createSelector(
  selectTemplateFiguresPopoverState,
  state => state.templateId,
);

export const selectTemplateFiguresPopoverWidgetId = createSelector(
  selectTemplateFiguresPopoverState,
  state => state.widgetId,
);

export const selectTemplateFiguresPopoverWidget = createSelector(
  selectTemplateFiguresPopoverWidgetId,
  selectTemplateWidgetData,
  (widgetId, { widgetsById, templateWidgetsById }) => {
    return {
      ...widgetsById[widgetId],
      ...templateWidgetsById[widgetId],
    };
  },
);
