import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TemplateWidgetService } from '../../core/services/template-widget.service';
import { catchError, concatMap, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { ErrorsActions } from '../errors';
import { TemplateWidgetsActions } from './index';
import { TemplatesActions } from '../templates';
import { select, Store } from '@ngrx/store';
import { selectWorkspacesCurrentSlug } from '../workspaces/workspaces.selectors';
import { NodeType } from '../../core/constants/node-type';
import { WidgetsActions } from '../widgets';
import { WorkspaceGroupsActions } from '../workspace-groups';
import { selectWorkspaceGroupsById } from '../workspace-groups/workspace-groups.selectors';

@Injectable()
export class TemplateWidgetsEffects {
  constructor(
    private actions$: Actions,
    private templateWidgetService: TemplateWidgetService,
    private store: Store,
  ) {}

  addTemplateWidgetsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TemplateWidgetsActions.addTemplateWidgetsRequest),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      mergeMap(([action, slug]) =>
        this.templateWidgetService
          .addTemplateWidgets(action.templateId, action.widgetIds, action.groupId, action.replace)
          .pipe(
            switchMap(templateWidgets => {
              return of(
                TemplateWidgetsActions.addTemplateWidgetsSuccess({
                  templateWidgets,
                  groupId: action.groupId,
                  replace: action.replace,
                }),
                TemplatesActions.refreshNodeTemplatesRequest({ workspaceSlug: slug }),
              );
            }),
            catchError(error => {
              return of(ErrorsActions.goToErrorPage({ error }));
            }),
          ),
      ),
    ),
  );

  createNewAssignmentChecklistWidgetRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TemplateWidgetsActions.createAndAddAssignmentChecklistWidgetRequest),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspaceGroupsById)))),
      ),
      mergeMap(([action, groupsById]) =>
        this.templateWidgetService
          .createNewTemplateGroupWidget(groupsById[action.groupId].nodeTemplateId, {
            title: action.title,
            group: action.groupId,
            nodeType: NodeType.assignment,
            widgetType: action?.widgetType,
            choices: action.choices,
          })
          .pipe(
            switchMap(({ widget, ...templateWidget }) => {
              return of(
                TemplateWidgetsActions.createAndAddAssignmentChecklistWidgetSuccess({
                  templateWidget,
                  widget,
                }),
              );
            }),
            catchError(error => {
              return of(ErrorsActions.goToErrorPage({ error }));
            }),
          ),
      ),
    ),
  );

  createAndAddCalculationWidgetRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TemplateWidgetsActions.createAndAddCalculationWidgetRequest),
      mergeMap(action =>
        this.templateWidgetService
          .createNewCalculationWidget(action.templateId, {
            title: action.title,
            calculationMethod: action.calculationMethod,
            widgetATitle: action.widgetATitle,
            widgetAType: action.widgetAType,
            widgetBTitle: action.widgetBTitle,
            widgetBType: action.widgetBType,
            sortIndex: action.sortIndex,
          })
          .pipe(
            concatMap(({ widget, widgetA, widgetB, group, ...templateWidget }) => [
              TemplateWidgetsActions.addTemplateWidgetsSuccess({
                templateWidgets: [templateWidget],
                groupId: null,
                replace: false,
              }),
              WidgetsActions.addWidgetsSuccess({
                widgets: [widget, widgetA, widgetB],
              }),
              WorkspaceGroupsActions.addWorkspaceGroupsSuccess({ groups: [group] }),
            ]),
            catchError(error => {
              return of(ErrorsActions.goToErrorPage({ error }));
            }),
          ),
      ),
    ),
  );

  removeTemplateWidgetRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TemplateWidgetsActions.removeTemplateWidgetRequest),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      mergeMap(([action, slug]) =>
        this.templateWidgetService.removeTemplateWidget(action.templateId, action.joinId).pipe(
          switchMap(() => {
            return of(TemplatesActions.refreshNodeTemplatesRequest({ workspaceSlug: slug }));
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  sortTemplateWidgetsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TemplateWidgetsActions.sortTemplateWidgetsRequest),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      mergeMap(([action, slug]) =>
        this.templateWidgetService.sortTemplateWidgets(action.templateId, action.joinIds).pipe(
          switchMap(() => {
            return EMPTY;
            // return of(TemplatesActions.refreshNodeTemplatesRequest({ workspaceSlug: slug }));
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );
}
