<div
  class="z-table__editor-workspace-group"
  [ngClass]="{ 'z-table__editor--empty': editable && !cell?.value }"
>
  <ng-select
    class="table-editor"
    #ngSelect
    [disabled]="!editable"
    [clearable]="false"
    [items]="options.list"
    [(ngModel)]="cell.value"
    bindLabel="label"
    bindValue="value"
    appendTo="body"
    [placeholder]="editable ? 'Select ' + column.label : 'Empty'"
    (clear)="onStartEdit()"
    (open)="onStartEdit()"
    (focus)="onStartEdit()"
    (blur)="onEndEdit()"
  >
    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
      <div class="workspace-group__option">
        <div class="workspace-group__title">
          <div class="workspace-group__label">{{ item.label }}</div>
          <div class="workspace-group__type">{{ item.type || ' N/A ' }}</div>
        </div>
        <div class="workspace-group__meta">
          <div class="workspace-group__units">{{ item.units }}</div>
          <div class="workspace-group__amount">{{ item.amount | currency }}</div>
        </div>
      </div>
    </ng-template>
  </ng-select>
</div>
