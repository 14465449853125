import { TableEditor } from './table-editors/table-editors.common';

export const zTableProp = {
  EmptyGroupId: '__empty_group',
  EmptyRowId: '__empty_row',
};

export enum TableColumnAlignment {
  Left,
  Center,
  Right,
}

export type TableGroup = {
  id: string;
  rows: TableRow[];
  totals?: number[];
  collapsed?: boolean;
};

export type TableColumn = {
  id: string;
  label: string;
  colSpan?: number;
  alignment?: TableColumnAlignment;
  editor?: TableEditor;
  editable?: boolean;
  collapsed?: boolean;
  sortable?: boolean;
  options?: any;
  groupable?: boolean;
  groupIndex?: Function;
  groupLabel?: Function;
  sortValue?: Function;
  includeTotal?: boolean;
  totalCalculation?: Function;
  fixedWidth?: boolean;
  hideSmall?: boolean;
};

export type TableRow = {
  id: string;
  columns: TableCell[];
  collapsed?: boolean;
  editable?: boolean;
};

export type TableCell = {
  value: any;
  alignment?: TableColumnAlignment;
  options?: any;
  editable?: boolean;
};

export type TableColumnSortEvent = {
  column: TableColumn;
  sortDescending: boolean;
};

export type TableColumnMoveEvent = {
  column: TableColumn;
  columnIds: string[];
  previousIndex: number;
  currentIndex: number;
};

export type TableGroupMoveEvent = {
  groupBy: string;
  group: TableGroup;
  groupIds: string[];
  previousIndex: number;
  currentIndex: number;
};

export type TableRowMoveEvent = {
  groupBy: string;
  group: TableGroup;
  row: TableRow;
  rowIds: string[];
  previousIndex: number;
  currentIndex: number;
  sortColumn: TableColumn;
  sortDescending: boolean;
};

export type TableNewRowEvent = {};
