import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { WidgetService } from '../../../../core/services/widget.service';
import { WidgetType } from '../../../../core/constants/widget-type';
import { NodeTemplateWidgetModel } from '../../../../core/models/node-template-widget.model';
import { NodeModel } from '../../../../core/models/node.model';
import { AppState } from '../../../../store';
import { NodeWidgetRowsActions } from '../../../../store/node-widget-rows';
import { TableColumn, TableRow, TableCell } from '../../table.common';
import { TableCellEditEvent } from '../table-editors.common';

@Component({
  selector: 'app-table-editable-assignment-additional-table-fields',
  templateUrl: './table-editable-assignment-additional-table-fields.component.html',
})
export class TableEditableAssignmentAdditionalTableFieldsComponent implements OnInit {
  public readonly WidgetTypes = WidgetType;

  @Output()
  public startEdit = new EventEmitter<TableCellEditEvent>();

  @Output()
  public endEdit = new EventEmitter<TableCellEditEvent>();

  @Input()
  public tableEditable?: boolean;

  @Input()
  public field: string;

  @Input()
  public column: TableColumn;

  @Input()
  public row: TableRow;

  @Input()
  public cell: TableCell;

  public cellNew: TableCell;

  private readonly ROW_NUMBER = 0;

  constructor(private store: Store<AppState>, private readonly widgetService: WidgetService) {}

  ngOnInit(): void {
    this.cellNew = { ...this.cell, value: this.cell?.value?.value };
  }

  onEndEdit(event: any): void {
    this.cell.value = {
      ...this.cell.value,
      value: event?.cell?.value,
    };
    const widget = this.cell.value as NodeTemplateWidgetModel;
    const assignment = this.cell.options?.assignment as NodeModel;

    this.store.dispatch(
      NodeWidgetRowsActions.updateWidgetRowValueRequest({
        nodeId: assignment.id,
        widgetId: widget.id,
        value: this.widgetService.getWidgetValue(widget),
        rowNumber: this.ROW_NUMBER,
        referenceId: null,
      }),
    );
  }
}
