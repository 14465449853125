import { createAction, props } from '@ngrx/store';

export const checkReferenceCountRequest = createAction(
  '[Modal Archive] check reference count request',
  props<{ nodeIds: number[] }>(),
);
export const checkReferenceCountSuccess = createAction(
  '[Modal Archive] check reference count success',
  props<{ referenceCount: number }>(),
);

export const noReferenceCountRequired = createAction(
  '[Modal Archive] no reference count is required',
);
