import { Component, Input, OnInit } from '@angular/core';
import { NodeModel } from '../../../core/models/node.model';

@Component({
  selector: 'app-assignment-row-announcement',
  templateUrl: './assignment-row-announcement.component.html',
})
export class AssignmentRowAnnouncementComponent implements OnInit {
  @Input() project: NodeModel;

  constructor() {}

  ngOnInit(): void {}
}
