import { HostListener, Directive, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({ selector: '[appInputEmptyToNull]' })
export class InputEmptyToNullDirective {
  constructor(@Self() private readonly ngControl: NgControl) {}

  @HostListener('blur', ['$event']) onBlur(event: KeyboardEvent): void {
    if (this.ngControl.value?.length > 0 && !this.ngControl.value?.replace(/\s/g, '')?.length) {
      this.ngControl.control.setValue(null);
    }
  }
}
