import { Component, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ColorType } from '../../../core/constants/color-type';
import { IconType } from '../../../core/constants/icon-type';
import { NodeUtils } from '../../../core/utils/node.util';

export type ListItem<T> = {
  model: T;
  selected: boolean;
  disabled: boolean;
};

@Component({
  template: '',
})
export abstract class AbstractPickerComponent<T> implements OnInit, OnDestroy {
  protected readonly subscription = new Subscription();

  public readonly ColorType = ColorType;
  public readonly IconType = IconType;

  public list: ListItem<T>[];
  public keywords: string;

  public validationMessage: string;

  private _selected: T[];
  private _disabled: T[];
  private _whitelist: T[];
  private _blacklist: T[];

  @Input()
  public instructions: string;

  @Input()
  public instructionsTemplate: TemplateRef<any>;

  @Input()
  public minSelection: number;

  @Input()
  public maxSelection: number;

  @Input()
  public allowEmpty: boolean;

  @Input()
  public emptyValue: any;

  @Input()
  public set selected(value: T[]) {
    // So we don't manipulate the original list until confirmation, so create a new array
    this._selected = [...value];
    this.updateListItemSelection();
    this.sortItems();
  }

  public get selected(): T[] {
    return this._selected;
  }

  @Input()
  public set disabled(value: T[]) {
    this._disabled = [...value];
    this.updateListItemSelection();
  }

  public get disabled(): T[] {
    return this._disabled;
  }

  @Input()
  public set whitelist(value: T[]) {
    this._whitelist = [...value];
    this.filterList();
  }

  public get whitelist(): T[] {
    return this._whitelist;
  }

  @Input()
  public set blacklist(value: T[]) {
    this._blacklist = [...value];
    this.filterList();
  }

  public get blacklist(): T[] {
    return this._blacklist;
  }

  protected constructor(public activeModal: NgbActiveModal) {
    this.list = [];
    this._selected = [];
    this._disabled = [];
    this._whitelist = [];
    this._blacklist = [];

    this.minSelection = 0;
    this.maxSelection = 100;
    this.validationMessage = '';
  }

  public abstract isEqual(itemA: T, itemB: T): boolean;

  public abstract search(keywords);

  public abstract add(data: any);

  public abstract edit(listItem: ListItem<T>);

  public abstract trackBy(index: number, item: ListItem<T>);

  ngOnInit(): void {}

  protected updateList(items: T[]) {
    this.list = items.map(i => {
      return {
        model: i,
        selected: false,
        disabled: false,
      };
    });

    if (this.allowEmpty) {
      this.list.unshift({
        model: this.emptyValue,
        selected: false,
        disabled: false,
      });
    }

    this.filterList();
    this.updateListItemSelection();
    this.sortItems();
  }

  protected filterList() {
    this.list = this.list.filter(i => {
      if (this._blacklist.length && this._blacklist.some(j => this.isEqual(i.model, j)) == true) {
        return false;
      }
      if (this._whitelist.length && this._whitelist.some(j => this.isEqual(i.model, j)) == false) {
        return false;
      }
      return true;
    });
  }

  protected updateListItemSelection() {
    this.list.forEach(li => {
      li.disabled = this.isDisabled(li);
      li.selected = li.disabled === true ? false : this.isSelected(li);
    });
  }

  protected sortItems() {
    this.list.sort((a: ListItem<T>, b: ListItem<T>) => {
      if (this.maxSelection === 1) {
        return NodeUtils.sortByIndex(a.model, b.model);
      }
      // Move Selected to start position
      if (a.selected == true && b.selected == false) {
        return -1;
      } else if (a.selected == false && b.selected == true) {
        return 1;
      }
      // Now sort by SortIndex
      return NodeUtils.sortByIndex(a.model, b.model);
    });
  }

  protected verifySelection(): boolean {
    let itemText = this.minSelection > 1 ? 'items' : 'item';
    this.validationMessage = null;
    if (
      this._selected.length < this.minSelection ||
      (this.maxSelection > 0 && this._selected.length > this.maxSelection)
    ) {
      if (this.minSelection == this.maxSelection) {
        this.validationMessage = `You must select ${this.minSelection} ${itemText}.`;
        console.error(this.validationMessage);
        return false;
      }

      if (this._selected.length < this.minSelection) {
        this.validationMessage = `You must select at least ${this.minSelection} ${itemText}.`;
        console.error(this.validationMessage);
        return false;
      }

      if (this.maxSelection > 0 && this._selected.length > this.maxSelection) {
        this.validationMessage = `You can't select more than ${this.maxSelection} items.`;
        console.error(this.validationMessage);
        return false;
      }

      // this.validationMessage = `Number of selected items not within range: ${this.minSelection} to ${this.maxSelection}`;
      // console.error(this.validationMessage);
      // return false;
    }
    return true;
  }

  public isSelected(listItem: ListItem<T>): boolean {
    return this._selected.some(x => this.isEqual(x, listItem.model));
  }

  public isDisabled(listItem: ListItem<T>): boolean {
    return this._disabled.some(x => this.isEqual(x, listItem.model));
  }

  public addItem(listItem: ListItem<T>) {
    if (this.maxSelection === 1) {
      this._selected = [listItem.model];
    } else {
      this._selected = [
        ...this._selected.filter(i => this.isEqual(i, listItem.model) === false),
        listItem.model,
      ];
    }
    this.verifySelection();
    this.updateListItemSelection();
  }

  public removeItem(listItem: ListItem<T>) {
    this._selected = this._selected.filter(i => this.isEqual(i, listItem.model) === false);
    this.verifySelection();
    this.updateListItemSelection();
  }

  public toggle(listItem: ListItem<T>) {
    if (listItem.disabled === true) {
      return;
    }
    listItem.selected = !listItem.selected;
    if (listItem.selected === false) {
      this.removeItem(listItem);
    } else {
      this.addItem(listItem);
    }
    this.sortItems();
  }

  public clearSelection() {
    this.list.forEach(li => {
      li.selected = false;
    });
    this._selected = [];
    this.sortItems();
    this.verifySelection();
  }

  public onSubmit() {
    if (this.verifySelection() === false) {
      return;
    }
    //const selected = this.list.filter(li => li.selected).map(li => li.model);
    this.activeModal.close(this._selected);
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
