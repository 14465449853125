import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { OptionModel } from '../models/option.model';
import { WidgetEditableProps, WidgetModel } from '../models/widget.model';
import { ResponseMessage } from '../models/response-message.model';
import { map } from 'rxjs/operators';
import { WidgetType } from '../constants/widget-type';
import { NodeType } from '../constants/node-type';
import { NodeGroupValueModel } from '../models/node-group-value.model';
import { NodeWidgetValueModel } from '../models/node-widget-value.model';
import { NodeModel } from '../models/node.model';
import { NodeTemplateWidgetModel } from '../models/node-template-widget.model';
import { momentDateFormat } from '../../shared/pipes/moment-date.pipe';
import { getFormattedAddress } from '../utils/address.util';
import { CurrencyPipe } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class WidgetService {
  constructor(private http: HttpClient, private currencyPipe: CurrencyPipe) {}

  public loadWidgetTypes(): Observable<OptionModel[]> {
    return this.http.get<OptionModel[]>(`${environment.apiBaseUrl}/options/widget-types`);
  }

  public loadWidgets(workspaceSlug: string): Observable<WidgetModel[]> {
    return this.http
      .get<ResponseMessage<WidgetModel[]>>(
        `${environment.apiBaseUrl}/workspace/${workspaceSlug}/widgets`,
      )
      .pipe(map(response => response.data));
  }

  public loadArchivedWidgets(
    slug: string,
    startDate: string,
    endDate: string,
  ): Observable<WidgetModel[]> {
    const options = {
      params: new HttpParams()
        .set('archived', 1)
        .set('startDate', startDate)
        .set('endDate', endDate),
    };
    return this.http
      .get<ResponseMessage<WidgetModel[]>>(
        `${environment.apiBaseUrl}/workspace/${slug}/widgets`,
        options,
      )
      .pipe(map(response => response.data));
  }

  public addWidget(
    workspaceSlug: string,
    title: string,
    widgetType: WidgetType,
    nodeType: NodeType,
    choices: string[],
  ): Observable<WidgetModel> {
    let body: any = {
      title,
      widgetType,
      nodeType,
    };
    if (choices && choices.length > 0) {
      body = {
        ...body,
        choices,
      };
    }
    return this.http
      .post<ResponseMessage<WidgetModel>>(
        `${environment.apiBaseUrl}/workspace/${workspaceSlug}/widget/add`,
        body,
      )
      .pipe(map(response => response.data));
  }

  public updateWidget(
    widgetId: number,
    widgetProps: Partial<WidgetEditableProps>,
  ): Observable<WidgetModel> {
    return this.http
      .patch<ResponseMessage<WidgetModel>>(
        `${environment.apiBaseUrl}/workspace/widget/${widgetId}/update`,
        {
          ...widgetProps,
        },
      )
      .pipe(map(response => response.data));
  }

  public removeWidget(widgetId: number): Observable<any> {
    return this.http.delete(`${environment.apiBaseUrl}/workspace/widget/${widgetId}/remove`);
  }

  public restoreWidget(widgetId: number): Observable<object> {
    return this.http.patch(`${environment.apiBaseUrl}/workspace/widget/${widgetId}/archive`, {
      archived: false,
    });
  }

  public archiveWidget(widgetId: number): Observable<object> {
    return this.http.patch(`${environment.apiBaseUrl}/workspace/widget/${widgetId}/archive`, {
      archived: true,
    });
  }

  public addNodeWidget(nodeId: number, widgetType: number): Observable<WidgetModel> {
    return this.http.post<WidgetModel>(
      `${environment.apiBaseUrl}/workspace/node/${nodeId}/widget/add/${widgetType}`,
      {},
    );
  }

  public updateNodeWidget(
    nodeId: number,
    widgetId: number,
    widgetProps: Partial<WidgetEditableProps>,
  ): Observable<{ widgetValue: NodeWidgetValueModel; groupValue: NodeGroupValueModel }> {
    return this.http
      .patch<ResponseMessage<any>>(
        `${environment.apiBaseUrl}/workspace/node/${nodeId}/widget/${widgetId}/update`,
        {
          ...widgetProps,
        },
      )
      .pipe(map(res => res.data));
  }

  public removeNodeWidget(nodeId: number, widgetId: number): Observable<object> {
    return this.http.delete(
      `${environment.apiBaseUrl}/workspace/node/${nodeId}/widget/${widgetId}/remove`,
    );
  }

  public addWidgetRow(nodeId: number): Observable<NodeModel> {
    return this.http
      .post<ResponseMessage<NodeModel>>(
        `${environment.apiBaseUrl}/workspace/node/${nodeId}/widget-row/add`,
        {},
      )
      .pipe(map(response => response.data));
  }

  public removeWidgetRow(nodeId: number, rowNumber: number): Observable<NodeModel> {
    return this.http
      .delete<ResponseMessage<NodeModel>>(
        `${environment.apiBaseUrl}/workspace/node/${nodeId}/widget-row/${rowNumber}/remove`,
      )
      .pipe(map(response => response.data));
  }

  // move this into data service
  public getWidgetValue(widget: NodeTemplateWidgetModel): string {
    if (widget == null || widget.widget == null) {
      return null;
    }
    switch (widget.widget.widgetType) {
      case WidgetType.address:
        return getFormattedAddress(widget.value);
      case WidgetType.email:
        return widget.value ? widget.value : '';
      case WidgetType.checkbox:
        return widget.value ? 'Yes' : 'No';
      case WidgetType.date:
        const dateResult = momentDateFormat(widget.value, 'fullDateSimple');
        return dateResult === 'Invalid date' ? '-' : dateResult;
      case WidgetType.dateTime:
        const dateTimeResult = momentDateFormat(widget.value, 'fullDateTimeSimple');
        return dateTimeResult === 'Invalid date' ? '-' : dateTimeResult;
      case WidgetType.time:
        const timeResult = momentDateFormat(widget.value, 'time');
        return timeResult === 'Invalid date' ? '-' : timeResult;
      case WidgetType.currency:
        return this.currencyPipe.transform((widget.value || 0) / 100);
      case WidgetType.urlList: {
        if (!widget.value || widget.value.length === 0) {
          return null;
        }
        return `${widget.value.length} ${widget.value.length > 1 ? 'links' : 'link'}`;
      }
      default:
        return widget.value;
    }
  }
}
