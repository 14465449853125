import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {NgbActiveModal, NgbNavChangeEvent} from '@ng-bootstrap/ng-bootstrap';
import {select, Store} from '@ngrx/store';
import {Subscription} from 'rxjs';
import {CostTableSettings} from '../../../core/models/cost-table-settings.model';
import {NodeTemplateModel} from '../../../core/models/node-template.model';
import {AppState} from '../../../store';
import {NodeEnrichmentSelectors} from '../../../store/nodes';
import {NodeModel} from '../../../core/models/node.model';
import {NodeUtils} from '../../../core/utils/node.util';

@Component({
  templateUrl: 'node-rate-values.component.html',
  styleUrls: ['node-rate-values.component.scss'],
})
export class NodeRateValuesComponent implements OnInit, OnDestroy {
  public readonly awaitingAssignmentDateKey = NodeUtils.awaitingAssignmentsDateKey;
  private readonly subscription: Subscription = new Subscription();

  @Input() type: 'Cost' | 'Revenue' = 'Cost';
  @Input()
  public assignment: NodeModel;

  @Input()
  public project: NodeModel;

  public active: 'Cost' | 'Revenue' = 'Cost';
  public assignmentsByNodeTemplate: NodeTemplateModel[];
  public tableSettings: CostTableSettings;

  constructor(public activeModal: NgbActiveModal, private store: Store<AppState>) {
  }

  public ngOnInit() {
    this.active = this.type;

    this.tableSettings = {
      sortIndex: true,
      lock: true,
      menu: true,
      date: true,
      projectTitle: true,
      primaryTags: true,
      assetProfile: true,
      assetName: false,
      finalValue: false,
      value: false,
      timeTotal: false,
      startTime: true,
      endTime: true,
      timeHours: true,
    };
    this.subscription.add(
      this.store
        .pipe(select(NodeEnrichmentSelectors.selectAssignmentsCostsByNodeId(this.assignment.id)))
        .subscribe(assignmentsByNodeTemplate => {
          this.assignmentsByNodeTemplate = assignmentsByNodeTemplate;
        }),
    );
  }

  onNavChange(changeEvent: NgbNavChangeEvent) {
    this.active = changeEvent.nextId;
  }

  public trackBy(index, item) {
    return item?.id;
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
