import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafePipe } from './safe.pipe';
import { MomentDatePipe } from './moment-date.pipe';
import { ConcatNounsPipe } from './concat-nouns.pipe';
import { ColorStringPipe } from './color-string.pipe';
import { IconStringPipe } from './icon-string.pipe';
import { ToNgbDatePipe } from './to-ngb-date.pipe';
import { NgbDateFormatPipe } from './ngb-date-format.pipe';
import { NiceDatePipe } from './nice-date.pipe';
import { WidgetTypeLabelPipe } from './widget-type-label.pipe';
import { WidgetInputTypePipe } from './widget-input-type.pipe';
import { WidgetValuePipe } from './widget-value.pipe';
import { NodeTypeLabelPipe } from './server-node-type-label.pipe';
import { ServerNodeTypeIconClassPipe } from './server-node-type-icon-class.pipe';
import { ServerNodeTypeIsFolderPipe } from './server-node-type-is-folder.pipe';
import { TagTypeLabelPipe } from './tag-type-label.pipe';
import { TagsPopoverPageTitlePipe } from './tags-popover-page-title.pipe';
import { UserRoleLabelPipe } from './user-role-label.pipe';
import { CanPipe } from './can.pipe';
import { BackgroundImagePipe } from './background-image.pipe';
import { TruncatePipe } from './truncate.pipe';
import { IsTodayPipe } from './is-today.pipe';
import { IsEndOfWeekPipe } from './is-end-of-week.pipe';
import { ObjectKeyCountPipe } from './object-key-count.pipe';
import { HasPreviousDateItemPipe } from './has-previous-date-item.pipe';
import { HasNextDateItemPipe } from './has-next-date-item.pipe';
import { ConsecutiveDaysPipe } from './consecutive-days.pipe';
import { AssignmentChecklistsPopoverPageTitlePipe } from './assignment-forms-popover-page-title.pipe';
import { WorkspaceTemplateImagePipe } from './workspace-template-image.pipe';
import { CalculationMethodLabelPipe } from './calculation-method-label.pipe';
import { TemplateFiguresPopoverPageTitlePipe } from './template-figures-popover-page-title.pipe';
import { CalculationResultTypeLabelPipe } from './calculation-result-type-label.pipe';
import { CalculationOperatorPipe } from './calculation-operator.pipe';
import { CalculationNumberResultPipe } from './calculation-number-result.pipe';
import { CalculationFormattedResultPipe } from './calculation-formatted-result.pipe';
import { LeafTagTypePipe } from './leaf-tag-type.pipe';
import { CalculationWidgetFormulaPipe } from './calculation-widget-formula.pipe';
import { ToOptionsPipe } from './to-options.pipe';
import { InDateRangePipe } from './in-date-range.pipe';
import { IsSameDatePipe } from './is-same-date.pipe';
import { DurationTimePipe } from './duration-time.pipe';
import { DefaultTemplateIdPipe } from './default-template-id.pipe';
import { HasChoicesSupportPipe } from './has-choices-support.pipe';
import { ValueByKeyPairPipe } from './value-by-key-pair.pipe';
import { GanttWidthPipe } from './gantt-width.pipe';
import { IsGanttStartBoundaryPipe } from './is-gantt-start-boundary.pipe';
import { GroupItemNamePipe } from './group-item-name.pipe';
import { WidgetTypeFilterPipe } from './widget-type-filter.pipe';
import { HasNonEmptyItemPipe } from './has-non-empty-item.pipe';
import { FaviconPipe } from './favicon.pipe';
import { WidgetSortPipe } from './widget-sort.pipe';
import { UrlWidgetToLinkPipe } from './url-widget-to-link.pipe';
import { SharedLinkUrlPipe } from './shared-link-url.pipe';
import { ToInitialsPipe } from './to-initials.pipe';
import { FirstWordPipe } from './first-word.pipe';
import { ProgressStyleWidthPipe } from './progress-style-width.pipe';
import { GroupLinksPopoverPageTitlePipe } from './group-links-popover-page-title.pipe';
import { ToLinkModelPipe } from './to-link-model.pipe';
import { RichSearchTypeLabelPipe } from './rich-search-type-label.pipe';
import { RichSearchSelectedIdsPipe } from './rich-search-selected-ids.pipe';
import { RichSearchFilterOptionsPipe } from './rich-search-filter-options.pipe';
import { TimesheetTimePipe } from './timesheet-time.pipe';
import { ItemsFilterPipe } from './items-filter.pipe';
import { ToNgbTimePipe } from './to-ngb-time.pipe';
import { DisplayTimeNumberPipe } from './display-time-number.pipe';
import { CardLayoutMetricPipe } from './card-layout-metric.pipe';
import { TimesheetFieldsPipe } from './timesheet-fields.pipe';
import { ToDollarPipe } from './to-dollar.pipe';
import { AssignmentCalSubtotalPipe } from './assignment-cal-subtotal.pipe';
import { FormattedFigurePipe } from './formatted-figure.pipe';
import { MeasurementUnitPipe } from './measurement-unit.pipe';
import { LongestTimesheetFieldLeafArrayPipe } from './longest-timesheet-field-leaf-array.pipe';
import { SelectedOptionPipe } from './selected-option.pipe';
import { DayOfMonthPipe } from './day-of-month.pipe';
import { IsWeekendPipe } from './is-weekend.pipe';
import { DurationTimeNumberPipe } from './duration-time-number.pipe';
import { ToDollarStrPipe } from './to-dollar-str.pipe';
import { WidgetRowValuePipe } from './widget-row-value.pipe';
import { GroupStatusLabelPipe } from './group-status-label.pipe';
import { EmptyArrayPipe } from './empty-array.pipe';
import { TimesheetFieldTotalPipe } from './timesheet-field-total.pipe';
import { ChatChannelTitlePipe } from './chat-channel-title.pipe';
import { ChatChannelTypeLabelPipe } from './chat-channel-type-label.pipe';
import { BytesToSizePipe } from './bytes-to-size.pipe';
import { RoleTypeLabelPipe } from './role-type-label.pipe';
import { PermissionLabelPipe } from './permission-label.pipe';
import { UserGroupsPopoverPageTitlePipe } from './user-groups-popover-page-title.pipe';
import { SelectedOptionLabelPipe } from './selected-option-label.pipe';
import { BackgroundImageStringPipe } from './background-image-string.pipe';
import { PrimaryTagsPopoverPageTitlePipe } from './primary-tags-popover-page-title.pipe';
import { WidgetTypeIconPipe } from './widget-type-icon.pipe';
import { RateTypeLabelPipe } from './rate-type-label.pipe';
import { RateTypeColorPipe } from './rate-type-color.pipe';
import { DisplayNameByTypePipe } from './display-name-by-type.pipe';
import { AllowRatesAssetGroupsPipe } from './allow-rates-asset-groups.pipe';
import { NodeWidgetsTitlePipe } from './node-widgets-title.pipe';
import { NodeAllowedTemplatesTitlePipe } from './node-allowed-templates-title.pipe';
import { TableNameByDisplayModePipe } from './table-name-by-display-mode.pipe';
import { LinksCountGroupsPipe } from './links-count-groups.pipe';

@NgModule({
  declarations: [
    SafePipe,
    MomentDatePipe,
    ConcatNounsPipe,
    ColorStringPipe,
    IconStringPipe,
    BackgroundImageStringPipe,
    ToNgbDatePipe,
    NgbDateFormatPipe,
    NiceDatePipe,
    WidgetTypeFilterPipe,
    WidgetTypeLabelPipe,
    TableNameByDisplayModePipe,
    WidgetTypeIconPipe,
    WidgetInputTypePipe,
    WidgetValuePipe,
    NodeTypeLabelPipe,
    ServerNodeTypeIconClassPipe,
    ServerNodeTypeIsFolderPipe,
    TagTypeLabelPipe,
    TagsPopoverPageTitlePipe,
    UserRoleLabelPipe,
    CanPipe,
    BackgroundImagePipe,
    TruncatePipe,
    IsTodayPipe,
    IsEndOfWeekPipe,
    ObjectKeyCountPipe,
    HasPreviousDateItemPipe,
    HasNextDateItemPipe,
    ConsecutiveDaysPipe,
    AssignmentChecklistsPopoverPageTitlePipe,
    WorkspaceTemplateImagePipe,
    CalculationMethodLabelPipe,
    TemplateFiguresPopoverPageTitlePipe,
    CalculationResultTypeLabelPipe,
    CalculationFormattedResultPipe,
    CalculationOperatorPipe,
    CalculationNumberResultPipe,
    LeafTagTypePipe,
    LinksCountGroupsPipe,
    CalculationWidgetFormulaPipe,
    ToOptionsPipe,
    InDateRangePipe,
    IsSameDatePipe,
    DurationTimePipe,
    DefaultTemplateIdPipe,
    HasChoicesSupportPipe,
    ValueByKeyPairPipe,
    GanttWidthPipe,
    IsGanttStartBoundaryPipe,
    GroupItemNamePipe,
    HasNonEmptyItemPipe,
    FaviconPipe,
    WidgetSortPipe,
    UrlWidgetToLinkPipe,
    SharedLinkUrlPipe,
    ToInitialsPipe,
    FirstWordPipe,
    ProgressStyleWidthPipe,
    GroupLinksPopoverPageTitlePipe,
    ToLinkModelPipe,
    RichSearchTypeLabelPipe,
    RichSearchSelectedIdsPipe,
    RichSearchFilterOptionsPipe,
    TimesheetTimePipe,
    ItemsFilterPipe,
    ToNgbTimePipe,
    DisplayTimeNumberPipe,
    CardLayoutMetricPipe,
    TimesheetFieldsPipe,
    ToDollarPipe,
    AssignmentCalSubtotalPipe,
    FormattedFigurePipe,
    MeasurementUnitPipe,
    LongestTimesheetFieldLeafArrayPipe,
    SelectedOptionPipe,
    DayOfMonthPipe,
    IsWeekendPipe,
    DurationTimeNumberPipe,
    ToDollarStrPipe,
    WidgetRowValuePipe,
    GroupStatusLabelPipe,
    EmptyArrayPipe,
    TimesheetFieldTotalPipe,
    ChatChannelTitlePipe,
    ChatChannelTypeLabelPipe,
    BytesToSizePipe,
    RoleTypeLabelPipe,
    PermissionLabelPipe,
    UserGroupsPopoverPageTitlePipe,
    SelectedOptionLabelPipe,
    PrimaryTagsPopoverPageTitlePipe,
    RateTypeLabelPipe,
    RateTypeColorPipe,
    DisplayNameByTypePipe,
    AllowRatesAssetGroupsPipe,
    NodeWidgetsTitlePipe,
    NodeAllowedTemplatesTitlePipe,
  ],
  imports: [CommonModule],
  exports: [
    CommonModule,
    SafePipe,
    ColorStringPipe,
    IconStringPipe,
    BackgroundImageStringPipe,
    NiceDatePipe,
    MomentDatePipe,
    ConcatNounsPipe,
    ToNgbDatePipe,
    NgbDateFormatPipe,
    WidgetTypeFilterPipe,
    WidgetTypeLabelPipe,
    TableNameByDisplayModePipe,
    WidgetTypeIconPipe,
    WidgetInputTypePipe,
    WidgetValuePipe,
    NodeTypeLabelPipe,
    ServerNodeTypeIconClassPipe,
    ServerNodeTypeIsFolderPipe,
    TagTypeLabelPipe,
    TagsPopoverPageTitlePipe,
    UserRoleLabelPipe,
    CanPipe,
    BackgroundImagePipe,
    TruncatePipe,
    IsTodayPipe,
    IsEndOfWeekPipe,
    ObjectKeyCountPipe,
    HasPreviousDateItemPipe,
    HasNextDateItemPipe,
    ConsecutiveDaysPipe,
    AssignmentChecklistsPopoverPageTitlePipe,
    WorkspaceTemplateImagePipe,
    CalculationMethodLabelPipe,
    TemplateFiguresPopoverPageTitlePipe,
    CalculationResultTypeLabelPipe,
    CalculationFormattedResultPipe,
    CalculationOperatorPipe,
    CalculationNumberResultPipe,
    LeafTagTypePipe,
    LinksCountGroupsPipe,
    CalculationWidgetFormulaPipe,
    ToOptionsPipe,
    InDateRangePipe,
    IsSameDatePipe,
    DurationTimePipe,
    DefaultTemplateIdPipe,
    HasChoicesSupportPipe,
    ValueByKeyPairPipe,
    GanttWidthPipe,
    IsGanttStartBoundaryPipe,
    GroupItemNamePipe,
    HasNonEmptyItemPipe,
    FaviconPipe,
    WidgetSortPipe,
    UrlWidgetToLinkPipe,
    SharedLinkUrlPipe,
    ToInitialsPipe,
    FirstWordPipe,
    ProgressStyleWidthPipe,
    GroupLinksPopoverPageTitlePipe,
    ToLinkModelPipe,
    RichSearchTypeLabelPipe,
    RichSearchSelectedIdsPipe,
    RichSearchFilterOptionsPipe,
    TimesheetTimePipe,
    ItemsFilterPipe,
    ToNgbTimePipe,
    DisplayTimeNumberPipe,
    CardLayoutMetricPipe,
    TimesheetFieldsPipe,
    ToDollarPipe,
    AssignmentCalSubtotalPipe,
    FormattedFigurePipe,
    MeasurementUnitPipe,
    LongestTimesheetFieldLeafArrayPipe,
    SelectedOptionPipe,
    DayOfMonthPipe,
    IsWeekendPipe,
    DurationTimeNumberPipe,
    ToDollarStrPipe,
    WidgetRowValuePipe,
    GroupStatusLabelPipe,
    EmptyArrayPipe,
    TimesheetFieldTotalPipe,
    ChatChannelTitlePipe,
    ChatChannelTypeLabelPipe,
    BytesToSizePipe,
    RoleTypeLabelPipe,
    PermissionLabelPipe,
    UserGroupsPopoverPageTitlePipe,
    SelectedOptionLabelPipe,
    PrimaryTagsPopoverPageTitlePipe,
    RateTypeLabelPipe,
    RateTypeColorPipe,
    DisplayNameByTypePipe,
    AllowRatesAssetGroupsPipe,
    NodeWidgetsTitlePipe,
    NodeAllowedTemplatesTitlePipe,
  ],
})
export class PipesModule {}
