import { createAction, props } from '@ngrx/store';
import { TemplateTagModel } from '../../core/models/template-tag.model';

export const addTemplateTagsRequest = createAction(
  '[Template Tags] add template tags request',
  props<{ templateId: number; tagIds: number[]; groupId: number; replace: boolean }>(),
);

export const addTemplateTagsSuccess = createAction(
  '[Template Tags] add template tags success',
  props<{ templateTags: TemplateTagModel[]; groupId: number; replace: boolean }>(),
);

export const removeTemplateTagRequest = createAction(
  '[Template Tags] remove template tag request',
  props<{ templateId: number; joinId: number }>(),
);

export const removeTemplateTagsByTagId = createAction(
  '[Template Tags] remove template tags by tag id request',
  props<{ tagId: number }>(),
);

export const sortTemplateTagsRequest = createAction(
  '[Template Tags] sort template tags request',
  props<{ templateId: number; joinIds: number[] }>(),
);

export const clearTemplateTagsRequest = createAction(
  '[Template Tags] clear template tags request',
  props<{ templateId: number }>(),
);
