import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ChatService } from '../../../core/services/chat.service';

export interface FilePreview {
  file: File;
  objectUrl: string;
  isUrlOnly?: boolean;
}

@Component({
  selector: 'app-file-attachment',
  templateUrl: './file-attachment.component.html',
  styleUrls: ['./file-attachment.component.scss'],
})
export class FileAttachmentComponent implements OnInit, OnDestroy {
  @Input() public fileTypes: string;

  @Output() filePreviewEvent = new EventEmitter<FilePreview>();

  objectUrl: string;

  constructor() {}

  ngOnInit(): void {}

  getFileNameFromUrl(filename: string): string {
    return filename.substring(filename.lastIndexOf('.') + 1, filename.length) || filename;
  }

  imagePreview(event) {
    if (this.objectUrl) {
      URL.revokeObjectURL(this.objectUrl);
    }
    const file: File = (event.target as HTMLInputElement).files[0];
    this.objectUrl = URL.createObjectURL(file);
    this.filePreviewEvent.emit({
      file,
      objectUrl: this.objectUrl,
    });
  }

  ngOnDestroy() {
    if (this.objectUrl) {
      URL.revokeObjectURL(this.objectUrl);
    }
  }
}
