<div
  class="assignment-asset"
  [ngClass]="[
    displayAsList ? 'assignment-asset--display-as-list' : 'assignment-asset--display-not-as-list',
    size ? 'assignment-asset--' + size : '',
    isDuplicate ? 'assignment-asset--duplicate' : ''
  ]"
>
  <app-node-avatar
    *ngIf="showProfilePhoto"
    [profile]="node?.profile"
    [profileIcon]="node?.nodeMeta?.profileIcon"
    [fullName]="node?.title"
    [initials]="node?.title | toInitials"
    [size]="size ? size : 'md'"
    [colorTheme]="node.colorTheme"
    [revertColor]="false"
    [hideTooltip]="true"
    class="assignment-asset__avatar"
  ></app-node-avatar>
  <div class="assignment-asset__title" *ngIf="size !== 'sm' && displayAsList">
    {{ displayAsList ? node.title : (node.title | firstWord) }}
  </div>
  <app-btn-view class="assignment-asset__view-btn" (click)="view($event)"></app-btn-view>
</div>
