import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../store';
import { PropertiesPanelActions } from '../../../../store/properties-panel';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmPopupArchiveNodeComponent } from '../../../../shared/modals/confirm-popup-archive-node/confirm-popup-archive-node.component';
import { NodeModel } from '../../../../core/models/node.model';
import { ArchivePopoverActions } from '../../../../store/archive-popover';
import { Subscription } from 'rxjs';
import { WorkspaceUserModel } from '../../../../core/models/workspace-user.model';
import { selectLoggedInWorkspaceUser } from '../../../../store/workspace-users/workspace-users.selectors';
import { PermissionType } from '../../../../core/constants/permission-type';
import { NodeListPanelActions } from '../../../../store/node-list-panel';
import { NodesActions } from '../../../../store/nodes';
import { NodeUtils } from '../../../../core/utils/node.util';
import { NodeType } from '../../../../core/constants/node-type';
import { FolderPickerComponent } from '../../../../shared/modals/folder-picker/folder-picker.component';

@Component({
  selector: 'app-folder-menu',
  templateUrl: './folder-menu.component.html',
  styleUrls: ['./folder-menu.component.scss'],
})
export class FolderMenuComponent implements OnInit, OnDestroy {
  private readonly subscription = new Subscription();
  public readonly PermissionType = PermissionType;
  public readonly NodeType = NodeType;

  @Input()
  public node: NodeModel;

  @Input()
  isRootFolder: boolean;

  public childNodeType: NodeType;

  public loggedInUser: WorkspaceUserModel;

  constructor(private store: Store<AppState>, private modalService: NgbModal) {}

  ngOnInit() {
    this.subscription.add(
      this.store.pipe(select(selectLoggedInWorkspaceUser)).subscribe(loggedInUser => {
        this.loggedInUser = loggedInUser;
      }),
    );

    this.childNodeType = NodeUtils.getChildNodeType(this.node.nodeType);
  }

  editProperties() {
    if (NodeUtils.isShortcut(this.node) === true) {
      return;
    }
    this.store.dispatch(
      PropertiesPanelActions.openPropertiesPanel({
        nodeId: this.node.id,
      }),
    );
  }

  importExport(): void {
    // this.openNodeListPanel();
    // if (NodeUtils.isShortcut(this.node) === true) {
    //   return;
    // }
    // this.store.dispatch(
    //   ExportImportPanelActions.openExportImportPanel({
    //     nodeId: this.node.id,
    //   }),
    // );
  }

  addFolder() {
    if (NodeUtils.isShortcut(this.node) === true) {
      return;
    }
    this.store.dispatch(
      NodesActions.addNodeRequest({
        parentNode: this.node,
        nodeType: this.node.nodeType,
      }),
    );
  }

  moveFolder() {
    const modelRef = this.modalService.open(FolderPickerComponent, {
      size: 'xs',
      backdrop: 'static',
    });

    const isShortcut = NodeUtils.isShortcut(this.node) === true;
    modelRef.componentInstance.types = isShortcut
      ? [
          NodeType.favouritesFolder,
          NodeType.projectFolder,
          NodeType.assetFolder,
          NodeType.elementFolder,
        ]
      : [this.node.nodeType];

    modelRef.componentInstance.disabled = isShortcut
      ? [this.node, this.node.reference]
      : [this.node];

    modelRef.componentInstance.selected = [];
    modelRef.componentInstance.minSelection = 1;
    modelRef.componentInstance.maxSelection = 1;
    modelRef.result
      .then((result: NodeModel[]) => {
        this.store.dispatch(
          NodesActions.moveNodesRequest({
            nodeIds: [this.node.id],
            toParentId: result[0]?.id,
            sortIndex: 0,
          }),
        );
      })
      .catch(res => {});
  }

  addShortcut() {
    if (NodeUtils.isShortcut(this.node) === true) {
      return;
    }
    const modelRef = this.modalService.open(FolderPickerComponent, {
      size: 'xs',
      backdrop: 'static',
    });

    modelRef.componentInstance.types = [
      NodeType.projectFolder,
      NodeType.assetFolder,
      NodeType.elementFolder,
      NodeType.favouritesFolder,
    ];
    modelRef.componentInstance.selected = [];
    modelRef.componentInstance.disabled = [this.node];
    modelRef.componentInstance.minSelection = 1;
    modelRef.componentInstance.maxSelection = 1;
    modelRef.result
      .then((result: NodeModel[]) => {
        this.store.dispatch(
          NodesActions.addFolderShortcutRequest({
            sourceId: this.node.id,
            targetId: result[0]?.id,
          }),
        );
      })
      .catch(res => {});
  }

  addLeaf() {
    if (NodeUtils.isShortcut(this.node) === true) {
      return;
    }
    this.store.dispatch(
      NodesActions.addNodeRequest({
        parentNode: this.node,
        nodeType: this.childNodeType,
      }),
    );
  }

  archiveFolder() {
    if (NodeUtils.isShortcut(this.node) === true) {
      const modelRef = this.modalService.open(ConfirmPopupArchiveNodeComponent, {
        size: 'md',
        backdrop: 'static',
      });
      modelRef.componentInstance.title = 'Delete Shortcut';
      modelRef.componentInstance.message = 'Do you want to delete this folder shortcut?';
      modelRef.result.then((result?: boolean) => {
        if (result) {
          this.store.dispatch(
            NodesActions.removeNodeRequest({
              node: this.node,
            }),
          );
        }
      });
    } else {
      this.store.dispatch(
        ArchivePopoverActions.checkReferenceCountRequest({ nodeIds: [this.node.id] }),
      );

      const modelRef = this.modalService.open(ConfirmPopupArchiveNodeComponent, {
        size: 'md',
        backdrop: 'static',
      });
      modelRef.componentInstance.title = 'Archive Folder';
      modelRef.componentInstance.message = 'Do you want to archive this Folder?';
      modelRef.result.then((result?: boolean) => {
        if (result) {
          this.store.dispatch(
            NodesActions.archiveNodesRequest({
              nodeIds: [this.node.id],
            }),
          );
        }
      });
    }
  }

  openNodeListPanel() {
    this.store.dispatch(
      NodeListPanelActions.openNodeListPanel({
        node: NodeUtils.isShortcut(this.node) === true ? this.node.reference : this.node,
      }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
