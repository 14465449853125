import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../store';
import { TagModel } from '../../../core/models/tag.model';
import { TagsActions, TagsSelectors } from '../../../store/tags';
import { TagsPopoverActions } from '../../../store/tags-popover';
import { TagsPopoverPage } from '../../../core/constants/tags-popover-page';
import { TagEditComponent } from '../tag-edit/tag-edit.component';
import { AbstractPickerComponent, ListItem } from '../_abstract-picker/abstract-picker.component';
import { NodeModel } from '../../../core/models/node.model';
import { TemplateTagModel } from '../../../core/models/template-tag.model';

@Component({
  templateUrl: './tags-picker-multiple.component.html',
  styleUrls: ['./tags-picker-multiple.component.scss'],
})
export class TagsPickerMultipleComponent extends AbstractPickerComponent<TagModel>
  implements OnInit, OnDestroy {
  @Input()
  public allowEdit: boolean;

  @Input()
  public allowNew: boolean;

  @Input()
  public assignment: NodeModel;

  public currentStep = 1;
  public stepLength = 0;
  private originalList: TagModel[];

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private store: Store<AppState>,
  ) {
    super(activeModal);

    this.allowEdit = true;
    this.allowNew = true;

    this.subscription.add(
      this.store.pipe(select(TagsSelectors.selectAllTagsWithKeyword)).subscribe(tags => {
        this.originalList = tags;
        this.updateList(tags);
      }),
    );
  }

  ngOnInit(): void {
    this.stepLength = this.assignment?.stampTagGroups?.length;
  }

  private getStampTag(): TemplateTagModel {
    return this.assignment?.stampTagGroups[this.currentStep - 1]?.tags[0];
  }

  public search(keywords) {
    this.keywords = keywords;
    this.store.dispatch(TagsActions.updateSearchKeywordRequest({ searchKeyword: this.keywords }));
  }

  public isEqual(itemA: TagModel, itemB: TagModel): boolean {
    return itemA.id === itemB.id;
  }

  goToNext(): void {
    this.currentStep++;
    this.selected = this.getStampTag() ? [this.getStampTag()?.tag] : [];
    this.whitelist = (this.assignment?.nodeTemplate?.tags || []).filter(
      t => t.groupId === this.getGroupId(),
    ) as any[];
    this.updateList(this.originalList);
  }

  goToBack(): void {
    this.currentStep--;
    this.selected = this.getStampTag() ? [this.getStampTag()?.tag] : [];
    this.whitelist = (this.assignment?.nodeTemplate?.tags || []).filter(
      t => t.groupId === this.getGroupId(),
    ) as any[];
    this.updateList(this.originalList);
  }

  getGroupId(): number {
    return this.assignment?.stampTagGroups[this.currentStep - 1]?.id;
  }

  public onSubmit() {
    if (this.verifySelection() === false) {
      return;
    }
    this.activeModal.close({
      groupId: this.getGroupId(),
      tagId: this.selected[0]?.id,
    });
  }

  public add() {
    this.modalService.open(TagEditComponent, {
      size: 'sm',
      backdrop: 'static',
    });
  }

  public edit(listItem: ListItem<TagModel>) {
    this.store.dispatch(
      TagsPopoverActions.showTagEditPage({
        tagId: listItem.model.id,
        tagType: null,
        previousPage: TagsPopoverPage.tags,
        templateId: null,
        nodeId: undefined,
      }),
    );
    this.modalService.open(TagEditComponent, {
      size: 'sm',
      backdrop: 'static',
    });
  }

  public trackBy(index, item: ListItem<TagModel>) {
    return item.model.id;
  }

  public ngOnDestroy(): void {
    this.store.dispatch(TagsPopoverActions.resetTagEditPage());
    this.store.dispatch(TagsActions.updateSearchKeywordRequest({ searchKeyword: '' }));
    super.ngOnDestroy();
  }
}
