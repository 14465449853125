import { Pipe, PipeTransform } from '@angular/core';
import { WidgetTypeLabelPipe } from './widget-type-label.pipe';
import { WidgetUtils } from '../../core/utils/widget.util';
import { CalculationWidgetModel } from '../../core/models/calculation-widget.model';

@Pipe({
  name: 'calculationResultTypeLabel',
})
export class CalculationResultTypeLabelPipe implements PipeTransform {
  constructor(private widgetTypeLabelPipe: WidgetTypeLabelPipe) {}

  transform(cal: CalculationWidgetModel): string {
    let widgetType = WidgetUtils.getCalculationResultWidgetType(cal);

    return this.widgetTypeLabelPipe.transform(widgetType);
  }
}
