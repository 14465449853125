import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { WorkspaceTemplateModel } from '../models/workspace-template.model';
import { ResponseMessage } from '../models/response-message.model';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { WorkspaceMetaModel } from '../models/workspace-meta.model';
import { WorkspaceEditableProps } from '../models/workspace.model';

@Injectable({ providedIn: 'root' })
export class WorkspaceTemplatesService {
  constructor(private http: HttpClient) {}

  public loadWorkspaceTemplates(): Observable<WorkspaceTemplateModel[]> {
    return this.http
      .get<ResponseMessage<WorkspaceTemplateModel[]>>(
        `${environment.apiBaseUrl}/workspace/templates/all`,
      )
      .pipe(map(response => response.data));
  }

  public updateWorkspaceTemplate(id: number, props: Partial<WorkspaceEditableProps>) {
    return this.http
      .put<ResponseMessage<any>>(`${environment.apiBaseUrl}/workspace/template/${id}/update`, {
        ...props,
      })
      .pipe(map(response => response.data));
  }

  public patchWorkspaceTemplateMeta(id: number, meta: Partial<WorkspaceMetaModel>) {
    return this.http
      .patch<ResponseMessage<any>>(
        `${environment.apiBaseUrl}/workspace/template/${id}/meta/update`,
        meta,
      )
      .pipe(map(response => response.data));
  }

  public removeWorkspaceTemplate(id: number): Observable<ResponseMessage<any>> {
    return this.http.delete<ResponseMessage<any>>(
      `${environment.apiBaseUrl}/workspace/template/${id}/remove`,
    );
  }
}
