import { Component, Input, OnInit } from '@angular/core';
import { PropertiesPanelActions } from '../../../../store/properties-panel';
import { AppState } from '../../../../store';
import { select, Store } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmPopupArchiveNodeComponent } from '../../../../shared/modals/confirm-popup-archive-node/confirm-popup-archive-node.component';
import { ArchivePopoverActions } from '../../../../store/archive-popover';
import { selectAvailableNodeTemplateOptionsWithProps } from '../../../../store/templates/templates.selectors';
import { take } from 'rxjs/operators';
import { ConfirmPopupComponent } from '../../../../shared/modals/confirm-popup/confirm-popup.component';
import { OptionModel } from '../../../../core/models/option.model';
import { ChatbarActions } from '../../../../store/chatbar';
import { TagsPickerComponent } from '../../../../shared/modals/tags-picker/tags-picker.component';
import { TagModel } from '../../../../core/models/tag.model';
import { NodeTagsActions } from '../../../../store/node-tags';
import { NodeModel } from '../../../../core/models/node.model';
import { NodesActions } from '../../../../store/nodes';
import { NodeUtils } from '../../../../core/utils/node.util';
import { NodeRatesComponent } from '../../../../shared/modals/node-rates/node-rates.component';
import { NodeType } from '../../../../core/constants/node-type';
import { FolderPickerComponent } from '../../../../shared/modals/folder-picker/folder-picker.component';

@Component({
  selector: 'app-leaf-menu',
  templateUrl: './leaf-menu.component.html',
  styleUrls: ['./leaf-menu.component.scss'],
})
export class LeafMenuComponent implements OnInit {
  public readonly NodeType = NodeType;

  @Input()
  node: NodeModel;

  templateOptions: OptionModel[];

  constructor(private store: Store<AppState>, private modalService: NgbModal) {}

  ngOnInit() {
    this.store
      .pipe(select(selectAvailableNodeTemplateOptionsWithProps(this.node.nodeType)), take(1))
      .subscribe(options => {
        this.templateOptions = options;
      });
  }

  editProperties() {
    this.store.dispatch(
      PropertiesPanelActions.openPropertiesPanel({
        nodeId: this.node.id,
      }),
    );
  }

  archiveElement() {
    this.store.dispatch(
      ArchivePopoverActions.checkReferenceCountRequest({ nodeIds: [this.node.id] }),
    );

    const modelRef = this.modalService.open(ConfirmPopupArchiveNodeComponent, {
      size: 'md',
      backdrop: 'static',
    });
    const typeName = NodeUtils.getNodeTypeLabel(this.node.nodeType);
    modelRef.componentInstance.title = `Archive ${typeName}`;
    modelRef.componentInstance.message = `Do you want to archive this ${typeName}?`;
    modelRef.result
      .then((result?: boolean) => {
        if (result) {
          this.store.dispatch(
            NodesActions.archiveNodesRequest({
              nodeIds: [this.node.id],
            }),
          );
        }
      })
      .catch(res => {});
  }

  openChat() {
    this.store.dispatch(ChatbarActions.clickNodeChatIcon({ nodeId: this.node.id }));
  }

  changeTemplate(option: OptionModel) {
    const modelRef = this.modalService.open(ConfirmPopupComponent, {
      size: 'md',
      backdrop: 'static',
    });
    modelRef.componentInstance.title = 'Apply Template';
    modelRef.componentInstance.message = `Do you want to apply ${option.label} template to the item ?`;
    modelRef.result
      .then((result?: boolean) => {
        if (result) {
          if (typeof option.id === 'string') {
            this.store.dispatch(
              NodesActions.applyTemplateToNodesRequest({
                nodeIds: [this.node.id],
                nodeTemplateId: parseInt(option.id, 10),
              }),
            );
          }
        }
      })
      .catch(res => {});
  }

  openTagsPopover() {
    const modelRef = this.modalService.open(TagsPickerComponent, {
      size: 'xl',
      backdrop: 'static',
    });
    modelRef.componentInstance.selected = this.node.tags || [];
    modelRef.result
      .then((result: TagModel[]) => {
        this.store.dispatch(
          NodeTagsActions.addMultipleTagsToNodeRequest({
            nodeId: this.node.id,
            tagIds: (result || []).map(t => t.id),
            groupId: undefined,
            replace: true,
          }),
        );
      })
      .catch(res => {});
  }

  showRates() {
    const modelRef = this.modalService.open(NodeRatesComponent, {
      size: 'xxl',
      backdrop: 'static',
    });
    modelRef.componentInstance.node = this.node;
  }

  moveFolder() {
    const modelRef = this.modalService.open(FolderPickerComponent, {
      size: 'xs',
      backdrop: 'static',
    });

    modelRef.componentInstance.types = [NodeUtils.getParentNodeType(this.node.nodeType)];
    modelRef.componentInstance.selected = [];
    modelRef.componentInstance.disabled = [];
    modelRef.componentInstance.minSelection = 1;
    modelRef.componentInstance.maxSelection = 1;
    modelRef.result
      .then((result: NodeModel[]) => {
        this.store.dispatch(
          NodesActions.moveNodesRequest({
            nodeIds: [this.node.id],
            toParentId: result[0]?.id,
            sortIndex: 0,
          }),
        );
      })
      .catch(res => {});
  }

  copyElement(): void {
    this.store.dispatch(
      NodesActions.copyNodeRequest({
        nodeId: this.node?.id,
      } as any),
    );
  }

  copyAsset(): void {
    this.store.dispatch(
      NodesActions.copyNodeRequest({
        nodeId: this.node?.id,
      } as any),
    );
  }
}
