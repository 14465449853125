<div class="account-modal">
  <app-sticky-panel-header
    [title]="'Zedulr Account'"
    (closeEvent)="close()"
  ></app-sticky-panel-header>

  <div class="account-modal__body">
    <div class="account-modal__avatar-container">
      <app-node-avatar-editable
        [profile]="user?.profile"
        [profileIcon]="user?.metaData?.profileIcon"
        [fullName]="user?.firstName + ' ' + user?.lastName"
        [initials]="user?.initials"
        class="account-modal__avatar"
        [ngbPopover]="popContent"
        #popover="ngbPopover"
        triggers="manual"
        [placement]="defaultPlacements"
        (click)="changePhoto(popover)"
      ></app-node-avatar-editable>
    </div>
    <form class="account-modal__form" [formGroup]="accountForm">
      <div class="form-group">
        <label for="firstName">First Name</label>
        <input
          id="firstName"
          type="text"
          formControlName="firstName"
          class="form-control"
          [ngClass]="{ 'is-invalid': f.firstName.errors && f.firstName.touched }"
          placeholder="First name"
          (blur)="updateUserField('firstName')"
        />
        <app-control-validation
          [control]="f.firstName"
          [submitted]="false"
        ></app-control-validation>
      </div>

      <div class="form-group">
        <label for="lastName">Last Name</label>
        <input
          id="lastName"
          type="text"
          formControlName="lastName"
          class="form-control"
          [ngClass]="{ 'is-invalid': f.lastName.errors && f.lastName.touched }"
          placeholder="Last Name"
          (blur)="updateUserField('lastName')"
        />
        <app-control-validation [control]="f.lastName" [submitted]="false"></app-control-validation>
      </div>

      <div class="form-group">
        <label for="phone">Phone <span class="ap-optional-text">- optional</span></label>
        <input
          id="phone"
          type="phone"
          formControlName="phone"
          class="form-control"
          [ngClass]="{ 'is-invalid': f.phone.errors && f.phone.touched }"
          placeholder="Phone"
          (blur)="updateUserField('phone')"
        />
        <app-control-validation [control]="f.phone" [submitted]="false"></app-control-validation>
      </div>

      <div class="form-group">
        <label for="email">Email</label>
        <input
          id="email"
          type="text"
          formControlName="email"
          class="form-control"
          [ngClass]="{ 'is-invalid': f.email.errors && f.email.touched }"
          placeholder="Email"
          (blur)="updateUserField('email')"
        />
        <app-control-validation [control]="f.email" [submitted]="false"></app-control-validation>
      </div>
    </form>
  </div>
</div>

<ng-template #popContent>
  <app-profile-photo-menu></app-profile-photo-menu>
</ng-template>
