import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { TagEditableProps, TagModel } from '../models/tag.model';
import { HttpClient } from '@angular/common/http';
import { ColorType } from '../constants/color-type';
import { ResponseMessage } from '../models/response-message.model';
import { map } from 'rxjs/operators';
import { IconType } from '../constants/icon-type';
import { TagType } from '../constants/tag-type';

@Injectable({ providedIn: 'root' })
export class TagService {
  constructor(private http: HttpClient) {}

  public loadTags(workspaceSlug: string): Observable<TagModel[]> {
    return this.http
      .get<ResponseMessage<TagModel[]>>(`${environment.apiBaseUrl}/workspace/${workspaceSlug}/tags`)
      .pipe(map(response => response.data));
  }

  public addTag(
    workspaceSlug: string,
    title: string,
    colorTheme: ColorType,
    icon: IconType,
    tagType: TagType,
    searchTerms?: string,
    sortIndex?: number,
  ) {
    return this.http
      .post<ResponseMessage<any>>(`${environment.apiBaseUrl}/workspace/${workspaceSlug}/tag/add`, {
        title,
        colorTheme,
        tagType: tagType,
        icon,
        searchTerms: searchTerms || undefined, // to avoid empty string
        sortIndex: sortIndex || undefined, // to avoid empty string
      })
      .pipe(map(response => response.data));
  }

  public updateTag(tagId: number, tagProps: Partial<TagEditableProps>): Observable<object> {
    return this.http.patch<object>(`${environment.apiBaseUrl}/workspace/tag/${tagId}/update`, {
      ...tagProps,
    });
  }

  public removeTag(tagId: number): Observable<any> {
    return this.http.delete(`${environment.apiBaseUrl}/workspace/tag/${tagId}/remove`);
  }

  public sortTags(tagIds: number[]): Observable<any> {
    return this.http
      .post<ResponseMessage<any>>(`${environment.apiBaseUrl}/workspace/tag/sort`, { ids: tagIds })
      .pipe(map(response => response.data));
  }
}
