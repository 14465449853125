import { NodeWidgetRowModel } from '../../core/models/node-widget-row.model';

export class NodeWidgetRowsState {
  nodeWidgetRowsById: { [id: number]: NodeWidgetRowModel };
  nodeWidgetRowsByNodeId: { [nodeId: number]: NodeWidgetRowModel[] };
  nodeWidgetRowsByWidgetId: { [widgetId: number]: NodeWidgetRowModel[] };
}
export const initialNodeWidgetRowsState: NodeWidgetRowsState = {
  nodeWidgetRowsById: {},
  nodeWidgetRowsByNodeId: {},
  nodeWidgetRowsByWidgetId: {},
};
