import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentLoaderComponent } from './content-loader.component';

import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
export function playerFactory() {
  return player;
}
@NgModule({
  imports: [CommonModule, LottieModule.forRoot({ player: playerFactory })],
  declarations: [ContentLoaderComponent],
  exports: [ContentLoaderComponent],
  providers: [],
})
export class ContentLoaderModule {}
