import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../store';
import { NodeTemplateModel } from '../../../core/models/node-template.model';
import { NodeType } from '../../../core/constants/node-type';
import { TemplatesActions, NodeTemplatesSelectors } from '../../../store/templates';
import { AbstractPickerComponent, ListItem } from '../_abstract-picker/abstract-picker.component';

@Component({
  templateUrl: './node-template-picker.component.html',
  styleUrls: ['./node-template-picker.component.scss'],
})
export class NodeTemplatePickerComponent extends AbstractPickerComponent<NodeTemplateModel>
  implements OnInit, OnDestroy {
  @Input()
  public types: NodeType[];

  constructor(public activeModal: NgbActiveModal, private store: Store<AppState>) {
    super(activeModal);
    this.types = [NodeType.project, NodeType.assignment, NodeType.asset, NodeType.element];
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.subscription.add(
      this.store
        .pipe(select(NodeTemplatesSelectors.selectNodeTemplatesByNodeTypeWithKeyword(this.types)))
        .subscribe(templates => {
          this.updateList(templates);
        }),
    );
  }

  public search(keywords) {
    this.keywords = keywords;
    this.store.dispatch(
      TemplatesActions.updateSearchKeywordRequest({ searchKeyword: this.keywords }),
    );
  }

  public isEqual(itemA: NodeTemplateModel, itemB: NodeTemplateModel): boolean {
    return itemA.id === itemB.id;
  }

  public add() {
    throw new Error('Not implemented');
  }

  public edit(listItem: ListItem<NodeTemplateModel>) {
    throw new Error('Not implemented');
  }

  public trackBy(index, item: ListItem<NodeTemplateModel>) {
    return item.model.id;
  }

  public ngOnDestroy(): void {
    this.store.dispatch(TemplatesActions.updateSearchKeywordRequest({ searchKeyword: '' }));
    super.ngOnDestroy();
  }
}
