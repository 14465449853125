import {Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'app-btn-edit',
  templateUrl: './btn-edit.component.html',
  styleUrls: ['./btn-edit.component.scss'],
})
export class BtnEditComponent implements OnInit {
  @Input() label: string;

  constructor() {
  }

  ngOnInit(): void {
  }
}
