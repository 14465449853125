import { Pipe, PipeTransform } from '@angular/core';
import { WidgetType } from '../../core/constants/widget-type';

@Pipe({
  name: 'widgetTypeLabel',
})
export class WidgetTypeLabelPipe implements PipeTransform {
  transform(widgetType: WidgetType | string): string {
    const type = typeof widgetType === 'string' ? parseInt(widgetType, 10) : widgetType;

    switch (type) {
      case WidgetType.textbox:
        return 'Text Box';
      case WidgetType.textarea:
        return 'Textarea';
      case WidgetType.address:
        return 'Address';
      case WidgetType.checkbox:
        return 'Checkbox';
      case WidgetType.date:
        return 'Date';
      case WidgetType.currency:
        return 'Currency';
      case WidgetType.email:
        return 'Email';
      case WidgetType.number:
        return 'Number';
      case WidgetType.phone:
        return 'Phone';
      case WidgetType.slider:
        return 'Slider';
      case WidgetType.url:
        return 'URL';
      case WidgetType.urlList:
        return 'Link List';
      case WidgetType.calculationJoin:
        return 'Calculation';
      case WidgetType.dateTime:
        return 'Date Time';
      case WidgetType.time:
        return 'Time';
      case WidgetType.projectList:
        return 'Project List';
      case WidgetType.assetList:
        return 'Asset List';
      case WidgetType.heading:
        return 'Heading';
      case WidgetType.lineSeparator:
        return 'Line Separator';
      case WidgetType.subHeading:
        return 'Sub Heading';
      case WidgetType.paragraph:
        return 'Paragraph';
      default:
        return 'Unknown';
    }
  }
}
