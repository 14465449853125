<app-workspace-user class="comment-user-avatar" [user]="user" [isActive]="true"
                    [size]="'xl'"
                    [ngbTooltip]="statusToolTip"
                    [placement]="defaultPlacements"
                    triggers="click"
                    container="body"
                    [autoClose]="'outside'"
></app-workspace-user>
<ng-template #statusToolTip>
  <ng-container *ngIf="editedDate">
    Edited <span class="font-italic">{{ editedDate | niceDate }}</span> by

  </ng-container>
  <span class="font-italic">{{ user?.firstName + ' ' + user?.lastName}}</span>
</ng-template>
