import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {SizeType} from '../../../core/constants/size-type';
import {Subject, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss'],
})
export class SearchBoxComponent implements OnInit, OnDestroy {
  @ViewChild('input', {static: true}) input;
  @Input() id: string;
  @Input() searchKeyword: string;
  @Input() sizeType: SizeType;
  @Input() inputWidth: string = '100%'; // Default width
  @Input() inputHeight: string = '45px'; // Default height
  @Output() searchChanged = new EventEmitter<string>();

  $search = new Subject<string>();

  subscription = new Subscription();

  constructor() {
  }

  ngOnInit() {
    this.subscription.add(
      this.$search.pipe(debounceTime(300), distinctUntilChanged()).subscribe(keyword => {
        this.searchChanged.emit(keyword);
      }),
    );
  }

  keyup($event) {
    this.$search.next($event.target.value);
  }

  cancelSearch() {
    // this is for clicking chrome search cancel issue
    this.$search.next('');
  }

  setFocus() {
    this.input.nativeElement.focus();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
