import * as moment from 'moment';
import { DATE_KEY_FORMAT } from '../constants/date-format.constants';
import { DayOfWeekType } from '../constants/day-of-week-type';

const transformRecurringSettingsToDateKeys = (
  startDate: string | Date,
  endDate: string | Date,
  daysOfWeek: DayOfWeekType[],
): string[] => {
  const dateKeys = [];
  const startMoment = moment(startDate);
  const endMoment = moment(endDate);

  const dayDiff = endMoment.diff(startMoment, 'days');
  if (dayDiff < 0) {
    return [];
  } else {
    for (let i = 0; i <= dayDiff; i++) {
      const nextMoment = moment(startMoment.format(DATE_KEY_FORMAT)).add(i, 'days');
      const dayOfWeek = nextMoment.day();
      if (daysOfWeek.indexOf(dayOfWeek) >= 0) {
        dateKeys.push(nextMoment.format(DATE_KEY_FORMAT));
      }
    }
    return dateKeys;
  }
};

const dateKeyCompareFn = (dateKeyA: string, dateKeyB: string) => {
  if (!dateKeyA) {
    return !dateKeyB ? 0 : -1;
  } else if (!dateKeyB) {
    return 1;
  }

  const dateAMoment = moment(dateKeyA);
  const dateDiff = dateAMoment.diff(dateKeyB, 'days');
  return dateDiff;
};

const getDateKeyOfToday = () => {
  return moment().format(DATE_KEY_FORMAT);
};

export { transformRecurringSettingsToDateKeys, dateKeyCompareFn, getDateKeyOfToday };
