import { createAction, props } from '@ngrx/store';
import { TimesheetEditableProps, TimesheetModel } from '../../core/models/timesheet.model';
import { NodeModel } from '../../core/models/node.model';

export const loadTimesheetsRequest = createAction(
  '[Timesheets] load timesheets request',
  props<{ nullDate?: boolean }>(),
);

export const loadTimesheetsByProjectsRequest = createAction(
  '[Timesheets] load timesheets by projects request',
  props<{ projects?: NodeModel[] }>(),
);

export const loadTimesheetsSuccess = createAction(
  '[Timesheets] load timesheets success',
  props<{ timesheets: TimesheetModel[] }>(),
);

export const addTimesheetRequest = createAction(
  '[Timesheets] add timesheet request',
  props<{
    assetId: number;
    assignmentId: number;
    timesheetProps: Partial<TimesheetEditableProps>;
  }>(),
);

export const addTimesheetSuccess = createAction(
  '[Timesheets] add timesheet success',
  props<{ timesheet: TimesheetModel }>(),
);

export const updateTimesheetRequest = createAction(
  '[Timesheets] update timesheet request',
  props<{ timesheetId: number; timesheetProps: Partial<TimesheetEditableProps> }>(),
);

export const updateTimesheetSuccess = createAction(
  '[Timesheets] update timesheet success',
  props<{ timesheet: TimesheetModel }>(),
);
