import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { NoteModel } from '../models/note.model';
import { ResponseMessage } from '../models/response-message.model';
import { map } from 'rxjs/operators';
import { NoteType } from '../constants/note-type';

@Injectable({ providedIn: 'root' })
export class NodeNotesService {
  constructor(private http: HttpClient) {}

  public addNodeNote(nodeId: number, note: string, noteType: NoteType): Observable<NoteModel> {
    return this.http
      .post<ResponseMessage<NoteModel>>(
        `${environment.apiBaseUrl}/workspace/node/${nodeId}/note/add`,
        {
          note,
          noteType,
        },
      )
      .pipe(map(response => response.data as NoteModel));
  }

  public updateNodeNote(noteId: number, note: string): Observable<NoteModel> {
    return this.http
      .patch<ResponseMessage<NoteModel>>(
        `${environment.apiBaseUrl}/workspace/note/${noteId}/update`,
        {
          note,
        },
      )
      .pipe(map(response => response.data));
  }

  public deleteNodeNote(nodeId: number, noteId: number): Observable<object> {
    return this.http.delete(
      `${environment.apiBaseUrl}/workspace/node/${nodeId}/note/${noteId}/remove`,
    );
  }
}
