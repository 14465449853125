import { Action, createReducer, on } from '@ngrx/store';
import { initialWidgetTypesState, WidgetTypesState } from './widget-types.state';
import { WidgetTypesActions } from './index';

const reducer = createReducer<WidgetTypesState>(
  initialWidgetTypesState,
  on(WidgetTypesActions.loadWidgetTypesSuccess, (state, { widgetTypes }) => ({
    ...initialWidgetTypesState,
    widgetTypes,
  })),
);

export function widgetTypesReducer(state: WidgetTypesState, action: Action) {
  return reducer(state, action);
}
