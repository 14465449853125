import { OptionModel } from '../models/option.model';

export enum GroupingType {
  daily,
  weekly,
  monthly,
}

export const groupingOptions: OptionModel[] = [
  {
    id: GroupingType.daily,
    label: 'Daily',
  },
  {
    id: GroupingType.weekly,
    label: 'Weekly',
  },
  {
    id: GroupingType.monthly,
    label: 'Monthly',
  },
];
