<div
  class="content"
  [ngClass]="{
    'content--public': publicView
  }"
>
  <div class="calendar__view-port-item" *ngFor="let dateKey of dateKeys; trackBy: trackBy">
    <div
      class="calendar-row"
      [ngClass]="[
        scheduleLayout ? 'calendar-row--' + scheduleLayout : '',
        dateKeys?.indexOf(dateKey) % 2 === 0 ? 'calendar-row--even' : '',
        dateKey === awaitingAssignmentsDateKey ? 'calendar-row--awaiting-assignments' : ''
      ]"
      [id]="'calendar-row' + dateKey"
      cdkDropList
    >
      <div *appLazyScrollerElement="'active-link'; orientation: 'vertical'">
        <app-calendar-row-header
          [day]="dateKey"
          [dateKey]="dateKey"
          [readonly]="true"
          [assignments]="assignmentsByDateKey[dateKey]"
          [templatesById]="nodeTemplatesById"
          [hideInfo]="publicView"
          [type]="'sharedLinks'"
        ></app-calendar-row-header>

        <div class="calendar-row__body" [attr.data-date-key]="dateKey" cdkDropListGroup>
          <div
            class="calendar-row__project"
            *ngFor="
              let assignment of assignmentsByDateKey[dateKey];
              let i = index;
              trackBy: trackBy
            "
            cdkDropList
            cdkDropListOrientation="vertical"
            cdkDropListSortingDisabled
            [cdkDropListData]="{ item: assignment, index: i }"
          >
            <div
              cdkDrag
              [cdkDragData]="assignment"
              [cdkDragStartDelay]="50"
              [cdkDragDisabled]="true"
            >
              <!-- <app-assignment
                *ngIf="assignment?.reference?.nodeTemplateId"
                [assignment]="assignment"
                [readonly]="true"
              >
              </app-assignment> -->
              <ng-container *ngIf="assignment?.reference?.nodeTemplateId">
                <div
                  class="assignment"
                  [id]="'assignment' + assignment?.id"
                  cdkDropList
                  #assignmentOrigin
                >
                  <!-- [progress]="assignment?.progress" -->
                  <app-assignment-header
                    [assignment]="assignment"
                    [progress]="null"
                    [progressViewMode]="'hide'"
                    [viewType]="viewType"
                    [isExpanded]="isAssignmentExpanded(assignment?.id)"
                    [showHeaderDetails]="false"
                    [isSelected]="false"
                    [isSelectable]="false"
                    [showUncheckedBtn]="false"
                    [readonly]="true"
                    [widgetsNodeTypes]="[]"
                    [tagsNodeTypes]="[]"
                    [hideLock]="publicView"
                    (changeExpanded)="toggleExpanded(assignment?.id)"
                    [menuRef]="!publicView ? assignmentMenuTemplate : null"
                  ></app-assignment-header>

                  <ng-template #assignmentMenuTemplate>
                    <app-assignment-menu
                      [assignment]="assignment"
                      [linksWidgets]="
                        assignment?.reference.widgets
                          | widgetTypeFilter: [WidgetType.url, WidgetType.urlList]
                          | widgetSort: [WidgetType.urlList, WidgetType.url]
                      "
                    ></app-assignment-menu>
                  </ng-template>

                  <app-assignment-body [locked]="true">
                    <ng-container *ngIf="assignment?.nodeTemplate?.allowPrimaryTags">
                      <app-assignment-section
                        [title]="group.title"
                        *ngFor="let group of assignment?.primaryTagGroups"
                      >
                        <div>
                          <app-assignment-primary-tag
                            [group]="group"
                            [isExpanded]="isAssignmentExpanded(assignment?.id)"
                            [readonly]="true"
                            class="assignment__primary-col"
                          ></app-assignment-primary-tag>
                        </div>
                      </app-assignment-section>
                    </ng-container>

                    <div
                      class="assigment__elements-container"
                      [ngClass]="{
                        'assigment__elements-container--one-column':
                          cardLayout === CardLayoutType.oneColumn
                      }"
                    >
                      <!-- assets -->
                      <div
                        *ngIf="
                          viewType >= AssignmentViewType.medium ||
                          isAssignmentExpanded(assignment?.id)
                        "
                        class="assignment__elements-col"
                      >
                        <app-assignment-asset-fields
                          [nodeTemplates]="assignment?.assetGroups"
                          [readonly]="true"
                          [showProfilePhoto]="true"
                          [displayAsList]="true"
                        >
                        </app-assignment-asset-fields>
                      </div>
                      <!-- elements -->
                      <div
                        *ngIf="
                          false &&
                          (viewType >= AssignmentViewType.relaxed ||
                            isAssignmentExpanded(assignment?.id))
                        "
                        class="assignment__elements-col"
                      >
                        <app-assignment-element-fields
                          [nodeTemplates]="assignment?.elementGroups"
                          [readonly]="true"
                          [fieldNodeType]="CustomViewNodeType.element"
                          [assignmentViewType]="viewType"
                          [widgetsNodeTypes]="[]"
                          [tagsNodeTypes]="[]"
                          [cardLayout]="cardLayout"
                        ></app-assignment-element-fields>
                      </div>

                      <!-- additional fields -->
                      <div
                        *ngIf="
                          viewType >= AssignmentViewType.relaxed ||
                          isAssignmentExpanded(assignment?.id)
                        "
                        class="assignment__elements-col"
                      >
                        <app-assignment-additional-fields
                          [additionalFields]="assignment?.checklistGroups"
                          [readonly]="true"
                          [cardLayout]="cardLayout"
                        ></app-assignment-additional-fields>
                      </div>
                    </div>

                    <ng-container
                      *ngIf="
                        viewType >= AssignmentViewType.relaxed ||
                        isAssignmentExpanded(assignment?.id)
                      "
                    >
                      <div class="assignment__announcement" *ngIf="assignment?.notifications">
                        <app-assignment-row-announcement
                          [project]="assignment"
                        ></app-assignment-row-announcement>
                      </div>
                      <!-- <div class="assignment__notes"> -->
                      <!-- notes -> hide -> JeRvxThB -->
                      <!-- <app-assignment-row-notes
          [assignment]="assignment"
          [noteType]="NoteType.Calendar"
          [readonly]="readonly"
          (edit)="onRequestEditNoteField($event)"
        ></app-assignment-row-notes> -->
                      <!-- </div> -->
                    </ng-container>
                  </app-assignment-body>

                  <!-- [isChatChannelActive]="assignment?.isChatChannelActive" -->
                  <app-assignment-footer
                    [isExpanded]="isAssignmentExpanded(assignment?.id)"
                    [assignment]="assignment"
                    [hasStamp]="assignment.stampTags?.length > 0"
                    [isChatChannelActive]="false"
                    [readonly]="true"
                    [hideOverlayIcons]="publicView"
                  ></app-assignment-footer>

                  <!-- [isExpanded]="isAssignmentExpanded(assignment?.id)"
                  [assignment]="assignment"
                  [hasStamp]="assignment.stampTags?.length > 0"
                  [isChatChannelActive]="false"
                  [readonly]="readonly"
                  (openChatEvent)="openChat()"
                  (selectStampEvent)="selectStampTag()" -->

                  <div class="assignment__overlay-element-add">
                    <span class="assignment__plus-icon fal fa-plus-circle"></span>
                  </div>

                  <div class="assignment__lock-overlay">
                    <span class="assignment__lock-overlay-btn-area"></span>
                  </div>
                </div>
              </ng-container>

              <app-assignment-overlay-card
                *ngIf="!assignment?.reference?.nodeTemplateId"
                [assignment]="assignment"
                [assignmentHeaderDetailsViewMode]="'hide'"
                [withoutScrollbars]="false"
                [hideFirstCard]="true"
                [readonly]="true"
                [type]="'sharedLinks'"
              >
              </app-assignment-overlay-card>
              <app-content-loader [scope]="assignment?.id?.toString()"></app-content-loader>
            </div>
          </div>
        </div>
        <div class="calendar-row__overlay">
          <span class="calendar-row__plus-icon fal fa-plus-circle"></span>
        </div>
      </div>

      <!-- dateKey 'null' is cast to string? Need to trace where it happens -->
      <app-content-loader
        [scope]="!dateKey || dateKey == 'null' ? 'awaiting' : dateKey"
      ></app-content-loader>
    </div>
  </div>

  <app-content-loader scope="shared-link"></app-content-loader>
</div>
