import { colorTypeKeys } from '../../core/constants/color-type';
import { ModificationBasicInterface } from '../../core/models/mofification-basic.interface';
import { ModificationInterface } from '../../core/models/modification.interface';
import { WorkspaceUsersById } from './workspace-users.state';

// temp method
export const getRandomAvatarColor = () => {
  const maxColorTypeValue = colorTypeKeys.length - 1;
  return Math.ceil(Math.random() * maxColorTypeValue);
};

export const populateModificationInfo = (
  basic: ModificationBasicInterface,
  usersById: WorkspaceUsersById,
): ModificationInterface => {
  if (!basic) {
    return undefined;
  }
  return {
    ...basic,
    editedBy: {
      ...basic.editedBy,
      ...(usersById[basic.editedBy?.id] || {}),
    },
    createdBy: {
      ...basic.createdBy,
      ...(usersById[basic.createdBy?.id] || {}),
    },
  } as ModificationInterface;
};
