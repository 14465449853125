import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-sticky-panel-header',
  templateUrl: './sticky-panel-header.component.html',
  styleUrls: ['./sticky-panel-header.component.scss'],
})
export class StickyPanelHeaderComponent implements OnInit {
  @Input() title: string;
  @Input() isLoading: boolean;
  @Output() closeEvent = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  close() {
    this.closeEvent.emit();
  }
}
