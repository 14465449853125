import { Component, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { TemplateWidgetsActions } from '../../../../store/template-widgets';
import { WorkspaceGroupsActions } from '../../../../store/workspace-groups';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GroupEditComponent } from '../../../../shared/modals/group-edit/group-edit.component';
import { WorkspaceGroupModel } from '../../../../core/models/workspace-group.model';
import { WidgetsActions } from '../../../../store/widgets';
import { NodeTemplateWidgetModel } from '../../../../core/models/node-template-widget.model';
import { TemplateChecklistAddEditComponent } from '../template-checklist-add-edit/template-checklist-add-edit.component';
import { WidgetType } from '../../../../core/constants/widget-type';
import { ConfirmPopupComponent } from '../../../../shared/modals/confirm-popup/confirm-popup.component';
import { hasChoicesSupport } from '../../../../core/utils/widget-choices.util';

export interface ChecklistAddEditResult {
  title: string;
  widgetType: WidgetType;
  choices: string[];
  isDelete?: boolean;
}

@Component({
  selector: 'app-template-checklist',
  templateUrl: './template-checklist.component.html',
  styleUrls: ['./template-checklist.component.scss'],
})
export class TemplateChecklistComponent implements OnDestroy {
  private readonly subscription: Subscription;

  @Input()
  group: WorkspaceGroupModel;

  @Input()
  groups: WorkspaceGroupModel[];

  @Input()
  templateId: number;

  get groupIds() {
    return this.groups.map(group => group.id);
  }

  constructor(private store: Store, private modalService: NgbModal) {
    this.groups = [];
    this.subscription = new Subscription();
  }

  editAssignmentChecklist() {
    const modelRef = this.modalService.open(GroupEditComponent, {
      size: 'sm',
      backdrop: 'static',
    });
    modelRef.componentInstance.group = this.group;
  }

  showQuestionWidgetCreatePage() {
    const modelRef = this.modalService.open(TemplateChecklistAddEditComponent, {
      size: 'sm',
      backdrop: 'static',
    });
    modelRef.componentInstance.title = 'Add a data field';
    modelRef.componentInstance.valueWidgetType = WidgetType.number; // default option

    modelRef.result
      .then((result: ChecklistAddEditResult) => {
        this.store.dispatch(
          TemplateWidgetsActions.createAndAddAssignmentChecklistWidgetRequest({
            title: result?.title,
            widgetType: result?.widgetType,
            groupId: this.group.id,
            choices:
              hasChoicesSupport(result?.widgetType) && result?.choices?.length
                ? result.choices
                : [],
          }),
        );
      })
      .catch(res => {});
  }

  openChangeQuestionWidgetPopover(templateWidget: NodeTemplateWidgetModel) {
    const modelRef = this.modalService.open(TemplateChecklistAddEditComponent, {
      size: 'sm',
      backdrop: 'static',
    });
    modelRef.componentInstance.title = 'Edit question';
    modelRef.componentInstance.valueTitle = templateWidget.widget?.title;
    modelRef.componentInstance.valueWidgetType = templateWidget.widget?.widgetType;
    modelRef.componentInstance.valueWidgetChoices = templateWidget.widget?.choices;

    modelRef.result
      .then((result: ChecklistAddEditResult) => {
        if (result?.isDelete) {
          // delete the widget
          this.onDeleteWidget(templateWidget.id);
        } else {
          // update the widget
          this.store.dispatch(
            WidgetsActions.updateWidgetRequest({
              widgetId: templateWidget.id,
              widgetProps: {
                title: result?.title,
                widgetType: result?.widgetType,
                choices:
                  hasChoicesSupport(result?.widgetType) && result?.choices?.length
                    ? result.choices
                    : [],
              },
            }),
          );
        }
      })
      .catch(res => {});
  }

  onDeleteWidget(widgetId: number) {
    const modelRef = this.modalService.open(ConfirmPopupComponent, {
      size: 'md',
      backdrop: 'static',
    });
    modelRef.componentInstance.title = 'Delete Widget';
    modelRef.componentInstance.message = 'Do you want to Delete this Widget?';
    modelRef.componentInstance.warningMessage =
      'Warning: The widget and its related data will be deleted permanently.';

    modelRef.result
      .then((result?: boolean) => {
        if (result) {
          this.store.dispatch(
            TemplateWidgetsActions.removeTemplateGroupWidgetAndWidgetItselfRequest({
              groupId: this.group.id,
              widgetId,
            }),
          );
        }
      })
      .catch(res => {});
  }

  sortQuestionWidgets(event) {
    const widgetJoinIds = this.group.widgets.map(widget => widget.joinId);
    moveItemInArray(widgetJoinIds, event.previousIndex, event.currentIndex);
    this.store.dispatch(
      TemplateWidgetsActions.sortTemplateWidgetsRequest({
        templateId: this.group.nodeTemplateId,
        joinIds: widgetJoinIds,
      }),
    );
  }

  trackBy(index, item) {
    return item?.id;
  }

  moveGroupUp(groupId) {
    const groupIds = this.groupIds;
    const index = groupIds.indexOf(groupId);
    moveItemInArray(groupIds, index, index - 1);
    this.store.dispatch(
      WorkspaceGroupsActions.sortWorkspaceGroupsRequest({
        groupIds,
      }),
    );
  }

  moveGroupDown(groupId) {
    const groupIds = this.groupIds;
    const index = groupIds.indexOf(groupId);
    moveItemInArray(groupIds, index, index + 1);
    this.store.dispatch(
      WorkspaceGroupsActions.sortWorkspaceGroupsRequest({
        groupIds,
      }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
