import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import {
  catchError,
  concatMap,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { ErrorsActions } from '../errors';
import { ChatsActions } from './index';
import { ChatService } from '../../core/services/chat.service';
import { selectWorkspacesCurrentSlug } from '../workspaces/workspaces.selectors';
import { ChatbarActions, ChatbarSelectors } from '../chatbar';
import { openCurrentWorkspaceChannelSuccess } from '../chatbar/chatbar.actions';
import { convertServerChatChannelDataToChannelModel } from './chats.util';
import { ChatChannelModel } from '../../core/models/chat-channel.model';
import { ChatChannelCommentModel } from '../../core/models/chat-channel-comment.model';

@Injectable()
export class ChatsEffects {
  constructor(private actions$: Actions, private store: Store, private chatsService: ChatService) {}

  loadWorkspaceAllChannelsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatbarActions.goToWorkspaceAllChannelsPageRequest),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      mergeMap(([action, slug]) =>
        this.chatsService.loadWorkspaceAllChannels(slug).pipe(
          switchMap(dataItems => {
            const channels: ChatChannelModel[] = (dataItems || []).map(dataItem => {
              return convertServerChatChannelDataToChannelModel(dataItem);
            });
            return of(ChatbarActions.goToWorkspaceAllChannelsPageSuccess({ channels }));
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  loadUnreadCommentsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatsActions.loadUnreadCommentsRequest),
      concatMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(selectWorkspacesCurrentSlug)),
            this.store.pipe(select(ChatbarSelectors.selectChatbarCurrentChannel)),
          ),
        ),
      ),
      mergeMap(([action, slug, channel]) =>
        this.chatsService.loadUnreadComments(slug).pipe(
          concatMap((comments: ChatChannelCommentModel[]) => {
            if (channel && channel.unreadComments > 0) {
              return [
                ChatsActions.loadUnreadCommentsSuccess({ comments }),
                ChatsActions.markChannelAsReadRequest({ channelId: channel.id }),
              ];
            }
            return of(ChatsActions.loadUnreadCommentsSuccess({ comments }));
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  // We probably don't need to update the server
  //
  // markChannelAsReadRequest$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(ChatsActions.markChannelAsReadRequest),
  //     mergeMap(action =>
  //       this.chatsService.markChannelAsRead(action.channelId).pipe(
  //         switchMap((comments: ChatChannelCommentModel[]) => {
  //           return EMPTY;
  //         }),
  //         catchError(error => {
  //           return of(ErrorsActions.goToErrorPage({ error }));
  //         }),
  //       ),
  //     ),
  //   ),
  // );

  openCurrentWorkspaceChannelRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatbarActions.openCurrentWorkspaceChannelRequest),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      tap(action => {
        if (action[0].hideChatModal) {
          return;
        }
        // call a method to open the chat modal
        this.chatsService.openChatModal();
      }),
      mergeMap(([action, slug]) =>
        this.chatsService.openWorkspaceChatChannel(slug).pipe(
          switchMap(dataItem => {
            const channel: ChatChannelModel = convertServerChatChannelDataToChannelModel(dataItem);
            return of(
              openCurrentWorkspaceChannelSuccess({ channel }),
              ChatsActions.loadChannelCommentsRequest({ channelId: channel.id }),
            );
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  openNodeChannelRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatbarActions.openNodeChannelRequest),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      tap(action => {
        if (action[0].hideChatModal) {
          return;
        }
        // call a method to open the chat modal
        this.chatsService.openChatModal();
      }),
      mergeMap(([action, slug]) =>
        this.chatsService.openNodeChatChannel(slug, action.nodeId).pipe(
          switchMap(dataItem => {
            const channel: ChatChannelModel = convertServerChatChannelDataToChannelModel(dataItem);
            return of(
              ChatbarActions.openNodeChannelSuccess({ channel }),
              ChatsActions.loadChannelCommentsRequest({ channelId: channel.id }),
            );
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  deleteNodeChannelRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatsActions.deleteNodeChannelRequest),
      mergeMap(action =>
        this.chatsService.deleteChatChannel(action.channelId).pipe(
          map(() => {
            return ChatsActions.deleteNodeChannelSuccess({
              channelId: action?.channelId,
            });
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  loadChannelCommentsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatsActions.loadChannelCommentsRequest),
      mergeMap(action =>
        this.chatsService.searchChannelComments(action.channelId).pipe(
          map(comments => {
            return ChatsActions.loadChannelCommentsSuccess({ comments });
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  addCommentToChannelRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatsActions.addCommentToChannelRequest),
      mergeMap(action =>
        this.chatsService.addCommentToChannel(action.channelId, action.comment).pipe(
          map(comment => {
            return ChatsActions.addCommentToChannelSuccess({ comment });
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  updateCommentInChannelRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatsActions.updateCommentInChannelRequest),
      mergeMap(action =>
        this.chatsService.updateComment(action.channelId, action.commentId, action.comment).pipe(
          map(comment => {
            return ChatsActions.updateCommentInChannelSuccess({ comment });
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );
  deleteCommentInChannelRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatsActions.deleteCommentInChannelRequest),
      mergeMap(action =>
        this.chatsService.deleteCommentInChannel(action.channelId, action.commentId).pipe(
          map(() => {
            return ChatsActions.deleteCommentInChannelSuccess({
              channelId: action.channelId,
              commentId: action.commentId,
            });
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  addAttachmentToChannelRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatsActions.addAttachmentToChannelRequest),
      mergeMap(action =>
        this.chatsService
          .commentUploadToChannel(action.channelId, action.file, action.comment)
          .pipe(
            map(comment => {
              return ChatsActions.addAttachmentToChannelSuccess({ comment });
            }),
            catchError(error => {
              return of(ErrorsActions.goToErrorPage({ error }));
            }),
          ),
      ),
    ),
  );

  updateAttachmentToChannelRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatsActions.updateAttachmentToChannelRequest),
      mergeMap(action =>
        this.chatsService
          .updateCommentUploadToChannel(
            action.channelId,
            action.commentId,
            action.file,
            action.comment,
          )
          .pipe(
            map(comment => {
              return ChatsActions.updateAttachmentToChannelSuccess({ comment });
            }),
            catchError(error => {
              return of(ErrorsActions.goToErrorPage({ error }));
            }),
          ),
      ),
    ),
  );

  clickNodeChatIcon$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ChatbarActions.clickNodeChatIcon),
      concatMap(action => of(ChatbarActions.openNodeChannelRequest({ nodeId: action.nodeId }))),
    );
  });
}
