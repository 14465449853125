export enum PermissionType {
  // previous permission types
  manageUsers = 'manageUsers',
  editWorkspace = 'editWorkspace',
  workspaceOwner = 'workspaceOwner',

  // Admin permission types
  workspaceEditor = 'p_workspace_editor',
  templateEditor = 'p_template_editor',
  tagEditor = 'p_tag_editor',
  urlsEditor = 'p_urls_editor',
  archivedEditor = 'p_archived_editor',
  userEditor = 'p_user_editor',
  integrationEditor = 'p_integration_editor',

  // Manager permission types
  projectEditor = 'p_project_editor',
  assetEditor = 'p_asset_editor',
  elementEditor = 'p_element_editor',
  workspaceShare = 'p_workspace_share',

  // Allocator permission types
  assignmentEditor = 'p_assignment_editor',
  assignmentElementEditor = 'p_assignment_element_editor',
  stampsEditor = 'p_stamps_editor',
  figuresEditor = 'p_figures_editor',
  checklistsEditor = 'p_checklists_editor',
  diaryEditor = 'p_diary_editor',
  timesheetsEditor = 'p_timesheets_editor',

  // Member permission types
  workspaceView = 'p_workspace_view',
  urlsView = 'p_urls_view',
  projectView = 'p_project_view',
  assetView = 'p_asset_view',
  elementView = 'p_element_view',
  assignmentView = 'p_assignment_view',
  stampsView = 'p_stamps_view',
  figuresView = 'p_figures_view',
  checklistsView = 'p_checklists_view',
  diaryView = 'p_diary_view',
  timesheetsView = 'p_timesheets_view',

  // layout templates
  deleteLayoutTemplate = 'p_delete_layout_template',
}
