import { Component, Input } from '@angular/core';
import { NodeType } from '../../../../core/constants/node-type';
import { NodeModel } from '../../../../core/models/node.model';

@Component({
  selector: 'app-folder-list',
  templateUrl: './folder-list.component.html',
  styleUrls: ['./folder-list.component.scss'],
})
export class FolderListComponent {
  public readonly NodeType = NodeType;

  @Input()
  public nodes: NodeModel[];

  @Input()
  public readonly: boolean;

  constructor() {}

  trackBy(index, folder: NodeModel) {
    return folder.id;
  }
}
