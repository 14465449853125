<div [id]="'print-modal-' + pdfTableId" class="container">
  <div class="apm-block">
    <ng-container *ngIf="!showOnlyDynamicContent">
      <!-- Header Row -->
      <div class="header">
        <img src="assets/img/new-logo/Zedulr-LOGO-2022-SML.png" alt="Logo" class="logo" />
      </div>

      <!-- Title Row -->
      <div class="title">
        <h1>{{ title }}</h1>
      </div>

      <!-- Assignment Details -->
      <div class="assignment-details">
        <div class="row m-0"><span>Layout:</span> {{ header }}</div>
        <div class="row m-0" *ngIf="dateRange"><span>Date Range:</span> {{ dateRange }}</div>
        <!-- <div class="row m-0"><span>Activity Name:</span> {{ item?.nodeTemplate?.title }}</div> -->
      </div>
    </ng-container>

    <!-- dynamic content -->
    <div class="form-data">
      <ng-content></ng-content>
    </div>

    <!-- Footer -->
    <div class="footer">
      <hr />
      <div class="footer-content">
        <div class="site-name">www.zedulr.com</div>
        <!-- <div class="page-number">Page {{ index + 1 }} of {{ assignments?.length }}</div> -->
      </div>
    </div>
  </div>
</div>
