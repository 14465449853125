import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-btn-remove',
  templateUrl: './btn-remove.component.html',
  styleUrls: ['./btn-remove.component.scss']
})
export class BtnRemoveComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
