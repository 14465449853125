import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ChatChannelCommentViewModel } from '../../../../../core/models/chat-channel-comment-view.model';
import { ChatActions } from '../../../../../core/constants/chatbar-page';
import { selectCurrentUserId } from '../../../../../store/auth/auth.selectors';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-chat-comment',
  templateUrl: './chat-comment.component.html',
  styleUrls: ['./chat-comment.component.scss'],
})
export class ChatCommentComponent implements OnInit, OnDestroy {
  @Output() actionFired: EventEmitter<ChatActions> = new EventEmitter<ChatActions>();
  @Input() selectedCommentForEdit: ChatChannelCommentViewModel;
  @Input() comment: ChatChannelCommentViewModel;
  @Input() fromRight: boolean;
  currentUserId: number;
  private readonly subscription = new Subscription();

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.subscription.add(
      this.store.pipe(select(selectCurrentUserId)).subscribe(currentUserId => {
        this.currentUserId = currentUserId;
      }),
    );
  }

  editMessage(): void {
    this.actionFired.emit(ChatActions.edit);
  }

  deleteMessage(): void {
    this.actionFired.emit(ChatActions.delete);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
