import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { CalendarActions } from './index';
import { selectCalendarSelectedDates } from './calendar.selectors';

@Injectable()
export class CalendarEffects {
  constructor(private actions$: Actions, private store: Store) {}

  selectDateRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CalendarActions.toggleDateRequest),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectCalendarSelectedDates)))),
      ),
      switchMap(([action, dates]) => {
        const index = dates.indexOf(action.date);

        if (index > -1) {
          return of(CalendarActions.unselectDate({ date: action.date }));
        } else {
          return of(CalendarActions.selectDate({ date: action.date }));
        }
      }),
    ),
  );
}
