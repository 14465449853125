import { Pipe, PipeTransform } from '@angular/core';
import { NodeTemplateModel } from '../../core/models/node-template.model';

@Pipe({
  name: 'defaultTemplateId',
})
export class DefaultTemplateIdPipe implements PipeTransform {
  transform(templates: NodeTemplateModel[]): number {
    const defaultTemplates = (templates || []).filter(template => template.isDefault);
    return defaultTemplates?.length > 0 ? defaultTemplates[0].id : undefined;
  }
}
