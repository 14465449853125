import { Action, createReducer, on } from '@ngrx/store';
import { initialPropertiesPanelState, PropertiesPanelState } from './properties-panel.state';
import { PropertiesPanelActions } from './index';

const reducer = createReducer<PropertiesPanelState>(
  initialPropertiesPanelState,

  on(PropertiesPanelActions.reset, PropertiesPanelActions.close, state => ({
    ...initialPropertiesPanelState,
  })),

  on(PropertiesPanelActions.openPropertiesPanel, (state: PropertiesPanelState, { nodeId }) => {
    return {
      ...initialPropertiesPanelState,
      isOpen: true,
      nodeId,
    };
  }),

  on(PropertiesPanelActions.togglePropertiesPanel, (state: PropertiesPanelState, { nodeId }) => {
    return state.isOpen && state.nodeId === nodeId
      ? {
          ...initialPropertiesPanelState,
        }
      : {
          ...initialPropertiesPanelState,
          isOpen: true,
          nodeId,
        };
  }),
);

// Note: The exported reducer function is necessary as function calls are not supported by the AOT compiler.
export function propertiesPanelReducer(state: PropertiesPanelState | undefined, action: Action) {
  return reducer(state, action);
}
