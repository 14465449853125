import { createSelector } from '@ngrx/store';
import { selectTemplateTagsByGroupId } from './template-tags.selectors';
import { selectTagsById } from '../tags/tags.selectors';
import {
  selectWorkspaceGroupsById,
  selectWorkspacePrimaryTagGroups,
} from '../workspace-groups/workspace-groups.selectors';

export const selectAllPrimaryTagsWithGroupLabel = createSelector(
  selectWorkspacePrimaryTagGroups,
  selectTemplateTagsByGroupId,
  selectTagsById,
  selectWorkspaceGroupsById,
  (groups, templateTagsByGroupId, tagsById, groupsById) => {
    let tags = [];
    (groups || []).forEach(group => {
      const groupTags = templateTagsByGroupId[group.id] || [];
      groupTags.forEach(templateTag => {
        if (templateTag) {
          tags = [
            ...tags,
            {
              ...tagsById[templateTag.id],
              groupLabel: group.title,
            },
          ];
        }
      });
    });
    return tags;
  },
);
