import { AppState } from '../index';
import { createSelector } from '@ngrx/store';
import { selectTagsById } from '../tags/tags.selectors';
import { selectWorkspaceGroupsById } from '../workspace-groups/workspace-groups.selectors';
import { WorkspaceGroupType } from '../../core/constants/workspace-group-type';
import {
  selectTemplateTagsById,
  selectTemplateTagsByTemplateId,
} from '../template-tags/template-tags.selectors';
import { NodesSelectors } from '../nodes';

const selectNodeTagsState = (state: AppState) => state.nodeTags;

export const selectNodeTagsById = createSelector(selectNodeTagsState, state => state.nodeTagsById);

export const selectNodeTagsByNodeId = createSelector(
  selectNodeTagsState,
  state => state.nodeTagsByNodeId,
);

export const selectIntersectingTemplateTagsByNodeIds = createSelector(
  selectTemplateTagsByTemplateId,
  selectTemplateTagsById,
  selectTagsById,
  selectWorkspaceGroupsById,
  NodesSelectors.selectNodesById,
  NodesSelectors.selectAssignmentsById,
  (
    templateTagsByTemplateId,
    templateTagsById,
    tagsById,
    groupsById,
    nodesById,
    assignmentsById,
    props: { nodeIds: number[]; groupType: WorkspaceGroupType },
  ) => {
    const templateIds = (props.nodeIds || []).map(id => {
      let node = nodesById[id];
      if (assignmentsById[id] != null) {
        node = nodesById[assignmentsById[id].referenceNodeId];
      }
      return node && node.nodeTemplateId;
    });

    let templateTagsByGroup = [];
    templateIds.forEach(id => {
      const byGroup =
        templateTagsByTemplateId[id] &&
        templateTagsByTemplateId[id]

          // Filter by GroupType or no group
          // We *must* do this first to ensure we have the correct TemplateTags
          .filter(templateTag => {
            // Tags without a Group
            const noGroupTest = props.groupType == null && templateTag.groupId == null;
            if (noGroupTest) {
              return true;
            }

            // Tags within a group Type
            const hasGroupType =
              props.groupType != null &&
              groupsById[templateTag.groupId] &&
              groupsById[templateTag.groupId].type == props.groupType;
            if (hasGroupType) {
              return true;
            }

            return false;
          })
          .map(templateTag => templateTag.id);

      templateTagsByGroup.push(byGroup);
    });

    return (
      templateTagsByGroup

        // Get template tags intersection
        .reduce((a, b) => (a || []).filter(c => (b || []).includes(c)))

        // Remove Duplicates
        .filter((id, idx, self) => self.indexOf(id) == idx)

        // Map to Tag
        .map(id => tagsById[id])
    );
  },
);
