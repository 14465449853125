import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FileUpload, FileUploaderService } from '../file-uploader.service';

@Component({
  selector: 'app-file-uploader-thumb',
  templateUrl: 'file-uploader-thumb.component.html',
  styleUrls: ['../file-uploader.component.scss', 'file-uploader-thumb.component.scss'],
})
export class FileUploaderThumbComponent {
  @Input() public file: FileUpload;
  @Input() public removable: boolean;
  @Input() public sortable: boolean;
  @Output() public fileClick: EventEmitter<FileUpload>;
  @Output() public fileRemove: EventEmitter<FileUpload>;

  // public faArrowsAlt = faArrowsAlt;

  constructor(protected fileUploader: FileUploaderService) {
    this.file = null;
    this.fileClick = new EventEmitter<FileUpload>();
    this.fileRemove = new EventEmitter<FileUpload>();
  }

  public onClick(event, file: FileUpload) {
    event.preventDefault();
    this.fileClick.emit(file);
  }

  public onRemove(event, file: FileUpload) {
    event.preventDefault();
    this.fileRemove.emit(file);
  }

  public isImage(file: FileUpload) {
    return this.fileUploader.isImage(file);
  }

  public isDocument(file: FileUpload) {
    return this.fileUploader.isDocument(file);
  }

  public isVideo(file: FileUpload) {
    return this.fileUploader.isVideo(file);
  }

  public isAudio(file: FileUpload) {
    return this.fileUploader.isAudio(file);
  }

  public isUnknown(file: FileUpload) {
    return this.fileUploader.isUnknown(file);
  }
}
