import { Component, Input, OnInit } from '@angular/core';
import { ProgressModel } from '../../../core/models/progress.model';

@Component({
  selector: 'app-assignment-progress',
  templateUrl: './assignment-progress.component.html',
  styleUrls: ['./assignment-progress.component.scss'],
})
export class AssignmentProgressComponent implements OnInit {
  @Input() progress: ProgressModel;
  @Input() twoRows: boolean;

  constructor() {}

  ngOnInit(): void {}
}
