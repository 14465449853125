import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NodeTemplateModel } from '../../../core/models/node-template.model';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { TemplatesActions } from '../../../store/templates';
import { ColorType } from '../../../core/constants/color-type';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NodeTemplatePickerComponent } from '../../../shared/modals/node-template-picker/node-template-picker.component';
import { NodeType } from '../../../core/constants/node-type';

@Component({
  selector: 'app-allowed-templates',
  templateUrl: './allowed-templates.component.html',
  styleUrls: ['./allowed-templates.component.scss'],
})
export class AllowedTemplatesComponent implements OnInit, OnDestroy {
  private readonly subscription = new Subscription();

  public readonly ColorType = ColorType;

  @Input() type: NodeType.asset | NodeType.element = NodeType.asset; // default value
  @Input() selectedTemplate: NodeTemplateModel;
  @Input() selectedTemplates: NodeTemplateModel[];
  @Input() selectedAssetTemplates: NodeTemplateModel[];
  @Input() selectedFormTemplates: NodeTemplateModel[];
  public nodeTypes = NodeType;

  constructor(private store: Store, private modalService: NgbModal) {}

  ngOnInit(): void {}

  openTemplatesPopover() {
    const modelRef = this.modalService.open(NodeTemplatePickerComponent, {
      size: 'md',
      backdrop: 'static',
    });
    modelRef.componentInstance.types = [this.type];
    modelRef.componentInstance.selected =
      this.type === NodeType.asset ? this.selectedAssetTemplates : this.selectedFormTemplates;
    modelRef.result
      .then((result: NodeTemplateModel[]) => {
        let selectedIds: number[] =
          this.type === NodeType.asset
            ? this.selectedFormTemplates.map(t => t.id)
            : this.selectedAssetTemplates.map(t => t.id);
        selectedIds = selectedIds.concat(result.map(t => t.id));
        this.store.dispatch(
          TemplatesActions.updateNodeTemplateRequest({
            templateId: this.selectedTemplate.id,
            templateProps: {
              allowedTemplateIds: selectedIds,
            },
          }),
        );
      })
      .catch(res => {});
  }

  changeSwitch(): void {
    // let templateProps = {};
    // templateProps = {
    //   allowRates: !this.selectedTemplate?.allowRates,
    // };
    // this.store.dispatch(
    //   TemplatesActions.updateNodeTemplateRequest({
    //     templateId: this.selectedTemplate.id,
    //     templateProps,
    //   }),
    // );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
