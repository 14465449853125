<div
  #input
  class="z-table__input z-table__input--assignment-checkbox"
  [ngClass]="[
        editable == false ? 'z-table__input--disabled' : '',
        cell?.value === true ? 'z-table__input--complete' : 'z-table__input--incomplete',
      ]"
>
  <div class="custom-control custom-checkbox">
    <input
      id="editorAssignmentCheckbox_{{ elementId }}"
      class="custom-control-input"
      type="checkbox"
      [checked]="cell?.value ? true : false"
      (change)="onCheckboxChange()"
      [disabled]="!editable"
    />

    <label class="custom-control-label pt-1" for="editorAssignmentCheckbox_{{ elementId }}">
      <!-- {{cell?.value?.widget?.title}} -->
      <!-- {{ isChecked ? 'Complete' : 'Incomplete' }} -->
    </label>
  </div>
</div>
