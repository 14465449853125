import { OptionModel } from '../models/option.model';

export enum BehaviourType {
  multiple = 1,
  single = 2,
}

export enum BehaviourTypeLabels {
  multiple = 'Static Element (Multiple)',
  single = 'Dynamic Element (Single)',
}

export const behaviourTypeOptions: OptionModel[] = Object.keys(BehaviourType)
  .filter(key => parseInt(key, 10) >= 0)
  .map((key: unknown) => {
    return {
      id: key as number,
      label: +key === 1 ? BehaviourTypeLabels.multiple : BehaviourTypeLabels.single,
    };
  });
