import { Action, createReducer, on } from '@ngrx/store';
import { initialNodeWidgetsState, NodeWidgetsState } from './node-widgets.state';
import { NodeWidgetsActions } from './index';
import { NodeWidgetValueModel } from '../../core/models/node-widget-value.model';

const clearNodeWidgetsByNodeIdFromState = (
  state: NodeWidgetsState,
  nodeId: number,
): NodeWidgetsState => {
  let widgetsByNodeId = state.nodeWidgetsByNodeId[nodeId] || [];
  if (widgetsByNodeId.length === 0) {
    return state;
  }

  let nodeWidgetsById = { ...state.nodeWidgetsById };
  widgetsByNodeId.forEach(nodeWidget => {
    nodeWidgetsById = {
      ...nodeWidgetsById,
      [nodeWidget.id]: undefined,
    };
  });

  return {
    ...(state || {}),

    nodeWidgetsById: nodeWidgetsById,

    nodeWidgetsByNodeId: {
      ...state.nodeWidgetsByNodeId,
      [nodeId]: [],
    },
  };
};

const addNodeWidgetToState = (
  state: NodeWidgetsState,
  nodeWidget: NodeWidgetValueModel,
): NodeWidgetsState => {
  return {
    ...(state || {}),

    nodeWidgetsById: {
      ...state?.nodeWidgetsById,
      [nodeWidget?.id]: nodeWidget,
    },

    nodeWidgetsByNodeId: {
      ...state?.nodeWidgetsByNodeId,
      [nodeWidget?.parentNodeId]: [
        ...(state?.nodeWidgetsByNodeId[nodeWidget?.parentNodeId] || []).filter(
          widget => widget.id !== nodeWidget.id,
        ),
        nodeWidget,
      ],
    },
  };
};

const removeNodeWidgetFromState = (
  state: NodeWidgetsState,
  nodeWidget: NodeWidgetValueModel,
): NodeWidgetsState => {
  return {
    ...(state || {}),

    nodeWidgetsById: {
      ...state.nodeWidgetsById,
      [nodeWidget.id]: undefined,
    },

    nodeWidgetsByNodeId: {
      ...state.nodeWidgetsByNodeId,
      [nodeWidget.parentNodeId]: [
        ...(state.nodeWidgetsByNodeId[nodeWidget.parentNodeId] || []).filter(
          widget => widget.id !== nodeWidget.id,
        ),
      ],
    },
  };
};

const reducer = createReducer<NodeWidgetsState>(
  initialNodeWidgetsState,

  on(NodeWidgetsActions.loadNodeWidgetsFromNodeTree, (state, { nodeWidgets }) => {
    return nodeWidgets?.reduce((state, nodeWidget) => {
      return addNodeWidgetToState(state, nodeWidget);
    }, state);
  }),

  on(NodeWidgetsActions.updateNodeWidgetSuccess, (state, { nodeWidget }) => {
    return addNodeWidgetToState(state, nodeWidget);
  }),
);

export function nodeWidgetsReducer(state: NodeWidgetsState, action: Action) {
  return reducer(state, action);
}
