<table class="ap-table table table-hover">
  <thead class="thead-light">
    <tr>
      <th scope="col">#</th>
      <th scope="col">Label</th>
      <th scope="col ">Actions</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let choice of choices; index as index; trackBy: trackBy">
      <th scope="row">{{ index + 1 }}</th>
      <td class="data-choices__task-col-label">
        <app-inline-edit-textbox
          #inlineEdit
          class="data-choices__inline-edit"
          [value]="choice"
          [inputType]="WidgetType.textbox"
          [confirmBtnLabel]="'OK'"
          (changeValue)="updateChoice(index, $event)"
        ></app-inline-edit-textbox>
      </td>
      <td class="data-choices__task-col-actions">
        <app-btn-edit (click)="editChoice(inlineEdit)"></app-btn-edit>
        <app-btn-remove (click)="deleteChoice(index)"></app-btn-remove>
      </td>
    </tr>

    <tr *ngIf="choices?.length === 0">
      <td colspan="3" class="ap-no-content ">
        There are no Data Choices in the Widget yet.
      </td>
    </tr>
  </tbody>
</table>
<div>
  <button type="button" class="btn btn-primary btn-sm" (click)="addChoice()">
    Add Choice
  </button>
</div>
