export enum WorkspaceGroupType {
  checklist = 1,
  tag,
  stamp,
  primaryTag,
  workspaceLink,
  nodeRate,
  claimPeriod,
  calculationJoin = 101,
}
