import { Pipe, PipeTransform } from '@angular/core';
import { WorkspaceUrlModel } from '../../core/models/workspace-url.model';
import { LinkModel } from '../../core/models/link.model';

@Pipe({
  name: 'toLinkModel',
})
export class ToLinkModelPipe implements PipeTransform {
  transform(urlModel: WorkspaceUrlModel): LinkModel {
    if (urlModel) {
      return {
        value: urlModel.url,
        label: urlModel.title,
      };
    }
    return null;
  }
}
