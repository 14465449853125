<div class="assignment-menu-row">
  <span class="d-flex align-items-center">
    <span
      class="assignment-menu-row__icon fal"
      *ngIf="icon"
      [ngClass]="['fa-' + (icon | iconString)]"
    ></span>
    <!-- Add NgbTooltip to display the createdDate in a tooltip -->
    <ng-template #toolTipContent>
      Activity added <b>{{ createdDate | niceDate: 'day-only' }}</b> - {{ title }}
    </ng-template>
    <span class="assignment-menu-row__created-time" [ngbTooltip]="toolTipContent" container="body">
      {{ title | truncate: 25 }}
    </span>
    <!--    <span class="assignment-menu-row__created-time">-->
    <!--      Added {{ createdDate | niceDate: 'day-only' }}-->
    <!--    </span>-->
  </span>
  <ng-container *ngTemplateOutlet="toolbarTemplate"></ng-container>
</div>
