<ng-template #modalFooter>
  <div class="row m-0 w-100">
    <div class="col-6 p-0">
      <button type="button" class="btn btn-sm btn-link" (click)="clearSelection()">
        Clear selection
      </button>
    </div>

    <div class="col-6 p-0 d-flex justify-content-end">
      <button
        ngbAutofocus
        type="button"
        class="btn btn-sm btn-outline-light"
        aria-label="Close"
        (click)="activeModal.dismiss(false)"
      >
        Cancel
      </button>

      <button
        type="button"
        class="btn btn-sm btn-primary ml-2"
        aria-label="Close"
        (click)="onSubmit()"
      >
        <i class="fal fa-circle-check layout-mr-sm"></i>
        Confirm
      </button>
    </div>
  </div>
</ng-template>

<app-modal [title]="title" [footerTemplate]="modalFooter">
  <app-modal-instructions
    [html]="instructions"
    [template]="instructionsTemplate"
  ></app-modal-instructions>

  <div class="row mx-0 item-picked__search-bar">
    <div class="col-12">
      <app-search-box
        [searchKeyword]="keywords"
        sizeType="sm"
        [inputHeight]="'45px'"
        (searchChanged)="search($event)"
      ></app-search-box>
    </div>
  </div>

  <ol class="item-picker__list item-picker__list--flex-column list-unstyled">
    <li class="item-picker__list-item item-picker__list-item--flex-column" *ngIf="allowNew">
      <div
        class="list-item__brick list-item__btn"
        app-selectable-brick
        [icon]="IconType.plus"
        label="Create new resource"
        (click)="add()"
      ></div>
    </li>
    <li
      *ngFor="let item of list; trackBy: trackBy"
      class="item-picker__list-item item-picker__list-item--flex-column"
    >
      <ng-container [ngSwitch]="item.model.nodeType">
        <div
          *ngSwitchCase="NodeType.asset"
          class="list-item__brick list-item__brick--node-asset"
          [ngClass]="[
            item.model.colorTheme
              ? 'list-item__brick--' + (item.model.colorTheme | colorString)
              : ''
          ]"
          (click)="toggle(item)"
        >
          <div class="list-item__left">
            <app-node-avatar
              class="list-item__avatar"
              [profile]="item.model.profile"
              [profileIcon]="item.model?.nodeMeta?.profileIcon"
              [initials]="item.model.title | toInitials"
              [fullName]="item.model.title"
              [size]="'sm'"
              [colorTheme]="item.model.colorTheme"
            ></app-node-avatar>
          </div>

          <div class="list-item__center">
            <div class="list-item____title list-item____title--flex-column">
              <app-truncate-text [value]="item.model.title" [limit]="20"></app-truncate-text>
            </div>
          </div>

          <div class="list-item__right">
            <app-selectable-input
              [id]="item.model.id"
              [checked]="item.selected"
              [type]="maxSelection === 1 ? 'radio' : 'checkbox'"
            ></app-selectable-input>
          </div>
        </div>

        <div
          *ngSwitchDefault
          class="list-item__brick list-item__brick--flex-column list-item__brick--node-element"
          app-selectable-brick
          [color]="item.model.colorTheme"
          [icon]="item.model.icon"
          [label]="item.model.title"
          [isChecked]="item.selected"
          (click)="toggle(item)"
          [flexColumn]="true"
          [rightColTemplate]="checkBox"
        ></div>
        <ng-template #checkBox>
          <app-selectable-input
            [id]="item.model.id"
            [checked]="item.selected"
            [type]="maxSelection === 1 ? 'radio' : 'checkbox'"
          ></app-selectable-input>
        </ng-template>
      </ng-container>
    </li>
  </ol>
</app-modal>
