<div
  *ngIf="assignment && assignment.reference"
  class="assignment-header assignment-header--background-dark"
  [ngClass]="[
    assignment.reference.colorTheme
      ? 'assignment-header--' + (assignment.reference.colorTheme | colorString)
      : '',
    isSelected ? 'assignment-header--selected' : '',
    showUncheckedBtn ? 'assignment-header--show-unchecked-btn' : '',
    isSelectable ? 'assignment-header--is-selectable' : '',
    isClickable ? 'assignment-header--is-clickable' : ''
  ]"
>
  <ng-template #menuRowToolbar>
    <div class="assignment-header__icons">
      <app-btn-check
        class="assignment-header__check-icon"
        [isChecked]="isSelected"
        (toggleSelectionEvent)="toggleAssignmentSelection()"
      >
      </app-btn-check>

      <span
        *ngIf="!readonly"
        class="assignment-header__move-icon fal fa-arrows-alt layout-mr"
        cdkDragHandle
      ></span>

      <span
        *ngIf="!hideLock"
        [ngClass]="assignment.readOnly ? 'fa-lock-alt' : 'fa-unlock-alt'"
        class="assignment-header__locker-icon fal layout-mr"
        (click)="toggleLock()"
      ></span>

      <span
        *ngIf="menuRef"
        class="assignment-header__menu-icon fal fa-bars"
        [disablePopover]="readonly"
        [ngbPopover]="menuRef"
        container="body"
        [placement]="defaultPlacements"
      ></span>
    </div>
  </ng-template>

  <app-assignment-menu-row
    [createdDate]="assignment.createdDate"
    [icon]="assignment.nodeTemplate?.icon"
    [title]="assignment.nodeTemplate?.title"
    [toolbarTemplate]="menuRowToolbar"
  ></app-assignment-menu-row>

  <div class="assignment-header__title-row ">
    <h2
      class="assignment-header__title "
      [ngClass]="{ 'assignment-header__title--readonly': readonly }"
      (click)="clickTitle()"
      [title]="assignment.id + ': ' + assignment.reference.title"
    >
      <app-truncate-text [value]="assignment.reference.title" [limit]="80"></app-truncate-text>
    </h2>
    <div
      *ngIf="!hideExpansion"
      class="assignment-header__expand-icon fal"
      [ngClass]="isExpanded ? 'fa-chevron-up' : 'fa-chevron-down'"
      (click)="toggleExpanded()"
    ></div>
  </div>

  <div
    class="assignment-header__row assignment-header__row--widgets"
    *ngIf="isExpanded || showHeaderDetails"
  >
    <ng-container
      *ngFor="
        let widget of assignment.reference.widgets
          | slice: 0:3
          | widgetTypeFilter: [WidgetType.url, WidgetType.urlList]:false;
        trackBy: trackWidgetsBy
      "
    >
      <div class="assignment-header__carousel-field">
        <app-assignment-header-field
          [widget]="widget"
          [invertColor]="true"
        ></app-assignment-header-field>
      </div>
    </ng-container>
  </div>

  <app-assignment-progress
    class="assignment-header__progress"
    *ngIf="
      (viewType === ViewType.custom ||
        isExpanded ||
        progressViewMode === 'show' ||
        showHeaderDetails) &&
      progressViewMode !== 'hide'
    "
    [progress]="progress"
  ></app-assignment-progress>
</div>
