<div
  class="assignment-header-field"
  [ngClass]="[invertColor ? 'assignment-header-field--inverted-color' : '']"
>
  <div class="assignment-header-field__name">{{ widget.widget.title }}</div>
  <div class="assignment-header-field__values" *ngIf="widget">
    <span class="assignment-header-field__value-item">
      <ng-container
        *ngIf="
          widget.widget.widgetType !== WidgetType.url &&
          widget.widget.widgetType !== WidgetType.phone
        "
      >
        <app-truncate-text
          [value]="widget | widgetValue"
          [limit]="40 - widget.widget.title?.length"
        ></app-truncate-text>
      </ng-container>
      <ng-container *ngIf="widget.widget.widgetType === WidgetType.url">
        <a class="assignment-header-field__link" href="{{ widget.value }}" target="_blank">{{
          widget | widgetValue | truncate: 40 - widget.widget.title?.length:false
        }}</a
        ><app-ellipse-tooltip
          [value]="widget | widgetValue"
          [limit]="40 - widget.widget.title?.length"
        ></app-ellipse-tooltip>
      </ng-container>
      <ng-container *ngIf="widget.widget.widgetType === WidgetType.phone">
        <a class="assignment-header-field__link" href="tel:{{ widget.value }}">{{
          widget | widgetValue | truncate: 40 - widget.widget.title?.length:false
        }}</a
        ><app-ellipse-tooltip
          [value]="widget | widgetValue"
          [limit]="40 - widget.widget.title?.length"
        ></app-ellipse-tooltip>
      </ng-container>
    </span>
  </div>
</div>
