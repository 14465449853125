import { NodeTemplateModel } from '../../core/models/node-template.model';
import { NodeType } from '../../core/constants/node-type';

export class TemplatesState {
  byId: TemplatesById;
  idsByServerNodeType: TemplateIdsByServerNodeType;
  searchKeyword: string;
}

export const initialTemplatesState: TemplatesState = {
  byId: {},
  idsByServerNodeType: {},
  searchKeyword: undefined,
};

export const initialTemplateTitle = 'untitled';

export class TemplatesById {
  [id: number]: NodeTemplateModel;
}

// Please note: only leaf type serverNodeType will be used for template
export type TemplateIdsByServerNodeType = {
  [key in NodeType]?: number[];
};
