import { Component, OnDestroy, OnInit } from '@angular/core';
import { Idle } from '@ng-idle/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-confirm-popup-session-timeout',
  templateUrl: './confirm-popup-session-timeout.component.html',
  styleUrls: ['./confirm-popup-session-timeout.component.scss'],
})
export class ConfirmPopupSessionTimeoutComponent implements OnInit, OnDestroy {
  countdown: number;

  subscription = new Subscription();

  constructor(private idle: Idle) {}

  ngOnInit(): void {
    this.subscription.add(
      this.idle.onTimeoutWarning.subscribe(countdown => {
        this.countdown = countdown;
      }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
