<ul class="popover-menu" *ngIf="assignment">
  <li class="popover-menu__info-title popover-menu__info-title--primary">
    {{ assignment.reference?.title }}
  </li>

  <!--  <li *ngIf="!onlyShowLinks" class="popover-menu__item" (click)="editProject()">-->
  <!--    Edit project-->
  <!--  </li>-->

  <!-- send to date | schedule | copy -->
  <ng-container *ngIf="assignment.date == awaitingAssignmentDateKey || assignment.date == null">
    <li class="popover-menu__item" (click)="changeDate()">
      <i class="fal fa-calendar layout-mr-lg popover_icon_style"></i>
      Move to date
    </li>

    <li class="popover-menu__item" (click)="copyMultiple()">
      <i class="fal fa-calendars layout-mr-lg popover_icon_style"></i>
      Copy to schedule
    </li>

    <li class="popover-menu__item" (click)="copy()">
      <i class="fal fa-copy layout-mr-lg popover_icon_style"></i>
      Duplicate
    </li>
  </ng-container>

  <!-- change date and send to plan date -->
  <ng-container *ngIf="assignment.date != awaitingAssignmentDateKey && assignment.date != null">
    <li class="popover-menu__item" (click)="changeDate()">
      <i class="fal fa-calendar-lines-pen layout-mr-lg popover_icon_style"></i>
      Change date
    </li>
    <li class="popover-menu__item" (click)="sendToAwaiting()">
      <i class="fal fa-paper-plane-top layout-mr-lg popover_icon_style"></i>
      Send to Plan Board
    </li>
    <li
      class="popover-menu__item"
      (click)="selectStampTag()"
      *ngIf="assignment?.nodeTemplate?.allowStampTags"
    >
      <i class="fal fa-stamp layout-mr-lg popover_icon_style"></i>
      Add status tag
    </li>
  </ng-container>
  <li class="popover-menu__divider"></li>

  <!-- add daily pricing -->
  <li class="popover-menu__info-title popover-menu__info-title--primary">
    Financials
  </li>
  <li
    *ngIf="
      !onlyShowLinks &&
      assignment?.reference?.rates?.length &&
      assignment?.nodeTemplate?.allowRevenue
    "
    class="popover-menu__item"
    (click)="showRateValues('Revenue')"
  >
    <i class="fal fa-money-bill-wave  layout-mr-lg popover_icon_style"></i>
    Add revenue record
  </li>

  <li
    *ngIf="
      !onlyShowLinks && assignment?.reference?.rates?.length && assignment?.nodeTemplate?.allowCosts
    "
    class="popover-menu__item"
    (click)="showRateValues('Cost')"
  >
    <i class="fal fa-coins layout-mr-lg popover_icon_style"></i>
    Add cost record
  </li>

  <!--  &lt;!&ndash; view pricing &ndash;&gt;-->
  <!--  <li *ngIf="!onlyShowLinks" class="popover-menu__item" (click)="showRates()">-->
  <!--    <i class="fal fa-eye layout-mr-lg popover_icon_style"></i>-->
  <!--    View revenue items-->
  <!--  </li>-->

  <!-- view summary -->
  <li
    class="popover-menu__item"
    (click)="viewSummary()"
    *ngIf="
      assignmentDisplayMode === AssignmentDisplayMode.ProjectPricing ||
      assignmentDisplayMode === AssignmentDisplayMode.Timesheets
    "
  >
    <i class="fal fa-eye layout-mr-lg popover_icon_style"></i>
    View summary
  </li>

  <li class="popover-menu__divider"></li>

  <!-- add daily pricing -->
  <li class="popover-menu__info-title popover-menu__info-title--primary">
    Z-Apps
  </li>

  <!-- chat -->
  <li class="popover-menu__item" (click)="openChat()">
    <i class="fal fa-message-pen layout-mr-lg popover_icon_style"></i>
    Add chat comment
  </li>

  <!-- chat -->
  <li class="popover-menu__item" (click)="createShareLink()">
    <i class="fal fa-share-alt layout-mr-lg popover_icon_style"></i>
    Create Share Link
  </li>

  <li class="popover-menu__divider"></li>
  <li *ngIf="!onlyShowLinks" class="popover-menu__item" (click)="archive()">
    <i class="fal fa-archive layout-mr-lg popover_icon_style"></i>
    Archive
  </li>

  <ng-container *ngIf="!hideLinkViewer && showLinksOptions">
    <li class="popover-menu__divider"></li>
    <!--    <li class="popover-menu__item" (click)="openLinksViewer()"
        [ngClass]="{disabled: !(linksWidgets | hasNonEmptyItem)}">-->
    <li class="popover-menu__item disabled">
      Links Viewer
    </li>

    <ng-container *ngFor="let tw of linksWidgets; trackBy: trackBy; index as index">
      <li class="popover-menu__divider" *ngIf="index > 0 || !hideLinkViewer"></li>
      <li class="popover-menu__info-title popover-menu__info-title--primary">
        {{ tw.widget.title }}
      </li>

      <ng-container *ngIf="tw.widget.widgetType === WidgetType.urlList">
        <li
          class="popover-menu__item popover-menu__item--name-value-pair"
          *ngFor="let link of tw.value; trackBy: trackBy"
          (click)="openLink(link.value)"
        >
          <app-link-item [link]="link"></app-link-item>
        </li>
      </ng-container>

      <li
        *ngIf="tw.widget.widgetType === WidgetType.url"
        class="popover-menu__item popover-menu__item--name-value-pair"
        [ngClass]="{ disabled: !tw.value }"
        (click)="openLink(tw.value)"
      >
        <span class="popover-menu__item-value">
          <app-link-item [link]="tw | urlWidgetToLink"></app-link-item
        ></span>
      </li>
    </ng-container>
  </ng-container>
</ul>
