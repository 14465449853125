import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-active-indicator',
  templateUrl: './active-indicator.component.html',
  styleUrls: ['./active-indicator.component.scss'],
})
export class ActiveIndicatorComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
