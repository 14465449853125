import { Pipe, PipeTransform } from '@angular/core';
import { TagType } from '../../core/constants/tag-type';

@Pipe({
  name: 'tagTypeLabel',
})
export class TagTypeLabelPipe implements PipeTransform {
  transform(tagType: TagType, isPlural: boolean = false): string {
    if (tagType === TagType.general) {
      return isPlural ? 'tags' : 'tag';
    } else if (tagType === TagType.stamp) {
      return isPlural ? 'stamps' : 'stamp';
    } else if (tagType === TagType.primary) {
      return isPlural ? 'primary tags' : 'primary tag';
    }

    return 'unknown';
  }
}
