import { Action, createReducer, on } from '@ngrx/store';
import { ChatbarState, initialChatbarState } from './chatbar.state';
import { ChatbarActions } from './index';
import { ChatbarPage } from '../../core/constants/chatbar-page';

const reducer = createReducer(
  initialChatbarState,

  on(
    ChatbarActions.openCurrentWorkspaceChannelSuccess,
    ChatbarActions.openNodeChannelSuccess,
    (state, { channel }): ChatbarState => {
      return {
        ...state,
        page: ChatbarPage.channel,
        currentChannelId: channel.id,
      };
    },
  ),
  on(ChatbarActions.goToWorkspaceAllChannelsPageSuccess, state => {
    return {
      ...state,
      page: ChatbarPage.allChannels,
      currentChannelId: undefined,
    };
  }),
);

export function chatbarReducer(state: ChatbarState, action: Action) {
  return reducer(state, action);
}
