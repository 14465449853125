import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-chat-sidebar-header',
  templateUrl: './chat-sidebar-header.component.html',
  styleUrls: ['./chat-sidebar-header.component.scss'],
})
export class ChatSidebarHeaderComponent {
  @Output()
  public readonly backEvent: EventEmitter<any>;

  @Input()
  public template: TemplateRef<any>;

  @Input()
  public title: string;

  @Input()
  public truncateTitle: boolean;

  @Input()
  public subTitle: string;

  @Input()
  public badge: string;

  @Input()
  public enableBack: boolean;

  constructor() {
    this.backEvent = new EventEmitter<any>();
    this.title = '';
    this.subTitle = '';
    this.badge = '';
  }

  public onBack(event: any) {
    if (this.enableBack === false) {
      return;
    }
    event.preventDefault();
    this.backEvent.emit(event);
  }
}
