import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { UserModel } from '../../../core/models/user.model';
import { FileUploaderComponent } from '../file-uploader/file-uploader.component';
import { Subscription } from 'rxjs';
import { selectCurrentUser } from '../../../store/auth/auth.selectors';
import { UploadService } from '../../../core/services/upload.service';
import { environment } from '../../../../environments/environment';
import { FileModel } from '../../../core/models/file.model';
import { AuthActions } from '../../../store/auth';
import { ErrorsActions } from '../../../store/errors';
import { WorkspaceUserMetaActions } from '../../../store/workspace-user-meta';

@Component({
  selector: 'app-avatar-upload-modal',
  templateUrl: './avatar-upload-modal.component.html',
  styleUrls: ['./avatar-upload-modal.component.scss'],
})
export class AvatarUploadModalComponent implements OnInit, OnDestroy {
  @ViewChild(FileUploaderComponent, { static: false })
  fileUploader: FileUploaderComponent;

  user: UserModel;
  isLoading: boolean;

  isUploaderLoading: boolean;

  subscription = new Subscription();

  constructor(
    public activeModal: NgbActiveModal,
    private store: Store,
    private uploadService: UploadService,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.store.pipe(select(selectCurrentUser)).subscribe(user => {
        this.user = user;
      }),
    );
  }

  uploadFiles(fileBlob: Blob) {
    this.isUploaderLoading = true;
    this.uploadService
      .uploadFiles(
        `${environment.apiBaseUrl}/user/${this.user.id}/profile/add`,
        fileBlob,
        `profile-image-${this.user.fullName}`,
      )
      .subscribe(
        (files: FileModel[]) => {
          if (files.length && files[0] != null) {
            // REMOVE THE PROFILE ICON FIRST IF THERE
            if (this.user?.metaData?.profileIcon) {
              this.store.dispatch(
                AuthActions.patchCurrentUserMetaRequest({
                  metaData: { profileIcon: null },
                }),
              );
              this.store.dispatch(
                WorkspaceUserMetaActions.updateWorkspaceUserProfileIcon({
                  profileIcon: null,
                  currentUserId: this.user.id,
                }),
              );
            }
            this.store.dispatch(
              AuthActions.patchCurrentUserProfile({
                profile: files[0],
                currentUserId: this.user.id,
              }),
            );
            this.activeModal.close();
          }
          this.isUploaderLoading = false;
        },
        error => {
          this.store.dispatch(ErrorsActions.goToErrorPage({ error }));
          this.isUploaderLoading = false;
        },
      );
  }

  savePredefineProfileIcon(item: { id: string; src: string }) {
    // REMOVE THE PROFILE IMAGE FIRST IF THERE
    if (this.user?.profile) {
      this.store.dispatch(AuthActions.removeCurrentUserProfileRequest());
    }
    this.store.dispatch(
      AuthActions.patchCurrentUserMetaRequest({
        metaData: { profileIcon: item },
      }),
    );
    this.store.dispatch(
      WorkspaceUserMetaActions.updateWorkspaceUserProfileIcon({
        profileIcon: item,
        currentUserId: this.user.id,
      }),
    );
    this.activeModal.close();
  }

  close() {
    this.activeModal.close();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
