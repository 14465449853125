<div class="sidebar-toggle-bar"
     [ngClass]="{'sidebar-toggle-bar--collapsed': parent.collapsed}"
     (click)="onToggle($event)">

  <span
    class="sidebar-toggle-bar__icon fal"
    [ngClass]="[
      position === SideBarPosition.Left && parent.collapsed === false ? 'fa-chevron-left' : '',
      position === SideBarPosition.Left && parent.collapsed === true ? 'fa-chevron-right' : '',
      position === SideBarPosition.Right && parent.collapsed === false ? 'fa-chevron-right' : '',
      position === SideBarPosition.Right && parent.collapsed === true ? 'fa-chevron-left' : ''
    ]"
  ></span>

<!-- THIS VERSION DOES NOT WORK!? WHY???-->
<!--  <span-->
<!--    class="sidebar-toggle-bar__icon fal"-->
<!--    [ngClass]="{-->
<!--      'fa-chevron-left': position === SideBarPosition.Left && parent.collapsed === false,-->
<!--      'fa-chevron-right': position === SideBarPosition.Left && parent.collapsed === true,-->
<!--      'fa-chevron-right': position === SideBarPosition.Right && parent.collapsed === false,-->
<!--      'fa-chevron-left': position === SideBarPosition.Right && parent.collapsed === true-->
<!--    }"-->
<!--  ></span>-->

</div>
