import { Action, createReducer, on } from '@ngrx/store';
import { initialTagsState, TagsState } from './tags.state';
import { TagsActions } from './index';

const reducer = createReducer<TagsState>(
  initialTagsState,
  on(TagsActions.loadTagsSuccess, (state, { tags }) => {
    const byId = tags.reduce((byId, tag) => {
      return {
        ...byId,
        [tag.id]: { ...tag },
      };
    }, {});
    const ids = tags.map(tag => tag.id);
    return {
      ...initialTagsState,
      tagsById: byId,
      ids,
    };
  }),

  on(TagsActions.addTagSuccess, (state, { tag }) => {
    return {
      ...state,
      tagsById: {
        ...state.tagsById,
        [tag.id]: { ...tag },
      },
      ids: [...state.ids, tag.id], // @todo: we may need to sort tags by sortIndex and name
    };
  }),

  on(TagsActions.updateTagRequest, (state, { tagId, tagProps }) => {
    return {
      ...state,
      tagsById: {
        ...state.tagsById,
        [tagId]: { ...state.tagsById[tagId], ...tagProps },
      },
    };
  }),

  on(TagsActions.deleteTagRequest, (state, { tagId }) => {
    return {
      ...state,
      tagsById: {
        ...state.tagsById,
        [tagId]: undefined,
      },
      ids: [...state.ids.filter(id => id !== tagId)],
    };
  }),

  on(TagsActions.sortTagsRequest, (state, { tagIds }) => {
    let idx = 0;
    const byId = tagIds.reduce((byId, id) => {
      return {
        ...byId,
        [id]: {
          ...byId[id],
          sortIndex: idx++,
        },
      };
    }, state.tagsById);

    return {
      ...state,
      tagsById: byId,
      ids: [...tagIds],
    };
  }),

  on(TagsActions.updateSearchKeywordRequest, (state, { searchKeyword }) => ({
    ...state,
    searchKeyword,
  })),
);

export function tagsReducer(state: TagsState, action: Action) {
  return reducer(state, action);
}
