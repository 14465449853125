import {OptionModel} from '../models/option.model';
import {NodeType} from './node-type';

export const templateNodeTypeOptions: OptionModel[] = [
  {
    id: NodeType.project,
    label: 'Assignments',
  },
  {
    id: NodeType.assignment,
    label: 'Activities',
  },

  {
    id: NodeType.asset,
    label: 'Resources',
  },
  {
    id: NodeType.element,
    label: 'Forms',
  },
];
