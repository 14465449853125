<div class="workspace-switch">
  <div ngbDropdown class="workspace-switch__dropdown" [container]="isPopupSelect ? null : 'body'">
    <button
      ngbDropdownToggle
      class="workspace-switch__dropdown-button btn"
      [ngClass]="
        isPopupSelect ? 'workspace-switch__dropdown-button--popup-select' : null
      "
      id="workspace-switch-dropdown-toggle"
      title="Workspace Switch"
    >
      {{ currentWorkspace?.title | truncate: 19 }}
    </button>
    <div
      ngbDropdownMenu
      class="workspace-switch__dropdown-menu"
      aria-labelledby="workspace-switch-dropdown-toggle"
    >
      <button
        *ngFor="let workspace of workspaces; trackBy: trackBy"
        ngbDropdownItem
        class="workspace-switch__dropdown-item"
        (click)="selectWorkspace(workspace.slug)"
      >
        {{ workspace.title }}
        <span
          class="workspace-switch__item-tick fal fa-check"
          *ngIf="currentWorkspace?.id === workspace.id"
        ></span>
      </button>
    </div>
  </div>
</div>
