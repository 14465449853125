import { Pipe, PipeTransform } from '@angular/core';
import { UsedIdsAndCountsModel } from '../../core/models/used-ids-and-counts.model';
import { TemplatesById } from '../../store/templates/templates.state';
import { NodeTemplateModel } from '../../core/models/node-template.model';
import { LayoutMetricModel } from '../../core/models/layout-metric.model';
import { NodeModel } from '../../core/models/node.model';

@Pipe({
  name: 'cardLayoutMetric',
})
export class CardLayoutMetricPipe implements PipeTransform {
  transform(assignments: NodeModel[], nodeTemplatesById: TemplatesById): LayoutMetricModel[] {
    // const {
    //   ids,
    //   countsById,
    // }: UsedIdsAndCountsModel = assignmentUtil.getUsedLayoutTemplateIdsAndCounts(assignments);
    // const metricArray = ids.map(id => {
    //   const count = countsById[id];
    //   const template: NodeTemplateModel = nodeTemplatesById[id];
    //   return { count, template };
    // });
    return [];
  }
}
