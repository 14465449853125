import { WorkspaceUserModel } from '../../core/models/workspace-user.model';

export class WorkspaceUsersState {
  currentWorkspaceUserIds: number[];
  currentWorkspaceUsersById: WorkspaceUsersById; // pls note the byId refers to userId instead of workspaceUserId
  isSendingInvitation: boolean; // "Send Invitation" api is slow to send email, so we need this loading state for UX
}

export const initialWorkspaceUsersState: WorkspaceUsersState = {
  currentWorkspaceUserIds: [],
  currentWorkspaceUsersById: {},
  isSendingInvitation: false,
};

export interface WorkspaceUsersById {
  [id: number]: WorkspaceUserModel;
}
