<div class="z-table__editor-url" [ngClass]="{ 'z-table__editor--empty': editable && !cell?.value }">
  <div
    *ngIf="faviconUrl"
    class="z-table__input--icon"
    [ngStyle]="{ 'background-image': 'url(' + (cell.value | favicon) + ')' }"
  ></div>
  <input
    #input
    type="url"
    [id]="elementId"
    class="z-table__input z-table__input--url"
    [(ngModel)]="cell.value"
    [placeholder]="!cell.value ? 'https://' : ''"
    [disabled]="!editable"
    (focus)="onStartEdit()"
    (blur)="onEndEdit()"
    (keyup.enter)="onEnterKey()"
    (keyup.escape)="onEscapeKey()"
  />
  <div *ngIf="faviconUrl" class="z-table__input--link" (click)="goToLink()">
    <i class="fal fa-external-link-alt"></i>
  </div>
</div>
