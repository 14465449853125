import { Action, createReducer, on } from '@ngrx/store';
import { initialUserGroupsPopoverState, UserGroupsPopoverState } from './user-groups-popover.state';
import { UserGroupsPopoverActions } from './index';
import { UserGroupsPopoverPage } from '../../core/constants/user-groups-popover-page';

const reducer = createReducer(
  initialUserGroupsPopoverState,
  on(UserGroupsPopoverActions.showUserGroupCreatePage, state => {
    return {
      ...state,
      page: UserGroupsPopoverPage.groupCreate,
      groupId: undefined,
    };
  }),
  on(UserGroupsPopoverActions.showUserGroupEditPage, (state, { groupId }) => {
    return {
      ...state,
      page: UserGroupsPopoverPage.groupEdit,
      groupId,
    };
  }),
);

export function userGroupsPopoverReducer(state: UserGroupsPopoverState, action: Action) {
  return reducer(state, action);
}
