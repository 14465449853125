export enum RichSearchType {
  keyword = 1,
  filterCategory,
  template,
  layout,
  tag,
  folder,
  unassignedDate,
}

export type RichSearchFilterCategory =
  | RichSearchType.template
  | RichSearchType.layout
  | RichSearchType.tag
  | RichSearchType.folder;
