import { Component, ElementRef, ViewChild } from '@angular/core';
import { TableComponent } from '../table.component';

@Component({
  selector: 'app-z-table-row-menu',
  templateUrl: './table-row-menu.component.html',
  styleUrls: ['./table-row-menu.component.scss'],
})
export class TableRowMenuComponent {
  @ViewChild('menuContent')
  public menuContent: ElementRef;
  constructor(public table: TableComponent) {}
}
