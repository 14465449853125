import { NgModule } from '@angular/core';
import { ComponentsModule } from './components/_components.module';
import { DirectivesModule } from './directives/_directives.module';
import { ModalsModule } from './modals/_modals.module';
import { PipesModule } from './pipes/_pipes.module';
import { TableModule } from './table/table.module';
import { ZedulrTableModule } from './zedulr-tables/_zedulr-tables.module';

@NgModule({
  declarations: [],
  imports: [
    ComponentsModule,
    DirectivesModule,
    ModalsModule,
    PipesModule,
    TableModule,
    ZedulrTableModule,
  ],
  exports: [
    ComponentsModule,
    DirectivesModule,
    ModalsModule,
    PipesModule,
    TableModule,
    ZedulrTableModule,
  ],
})
export class SharedModule {}
