import { createAction, props } from '@ngrx/store';
import { WorkspaceTemplateModel } from '../../core/models/workspace-template.model';
import { WorkspaceMetaModel } from '../../core/models/workspace-meta.model';
import { WorkspaceEditableProps } from '../../core/models/workspace.model';

export const loadWorkspaceTemplatesRequest = createAction(
  '[Workspace Templates] load workspace templates request',
);

export const loadWorkspaceTemplatesSuccess = createAction(
  '[Workspace Templates] load workspace templates success',
  props<{ templates: WorkspaceTemplateModel[] }>(),
);

export const updateWorkspaceTemplateRequest = createAction(
  '[Workspace Templates] update workspace template request',
  props<{ id: number; props: Partial<WorkspaceEditableProps> }>(),
);

export const updateWorkspaceTemplateSuccess = createAction(
  '[Workspace Templates] update workspace template success',
  props<{ id: number; template: WorkspaceTemplateModel }>(),
);

export const patchWorkspaceTemplateMetaRequest = createAction(
  '[Workspace Templates] patch workspace template meta request',
  props<{ id: number; metaData: Partial<WorkspaceMetaModel> }>(),
);

export const patchWorkspaceTemplateMetaSuccess = createAction(
  '[Workspace Templates] patch workspace template meta success',
  props<{ id: number; metaData: Partial<WorkspaceMetaModel> }>(),
);

export const deleteWorkspaceTemplateRequest = createAction(
  '[Workspace Templates] delete workspace template request',
  props<{ id: number }>(),
);

export const deleteWorkspaceTemplateSuccess = createAction(
  '[Workspace Templates] delete workspace template success',
  props<{ id: number }>(),
);

export const updateSearchKeywordRequest = createAction(
  '[Workspace Templates] update search keyword request',
  props<{ searchKeyword: string }>(),
);
