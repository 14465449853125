import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AppState } from '../../../store';
import { select, Store } from '@ngrx/store';
import { WorkspaceModel } from '../../../core/models/workspace.model';
import { Subscription } from 'rxjs';
import {
  selectAllWorkspaces,
  selectCurrentWorkspace,
} from '../../../store/workspaces/workspaces.selectors';
import { Router } from '@angular/router';
import { WorkspacesActions } from '../../../store/workspaces';
import { NodesActions } from '../../../store/nodes';
import { ArchivesActions } from '../../../store/archives';

@Component({
  selector: 'app-workspace-switch',
  templateUrl: './workspace-switch.component.html',
  styleUrls: ['./workspace-switch.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WorkspaceSwitchComponent implements OnInit, OnDestroy {
  private readonly subscription = new Subscription();

  @Input()
  public isPopupSelect: boolean;

  workspaces: WorkspaceModel[];
  currentWorkspace: WorkspaceModel;

  constructor(private store: Store<AppState>, private router: Router) {}

  ngOnInit() {
    this.subscription.add(
      this.store.pipe(select(selectAllWorkspaces)).subscribe(workspaces => {
        this.workspaces = workspaces;
      }),
    );

    this.subscription.add(
      this.store.pipe(select(selectCurrentWorkspace)).subscribe(workspace => {
        this.currentWorkspace = workspace;
      }),
    );
  }

  trackBy(index, workspace) {
    return workspace.id;
  }

  selectWorkspace(slug: string) {
    this.store.dispatch(WorkspacesActions.unloadCurrentWorkspace());
    this.store.dispatch(NodesActions.unloadNodesRequest());
    this.store.dispatch(NodesActions.unloadAssignmentsRequest());
    this.store.dispatch(ArchivesActions.unloadArchiveRequest());
    this.router.navigate(['ws', slug]);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
