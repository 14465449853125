import { Action, createReducer, on } from '@ngrx/store';
import { AppLayoutState, initialAppLayoutState } from './app-layout.state';
import { AppLayoutActions } from '../app-layout';

const reducer = createReducer(
  initialAppLayoutState,

  on(AppLayoutActions.setWorkspaceContent, (state, workspaceContent) => {
    return {
      ...state,
      workspaceContent: { ...state.workspaceContent, ...workspaceContent },
    };
  }),

  on(AppLayoutActions.setSidebarLeft, (state, sidebar) => {
    return {
      ...state,
      sidebarLeft: { ...state.sidebarLeft, ...sidebar },
    };
  }),

  on(AppLayoutActions.setSidebarLeftCollapsed, (state, { collapsed }) => {
    return {
      ...state,
      sidebarLeft: {
        ...state.sidebarLeft,
        collapsed,
      },
    };
  }),

  on(AppLayoutActions.openSidebarLeft, (state, sidebar) => {
    return {
      ...state,
      sidebarLeft: {
        ...state.sidebarLeft,
        ...sidebar,
        disable: false,
        collapsed: false,
      },
    };
  }),

  on(AppLayoutActions.closeSidebarLeft, state => {
    return {
      ...state,
      sidebarLeft: {
        ...state.sidebarLeft,
        collapsed: true,
      },
    };
  }),

  on(AppLayoutActions.setSidebarSubLeft, (state, sidebar) => {
    return {
      ...state,
      sidebarSubLeft: { ...state.sidebarSubLeft, ...sidebar },
    };
  }),

  on(AppLayoutActions.openSidebarSubLeft, (state, sidebar) => {
    return {
      ...state,
      sidebarSubLeft: {
        ...state.sidebarSubLeft,
        ...sidebar,
        disable: false,
        collapsed: false,
      },
    };
  }),

  on(AppLayoutActions.closeSidebarSubLeft, state => {
    return {
      ...state,
      sidebarSubLeft: {
        ...state.sidebarSubLeft,
        collapsed: true,
      },
    };
  }),

  on(AppLayoutActions.setSidebarRight, (state, sidebar) => {
    return {
      ...state,
      sidebarRight: { ...state.sidebarRight, ...sidebar },
    };
  }),

  on(AppLayoutActions.setSidebarRightCollapsed, (state, { collapsed }) => {
    return {
      ...state,
      sidebarRight: {
        ...state.sidebarRight,
        collapsed,
      },
    };
  }),

  on(AppLayoutActions.openSidebarRight, (state, sidebar) => {
    return {
      ...state,
      sidebarRight: {
        ...state.sidebarRight,
        ...sidebar,
        disable: false,
        collapsed: false,
      },
    };
  }),

  on(AppLayoutActions.closeSidebarRight, state => {
    return {
      ...state,
      sidebarRight: {
        ...state.sidebarRight,
        collapsed: true,
      },
    };
  }),

  on(AppLayoutActions.setSidebarSubRight, (state, sidebar) => {
    return {
      ...state,
      sidebarSubRight: { ...state.sidebarSubRight, ...sidebar },
    };
  }),

  on(AppLayoutActions.openSidebarSubRight, (state, sidebar) => {
    return {
      ...state,
      sidebarSubRight: {
        ...state.sidebarSubRight,
        ...sidebar,
        disable: false,
        collapsed: false,
      },
    };
  }),

  on(AppLayoutActions.closeSidebarSubRight, state => {
    return {
      ...state,
      sidebarSubRight: {
        ...state.sidebarSubRight,
        collapsed: true,
      },
    };
  }),

  on(AppLayoutActions.setWorkspaceCalendarOpen, (state, { open }) => {
    return {
      ...state,
      workspaceCalendarOpen: open,
    };
  }),
);

export function appLayoutReducer(state: AppLayoutState, action: Action) {
  return reducer(state, action);
}
