import { Pipe, PipeTransform } from '@angular/core';
import { NodeType } from '../../core/constants/node-type';
import { NodeUtils } from '../../core/utils/node.util';

@Pipe({
  name: 'nodeTypeLabel',
})
export class NodeTypeLabelPipe implements PipeTransform {
  transform(nodeType: NodeType): string {
    return NodeUtils.getNodeTypeLabel(nodeType);
  }
}
