import { createAction, props } from '@ngrx/store';
import { ChatChannelModel } from '../../core/models/chat-channel.model';

export const goToWorkspaceAllChannelsPageRequest = createAction(
  '[Chatbar] go to workspace all channels page request',
);

export const goToWorkspaceAllChannelsPageSuccess = createAction(
  '[Chatbar] go to workspace all channels page success',
  props<{ channels: ChatChannelModel[] }>(),
);
export const openCurrentWorkspaceChannelRequest = createAction(
  '[Chatbar] open workspace channel request',
  props<{ hideChatModal?: boolean }>(),
);
export const openCurrentWorkspaceChannelSuccess = createAction(
  '[Chatbar] open workspace channel success',
  props<{ channel: ChatChannelModel }>(),
);
export const openNodeChannelRequest = createAction(
  '[Chats] open node channel request',
  props<{ nodeId: number; hideChatModal?: boolean }>(),
);
export const openNodeChannelSuccess = createAction(
  '[Chats] open node channel success',
  props<{ channel: ChatChannelModel }>(),
);
export const clickNodeChatIcon = createAction(
  '[Chats] click node chat icon',
  props<{ nodeId: number }>(),
);
