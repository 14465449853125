import { Component, OnDestroy, OnInit } from '@angular/core';
import { WorkspaceUserModel } from '../../../../core/models/workspace-user.model';
import { Subscription } from 'rxjs';
import { PermissionType } from '../../../../core/constants/permission-type';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../store';
import { selectLoggedInWorkspaceUser } from '../../../../store/workspace-users/workspace-users.selectors';
import { AppLayoutSelectors } from '../../../../store/app-layout';
import { NodesSelectors } from '../../../../store/nodes';
import { NodeType } from '../../../../core/constants/node-type';
import { NodeModel } from '../../../../core/models/node.model';

@Component({
  selector: 'app-folder-tree-root',
  templateUrl: './folder-tree-root.component.html',
  styleUrls: ['./folder-tree-root.component.scss'],
})
export class FolderTreeRootComponent implements OnInit, OnDestroy {
  private readonly subscription: Subscription = new Subscription();

  public readonly PermissionType = PermissionType;

  public isSidebarOpen: boolean;

  public favouritesRootFolder: NodeModel;
  public projectRootFolder: NodeModel;
  public assetRootFolder: NodeModel;
  public elementRootFolder: NodeModel;

  loggedInUser: WorkspaceUserModel;

  constructor(private store: Store<AppState>) {
    this.subscription.add(
      this.store.pipe(select(selectLoggedInWorkspaceUser)).subscribe(loggedInUser => {
        this.loggedInUser = loggedInUser;
      }),
    );

    this.subscription.add(
      this.store.pipe(select(AppLayoutSelectors.selectSidebarLeft)).subscribe(sidebar => {
        this.isSidebarOpen = !sidebar.collapsed;
      }),
    );

    this.subscription.add(
      this.store.pipe(select(NodesSelectors.selectRootNodes)).subscribe((nodes: NodeModel[]) => {
        nodes.map(n => {
          switch (n.nodeType) {
            case NodeType.favouritesFolder:
              this.favouritesRootFolder = n;
              break;

            case NodeType.projectFolder:
              this.projectRootFolder = n;
              break;

            case NodeType.assetFolder:
              this.assetRootFolder = n;
              break;

            case NodeType.elementFolder:
              this.elementRootFolder = n;
              break;
          }
        });
      }),
    );
  }

  ngOnInit(): void {}

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
