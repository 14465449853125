import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Dashboard } from '../models/dashboard.model';
import { environment } from '../../../environments/environment';
import { WorkspaceEditableProps, WorkspaceModel } from '../models/workspace.model';
import { ResponseMessage } from '../models/response-message.model';
import { map } from 'rxjs/operators';
import { WorkspaceMetaModel } from '../models/workspace-meta.model';
import { WorkspaceTemplateModel } from '../models/workspace-template.model';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private http: HttpClient) {}

  public getDashboard(): Observable<Dashboard> {
    return this.http.get<Dashboard>(`${environment.apiBaseUrl}/dashboard`);
  }

  public addWorkspace(
    props: WorkspaceEditableProps,
    workspaceTemplateId = null,
  ): Observable<WorkspaceModel> {
    return this.http.post<WorkspaceModel>(`${environment.apiBaseUrl}/workspace/add`, {
      ...props,
      template: workspaceTemplateId,
    });
  }

  public copyWorkspace(title: string, slug: string): Observable<WorkspaceModel> {
    return this.http.post<WorkspaceModel>(`${environment.apiBaseUrl}/workspace/${slug}/copy`, {
      title,
    });
  }

  public saveAsTemplate(
    props: WorkspaceEditableProps,
    slug: string,
  ): Observable<WorkspaceTemplateModel> {
    return this.http
      .post<ResponseMessage<WorkspaceTemplateModel>>(
        `${environment.apiBaseUrl}/workspace/${slug}/save-as-template`,
        {
          ...props,
          sortIndex: 1, // Always put to the first for now.
        },
      )
      .pipe(map(response => response.data));
  }

  public removeWorkspace(slug: string): Observable<ResponseMessage<any>> {
    return this.http.delete<ResponseMessage<any>>(
      `${environment.apiBaseUrl}/workspace/${slug}/remove`,
    );
  }

  public leaveWorkspace(slug: string): Observable<ResponseMessage<any>> {
    return this.http.delete<ResponseMessage<any>>(
      `${environment.apiBaseUrl}/workspace/${slug}/user/remove`,
    );
  }

  public updateWorkspace(slug: string, props: WorkspaceEditableProps) {
    return this.http
      .put<ResponseMessage<any>>(`${environment.apiBaseUrl}/workspace/${slug}/update`, {
        ...props,
      })
      .pipe(map(response => response.data));
  }

  public patchWorkspaceMeta(slug: string, meta: Partial<WorkspaceMetaModel>) {
    return this.http
      .patch<ResponseMessage<any>>(`${environment.apiBaseUrl}/workspace/${slug}/meta/update`, meta)
      .pipe(map(response => response.data));
  }
}
