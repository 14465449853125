import { NodeTemplateWidgetModel } from '../../core/models/node-template-widget.model';

export interface TemplateWidgetsState {
  templateWidgetsById: { [joinId: number]: NodeTemplateWidgetModel };
  templateWidgetsByTemplateId: { [templateId: number]: NodeTemplateWidgetModel[] };
  templateWidgetsByGroupId: { [groupId: number]: NodeTemplateWidgetModel[] };
}

export const initialTemplateWidgetsState = {
  templateWidgetsById: {},
  templateWidgetsByTemplateId: {},
  templateWidgetsByGroupId: {},
};
