import { AppState } from '../index';
import { createSelector } from '@ngrx/store';

const selectNodeGroupsState = (state: AppState) => state.nodeGroups;

export const selectNodeGroupsById = createSelector(
  selectNodeGroupsState,
  state => state.nodeGroupsById,
);

export const selectNodeGroupsByNodeId = createSelector(
  selectNodeGroupsState,
  state => state.nodeGroupsByNodeId,
);

export const selectNodeGroupsByGroupId = createSelector(
  selectNodeGroupsState,
  state => state.nodeGroupsByGroupId,
);
