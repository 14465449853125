import { Component, Input, OnInit } from '@angular/core';
import { defaultPlacements } from '../../../core/constants/placements';

@Component({
  selector: 'app-ellipse-tooltip',
  templateUrl: './ellipse-tooltip.component.html',
  styleUrls: ['./ellipse-tooltip.component.scss'],
})
export class EllipseTooltipComponent implements OnInit {
  @Input() value: string;
  @Input() limit: number;

  defaultPlacements = defaultPlacements;

  constructor() {}

  ngOnInit(): void {}

  onClick(event: MouseEvent) {
    event.stopPropagation();
  }
}
