import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap } from 'rxjs/operators';
import { NodeListPanelActions } from './index';
import { AppLayoutActions } from '../app-layout';
import { of } from 'rxjs';
import { SideBarMode } from '../../shared/components/_layout/sidebar/sidebar.component';

@Injectable()
export class NodeListPanelEffects {
  constructor(private actions$: Actions) {}

  openFromSidebar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NodeListPanelActions.openNodeListPanel),
      concatMap(action => of(AppLayoutActions.openSidebarSubLeft({ mode: SideBarMode.NodeList }))),
    ),
  );
}
