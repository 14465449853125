import { Component, OnInit, ViewChild } from '@angular/core';
import { TableComponent } from '../../table.component';
import { AbstractTableEditableCellComponent } from '../abstract-table-editable-cell.component';
import { NgSelectComponent } from '@ng-select/ng-select';
import { GroupEditComponent } from '../../../modals/group-edit/group-edit.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WorkspaceGroupModel } from '../../../../core/models/workspace-group.model';

@Component({
  selector: 'app-z-table-editable-workspace-group',
  templateUrl: './table-editable-workspace-group.component.html',
  styleUrls: ['./table-editable-workspace-group.component.scss'],
})
export class TableEditableWorkspaceGroupComponent extends AbstractTableEditableCellComponent
  implements OnInit {
  @ViewChild('ngSelect')
  public ngSelect: NgSelectComponent;

  protected defaultOptions = {
    list: [],
  };

  protected initialValue: any;

  constructor(public table: TableComponent, private modalService: NgbModal) {
    super(table);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.cell.value = this.cell.value || null;
    this.initialValue = this.cell.value;
  }

  public onStartEdit() {
    this.previousValue = this.initialValue;
    this.startEdit.emit(this.buildEditEvent());
  }

  public onEndEdit() {
    if (!this.cell.value) {
      this.cell.value = this.initialValue;
    } else {
      this.initialValue = this.cell.value;
    }
    super.onEndEdit();
  }

  public onEditGroup(event, group) {
    event.preventDefault();
    event.stopPropagation();
    this.ngSelect.close();

    const modelRef = this.modalService.open(GroupEditComponent, {
      size: 'sm',
      backdrop: 'static',
    });

    modelRef.componentInstance.group = {
      id: parseInt(group.value, 10),
      title: group.label.toString(),
      type: this.options.type || null,
      nodeId: this.options.nodeId || null,
      nodeTemplateId: this.options.nodeTemplateId || null,
    } as WorkspaceGroupModel;
  }

  public onAddGroup(event) {
    event.preventDefault();
    event.stopPropagation();
    this.ngSelect.close();

    const modelRef = this.modalService.open(GroupEditComponent, {
      size: 'sm',
      backdrop: 'static',
    });
    modelRef.componentInstance.group = null;
    modelRef.componentInstance.groupType = this.options.type || null;
    modelRef.componentInstance.nodeId = this.options.nodeId || null;
    modelRef.componentInstance.templateId = this.options.nodeTemplateId || null;
    modelRef.componentInstance.childId = this.options.childId || null;
  }
}
