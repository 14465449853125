import { Action, createReducer, on } from '@ngrx/store';
import { initialWidgetEditPopoverState, WidgetEditPopoverState } from './widget-edit-popover.state';
import { WidgetEditPopoverActions } from './index';

const reducer = createReducer(
  initialWidgetEditPopoverState,
  on(WidgetEditPopoverActions.showWidgetEditPopover, (state, { nodeId, nodeWidget }) => {
    return {
      nodeId,
      nodeWidget,
    };
  }),
);

export function widgetEditPopoverReducer(state: WidgetEditPopoverState, action: Action) {
  return reducer(state, action);
}
