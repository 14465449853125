<app-modal
  title="Record {{ active === 'Revenue' ? 'Revenue' : 'Cost' }} - {{ project.title }}: {{
    assignment.date == awaitingAssignmentDateKey || assignment.date == null
      ? 'Plan Board'
      : (assignment.date | momentDate: 'shortDate')
  }}"
>
  <nav
    ngbNav
    #nav="ngbNav"
    [activeId]="active"
    (navChange)="onNavChange($event)"
    class="properties-panel__tabs nav-tabs justify-content-center"
  >
    <ng-container
      [ngbNavItem]="'Revenue'"
      [destroyOnHide]="false"
      [disabled]="!(assignment?.reference?.rates?.length && assignment?.nodeTemplate?.allowRevenue)"
    >
      <a ngbNavLink>Revenue</a>
      <ng-template ngbNavContent>
        <div class="node-rates-modal__ztable">
          <app-z-table-node-rate-values
            [assignment]="assignment"
            [project]="project"
          ></app-z-table-node-rate-values>
        </div>
      </ng-template>
    </ng-container>
    <ng-container
      [ngbNavItem]="'Cost'"
      [destroyOnHide]="false"
      [disabled]="!(assignment?.reference?.rates?.length && assignment?.nodeTemplate?.allowCosts)"
    >
      <a ngbNavLink>Cost</a>
      <ng-template ngbNavContent>
        <div class="node-rates-modal__ztable">
          <app-z-table-assignment-timesheets
            *ngFor="let layout of assignmentsByNodeTemplate; trackBy: trackBy"
            class="d-block mb-3"
            [assignments]="layout.nodes"
            [assignmentTitle]="layout.title"
            [assignmentIcon]="layout.icon"
            [settings]="tableSettings"
          ></app-z-table-assignment-timesheets>
        </div>
      </ng-template>
    </ng-container>
  </nav>

  <div class="container-fluid">
    <div class="row justify-content-center mt-2">
      <div class="col-12" [ngbNavOutlet]="nav"></div>
    </div>
  </div>
</app-modal>
