import { Injectable, OnDestroy } from '@angular/core';
import { from } from 'rxjs';
import { last, map, mergeMap, reduce, takeWhile } from 'rxjs/operators';
import { HttpClient, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';

export class FileUpload {
  public file: File;
  public type: string;
  public preview: string | ArrayBuffer;
  public progress: number;
  public complete: boolean;
  public abort: boolean;

  constructor(file?: File) {
    this.file = file;
    this.type = '';
    this.preview = '';
    this.progress = 0;
    this.complete = false;
    this.abort = false;
  }
}

@Injectable({ providedIn: 'root' })
export class FileUploaderService implements OnDestroy {
  public static readonly Image = [
    'image', // Simple type
    'image/png',
    'image/jpg',
    'image/jpeg',
    'image/gif',
    'image/bmp',
    'image/tiff',
  ];

  public static readonly Document = [
    'document', // Simple type
    'application/msword',
    'application/vnd.ms-excel',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/pdf',
    'text/plain',
    'application/rtf',
    'application/vnd.oasis.opendocument.text',
    'application/vnd.oasis.opendocument.spreadsheet',
  ];

  public static readonly Video = [
    'video', // Simple type
    'video/x-flv',
    'video/mp4',
    'video/mpeg',
    'video/ogg',
    'video/webm',
    'application/x-mpegURL',
    'video/MP2T',
    'video/3gpp',
    'video/quicktime',
    'video/x-msvideo',
    'video/x-ms-wmv',
  ];

  public static readonly Audio = [
    'audio', // Simple type
    'audio/midi',
    'audio/x-midi',
    'audio/webm',
    'audio/wav',
    'audio/ogg',
    'audio/mpeg',
    'audio/mp3',
    'audio/aac',
  ];

  constructor(protected http: HttpClient) {}

  public getType(file, restrictType?: string[]): string | boolean {
    if (!file || !file.type) {
      return false;
    }

    // Use the provided 'restrictType' or use all known types
    const allowedTypes: string[] =
      restrictType ||
      FileUploaderService.Image.concat(
        FileUploaderService.Document,
        FileUploaderService.Video,
        FileUploaderService.Audio,
      );

    // Pre-process the file type
    const fileType = ('' + file.type) // Coerce into a string
      .toLowerCase() // Lowercase all for consistency
      .replace(/^\s*|\s*$/g, ''); // Remove white spaces

    // Loop through and validate each type
    for (let i = 0, l = allowedTypes.length; i < l; i++) {
      if (fileType.match(allowedTypes[i].toLowerCase())) {
        // Valid type
        return allowedTypes[i];
      }
    }

    // Invalid type
    return false;
  }

  public getSimpleType(file) {
    if (this.isImage(file)) {
      return 'Image';
    }

    if (this.isDocument(file)) {
      return 'Document';
    }

    if (this.isVideo(file)) {
      return 'Video';
    }

    if (this.isAudio(file)) {
      return 'Audio';
    }

    return '';
  }

  public isValidType(file, types): boolean {
    return !!this.getType(file, types);
  }

  public isImage(file): boolean {
    return !!this.getType(file, FileUploaderService.Image);
  }

  public isDocument(file): boolean {
    return !!this.getType(file, FileUploaderService.Document);
  }

  public isVideo(file): boolean {
    return !!this.getType(file, FileUploaderService.Video);
  }

  public isAudio(file): boolean {
    return !!this.getType(file, FileUploaderService.Audio);
  }

  public isUnknown(file): boolean {
    return this.getType(file) === false;
  }

  public uploadFiles(url: string, files: FileUpload[]) {
    // This will Chain each file Upload as an individual request
    // We do this so we can track individual progress
    return from(files).pipe(
      mergeMap(file => {
        const myFormData: FormData = new FormData();
        myFormData.append('file', file.file, file.file.name);

        const config = new HttpRequest('POST', url, myFormData, {
          reportProgress: false,
        });

        return this.http.request(config).pipe(
          takeWhile(event => !file.abort),
          map(event => {
            if (event.type === HttpEventType.UploadProgress) {
              // Update progress Indicator
              file.progress = Math.round((event.loaded / event.total) * 100);
            } else if (event.type === HttpEventType.Response) {
              // Complete
              file.complete = true;
              file.progress = 100;
            }
            return event;
          }),
          last(),
        );
      }),

      reduce<HttpResponse<any[]>, any>((results, response) => {
        // Multiple Files
        if (Array.isArray(response.body)) {
          response.body.forEach(file => {
            results.push(file);
          });
        } else {
          // Single File
          results.push(response.body);
        }

        return results;
      }, []),
    );
  }

  ngOnDestroy(): void {}
}
