import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';

import { ChatChannelCommentViewModel } from '../../../../core/models/chat-channel-comment-view.model';
import { ChatsActions } from '../../../../store/chats';
import { ChatChannelModel } from '../../../../core/models/chat-channel.model';
import { selectCurrentUserId } from '../../../../store/auth/auth.selectors';
import {
  selectChatbarCurrentChannelViewModel,
  selectChatbarCurrentWorkspaceChannelCommentViewModels,
} from '../../../../store/chatbar/chatbar.selectors';
import { ChatbarActions } from '../../../../store/chatbar';
import { ChatActions } from '../../../../core/constants/chatbar-page';
import { NoDataTypeIds } from '../../../../core/constants/no-data-settings';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: [
    './chat.component.scss',
    './../../../../shared/components/_layout/sidebar/sidebar-header/sidebar-header.component.scss',
  ],
})
export class ChatComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly subscription = new Subscription();

  @ViewChildren('commentElement')
  public commentElements: QueryList<ElementRef>;

  @ViewChild('commentsContainer')
  public commentsContainer: ElementRef;

  comments: ChatChannelCommentViewModel[];
  channel: ChatChannelModel;
  selectedCommentForEdit: ChatChannelCommentViewModel;
  public noDataTypeIds = NoDataTypeIds;
  currentUserId: number;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.subscription.add(
      this.store
        .pipe(select(selectChatbarCurrentWorkspaceChannelCommentViewModels))
        .subscribe(comments => {
          this.comments = comments;
          this.commentElements && this.commentElements.notifyOnChanges();
        }),
    );

    this.subscription.add(
      this.store.pipe(select(selectChatbarCurrentChannelViewModel)).subscribe(channel => {
        this.channel = channel;
      }),
    );

    this.subscription.add(
      this.store.pipe(select(selectCurrentUserId)).subscribe(currentUserId => {
        this.currentUserId = currentUserId;
      }),
    );
  }

  ngAfterViewInit(): void {
    this.subscription.add(
      this.commentElements.changes.subscribe(() => {
        this.scrollContainerToBottom();
      }),
    );
  }

  // addComment(comment) {
  //   this.store.dispatch(
  //     ChatsActions.addCommentToChannelRequest({ comment, channelId: this.channel.id }),
  //   );
  // }

  addComment({ comment, action }) {
    if (action === ChatActions.add) {
      this.store.dispatch(
        ChatsActions.addCommentToChannelRequest({ comment, channelId: this.channel.id }),
      );
    } else {
      // edit comment
      this.store.dispatch(
        ChatsActions.updateCommentInChannelRequest({
          channelId: this.channel.id,
          commentId: this.selectedCommentForEdit.id,
          comment,
        }),
      );
      // clear selected item
      this.selectedCommentForEdit = null;
    }
  }

  // addAttachment({ file, comment }) {
  //   this.store.dispatch(
  //     ChatsActions.addAttachmentToChannelRequest({
  //       file,
  //       comment,
  //       channelId: this.channel.id,
  //     }),
  //   );
  // }

  addAttachment({ file, comment, action }) {
    if (action === ChatActions.add) {
      this.store.dispatch(
        ChatsActions.addAttachmentToChannelRequest({
          file,
          comment,
          channelId: this.channel.id,
        }),
      );
    } else {
      // edit attachment
      this.store.dispatch(
        ChatsActions.updateAttachmentToChannelRequest({
          file,
          comment,
          channelId: this.channel.id,
          commentId: this.selectedCommentForEdit.id,
        }),
      );
      // clear selected item
      this.selectedCommentForEdit = null;
    }
  }

  onCommentActionFired(actionType: ChatActions, comment: ChatChannelCommentViewModel): void {
    switch (actionType) {
      case ChatActions.edit:
        this.editComment(comment);
        break;

      case ChatActions.delete:
        this.deleteComment(comment);
        break;

      default:
        break;
    }
  }

  editComment(comment: ChatChannelCommentViewModel): void {
    if (this.selectedCommentForEdit?.id === comment?.id) {
      this.selectedCommentForEdit = null;
      return;
    }
    this.selectedCommentForEdit = comment;
  }

  deleteComment(comment: ChatChannelCommentViewModel): void {
    this.store.dispatch(
      ChatsActions.deleteCommentInChannelRequest({
        commentId: comment.id,
        channelId: this.channel.id,
      }),
    );
    this.selectedCommentForEdit = null;
  }

  trackBy(index, item) {
    return item.id;
  }

  scrollContainerToBottom(): void {
    if (this.commentsContainer && this.commentsContainer.nativeElement) {
      this.commentsContainer.nativeElement.scrollTop = this.commentsContainer.nativeElement.scrollHeight;
    }
  }

  goToAllChannels() {
    this.store.dispatch(ChatbarActions.goToWorkspaceAllChannelsPageRequest());
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
