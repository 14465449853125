import { AppState } from '../index';
import { createSelector } from '@ngrx/store';
import {
  selectDefaultAssetTemplateId,
  selectDefaultAssignmentTemplateId,
  selectDefaultElementTemplateId,
  selectDefaultProjectTemplateId,
  selectNodeTemplateIdsByNodeType,
  selectNodeTemplatesById,
} from '../templates/templates.selectors';
import { selectWidgetsById } from '../widgets/widgets.selectors';
import {
  selectTemplateWidgetData,
  selectTemplateWidgetsByGroupId,
  selectTemplateWidgetsByTemplateId,
} from '../template-widgets/template-widgets.selectors';
import { selectTagsById } from '../tags/tags.selectors';

import {
  selectTemplateTagsByGroupId,
  selectTemplateTagsByTemplateByType,
} from '../template-tags/template-tags.selectors';

import { selectWorkspaceGroupsByNodeTemplateId } from '../workspace-groups/workspace-groups.selectors';
import { WorkspaceGroupType } from '../../core/constants/workspace-group-type';
import { WidgetType } from '../../core/constants/widget-type';
import { templateNodeTypeOptions } from '../../core/constants/template-node-type-options';
import { TemplateTypeViewModel } from '../../core/models/template-type-view.model';
import { TagType } from '../../core/constants/tag-type';
import { NodeType } from '../../core/constants/node-type';
import { selectElementTemplateViewModelsDataByProjectAndAssignmentTemplateId } from '../templates/template-view-models.selectors';
import { selectWorkspacesCurrentSlug } from '../workspaces/workspaces.selectors';
import { templateDefaultKey } from '../../core/constants/template.constants';
import { TemplateTagModel } from '../../core/models/template-tag.model';
import { NodeTemplateModel } from '../../core/models/node-template.model';
import { WorkspaceGroupModel } from '../../core/models/workspace-group.model';
import { NodeUtils } from '../../core/utils/node.util';

const selectTemplatesPopoverState = (state: AppState) => state.templatesPopover;

export const selectTemplatesPopoverSelectedTemplateId = createSelector(
  selectTemplatesPopoverState,
  state => state.selectedTemplateId,
);

export const selectTemplatesPopoverServerNodeType = createSelector(
  selectTemplatesPopoverSelectedTemplateId,
  selectNodeTemplatesById,
  (selectedTemplateId, templatesById) => {
    if (selectedTemplateId) {
      const template = templatesById[selectedTemplateId];
      return template ? template.nodeType : NodeType.assignment;
    } else {
      return NodeType.assignment;
    }
  },
);

export const selectTemplatesPopoverHasTemplateChanged = createSelector(
  selectTemplatesPopoverState,
  state => state.hasTemplateChanged,
);

export const selectTemplatesPopoverTemplateIds = createSelector(
  selectTemplatesPopoverServerNodeType,
  selectNodeTemplateIdsByNodeType,
  (serverNodeType, idsByServerNodeType) => {
    return idsByServerNodeType[serverNodeType] ? idsByServerNodeType[serverNodeType] : [];
  },
);

export const selectTemplatesPopoverTemplateList = createSelector(
  selectTemplatesPopoverTemplateIds,
  selectNodeTemplatesById,
  (ids, byId) => {
    return ids.map(id => byId[id]);
  },
);

export const selectTemplatesPopoverSelectedTemplate = createSelector(
  selectTemplatesPopoverSelectedTemplateId,
  selectTemplatesPopoverTemplateList,
  (selectedTemplateId, templateList) => {
    if (!selectedTemplateId) {
      return templateList.filter(template => template.isDefault)[0];
    } else {
      return templateList.filter(template => template.id === selectedTemplateId)[0];
    }
  },
);

export const selectTemplatesPopoverSelectedTemplateWidgets = createSelector(
  selectTemplatesPopoverSelectedTemplate,
  selectTemplateWidgetsByTemplateId,
  selectTemplateWidgetData,
  (template, templateWidgetsByTemplateId, { widgetsById, templateWidgetsById }) => {
    if (
      template &&
      templateWidgetsByTemplateId[template.id] &&
      templateWidgetsByTemplateId[template.id].length > 0
    ) {
      return templateWidgetsByTemplateId[template.id]
        .filter(templateWidget => widgetsById[templateWidget.id] != null)
        .map(templateWidget => {
          return { ...templateWidget, widget: widgetsById[templateWidget.id] };
        });
    } else {
      return [];
    }
  },
);

export const selectTemplatesPopoverSelectedTemplateCalculationWidgetViewModels = () =>
  createSelector(
    selectTemplatesPopoverSelectedTemplate,
    selectWidgetsById,
    selectTemplateWidgetsByTemplateId,
    (template, widgetsById, widgetsByTemplateId) => {
      if (
        template &&
        widgetsByTemplateId[template.id] &&
        widgetsByTemplateId[template.id].length > 0
      ) {
        return widgetsByTemplateId[template.id]
          .filter(
            templateWidget =>
              widgetsById[templateWidget.id] != null &&
              widgetsById[templateWidget.id].nodeType === NodeType.assignment &&
              widgetsById[templateWidget.id].widgetType === WidgetType.calculationJoin,
          )
          .map(calcWidget => {
            return {
              ...calcWidget,
              widget: widgetsById[calcWidget.id],
              // Widget A&B are not TemplateWidgets on the server so we'll normalise them here
              widgetA: {
                ...{ ...calcWidget, id: widgetsById[calcWidget.id].calculationAWidgetId },
                widget: widgetsById[widgetsById[calcWidget.id].calculationAWidgetId],
              },
              widgetB: {
                ...{ ...calcWidget, id: widgetsById[calcWidget.id].calculationBWidgetId },
                widget: widgetsById[widgetsById[calcWidget.id].calculationBWidgetId],
              },
            };
          });
      } else {
        return [];
      }
    },
  );

export const selectTemplateCalculationWidgetViewModelsByTemplate = (template: NodeTemplateModel) =>
  createSelector(
    selectWidgetsById,
    selectTemplateWidgetsByTemplateId,
    (widgetsById, widgetsByTemplateId) => {
      if (
        template &&
        widgetsByTemplateId[template.id] &&
        widgetsByTemplateId[template.id].length > 0
      ) {
        return widgetsByTemplateId[template.id]
          .filter(
            templateWidget =>
              widgetsById[templateWidget.id] != null &&
              widgetsById[templateWidget.id].nodeType === NodeType.assignment &&
              widgetsById[templateWidget.id].widgetType === WidgetType.calculationJoin,
          )
          .map(calcWidget => {
            return {
              ...calcWidget,
              widget: widgetsById[calcWidget.id],
              // Widget A&B are not TemplateWidgets on the server so we'll normalise them here
              widgetA: {
                ...{ ...calcWidget, id: widgetsById[calcWidget.id].calculationAWidgetId },
                widget: widgetsById[widgetsById[calcWidget.id].calculationAWidgetId],
              },
              widgetB: {
                ...{ ...calcWidget, id: widgetsById[calcWidget.id].calculationBWidgetId },
                widget: widgetsById[widgetsById[calcWidget.id].calculationBWidgetId],
              },
            };
          });
      } else {
        return [];
      }
    },
  );

export const selectTemplatesPopoverSelectedTemplateGroups = createSelector(
  selectTemplatesPopoverSelectedTemplate,
  selectWorkspaceGroupsByNodeTemplateId,
  (template, groupsByNodeTemplateId) => {
    return groupsByNodeTemplateId[template?.id] || [];
  },
);

export const selectTemplateGroupsByTemplate = (template: NodeTemplateModel) =>
  createSelector(selectWorkspaceGroupsByNodeTemplateId, groupsByNodeTemplateId => {
    return groupsByNodeTemplateId[template?.id] || [];
  });

const selectTemplatesPopoverSelectedTemplateTagGroupsFactory = (groupType: WorkspaceGroupType) => {
  return createSelector(
    selectTemplatesPopoverSelectedTemplateGroups,
    selectTemplateTagsByGroupId,
    selectTagsById,
    (groups: WorkspaceGroupModel[], templateTagsByGroupId, tagsById): WorkspaceGroupModel[] => {
      return groups
        .filter(group => group != null && group.type === groupType)
        .map(group => {
          return {
            ...group,
            tags:
              (templateTagsByGroupId[group.id] &&
                templateTagsByGroupId[group.id].map((templateTag: TemplateTagModel) => {
                  return {
                    ...templateTag,
                    tag: tagsById[templateTag.id],
                  };
                })) ||
              [],
          };
        });
    },
  );
};

const selectTemplateTagGroupsFactoryByTemplate = (
  template: NodeTemplateModel,
  groupType: WorkspaceGroupType,
) => {
  return createSelector(
    selectTemplateGroupsByTemplate(template),
    selectTemplateTagsByGroupId,
    selectTagsById,
    (groups: WorkspaceGroupModel[], templateTagsByGroupId, tagsById): WorkspaceGroupModel[] => {
      return groups
        .filter(group => group != null && group.type === groupType)
        .map(group => {
          return {
            ...group,
            tags:
              (templateTagsByGroupId[group.id] &&
                templateTagsByGroupId[group.id].map((templateTag: TemplateTagModel) => {
                  return {
                    ...templateTag,
                    tag: tagsById[templateTag.id],
                  };
                })) ||
              [],
          };
        });
    },
  );
};

export const selectTemplatesPopoverSelectedTemplatePrimaryTagGroups = () =>
  selectTemplatesPopoverSelectedTemplateTagGroupsFactory(WorkspaceGroupType.primaryTag);

export const selectTemplatePrimaryTagGroupsByTemplate = (template: NodeTemplateModel) =>
  selectTemplateTagGroupsFactoryByTemplate(template, WorkspaceGroupType.primaryTag);

export const selectTemplatesPopoverSelectedTemplateStampGroups = () =>
  selectTemplatesPopoverSelectedTemplateTagGroupsFactory(WorkspaceGroupType.stamp);

export const selectTemplateStampGroupsByTemplate = (template: NodeTemplateModel) =>
  selectTemplateTagGroupsFactoryByTemplate(template, WorkspaceGroupType.stamp);

const selectTemplatesPopoverSelectedTemplateTagGroupViewModelsFactory = tagGroupsSelector => {
  return createSelector(
    tagGroupsSelector,
    selectTagsById,
    (groups: WorkspaceGroupModel[], tagsById): WorkspaceGroupModel[] => {
      return groups.map(group => {
        return {
          ...group,
          tags: group.tags.map((templateTag: TemplateTagModel) => {
            return {
              ...templateTag,
              tag: tagsById[templateTag.id],
            };
          }),
        };
      });
    },
  );
};

export const selectTemplatesPopoverSelectedTemplatePrimaryTagGroupViewModels = () =>
  selectTemplatesPopoverSelectedTemplateTagGroupViewModelsFactory(
    selectTemplatesPopoverSelectedTemplatePrimaryTagGroups(),
  );

export const selectPrimaryTagGroupViewModelsByTemplate = (template: NodeTemplateModel) =>
  selectTemplatesPopoverSelectedTemplateTagGroupViewModelsFactory(
    selectTemplatePrimaryTagGroupsByTemplate(template),
  );

export const selectTemplatesPopoverSelectedTemplateStampGroupViewModels = () =>
  selectTemplatesPopoverSelectedTemplateTagGroupViewModelsFactory(
    selectTemplatesPopoverSelectedTemplateStampGroups(),
  );

export const selectStampGroupViewModelsByTemplate = (template: NodeTemplateModel) =>
  selectTemplatesPopoverSelectedTemplateTagGroupViewModelsFactory(
    selectTemplateStampGroupsByTemplate(template),
  );

export const getTemplatesPopoverSelectedTemplateTagGroupViewModelsSelector = (
  groupType: WorkspaceGroupType,
) => {
  if (groupType === WorkspaceGroupType.primaryTag) {
    return selectTemplatesPopoverSelectedTemplatePrimaryTagGroupViewModels();
  } else if (groupType === WorkspaceGroupType.stamp) {
    return selectTemplatesPopoverSelectedTemplateStampGroupViewModels();
  }
};

export const getTagGroupViewModelsSelectorByTemplate = (
  template: NodeTemplateModel,
  groupType: WorkspaceGroupType,
) => {
  if (groupType === WorkspaceGroupType.primaryTag) {
    return selectPrimaryTagGroupViewModelsByTemplate(template);
  } else if (groupType === WorkspaceGroupType.stamp) {
    return selectTemplatesPopoverSelectedTemplateStampGroupViewModels();
  }
};

export const selectTemplatesPopoverSelectedTemplateAssignmentWidgetGroups = createSelector(
  selectTemplatesPopoverSelectedTemplateGroups,
  selectTemplateWidgetsByGroupId,
  selectWidgetsById,
  (groups, templateWidgetsByGroupId, widgetsById) => {
    const result = groups
      .filter(group => group?.type === WorkspaceGroupType.checklist)
      .map(group => {
        return {
          ...group,
          widgets: (templateWidgetsByGroupId[group.id] || [])
            .map(templateWidget => {
              return {
                ...templateWidget,
                widget: widgetsById[templateWidget.id],
              };
            })
            .sort(NodeUtils.sortByIndex),
        };
      });
    return result;
  },
);

export const selectTemplatesAssignmentWidgetGroupsByTemplateGroup = (
  groups: WorkspaceGroupModel[],
) =>
  createSelector(
    selectTemplateWidgetsByGroupId,
    selectWidgetsById,
    (templateWidgetsByGroupId, widgetsById) => {
      const result = groups
        .filter(group => group?.type === WorkspaceGroupType.checklist)
        .map(group => {
          return {
            ...group,
            widgets: (templateWidgetsByGroupId[group.id] || [])
              .map(templateWidget => {
                return {
                  ...templateWidget,
                  widget: widgetsById[templateWidget.id],
                };
              })
              .sort(NodeUtils.sortByIndex),
          };
        });
      return result;
    },
  );

export const selectTemplatesPopoverSelectedTemplateTags = createSelector(
  selectTemplatesPopoverSelectedTemplate,
  selectTagsById,
  selectTemplateTagsByTemplateByType,
  (template, tagsById, templateTagsByTemplateByType): TemplateTagModel[] => {
    if (
      template &&
      templateTagsByTemplateByType[template.id] &&
      templateTagsByTemplateByType[template.id][TagType.general] &&
      templateTagsByTemplateByType[template.id][TagType.general].length
    ) {
      return templateTagsByTemplateByType[template.id][TagType.general]
        .filter(tag => !!tag)
        .map((templateTag: TemplateTagModel) => {
          return {
            ...templateTag,
            tag: tagsById[templateTag.id],
          };
        });
    } else {
      return [];
    }
  },
);

export const selectTemplatesPopoverSelectedTemplateGeneralTags = createSelector(
  selectTemplatesPopoverSelectedTemplateTags,
  tags => tags.filter(tag => tag.tagType === TagType.general),
);

export const selectTemplatesPopoverSelectedTemplatePrimaryTags = createSelector(
  selectTemplatesPopoverSelectedTemplateTags,
  tags => tags.filter(tag => tag.tagType === TagType.primary),
);

export const selectTemplatesPopoverSelectedTemplateStamps = createSelector(
  selectTemplatesPopoverSelectedTemplateTags,
  tags => tags.filter(tag => tag.tagType === TagType.stamp),
);

/*export const getTemplatesPopoverSelectedTemplateTagsSelector = (tagType: TagType) => {
  if (tagType === TagType.primary) {
    return selectTemplatesPopoverSelectedTemplatePrimaryTags;
  } else if (tagType === TagType.general) {
    return selectTemplatesPopoverSelectedTemplateGeneralTags;
  } else {
    return selectTemplatesPopoverSelectedTemplateStamps;
  }
};*/

export const selectTemplatesPopoverTemplateTypeViewModels = createSelector(
  selectNodeTemplateIdsByNodeType,
  selectNodeTemplatesById,
  (idsByServerNodeType, nodeTemplatesById): TemplateTypeViewModel[] => {
    const viewModels: TemplateTypeViewModel[] = (templateNodeTypeOptions as {
      id: number;
      label: string;
    }[]).map(option => {
      return {
        nodeType: option.id,
        title: option.label,
        templates: (idsByServerNodeType[option.id] || []).map(id => nodeTemplatesById[id]),
      };
    });
    return viewModels;
  },
);

export const selectTemplatesPopoverAllowedTemplates = createSelector(
  selectTemplatesPopoverSelectedTemplate,
  selectNodeTemplatesById,
  (selectedTemplate: NodeTemplateModel, byId): NodeTemplateModel[] => {
    return (selectedTemplate?.allowedTemplateIds || [])
      .filter(id => byId[id] != null)
      .map(id => byId[id]);
  },
);

export const selectTemplatesByAllowedTemplates = (selectedTemplate: NodeTemplateModel) =>
  createSelector(selectNodeTemplatesById, (byId): NodeTemplateModel[] => {
    return (selectedTemplate?.allowedTemplateIds || [])
      .filter(id => byId[id] != null)
      .map(id => byId[id]);
  });

export const selectTemplatesPopoverElementTemplateViewModelsDataOfSelectedTemplate = createSelector(
  selectElementTemplateViewModelsDataByProjectAndAssignmentTemplateId,
  selectTemplatesPopoverSelectedTemplate,
  (dataByTemplateId, selectedTemplate) => {
    return dataByTemplateId[selectedTemplate?.id]
      ? dataByTemplateId[selectedTemplate?.id]
      : {
          defaultElementTemplateViewModels: [],
          assetTemplateViewModels: [],
        };
  },
);

const selectTemplatesManagementDefaultUrlFactory = defaultTemplateIdSelector => {
  return createSelector(
    defaultTemplateIdSelector,
    selectWorkspacesCurrentSlug,
    (templateId, slug) => {
      return `/ws/${slug}/settings/node-templates/${templateId || templateDefaultKey}`;
    },
  );
};

export const selectAssignmentTemplatesManagementDefaultUrl = selectTemplatesManagementDefaultUrlFactory(
  selectDefaultAssignmentTemplateId,
);

export const selectProjectTemplatesManagementDefaultUrl = selectTemplatesManagementDefaultUrlFactory(
  selectDefaultProjectTemplateId,
);

export const selectAssetTemplatesManagementDefaultUrl = selectTemplatesManagementDefaultUrlFactory(
  selectDefaultAssetTemplateId,
);

export const selectElementTemplatesManagementDefaultUrl = selectTemplatesManagementDefaultUrlFactory(
  selectDefaultElementTemplateId,
);

export const getTemplateManagementUrl = (folderType: string, subType: string) => {
  if (folderType === 'project') {
    return selectProjectTemplatesManagementDefaultUrl;
  } else if (folderType === 'element') {
    if (subType === 'asset') {
      return selectAssetTemplatesManagementDefaultUrl;
    } else {
      return selectElementTemplatesManagementDefaultUrl;
    }
  } else {
    console.error('Unknown folderType: ', folderType);
  }
};
