import { Pipe, PipeTransform } from '@angular/core';
import { TableCell, TableColumn, TableColumnAlignment } from '../table.common';
import { TableEditor } from '../table-editors/table-editors.common';

@Pipe({
  name: 'zTableColumnClasses',
  pure: false,
})
export class TableColumnClassesPipe implements PipeTransform {
  transform(cell: TableCell | TableColumn, column: TableColumn): string {
    const classes: string[] = [];

    if (cell.hasOwnProperty('value') === true && column.editable === true) {
      classes.push(`z-table__col--editable`);
      if (!(cell as TableCell).value) {
        classes.push(`z-table__col--empty`);
      }
    }

    if (column != null) {
      if (column.colSpan > 1) {
        classes.push(`z-table__col--span-${column.colSpan}`);
      }
      if (column.collapsed === true) {
        classes.push('z-table__col--hidden');
      }
      if (column.hideSmall === true) {
        classes.push('hide-on-sm');
      }
      if (column.editor === TableEditor.Spacer) {
        classes.push(`z-table__col--spacer`);
      }
    }

    switch (cell.alignment) {
      case TableColumnAlignment.Center:
        classes.push('z-table__col--center');
        break;
      case TableColumnAlignment.Right:
        classes.push('z-table__col--right');
        break;
      case TableColumnAlignment.Left:
      default:
        classes.push('z-table__col--left');
        break;
    }

    if (column?.fixedWidth) {
      classes.push('z-table__col--fixed-width');
    }

    return classes.join(' ');
  }
}
