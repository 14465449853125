import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { ErrorsActions } from '../errors';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OverlayPopoverService } from '../../shared/components/overlay-popover/overlay-popover.service';
import { AlertService } from '../../shared/components/alert/alert.service';
import { AuthActions } from '../auth';
import { Store } from '@ngrx/store';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class ErrorsEffects {
  constructor(
    @Inject(PLATFORM_ID) protected platformId,
    private actions$: Actions,
    private router: Router,
    private modalService: NgbModal,
    private popoverService: OverlayPopoverService,
    private alertService: AlertService,
    private store: Store,
  ) {}

  goToErrorPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ErrorsActions.goToErrorPage),
        tap(action => {
          const errorMessage = action.error.error
            ? action.error.error.message || action.error.error.messages
            : action.error.message;

          if (isPlatformBrowser(this.platformId) === true && window.navigator.onLine === false) {
            console.info('Network connection lost,', errorMessage);
            this.alertService.error('Network connection lost. Please try again.');
            return;
          }

          if (action.error.status === 404 || action.error.status === 412) {
            console.info('API Form validation error', errorMessage);
            this.alertService.error(errorMessage);
            return;
          }

          if (action.error.status === 401 || action.error.status === 403) {
            console.info('Go to login page', errorMessage);
            this.modalService.dismissAll('Error happens');
            this.popoverService.close();
            this.store.dispatch(AuthActions.logoutRequest()); // need to logout to clean up the Auth data in store
            this.router.navigate(['/public/login']);
            return;
          }

          console.info('Go to error page', errorMessage);
          this.modalService.dismissAll('Error happens');
          this.popoverService.close();
          this.router.navigate(['/public/error-page']);
        }),
      ),
    { dispatch: false },
  );
}
