import { OptionModel } from '../models/option.model';

export enum CalculationMethodType {
  sum = 1,
  subtract = 2,
  multiply = 3,
  divide = 4,
  negativeMultiple = 5,
  negativeDivide = 6,
}

export const availableCalculationMethodOptions: OptionModel[] = [
  {
    id: CalculationMethodType.sum,
    label: 'A + B',
  },
  {
    id: CalculationMethodType.subtract,
    label: 'A - B',
  },
  {
    id: CalculationMethodType.multiply,
    label: 'A x B',
  },
  {
    id: CalculationMethodType.negativeMultiple,
    label: '- (A x B)',
  },
  {
    id: CalculationMethodType.divide,
    label: 'A / B',
  },
  {
    id: CalculationMethodType.negativeDivide,
    label: '- (A / B)',
  },
];
