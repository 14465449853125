import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { selectChatChannelViewModels } from '../../../../store/chats/chats.selectors';
import { ChatChannelViewModel } from '../../../../core/models/chat-channel-view.model';
import { ChatbarActions } from '../../../../store/chatbar';
import { ChatsActions } from '../../../../store/chats';
import { ChannelAction, ChannelType } from '../../../../core/constants/channel-type';
import { ChatChannelActionModel } from '../../../../core/models/chat-channel.model';
import { FormControl } from '@angular/forms';
import { NoDataTypeIds } from '../../../../core/constants/no-data-settings';

@Component({
  selector: 'app-chat-channels',
  templateUrl: './chat-channels.component.html',
  styleUrls: ['./chat-channels.component.scss'],
})
export class ChatChannelsComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  pinnedChannels: ChatChannelViewModel[] = [];
  newEntryChannels: ChatChannelViewModel[] = [];
  otherAllChannels: ChatChannelViewModel[] = [];
  allChannels: ChatChannelViewModel[] = [];
  searchControl = new FormControl('');
  public noDataTypeIds = NoDataTypeIds;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.subscription.add(
      this.store.pipe(select(selectChatChannelViewModels)).subscribe(channels => {
        this.allChannels = channels;
        this.pushChannelsToArrays(channels);
      }),
    );
    this.onSearchCtrlChange();
  }

  onSearchCtrlChange(): void {
    this.searchControl.valueChanges.subscribe(searchValue => {
      if (searchValue) {
        const filteredChannels = this.allChannels.filter(item => {
          let title = '';
          if (item.channelType === ChannelType.workspace) {
            title = `${item.workspace?.title}`;
          } else if (item.channelType === ChannelType.node) {
            title = `${item.node?.title}`;
          }
          return title?.toLowerCase().includes(searchValue.toLowerCase());
        });

        this.pushChannelsToArrays(filteredChannels);
      } else {
        this.pushChannelsToArrays(this.allChannels);
      }
    });
  }

  pushChannelsToArrays(channels: ChatChannelViewModel[]): void {
    this.resetChannelArrays();
    channels?.forEach((item: ChatChannelViewModel) => {
      if (item?.channelType === ChannelType.workspace) {
        this.pinnedChannels.push(item);
      } else if (item?.unreadComments > 0) {
        this.newEntryChannels.push(item);
      } else {
        this.otherAllChannels.push(item);
      }
    });
  }

  resetChannelArrays(): void {
    this.pinnedChannels = [];
    this.newEntryChannels = [];
    this.otherAllChannels = [];
  }

  onChannelActionChange(event: ChatChannelActionModel): void {
    const channel = event?.channel;
    if (event?.action === ChannelAction.open) {
      if (channel.channelType === ChannelType.node) {
        this.store.dispatch(
          ChatbarActions.openNodeChannelRequest({ nodeId: channel.nodeId, hideChatModal: true }),
        );
      } else if (channel.channelType === ChannelType.workspace) {
        this.store.dispatch(
          ChatbarActions.openCurrentWorkspaceChannelRequest({ hideChatModal: true }),
        );
      }
    } else if (event?.action === ChannelAction.delete) {
      this.store.dispatch(ChatsActions.deleteNodeChannelRequest({ channelId: channel.id }));
    }
  }

  trackBy(index, item) {
    return item.id;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
