import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { IconType } from '../../../core/constants/icon-type';
import { NodeTemplateModel } from '../../../core/models/node-template.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GroupEditComponent } from '../../../shared/modals/group-edit/group-edit.component';
import { WorkspaceGroupType } from '../../../core/constants/workspace-group-type';
import { WorkspaceGroupModel } from '../../../core/models/workspace-group.model';
import { TemplatesActions } from '../../../store/templates';

@Component({
  selector: 'app-template-checklists',
  templateUrl: './template-checklists.component.html',
  styleUrls: ['./template-checklists.component.scss'],
})
export class TemplateChecklistsComponent implements OnInit, OnDestroy {
  private readonly subscription = new Subscription();

  public readonly IconType = IconType;

  @Input() selectedTemplate: NodeTemplateModel;

  @Input() groups: WorkspaceGroupModel[];

  constructor(private store: Store, private modalService: NgbModal) {}

  ngOnInit(): void {
    //
  }

  showChecklistCreatePage(origin) {
    const modelRef = this.modalService.open(GroupEditComponent, {
      size: 'sm',
      backdrop: 'static',
    });
    modelRef.componentInstance.groupType = WorkspaceGroupType.checklist;
    modelRef.componentInstance.templateId = this.selectedTemplate?.id;
  }

  changeSwitch(): void {
    let templateProps = {};
    templateProps = {
      allowForms: !this.selectedTemplate?.allowForms,
    };
    this.store.dispatch(
      TemplatesActions.updateNodeTemplateRequest({
        templateId: this.selectedTemplate.id,
        templateProps,
      }),
    );
  }

  trackBy(index, group) {
    return group.id;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
