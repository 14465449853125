import { Pipe, PipeTransform } from '@angular/core';
import { WorkspaceGroupModel } from '../../core/models/workspace-group.model';

@Pipe({
  name: 'linksCountGroups',
})
export class LinksCountGroupsPipe implements PipeTransform {
  transform(groups: WorkspaceGroupModel[]): number {
    // go through the all groups and perform sum of the links count
    return groups.reduce((r, d) => r + d.links?.length, 0);
  }
}
