import { WorkspaceTemplateModel } from '../../core/models/workspace-template.model';

export class WorkspaceTemplatesState {
  byId: { [id: number]: WorkspaceTemplateModel };
  ids: number[];
  searchKeyword: string;
}

export const initialWorkspaceTemplatesState: WorkspaceTemplatesState = {
  byId: {},
  ids: [],
  searchKeyword: undefined,
};
