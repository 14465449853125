import { NodeMetasById } from '../../core/models/node-meta';

const expandFolderMetaExpansion = (nodeId: number, nodeMetasById: NodeMetasById) => {
  return {
    ...nodeMetasById,
    [nodeId]: {
      ...nodeMetasById[nodeId],
      isExpanded: true,
    },
  };
};

const toggleFolderTreeMetaExpansion = (nodeId: number, nodeMetasById: NodeMetasById) => {
  const isExpanded = !(nodeMetasById[nodeId] && nodeMetasById[nodeId].isExpanded);
  return {
    ...nodeMetasById,
    [nodeId]: {
      ...nodeMetasById[nodeId],
      isExpanded: isExpanded,
    },
  };
};

export const workspaceUserMetaUtils = {
  expandFolderMetaExpansion,
  toggleFolderTreeMetaExpansion,
  // toggleFolderTreeMetaHidden,
  // showFolderTree,
};
