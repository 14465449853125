import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserModel } from '../../../core/models/user.model';
import { Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { selectCurrentWorkspaceUsersSortedByActiveState } from '../../../store/workspace-users/workspace-users.selectors';

@Component({
  selector: 'app-workspace-users',
  templateUrl: './workspace-users.component.html',
  styleUrls: ['./workspace-users.component.scss'],
})
export class WorkspaceUsersComponent implements OnInit, OnDestroy {
  private readonly subscription = new Subscription();
  public readonly MAX_DISPLAY = 3;

  public users: UserModel[];

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.subscription.add(
      this.store.pipe(select(selectCurrentWorkspaceUsersSortedByActiveState)).subscribe(users => {
        this.users = users;
      }),
    );
  }

  trackBy(index, user: UserModel) {
    return user?.id;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
