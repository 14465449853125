import { Action, createReducer, on } from '@ngrx/store';
import { initialTemplateWidgetsState, TemplateWidgetsState } from './template-widgets.state';
import { TemplatesActions } from '../templates';
import { TemplateWidgetsActions } from './index';
import { NodeTemplateWidgetModel } from '../../core/models/node-template-widget.model';

const addTemplateWidgetToState = (
  state,
  templateWidget: NodeTemplateWidgetModel,
): TemplateWidgetsState => {
  return {
    ...(state || {}),

    templateWidgetsById: {
      ...state.templateWidgetsById,
      [templateWidget.joinId]: templateWidget,
    },

    templateWidgetsByTemplateId: {
      ...state.templateWidgetsByTemplateId,
      [templateWidget.nodeTemplateId]: [
        ...(state.templateWidgetsByTemplateId[templateWidget.nodeTemplateId] || []).filter(
          widget => widget.joinId !== templateWidget.joinId,
        ),
        templateWidget,
      ],
    },

    templateWidgetsByGroupId: {
      ...state.templateWidgetsByGroupId,
      [templateWidget.groupId || 0]: [
        ...(state.templateWidgetsByGroupId[templateWidget.groupId || 0] || []).filter(
          widget => widget.joinId !== templateWidget.joinId,
        ),
        templateWidget,
      ],
    },
  };
};

const removeTemplateWidgetFromState = (
  state,
  templateWidget: NodeTemplateWidgetModel,
): TemplateWidgetsState => {
  return {
    ...(state || {}),

    templateWidgetsById: {
      ...state.templateWidgetsById,
      [templateWidget.joinId]: undefined,
    },

    templateWidgetsByTemplateId: {
      ...state.templateWidgetsByTemplateId,
      [templateWidget.nodeTemplateId]: [
        ...(state.templateWidgetsByTemplateId[templateWidget.nodeTemplateId] || []).filter(
          widget => widget.joinId !== templateWidget.joinId,
        ),
      ],
    },

    templateWidgetsByGroupId: {
      ...state.templateWidgetsByGroupId,
      [templateWidget.groupId || 0]: [
        ...(state.templateWidgetsByGroupId[templateWidget.groupId || 0] || []).filter(
          widget => widget.joinId !== templateWidget.joinId,
        ),
      ],
    },
  };
};

const reducer = createReducer(
  initialTemplateWidgetsState,
  on(
    TemplatesActions.refreshNodeTemplatesSuccess,
    (state, { nodeTemplates }): TemplateWidgetsState => {
      let newState = { ...initialTemplateWidgetsState };
      nodeTemplates.forEach(template => {
        template.widgets.forEach(widget => {
          newState = addTemplateWidgetToState(newState, widget);
        });
      });
      return newState;
    },
  ),

  on(
    TemplateWidgetsActions.addTemplateWidgetsSuccess,
    (state, { templateWidgets, groupId, replace }) => {
      let newState = { ...state };
      if (replace === true) {
        // todo: What if not in Group?
        const groupWidgets = state.templateWidgetsByGroupId[groupId] || [];
        groupWidgets.forEach(tt => {
          newState = removeTemplateWidgetFromState(newState, tt);
        });
      }

      if (templateWidgets.length > 0) {
        newState = templateWidgets.reduce((newState, templateWidget) => {
          return addTemplateWidgetToState(newState, templateWidget);
        }, newState);
      }

      return newState;
    },
  ),

  on(TemplateWidgetsActions.removeTemplateWidgetRequest, (state, { templateId, joinId }) => {
    let templateWidget = state.templateWidgetsById[joinId] || null;
    if (templateWidget != null) {
      return removeTemplateWidgetFromState(state, templateWidget);
    }
    return state;
  }),

  on(TemplateWidgetsActions.removeTemplateWidgetsByWidgetId, (state, { widgetId }) => {
    let widgetsToRemove = Object.keys(state.templateWidgetsById)
      .filter(key => {
        return state.templateWidgetsById[key] && state.templateWidgetsById[key].id === widgetId;
      })
      .map(key => state.templateWidgetsById[key]);

    if (widgetsToRemove.length) {
      return widgetsToRemove.reduce((newState, widget) => {
        return removeTemplateWidgetFromState(newState, widget);
      }, state);
    }
    return state;
  }),

  on(
    TemplateWidgetsActions.createAndAddAssignmentChecklistWidgetSuccess,
    (state, { templateWidget, widget }) => {
      return addTemplateWidgetToState(state, templateWidget);
    },
  ),

  on(TemplateWidgetsActions.sortTemplateWidgetsRequest, (state, { joinIds }) => {
    let idx = 0;
    return joinIds.reduce((state, id) => {
      return addTemplateWidgetToState(state, {
        ...state.templateWidgetsById[id],
        sortIndex: idx++,
      });
    }, state);
  }),

  on(
    TemplateWidgetsActions.removeTemplateGroupWidgetAndWidgetItselfRequest,
    (state, { widgetId, groupId }): TemplateWidgetsState => {
      // const widget = state.templateWidgetsById[widgetId];
      return {
        ...state,
        // templateWidgetsByTemplateId: {
        //   ...state.templateWidgetsByTemplateId,
        //   [widget.nodeTemplateId]: state.templateWidgetsByTemplateId[widget?.nodeTemplateId].filter(
        //     id => id !== widgetId,
        //   ),
        // },
        templateWidgetsById: {
          ...state.templateWidgetsById,
          [widgetId]: undefined,
        },
        templateWidgetsByGroupId: {
          ...state.templateWidgetsByGroupId,
          [groupId]: state.templateWidgetsByGroupId[groupId].filter(item => item?.id !== widgetId),
        },
      };
    },
  ),
);

export function templateWidgetsReducer(state: TemplateWidgetsState, action: Action) {
  return reducer(state, action);
}
