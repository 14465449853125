import { Component, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../store';
import { TagModel } from '../../../core/models/tag.model';
import { TagsActions, TagsSelectors } from '../../../store/tags';
import { TagsPopoverActions } from '../../../store/tags-popover';
import { TagsPopoverPage } from '../../../core/constants/tags-popover-page';
import { TagEditComponent } from '../tag-edit/tag-edit.component';
import { AbstractPickerComponent, ListItem } from '../_abstract-picker/abstract-picker.component';

@Component({
  templateUrl: './tags-picker.component.html',
  styleUrls: ['./tags-picker.component.scss'],
})
export class TagsPickerComponent extends AbstractPickerComponent<TagModel> implements OnDestroy {
  @Input()
  public allowEdit: boolean;

  @Input()
  public allowNew: boolean;

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private store: Store<AppState>,
  ) {
    super(activeModal);

    this.allowEdit = true;
    this.allowNew = true;

    this.subscription.add(
      this.store.pipe(select(TagsSelectors.selectAllTagsWithKeyword)).subscribe(tags => {
        this.updateList(tags);
      }),
    );
  }

  public search(keywords) {
    this.keywords = keywords;
    this.store.dispatch(TagsActions.updateSearchKeywordRequest({ searchKeyword: this.keywords }));
  }

  public isEqual(itemA: TagModel, itemB: TagModel): boolean {
    return itemA.id === itemB.id;
  }

  public add() {
    this.modalService.open(TagEditComponent, {
      size: 'sm',
      backdrop: 'static',
    });
  }

  public edit(listItem: ListItem<TagModel>) {
    this.store.dispatch(
      TagsPopoverActions.showTagEditPage({
        tagId: listItem.model.id,
        tagType: null,
        previousPage: TagsPopoverPage.tags,
        templateId: null,
        nodeId: undefined,
      }),
    );
    this.modalService.open(TagEditComponent, {
      size: 'sm',
      backdrop: 'static',
    });
  }

  public trackBy(index, item: ListItem<TagModel>) {
    return item.model.id;
  }

  public ngOnDestroy(): void {
    this.store.dispatch(TagsPopoverActions.resetTagEditPage());
    this.store.dispatch(TagsActions.updateSearchKeywordRequest({ searchKeyword: '' }));
    super.ngOnDestroy();
  }
}
