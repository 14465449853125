import { AppState } from '../index';
import { createSelector } from '@ngrx/store';
import { RouteParamKey } from './route-param-key';
import { getSelectors } from '@ngrx/router-store';

const selectRouterState = (state: AppState) => state.router;

export const {
  selectCurrentRoute, // select the current route
  selectFragment, // select the current route fragment
  selectQueryParams, // select the current route query params
  selectQueryParam, // factory function to select a query param
  selectRouteParams, // select the current route params
  selectRouteParam, // factory function to select a route param
  selectRouteData, // select the current route data
  selectUrl, // select the current url
} = getSelectors(selectRouterState);

export const selectRouteParamFactory = (paramKey: string) => {
  return createSelector(selectRouteParams, params => {
    return params?.[paramKey];
  });
};

export const selectRouterInfoAssignmentLayout = selectRouteParamFactory(
  RouteParamKey.assignmentLayout,
);

export const selectRouterInfoSharedViewHash = selectRouteParamFactory(RouteParamKey.sharedViewHash);

export const selectRouterInfoTab = selectRouteParamFactory(RouteParamKey.tab);

export const selectQueryParamFactory = (paramKey: string) => {
  return createSelector(selectQueryParams, params => {
    return params?.[paramKey];
  });
};
