import { Component } from '@angular/core';
import { TableComponent } from '../../table.component';
import { AbstractTableEditableCellComponent } from '../abstract-table-editable-cell.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TagsPickerComponent } from '../../../modals/tags-picker/tags-picker.component';
import { TagModel } from '../../../../core/models/tag.model';
import { NodesActions } from '../../../../store/nodes';
import { TagType } from '../../../../core/constants/tag-type';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store';
import { WorkspaceGroupModel } from '../../../../core/models/workspace-group.model';
import { NodeModel } from '../../../../core/models/node.model';

@Component({
  selector: 'app-z-table-editable-assignment-tag',
  templateUrl: './table-editable-assignment-tag.component.html',
  styleUrls: ['./table-editable-assignment-tag.component.scss'],
})
export class TableEditableAssignmentTagComponent extends AbstractTableEditableCellComponent {
  constructor(
    public table: TableComponent,
    private modalService: NgbModal,
    private store: Store<AppState>,
  ) {
    super(table);
  }

  public onStartEdit() {
    super.onStartEdit();

    const tag = this.cell.value?.tag as TagModel;
    const assignment = this.options?.assignment as NodeModel;
    const group = this.options?.group as WorkspaceGroupModel;

    const modelRef = this.modalService.open(TagsPickerComponent, {
      size: 'sm',
      backdrop: 'static',
    });
    modelRef.componentInstance.selected = tag ? [tag] : [];

    modelRef.componentInstance.whitelist = (assignment?.nodeTemplate?.tags || []).filter(
      t => t.groupId === group?.id,
    );

    modelRef.componentInstance.minSelection = 1;
    modelRef.componentInstance.maxSelection = 1;
    modelRef.componentInstance.allowEdit = false;
    modelRef.componentInstance.allowNew = false;
    modelRef.result
      .then((result: TagModel[]) => {
        if (result == null || result.length == 0) {
          return;
        }
        this.store.dispatch(
          NodesActions.applyGroupTagToAssignmentRequest({
            assignmentId: assignment?.id,
            tagId: result[0].id,
            tagType: TagType.primary,
            groupId: group?.id,
          }),
        );
        super.onEndEdit();
      })
      .catch(res => {});
  }
}
