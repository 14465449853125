import { Action, ActionReducer } from '@ngrx/store';

enum StorageProvider {
  session = 'sessionStorage',
  local = 'localStorage',
}
const STORAGE_PROVIDERS = [StorageProvider.session, StorageProvider.local];

type StorageState = {
  key: string;
  sessionOnly: boolean;
};

const storageKey = '__ap_storage__';
const stateKeys: StorageState[] = [
  { key: 'auth', sessionOnly: true },
  //{ key: 'workspaceUserMeta', sessionOnly: false },
];

function setSavedState(state: any, storageKey: string, provider: StorageProvider) {
  window[provider].setItem(storageKey, JSON.stringify(state));
}

function getSavedState(storageKey: string): any {
  return STORAGE_PROVIDERS.reduce((data, provider) => {
    return {
      ...data,
      ...JSON.parse(window[provider].getItem(storageKey)),
    };
  }, {});
}

export function storageMetaReducer<S, A extends Action = Action>(reducer: ActionReducer<S, A>) {
  let onInit = true;
  let supported = typeof window !== 'undefined' && typeof window.localStorage !== 'undefined';
  return function(state: S, action: A): S {
    // reduce the nextState.
    const nextState = reducer(state, action);
    if (supported === false) {
      return nextState;
    }

    // init the application state.
    if (onInit) {
      onInit = false;
      const savedState = getSavedState(storageKey);
      if (savedState) {
        return stateKeys.reduce((previousState, key) => {
          return {
            ...previousState,
            [key.key]: { ...previousState[key.key], ...savedState[key.key] },
          };
        }, nextState);
      } else {
        return nextState;
      }
    }

    // save the next state to the application local-storage.
    let localState = {};
    let sessionState = {};
    stateKeys.forEach(key => {
      if (key.sessionOnly === true) {
        sessionState = {
          ...sessionState,
          [key.key]: nextState[key.key],
        };
      } else {
        localState = {
          ...localState,
          [key.key]: nextState[key.key],
        };
      }
    });
    setSavedState(localState, storageKey, StorageProvider.local);
    setSavedState(sessionState, storageKey, StorageProvider.session);

    return nextState;
  };
}
