import { Component, OnChanges, SimpleChanges } from '@angular/core';
import { TableComponent } from '../../table.component';
import { AbstractTableEditableCellComponent } from '../abstract-table-editable-cell.component';
//import * as URLParse from 'url-parse';

@Component({
  selector: 'app-z-table-editable-url',
  templateUrl: './table-editable-url.component.html',
  styleUrls: ['./table-editable-url.component.scss'],
})
export class TableEditableUrlComponent extends AbstractTableEditableCellComponent
  implements OnChanges {
  public faviconUrl: string;

  constructor(public table: TableComponent) {
    super(table);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.cell.value) {
      const url = new URL(this.cell.value);
      this.faviconUrl = `https://www.google.com/s2/favicons?domain=${url.hostname}`;
    } else {
      this.faviconUrl = null;
    }
  }

  goToLink() {
    window.open(this.cell.value, '_blank');
  }
}
