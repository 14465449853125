import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-square-button-add',
  templateUrl: './square-button-add.component.html',
  styleUrls: ['./square-button-add.component.scss'],
})
export class SquareButtonAddComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
