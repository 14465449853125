<div class="popover-layout" [ngClass]="embedded ? 'popover-layout--embedded' : ''">
  <div class="popover-layout__header modal-header">
    <button
      type="button"
      *ngIf="previousPage"
      class="btn popover-layout__btn-previous fal fa-chevron-left"
      (click)="previous()"
    ></button>

    <h4 class="popover-layout__title modal-title">
      <span class="popover-layout__title-text">
        {{ title }}
      </span>
      <ng-container *ngTemplateOutlet="iconTemplateRef"> </ng-container>
      <app-save-status
        *ngIf="saveStatus"
        class="popover-layout__save-status"
        [saveStatus]="saveStatus"
      ></app-save-status>
    </h4>

    <button
      type="button"
      class="close"
      aria-label="Close"
      *ngIf="!hideCloseButton"
      (click)="close()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="popover-layout__body modal-body">
    <ng-content></ng-content>
  </div>

  <app-content-loader scope="modal"></app-content-loader>
</div>
