import { WorkspaceGroupModel } from '../../core/models/workspace-group.model';

export class WorkspaceGroupsState {
  byId: { [id: number]: WorkspaceGroupModel };
  byType: { [type: number]: WorkspaceGroupModel[] };
  byTemplate: { [templateId: number]: WorkspaceGroupModel[] };
  byNode: { [nodeId: number]: WorkspaceGroupModel[] };
  searchKeyword: string;
}

export const initialWorkspaceGroupsState: WorkspaceGroupsState = {
  byId: {},
  byType: {},
  byTemplate: {},
  byNode: {},
  searchKeyword: undefined,
};
