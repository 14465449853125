<ul *ngIf="template" class="popover-menu">
  <li class="popover-menu__item" (click)="makeDefault()" *ngIf="!template.isDefault">
    Make Default
  </li>
  <li class="popover-menu__item" (click)="copy()">Duplicate Template</li>
  <li
    class="popover-menu__item"
    (click)="saveAsLayoutTemplate()"
    *ngIf="template?.nodeType === NodeType.assignment"
  >
    Save as Layout Template
  </li>
  <li class="popover-menu__item" (click)="archive()" *ngIf="!template.isDefault">Archive</li>
</ul>
