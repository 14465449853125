export enum ChatbarPage {
  allChannels = 'allChannels',
  channel = 'channel',
}

export enum ChatActions {
  edit = 0,
  add,
  delete,
}
