<textarea
  rows="1"
  cols="10"
  #input
  type="text"
  class="z-table__input z-table__input--textarea"
  [ngClass]="{ 'z-table__input--empty': editable && !cell?.value }"
  [(ngModel)]="cell.value"
  [placeholder]="!cell.value ? 'Empty' : ''"
  [disabled]="!editable"
  (focus)="onStartEdit()"
  (blur)="onEndEdit()"
  (keyup.escape)="onEscapeKey()"
></textarea>
