import { Pipe, PipeTransform } from '@angular/core';
import { OptionModel } from '../../core/models/option.model';

@Pipe({
  name: 'selectedOption',
})
export class SelectedOptionPipe implements PipeTransform {
  transform(options: OptionModel[], selectedOptionId): OptionModel {
    let selectedOption;
    if (options) {
      selectedOption = options.filter(item => item.id === selectedOptionId)[0];
    }
    return selectedOption;
  }
}
