<div class="assignment-footer">
  <app-edit-status
    class="assignment-footer__status"
    [editedDate]="assignment.editedDate"
    [showIcon]="false"
  ></app-edit-status>

  <! -- [editedBy]="assignment.editedBy" -->

  <span class="assignment-footer__buttons">
    <span class="assignment-footer__indicators" *ngIf="!hideOverlayIcons">
      <span
        *ngIf="assignment.elementGroups?.length"
        class="assignment-footer__indicator fal fa-clipboard-list-check"
        [ngClass]="isElementGroupsHasNodes() ? 'assignment-footer__rates' : ''"
        (click)="openFormEditModal()"
      >
      </span>

      <span
        *ngIf="assignment?.reference?.rates?.length && assignment?.nodeTemplate?.allowRevenue"
        class="assignment-footer__indicator assignment-footer__rates fal fa-usd-circle"
        [ngClass]="{
          'assignment-footer__rates--not-active':
            !assignment.rateValues || !assignment.rateValues.length
        }"
        (click)="openRateValues('Revenue')"
      >
      </span>

      <span
        *ngIf="isRatesAvailableInAssetGroupNodes()"
        class="assignment-footer__indicator assignment-footer__rates fal fa-coins"
        [ngClass]="{
          'assignment-footer__rates--not-active': !costRatesAvailable()
        }"
        (click)="openRateValues('Cost')"
      >
      </span>

      <span
        #diaryIconOrigin
        class="assignment-footer__indicator assignment-footer__indicator--diary fal fa-message-pen"
        title="Diary"
        [ngClass]="{
          'assignment-footer__indicator--active': isChatChannelActive,
          invisible: hideDiaryIcon,
          'assignment-footer__indicator--readonly': readonly
        }"
        (click)="openChat(diaryIconOrigin)"
      >
        <app-active-indicator
          *ngIf="isChatChannelActive"
          class="assignment-footer__active-icon"
        ></app-active-indicator>
      </span>

      <!-- <span
         class="assignment-footer__indicator assignment-footer__indicator&#45;&#45;announcement fal fa-bullhorn"
         [ngClass]="{
           'assignment-footer__indicator&#45;&#45;active':
             assignment.reference.notifications || assignment.notifications
         }"
       >
         <app-active-indicator
           *ngIf="assignment.reference.notifications || assignment.notifications"
           class="assignment-footer__active-icon"
         ></app-active-indicator>
       </span>-->
    </span>
  </span>
</div>
<div
  class="assignment-footer-status justify-content-center"
  *ngIf="assignment?.stampTags?.length"
  [ngClass]="[
    'assignment-primary-tag--' + ((assignment?.stampTags)[0]?.tag?.colorTheme | colorString),
    readonly ? 'assignment-primary-tag--readonly' : ''
  ]"
>
  <div class="overlay"></div>

  <div class="content-wrapper text-center">
    <div class="stamp-name-icon" #stampIconLabel (click)="selectStamp()">
      <div class="assignment-primary-tag__icon assignment-status_icon fal mr-2">
        <span [ngClass]="'fa-' + ((assignment?.stampTags)[0]?.tag?.icon | iconString)"></span>
      </div>

      <span class="mr-2">{{ (assignment?.stampTags)[0]?.tag?.title }}</span>
      <!-- Indicator badge with tooltip -->
      <span *ngIf="assignment?.stampTags?.length > 1">
        +{{ assignment?.stampTags?.length - 1 }}
      </span>
    </div>

    <!-- <button
       type="button"
       class="btn btn-sm btn-link mb-2 py-0"
       [routerLink]="['/ws', currentWorkspace?.slug, 'content', 'schedule', 'tableV2']"
     >
       View All
     </button>-->
  </div>
</div>
