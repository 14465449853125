import { Action, createReducer, on } from '@ngrx/store';
import { WidgetsPopoverActions } from './index';
import { initialWidgetsPopoverState, WidgetsPopoverState } from './widgets-popover.state';

const reducer = createReducer<WidgetsPopoverState>(
  initialWidgetsPopoverState,

  on(WidgetsPopoverActions.resetWidgetEditPage, state => ({
    ...initialWidgetsPopoverState,
  })),

  on(
    WidgetsPopoverActions.showWidgetsPopover,
    WidgetsPopoverActions.goBackWidgetsPage,
    (state, { templateId }) => ({
      ...initialWidgetsPopoverState,
      templateId,
    }),
  ),

  on(
    WidgetsPopoverActions.showWidgetCreatePopover,
    (state, { templateId, nodeType, selectedWidgetType }) => ({
      ...initialWidgetsPopoverState,
      page: 'widget-create',
      previousPage: 'widgets',
      selectedWidgetType,
      templateId,
      nodeType,
    }),
  ),

  on(WidgetsPopoverActions.showWidgetChangePopover, (state, { templateId, widgetId }) => ({
    ...initialWidgetsPopoverState,
    page: 'widget-change',
    previousPage: 'widgets',
    widgetId,
    templateId,
  })),

  on(WidgetsPopoverActions.showWidgetCreatePage, (state, { selectedWidgetType }) => ({
    ...state,
    page: 'widget-create',
    previousPage: 'widgets',
    selectedWidgetType,
    widgetId: undefined,
  })),

  on(WidgetsPopoverActions.showWidgetChangePage, (state, { widgetId }) => ({
    ...state,
    page: 'widget-change',
    previousPage: 'widgets',
    widgetId,
  })),
);

export function widgetsPopoverReducer(state: WidgetsPopoverState, action: Action) {
  return reducer(state, action);
}
