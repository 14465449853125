<div class="file-uploader-container">
  <ul class="file-uploader-list">
    <li class="file-uploader-list-item" *ngFor="let item of fileList">
      <app-file-uploader-thumb
        [file]="item"
        [removable]="removable"
        [sortable]="false"
        (fileClick)="onThumbClick($event)"
        (fileRemove)="onThumbRemove($event)"
      ></app-file-uploader-thumb>
    </li>

    <li class="file-uploader-list-item file-uploader-list-item--queue" *ngFor="let item of queue">
      <app-file-uploader-thumb
        [file]="item"
        [removable]="removable"
        [sortable]="false"
        (fileClick)="onThumbClick($event)"
        (fileRemove)="fileCancel($event)"
      ></app-file-uploader-thumb>

      <div class="file-uploader-list-item-progress" *ngIf="item.progress > 0">
        <div class="progress">
          <div
            class="progress-bar"
            role="progressbar"
            [ngStyle]="{ width: item.progress + '%' }"
          ></div>
        </div>
      </div>
    </li>

    <li class="file-uploader-list-item add-file" [draggable]="false">
      <div class="file-uploader-list-item-preview">
        <div class="file-uploader-list-item-text d-none d-sm-block">
          Drag &amp; Drop
          <br/>or Click to add
        </div>
        <input
          *ngIf="multiple"
          multiple
          type="file"
          (change)="fileDrop($event)"
          [accept]="fileTypes"
        />
        <input *ngIf="!multiple" type="file" (change)="fileDrop($event)" [accept]="fileTypes"/>
      </div>
    </li>
  </ul>

  <button
    class="file-uploader__upload-btn btn btn-primary"
    [disabled]="!queue || queue.length === 0"
    (click)="confirmUpload()"
  >
    Upload
  </button>
</div>
<!-- /file-uploader-container -->
