import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-assignment-body',
  templateUrl: './assignment-body.component.html',
  styleUrls: ['./assignment-body.component.scss'],
})
export class AssignmentBodyComponent implements OnInit {
  @Input() locked: boolean;
  @Input() secondCardTemplate: TemplateRef<any>;
  @Input() hideFirstCard: boolean;

  constructor() {}

  ngOnInit() {}
}
