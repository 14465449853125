import { Action, createReducer, on } from '@ngrx/store';
import { AuthState, initialAuthState } from './auth.state';
import { AuthActions } from './index';

const reducer = createReducer(
  initialAuthState,
  on(
    AuthActions.loginSuccess,
    AuthActions.getOpenServerSessionSuccess,
    AuthActions.getUserSuccess,
    (state, { user }) => ({
      currentUser: user,
    }),
  ),

  // on(AuthActions.logoutRequest, AuthActions.getOpenServerSessionError, state => ({
  //   ...initialAuthState,
  // })),

  on(AuthActions.patchCurrentUserRequest, (state, { userData }) => ({
    ...state,
    currentUser: {
      ...state.currentUser,
      ...userData,
    },
  })),
  on(AuthActions.patchCurrentUserProfile, (state, { profile }) => ({
    ...state,
    currentUser: {
      ...state.currentUser,
      profile,
    },
  })),
  on(AuthActions.removeCurrentUserProfileRequest, state => ({
    ...state,
    currentUser: {
      ...state.currentUser,
      profile: null,
    },
  })),
  on(
    AuthActions.patchCurrentUserMetaSuccess,
    (state, metadata): AuthState => ({
      ...state,
      currentUser: {
        ...state.currentUser,
        metaData: metadata.metaData,
      },
    }),
  ),
);

export function AuthReducer(state: AuthState, action: Action) {
  return reducer(state, action);
}
