import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppLayoutActions, AppLayoutSelectors } from './index';
import { WorkspaceUserMetaState } from '../workspace-user-meta/workspace-user-meta.state';
import { WorkspaceUserMetaActions, WorkspaceUserMetaSelectors } from '../workspace-user-meta';
import { EMPTY, of } from 'rxjs';
import { select, Store } from '@ngrx/store';

@Injectable()
export class AppLayoutEffects {
  constructor(private actions$: Actions, private store: Store) {}

  loadWorkspaceUserMeta$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceUserMetaActions.loadCurrentWorkspaceUserMetaSuccess),
      map(action => action.userMetaData),
      concatMap((userMetaData: WorkspaceUserMetaState) => [
        AppLayoutActions.setSidebarLeft({ collapsed: !userMetaData?.sidebarLeft }),
        AppLayoutActions.setSidebarRight({ collapsed: !userMetaData?.sidebarRight }),
      ]),
    ),
  );

  setSidebarLeftCollapsed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AppLayoutActions.setSidebarLeft,
        AppLayoutActions.openSidebarLeft,
        AppLayoutActions.closeSidebarLeft,
        AppLayoutActions.setSidebarLeftCollapsed,
      ),
      concatMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(AppLayoutSelectors.selectSidebarLeft)),
            this.store.pipe(select(WorkspaceUserMetaSelectors.selectSidebarLeftVisibility)),
          ),
        ),
      ),
      mergeMap(([action, sidebar, visible]) => {
        if (sidebar.collapsed === !visible) {
          return EMPTY;
        }
        return of(WorkspaceUserMetaActions.setSidebarLeft({ visible: !sidebar.collapsed }));
      }),
    ),
  );

  setSidebarRightCollapsed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AppLayoutActions.setSidebarRight,
        AppLayoutActions.openSidebarRight,
        AppLayoutActions.closeSidebarRight,
        AppLayoutActions.setSidebarRightCollapsed,
      ),
      concatMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(AppLayoutSelectors.selectSidebarRight)),
            this.store.pipe(select(WorkspaceUserMetaSelectors.selectSidebarRightVisibility)),
          ),
        ),
      ),
      mergeMap(([action, sidebar, visible]) => {
        if (sidebar.collapsed === !visible) {
          return EMPTY;
        }
        return of(WorkspaceUserMetaActions.setSidebarRight({ visible: !sidebar.collapsed }));
      }),
    ),
  );
}
