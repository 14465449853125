<ng-template #modalFooter>
  <div class="modal-picker-validation" *ngIf="validationMessage">
    <span class="modal-picker-validation__error">{{validationMessage}}</span>
  </div>

  <button ngbAutofocus type="button" class="btn btn-sm btn-outline-light" aria-label="Close"
          (click)="activeModal.dismiss(false)">
    Cancel
  </button>

  <button type="button" class="btn btn-sm btn-primary" aria-label="Close" (click)="onSubmit()">
    Select
  </button>
</ng-template>

<app-modal title="Date Picker" [footerTemplate]="modalFooter">
  <div class="date-picker">

    <ng-template #dayTemplate let-date let-focused="focused">
      <div class="custom-day" [ngClass]="{
      'custom-day--selected': isDateSelected(date),
      'custom-day--disabled': isDateDisabled(date)
      }">
        {{ date.day }}
      </div>
    </ng-template>

    <ngb-datepicker #datepicker [dayTemplate]="dayTemplate" class="custom-datepicker"
                    [displayMonths]="3"
                    outsideDays="hidden"
                    [weekdays]="true"
                    navigation="select"
                    (dateSelect)="onDateSelection($event)">
      <ng-template ngbDatepickerContent>
        <div *ngFor="let month of datepicker.state.months">
          <div class="$primary-color-txt text-center p-1 fw-bold">
            {{ i18n.getMonthFullName(month.month) }} {{ month.year }}
          </div>
          <ngb-datepicker-month class="border" [month]="month"></ngb-datepicker-month>
        </div>
      </ng-template>
    </ngb-datepicker>

  </div>
</app-modal>
