import { Pipe, PipeTransform } from '@angular/core';
import { ChannelType } from '../../core/constants/channel-type';
import { ChatChannelViewModel } from '../../core/models/chat-channel-view.model';

@Pipe({
  name: 'chatChannelTitle',
})
export class ChatChannelTitlePipe implements PipeTransform {
  transform(channel: ChatChannelViewModel): string {
    if (channel) {
      if (channel.channelType === ChannelType.workspace) {
        return `${channel.workspace?.title}`;
      } else if (channel.channelType === ChannelType.node) {
        return `${channel.node?.title}`;
      }
    } else {
      return null;
    }
  }
}
