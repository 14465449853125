import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../store';
import { NodeType } from '../../../core/constants/node-type';
import { NodeModel } from '../../../core/models/node.model';
import { NodesActions, NodesSelectors } from '../../../store/nodes';
import { AbstractPickerComponent, ListItem } from '../_abstract-picker/abstract-picker.component';
import { NodeTemplateModel } from '../../../core/models/node-template.model';
import { NodeUtils } from '../../../core/utils/node.util';

@Component({
  templateUrl: './node-picker.component.html',
  styleUrls: ['./node-picker.component.scss'],
})
export class NodePickerComponent extends AbstractPickerComponent<NodeModel>
  implements OnInit, OnDestroy {
  @Input()
  public types: NodeType[];
  @Input()
  public nodeTemplates: NodeTemplateModel[];

  public readonly nodeTypeList = NodeType;

  constructor(
    public activeModal: NgbActiveModal,
    protected modalService: NgbModal,
    protected store: Store<AppState>,
  ) {
    super(activeModal);
    this.types = [NodeType.project, NodeType.assignment, NodeType.asset, NodeType.element];
    this.nodeTemplates = [];
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.subscription.add(
      this.store
        .pipe(
          select(
            NodesSelectors.selectNodesFilteredByNodeTypeWithKeyword(this.types, this.nodeTemplates),
          ),
        )
        .subscribe(nodes => {
          this.updateList(nodes?.filter(f => f?.parentNodeId));
        }),
    );
  }

  protected sortItems() {
    this.list.sort((a: ListItem<NodeModel>, b: ListItem<NodeModel>) => {
      if (this.maxSelection === 1) {
        return NodeUtils.sortByParentNode(a.model, b.model);
      }
      // Move Selected to start position
      if (a.selected == true && b.selected == false) {
        return -1;
      } else if (a.selected == false && b.selected == true) {
        return 1;
      }
      // Now sort by SortIndex
      return NodeUtils.sortByParentNode(a.model, b.model);
    });
  }

  public search(keywords) {
    this.keywords = keywords;
    this.store.dispatch(NodesActions.updateSearchKeywordRequest({ searchKeyword: this.keywords }));
  }

  public isEqual(itemA: NodeModel, itemB: NodeModel): boolean {
    return itemA.id === itemB.id;
  }

  public add() {
    throw new Error('Not implemented');
  }

  public edit(listItem: ListItem<NodeModel>) {
    throw new Error('Not implemented');
  }

  public trackBy(index, item: ListItem<NodeModel>) {
    return item.model.id;
  }

  public ngOnDestroy(): void {
    this.store.dispatch(NodesActions.updateSearchKeywordRequest({ searchKeyword: '' }));
    super.ngOnDestroy();
  }
}
