import { GanttModel } from '../../core/models/gantt.model';

export class GanttsState {
  byId: { [id: number]: GanttModel };
  scopedProjectIds: number[];
}

export const initialGanttsState: GanttsState = {
  byId: {},
  scopedProjectIds: [],
};
