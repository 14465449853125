import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Subscription} from 'rxjs';
import {AppState} from '../../../store';
import {NodeModel} from '../../../core/models/node.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmPopupComponent} from '../../modals/confirm-popup/confirm-popup.component';
import {NodeRatesActions} from '../../../store/node-rates';
import {RateType, RateTypeOptions} from '../../../core/constants/rate-type';
import {
  zTableProp,
  TableCell,
  TableColumn,
  TableColumnAlignment,
  TableGroupMoveEvent,
  TableRow,
  TableRowMoveEvent,
} from '../../table/table.common';
import {WorkspaceGroupType} from '../../../core/constants/workspace-group-type';
import {WorkspaceGroupModel} from '../../../core/models/workspace-group.model';
import {WorkspaceGroupsActions, WorkspaceGroupsSelectors} from '../../../store/workspace-groups';
import {TableCellEditEvent, TableEditor} from '../../table/table-editors/table-editors.common';
import {NodeType} from '../../../core/constants/node-type';

@Component({
  selector: 'app-z-table-node-rates',
  templateUrl: './nodes-rates-table.component.html',
  styleUrls: ['./nodes-rates-table.component.scss'],
})
export class NodesRatesTableComponent implements OnInit, OnChanges, OnDestroy {
  private readonly subscriptions: Subscription[] = [];

  public readonly zTableProp = zTableProp;

  @Input()
  public node: NodeModel;

  @Input()
  public hideTypeColumnByDefault: boolean;

  @Input()
  public readonly: boolean;

  public tableColumns: TableColumn[] = [];
  public tableBody: TableRow[] = [];
  // public emptyRow: TableRow;

  groups: WorkspaceGroupModel[] = [];
  groupOptions: any[];

  constructor(private store: Store<AppState>, private modalService: NgbModal) {
  }

  public ngOnInit() {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.node && changes.node.previousValue?.id !== changes.node.currentValue?.id) {
      this.resetTable();
      return;
    }
    if (changes.readonly && changes.readonly.previousValue !== changes.readonly.currentValue) {
      this.resetTable();
      return;
    }
    this.onTableUpdate();
  }

  public resetTable(): void {
    this.subscriptions.forEach(s => {
      s.unsubscribe();
    });

    this.subscriptions.push(
      this.store
        .pipe(select(WorkspaceGroupsSelectors.selectRateGroupsByNodeId(this.node.id)))
        .subscribe(groups => {
          this.groups = groups || [];
          this.groupOptions = [];
          this.groups.forEach(group => {
            this.groupOptions.push({
              value: group.id,
              label: group.title,
            });
          });
          this.onTableUpdate();
        }),
    );
    this.onTableColumns();
    this.onTableUpdate();
  }

  public onTableEdit(event: TableCellEditEvent) {
    if (event.cell.value === event.previousValue) {
      return;
    }

    const value =
      ['value', 'total'].indexOf(event.field) !== -1
        ? Math.round(event.cell.value * 100)
        : event.cell.value;

    // This is a new Row event
    if (event.row.id === zTableProp.EmptyRowId) {
      const props = {
        ...{
          nodeId: this.node.id,
          rateType: RateType.Expense,
          groupId: null,
          title: 'Add item description',
          value: 0,
          tooltip: null,
          units: '',
          quantity: 0,
          total: 0,
          sortIndex: -1,
        },
        [event.field]: value,
      };
      this.store.dispatch(NodeRatesActions.addNodeRateRequest(props));
      return;
    }

    this.store.dispatch(
      NodeRatesActions.updateNodeRateRequest({
        rateId: parseInt(event.row.id, 10),
        rateProps: {
          [event.field]: value,
        },
      }),
    );
  }

  public onTableGroupMove(event: TableGroupMoveEvent) {
    if (event.groupBy === 'groupId') {
      const groupIds = event.groupIds.map(id => parseInt(id));
      this.store.dispatch(
        WorkspaceGroupsActions.sortWorkspaceGroupsRequest({
          groupIds,
        }),
      );
    }
  }

  public onTableRowMove(event: TableRowMoveEvent) {
    if (event.sortColumn.id === 'sortIndex' && !event.group.id) {
      const rateIds = event.rowIds.map(id => parseInt(id));
      this.store.dispatch(
        NodeRatesActions.sortNodeRateRequest({
          nodeId: this.node.id,
          rateIds: event.sortDescending ? rateIds.reverse() : rateIds,
        }),
      );
    }
  }

  public onTableNewRow(event) {
    this.store.dispatch(
      NodeRatesActions.addNodeRateRequest({
        nodeId: this.node.id,
        rateType: RateType.Expense,
        title: 'Add item description',
        value: 0,
        tooltip: '',
        units: '',
        quantity: 0,
        total: 0,
        sortIndex: -1,
      }),
    );
  }

  public onTableInsert(row: TableRow, index: number) {
    const rate = this.node.rates.find(r => r.id.toString() === row.id);
    if (rate == null) {
      throw new Error('Rate not found.');
    }
    this.store.dispatch(
      NodeRatesActions.addNodeRateRequest({
        nodeId: this.node.id,
        rateType: rate.rateType,
        groupId: rate.groupId,
        title: 'Add item description',
        value: 0,
        tooltip: rate.tooltip,
        units: '',
        quantity: 0,
        total: 0,
        sortIndex: rate.sortIndex,
      }),
    );
  }

  public onTableCopy(row: TableRow, index: number) {
    const rate = this.node.rates.find(r => r.id.toString() === row.id);
    if (rate == null) {
      throw new Error('Rate not found.');
    }
    this.store.dispatch(
      NodeRatesActions.addNodeRateRequest({
        nodeId: this.node.id,
        rateType: rate.rateType,
        groupId: rate.groupId,
        title: `${rate.title} - copy`,
        value: rate.value,
        tooltip: rate.tooltip,
        units: rate.units,
        quantity: rate.quantity,
        total: rate.total,
        sortIndex: rate.sortIndex,
      }),
    );
  }

  public onTableDelete(row: TableRow, index: number) {
    const modelRef = this.modalService.open(ConfirmPopupComponent, {
      size: 'md',
      backdrop: 'static',
    });
    modelRef.componentInstance.title = 'Delete Rate';
    modelRef.componentInstance.message = 'Do you want to Delete this Rate?';
    modelRef.result
      .then((result?: boolean) => {
        if (result) {
          this.store.dispatch(
            NodeRatesActions.deleteNodeRateRequest({
              nodeId: this.node.id,
              rateId: parseInt(row.id, 10),
            }),
          );
        }
      })
      .catch(res => {
      });
  }

  public onTableColumns() {
    const rateOptions = RateTypeOptions.map(rt => {
      return {
        value: rt,
        label: RateType[rt],
      };
    });
    this.tableColumns = [
      {
        id: 'sortIndex',
        label: 'Item',
        alignment: TableColumnAlignment.Center,
        sortable: false,
        editor: TableEditor.RowNumber,
        editable: false,
        collapsed: true,
      } as TableColumn,
      {
        id: 'rateType',
        label: 'Rate Type',
        alignment: TableColumnAlignment.Left,
        colSpan: 2,
        sortable: true,
        collapsed:
          this.hideTypeColumnByDefault &&
          (this.node.nodeType === NodeType.asset),
        groupable: true,
        editor: TableEditor.Select,
        editable: !this.readonly,
        options: {
          list: rateOptions,
        },
        groupLabel: (id: string): string => {
          return RateType[id] || '';
        },
      } as TableColumn,
      {
        id: 'title',
        label: 'Item',
        colSpan: 6,
        sortable: true,
        editor: !this.readonly ? TableEditor.Text : TableEditor.Textarea,
        editable: !this.readonly,
      } as TableColumn,
      {
        id: 'value',
        label: 'Rate ($)',
        colSpan: 2,
        sortable: true,
        alignment: TableColumnAlignment.Right,
        groupable: true,
        editor: TableEditor.Number,
        editable: !this.readonly,
        options: {
          decimals: 2,
          min: -99999999.99,
          max: 99999999.99,
        },
      } as TableColumn,
      {
        id: 'units',
        label: 'Units',
        colSpan: 2,
        sortable: true,
        groupable: true,
        alignment: TableColumnAlignment.Right,
        editor: TableEditor.Text,
        editable: !this.readonly,
      } as TableColumn,
      {
        id: 'quantity',
        label: 'Quantity',
        colSpan: 2,
        sortable: true,
        collapsed: false,
        alignment: TableColumnAlignment.Right,
        groupable: true,
        editor: TableEditor.Number,
        editable: !this.readonly,
      } as TableColumn,
      {
        id: 'total',
        label: 'Item Total ($)',
        colSpan: 2,
        sortable: true,
        collapsed: false,
        alignment: TableColumnAlignment.Right,
        editor: TableEditor.Number,
        editable: false,
        options: {
          decimals: 2,
        },
        includeTotal: true,
      } as TableColumn,
      {
        id: 'groupId',
        label: 'Category',
        colSpan: 2,
        sortable: true,
        editor: TableEditor.WorkspaceGroup,
        editable: !this.readonly,
        collapsed: true,
        groupable: true,
        groupLabel: (id: string): string => {
          const group = this.groups.find(g => String(g.id) === String(id));
          return group ? group.title : '';
        },
        groupIndex: (id: string): number => {
          const group = this.groups.find(g => String(g.id) === String(id));
          return group ? group.sortIndex : 0;
        },
        options: {
          nodeId: this.node.id,
          type: WorkspaceGroupType.nodeRate,
        },
      } as TableColumn,
    ];
  }

  public onTableUpdate() {
    const unitOptions = this.node.rates
      .map(r => r.units)
      .filter((item, pos, self) => {
        return self.indexOf(item) == pos;
      });

    this.tableBody = this.node.rates.map(rate => {
      return {
        id: String(rate.id),
        columns: [
          {
            value: rate.sortIndex,
            alignment: TableColumnAlignment.Center,
          } as TableCell,
          {
            value: rate.rateType,
            alignment: TableColumnAlignment.Left,
          } as TableCell,
          {
            value: rate.title,
            alignment: TableColumnAlignment.Left,
          } as TableCell,
          {
            value: (rate.value || 0) / 100,
            alignment: TableColumnAlignment.Right,
          } as TableCell,
          {
            value: rate.units,
            alignment: TableColumnAlignment.Right,
            options: {
              list: unitOptions,
            },
          } as TableCell,
          {
            value: rate.quantity,
            alignment: TableColumnAlignment.Right,
          } as TableCell,
          {
            value: rate.quantity * ((rate.value || 0) / 100),
            alignment: TableColumnAlignment.Right,
          } as TableCell,
          {
            value: rate.groupId,
            alignment: TableColumnAlignment.Center,
            options: {
              list: this.groupOptions,
              // This will trigger an automatic update when a new Group is added
              childId: rate.id,
            },
          } as TableCell,
        ],
      };
    });

    // This will be used as a blank "new" record
    // Don't forget to add logic to the "endEdit" event to detect changes to a new record
    // this.emptyRow = {
    //   id: zTableProp.EmptyRowId,
    //   columns: [
    //     {
    //       value: null,
    //       alignment: TableColumnAlignment.Center,
    //     } as TableCell,
    //     {
    //       value: null,
    //       alignment: TableColumnAlignment.Center,
    //       editable: true,
    //     } as TableCell,
    //     {
    //       value: null,
    //       alignment: TableColumnAlignment.Left,
    //       editable: false,
    //     } as TableCell,
    //     {
    //       value: null,
    //       alignment: TableColumnAlignment.Right,
    //       editable: false,
    //     } as TableCell,
    //     {
    //       value: null,
    //       alignment: TableColumnAlignment.Right,
    //       editable: false,
    //       options: {
    //         list: unitOptions,
    //       },
    //     } as TableCell,
    //     {
    //       value: null,
    //       alignment: TableColumnAlignment.Center,
    //       editable: false,
    //     } as TableCell,
    //     {
    //       value: null,
    //       alignment: TableColumnAlignment.Right,
    //       editable: false,
    //     } as TableCell,
    //     {
    //       value: null,
    //       alignment: TableColumnAlignment.Center,
    //       editable: false,
    //       options: {
    //         list: this.groupOptions,
    //       },
    //     } as TableCell,
    //   ],
    // };
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(s => {
      s.unsubscribe();
    });
  }
}
