<div
  class="panel-subsection"
  [ngClass]="{
    'panel-subsection--background-transparent': backgroundTransparent,
    'panel-subsection--box-shadow': boxShadow,
    'py-0': noVerticalPadding,
    'px-0': noHorizontalPadding
  }"
>
  <div
    class="panel-subsection__header"
    *ngIf="title || headerToolbarTemplate || headerLargeToolbarTemplate || headerRightTemplate"
  >
    <div class="panel-subsection__header-left-section">
      <label *ngIf="title" class="panel-subsection__title">
        {{ title }}
      </label>

      <span class="panel-subsection__header-toolbar" *ngIf="headerToolbarTemplate">
        <ng-container *ngTemplateOutlet="headerToolbarTemplate"></ng-container>
      </span>

      <app-btn-tooltip
        class="panel-subsection__title-tooltip"
        *ngIf="titleTooltip"
        [text]="titleTooltip"
      ></app-btn-tooltip>
      <div class="panel-subsection__header-large-toolbar" *ngIf="headerLargeToolbarTemplate">
        <ng-container *ngTemplateOutlet="headerLargeToolbarTemplate"></ng-container>
      </div>
    </div>

    <div class="panel-subsection__header-right-section" *ngIf="headerRightTemplate">
      <ng-container *ngTemplateOutlet="headerRightTemplate"></ng-container>
    </div>
  </div>

  <div class="panel-subsection__body">
    <ng-content></ng-content>
  </div>
</div>
