import { OverlayRef } from '@angular/cdk/overlay';
import { Observable, Subject } from 'rxjs';
import { TemplateRef, Type } from '@angular/core';
import { PopoverParams } from './overlay-popover.service';

export type PopoverCloseEvent<T = any> = {
  type: 'backdropClick' | 'close' | 'updateAndClose';
  data: T;
};

export type PopoverContent = TemplateRef<any> | Type<any> | string;

export class OverlayPopoverRef<T = any> {
  private afterClosedSubject = new Subject<PopoverCloseEvent<T>>();
  private afterClosed$ = this.afterClosedSubject.asObservable();

  constructor(
    public overlay: OverlayRef,
    public content: PopoverContent,
    public data: T,
    public params: Partial<PopoverParams<T>>,
  ) {
    overlay.backdropClick().subscribe(() => {
      if (!params.disableBackdropClose) {
        this._close('backdropClick', null);
      }
    });
  }

  close(data?: T) {
    this._close('close', data);
  }

  updateAndClose(data?: T) {
    this._close('updateAndClose', data);
  }

  afterClosed(): Observable<PopoverCloseEvent<T>> {
    return this.afterClosed$;
  }

  private _close(type: PopoverCloseEvent['type'], data?: T) {
    this.overlay.dispose();
    this.afterClosedSubject.next({
      type,
      data,
    });
    this.afterClosedSubject.complete();
  }
}
