import { Action, createReducer, on } from '@ngrx/store';
import { GenericPopoverActions } from './index';
import { GenericPopoverState, initialGenericPopoverState } from './generic-popover.state';

const reducer = createReducer(
  initialGenericPopoverState,
  on(GenericPopoverActions.setDataToGenericPopover, (state, { data }) => {
    return {
      data,
    };
  }),
);

export function genericPopoverReducer(state: GenericPopoverState, action: Action) {
  return reducer(state, action);
}
