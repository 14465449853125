import { Component } from '@angular/core';
import { TableComponent } from '../../table.component';
import { AbstractTableEditableCellComponent } from '../abstract-table-editable-cell.component';

@Component({
  selector: 'app-z-table-editable-assignment-checkbox',
  templateUrl: './table-editable-assignment-checkbox.component.html',
  styleUrls: ['./table-editable-assignment-checkbox.component.scss'],
})
export class TableEditableAssignmentCheckboxComponent extends AbstractTableEditableCellComponent {
  constructor(public table: TableComponent) {
    super(table);
  }

  public onCheckboxChange() {
    this.cell.value = !(this.cell?.value ? true : false);
    this.onEndEdit();
  }
}
