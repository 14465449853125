import { createAction, props } from '@ngrx/store';
import { SharedLinkModel } from '../../core/models/shared-link.model';
import { DateRangeModel } from '../../core/models/date-range.model';

export const loadSharedLinksRequest = createAction(
  '[Workspace Shares] load shared links request',
  props<{ workspaceSlug: string }>(),
);
export const loadSharedLinksSuccess = createAction(
  '[Workspace Shares] load shared links success',
  props<{ sharedLinks: SharedLinkModel[] }>(),
);

export const shareCurrentViewRequest = createAction(
  '[Workspace Shares] share current view request',
  props<{ assignmentDateRange?: DateRangeModel; title?: string }>(),
);

export const shareCurrentViewSuccess = createAction(
  '[Workspace Shares] share current view success',
  props<{ sharedLink: SharedLinkModel }>(),
);

export const removeSharedLinkRequest = createAction(
  '[Workspace Shares] remove shared link request',
  props<{ hash: string }>(),
);
