import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ResponseMessage } from '../models/response-message.model';
import { map } from 'rxjs/operators';
import { WorkspaceUserModel } from '../models/workspace-user.model';
import { RoleType } from '../constants/role-type';

@Injectable({ providedIn: 'root' })
export class WorkspaceUsersService {
  constructor(private http: HttpClient) {}

  public loadWorkspaceUsersBySlug(workspaceSlug: string): Observable<WorkspaceUserModel[]> {
    return this.http.get<WorkspaceUserModel[]>(
      `${environment.apiBaseUrl}/workspace/${workspaceSlug}/user/search`,
    );
  }

  public inviteWorkspaceUser(workspaceSlug: string, email: string) {
    return this.http
      .post<ResponseMessage<any>>(
        `${environment.apiBaseUrl}/workspace/${workspaceSlug}/user/invite`,
        {
          email,
        },
      )
      .pipe(map(response => response.data));
  }

  public deleteWorkspaceUser(id: number) {
    return this.http.delete(`${environment.apiBaseUrl}/workspace/user/${id}/remove`);
  }

  public patchWorkspaceUserRole(id: number, role: RoleType): Observable<WorkspaceUserModel> {
    return this.http
      .patch<ResponseMessage<WorkspaceUserModel>>(
        `${environment.apiBaseUrl}/workspace/user/${id}/update`,
        { role },
      )
      .pipe(map(response => response.data));
  }

  public updateUserBelongedGroups(
    userId: number,
    groupIds: number[],
  ): Observable<WorkspaceUserModel> {
    return this.http
      .patch<ResponseMessage<WorkspaceUserModel>>(
        `${environment.apiBaseUrl}/workspace/user/${userId}/add-groups`,
        {
          groups: groupIds,
          replace: true,
        },
      )
      .pipe(map(response => response.data));
  }
}
