<button
  type="button"
  title="Sidebar toggle"
  class="sidebar-toggle btn btn-secondary"
  [ngClass]="{ 'sidebar-toggle--collapsed': parent.collapsed }"
  (click)="onToggle($event)"
>
  <span
    class="sidebar-toggle__icon sidebar-toggle__icon--chevron fal"
    [ngClass]="{
      'fa-chevron-left': position === SideBarPosition.Left,
      'fa-chevron-right': position === SideBarPosition.Right
    }"
  ></span>
  <span class="sidebar-toggle__icon sidebar-toggle__icon--menu-hamburger fal fa-bars"></span>
</button>
