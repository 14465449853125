import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WorkspaceGroupModel } from '../models/workspace-group.model';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ResponseMessage } from '../models/response-message.model';
import { map } from 'rxjs/operators';
import { WorkspaceGroupType } from '../constants/workspace-group-type';
import { NodeGroupValueEditableProps, NodeGroupValueModel } from '../models/node-group-value.model';

@Injectable({ providedIn: 'root' })
export class WorkspaceGroupService {
  constructor(private http: HttpClient) {}

  public loadWorkspaceGroups(workspaceSlug: string): Observable<WorkspaceGroupModel[]> {
    return this.http
      .get<ResponseMessage<WorkspaceGroupModel[]>>(
        `${environment.apiBaseUrl}/workspace/${workspaceSlug}/groups`,
      )
      .pipe(map(response => response.data));
  }

  public addWorkspaceGroup(
    workspaceSlug: string,
    props: { title: string; type: WorkspaceGroupType; node: number; nodeTemplate: number },
  ): Observable<WorkspaceGroupModel> {
    return this.http
      .post<ResponseMessage<WorkspaceGroupModel>>(
        `${environment.apiBaseUrl}/workspace/${workspaceSlug}/group/add`,
        {
          ...props,
        },
      )
      .pipe(map(response => response.data));
  }

  public sortWorkspaceGroups(ids: number[]): Observable<any> {
    return this.http
      .post<ResponseMessage<any>>(`${environment.apiBaseUrl}/workspace/group/sort`, {
        ids,
      })
      .pipe(map(response => response.data));
  }

  public updateWorkspaceGroup(groupId: number, title: string): Observable<WorkspaceGroupModel> {
    return this.http
      .patch<ResponseMessage<WorkspaceGroupModel>>(
        `${environment.apiBaseUrl}/workspace/group/${groupId}/update`,
        {
          title,
        },
      )
      .pipe(map(response => response.data));
  }

  public deleteWorkspaceGroup(groupId: number): Observable<object> {
    return this.http.delete(`${environment.apiBaseUrl}/workspace/group/${groupId}/remove`);
  }

  public updateNodeGroupValue(
    nodeId: number,
    groupId: number,
    editableProps: Partial<NodeGroupValueEditableProps>,
  ): Observable<NodeGroupValueModel> {
    return this.http
      .put<ResponseMessage<NodeGroupValueModel>>(
        `${environment.apiBaseUrl}/workspace/node/${nodeId}/group/${groupId}/update`,
        {
          ...editableProps,
        },
      )
      .pipe(map(res => res.data));
  }
}
