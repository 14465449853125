<div class="leaf-widget-item">
  <div
    class="leaf-widget-item__header"
    *ngIf="
      ((widget && widget.widget.widgetType !== WidgetType.checkbox) || customTitle) &&
      widget.widget.widgetType !== WidgetType.lineSeparator &&
      widget.widget.widgetType !== WidgetType.heading &&
      widget.widget.widgetType !== WidgetType.subHeading &&
      widget.widget.widgetType !== WidgetType.paragraph
    "
  >
    <h2 class="leaf-widget-item__title">
      {{ customTitle ? customTitle : widget.widget.title }}
      <sup *ngIf="widget.widget.mandatory" class="ap-required-asterisk">*</sup>
    </h2>
    <span class="fal fa-edit" role="button" (click)="editWidget()" *ngIf="isEdit"></span>
  </div>
  <div class="leaf-widget-item__body">
    <ng-content></ng-content>
  </div>
</div>
