import { AppState } from '../index';
import { createSelector } from '@ngrx/store';

export const selectAppLayoutState = (state: AppState) => state.appLayout;

export const selectWorkspaceContent = createSelector(
  selectAppLayoutState,
  state => state.workspaceContent,
);

export const selectSidebarLeft = createSelector(selectAppLayoutState, state => state.sidebarLeft);

export const selectSidebarSubLeft = createSelector(
  selectAppLayoutState,
  state => state.sidebarSubLeft,
);

export const selectSidebarRight = createSelector(selectAppLayoutState, state => state.sidebarRight);

export const selectSidebarSubRight = createSelector(
  selectAppLayoutState,
  state => state.sidebarSubRight,
);

export const selectWorkspaceCalendarOpen = createSelector(
  selectAppLayoutState,
  state => state.workspaceCalendarOpen,
);
