import { ChatChannelModel } from '../../core/models/chat-channel.model';
import { WorkspaceModel } from '../../core/models/workspace.model';
import { ChannelType } from '../../core/constants/channel-type';
import { WorkspaceUsersById } from '../workspace-users/workspace-users.state';
import { populateModificationInfo } from '../workspace-users/workspace-users.util';
import { ServerChatChannelData } from '../../core/models/server-chat-channel-data';
import { NodeModel, NodeModelsById } from '../../core/models/node.model';

const convertToChannelViewModel = (
  channel: ChatChannelModel,
  nodesById: NodeModelsById,
  currentWorkspace: WorkspaceModel,
  usersById: WorkspaceUsersById,
) => {
  let channelViewModel;
  if (!channel) {
    return undefined;
  }

  if (channel?.channelType === ChannelType.node) {
    channelViewModel = {
      ...channel,
      node: nodesById[channel?.nodeId],
    };
  } else if (channel?.channelType === ChannelType.workspace) {
    channelViewModel = {
      ...channel,
      workspace: currentWorkspace,
    };
  } else {
    channelViewModel = channel;
  }

  channelViewModel = {
    ...channelViewModel,
    ...populateModificationInfo(channelViewModel, usersById),
  };
  return channelViewModel;
};

const convertServerChatChannelDataToChannelModel = (
  data: ServerChatChannelData,
): ChatChannelModel => {
  return {
    ...data.channel,
    unreadComments: data.user ? data.user.unreadComments : data.channel.commentCount,
  };
};

export { convertToChannelViewModel, convertServerChatChannelDataToChannelModel };
