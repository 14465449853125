import { Pipe, PipeTransform } from '@angular/core';
import { RichSearchItemModel } from '../../core/models/rich-search-item.model';
import { RichSearchType } from '../../core/constants/rich-search-type';

@Pipe({
  name: 'richSearchSelectedIds',
})
export class RichSearchSelectedIdsPipe implements PipeTransform {
  transform(
    selectedRichSearchItems: RichSearchItemModel[],
    types: RichSearchType[],
  ): (number | string)[] {
    return (selectedRichSearchItems || [])
      .filter(item => types.indexOf(item.type) >= 0)
      .map(item => item.entity?.id);
  }
}
