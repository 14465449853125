import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { WidgetType } from '../../../core/constants/widget-type';
import { addressFieldRequiredValidator } from '../address-field/address-field.validators';
import { ChangeWidgetValueEvent } from '../../../core/models/change-widget-value.event';
import { urlValidationPattern } from '../../../core/constants/validation-patterns';
import { NodeTemplateWidgetModel } from '../../../core/models/node-template-widget.model';

@Component({
  selector: 'app-leaf-widget-list',
  templateUrl: './leaf-widget-list.component.html',
  styleUrls: ['./leaf-widget-list.component.scss'],
})
export class LeafWidgetListComponent implements OnInit {
  @Input()
  isHorizontal: boolean;

  @Input()
  appendixTemplateRef: TemplateRef<any>;

  @Input()
  isEdit: boolean;

  @Input()
  set readonly(readonly: boolean) {
    this._readonly = readonly;
    if (this.form) {
      if (this.readonly) {
        this.form.disable();
      } else {
        this.form.enable();
      }
    }
  }

  @Input()
  set widgets(nodeWidgets: NodeTemplateWidgetModel[]) {
    if (nodeWidgets) {
      this.form = this.toFormGroup(nodeWidgets);
      this.formCreated.emit({ form: this.form });
    }
    this._widgets = nodeWidgets;
  }

  @Output()
  closeEvent = new EventEmitter();

  @Output()
  changeWidgetValueEvent = new EventEmitter<ChangeWidgetValueEvent>();

  @Output()
  formCreated = new EventEmitter<{ form: UntypedFormGroup }>();

  get readonly(): boolean {
    return this._readonly;
  }

  get widgets(): NodeTemplateWidgetModel[] {
    return this._widgets;
  }

  private _widgets: NodeTemplateWidgetModel[];
  private _readonly: boolean;

  form: UntypedFormGroup;

  constructor() {}

  ngOnInit(): void {}

  toFormGroup(widgets: NodeTemplateWidgetModel[]) {
    let group: any = {};

    widgets.forEach(w => {
      const validators = [];
      if (w.widget.mandatory) {
        if (w.widget.widgetType === WidgetType.address) {
          validators.push(addressFieldRequiredValidator);
        } else {
          validators.push(Validators.required);
        }
      }

      if (w.widget.widgetType === WidgetType.number) {
        validators.push(Validators.pattern(/^(?:-?\d+|-?\d{1,3}(?:,\d{3})+)?(?:\.\d+)?$/));
      } else if (w.widget.widgetType === WidgetType.email) {
        validators.push(Validators.email);
        validators.push(
          Validators.pattern(
            /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i,
          ),
        );
      } else if (w.widget.widgetType === WidgetType.phone) {
        validators.push(Validators.pattern(/^[0-9 .\-+()]+$/i));
      } else if (w.widget.widgetType === WidgetType.url) {
        validators.push(Validators.pattern(urlValidationPattern));
      }
      let widgetValue;
      if (w.widget.widgetType === WidgetType.currency) {
        widgetValue = (w.value || 0) / 100;
      } else {
        widgetValue = w.value || '';
      }

      group[w.id] = new UntypedFormControl(widgetValue, validators);

      if (this.readonly) {
        group[w.id].disable();
      }
    });
    return new UntypedFormGroup(group);
  }

  close() {
    this.closeEvent.emit();
  }

  changeWidgetValue(widget: NodeTemplateWidgetModel, value) {
    this.changeWidgetValueEvent.emit({ widget, value });
  }

  trackBy(index, widget) {
    return widget.id;
  }
}
