import { AppState } from '../index';
import { createSelector } from '@ngrx/store';
import { selectCurrentUser } from '../auth/auth.selectors';
import { UserModel } from '../../core/models/user.model';
import { selectWorkspaceUserGroupsById } from '../workspace-user-groups/workspace-user-groups.selectors';
import { WorkspaceUserViewModel } from '../../core/models/workspace-user-view.model';

const selectWorkspaceUsersState = (state: AppState) => state.workspaceUsers;

export const selectCurrentWorkspaceUserIds = createSelector(
  selectWorkspaceUsersState,
  state => state.currentWorkspaceUserIds,
);

export const selectCurrentWorkspaceUsersById = createSelector(
  selectWorkspaceUsersState,
  state => state.currentWorkspaceUsersById,
);

export const selectCurrentWorkspaceUsersIsSendingInvitation = createSelector(
  selectWorkspaceUsersState,
  state => state.isSendingInvitation,
);

export const selectCurrentWorkspaceUsers = createSelector(
  selectCurrentWorkspaceUserIds,
  selectCurrentWorkspaceUsersById,
  (ids, workspaceUsersById) =>
    ids.map(id => ({
      ...workspaceUsersById[id],
    })),
);

export const selectCurrentWorkspaceUserViewModels = createSelector(
  selectCurrentWorkspaceUsers,
  selectWorkspaceUserGroupsById,
  (users, groupsById) => {
    return users.map(user => {
      return {
        ...user,
        groups: (user.groupIds || []).map(groupId => groupsById[groupId]).filter(group => !!group),
      };
    });
  },
);

export const selectCurrentWorkspaceActivatedUsers = createSelector(
  selectCurrentWorkspaceUsers,
  users => users.filter(user => !user.isPending),
);

export const selectCurrentWorkspaceUsersSortedByActiveState = createSelector(
  selectCurrentWorkspaceActivatedUsers,
  selectCurrentUser,
  (users, currentUser: UserModel) =>
    [...users]
      .map(user => {
        return {
          ...user,
          metaData: {
            ...user.metaData,
            profileIcon:
              user?.email === currentUser?.email
                ? currentUser?.metaData?.profileIcon
                : user.metaData.profileIcon,
          },
        };
      })
      .sort((userA, userB) => {
        if (userA.isActive === true) {
          return -1;
        } else if (userB.isActive === true) {
          return 1;
        } else {
          return 0;
        }
      }),
);

export const selectLoggedInWorkspaceUser = createSelector(
  selectCurrentUser,
  selectCurrentWorkspaceUsers,
  (currentUser: UserModel, workspaceUsers) => {
    if (!currentUser) {
      return null;
    }
    const workspaceUser = workspaceUsers.find(
      workspaceUser => workspaceUser.userId == currentUser.id,
    );
    return {
      ...workspaceUser,
      ...currentUser,
    };
  },
);

export const selectWorkspaceUserViewModel = userId => {
  return createSelector(
    selectCurrentWorkspaceUsersById,
    selectWorkspaceUserGroupsById,
    (usersById, groupsById): WorkspaceUserViewModel => {
      const user = usersById?.[userId];
      const userViewModel = {
        ...(user || {}),
        groups: (user?.groupIds || [])
          .map(groupId => groupsById?.[groupId])
          .filter(group => !!group),
      };
      return userViewModel as WorkspaceUserViewModel;
    },
  );
};
