import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { LayoutTemplateModel } from '../../../core/models/layout-template.model';
import { AppState } from '../../../store';
import { LayoutTemplatesActions } from '../../../store/layout-templates';
import { selectWorkspacesCurrentSlug } from '../../../store/workspaces/workspaces.selectors';
import { NodeTemplateModel } from '../../../core/models/node-template.model';
import { WorkspaceGroupModel } from '../../../core/models/workspace-group.model';
import {
  getTagGroupViewModelsSelectorByTemplate,
  getTemplatesPopoverSelectedTemplateTagGroupViewModelsSelector,
  selectTemplateCalculationWidgetViewModelsByTemplate,
  selectTemplatesAssignmentWidgetGroupsByTemplateGroup,
  selectTemplatesByAllowedTemplates,
} from '../../../store/templates-popover/templates-popover.selectors';
import { WorkspaceGroupType } from '../../../core/constants/workspace-group-type';
import { NodeType } from '../../../core/constants/node-type';
import { CalculationWidgetModel } from '../../../core/models/calculation-widget.model';
import { selectLoggedInWorkspaceUser } from '../../../store/workspace-users/workspace-users.selectors';
import { WorkspaceUserModel } from '../../../core/models/workspace-user.model';
import { PermissionType } from '../../../core/constants/permission-type';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageModalComponent } from '../modals/image-modal/image-modal.component';

@Component({
  selector: 'app-layout-block',
  templateUrl: './layout-block.component.html',
  styleUrls: [
    './layout-block.component.scss',
    '../../../../../src/scss/components/_ngb-accordion.scss',
  ],
})
export class LayoutBlockComponent implements OnInit, OnChanges {
  @Input() item: any; // LayoutTemplateModel | NodeTemplateModel;
  @Input() type: 'workspace' | 'layout';
  @Input() readonly: boolean;
  @Input() layout: 'small' | 'medium' | 'large' = 'small';
  @Output()
  public selectEvent = new EventEmitter<LayoutTemplateModel | NodeTemplateModel>();
  private slug: string;
  private readonly subscription = new Subscription();
  public readonly WorkspaceGroupType = WorkspaceGroupType;
  public selectedTemplates: NodeTemplateModel[] = [];
  public selectedAssetTemplates: NodeTemplateModel[] = [];
  public selectedFormTemplates: NodeTemplateModel[] = [];
  public groupsAd: WorkspaceGroupModel[] = [];
  public primaryTagGroups: WorkspaceGroupModel[] = [];
  public calculationWidgets: CalculationWidgetModel[];
  public calIds: number[];
  public nodeTypes = NodeType;
  readonly PermissionType = PermissionType;
  public loggedInUser: WorkspaceUserModel;
  public stampTagGroups: WorkspaceGroupModel[] = [];

  constructor(private store: Store<AppState>, private modalService: NgbModal) {}

  ngOnChanges(changes): void {
    // if and only item is changed
    if (changes.item && changes.item.currentValue && this.type === 'workspace') {
      this.subscription.add(
        this.store
          .pipe(select(selectTemplatesByAllowedTemplates(this.item)))
          .subscribe(templates => {
            this.selectedTemplates = templates;
            this.selectedAssetTemplates = this.selectedTemplates.filter(
              t => t.nodeType === NodeType.asset,
            );
            this.selectedFormTemplates = this.selectedTemplates.filter(
              t => t.nodeType === NodeType.element,
            );
          }),
      );

      this.subscription.add(
        this.store
          .pipe(select(selectTemplatesAssignmentWidgetGroupsByTemplateGroup(this.item?.groups)))
          .subscribe(templates => {
            this.groupsAd = templates;
          }),
      );

      this.subscription.add(
        this.store
          .pipe(
            select(
              getTagGroupViewModelsSelectorByTemplate(this.item, WorkspaceGroupType.primaryTag),
            ),
          )
          .subscribe(groups => {
            this.primaryTagGroups = groups;
          }),
      );

      // Wait for 'groupType' input
      this.subscription.add(
        this.store
          .pipe(
            select(
              getTemplatesPopoverSelectedTemplateTagGroupViewModelsSelector(
                WorkspaceGroupType.stamp,
              ),
            ),
          )
          .subscribe(groups => {
            this.stampTagGroups = groups;
          }),
      );

      this.subscription.add(
        this.store
          .pipe(select(selectTemplateCalculationWidgetViewModelsByTemplate(this.item)))
          .subscribe(widgets => {
            this.calculationWidgets = widgets;
            this.calIds = this.calculationWidgets.map(cal => cal.id);
          }),
      );
    } else if (this.type === 'layout') {
      this.selectedTemplates = [];
      this.selectedAssetTemplates = [];
      this.selectedFormTemplates = [];
      this.groupsAd = [];
      this.primaryTagGroups = [];
      this.stampTagGroups = [];
      this.calculationWidgets = [];
      this.calIds = [];
    }
  }

  ngOnInit(): void {
    this.subscription.add(
      this.store.pipe(select(selectWorkspacesCurrentSlug)).subscribe(slug => {
        this.slug = slug;
      }),
    );

    this.subscription.add(
      this.store.pipe(select(selectLoggedInWorkspaceUser)).subscribe(loggedInUser => {
        this.loggedInUser = loggedInUser;
      }),
    );
  }

  public installLayout(): void {
    this.store.dispatch(
      LayoutTemplatesActions.installLayoutTemplateRequest({
        slug: this.slug,
        layoutTemplateId: this.item?.id,
      }),
    );
  }

  public deleteLayout(): void {
    this.store.dispatch(
      LayoutTemplatesActions.deleteLayoutTemplateRequest({
        layoutTemplateId: this.item?.id,
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  showImageModal() {
    const modelRef = this.modalService.open(ImageModalComponent, {
      size: 'sm',
      backdrop: 'static',
    });
    modelRef.componentInstance.file = this.item?.profile;
  }
}
