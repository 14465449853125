import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import {
  DATE_TIME,
  MEDIUM_DATE,
  THIS_YEAR_DATE,
  THIS_YEAR_DATE_TIME,
  TIME,
} from '../../core/constants/date-format.constants';

export type NiceDateFormat = 'default' | 'day-only';

@Pipe({
  name: 'niceDate',
})
export class NiceDatePipe implements PipeTransform {
  transform(value: moment.Moment | Date | string, format: NiceDateFormat = 'default'): string {
    const momentTime = moment(value);
    const currentMomentTime = moment();
    const yearDiff = currentMomentTime.year() - momentTime.year();
    const dayOfYearDiff = currentMomentTime.dayOfYear() - momentTime.dayOfYear();

    if (dayOfYearDiff <= 7 && yearDiff === 0) {
      if (dayOfYearDiff === 0) {
        return format === 'day-only' ? 'Today' : `Today at ${momentTime.format(TIME)}`;
      } else if (dayOfYearDiff === 1) {
        return format === 'day-only' ? 'Yesterday' : `Yesterday at ${momentTime.format(TIME)}`;
      } else {
        return format === 'day-only'
          ? `${dayOfYearDiff} days ago`
          : `${dayOfYearDiff} days ago at ${momentTime.format(TIME)}`;
      }
    } else {
      if (yearDiff === 0) {
        return format === 'day-only'
          ? momentTime.format(THIS_YEAR_DATE)
          : momentTime.format(THIS_YEAR_DATE_TIME);
      } else {
        return format === 'day-only'
          ? momentTime.format(MEDIUM_DATE)
          : momentTime.format(DATE_TIME);
      }
    }
  }
}
