import { GanttChartCustomColumnType } from '../constants/gantt-chart-custom-column-type';

// export interface GanttChartViewSettingsModel {
//   label: boolean;
//   startDate: boolean;
//   endDate: boolean;
//   available: boolean;
//   target: boolean;
//   complete: boolean;
//   assigned: boolean;
// }

export type GanttChartViewSettingsModel = {
  [key in GanttChartCustomColumnType]: boolean;
};

export const ganttChartRelaxedViewSettings: GanttChartViewSettingsModel = {
  label: true,
  dateRange: true,
  available: true,
  budget: true,
  cost: true,
  target: true,
  complete: true,
  assigned: true,
};

export const ganttChartCompactViewSettings: GanttChartViewSettingsModel = {
  label: false,
  dateRange: false,
  available: false,
  budget: false,
  cost: false,
  target: false,
  complete: false,
  assigned: true,
};
