import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { EditAssignmentNodeFormEvent } from '../../../core/models/edit-assignment-node-form.event';
import { NodeRateValuesComponent } from '../../modals/node-rate-values/node-rate-values.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NodeModel } from '../../../core/models/node.model';
import { TableEditor } from '../../table/table-editors/table-editors.common';
import { FormsEditPanelComponent } from '../../modals/forms-edit-panel/forms-edit-panel.component';
import { WorkspaceModel } from '../../../core/models/workspace.model';
import { selectCurrentWorkspace } from '../../../store/workspaces/workspaces.selectors';
import { AppState } from '../../../store';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { TimesheetsSelectors } from '../../../store/timesheets';
import { TimesheetModel } from '../../../core/models/timesheet.model';

@Component({
  selector: 'app-assignment-footer',
  templateUrl: './assignment-footer.component.html',
  styleUrls: ['./assignment-footer.component.scss'],
})
export class AssignmentFooterComponent implements OnInit, OnDestroy {
  @Input() assignment: NodeModel;
  @Input() isExpanded: boolean;
  @Input() hasStamp: boolean;
  @Input() hasFiguresCompleted: boolean;
  @Input() hasFormsCompleted: boolean;
  @Input() isChatChannelActive: boolean;
  @Input() hideStampIcon: boolean;
  @Input() hideFinanceIcon: boolean;
  @Input() hideFormsIcon: boolean;
  @Input() hideDiaryIcon: boolean;
  @Input() readonly: boolean;
  @Input() hideOverlayIcons: boolean;

  public currentWorkspace: WorkspaceModel;

  @Output() selectStampEvent = new EventEmitter();
  @Output() editFinanceEvent = new EventEmitter<{ origin: any }>();
  @Output() editFormsEvent = new EventEmitter<EditAssignmentNodeFormEvent>();
  @Output() openChatEvent = new EventEmitter<{ origin: any }>();

  protected readonly TableEditor = TableEditor;
  private readonly subscription = new Subscription();

  public timesheetsByNodeId: { [id: string]: TimesheetModel[] } = {};

  constructor(private modalService: NgbModal, private store: Store<AppState>) {} // Combine the two constructors

  ngOnInit() {
    //
    this.subscription.add(
      this.store.pipe(select(selectCurrentWorkspace)).subscribe(workspace => {
        setTimeout(() => {
          this.currentWorkspace = workspace;
        }, 0); // Async timeout to prevent ExpressionChangedAfterItHasBeenCheckedError error
      }),
    );

    this.subscription.add(
      this.store
        .pipe(select(TimesheetsSelectors.selectTimesheetsByNodeId))
        .subscribe(timesheetsByNodeId => {
          this.timesheetsByNodeId = timesheetsByNodeId || {};
          // console.log('timesheetsByNodeId', this.timesheetsByNodeId);

        }),
    );
  }

  public costRatesAvailable(): boolean {
    return Object.values(this.timesheetsByNodeId).some(ts =>
      ts?.some(t => t?.assignmentId === this.assignment?.id),
    );
  }

  isRatesAvailableInAssetGroupNodes(): boolean {
    return (
      this.assignment?.assetGroups?.some(group =>
        group?.nodes?.some(node => node?.reference?.rates?.length),
      ) && this.assignment?.nodeTemplate?.allowCosts
    );
  }

  public selectStamp(): void {
    if (this.readonly) {
      return;
    }
    this.selectStampEvent.emit();
  }

  // move this into a pipe
  public isElementGroupsHasNodes(): boolean {
    return this.assignment?.elementGroups?.some(group => group?.nodes?.length > 0);
  }

  editFinance(origin) {
    if (this.readonly) {
      return;
    }
    this.editFinanceEvent.emit({ origin });
  }

  // editForms(origin) {
  //   if (this.readonly) {
  //     return;
  //   }
  //   this.editFormsEvent.emit({ origin, formId: this.assignment.selectedAssignmentChecklistId });
  // }

  openChat(origin) {
    if (this.readonly) {
      return;
    }
    this.openChatEvent.emit({ origin });
  }

  openRateValues(type: 'Cost' | 'Revenue') {
    if (this.readonly) {
      return;
    }
    const modelRef = this.modalService.open(NodeRateValuesComponent, {
      size: 'xxl',
      backdrop: 'static',
    });
    modelRef.componentInstance.project = this.assignment.reference;
    modelRef.componentInstance.assignment = this.assignment;
    modelRef.componentInstance.type = type;
  }

  openFormEditModal(): void {
    if (this.readonly) {
      return;
    }
    const modelRef = this.modalService.open(FormsEditPanelComponent, {
      size: 'md',
      backdrop: 'static',
      windowClass: 'form-edit-modal',
    });

    modelRef.componentInstance.assignment = this.assignment;

    modelRef.result
      .then((result: NodeModel[]) => {
        //
      })
      .catch(() => {});
  }

  // getTooltipText(): string {
  //   if (!this.assignment.stampTags || this.assignment.stampTags.length === 0) {
  //     return '';
  //   }

  //   const stampTitles = this.assignment.stampTags.map(tag => tag.tag.title);
  //   return stampTitles.join(', ');
  // }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
