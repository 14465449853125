import { createAction, props } from '@ngrx/store';
import { GanttEditableProps, GanttModel } from '../../core/models/gantt.model';

export const loadGanttsRequest = createAction(
  '[Gantts] load gantts request'
);
export const loadGanttsSuccess = createAction(
  '[Gantts] load gantts success',
  props<{ gantts: GanttModel[] }>(),
);

export const createGanttRequest = createAction(
  '[Gantts] create gantt request',
  props<{
    projectId: number;
    primaryTagId: number;
    startDate: string;
    endDate: string;
    assignmentCount: number;
    title?: string;
    tooltip?: string;
    budget?: number;
    cost?: number;
  }>(),
);

export const createGanttSuccess = createAction(
  '[Gantts] create gantt success',
  props<{
    gantt: GanttModel;
  }>(),
);

export const updateGanttRequest = createAction(
  '[Gantts] update gantt request',
  props<{ ganttId: number; ganttProps: Partial<GanttEditableProps> }>(),
);

export const deleteGanttRequest = createAction(
  '[Gantts] delete gantt request',
  props<{ ganttId: number }>(),
);

export const changeGanttScopedProjectIds = createAction(
  '[Gantts] change gantt scoped project ids',
  props<{ projectIds: number[] }>(),
);
