import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NodeService } from '../../core/services/node.service';
import { catchError, concatMap, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { ErrorsActions } from '../errors';
import { ArchivesActions } from './index';
import { select, Store } from '@ngrx/store';
import { selectWorkspacesCurrentSlug } from '../workspaces/workspaces.selectors';
import { AssignmentService } from '../../core/services/assignment.service';
import { NodeTemplateService } from '../../core/services/node-template.service';
import { WidgetService } from '../../core/services/widget.service';
import { WorkspaceService } from '../../core/services/workspace.service';

@Injectable()
export class ArchivesEffects {
  constructor(
    private actions$: Actions,
    private workspaceService: WorkspaceService,
    private nodeService: NodeService,
    private assignmentService: AssignmentService,
    private nodeTemplateService: NodeTemplateService,
    private widgetService: WidgetService,
    private store: Store,
  ) {}

  loadArchivedNodeTreeRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArchivesActions.loadArchivedNodeTreeRequest),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      mergeMap(([action, slug]) => {
        return this.nodeService.loadWorkspaceArchivedNodeTree(slug).pipe(
          map(nodes => {
            return ArchivesActions.loadArchivedNodeTreeSuccess({
              nodes,
            });
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        );
      }),
    ),
  );

  loadArchivedAssignmentTreeRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArchivesActions.loadArchivedAssignmentTreeRequest),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      mergeMap(([action, slug]) => {
        return this.assignmentService
          .loadArchivedAssignments(slug, action?.startDate, action?.endDate)
          .pipe(
            map(nodes => {
              return ArchivesActions.loadArchivedAssignmentTreeSuccess({
                nodes,
              });
            }),
            catchError(error => {
              return of(ErrorsActions.goToErrorPage({ error }));
            }),
          );
      }),
    ),
  );

  loadArchivedNodeTemplateRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArchivesActions.loadArchivedNodeTemplateRequest),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      mergeMap(([action, slug]) => {
        return this.nodeTemplateService
          .loadArchivedNodeTemplates(slug, action?.startDate, action?.endDate)
          .pipe(
            map(templates => {
              return ArchivesActions.loadArchivedNodeTemplateSuccess({
                templates,
              });
            }),
            catchError(error => {
              return of(ErrorsActions.goToErrorPage({ error }));
            }),
          );
      }),
    ),
  );

  loadArchivedWidgetRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArchivesActions.loadArchivedWidgetRequest),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      mergeMap(([action, slug]) => {
        return this.widgetService
          .loadArchivedWidgets(slug, action?.startDate, action?.endDate)
          .pipe(
            map(widgets => {
              return ArchivesActions.loadArchivedWidgetSuccess({
                widgets,
              });
            }),
            catchError(error => {
              return of(ErrorsActions.goToErrorPage({ error }));
            }),
          );
      }),
    ),
  );

  restoreArchivedNodeRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArchivesActions.restoreArchivedNodeFromArchivesPopoverRequest),
      mergeMap(action =>
        this.nodeService.restoreArchivedWorkspaceNode(action.nodeId).pipe(
          switchMap(() =>
            of(
              ArchivesActions.loadArchivedNodeTreeRequest(),
              ArchivesActions.loadArchivedAssignmentTreeRequest({}),
              ArchivesActions.restoreArchivedNodeFromArchivesPopoverSuccess({
                nodeId: action.nodeId,
              }),
            ),
          ), // todo: optimise performance without loading again
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  deleteArchivedNodeRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArchivesActions.deleteArchivedNodeFromArchivesPopoverRequest),
      mergeMap(action =>
        this.nodeService.removeWorkspaceNode(action.nodeId).pipe(
          switchMap(() =>
            of(
              ArchivesActions.loadArchivedNodeTreeRequest(),
              ArchivesActions.loadArchivedAssignmentTreeRequest({}),
            ),
          ), // todo: optimise performance without loading again
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  emptyArchiveRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArchivesActions.emptyArchiveRequest),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      mergeMap(([action, slug]) => {
        return this.workspaceService.emptyArchive(slug).pipe(
          concatMap(metaData => [
            ArchivesActions.emptyArchiveSuccess(),
            ArchivesActions.unloadArchiveRequest(),
            ArchivesActions.loadArchivedNodeTreeRequest(),
            ArchivesActions.loadArchivedAssignmentTreeRequest({}),
            ArchivesActions.loadArchivedNodeTemplateRequest({}),
            ArchivesActions.loadArchivedWidgetRequest({}),
          ]),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        );
      }),
    ),
  );
}
