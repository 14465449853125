import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { debounceTime, distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { merge, Observable, Subject } from 'rxjs';
import { NgbTypeahead, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import {
  createRichSearchKeywordItem,
  RichSearchItemModel,
} from '../../../core/models/rich-search-item.model';
import { RichSearchType } from '../../../core/constants/rich-search-type';
import {
  addSearchItem,
  getRichSearchTypeLabel,
  removeKeyboardSearchItem,
} from '../../../core/utils/rich-search.util';
import { RichSearchFilterComponent } from './rich-search-filter/rich-search-filter.component';

@Component({
  selector: 'app-rich-search',
  templateUrl: './rich-search.component.html',
  styleUrls: ['./rich-search.component.scss'],
})
export class RichSearchComponent implements OnInit {
  public readonly RichSearchType = RichSearchType;

  @ViewChild('typeaheadInstance') instance: NgbTypeahead;
  @ViewChild('templateFilter') templateFilter: RichSearchFilterComponent;
  @ViewChild('layoutFilter') layoutFilter: RichSearchFilterComponent;
  @ViewChild('tagFilter') tagFilter: RichSearchFilterComponent;
  @ViewChild('folderFilter') folderFilter: RichSearchFilterComponent;
  @ViewChild('unassignedFilter') unassignedFilter: RichSearchFilterComponent;

  @Input() itemOptions: RichSearchItemModel[];
  @Input() selectedSearchItems: RichSearchItemModel[] = [];
  @Input() searchPlaceholder: string;

  @Output() richSearchEvent = new EventEmitter<RichSearchItemModel[]>();

  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  searchModel: any;
  showFilters: boolean;
  isFiltered: boolean;

  constructor() {}

  ngOnInit(): void {}

  reset() {
    this.isFiltered = false;
    this.showFilters = false;
    this.searchModel = createRichSearchKeywordItem('');
    // this.itemOptions = [];
    // this.selectedSearchItems = [];
    this.richSearchEvent.emit([]);
  }

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      tap(term => {
        const existingKeywordSearchItem = this.selectedSearchItems.filter(
          item => item.type === RichSearchType.keyword,
        )[0];

        // if keyword has changed, replace the keyword search item and trigger richSearchEvent
        if (!existingKeywordSearchItem || existingKeywordSearchItem.entity.id !== term) {
          const newKeywordSearchItem = createRichSearchKeywordItem(term);
          const changedItems = [
            newKeywordSearchItem,
            ...this.selectedSearchItems.filter(item => item.type !== RichSearchType.keyword),
          ];
          this.richSearchEvent.emit([...changedItems]);
          this.isFiltered = true;
        }
      }),
    );

    const clicksWithClosedPopup$ = this.click$.pipe(
      filter(() => {
        return !this.instance?.isPopupOpen();
      }),
    );

    // const clicksWithClosedPopup$ = this.click$;
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => {
        let resultOptions = this.itemOptions;
        if (term !== '') {
          resultOptions = resultOptions
            .filter(v => {
              const typeLabel = getRichSearchTypeLabel(v.type);

              return (
                v.entity.title.toLowerCase().indexOf(term.toLowerCase()) > -1 ||
                typeLabel.toLowerCase().indexOf(term.toLowerCase()) > -1
              );
            })
            .slice(0, 20); // limit the item number in case of extreme large number of choices.
          const keywordOption: RichSearchItemModel = createRichSearchKeywordItem(term);
          resultOptions = [keywordOption, ...resultOptions];
        }
        return resultOptions;
      }),
    );
  };

  formatter = (x: RichSearchItemModel) => x.entity.title;

  selectItem(event: NgbTypeaheadSelectItemEvent<RichSearchItemModel>) {
    const selectedItem = event.item;
    if (selectedItem?.type === RichSearchType.filterCategory) {
      this.showFilters = true;
      setTimeout(() => {
        if (selectedItem.entity.id === RichSearchType.template) {
          this.templateFilter?.open();
        } else if (selectedItem.entity.id === RichSearchType.layout) {
          this.layoutFilter?.open();
        } else if (selectedItem.entity.id === RichSearchType.tag) {
          this.tagFilter?.open();
        } else if (selectedItem.entity.id === RichSearchType.folder) {
          this.folderFilter?.open();
        } else if (selectedItem.entity.id === RichSearchType.unassignedDate) {
          this.unassignedFilter?.open();
        }
      }, 0);

      event.preventDefault(); // prevent from selecting existing item
      return;
    }
    let changedItems = this.selectedSearchItems;
    if (selectedItem.type !== RichSearchType.keyword) {
      changedItems = removeKeyboardSearchItem(changedItems);
    }
    changedItems = addSearchItem(selectedItem, changedItems);

    this.richSearchEvent.emit([...changedItems]);
    this.isFiltered = true;

    if (selectedItem.type !== RichSearchType.keyword) {
      this.searchModel = createRichSearchKeywordItem(''); // make keyword empty when selecting a non-keyword search item
      event.preventDefault();
    }
  }

  searchKeyword(keyword) {
    const keywordSearchItem: RichSearchItemModel = createRichSearchKeywordItem(keyword);
    const changedItems = addSearchItem(keywordSearchItem, this.selectedSearchItems);
    this.richSearchEvent.emit([...changedItems]);
    this.isFiltered = true;
  }

  changeFilterOptionIds(ids: (number | string)[], type: RichSearchType) {
    const changedItems = [
      ...this.selectedSearchItems.filter(item => item.type !== type),
      ...this.itemOptions.filter(item => ids.indexOf(item.entity.id) >= 0 && item.type === type),
    ];
    this.richSearchEvent.emit([...changedItems]);
    this.isFiltered = true;
  }

  searchModeChange(event) {
    // console.info('searchModeChange event: ', event);
  }
}
