import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedLinkModel } from '../../../core/models/shared-link.model';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-shared-link',
  templateUrl: './shared-link.component.html',
  styleUrls: ['./shared-link.component.scss'],
})
export class SharedLinkComponent implements OnInit {
  @Input() sharedLink: SharedLinkModel;
  @Input() selectedLink: SharedLinkModel;
  @Output() selectEvent = new EventEmitter();
  @Output() removeEvent = new EventEmitter();

  constructor(private store: Store) {}

  ngOnInit(): void {}

  openLink(hash: string) {
    window.open(`/shared-view/${hash}`, '_blank');
  }

  selectLink() {
    this.selectEvent.emit();
  }

  remove() {
    this.removeEvent.emit();
  }
}
