<input
  #input
  type="text"
  class="z-table__input z-table__input--number"
  [ngClass]="{ 'z-table__input--empty': editable && cell?.value == null }"
  [(ngModel)]="this.displayValue"
  [placeholder]="!this.displayValue ? '0.00' : ''"
  [disabled]="!editable"
  (focus)="onStartEdit()"
  (blur)="onEndEdit()"
  (keydown)="onKeyDown($event)"
  (keyup.enter)="onEnterKey()"
  (keyup.escape)="onEscapeKey()"
/>
