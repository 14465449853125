<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="modal-header">
    <h4 class="modal-title">
      Create new Template
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss(false)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-form-validation [control]="form" [submitted]="submitted"></app-form-validation>

    <div class="form-group">
      <label class="control-label sr-only">Title</label>
      <input
        type="text"
        formControlName="title"
        class="form-control"
        ngbAutofocus
        [ngClass]="{ 'is-invalid': submitted && f.title.errors }"
        placeholder="New Template name"
      />
      <app-control-validation [control]="f.title" [submitted]="submitted"></app-control-validation>
    </div>

    <div class="new-template__button-group text-center">
      <div class="btn-group" role="group">
        <button type="button" class="btn btn-outline-light" (click)="activeModal.dismiss()">
          Close
        </button>
        <button [disabled]="loading" type="submit" class="btn btn-primary">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          Save
        </button>
      </div>
    </div>
  </div>
</form>
