import { Action, createReducer, on } from '@ngrx/store';
import { initialTemplatesPopoverState, TemplatesPopoverState } from './templates-popover.state';
import { TemplatesPopoverActions } from './index';
import { TemplatesActions } from '../templates';
import { TemplateWidgetsActions } from '../template-widgets';
import { WidgetsActions } from '../widgets';
import { TemplateTagsActions } from '../template-tags';
import { TagsActions } from '../tags';

const reducer = createReducer(
  initialTemplatesPopoverState,
  on(TemplatesPopoverActions.openTemplatesPopover, (state, { node }) => {
    let serverNodeType = node.nodeType;
    return {
      ...initialTemplatesPopoverState,
      serverNodeType,
    };
  }),

  on(TemplatesPopoverActions.selectTemplatePopoverTemplate, (state, { templateId }) => ({
    ...state,
    selectedTemplateId: templateId,
  })),

  on(
    TemplatesPopoverActions.setTemplatePopoverTemplateChanged,
    TemplateWidgetsActions.addTemplateWidgetsRequest,
    TemplateWidgetsActions.removeTemplateWidgetRequest,
    TemplateWidgetsActions.sortTemplateWidgetsRequest,
    TemplateTagsActions.addTemplateTagsRequest,
    TemplateTagsActions.removeTemplateTagRequest,
    TemplateTagsActions.sortTemplateTagsRequest,
    TagsActions.deleteTagRequest,
    TagsActions.updateTagRequest,
    WidgetsActions.deleteWidgetRequest,
    WidgetsActions.updateWidgetRequest,
    TemplateWidgetsActions.removeTemplateGroupWidgetAndWidgetItselfRequest,
    state => ({
      ...state,
      hasTemplateChanged: true,
    }),
  ),
  on(TemplatesActions.deleteNodeTemplateRequest, (state, { templateId }) => ({
    ...state,
    selectedTemplateId:
      state.selectedTemplateId === templateId ? undefined : state.selectedTemplateId,
  })),
);

export function templatesPopoverReducer(state: TemplatesPopoverState, action: Action) {
  return reducer(state, action);
}
