import { Component, Input, TemplateRef } from '@angular/core';
import { SidebarComponent } from '../sidebar.component';

@Component({
  selector: 'app-sidebar-footer',
  templateUrl: './sidebar-footer.component.html',
  styleUrls: ['./sidebar-footer.component.scss'],
})
export class SidebarFooterComponent {
  @Input()
  public template: TemplateRef<any>;

  constructor(public parent: SidebarComponent) {}
}
