import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResponseMessage } from '../models/response-message.model';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { SharedLinkMetaModel } from '../models/shared-link-meta.model';
import { Observable } from 'rxjs';
import { SharedLinkModel } from '../models/shared-link.model';
import { SharedViewResponseModel } from '../models/shared-view-response.model';

@Injectable({ providedIn: 'root' })
export class WorkspaceSharesService {
  constructor(private http: HttpClient) {}

  public loadSharedLinks(workspaceSlug: string): Observable<SharedLinkModel[]> {
    return this.http
      .get<ResponseMessage<SharedLinkModel[]>>(
        `${environment.apiBaseUrl}/workspace/${workspaceSlug}/share-links`,
      )
      .pipe(map(response => response.data));
  }

  public shareLink(workspaceSlug: string, meta: SharedLinkMetaModel) {
    return this.http
      .post<ResponseMessage<any>>(
        `${environment.apiBaseUrl}/workspace/${workspaceSlug}/share-link/add`,
        {
          ...meta,
        },
      )
      .pipe(map(response => response.data));
  }

  public removeSharedLink(hash: string): Observable<object> {
    return this.http.delete(`${environment.apiBaseUrl}/workspace/share-link/${hash}/remove`);
  }

  public loadSharedView(hash: string): Observable<SharedViewResponseModel> {
    return this.http
      .get<ResponseMessage<SharedViewResponseModel>>(
        `${environment.apiBaseUrl}/workspace/share-link/${hash}`,
      )
      .pipe(map(response => response.data));
  }
}
