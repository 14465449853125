import * as moment from 'moment';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DATE_KEY_FORMAT, DATE_PICKER_DATE } from '../constants/date-format.constants';
import { dateRangeUtil } from './date-range.util';

const convertToNgbDate = (date: string | Date) => {
  const momentDate = moment(date);
  return new NgbDate(momentDate.year(), momentDate.month() + 1, momentDate.date());
};

const convertToDateKey = (ngbDate: NgbDateStruct): string => {
  return formatNgbDate(ngbDate, DATE_KEY_FORMAT);
};

const convertToMomentDate = (ngbDate: NgbDateStruct): moment.Moment => {
  return moment(formatNgbDate(ngbDate, DATE_KEY_FORMAT));
};

const formatNgbDate = (ngbDate: NgbDateStruct, format: string): string => {
  return ngbDate
    ? moment({ year: ngbDate.year, month: ngbDate.month - 1, day: ngbDate.day }).format(format)
    : null;
};

const covertToDatePickerDateStr = (ngbDate: NgbDateStruct): string => {
  return formatNgbDate(ngbDate, DATE_PICKER_DATE);
};

const getTomorrowNgbDate = (): NgbDate => {
  const tomorrowMoment = moment().add(1, 'day');
  return convertToNgbDate(tomorrowMoment.format(DATE_KEY_FORMAT));
};

const getNextDayNgbDate = (date: string | Date): NgbDate => {
  const nextDayMoment = moment(date).add(1, 'day');
  return convertToNgbDate(nextDayMoment.format(DATE_KEY_FORMAT));
};

const durationDays = (startNgbDate: NgbDateStruct, endNgbDate: NgbDateStruct) => {
  const startDate = formatNgbDate(startNgbDate, DATE_KEY_FORMAT);
  const endDate = formatNgbDate(endNgbDate, DATE_KEY_FORMAT);

  return dateRangeUtil.getDurationDays(startDate, endDate);
};

const getMinMaxDates = (dates: NgbDateStruct[]): { minDate: NgbDate; maxDate: NgbDate } => {
  const dateObjects = dates.map(date => new Date(date.year, date.month - 1, date.day).getTime());
  const minDate = new Date(Math.min(...dateObjects));
  const maxDate = new Date(Math.max(...dateObjects));
  return {
    minDate: convertToNgbDate(minDate),
    maxDate: convertToNgbDate(maxDate),
  };
};

export const ngbDateUtil = {
  convertToNgbDate,
  convertToDateKey,
  convertToMomentDate,
  covertToDatePickerDateStr,
  formatNgbDate,
  getTomorrowNgbDate,
  getNextDayNgbDate,
  durationDays,
  getMinMaxDates,
};
