import { createSelector } from '@ngrx/store';
import { selectWidgetsById } from '../widgets/widgets.selectors';
import { AppState } from '../index';
import { selectTemplateWidgetsByTemplateId } from '../template-widgets/template-widgets.selectors';
import { selectNodeTemplatesById } from '../templates/templates.selectors';

const selectWidgetsPopoverState = (state: AppState) => state.widgetsPopover;

export const selectWidgetsPopoverPage = createSelector(
  selectWidgetsPopoverState,
  state => state.page,
);

export const selectWidgetsPopoverPreviousPage = createSelector(
  selectWidgetsPopoverState,
  state => state.previousPage,
);

export const selectWidgetsPopoverTemplateId = createSelector(
  selectWidgetsPopoverState,
  state => state.templateId,
);

export const selectWidgetsPopoverTemplate = createSelector(
  selectWidgetsPopoverTemplateId,
  selectNodeTemplatesById,
  (templateId, byId) => byId[templateId],
);

export const selectWidgetsPopoverWidgetId = createSelector(
  selectWidgetsPopoverState,
  state => state.widgetId,
);

export const selectWidgetsPopoverSelectedWidgetType = createSelector(
  selectWidgetsPopoverState,
  state => state.selectedWidgetType,
);

export const selectWidgetsPopoverWidget = createSelector(
  selectWidgetsPopoverWidgetId,
  selectWidgetsById,
  (widgetId, widgetsById) => {
    return widgetId && widgetsById[widgetId];
  },
);

export const selectWidgetsPopoverTemplateWidgetIds = createSelector(
  selectWidgetsPopoverTemplateId,
  selectTemplateWidgetsByTemplateId,
  (templateId, widgetIdArraysByTemplateId) => {
    return widgetIdArraysByTemplateId[templateId] ? widgetIdArraysByTemplateId[templateId] : [];
  },
);
