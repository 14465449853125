import { Component, OnDestroy, OnInit } from '@angular/core';
import { TemplateFiguresPopoverPage } from '../../../core/constants/template-figures-popover-page';
import { Subscription } from 'rxjs';
import { OverlayPopoverRef } from '../../../shared/components/overlay-popover/overlay-popover-ref';
import { select, Store } from '@ngrx/store';
import { selectTemplateFiguresPopoverPage } from '../../../store/template-figures-popover/template-figures-popover.selectors';

@Component({
  selector: 'app-template-figures-popover',
  templateUrl: './template-figures-popover.component.html',
  styleUrls: ['./template-figures-popover.component.scss'],
})
export class TemplateFiguresPopoverComponent implements OnInit, OnDestroy {
  page: TemplateFiguresPopoverPage;
  TemplateFiguresPopoverPage = TemplateFiguresPopoverPage;
  subscription = new Subscription();

  constructor(private popoverRef: OverlayPopoverRef, private store: Store) {}

  ngOnInit(): void {
    this.subscription.add(
      this.store.pipe(select(selectTemplateFiguresPopoverPage)).subscribe(page => {
        this.page = page;
      }),
    );
  }

  close() {
    this.popoverRef.close();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
