import { Component, OnInit } from '@angular/core';
import { TableComponent } from '../../table.component';
import { AbstractTableEditableCellComponent } from '../abstract-table-editable-cell.component';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-z-table-editable-number',
  templateUrl: './table-editable-number.component.html',
  styleUrls: ['./table-editable-number.component.scss'],
})
export class TableEditableNumberComponent extends AbstractTableEditableCellComponent
  implements OnInit {
  protected readonly specialKeys = [
    'Enter',
    'Escape',
    'Tab',
    'Backspace',
    'ArrowLeft',
    'ArrowRight',
    'Delete',
  ];

  protected defaultOptions = {
    min: -99999999.99,
    max: 99999999.99,
    decimals: 2,
  };

  public displayValue: any;

  constructor(public table: TableComponent, protected decimalPipe: DecimalPipe) {
    super(table);
  }

  protected addFormatting() {
    const _float = parseFloat(this.displayValue);
    this.displayValue =
      isNaN(_float) === false
        ? this.decimalPipe.transform(_float, `1.${this.options.decimals}-${this.options.decimals}`)
        : '';
  }

  protected removeFormatting() {
    this.displayValue = String(this.displayValue);
    if (this.displayValue == null) {
      this.displayValue = '';
      return;
    }
    const _float = parseFloat(this.displayValue.replace(/[^\d.-]/g, ''));
    this.displayValue = isNaN(_float) === false ? _float : '';
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.displayValue = String(this.cell.value);
    this.removeFormatting();
    this.addFormatting();
  }

  public onKeyDown(event) {
    if (this.specialKeys.indexOf(event.key) !== -1 || event.ctrlKey) {
      return true;
    }

    if (event.key.match(/[\d.-]/g) == null) {
      return false;
    }

    const _value = String(this.displayValue) || '';
    if (event.key === '.' && _value.split('.').length > 1) {
      return false;
    }

    if (event.key === '-' && (event.target.selectionStart != 0 || _value.split('-').length > 1)) {
      return false;
    }

    const nextValue = _value + event.key;
    const _float = parseFloat(nextValue || '0');
    if (isNaN(_float) === true || _float < this.options.min || _float > this.options.max) {
      return false;
    }

    return true;
  }

  public onStartEdit() {
    super.onStartEdit();
    this.removeFormatting();
  }

  public onEndEdit() {
    this.removeFormatting();
    this.cell.value = this.displayValue || null;
    this.addFormatting();
    super.onEndEdit();
  }
}
