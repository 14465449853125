import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  availableCalculationFieldWidgetTypeOptions,
  WidgetType,
} from '../../../../core/constants/widget-type';
import { availableCalculationMethodOptions } from '../../../../core/constants/calculation-method.type';
import { Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { selectTemplatesPopoverSelectedTemplate } from '../../../../store/templates-popover/templates-popover.selectors';
import { ENTER } from '@angular/cdk/keycodes';
import { WidgetModel } from '../../../../core/models/widget.model';
import { selectTemplateFiguresPopoverWidget } from '../../../../store/template-figures-popover/template-figures-popover.selectors';
import { WidgetsActions } from '../../../../store/widgets';

@Component({
  selector: 'app-template-calculation-widget-edit',
  templateUrl: './template-calculation-widget-edit.component.html',
  styleUrls: ['./template-calculation-widget-edit.component.scss'],
})
export class TemplateCalculationWidgetEditComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('titleInput', { static: true }) titleRef: ElementRef;
  @Output() closeEvent = new EventEmitter();

  widget: WidgetModel;

  selectedTemplateId: number;
  isSubmitted: boolean;

  form: UntypedFormGroup;

  availableCalculationFieldWidgetTypeOptions = availableCalculationFieldWidgetTypeOptions;
  availableCalculationMethodOptions = availableCalculationMethodOptions;
  WidgetType = WidgetType;

  subscription = new Subscription();

  get title() {
    return this.form.get('title');
  }

  // convenience getter for easy access to form fields
  public get f() {
    return this.form.controls;
  }

  constructor(private fb: UntypedFormBuilder, private store: Store) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      title: ['', Validators.required],
      selectedWidgetType: [undefined],
      calculationMethod: [undefined],
    });

    this.subscription.add(
      this.store.pipe(select(selectTemplatesPopoverSelectedTemplate)).subscribe(template => {
        this.selectedTemplateId = template?.id;
      }),
    );

    this.subscription.add(
      this.store.pipe(select(selectTemplateFiguresPopoverWidget)).subscribe(widget => {
        this.widget = widget;
        if (this.widget) {
          this.title.setValue(widget.title);
          this.f['selectedWidgetType'].setValue(widget.widgetType);
          if (widget.widgetType === WidgetType.calculationJoin) {
            this.f['calculationMethod'].setValue(widget.calculationMethod);
          }
        }
      }),
    );
  }

  submit() {
    this.isSubmitted = true;
    if (this.form.valid) {
      if (this.widget.widgetType === WidgetType.calculationJoin) {
        this.store.dispatch(
          WidgetsActions.updateWidgetRequest({
            widgetId: this.widget?.id,
            widgetProps: {
              title: this.title.value,
              calculationMethod: this.f['calculationMethod'].value,
            },
          }),
        );
      } else {
        this.store.dispatch(
          WidgetsActions.updateWidgetRequest({
            widgetId: this.widget?.id,
            widgetProps: {
              title: this.title.value,
              widgetType: this.f['selectedWidgetType'].value,
            },
          }),
        );
      }

      this.closeEvent.emit();
    }
  }

  onButtonKeyDown(event) {
    if (event.keyCode === ENTER) {
      event.preventDefault();
      this.submit();
    }
  }

  close() {
    this.closeEvent.emit();
  }

  onDelete() {
    // this.store.dispatch(
    //   TemplateWidgetsActions.removeTemplateGroupWidgetAndWidgetItselfRequest({
    //     templateId: this.selectedTemplateId,
    //     groupId: this.assignmentFormId,
    //     widgetId: this.widgetId,
    //   }),
    // );
    this.close();
  }

  ngAfterViewInit() {
    this.titleRef.nativeElement.focus();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
