<ng-container *ngIf="settings">
  <div class="w-100 text-center">
    <div class="card-body">
      <ng-container *ngIf="settings?.svgIconPath; else falIcon">
        <img src="{{ settings.svgIconPath }}" class="feedback-icon" alt="Feedback Icon" />
      </ng-container>

      <ng-template #falIcon>
        <i class="fal fal-icon" [ngClass]="settings?.falIcon ?? 'fa-question'"></i>
      </ng-template>

      <h5 class="card-title">{{ settings?.feedbackTitle }}</h5>
      <p class="card-text">{{ settings?.feedbackText }}</p>
      <button
        *ngIf="settings?.buttonDisplay"
        [ngClass]="settings?.buttonClass"
        (click)="onBtnClick()"
      >
        {{ settings?.buttonLabel }}
      </button>
    </div>
  </div>
</ng-container>
