import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';
import { SecuredLayoutComponent } from './shared/components/_layout/secured-layout/secured-layout.component';
import { NgModule } from '@angular/core';
// import { environment } from '../environments/environment';

const routes: Routes = [
  // Public Routes
  {
    path: 'public',
    loadChildren: () => import('./features/public/public.module').then(m => m.PublicModule),
  },

  // Secured Routes
  {
    path: '',
    component: SecuredLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        loadChildren: () =>
          import('./features/dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      // {
      //   path: 'account',
      //   loadChildren: () => import('./features/account/account.module').then(m => m.AccountModule),
      // },
      {
        path: 'ws',
        loadChildren: () =>
          import('./features/workspace-common/workspace-common.module').then(
            m => m.WorkspaceCommonModule,
          ),
      },
    ],
  },
  {
    path: 'shared-view',
    loadChildren: () => import('./shared-view/shared-view.module').then(m => m.SharedViewModule),
  },
];
routes.push({ path: '**', redirectTo: '', pathMatch: 'full' });

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // useHash: false,
      enableTracing: false,
      relativeLinkResolution: 'corrected',
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
      onSameUrlNavigation: 'reload',
      scrollOffset: [0, 64],
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
