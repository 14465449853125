<div class="sidebar-profile" [ngClass]="{ 'sidebar-profile--collapsed': isCollapsed }">
  <div class="sidebar-profile__photo-container">
    <app-node-avatar
      class="sidebar-profile__avatar"
      [profile]="user?.profile"
      [profileIcon]="user?.metaData?.profileIcon"
      [initials]="user?.initials"
      [fullName]="user?.firstName + ' ' + user?.lastName"
    ></app-node-avatar>
  </div>
  <div class="sidebar-profile__content-container">
    <div class="sidebar-profile__left-section" *ngIf="!isCollapsed">
      <span class="sidebar-profile__name">{{ user?.firstName }} {{ user?.lastName }}</span>
      <span class="sidebar-profile__role">{{ user | userRoleLabel }}</span>
    </div>
    <div class="sidebar-profile__right-section">
      <span class="sidebar-profile__badge badge badge-primary"
        >Beta {{ environment?.version }}</span
      >
    </div>
  </div>
</div>
