import { NodeRateModel } from '../../core/models/node-rate.model';

export class NodeRatesState {
  byId: { [id: string]: NodeRateModel };
  byNodeId: { [id: string]: NodeRateModel[] };
}

export const initialNodeRatesState: NodeRatesState = {
  byId: {},
  byNodeId: {},
};
