import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { InlineEditInputType } from '../../../core/constants/inline-edit-input-type';
import { InlineEditInputLayoutComponent } from '../inline-edit-input-layout/inline-edit-input-layout.component';
import { WidgetType } from '../../../core/constants/widget-type';

@Component({
  selector: 'app-inline-edit-textbox',
  templateUrl: './inline-edit-textbox.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InlineEditTextboxComponent,
      multi: true,
    },
  ],
})
/**
 * This inline edit Supports textbox and number types.
 * And it supports Save and Cancel buttons which inline-edit-number and inline-edit-currency do not have.
 */
export class InlineEditTextboxComponent implements OnInit, ControlValueAccessor {
  @ViewChild(InlineEditInputLayoutComponent) layoutComponent;

  @Input() set value(value: string) {
    this._currentValue = value;
    this._originValue = value;
  }

  get value() {
    return this._currentValue;
  }

  @Input() inputType: InlineEditInputType;

  @Input() confirmBtnLabel: string;

  @Input() truncateLimit: number;

  @Input() displayTemplate: TemplateRef<any>;

  @Output() changeValue = new EventEmitter<string | number>();

  public _currentValue: string;
  private _originValue: string;

  WidgetType = WidgetType;

  onChanged: any = () => {};
  onTouched: any = () => {};

  constructor() {}

  ngOnInit() {}

  // asyncSetEditMode function may be directly called by parent component
  public asyncSetEditMode(mode = true) {
    this.layoutComponent.asyncSetEditMode(mode);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  writeValue(value): void {
    this.value = value;
  }

  onClickOutside() {
    this.onSaveValue();
  }

  onSaveValue() {
    this.changeValue.emit(this.value);
    this.asyncSetEditMode(false);
  }

  onCancelValue() {
    this._currentValue = this._originValue;
    this.changeValue.emit(this.value);
    this.asyncSetEditMode(false);
  }

  public restoreValue() {
    // called by parent components to restore the value to original value
    this._currentValue = this._originValue;
  }
}
