import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NodeAvatarUploadModalComponent } from '../node-avatar-upload-modal/node-avatar-upload-modal.component';
import { FileModel } from '../../../core/models/file.model';
import { NodesActions } from '../../../store/nodes';

@Component({
  selector: 'app-node-avatar-menu',
  templateUrl: './node-avatar-menu.component.html',
})
export class NodeAvatarMenuComponent implements OnInit {
  @Input() profile: FileModel;
  @Input() nodeId: number;
  @Input() nodeType: number;
  @Input() profileIcon: { id: string; src: string };

  constructor(private store: Store, private modalService: NgbModal) {}

  ngOnInit(): void {}

  changePhoto() {
    const modalRef = this.modalService.open(NodeAvatarUploadModalComponent, { size: 'sm' });
    modalRef.componentInstance.profile = this.profile;
    modalRef.componentInstance.nodeId = this.nodeId;
    modalRef.componentInstance.profileIcon = this.profileIcon;
  }

  removePhoto() {
    this.store.dispatch(
      NodesActions.removeNodeProfileRequest({
        id: this.nodeId,
      }),
    );
  }
}
