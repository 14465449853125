import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { NodeTemplateEditableProps, NodeTemplateModel } from '../models/node-template.model';
import { map } from 'rxjs/operators';
import { ResponseMessage } from '../models/response-message.model';
import { NodeType } from '../constants/node-type';

@Injectable({ providedIn: 'root' })
export class NodeTemplateService {
  constructor(private http: HttpClient) {}

  public loadNodeTemplates(
    workspaceSlug: string,
    nodeTypes?: NodeType[],
  ): Observable<NodeTemplateModel[]> {
    const options = nodeTypes
      ? { params: new HttpParams().set('nodeTypes', JSON.stringify(nodeTypes)) }
      : {};
    return this.http
      .get<ResponseMessage<NodeTemplateModel[]>>(
        `${environment.apiBaseUrl}/workspace/${workspaceSlug}/node-templates`,
        options,
      )
      .pipe(map(response => response.data as NodeTemplateModel[]));
  }

  public loadArchivedNodeTemplates(
    slug: string,
    startDate: string,
    endDate: string,
  ): Observable<NodeTemplateModel[]> {
    const options = {
      params: new HttpParams()
        .set('archived', 1)
        .set('startDate', startDate ?? null)
        .set('endDate', endDate ?? null),
    };
    return this.http
      .get<ResponseMessage<NodeTemplateModel[]>>(
        `${environment.apiBaseUrl}/workspace/${slug}/node-templates`,
        options,
      )
      .pipe(map(response => response.data as NodeTemplateModel[]));
  }

  public addNodeTemplate(
    workspaceSlug: string,
    title: string,
    serverNodeType: NodeType,
  ): Observable<NodeTemplateModel> {
    return this.http
      .post<ResponseMessage<NodeTemplateModel>>(
        `${environment.apiBaseUrl}/workspace/${workspaceSlug}/node-template/add`,
        { title, nodeType: serverNodeType },
      )
      .pipe(map(response => response.data));
  }

  public setDefaultNodeTemplate(templateId: number) {
    return this.http
      .post<ResponseMessage<any>>(
        `${environment.apiBaseUrl}/workspace/node-template/${templateId}/set-default`,
        {},
      )
      .pipe(map(response => response.data));
  }

  public updateNodeTemplate(
    templateId: number,
    templateProps: Partial<NodeTemplateEditableProps>,
  ): Observable<NodeTemplateModel> {
    let props: any = {
      ...templateProps,
    };
    // API expects update props match the Database Entity fields
    if (props.allowedTemplateIds) {
      props.allowedTemplates = props.allowedTemplateIds;
      delete props.allowedTemplateIds;
    }
    return this.http
      .patch<ResponseMessage<NodeTemplateModel>>(
        `${environment.apiBaseUrl}/workspace/node-template/${templateId}/update`,
        {
          ...props,
        },
      )
      .pipe(map(response => response.data));
  }

  public archiveNodeTemplate(templateId: number): Observable<object> {
    return this.http.patch(
      `${environment.apiBaseUrl}/workspace/node-template/${templateId}/archive`,
      {
        archived: true,
      },
    );
  }

  public restoreArchivedNodeTemplate(templateId: number): Observable<object> {
    return this.http.patch(
      `${environment.apiBaseUrl}/workspace/node-template/${templateId}/archive`,
      {
        archived: false,
      },
    );
  }

  public removeNodeTemplate(templateId: number): Observable<object> {
    return this.http.delete(
      `${environment.apiBaseUrl}/workspace/node-template/${templateId}/remove`,
    );
  }

  public sortNodeTemplates(templateIds: number[]): Observable<any> {
    return this.http
      .post<ResponseMessage<any>>(`${environment.apiBaseUrl}/workspace/node-template/sort`, {
        ids: templateIds,
      })
      .pipe(map(response => response.data));
  }

  public copyNodeTemplate(templateId: number): Observable<NodeTemplateModel> {
    return this.http
      .post<ResponseMessage<NodeTemplateModel>>(
        `${environment.apiBaseUrl}/workspace/node-template/${templateId}/copy`,
        null,
      )
      .pipe(map(response => response.data));
  }
}
