import { Pipe, PipeTransform } from '@angular/core';
import { FigureModel } from '../../core/models/figure.model';
import { WidgetType } from '../../core/constants/widget-type';
import { CurrencyPipe } from '@angular/common';

@Pipe({
  name: 'formattedFigure',
})
export class FormattedFigurePipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) {}

  transform(figure: FigureModel): string | number {
    return figure?.widgetType === WidgetType.currency
      ? this.currencyPipe.transform(figure?.value)
      : figure?.value;
  }
}
