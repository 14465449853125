import { Component, Input, OnInit } from '@angular/core';
import { WorkspaceGroupModel } from '../../../core/models/workspace-group.model';

@Component({
  selector: 'app-link-group-view',
  templateUrl: './link-group-view.component.html',
  styleUrls: ['./link-group-view.component.scss'],
})
export class LinkGroupViewComponent implements OnInit {
  @Input() group: WorkspaceGroupModel;

  constructor() {}

  ngOnInit(): void {}

  goToLink(url) {
    window.open(url, '_blank');
  }

  trackBy(index, link) {
    return link.id;
  }
}
