import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { UserEditableFields, UserModel } from '../models/user.model';
import { RegisterModel } from '../models/register.model';
import { ResponseMessage } from '../models/response-message.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppState } from '../../store';
import { Store } from '@ngrx/store';
import { AuthActions } from '../../store/auth';
import { UserMetaModel } from '../models/user-meta.model';

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(
    private http: HttpClient,
    private modalService: NgbModal,
    private store: Store<AppState>,
  ) {}

  public hasOpenServerSession(): Observable<UserModel> {
    return this.http.get<UserModel>(`${environment.apiBaseUrl}/auth/active`).pipe(
      map(userObject => {
        this.store.dispatch(AuthActions.getOpenServerSessionSuccess({ user: userObject }));
        return userObject;
      }),
      catchError((err: HttpErrorResponse) => {
        console.error('HasOpenServerSession error: ', err);
        this.store.dispatch(AuthActions.getOpenServerSessionError());

        return throwError(err);
      }),
    );
  }

  public login(username: string, password: string, rememberMe: boolean): Observable<UserModel> {
    return this.http
      .post<UserModel>(`${environment.apiBaseUrl}/auth/login`, {
        username,
        password,
        _remember_me: rememberMe,
      })
      .pipe(
        map(userObject => {
          this.store.dispatch(AuthActions.loginSuccess({ user: userObject }));

          return userObject;
        }),
      );
  }

  public register(model: RegisterModel): Observable<ResponseMessage<any>> {
    // Symfony requires the passwords to be sent as an Object for comparison
    model.password = {
      password: model.password,
      passwordConfirm: model.passwordConfirm,
    };

    // Hacky: Server will reject invalid fields, so we need to remove this one.
    model.passwordConfirm = undefined;

    return this.http.post<ResponseMessage<any>>(`${environment.apiBaseUrl}/auth/register`, model);
  }

  public verify(hash: string): Observable<ResponseMessage<any>> {
    return this.http.get<ResponseMessage<any>>(`${environment.apiBaseUrl}/auth/verify/${hash}`);
  }

  public passwordReset(username: string): Observable<ResponseMessage<any>> {
    return this.http.post<ResponseMessage<any>>(`${environment.apiBaseUrl}/auth/password-reset`, {
      username,
    });
  }

  public passwordChange(
    hash: string,
    password1: string,
    password2: string,
  ): Observable<ResponseMessage<any>> {
    // Symfony requires the passwords to be sent as an Object for comparison
    const password = {
      password: password1,
      passwordConfirm: password2,
    };
    return this.http.post<ResponseMessage<any>>(`${environment.apiBaseUrl}/auth/password-change`, {
      hash,
      password,
    });
  }

  public invitationComplete(
    hash: string,
    password1: string,
    password2: string,
    firstName: string,
    lastName: string,
    phone: string,
  ): Observable<ResponseMessage<any>> {
    // Symfony requires the passwords to be sent as an Object for comparison
    const password = {
      password: password1,
      passwordConfirm: password2,
    };
    return this.http.post<ResponseMessage<any>>(
      `${environment.apiBaseUrl}/auth/invitation-complete`,
      {
        hash,
        password,
        firstName,
        lastName,
        phone,
      },
    );
  }

  // public getUser(id?: number): Observable<User> {
  //   return this.http.post<User>(`${environment.apiBaseUrl}/auth/user`, { id }).pipe(
  //     map((user: User) => {
  //       // See if it's the current user, refresh the local copy
  //       if (user && user.id && user.id === this.currentUser.id) {
  //         this.store.dispatch(AuthActions.getUserSuccess({ user }));
  //       }
  //       return user;
  //     }),
  //   );
  // }

  // public getUserSubscription(id?: number): Observable<UserSubscription> {
  //   return this.http
  //     .post<UserSubscription>(`${environment.apiBaseUrl}/user/subscription`, { id })
  //     .pipe(
  //       map((userSub: UserSubscription) => {
  //         // See if it's the current user's subscription, refresh the local copy
  //         if (userSub && userSub.user && userSub.user === this.currentUser.id) {
  //           this.setCurrentUserSubscription(userSub);
  //         }
  //
  //         return userSub;
  //       }),
  //     );
  // }

  public logout() {
    this.modalService.dismissAll('Logout');
    return this.http.post(`${environment.apiBaseUrl}/auth/logout`, {}).pipe(
      tap(() => {
        this.store.dispatch(AuthActions.logoutRequest());
      }),
      catchError(error => {
        console.error('error: ', error);
        return EMPTY;
      }),
    );
  }

  public patchCurrentUser(userFields: Partial<UserEditableFields>): Observable<UserModel> {
    return this.http.patch<UserModel>(`${environment.apiBaseUrl}/user/update`, { ...userFields });
  }

  public patchCurrentUserMeta(metaData: Partial<UserMetaModel>): Observable<UserMetaModel> {
    return this.http
      .patch<ResponseMessage<UserMetaModel>>(`${environment.apiBaseUrl}/user/meta/update`, metaData)
      .pipe(map(response => response.data));
  }

  public removeCurrentUserProfile(): Observable<object> {
    return this.http.delete(`${environment.apiBaseUrl}/user/profile/remove`);
  }
}
