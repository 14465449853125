<div
  class="assignment-primary-tag"
  (click)="requestEditAssignment(group.tags[0]?.id)"
  [ngClass]="[
    'assignment-primary-tag--' + (colorTheme | colorString),
    readonly ? 'assignment-primary-tag--readonly' : '',
    size ? 'assignment-primary-tag--' + size : '',
    hasPrimaryTag ? '' : 'assignment-primary-tag--no-value'
  ]"
>
  <div class="assignment-primary-tag__value">
    {{ hasPrimaryTag ? group.tags[0].tag.title : 'Select a tag' }}
  </div>

  <span
    *ngIf="(group?.tags)[0]?.tag?.icon"
    class="assignment-primary-tag__icon fal"
    [ngClass]="['fa-' + (hasPrimaryTag && group.tags[0].tag.icon | iconString)]"
  ></span>
</div>
