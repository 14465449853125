import { Action, createReducer, on } from '@ngrx/store';
import {
  FiguresEditPopoverState,
  initialFiguresEditPopoverState,
} from './figures-edit-popover.state';
import { FiguresEditPopoverActions } from './index';

const reducer = createReducer(
  initialFiguresEditPopoverState,
  on(FiguresEditPopoverActions.showFiguresEditPopover, (state, { assignmentId }) => {
    return {
      assignmentId,
    };
  }),
);

export function figuresEditPopoverReducer(state: FiguresEditPopoverState, action: Action) {
  return reducer(state, action);
}
