import { TagModel } from '../../core/models/tag.model';

export class TagsState {
  tagsById: { [id: number]: TagModel };
  ids: number[];
  searchKeyword: string;
}

export const initialTagsState: TagsState = {
  tagsById: {},
  ids: [],
  searchKeyword: undefined,
};
