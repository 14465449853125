import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ResponseMessage } from '../models/response-message.model';
import { map } from 'rxjs/operators';
import { TemplateTagModel } from '../models/template-tag.model';

@Injectable({ providedIn: 'root' })
export class TemplateTagService {
  constructor(private http: HttpClient) {}

  public addTemplateTags(
    templateId: number,
    tagIds: number[],
    groupId?: number,
    replace?: boolean,
  ): Observable<TemplateTagModel[]> {
    return this.http
      .post<ResponseMessage<TemplateTagModel[]>>(
        `${environment.apiBaseUrl}/workspace/node-template/${templateId}/add-tags`,
        {
          tags: tagIds,
          group: groupId,
          replace,
        },
      )
      .pipe(map(response => response.data));
  }

  public removeTemplateTag(templateId: number, joinId: number): Observable<object> {
    return this.http.delete(
      `${environment.apiBaseUrl}/workspace/node-template/${templateId}/tag/${joinId}/remove`,
    );
  }

  public sortTemplateTags(templateId: number, tagIds: number[]): Observable<any> {
    return this.http
      .post<ResponseMessage<any>>(
        `${environment.apiBaseUrl}/workspace/node-template/${templateId}/tag/sort`,
        { ids: tagIds },
      )
      .pipe(map(response => response.data));
  }
}
