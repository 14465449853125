import { WorkspaceModel } from '../../core/models/workspace.model';

export class WorkspacesState {
  bySlug: WorkspacesBySlug;
  currentSlug: string;
  ownedSlugs: string[];
  sharedSlugs: string[];
}

export const initialWorkspacesState: WorkspacesState = {
  bySlug: {},
  currentSlug: undefined,
  ownedSlugs: [],
  sharedSlugs: [],
};

export class WorkspacesBySlug {
  [slug: string]: WorkspaceModel;
}
