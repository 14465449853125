import { Component, Input, TemplateRef } from '@angular/core';
import { ModalComponent, ModalSize, ModalTheme } from '../modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-instructions',
  templateUrl: './modal-instructions.component.html',
  styleUrls: ['./modal-instructions.component.scss'],
})
export class ModalInstructionsComponent {
  public readonly ModalSize = ModalSize;
  public readonly ModalTheme = ModalTheme;

  @Input()
  public html: string;

  @Input()
  public template: TemplateRef<any>;

  constructor(public parent: ModalComponent, public activeModal: NgbActiveModal) {
    this.html = '';
  }
}
