import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WorkspaceCommonRoutingModule } from './workspace-common-routing.module';
import { WorkspaceCommonComponent } from './workspace-common.component';
import { SharedModule } from '../../shared/_shared.module';
import { RootFolderListComponent } from './root-folder-list/root-folder-list.component';

import { SidebarProfileComponent } from './root-folder-list/sidebar-profile/sidebar-profile.component';
import { FolderTreeRootComponent } from './root-folder-list/folder-tree-root/folder-tree-root.component';
import { FolderTreeNodeComponent } from './root-folder-list/folder-tree-node/folder-tree-node.component';
import { SidebarSectionComponent } from './root-folder-list/sidebar-section/sidebar-section.component';
import { FolderExpansionComponent } from './root-folder-list/folder-expansion/folder-expansion.component';

import { NodeListComponent } from './node-list/node-list.component';
import { FolderListComponent } from './node-list/folder-list/folder-list.component';
import { FolderListItemComponent } from './node-list/folder-list-item/folder-list-item.component';
import { LeafListComponent } from '../../features/workspace-common/node-list/leaf-list/leaf-list.component';
import { LeafListItemComponent } from '../../features/workspace-common/node-list/leaf-list-item/leaf-list-item.component';
import { ChatbarComponent } from './chatbar/chatbar.component';
import { ChatComponent } from './chatbar/chat/chat.component';
import { ChatCommentComponent } from './chatbar/chat/chat-comment/chat-comment.component';
import { ChatCommentBubbleComponent } from './chatbar/chat/chat-comment-bubble/chat-comment-bubble.component';
import { ChatCommentNewComponent } from './chatbar/chat/chat-comment-new/chat-comment-new.component';
import { ChatChannelsComponent } from './chatbar/chat-channels/chat-channels.component';
import { ChatChannelItemComponent } from './chatbar/chat-channels/chat-channel-item/chat-channel-item.component';
import { ChatFilePreviewComponent } from './chatbar/chat/chat-file-preview/chat-file-preview.component';
import { ChatCommentImageComponent } from './chatbar/chat/chat-comment-image/chat-comment-image.component';
import { ChatCommentDateComponent } from './chatbar/chat/chat-comment-date/chat-comment-date.component';
import { TextFieldModule } from '@angular/cdk/text-field';
import { WorkspaceSettingsComponent } from './workspace-settings/workspace-settings.component';
import { NodeTemplatesComponent } from './node-templates/node-templates.component';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ContextButtonsComponent } from './root-folder-list/context-buttons/context-buttons.component';
import { FolderMenuComponent } from './root-folder-list/folder-menu/folder-menu.component';
import { LeafMenuComponent } from './node-list/leaf-menu/leaf-menu.component';
import { TemplateTypeListComponent } from './node-templates/templates-nav/template-type-list/template-type-list.component';
import { TemplateTypeItemComponent } from './node-templates/templates-nav/template-type-item/template-type-item.component';
import { TemplateListComponent } from './node-templates/templates-nav/template-list/template-list.component';
import { TemplateMenuComponent } from './node-templates/templates-nav/template-menu/template-menu.component';
import { NodeListDisplaySettingsComponent } from './node-list/node-list-display-settings/node-list-display-settings.component';
import { MultiSelectControlsComponent } from './node-list/multi-select-controls/multi-select-controls.component';
import { PropertiesPanelComponent } from './properties-panel/properties-panel.component';
import { NgxPrintModule } from 'ngx-print';
import { ChatModalComponent } from './chatbar/chat-modal/chat-modal.component';
import { ChatSidebarHeaderComponent } from './chatbar/chat-sidebar-header/chat-sidebar-header.component';

@NgModule({
  declarations: [
    WorkspaceCommonComponent,
    RootFolderListComponent,
    SidebarProfileComponent,
    FolderTreeRootComponent,
    FolderTreeNodeComponent,
    SidebarSectionComponent,
    FolderExpansionComponent,
    ChatbarComponent,
    NodeListComponent,
    FolderListComponent,
    FolderListItemComponent,
    LeafListComponent,
    LeafListItemComponent,
    ContextButtonsComponent,
    MultiSelectControlsComponent,
    FolderMenuComponent,
    LeafMenuComponent,
    NodeListDisplaySettingsComponent,
    PropertiesPanelComponent,
    ChatComponent,
    ChatCommentComponent,
    ChatCommentBubbleComponent,
    ChatCommentNewComponent,
    ChatChannelsComponent,
    ChatChannelItemComponent,
    ChatFilePreviewComponent,
    ChatCommentImageComponent,
    ChatCommentDateComponent,
    ChatSidebarHeaderComponent,
    NodeTemplatesComponent,
    WorkspaceSettingsComponent,
    MainMenuComponent,
    TemplateTypeListComponent,
    TemplateTypeItemComponent,
    TemplateListComponent,
    TemplateMenuComponent,
    ChatModalComponent,
  ],
  imports: [
    CommonModule,
    WorkspaceCommonRoutingModule,
    NgbModule,
    SharedModule,
    TextFieldModule,
    NgxPrintModule,
  ],
  exports: [ChatbarComponent, ChatComponent],
})
export class WorkspaceCommonModule {}
