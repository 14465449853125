import { Pipe, PipeTransform } from '@angular/core';
import { NodeTemplateWidgetModel } from '../../core/models/node-template-widget.model';
import { WidgetService } from '../../core/services/widget.service';

@Pipe({
  name: 'widgetValue',
})
export class WidgetValuePipe implements PipeTransform {
  constructor(private widgetService: WidgetService) {}

  transform(widget: NodeTemplateWidgetModel): string {
    return this.widgetService.getWidgetValue(widget);
  }
}
