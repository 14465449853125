import { createAction, props } from '@ngrx/store';
import { DateRangeModel } from '../../core/models/date-range.model';
import { ToggleViewMode } from '../../core/constants/toggle-view-mode';
import { CustomViewNodeType } from '../../core/constants/custom-view-node-type';
import { AssignmentLayoutMode } from '../../core/constants/assignment-layout-mode';
import { AssignmentViewType } from '../../core/constants/assignment-view-type';
import { GroupingType } from '../../core/constants/grouping-type';
import { NodeMetasById } from '../../core/models/node-meta';
import { GanttChartGroupByType } from '../../core/constants/gantt-chart-group-by-type';
import { OverlaysViewSetting } from '../../core/constants/overlays-view.setting';
import { GanttChartViewType } from '../../core/constants/gantt-chart-view-type';
import { GanttChartViewSettingsModel } from '../../core/models/gantt-chart-view-settings.model';
import { ScheduleLayoutType } from '../../core/constants/schedule-layout-type';
import { WorkspaceUserMetaState } from './workspace-user-meta.state';
import { AssignmentDisplayMode } from '../../core/constants/assignment-display-mode';

export const loadCurrentWorkspaceUserMetaRequest = createAction(
  '[Workspace User Meta] load current workspace user meta request',
  props<{ workspaceSlug: string }>(),
);

export const loadCurrentWorkspaceUserMetaSuccess = createAction(
  '[Workspace User Meta] load current workspace user meta success',
  props<{ userMetaData: WorkspaceUserMetaState }>(),
);

export const updateNodeMetasByIdRequest = createAction(
  '[Workspace User Meta] update nodeMetasById',
  props<{ nodeMetasById: NodeMetasById }>(),
);

export const toggleSingleFolderHiddenRequest = createAction(
  '[Workspace User Meta] toggle whether folder is hidden request',
  props<{ nodeId: number }>(),
);

export const toggleFolderTreeExpansionFromSidebarRequest = createAction(
  '[Workspace User Meta] toggle whether the folder and its child folders are expanded from sidebar request',
  props<{ nodeId: number }>(),
);

export const expandFolderAfterAddingChildFolderRequest = createAction(
  '[Workspace User Meta] expand folder after adding child folder request',
  props<{ nodeId: number }>(),
);

export const changeAssignmentDateRangeRequest = createAction(
  '[Workspace User Meta] change assignment date range request',
  props<{ dateRange: DateRangeModel; slug?: string }>(),
);

export const changeLastAssignmentLayoutRequest = createAction(
  '[Workspace User Meta] change last assignment layout request',
  props<{ layout: AssignmentLayoutMode }>(),
);

export const changeLastScheduleLayoutRequest = createAction(
  '[Workspace User Meta] change last schedule layout request',
  props<{ scheduleLayout: ScheduleLayoutType }>(),
);

export const changeLastPlanBoardLayoutRequest = createAction(
  '[Workspace User Meta] change last plan board layout request',
  props<{ planBoardLayout: ScheduleLayoutType }>(),
);

export const changeGanttChartGroupByRequest = createAction(
  '[Workspace User Meta] change Gantt chart groupBy request',
  props<{ groupBy: GanttChartGroupByType }>(),
);

export const changeAssignmentViewRequest = createAction(
  '[Workspace User Meta] change assignment view request',
  props<{ viewType: AssignmentViewType }>(),
);

export const changeAssignmentViewTagsNodeTypesRequest = createAction(
  '[Workspace User Meta] change assignment view tags node types',
  props<{ nodeTypes: CustomViewNodeType[] }>(),
);

export const changeAssignmentViewWidgetsNodeTypesRequest = createAction(
  '[Workspace User Meta] change assignment view widgets node types',
  props<{ nodeTypes: CustomViewNodeType[] }>(),
);

export const changeGanttChartViewRequest = createAction(
  '[Workspace User Meta] change gantt chart view request',
  props<{ viewType: GanttChartViewType }>(),
);

export const changeGanttChartViewSettingsRequest = createAction(
  '[Workspace User Meta] change assignment view tags node types',
  props<{ viewSettings: GanttChartViewSettingsModel }>(),
);

export const changeFiguresViewTypesRequest = createAction(
  '[Workspace User Meta] change figures view types',
  props<{ viewTypes: OverlaysViewSetting[] }>(),
);

export const changeAssignmentGroupingRequest = createAction(
  '[Workspace User Meta] change assignment grouping request',
  props<{ grouping: GroupingType }>(),
);

export const changeAssignmentSortingRequest = createAction(
  '[Workspace User Meta] change assignment sortig request',
  props<{ sorting: string }>(),
);

export const changeAssignmentFilterPrimaryTagIdsRequest = createAction(
  '[Workspace User Meta] change assignment filter primary tag ids request',
  props<{ ids: number[] }>(),
);

export const changeAssignmentFilterProjectIdsRequest = createAction(
  '[Workspace User Meta] change assignment project ids request',
  props<{ ids: number[] }>(),
);

export const changeAssignmentFilterLayoutIdsRequest = createAction(
  '[Workspace User Meta] change assignment layout ids request',
  props<{ ids: number[] }>(),
);

export const changeAssignmentFilterAssetIdsRequest = createAction(
  '[Workspace User Meta] change assignment asset ids request',
  props<{ ids: number[] }>(),
);

export const cancelAssignmentFiltersRequest = createAction(
  '[Workspace User Meta] cancel assignment filters request',
);

export const changeAwaitingAssignmentViewModeRequest = createAction(
  '[Workspace User Meta] change whether awaiting assignments are shown request',
  props<{ mode: ToggleViewMode }>(),
);

export const changeAssignmentHeaderDetailsViewModeRequest = createAction(
  '[Workspace User Meta] change whether assignment header details is shown request',
  props<{ mode: ToggleViewMode }>(),
);

export const changeAssignmentAssetProfilePhotoViewModeRequest = createAction(
  '[Workspace User Meta] change whether assignment asset profile photo is shown request',
  props<{ mode: ToggleViewMode }>(),
);

export const changeAssignmentAssetsAsListViewModeRequest = createAction(
  '[Workspace User Meta] change whether assignment asset list mode is shown request',
  props<{ mode: ToggleViewMode }>(),
);

export const changeAssignmentOverlayViewModeRequest = createAction(
  '[Workspace User Meta] change whether assignment overlay view mode request',
  props<{ mode: AssignmentDisplayMode }>(),
);

export const setContentPanelTagsViewModeRequest = createAction(
  '[Workspace User Meta] set content panel view mode request',
  props<{ mode: ToggleViewMode }>(),
);

export const setContentPanelWidgetsViewModeRequest = createAction(
  '[Workspace User Meta] set content panel widgets view mode request',
  props<{ mode: ToggleViewMode }>(),
);

export const setContentPanelLayoutTemplateViewModeRequest = createAction(
  '[Workspace User Meta] set content panel layout template view mode request',
  props<{ mode: ToggleViewMode }>(),
);

export const changeDefaultSelectedAssignmentChecklistId = createAction(
  '[Workspace User Meta] change default selected assignment checklist id',
  props<{ checklistId: number }>(),
);

export const changeSelectedChecklistIdOfAssignment = createAction(
  '[Workspace User Meta] change selected checklist id of assignment',
  props<{ checklistId: number; assignmentId: number }>(),
);

export const changeSelectedChecklistIdOfAssignmentFromNodeChecklistEditPopover = createAction(
  '[Workspace User Meta] change selected checklist id of assignment from node-checklist-edit popover',
  props<{ checklistId: number; assignmentId: number }>(),
);

export const setSidebarLeft = createAction(
  '[Workspace User Meta] set sidebar left request',
  props<{ visible: boolean }>(),
);

export const setSidebarRight = createAction(
  '[Workspace User Meta] set sidebar right request',
  props<{ visible: boolean }>(),
);

export const setTableProjectIds = createAction(
  '[Workspace User Meta] set table project ids request',
  props<{ ids: number[] }>(),
);

export const setTableAssetIds = createAction(
  '[Workspace User Meta] set table asset ids request',
  props<{ ids: number[] }>(),
);

export const setTableTagIds = createAction(
  '[Workspace User Meta] set table tag ids request',
  props<{ ids: number[] }>(),
);

export const setTableGroupIds = createAction(
  '[Workspace User Meta] set table group ids request',
  props<{ ids: number[] }>(),
);

export const setTableNodeTemplateIds = createAction(
  '[Workspace User Meta] set table node template ids request',
  props<{ ids: number[] }>(),
);

export const updateWorkspaceUserProfileIcon = createAction(
  '[Workspace User Meta] update user profile icon request',
  props<{ profileIcon: { id: string; src: string }; currentUserId?: number }>(),
);

export const clearAllFilters = createAction('[Workspace User Meta] clear all filters request');
