import {Component, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../store';
import {Router} from '@angular/router';
import {AppLayoutActions} from '../../../store/app-layout';
import {
  selectCurrentWorkspace,
  selectRecentWorkspaces,
} from '../../../store/workspaces/workspaces.selectors';
import {WorkspaceModel} from '../../../core/models/workspace.model';
import {AuthActions} from '../../../store/auth';
import {AccountModalComponent} from '../../../shared/components/account-modal/account-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {selectLoggedInWorkspaceUser} from '../../../store/workspace-users/workspace-users.selectors';
import {WorkspaceUserModel} from '../../../core/models/workspace-user.model';
import {PermissionType} from '../../../core/constants/permission-type';
import {AssignmentLayoutMode} from "../../../core/constants/assignment-layout-mode";
import {IconType} from "../../../core/constants/icon-type";
import {reportOptions} from "../../../core/constants/assignment-display-mode";

@Component({
  selector: 'app-main-menu',
  templateUrl: 'main-menu.component.html',
  styleUrls: ['main-menu.component.scss'],
})
export class MainMenuComponent implements OnDestroy {
  private readonly subscription: Subscription = new Subscription();

  public readonly PermissionType = PermissionType;

  public user: WorkspaceUserModel;
  public workspace: WorkspaceModel;
  public recentWorkspaces: WorkspaceModel[];

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private modalService: NgbModal,
  ) {
    this.subscription.add(
      this.store.pipe(select(selectLoggedInWorkspaceUser)).subscribe(user => {
        this.user = user;
      }),
    );

    this.subscription.add(
      this.store.pipe(select(selectCurrentWorkspace)).subscribe(workspace => {
        this.workspace = workspace;
      }),
    );

    this.subscription.add(
      this.store.pipe(select(selectRecentWorkspaces)).subscribe(workspaces => {
        this.recentWorkspaces = workspaces;
      }),
    );
  }

  public myAccount(event) {
    const modelRef = this.modalService.open(AccountModalComponent, {
      size: 'sm',
    });
  }

  public logout(event) {
    this.store.dispatch(AuthActions.logoutRequest());
    this.router.navigate(['/public/login']);
  }

  public closeMenu() {
    this.store.dispatch(AppLayoutActions.closeSidebarRight());
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  protected readonly AssignmentLayoutMode = AssignmentLayoutMode;
  protected readonly IconType = IconType;
  protected readonly reportOptions = reportOptions;
}

