import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NodeService } from '../../core/services/node.service';
import { ArchivePopoverActions } from './index';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ErrorsActions } from '../errors';

@Injectable()
export class ArchivePopoverEffects {
  constructor(private actions$: Actions, private nodeService: NodeService) {}

  checkReferenceCountRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArchivePopoverActions.checkReferenceCountRequest),
      mergeMap(action =>
        this.nodeService.getAssignmentReferenceCount(action.nodeIds).pipe(
          map(count => ArchivePopoverActions.checkReferenceCountSuccess({ referenceCount: count })),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );
}
