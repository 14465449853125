// for element and asset types
import { createSelector } from '@ngrx/store';
import {
  selectSharedViewServerNodeTemplates,
  selectSharedViewServerNodeTemplatesById,
} from './shared-view.selectors';
import { NodeType } from '../../core/constants/node-type';
import { TemplateViewModel } from '../../core/models/template-view.model';
import { NodeTemplateModel } from '../../core/models/node-template.model';

export const selectSharedViewServerNodeTemplateIdsByServerNodeType = createSelector(
  selectSharedViewServerNodeTemplates,
  templates => {
    let templateIdsByServerNodeType = {};
    (templates || []).forEach(template => {
      templateIdsByServerNodeType = {
        ...templateIdsByServerNodeType,
        [template.nodeType]: [
          ...(templateIdsByServerNodeType[template.nodeType] || []),
          template.id,
        ],
      };
    });

    return templateIdsByServerNodeType;
  },
);

export const selectSharedViewProjectTemplateIds = createSelector(
  selectSharedViewServerNodeTemplateIdsByServerNodeType,
  (templateIdsByServerNodeType): number[] => {
    return [
      ...(templateIdsByServerNodeType[NodeType.project] || []),
      ...(templateIdsByServerNodeType[NodeType.assignment] || []),
    ];
  },
);

export const selectSharedViewProjectTemplates = createSelector(
  selectSharedViewProjectTemplateIds,
  selectSharedViewServerNodeTemplatesById,
  (ids, byId) => {
    return ids.map(id => byId[id]).filter(template => !!template);
  },
);

export const selectSharedViewElementTemplateByServerNodeType = createSelector(
  selectSharedViewServerNodeTemplates,
  selectSharedViewServerNodeTemplateIdsByServerNodeType,
  selectSharedViewServerNodeTemplatesById,
  (
    templates,
    idsByServerNodeType,
    templatesById,
  ): { [key in NodeType]?: NodeTemplateModel[] } => {
    const serverNodeTypes = [NodeType.element, NodeType.asset];

    let templatesByServerNodeType = {};
    serverNodeTypes.forEach(type => {
      const ids = idsByServerNodeType[type] || [];
      const models: TemplateViewModel[] = ids
        .map(id => templatesById[id])
        .filter(template => !!template);
      templatesByServerNodeType = {
        ...templatesByServerNodeType,
        [type]: models,
      };
    });
    return templatesByServerNodeType;
  },
);

export const selectSharedViewElementTemplateData = createSelector(
  selectSharedViewElementTemplateByServerNodeType,
  (
    elementTemplatesByServerNodeType,
  ): {
    defaultElementTemplates: NodeTemplateModel[];
    assetTemplates: NodeTemplateModel[];
  } => {
    const defaultElementTemplates = elementTemplatesByServerNodeType[NodeType.element] || [];
    const assetTemplates = elementTemplatesByServerNodeType[NodeType.asset] || [];
    return {
      defaultElementTemplates,
      assetTemplates,
    };
  },
);

export const selectSharedViewElementTemplatesDataByProjectTemplateId = createSelector(
  selectSharedViewElementTemplateData,
  selectSharedViewProjectTemplates,
  (
    data,
    projectTemplates,
  ): {
    [id: number]: {
      defaultElementTemplates: NodeTemplateModel[];
      assetTemplates: NodeTemplateModel[];
    };
  } => {
    let dataByTemplateId = {};
    (projectTemplates || []).forEach((template: NodeTemplateModel) => {
      dataByTemplateId = {
        ...dataByTemplateId,
        [template.id]: {
          defaultElementTemplates: data.defaultElementTemplates.filter(
            model =>
              template.allowedTemplateIds
                .map(id => (typeof id === 'string' ? parseInt(id, 10) : id))
                .indexOf(model.id) >= 0,
          ),
          assetTemplates: data.assetTemplates.filter(
            model =>
              template.allowedTemplateIds
                .map(id => (typeof id === 'string' ? parseInt(id, 10) : id))
                .indexOf(model.id) >= 0,
          ),
        },
      };
    });

    return dataByTemplateId;
  },
);
