import { Pipe, PipeTransform } from '@angular/core';
import { NodeTemplateWidgetModel } from '../../core/models/node-template-widget.model';

@Pipe({
  name: 'nodeWidgetsTitle',
})
export class NodeWidgetsTitlePipe implements PipeTransform {
  transform(value: NodeTemplateWidgetModel[]): string {
    return value?.length ? value?.map(item => item?.widget?.title?.toUpperCase())?.join(', ') : '-';
  }
}
