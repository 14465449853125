import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FileUploaderService } from '../file-uploader.service';
import { FileModel } from '../../../../core/models/file.model';

export class FileParams {
  public width = 0;
  public height = 0;
  public duration = 0;
}

@Component({
  selector: 'app-file-lazy-loader',
  templateUrl: 'file-lazy-loader.component.html',
  styleUrls: ['file-lazy-loader.component.css'],
})
export class FileLazyLoaderComponent implements OnInit, OnChanges {
  @ViewChild('image') imgElement: ElementRef;
  @ViewChild('document') objectElement: ElementRef;
  @ViewChild('video') videoElement: ElementRef;
  @ViewChild('audio') audioElement: ElementRef;

  @Input() public file: FileModel;
  @Output() public loadingComplete: EventEmitter<FileParams>;

  public loading: boolean;

  constructor(protected fileUploader: FileUploaderService) {
    this.file = new FileModel();
    this.loadingComplete = new EventEmitter<FileParams>();
    this.loading = true;
  }

  public ngOnInit(): void {}

  public ngOnChanges(changes: SimpleChanges) {}

  public onLoadingComplete(event: any) {
    const params = new FileParams();
    if (this.isImage()) {
      const element = this.imgElement.nativeElement as HTMLImageElement;
      params.width = element.width;
      params.height = element.height;
    } else if (this.isVideo()) {
      const element = this.videoElement.nativeElement as HTMLVideoElement;
      params.width = element.videoWidth;
      params.height = element.videoHeight;
      params.duration = element.duration;
    } else if (this.isAudio()) {
      const element = this.audioElement.nativeElement as HTMLAudioElement;
      params.duration = element.duration;
    } else if (this.isDocument()) {
      const element = this.objectElement.nativeElement as HTMLObjectElement;
    }

    this.loadingComplete.emit(params);
    this.loading = false;
    console.info('LOADING COMPLETE!');
  }

  public isImage() {
    return this.fileUploader.isImage(this.file);
  }

  public isDocument() {
    return this.fileUploader.isDocument(this.file);
  }

  public isVideo() {
    return this.fileUploader.isVideo(this.file);
  }

  public isAudio() {
    return this.fileUploader.isAudio(this.file);
  }

  public isUnknown() {
    return this.fileUploader.isUnknown(this.file);
  }
}
