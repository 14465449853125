import { AppState } from '../index';
import { createSelector } from '@ngrx/store';
import { NodeListPanelState } from '../../store/node-list-panel/node-list-panel.state';
import { folderViewUtil } from '../../core/utils/folder-view.util';
import { selectNodeTemplatesById } from '../templates/templates.selectors';
import { selectTagsById } from '../tags/tags.selectors';
import { TagViewModel } from '../../core/models/tag-view.model';
import { AssignmentLayoutMode } from '../../core/constants/assignment-layout-mode';
import { selectLoggedInWorkspaceUser } from '../workspace-users/workspace-users.selectors';
import { canUser } from '../../core/utils/permissions.util';
import { PermissionType } from '../../core/constants/permission-type';
import { selectRouterDerivedAssignmentLayout } from '../router/router-derived.selectors';
import { NodeTemplateModel } from '../../core/models/node-template.model';
import {
  createRichSearchKeywordItem,
  createRichSearchLayoutItem,
  createRichSearchTagItem,
  createRichSearchTemplateItem,
  createRichSearchUnassignedDateItem,
  filterCategoryRichSearchItems,
  RichSearchItemModel,
} from '../../core/models/rich-search-item.model';
import { RichSearchType } from '../../core/constants/rich-search-type';
import { NodeModel } from '../../core/models/node.model';
import { NodeEnrichmentSelectors, NodesSelectors } from '../nodes';
import { NodeType } from '../../core/constants/node-type';
import { selectNodesById, selectRootNodesById } from '../nodes/nodes.selectors';
import { NodeUtils } from '../../core/utils/node.util';
import { TemplateWidgetsSelectors } from '../template-widgets';
import { PropertiesPanelSelectors } from '../properties-panel';
import {
  selectNodeRelatedData,
  selectWorkspaceRelatedData,
} from '../properties-panel/properties-panel.selectors';

const selectNodeListPanelState = (state: AppState): NodeListPanelState => state.contentPanel;

export const selectNodeListPanelSelectedNode = createSelector(
  selectNodeListPanelState,
  NodesSelectors.selectRootNodeFilteredByNodeType(NodeType.projectFolder),
  selectNodeRelatedData,
  selectWorkspaceRelatedData,
  TemplateWidgetsSelectors.selectTemplateWidgetsByTemplateId,
  (
    state: NodeListPanelState,
    projectRootFolder: NodeModel,
    {
      nodeMetasById,
      nodeTagsByNodeId,
      nodeRatesByNodeId,
      nodeRateValuesByNodeId,
      nodeWidgetsByNodeId,
      nodeWidgetRowsByNodeId,
      nodeGroupsByNodeId,
      nodeTemplatesById,
    },
    {
      nodesById,
      nodesByParentId,
      tagsById,
      widgetsById,
      templatesById,
      groupsById,
      groupsByTemplateId,
      timesheetsByNodeId,
    },
    widgetsByTemplateId,
  ) => {
    return NodeUtils.enrichNode(
      state.selectedNode || projectRootFolder,
      //nodeMetasById,
      nodeTagsByNodeId,
      nodeRatesByNodeId,
      nodeRateValuesByNodeId,
      nodeWidgetsByNodeId,
      nodeWidgetRowsByNodeId,
      nodeGroupsByNodeId,
      nodeTemplatesById,
      nodesById,
      nodesByParentId,
      tagsById,
      widgetsById,
      groupsById,
      groupsByTemplateId,
      widgetsByTemplateId,
      timesheetsByNodeId,
    );
  },
);

export const selectNodeListPanelSearchKeyword = createSelector(
  selectNodeListPanelState,
  state => state.searchKeyword,
);

export const selectContentPanelFilterTagIds = createSelector(
  selectNodeListPanelState,
  state => state.filterTagIds,
);

export const selectContentPanelFilterFolderIds = createSelector(
  selectNodeListPanelState,
  state => state.filterFolderIds,
);

export const selectContentPanelFilterTemplateIds = createSelector(
  selectNodeListPanelState,
  state => state.filterTemplateIds,
);

export const selectContentPanelFilterLayoutTemplateIds = createSelector(
  selectNodeListPanelState,
  state => state.filterLayoutTemplateIds,
);

export const selectContentPanelFilterUnassignedDateKeys = createSelector(
  selectNodeListPanelState,
  state => state.filterUnassignedDateKeys,
);

export const selectNodeListPanelSelectedNodeIds = createSelector(
  selectNodeListPanelState,
  (state: NodeListPanelState) => state && state.selectedNodeIds,
);

export const selectNodeListPanelIsDraggingOfSelected = createSelector(
  selectNodeListPanelState,
  (state: NodeListPanelState) => state && state.isDraggingOfSelected,
);

export const selectNodeListPanelIsReadonly = createSelector(
  selectRouterDerivedAssignmentLayout,
  selectLoggedInWorkspaceUser,
  (layout, loggedInUser) => {
    return (
      !canUser(loggedInUser, PermissionType.editWorkspace) || layout === AssignmentLayoutMode.chart
    );
  },
);

export const selectNodeListPanelRootNode = createSelector(
  selectNodeListPanelSelectedNode,
  selectRootNodesById,
  (node, rootNodesById) => {
    if (node == null) {
      return null;
    }
    const parentNodeType = NodeUtils.getParentNodeType(node.nodeType);
    return Object.values(rootNodesById).find(n => n.nodeType === parentNodeType);
  },
);

export const selectNodeListPanelIsRootNode = createSelector(
  selectNodeListPanelSelectedNode,
  selectNodeListPanelRootNode,
  (node, rootNode) => node && rootNode && node.id === rootNode.id,
);

export const selectNodeListPanelNodes = createSelector(
  selectNodeListPanelSelectedNode,
  PropertiesPanelSelectors.selectNodeRelatedData,
  PropertiesPanelSelectors.selectWorkspaceRelatedData,
  TemplateWidgetsSelectors.selectTemplateWidgetsByTemplateId,
  (
    selectedNode,
    {
      nodeTagsByNodeId,
      nodeRatesByNodeId,
      nodeRateValuesByNodeId,
      nodeWidgetsByNodeId,
      nodeWidgetRowsByNodeId,
      nodeGroupsByNodeId,
      nodeTemplatesById,
    },
    {
      nodesById,
      nodesByParentId,
      tagsById,
      widgetsById,
      groupsById,
      groupsByTemplateId,
      timesheetsByNodeId,
    },
    widgetsByTemplateId,
  ): NodeModel[] => {
    if (selectedNode == null) {
      return [];
    }

    return (nodesByParentId[selectedNode.id] || []).map(node => {
      // Shortcuts must be handled differently
      const targetNode =
        NodeUtils.isShortcut(node) === true ? nodesById[node.referenceNodeId] : node;

      return NodeUtils.enrichNode(
        targetNode,
        nodeTagsByNodeId,
        nodeRatesByNodeId,
        nodeRateValuesByNodeId,
        nodeWidgetsByNodeId,
        nodeWidgetRowsByNodeId,
        nodeGroupsByNodeId,
        nodeTemplatesById,
        nodesById,
        nodesByParentId,
        tagsById,
        widgetsById,
        groupsById,
        groupsByTemplateId,
        widgetsByTemplateId,
        timesheetsByNodeId,
      );
    });
  },
);

export const selectAllChildNodesForSearch = createSelector(
  selectNodeListPanelNodes,
  selectNodeListPanelSearchKeyword,
  PropertiesPanelSelectors.selectNodeRelatedData,
  PropertiesPanelSelectors.selectWorkspaceRelatedData,
  TemplateWidgetsSelectors.selectTemplateWidgetsByTemplateId,
  (
    nodes: NodeModel[],
    keywords,
    {
      nodeTagsByNodeId,
      nodeRatesByNodeId,
      nodeRateValuesByNodeId,
      nodeWidgetsByNodeId,
      nodeWidgetRowsByNodeId,
      nodeGroupsByNodeId,
      nodeTemplatesById,
    },
    {
      nodesById,
      nodesByParentId,
      tagsById,
      widgetsById,
      groupsById,
      groupsByTemplateId,
      timesheetsByNodeId,
    },
    widgetsByTemplateId,
  ) => {
    const lowerCaseKeyword = (keywords || '').toLowerCase();
    let list = nodes;
    // if and only user search anything - get the child node info for filtering part
    if (lowerCaseKeyword?.trim()) {
      const repeat = (childItems: NodeModel[]) => {
        childItems.forEach(child => {
          const childNodes = nodesByParentId[child?.id];
          if (childNodes?.length) {
            // go through the child nodes as well.
            repeat(childNodes);
            list.push(...childNodes);
          }
        });
      };
      repeat(nodes);
    } else {
      return list;
    }
    // return more info about child nodes
    return list.map(node => {
      // Shortcuts must be handled differently
      const targetNode =
        NodeUtils.isShortcut(node) === true ? nodesById[node.referenceNodeId] : node;

      return NodeUtils.enrichNode(
        targetNode,
        nodeTagsByNodeId,
        nodeRatesByNodeId,
        nodeRateValuesByNodeId,
        nodeWidgetsByNodeId,
        nodeWidgetRowsByNodeId,
        nodeGroupsByNodeId,
        nodeTemplatesById,
        nodesById,
        nodesByParentId,
        tagsById,
        widgetsById,
        groupsById,
        groupsByTemplateId,
        widgetsByTemplateId,
        timesheetsByNodeId,
      );
    });
  },
);

export const selectNodeListPanelNodesWithFilters = createSelector(
  selectAllChildNodesForSearch,
  selectNodeListPanelSearchKeyword,
  selectContentPanelFilterTagIds,
  selectContentPanelFilterFolderIds,
  selectContentPanelFilterTemplateIds,
  selectContentPanelFilterLayoutTemplateIds,
  selectContentPanelFilterUnassignedDateKeys,
  NodeEnrichmentSelectors.selectDateKeyAssignmentSet,
  (
    nodes: NodeModel[],
    keywords,
    filterTagIds,
    filterFolderIds,
    filterTemplateIds,
    filterLayoutTemplateIds,
    filterUnassignedDateKeys,
    dateKeyAssignmentSet,
  ): NodeModel[] => {
    const lowerCaseKeyword = (keywords || '').toLowerCase();

    let unassignedNodeIdSet = new Set();
    (filterUnassignedDateKeys || []).forEach(dateKey => {
      unassignedNodeIdSet = new Set([
        ...unassignedNodeIdSet,
        ...(dateKeyAssignmentSet[dateKey] || []).map(n => n.referenceNodeId),
      ]);
    });

    return nodes.filter(n => {
      if (NodeUtils.isFolder(n)) {
        return true;
      }

      if (
        filterTagIds &&
        filterTagIds.length &&
        n.tags.some(nodeTag => filterTagIds.indexOf(nodeTag.id) >= 0) === false
      ) {
        return false;
      }

      if (
        filterFolderIds &&
        filterFolderIds.length &&
        filterFolderIds.indexOf(n.parentNodeId) === -1
      ) {
        return false;
      }

      if (
        filterTemplateIds &&
        filterTemplateIds.length &&
        filterTemplateIds.indexOf(n.nodeTemplateId) === -1
      ) {
        return false;
      }

      if (
        filterLayoutTemplateIds &&
        filterLayoutTemplateIds.length &&
        n.allowedTemplates.some(template => filterLayoutTemplateIds.indexOf(template.id) >= 0) ===
          false
      ) {
        return false;
      }

      if (unassignedNodeIdSet.size && unassignedNodeIdSet.has(n.id) === true) {
        return false;
      }

      if (lowerCaseKeyword && n.title.toLowerCase().indexOf(lowerCaseKeyword) === -1) {
        return false;
      }
      return true;
    });
  },
);

export const selectNodeListPanelDragDropConnectedLists = createSelector(
  selectNodeListPanelSelectedNode,
  NodeEnrichmentSelectors.selectCurrentDateKeys,
  NodesSelectors.selectAssignmentsById,
  (node: NodeModel, dateKeys, assignmentsById) => {
    if (node == null) {
      return undefined;
    }
    if (node.nodeType === NodeType.projectFolder) {
      return [
        `calendar-row-${NodeUtils.awaitingAssignmentsDateKey}`,
        ...dateKeys.map(dateKey => 'calendar-row-' + dateKey),
      ];
    } else if (node.nodeType === NodeType.assetFolder || node.nodeType === NodeType.elementFolder) {
      return Object.values(assignmentsById)
        .filter(a => a != null && a.nodeType === NodeType.assignment)
        .map(a => 'assignment' + a.id);
    }
    return undefined;
  },
);

export const selectNodeListPanelSelectedNodes = createSelector(
  selectNodeListPanelSelectedNodeIds,
  selectNodesById,
  (selectedNodesIds, nodesById) => {
    return selectedNodesIds.map(id => nodesById[id]);
  },
);

export const selectNodeListPanelSubFolderModels = createSelector(
  selectNodeListPanelNodes,
  nodes => {
    return nodes.filter(n => NodeUtils.isFolder(n));
  },
);

export const selectNodeListPanelUsedTagModels = createSelector(
  selectNodeListPanelNodes,
  selectTagsById,
  (nodes, tagsById): TagViewModel[] => {
    const { tagIds, countsById } = NodeUtils.getTagIdsAndCounts(nodes);
    return tagIds
      .map(id => tagsById[id])
      .filter(tag => !!tag)
      .map(tag => ({ ...tag, count: countsById[tag.id] }));
  },
);

export const selectNodeListPanelUsedTemplateModels = createSelector(
  selectNodeListPanelNodes,
  selectNodeTemplatesById,
  (nodes, templatesById): NodeTemplateModel[] => {
    const { templateIds, countsById } = NodeUtils.getTemplateIdsAndCounts(nodes);
    return templateIds
      .map(id => templatesById[id])
      .filter(template => !!template)
      .map(template => ({ ...template, count: countsById[template.id] }));
  },
);

export const selectNodeListPanelUsedLayoutTemplateModels = createSelector(
  selectNodeListPanelNodes,
  selectNodeTemplatesById,
  (folderViewModels, templatesById): NodeTemplateModel[] => {
    const { templateIds } = folderViewUtil.getUsedLayoutTemplateIds(folderViewModels);
    return templateIds.map(id => templatesById[id]).filter(template => !!template);
  },
);

export const selectNodeListPanelRichSearchFilterItems = createSelector(
  selectNodeListPanelUsedTagModels,
  selectNodeListPanelUsedTemplateModels,
  selectNodeListPanelUsedLayoutTemplateModels,
  NodeEnrichmentSelectors.selectCurrentDateKeys,
  (tags, templates, layoutTemplates, dateKeys) => {
    const tagSearchItems: RichSearchItemModel[] = tags.map(tag => {
      return createRichSearchTagItem(tag);
    });

    const templateSearchItems: RichSearchItemModel[] = templates.map(template => {
      return createRichSearchTemplateItem(template);
    });

    const layoutTemplateSearchItems: RichSearchItemModel[] = layoutTemplates.map(template => {
      return createRichSearchLayoutItem(template);
    });

    const unassignedDateItems: RichSearchItemModel[] = (dateKeys || []).map(dateKey => {
      return createRichSearchUnassignedDateItem(dateKey);
    });

    return [
      ...filterCategoryRichSearchItems,
      ...templateSearchItems,
      ...layoutTemplateSearchItems,
      ...tagSearchItems,
      ...unassignedDateItems,
    ];
  },
);

export const selectContentPanelSelectedSearchItems = createSelector(
  selectContentPanelFilterTemplateIds,
  selectContentPanelFilterLayoutTemplateIds,
  selectContentPanelFilterTagIds,
  selectContentPanelFilterFolderIds,
  selectContentPanelFilterUnassignedDateKeys,
  selectNodeListPanelSearchKeyword,
  selectNodeListPanelRichSearchFilterItems,
  (
    templateIds,
    layoutTemplateIds,
    tagIds,
    folderIds,
    dateKeys,
    searchKeyword,
    searchFilterItems,
  ) => {
    const searchKeywordItem = createRichSearchKeywordItem(searchKeyword);
    const selectedFilterItems = searchFilterItems.filter(item => {
      if (item.type === RichSearchType.template) {
        return templateIds.indexOf(item.entity.id as number) >= 0;
      } else if (item.type === RichSearchType.layout) {
        return layoutTemplateIds.indexOf(item.entity.id as number) >= 0;
      } else if (item.type === RichSearchType.tag) {
        return tagIds.indexOf(item.entity.id as number) >= 0;
      } else if (item.type === RichSearchType.folder) {
        return folderIds.indexOf(item.entity.id as number) >= 0;
      } else if (item.type === RichSearchType.unassignedDate) {
        return dateKeys.indexOf(item.entity.id as string) >= 0;
      }
      return false;
    });
    return [searchKeywordItem, ...selectedFilterItems];
  },
);
