import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from '../components/_components.module';
import { DirectivesModule } from '../directives/_directives.module';
import { PipesModule } from '../pipes/_pipes.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NodesRatesTableComponent } from './node-rates/nodes-rates-table.component';
import { AssignmentRatesTableComponent } from './assignment-rates/assignment-rates-table.component';
import { CumulativeNodesRatesTableComponent } from './cumulative-node-rates/cumulative-nodes-rates-table.component';
import { AssignmentTableFiltersComponent } from '../../features/workspace/assignment-table-filters/assignment-table-filters.component';
import { AssignmentsTableComponent } from './assignments/assignments-table.component';
import { TableModule } from '../table/table.module';
import { AssignmentTimesheetsTableComponent } from './assignment-timesheets/assignment-timesheets-table.component';
import { NodesRateValuesTableComponent } from './node-rate-values/nodes-rate-values-table.component';
import { AssignmentStampsTableComponent } from './assignment-stamps/assignment-stamps-table.component';
import { AssignmentFiguresTableComponent } from './assignments-figures/assignment-figures-table.component';
import { AssignmentChecklistsTableComponent } from './assignment-checklists/assignment-checklists-table.component';
import { AssignmentAssetPricingSummaryTableComponent } from './assignment-asset-pricing-summary/assignment-asset-pricing-summary-table.component';
import { AssignmentProjectsTableComponent } from './assignment-projects/assignment-projects-table.component';
import { AssignmentAssetsTableComponent } from './assignment-assets/assignment-assets-table.component';
import { AssignmentElementsTableComponent } from './assignment-elements/assignment-elements-table.component';
import { AssignmentTagsTableComponent } from './assignment-tags/assignment-tags-table.component';
import { DispatchScheduleTableComponent } from './dispatch-schedule-table/dispatch-schedule-table.component';
import { DispatchScheduleTableFiltersComponent } from '../../features/workspace/dispatch-schedule-table-filters/dispatch-schedule-table-filters.component';
import { AssignmentTableDateRangeFiltersComponent } from '../../features/workspace/assignment-table-date-range-filters/assignment-table-date-range-filters.component';

@NgModule({
  imports: [
    ReactiveFormsModule,
    NgbModule,
    ComponentsModule,
    DirectivesModule,
    PipesModule,
    TableModule,
  ],
  declarations: [
    AssignmentTableFiltersComponent,
    AssignmentTableDateRangeFiltersComponent,
    NodesRatesTableComponent,
    NodesRateValuesTableComponent,
    AssignmentsTableComponent,
    AssignmentStampsTableComponent,
    AssignmentAssetPricingSummaryTableComponent,
    AssignmentFiguresTableComponent,
    AssignmentTimesheetsTableComponent,
    AssignmentChecklistsTableComponent,
    AssignmentRatesTableComponent,
    AssignmentProjectsTableComponent,
    AssignmentAssetsTableComponent,
    AssignmentTagsTableComponent,
    AssignmentElementsTableComponent,
    CumulativeNodesRatesTableComponent,
    DispatchScheduleTableComponent,
    DispatchScheduleTableFiltersComponent,
  ],
  exports: [
    AssignmentTableFiltersComponent,
    AssignmentTableDateRangeFiltersComponent,
    NodesRatesTableComponent,
    NodesRateValuesTableComponent,
    AssignmentsTableComponent,
    AssignmentAssetPricingSummaryTableComponent,
    AssignmentStampsTableComponent,
    AssignmentFiguresTableComponent,
    AssignmentTimesheetsTableComponent,
    AssignmentChecklistsTableComponent,
    AssignmentRatesTableComponent,
    AssignmentProjectsTableComponent,
    AssignmentAssetsTableComponent,
    AssignmentTagsTableComponent,
    AssignmentElementsTableComponent,
    CumulativeNodesRatesTableComponent,
    DispatchScheduleTableComponent,
    DispatchScheduleTableFiltersComponent,
  ],
})
export class ZedulrTableModule {}
