import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  concatMap,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { WorkspacesActions } from './index';
import { DashboardService } from '../../core/services/dashboard.service';
import { ErrorsActions } from '../errors';
import { WorkspaceService } from '../../core/services/workspace.service';
import { Router } from '@angular/router';
import { NodeTagsActions } from '../node-tags';
import { NodeRatesActions } from '../node-rates';
import { NodesActions } from '../nodes';
import { NodeUtils } from '../../core/utils/node.util';
import { NodeWidgetsActions } from '../node-widgets';
import { WorkspaceUrlsActions } from '../workspace-urls';
import { WorkspaceModel } from '../../core/models/workspace.model';
import { WorkspaceUserGroupsActions } from '../workspace-user-groups';
import { WorkspaceGroupsActions } from '../workspace-groups';
import { TagsActions } from '../tags';
import { WidgetsActions } from '../widgets';
import { WorkspaceSharesActions } from '../workspace-shares';
import { TemplatesActions } from '../templates';
import { convertServerWorkspaceUserGroup } from '../../core/utils/server-workspace-user-group.util';
import { WorkspaceUsersActions } from '../workspace-users';
import { WorkspaceUserMetaActions } from '../workspace-user-meta';
import { select, Store } from '@ngrx/store';
import { selectWorkspacesCurrentSlug } from './workspaces.selectors';
import { NodeRateValuesActions } from '../node-rate-values';
import { NodeWidgetRowsActions } from '../node-widget-rows';
import { NodeGroupsActions } from '../node-groups';

@Injectable()
export class WorkspacesEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private dashboardService: DashboardService,
    private workspaceService: WorkspaceService,
    private router: Router,
  ) {}

  loadDashboardRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspacesActions.loadDashboardRequest),
      mergeMap(() =>
        this.dashboardService.getDashboard().pipe(
          map(dashboard =>
            WorkspacesActions.loadDashboardSuccess({
              owned: dashboard.owned,
              shared: dashboard.shared,
            }),
          ),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  addWorkspaceRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspacesActions.addWorkspaceRequest),
      mergeMap(action =>
        this.dashboardService.addWorkspace(action.props, action.workspaceTemplateId).pipe(
          map(workspace => WorkspacesActions.addWorkspaceSuccess({ workspace })),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  copyWorkspaceRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspacesActions.copyWorkspaceRequest),
      mergeMap(action =>
        this.dashboardService.copyWorkspace(action.title, action.slug).pipe(
          map(workspace =>
            WorkspacesActions.copyWorkspaceSuccess({ slug: action.slug, workspace }),
          ),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  saveWorkspaceAsTemplateRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspacesActions.saveWorkspaceAsTemplateRequest),
      mergeMap(action =>
        this.dashboardService.saveAsTemplate(action.props, action.slug).pipe(
          map(workspaceTemplate =>
            WorkspacesActions.saveWorkspaceAsTemplateSuccess({ template: workspaceTemplate }),
          ),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  deleteWorkspaceRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspacesActions.deleteWorkspaceRequest),
      mergeMap(action => {
        const slug = action.slug;
        return this.dashboardService.removeWorkspace(slug).pipe(
          map(responseMessage => WorkspacesActions.deleteWorkspaceSuccess({ slug })),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        );
      }),
    ),
  );

  leaveWorkspaceRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspacesActions.leaveWorkspaceRequest),
      mergeMap(action => {
        const slug = action.slug;
        return this.dashboardService.leaveWorkspace(slug).pipe(
          map(responseMessage => WorkspacesActions.leaveWorkspaceSuccess({ slug })),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        );
      }),
    ),
  );

  updateWorkspaceRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspacesActions.updateWorkspaceRequest),
      mergeMap(action => {
        const slug = action.slug;
        return this.dashboardService.updateWorkspace(slug, action.props).pipe(
          map(workspace => WorkspacesActions.updateWorkspaceSuccess({ slug, workspace })),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        );
      }),
    ),
  );

  patchWorkspaceMetaRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspacesActions.patchWorkspaceMetaRequest),
      mergeMap(action => {
        const slug = action.slug;
        return this.dashboardService.patchWorkspaceMeta(slug, action.metaData).pipe(
          map(metaData => WorkspacesActions.patchWorkspaceMetaSuccess({ slug, metaData })),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        );
      }),
    ),
  );

  deleteCurrentWorkspaceRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspacesActions.deleteCurrentWorkspaceRequest),
      mergeMap(action => {
        const slug = action.slug;
        return this.dashboardService.removeWorkspace(slug).pipe(
          map(responseMessage => WorkspacesActions.deleteWorkspaceSuccess({ slug })),
          tap(() => {
            this.router.navigate(['/']);
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        );
      }),
    ),
  );

  loadWorkspaceWithNodeTreeRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspacesActions.loadWorkspaceWithNodeTreeRequest),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      mergeMap(([action, slug]) => {
        return this.workspaceService.loadWorkspace(slug).pipe(
          switchMap((workspace: WorkspaceModel) => {
            const {
              nodes,
              nodeTags,
              nodeRates,
              nodeRateValues,
              nodeWidgetValues,
              nodeWidgetRows,
              nodeGroupValues,
            } = NodeUtils.parseNodeTree(workspace.tree);

            const groups = (workspace.userGroups || []).map(convertServerWorkspaceUserGroup);

            return [
              WorkspaceUserMetaActions.loadCurrentWorkspaceUserMetaSuccess({
                userMetaData: workspace.userMetaData,
              }),
              WorkspaceUrlsActions.loadCurrentWorkspaceUrlsSuccess({
                urls: workspace.urls,
              }),
              WorkspaceUserGroupsActions.loadCurrentWorkspaceUserGroupsSuccess({
                groups,
              }),
              WorkspaceUsersActions.loadCurrentWorkspaceUsersSuccess({
                users: workspace.users,
              }),
              WorkspaceGroupsActions.loadWorkspaceGroupsSuccess({ groups: workspace.groups }),
              TagsActions.loadTagsSuccess({ tags: workspace.tags }),
              WidgetsActions.loadWidgetsSuccess({ widgets: workspace.widgets }),
              WorkspaceSharesActions.loadSharedLinksSuccess({ sharedLinks: workspace.shares }),
              WorkspaceUrlsActions.loadCurrentWorkspaceUrlsSuccess({
                urls: workspace.urls,
              }),
              TemplatesActions.refreshNodeTemplatesSuccess({
                nodeTemplates: workspace.templates,
              }),
              NodesActions.loadNodesSuccess({ nodes }),
              NodeTagsActions.loadNodeTagsFromNodeTree({
                nodeTags: nodeTags,
              }),
              NodeRatesActions.loadNodeRatesFromNodeTree({
                nodeRates: nodeRates,
              }),
              NodeRateValuesActions.loadNodeRateValuesFromNodeTree({
                nodeRateValues: nodeRateValues,
              }),
              NodeWidgetsActions.loadNodeWidgetsFromNodeTree({
                nodeWidgets: nodeWidgetValues,
              }),
              NodeWidgetRowsActions.loadNodeWidgetRowsFromNodeTree({
                nodeWidgetRows: nodeWidgetRows,
              }),
              NodeGroupsActions.loadNodeGroupsFromNodeTree({
                nodeGroups: nodeGroupValues,
              }),
              WorkspacesActions.loadWorkspaceWithNodeTreeSuccess(),
            ];
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        );
      }),
    ),
  );

  refreshWorkspaceCacheRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspacesActions.refreshWorkspaceCacheRequest),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      mergeMap(([action, slug]) => {
        return this.workspaceService.refreshWorkspaceCache(slug, action.key).pipe(
          concatMap(metaData => [
            WorkspacesActions.refreshWorkspaceCacheSuccess({ key: action.key }),
            WorkspacesActions.loadWorkspaceWithNodeTreeRequest(),
          ]),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        );
      }),
    ),
  );
}
