import { createAction, props } from '@ngrx/store';

export const showLinkGroupCreatePage = createAction(
  '[Group Links Popover] show  link group create page',
);

export const showLinkGroupEditPage = createAction(
  '[Group Links Popover] show  link group edit page',
  props<{ groupId: number }>(),
);

export const showLinkCreatePage = createAction(
  '[Group Links Popover] show link create page',
  props<{ groupId: number }>(),
);

export const showLinkEditPage = createAction(
  '[Group Links Popover] show link edit page',
  props<{ linkId: number; groupId: number }>(),
);
