<div
  class="modal-header"
  [ngClass]="{
    'modal-header--template': template,
    'modal--sm': parent.size === ModalSize.Small,
    'modal--md': parent.size === ModalSize.Medium,
    'modal--lg': parent.size === ModalSize.Large,
    'modal--xl': parent.size === ModalSize.XLarge,
    'theme--info': parent.theme === ModalTheme.Info,
    'theme--success': parent.theme === ModalTheme.Success,
    'theme--warning': parent.theme === ModalTheme.Warning,
    'theme--danger': parent.theme === ModalTheme.Danger
  }"
>
  <ng-container *ngTemplateOutlet="template"></ng-container>

  <ng-container *ngIf="title">
    <img class="modal-title-icon" [src]="iconUrl" *ngIf="iconUrl">
    <h4 class="modal-title-addons">
      {{ title }}
    </h4>
    <ng-content select="[stepInfo]"></ng-content>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </ng-container>

  <ng-container *ngIf="!template && !title">
    <ng-content></ng-content>
  </ng-container>
</div>
