import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { selectChatChannelTotalUnreadCount } from '../../../store/chats/chats.selectors';
import { ChatsActions } from '../../../store/chats';
import { isPlatformBrowser } from '@angular/common';
import { ChatbarActions } from '../../../store/chatbar';

@Component({
  selector: 'app-nav-chat',
  templateUrl: './nav-chat.component.html',
  styleUrls: ['./nav-chat.component.scss'],
})
export class NavChatComponent implements OnInit, OnDestroy {
  private readonly subscription = new Subscription();

  private intervalId: any;

  public totalUnreadCount: number;

  constructor(@Inject(PLATFORM_ID) protected platformId, private store: Store) {}

  ngOnInit(): void {
    this.subscription.add(
      this.store.pipe(select(selectChatChannelTotalUnreadCount)).subscribe(totalUnreadCount => {
        this.totalUnreadCount = totalUnreadCount;
      }),
    );

    if (isPlatformBrowser(this.platformId) === true) {
      this.intervalId = window.setInterval(() => {
        if (window.navigator.onLine) {
          this.store.dispatch(ChatsActions.loadUnreadCommentsRequest());
        }
      }, 30000); // 30-seconds
    }
  }

  public openChatModal(): void {
    this.store.dispatch(ChatbarActions.openCurrentWorkspaceChannelRequest({}));
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId);
    this.subscription.unsubscribe();
  }
}
