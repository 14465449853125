import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { NodeTemplateModel } from '../../../core/models/node-template.model';
// import { TemplatesActions } from '../../../store/templates';
import { selectTemplatesPopoverSelectedTemplate } from '../../../store/templates-popover/templates-popover.selectors';

@Component({
  selector: 'app-template-gantt-chart',
  templateUrl: './template-gantt-chart.component.html',
  styleUrls: ['./template-gantt-chart.component.scss'],
})
export class TemplateGanttChartComponent implements OnInit, OnDestroy {
  private readonly subscription = new Subscription();
  selectedTemplate: NodeTemplateModel;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.subscription.add(
      this.store
        .pipe(select(selectTemplatesPopoverSelectedTemplate))
        .subscribe(selectedTemplate => {
          this.selectedTemplate = selectedTemplate;
        }),
    );
  }

  changeSwitch(): void {
    // let templateProps = {};
    // templateProps = {
    //   allowFigures: !this.selectedTemplate?.allowFigures,
    // };
    // this.store.dispatch(
    //   TemplatesActions.updateNodeTemplateRequest({
    //     templateId: this.selectedTemplate.id,
    //     templateProps,
    //   }),
    // );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
