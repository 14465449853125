import { Component } from '@angular/core';
import { TableComponent } from '../../table.component';
import { AbstractTableEditableCellComponent } from '../abstract-table-editable-cell.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FolderPickerComponent } from '../../../../shared/modals/folder-picker/folder-picker.component';
import { NodeUtils } from '../../../../core/utils/node.util';
import { NodeModel } from '../../../../core/models/node.model';
import { NodesActions } from '../../../../store/nodes';
import { AppState } from '../../../../store';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-z-table-editable-folder-picker',
  templateUrl: './table-editable-folder-picker.component.html',
  styleUrls: ['./table-editable-folder-picker.component.scss'],
})
export class TableEditableFolderPickerComponent extends AbstractTableEditableCellComponent {
  constructor(
    private readonly store: Store<AppState>,
    public readonly table: TableComponent,
    private readonly modalService: NgbModal,
  ) {
    super(table);
  }

  public onClick() {
    this.onStartEdit();
    this.moveFolder();
  }

  moveFolder() {
    const modelRef = this.modalService.open(FolderPickerComponent, {
      size: 'xs',
      backdrop: 'static',
    });

    modelRef.componentInstance.types = [
      NodeUtils.getParentNodeType(this.cell?.options?.node?.nodeType),
    ];
    modelRef.componentInstance.selected = [this.cell?.options?.node?.parent];
    modelRef.componentInstance.disabled = [];
    modelRef.componentInstance.minSelection = 1;
    modelRef.componentInstance.maxSelection = 1;
    modelRef.result
      .then((result: NodeModel[]) => {
        this.store.dispatch(
          NodesActions.moveNodesRequest({
            nodeIds: [this.cell?.options?.node?.id],
            toParentId: result[0]?.id,
            sortIndex: 0,
          }),
        );
      })
      .catch(res => {});
  }
}
