<div class="template-calculation-widget-edit">
  <form [formGroup]="form" (ngSubmit)="submit()">
    <app-popover-subsection [title]="'Title'">
      <input
        #titleInput
        type="text"
        class="form-control"
        id="template-calculation-widget-edit-title"
        placeholder="Title"
        required
        formControlName="title"
        [ngClass]="title.invalid && (title.dirty || isSubmitted) ? 'is-invalid' : ''"
        (keydown)="onButtonKeyDown($event)"
      />
      <div class="invalid-feedback">
        Please provide a title
      </div>
      <div class="mb-3"></div>
    </app-popover-subsection>

    <app-popover-subsection
      [title]="'Widget Type'"
      *ngIf="widget?.widgetType !== WidgetType.calculationJoin"
    >
      <select
        class="form-control"
        id="widget-type"
        [formControlName]="'selectedWidgetType'"
        [ngClass]="
          f['selectedWidgetType'].invalid && (f['selectedWidgetType'].dirty || isSubmitted)
            ? 'is-invalid'
            : ''
        "
      >
        <option
          *ngFor="let widgetTypeOption of availableCalculationFieldWidgetTypeOptions"
          [ngValue]="widgetTypeOption.id"
        >{{ widgetTypeOption.label }}</option
        >
      </select>
      <div class="invalid-feedback">
        Please select a Widget Type
      </div>
      <div class="mb-3"></div>
    </app-popover-subsection>

    <app-popover-subsection
      [title]="'Calculation Method'"
      *ngIf="widget?.widgetType === WidgetType.calculationJoin"
    >
      <select
        class="form-control"
        id="widget-C-type"
        [formControlName]="'calculationMethod'"
        [ngClass]="
          f['calculationMethod'].invalid && (f['calculationMethod'].dirty || isSubmitted)
            ? 'is-invalid'
            : ''
        "
      >
        <option
          *ngFor="let calculationMethodOption of availableCalculationMethodOptions"
          [ngValue]="calculationMethodOption.id"
        >{{ calculationMethodOption.label }}</option
        >
      </select>
      <div class="invalid-feedback">
        Please select a Calculation Method
      </div>
      <div class="mb-3"></div>
    </app-popover-subsection>

    <div class="pb-3"></div>

    <div class="template-calculation-widget-edit__actions">
      <span>
        <button type="submit" class="btn btn-primary btn-sm layout-mr">Save</button>
        <button type="button" class="btn btn-outline-light btn-sm" (click)="close()">Cancel</button>
      </span>
      <!--      <span *ngIf="widget.widgetType === WidgetType.calculationJoin">-->
      <!--        <button-->
      <!--          type="button"-->
      <!--          class="btn btn-danger btn-sm"-->
      <!--          (click)="onDelete()"-->
      <!--        >-->
      <!--          Delete-->
      <!--        </button>-->
      <!--      </span>-->
    </div>
  </form>
</div>
