<select
  #input
  type="number"
  class="z-table__input z-table__input--select table-editor"
  [ngClass]="{ 'z-table__input--empty': editable && !cell?.value }"
  [(ngModel)]="cell.value"
  [disabled]="!editable"
  (focus)="onStartEdit()"
  (blur)="onEndEdit()"
  (keyup.enter)="onEnterKey()"
  (keyup.escape)="onEscapeKey()"
>
  <option [value]="null" disabled>{{ editable ? 'Select...' : 'Empty' }}</option>
  <option *ngFor="let opt of options.list" [value]="opt.value">{{ opt.label }}</option>
</select>
