import { Component, Input, OnInit } from '@angular/core';
import { IconType } from '../../../core/constants/icon-type';

@Component({
  selector: 'app-fa-icon',
  templateUrl: './fa-icon.component.html',
  styleUrls: ['./fa-icon.component.scss'],
})
export class FaIconComponent implements OnInit {
  @Input() icon: IconType;

  // @HostBinding('class')
  constructor() {}

  ngOnInit(): void {}
}
