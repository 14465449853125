import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WorkspaceCommonComponent } from './workspace-common.component';
import { WorkspaceResolverService } from '../../core/resolvers/workspace-resolver.service';

const routes: Routes = [
  {
    path: ':slug',
    component: WorkspaceCommonComponent,
    resolve: {
      workspaceModel: WorkspaceResolverService,
    },
    children: [
      {
        path: 'content',
        loadChildren: () => import('../workspace/workspace.module').then(m => m.WorkspaceModule),
      },
      {
        path: 'settings/node-templates',
        loadChildren: () =>
          import('../node-templates-management/node-templates-management.module').then(
            m => m.NodeTemplatesManagementModule,
          ),
      },
      {
        path: 'settings/users',
        loadChildren: () =>
          import('../users-management/users-management.module').then(m => m.UsersManagementModule),
      },
      {
        path: 'settings/archives',
        loadChildren: () =>
          import('../archives-management/archives-management.module').then(
            m => m.ArchivesManagementModule,
          ),
      },
      {
        path: 'settings/tags',
        loadChildren: () =>
          import('../tags-management/tags-management.module').then(m => m.TagsManagementModule),
      },
      {
        path: 'settings/links',
        loadChildren: () =>
          import('../links-management/links-management.module').then(m => m.LinksManagementModule),
      },
      {
        path: 'settings/user-groups',
        loadChildren: () =>
          import('../user-groups-management/user-groups-management.module').then(
            m => m.UserGroupsManagementModule,
          ),
      },
      { path: '', redirectTo: 'content', pathMatch: 'full' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class WorkspaceCommonRoutingModule {}
