<app-panel-subsection
  class="template-edit__subsection"
  title="Figures"
  [titleTooltip]="
    'Template Figures allow users to input numbers and currency amounts with designed calculation formulas for each assignment.'
  "
  [backgroundTransparent]="true"
  [headerRightTemplate]="figuresOnOffLayout"
>
  <ng-container *ngIf="selectedTemplate?.allowFigures">
    <div class="template-figures">
      <div class="template-figures__lists">
        <app-template-calculation
          [calcWidget]="calWidget"
          [calIds]="calIds"
          *ngFor="let calWidget of calculationWidgets; trackBy: trackBy"
        ></app-template-calculation>
      </div>
    </div>
    <div class="d-flex justify-content-center" #origin>
      <button
        class="btn btn-sm btn-outline-light"
        app-icon-button
        [icon]="IconType['plus']"
        (click)="showFiguresCreatePage(origin)"
      >
        Calculation
      </button>
    </div>
  </ng-container>
</app-panel-subsection>

<ng-template #figuresOnOffLayout>
  <div
    role="button"
    class="layout-switch-btn"
    (click)="changeSwitch()"
    [ngClass]="{
      'layout-switch-btn-on': selectedTemplate?.allowFigures === true,
      'layout-switch-btn-off': !selectedTemplate?.allowFigures
    }"
  >
    <span>{{ selectedTemplate?.allowFigures ? 'ON' : 'OFF' }}</span>
  </div>
</ng-template>
