import { Action, createReducer, on } from '@ngrx/store';
import { initialRateEditPopoverState, RateEditPopoverState } from './rate-edit-popver.state';
import { RateEditPopoverActions } from './index';

const reducer = createReducer(
  initialRateEditPopoverState,

  on(RateEditPopoverActions.showRateCreatePage, (state, { nodeId }) => {
    return {
      ...initialRateEditPopoverState,
      nodeId,
    };
  }),
  on(RateEditPopoverActions.showRateEditPage, (state, { nodeId, rateId }) => {
    return {
      ...initialRateEditPopoverState,
      nodeId,
      rateId,
    };
  }),
);

export function rateEditPopoverReducer(state: RateEditPopoverState, action: Action) {
  return reducer(state, action);
}
