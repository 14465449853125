import { Injectable } from '@angular/core';
import { AppState } from '../../store';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AssignmentSelectionTimerService {
  // Use Subject instead of BehaviourSubject so past timeout event will not be subscribed by new subscriber
  private isSelectionTimeoutSubject = new Subject();

  public isSelectionTimeout$ = this.isSelectionTimeoutSubject.asObservable();

  // private selectedIds = [];
  // private selectionChangeTime = undefined;
  // private timer = timer(0, 1000);

  constructor(private store: Store<AppState>) {
    // The service is a singleton, so we don't need to unsubscribe
    // this.store
    //   .pipe(select(selectAssignmentSelectionData))
    //   .subscribe(({ selectedIds, selectionChangeTime }) => {
    //     this.selectedIds = selectedIds;
    //     this.selectionChangeTime = selectionChangeTime;
    //   });
    //
    // this.timer.subscribe(() => {
    //   if (this.selectedIds.length > 0 && this.selectionChangeTime) {
    //     const now = new Date().getTime();
    //     if (now - this.selectionChangeTime > 3000) {
    //       this.isSelectionTimeoutSubject.next(true);
    //
    //       this.store.dispatch(AssignmentsActions.selectionTimeout());
    //     }
    //   }
    // });
  }
}
