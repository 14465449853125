<div class="assignment-row d-flex flex-wrap w-100">
  <ng-container *ngFor="let field of additionalFields; trackBy: trackBy">
    <div class="assignment-field w-100" [ngClass]="readonly ? 'assignment-field--readonly' : ''">
      <div class="assignment-field__content">
        <div class="assignment-section-title">{{ field?.title }}</div>
        <div class="assignment-field__values">
          <div class="assignment-field__value-item" (click)="requestEdit($event, field)">
            <app-btn-view
              class="assignment-field__view-btn"
              (click)="nodeView($event, field)"
            ></app-btn-view>
            <div class="assignment-field__widget-items">
              <ng-container *ngFor="let widget of field?.widgets; trackBy: widgetsTrackBy">
                <span class="assignment-field__widget-item">
                  <app-truncate-text
                    [value]="
                      widget?.widget?.title +
                      ': ' +
                      ((widget | widgetValue) === 'Invalid date' ? '-' : (widget | widgetValue) ?? '-')
                    "
                    [limit]="cardLayout === CardLayoutType.oneColumn ? 500 : 250"
                    [bulletPoints]="true"
                  ></app-truncate-text>
                </span>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
