import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NodeModel } from '../../../core/models/node.model';

@Component({
  templateUrl: 'node-rates.component.html',
  styleUrls: ['node-rates.component.scss'],
})
export class NodeRatesComponent implements OnInit {
  @Input()
  public node: NodeModel;

  constructor(public activeModal: NgbActiveModal) {}
  public ngOnInit() {}
}
