import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SidebarComponent, SideBarPosition } from '../sidebar.component';

@Component({
  selector: 'app-sidebar-toggle-bar',
  templateUrl: './sidebar-toggle-bar.component.html',
  styleUrls: ['./sidebar-toggle-bar.component.scss'],
})
export class SidebarToggleBarComponent {
  public readonly SideBarPosition = SideBarPosition;

  @Output()
  public readonly toggleEvent: EventEmitter<boolean>;

  @Input()
  public position: SideBarPosition;

  constructor(public parent: SidebarComponent) {
    this.toggleEvent = new EventEmitter<boolean>();
  }

  public onToggle(event: any) {
    event.preventDefault();
    this.toggleEvent.emit(!this.parent.collapsed);
  }
}
