import { Component } from '@angular/core';
import { TableComponent } from '../../table.component';
import { AbstractTableEditableCellComponent } from '../abstract-table-editable-cell.component';
import { NodeTemplateModel } from '../../../../core/models/node-template.model';
import { NodeTemplatePickerComponent } from '../../../../shared/modals/node-template-picker/node-template-picker.component';
import { NodeType } from '../../../../core/constants/node-type';
import { NodesActions } from '../../../../store/nodes';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store';
import { NodeModel } from '../../../../core/models/node.model';

@Component({
  selector: 'app-z-table-editable-assignment-templates',
  templateUrl: './table-editable-assignment-templates.component.html',
  styleUrls: ['./table-editable-assignment-templates.component.scss'],
})
export class TableEditableAssignmentTemplatesComponent extends AbstractTableEditableCellComponent {
  constructor(
    public table: TableComponent,
    private modalService: NgbModal,
    private store: Store<AppState>,
  ) {
    super(table);
  }

  public onStartEdit() {
    const assignment = this.options?.assignment as NodeModel;

    const modelRef = this.modalService.open(NodeTemplatePickerComponent, {
      size: 'md',
      backdrop: 'static',
    });
    modelRef.componentInstance.types = [NodeType.assignment];
    modelRef.componentInstance.selected = assignment.allowedTemplates || [];
    modelRef.result
      .then((result: NodeTemplateModel[]) => {
        this.store.dispatch(
          NodesActions.updateNodeRequest({
            nodeId: assignment.id,
            nodeProps: {
              allowedTemplateIds: result.map(t => t.id),
            },
          }),
        );
      })
      .catch(res => {});
  }

  trackBy(index, item: NodeTemplateModel) {
    return item.id;
  }
}
