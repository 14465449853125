<div class="link-list-field">
  <table class="link-list-field__table ap-table table table-hover">
    <tbody>
    <tr class="link-list-field__tr"
        *ngFor="let link of (widget.value || []), trackBy: trackBy"
        [ngbTooltip]="link.value"
        container="body"
        (click)="goToLink(link.value)"
    >
      <td>
        <app-link-item [link]="link"></app-link-item>
      </td>
      <td class="text-right">
        <span #origin>
          <app-btn-edit (click)="editLink(link, origin, $event)"></app-btn-edit>
        </span>
      </td>
    </tr>
    </tbody>
  </table>
  <div class="link-list-field__actions">
    <button
      #originAdd
      type="button"
      class="btn btn-sm btn-outline-light"
      (click)="showLinkAddPage(originAdd)"
    >
      Add Link
    </button>
  </div>
</div>
