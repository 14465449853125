import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-data-field',
  templateUrl: './data-field.component.html',
  styleUrls: ['./data-field.component.scss'],
})
export class DataFieldComponent implements OnInit {
  @Input() fieldLabel: string;
  @Input() fieldTemplateRef: TemplateRef<any>;

  constructor() {}

  ngOnInit(): void {}
}
