import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import {
  selectTemplatesPopoverSelectedTemplate,
  selectTemplatesPopoverTemplateTypeViewModels,
} from '../../../../../store/templates-popover/templates-popover.selectors';
import { TemplateTypeViewModel } from '../../../../../core/models/template-type-view.model';
import { NodeType } from '../../../../../core/constants/node-type';
import { NodeTemplateModel } from '../../../../../core/models/node-template.model';
import { selectWorkspacesCurrentSlug } from '../../../../../store/workspaces/workspaces.selectors';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NewTemplateComponent } from '../../../../../shared/components/modals/new-template/new-template.component';
import { filter } from 'rxjs/operators';
import { NodesSelectors } from '../../../../../store/nodes';

@Component({
  selector: 'app-template-type-list',
  templateUrl: './template-type-list.component.html',
  styleUrls: ['./template-type-list.component.scss'],
})
export class TemplateTypeListComponent implements OnInit, OnDestroy {
  private readonly subscription = new Subscription();

  templateTypeViewModels: TemplateTypeViewModel[];

  selectedTemplate: NodeTemplateModel;
  selectedNodeType: NodeType;
  rootFolderIdByServerNodeType: { [key in NodeType]?: number };

  slug: string;

  constructor(private store: Store, private modalService: NgbModal) {}

  ngOnInit(): void {
    this.subscription.add(
      this.store
        .pipe(
          select(selectTemplatesPopoverSelectedTemplate),
          filter(t => t != null),
        )
        .subscribe(selectedTemplate => {
          this.selectedTemplate = selectedTemplate;
          this.selectedNodeType = selectedTemplate.nodeType;
        }),
    );

    this.subscription.add(
      this.store
        .pipe(select(selectTemplatesPopoverTemplateTypeViewModels))
        .subscribe(templateTypeViewModels => {
          this.templateTypeViewModels = templateTypeViewModels;
        }),
    );

    this.subscription.add(
      this.store
        .pipe(select(NodesSelectors.selectRootNodeFilteredByNodeType(this.selectedNodeType)))
        .subscribe(rootFolderIdByServerNodeType => {
          this.rootFolderIdByServerNodeType = rootFolderIdByServerNodeType;
        }),
    );

    this.subscription.add(
      this.store.pipe(select(selectWorkspacesCurrentSlug)).subscribe(slug => {
        this.slug = slug;
      }),
    );
  }

  createTemplate(typeViewModel: TemplateTypeViewModel) {
    const modelRef = this.modalService.open(NewTemplateComponent, {
      centered: true,
      backdrop: 'static',
    });

    modelRef.componentInstance.slug = this.slug;
    modelRef.componentInstance.serverNodeType = typeViewModel.nodeType;
    modelRef.componentInstance.rootFolderId = this.rootFolderIdByServerNodeType
      ? this.rootFolderIdByServerNodeType[typeViewModel?.nodeType]
      : null;
    if (!modelRef.componentInstance.rootFolderId) {
      console.error('can not set rootFolderId when creating template');
    }
  }

  trackBy(index, typeViewModel) {
    return typeViewModel.id;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
