<div
  #input
  class="z-table__input z-table__input--tag-preview"
  [ngClass]="[
        'background--' + (cell.value?.colorTheme ? (cell.value?.colorTheme | colorString) : 'transparent'),
      ]"
  (click)="onStartEdit()"
>
  <span
    class="fal"
    [ngClass]="['fa-' + (cell.value?.icon | iconString), cell.value?.icon ? '' : 'invisible']"
  ></span>
  <span>{{ cell.value?.title }}</span>
</div>
