import { AppState } from '../index';
import { createSelector } from '@ngrx/store';
import { NodeRateModel } from '../../core/models/node-rate.model';

export const selectNodeRatesState = (state: AppState) => state.nodeRates;

export const selectNodeRatesById = createSelector(selectNodeRatesState, state => state.byId);

export const selectNodeRateNodes = createSelector(selectNodeRatesState, state => state.byNodeId);

export const selectNodeRatesByNodeId = nodeId =>
  createSelector(selectNodeRateNodes, (nodeRatesByNodeId): NodeRateModel[] => {
    return (nodeRatesByNodeId[nodeId] || [])
      .filter(r => r != null)
      .sort((a, b) => {
        if (a.rateType > b.rateType) {
          return 1;
        } else if (a.rateType < b.rateType) {
          return -1;
        }

        if (a.title > b.title) {
          return 1;
        } else if (a.title < b.title) {
          return -1;
        }
        return 0;
      });
  });
