import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { EMPTY, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DATE_KEY_FORMAT } from '../constants/date-format.constants';
import { DayOfWeekType } from '../constants/day-of-week-type';
import { ResponseMessage } from '../models/response-message.model';
import { transformRecurringSettingsToDateKeys } from '../utils/date.util';
import * as moment from 'moment';
import { NodeModel } from '../models/node.model';
import { NodeUtils } from '../utils/node.util';

@Injectable({ providedIn: 'root' })
export class AssignmentService {
  constructor(private http: HttpClient) {}

  public addAssignmentProject(
    slug: string,
    projectId: number,
    dateKey: string,
  ): Observable<NodeModel> {
    return this.http
      .post<NodeModel[]>(
        `${environment.apiBaseUrl}/workspace/${slug}/assignment/add/${projectId}`,
        { date: dateKey },
      )
      .pipe(map(nodes => nodes[0]));
  }

  public addAssignmentProjects(
    slug: string,
    projectIds: number[],
    dateKeys: string[],
  ): Observable<NodeModel[]> {
    return this.http.post<NodeModel[]>(
      `${environment.apiBaseUrl}/workspace/${slug}/assignment/add`,
      {
        projects: projectIds,
        date: dateKeys,
      },
    );
  }

  public patchAssignmentReadonly(nodeId: number, readonly: boolean): Observable<NodeModel> {
    return this.http
      .patch<{ data: NodeModel }>(
        `${environment.apiBaseUrl}/workspace/assignment/${nodeId}/readonly`,
        {
          readOnly: readonly,
        },
      )
      .pipe(map(response => response.data));
  }

  public copyAssignment(
    assignmentId: number,
    dateKeys: string[],
    inclusionProps: {
      primaryTags: boolean;
      assets: boolean;
      elements: boolean;
      rates: boolean;
    },
  ) {
    return this.http
      .post<ResponseMessage<any>>(
        `${environment.apiBaseUrl}/workspace/assignment/${assignmentId}/copy`,
        {
          date: dateKeys,
          ...inclusionProps,
        },
      )
      .pipe(map(response => response.data));
  }

  public addAssignmentProjectsWithFrequencySettings(
    slug: string,
    projectIds: number[],
    dateKey: string,
    repetitionNumber: number,
    recurrenceDays: number,
  ): Observable<NodeModel[]> {
    if (recurrenceDays > 30) {
      console.error('recurrenceDays can not be greater than 30');
      return EMPTY;
    }
    if (repetitionNumber > 30) {
      console.error('repetition number can not be greater than 30');
      return EMPTY;
    }

    const dateArray = [];
    for (let i = 0; i < recurrenceDays; i++) {
      const date = moment(dateKey).add(i, 'days');
      for (let j = 0; j < repetitionNumber; j++) {
        dateArray.push(date);
      }
    }
    const dateKeyArray = dateArray.map(date => date.format(DATE_KEY_FORMAT));

    return this.http.post<NodeModel[]>(
      `${environment.apiBaseUrl}/workspace/${slug}/assignment/add`,
      {
        projects: projectIds,
        date: dateKeyArray,
      },
    );
  }

  public addRecurringAssignments(
    slug: string,
    projectId: number,
    primaryTagId: number,
    daysOfWeek: DayOfWeekType[],
    startDate: string | Date,
    endDate: string | Date,
  ): Observable<NodeModel[]> {
    const dateKeys = transformRecurringSettingsToDateKeys(startDate, endDate, daysOfWeek);

    return this.http.post<NodeModel[]>(
      `${environment.apiBaseUrl}/workspace/${slug}/assignment/add`,
      {
        projects: [projectId],
        primaryTag: primaryTagId,
        date: dateKeys,
      },
    );
  }

  public addAssignmentElements(
    assignmentId: number,
    elementIds: number[],
  ): Observable<NodeModel[]> {
    return this.http.post<NodeModel[]>(
      `${environment.apiBaseUrl}/workspace/assignment/${assignmentId}/element/add`,
      { elements: elementIds },
    );
  }

  public replaceAssignmentElements(
    assignmentId: number,
    nodeIds: number[],
    removeByNodeTemplateIds?: number[],
  ): Observable<NodeModel> {
    return this.http.patch<NodeModel>(
      `${environment.apiBaseUrl}/workspace/assignment/${assignmentId}/element/replace`,
      {
        elements: nodeIds,
        // Remove existing Assets/Elements by matching referenceNode nodeTemplateId
        // This can also be used to enforce BehaviourType.Single
        nodeTemplates: removeByNodeTemplateIds ? removeByNodeTemplateIds : [],
      },
    );
  }

  public updateAssignmentFigures(
    budget: number,
    cost: number,
    billable: number,
    assignmentId,
  ): Observable<any> {
    return this.http.patch<ResponseMessage<any>>(
      `${environment.apiBaseUrl}/workspace/assignment/${assignmentId}/meta/update`,
      { budget, cost, billable },
    );
  }

  public loadAssignmentsByProjects(
    slug: string,
    projects: NodeModel[],
    includeWidgets?: boolean,
  ): Observable<NodeModel[]> {
    const options = {
      params: new HttpParams()
        .set('nodeFilters', JSON.stringify(projects.map(p => p.id)))
        .set('widgets', includeWidgets ? 1 : 0),
    };
    return this.http.get<NodeModel[]>(
      `${environment.apiBaseUrl}/workspace/${slug}/assignment/search`,
      options,
    );
  }

  public loadAssignmentsByDateRange(
    slug: string,
    startDate: string,
    endDate: string,
    includeWidgets?: boolean,
    nullDate = false,
  ): Observable<NodeModel[]> {
    // const options = {
    //   params: new HttpParams()
    //     .set('startDate', startDate)
    //     .set('endDate', endDate)
    //     .set('widgets', includeWidgets ? 1 : 0)
    //     .set('nullDate', nullDate ? 0 : 1),
    // };
    let params = new HttpParams().set('widgets', includeWidgets ? 1 : 0);

    if (!nullDate) {
      params = params
        .set('startDate', startDate)
        .set('endDate', endDate)
        .set('nullDate', nullDate ? 0 : 1);
    }

    const options = { params };

    return this.http.get<NodeModel[]>(
      `${environment.apiBaseUrl}/workspace/${slug}/assignment/search`,
      options,
    );
  }

  public loadArchivedAssignments(
    slug: string,
    startDate: string,
    endDate: string,
    includeWidgets?: boolean,
  ): Observable<NodeModel[]> {
    const options = {
      params: new HttpParams()
        .set('archived', 1)
        .set('widgets', includeWidgets ? 1 : 0)
        .set('startDate', startDate ?? null)
        .set('endDate', endDate ?? null),
    };
    return this.http.get<NodeModel[]>(
      `${environment.apiBaseUrl}/workspace/${slug}/assignment/search`,
      options,
    );
  }

  public moveAssignments(assignmentIds: number[], toDateKey?: string, sortIndex?: number) {
    const url =
      toDateKey == null || toDateKey == NodeUtils.awaitingAssignmentsDateKey
        ? `${environment.apiBaseUrl}/workspace/assignment/move`
        : `${environment.apiBaseUrl}/workspace/assignment/move/${toDateKey}`;
    return this.http.patch(url, {
      assignments: assignmentIds,
      sortIndex,
    });
  }
}
