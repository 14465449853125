import { OptionModel } from '../models/option.model';
import { GanttChartCustomColumnType } from './gantt-chart-custom-column-type';

export const ganttChartTimelineColumns: OptionModel[] = [
  {
    id: GanttChartCustomColumnType.label,
    label: 'Label',
  },
  {
    id: GanttChartCustomColumnType.dateRange,
    label: 'Date Range',
  },
  {
    id: GanttChartCustomColumnType.available,
    label: 'Available',
  },
];
export const ganttChartProgressColumns: OptionModel[] = [
  {
    id: GanttChartCustomColumnType.budget,
    label: 'Budget',
  },
  {
    id: GanttChartCustomColumnType.cost,
    label: 'Cost',
  },
  {
    id: GanttChartCustomColumnType.target,
    label: 'Target',
  },
  {
    id: GanttChartCustomColumnType.complete,
    label: 'Complete',
  },
  {
    id: GanttChartCustomColumnType.assigned,
    label: 'Assignments',
  },
];

export const ganttChartTimelineColumnKeys = ganttChartTimelineColumns.map(option => option.id);
export const ganttChartProgressColumnKeys = ganttChartProgressColumns.map(option => option.id);
export const ganttChartAllColumnKeys = [
  ...ganttChartTimelineColumnKeys,
  ...ganttChartProgressColumnKeys,
];
