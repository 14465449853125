import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import {
  selectIsColorSectionShown,
  selectIsProfilePhotoSectionShown,
  selectPropertiesPanelAvailableTemplateOptions,
  selectPropertiesPanelInitialFocusWithNodeId,
  selectPropertiesPanelIsOpen,
  selectPropertiesPanelNode,
} from '../../../store/properties-panel/properties-panel.selectors';
import { AppState } from '../../../store';
import { PropertiesPanelActions } from '../../../store/properties-panel';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ColorType, colorTypeKeys } from '../../../core/constants/color-type';
import { ENTER } from '@angular/cdk/keycodes';
import { IconType, iconTypeKeys } from '../../../core/constants/icon-type';
import { NodeEditableProps, NodeModel } from '../../../core/models/node.model';
import { SquareButtonAddComponent } from '../../../shared/components/square-button-add/square-button-add.component';
import { NodeTemplateModel } from '../../../core/models/node-template.model';
import { OptionModel } from '../../../core/models/option.model';
import { defaultPlacements } from '../../../core/constants/placements';
import { NgbActiveModal, NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { NodeAvatarUploadModalComponent } from '../../../shared/components/node-avatar-upload-modal/node-avatar-upload-modal.component';
import { TagsPickerComponent } from '../../../shared/modals/tags-picker/tags-picker.component';
import { IconPickerComponent } from '../../../shared/modals/icon-picker/icon-picker.component';
import { ColorPickerComponent } from '../../../shared/modals/color-picker/color-picker.component';
import { TagModel } from '../../../core/models/tag.model';
import { NodeTagsActions } from '../../../store/node-tags';
import { ChangeWidgetValueEvent } from '../../../core/models/change-widget-value.event';
import { NodeTemplatePickerComponent } from '../../../shared/modals/node-template-picker/node-template-picker.component';
import { NodeType } from '../../../core/constants/node-type';
import { NodeUtils } from '../../../core/utils/node.util';
import { NodeWidgetsActions } from '../../../store/node-widgets';
import { NodesActions } from '../../../store/nodes';

@Component({
  selector: 'app-properties-panel',
  templateUrl: './properties-panel.component.html',
  styleUrls: ['./properties-panel.component.scss'],
})
export class PropertiesPanelComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly subscription = new Subscription();

  public readonly NodeUtils = NodeUtils;
  public readonly NodeType = NodeType;
  public readonly ColorType = ColorType;
  public readonly icons: string[] = iconTypeKeys;
  public readonly colorThemes: string[] = colorTypeKeys;
  public readonly defaultPlacements = defaultPlacements;

  @ViewChild('titleInput')
  titleInput: ElementRef;

  @ViewChild(SquareButtonAddComponent)
  tagButtonAdd;

  public isPropertiesPanelOpen: boolean;

  public isColorSectionShown: boolean;
  public isProfilePhotoSectionShown: boolean;

  public node: NodeModel;
  public templateOptions: OptionModel[];

  selectedColorTheme: ColorType;
  selectedIcon: IconType;

  form: UntypedFormGroup;

  get title() {
    return this.form.get('title');
  }

  get appliedTemplate() {
    return this.form.get('appliedTemplate');
  }

  get notifications() {
    return this.form.get('notifications');
  }

  constructor(
    private store: Store<AppState>,
    private fb: UntypedFormBuilder,
    private elementRef: ElementRef,
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
  ) {
    this.form = this.fb.group({
      title: ['', Validators.required],
      appliedTemplate: [''],
      notifications: [''],
    });
  }

  ngOnInit() {
    this.subscription.add(
      this.store.pipe(select(selectPropertiesPanelIsOpen)).subscribe(isOpen => {
        this.isPropertiesPanelOpen = isOpen;
      }),
    );

    this.subscription.add(
      this.store.pipe(select(selectPropertiesPanelNode)).subscribe((node: NodeModel) => {
        this.node = node;
        if (this.node) {
          if (this.title.value !== this.node.title) {
            this.title.setValue(this.node.title);
          }

          this.selectedColorTheme = this.node.colorTheme;

          this.selectedIcon = this.node.icon;

          if (this.node.nodeType === NodeType.project && this.node.notifications) {
            this.notifications.setValue(this.node.notifications.note);
          } else {
            this.notifications.setValue('');
          }

          if (this.node.nodeTemplateId) {
            this.appliedTemplate.setValue(this.node.nodeTemplateId);
          }
        }
      }),
    );

    this.subscription.add(
      this.store.pipe(select(selectIsColorSectionShown)).subscribe(isColorSectionShown => {
        this.isColorSectionShown = isColorSectionShown;
      }),
    );

    this.subscription.add(
      this.store
        .pipe(select(selectIsProfilePhotoSectionShown))
        .subscribe(isProfilePhotoSectionShown => {
          this.isProfilePhotoSectionShown = isProfilePhotoSectionShown;
        }),
    );

    this.subscription.add(
      this.store
        .pipe(select(selectPropertiesPanelInitialFocusWithNodeId))
        .subscribe(({ initialFocus, nodeId }) => {
          if (initialFocus && nodeId) {
            setTimeout(() => {
              const field: any = this.elementRef.nativeElement.querySelector(
                `[formControlName="${initialFocus}"]`,
              );
              if (field) {
                field.focus();
              }
            }, 0);
          }
        }),
    );

    this.subscription.add(
      this.store
        .pipe(select(selectPropertiesPanelAvailableTemplateOptions))
        .subscribe(templateOptions => {
          this.templateOptions = templateOptions;
        }),
    );
  }

  ngAfterViewInit(): void {
    if (this.node && this.node.title === 'Untitled') {
      this.titleInput.nativeElement.focus();
      this.titleInput.nativeElement.select();
    }
  }

  public closePropertiesPanel() {
    this.store.dispatch(PropertiesPanelActions.close());
    this.activeModal.close();
  }

  public onIconPicker() {
    const modelRef = this.modalService.open(IconPickerComponent, {
      size: 'md',
      backdrop: 'static',
    });
    modelRef.componentInstance.selected = this.selectedIcon
      ? IconType[this.selectedIcon]
      : IconType['not-available'];
    modelRef.result
      .then((result: string) => {
        if (result) {
          this.store.dispatch(
            NodesActions.updateNodeRequest({
              nodeId: this.node.id,
              nodeProps: {
                icon: IconType[result],
              },
            }),
          );
        }
      })
      .catch(res => {});
  }

  public onColourPicker() {
    const modelRef = this.modalService.open(ColorPickerComponent, {
      size: 'sm',
      backdrop: 'static',
    });
    modelRef.componentInstance.selected = this.selectedColorTheme
      ? ColorType[this.selectedColorTheme]
      : ColorType['not-available'];
    modelRef.result
      .then((result: string) => {
        if (result) {
          this.store.dispatch(
            NodesActions.updateNodeRequest({
              nodeId: this.node.id,
              nodeProps: {
                colorTheme: ColorType[result],
              },
            }),
          );
        }
      })
      .catch(res => {});
  }

  public updateProp(key: keyof NodeEditableProps) {
    if (this[key].valid && this.node && this.node[key] !== this.form.get(key).value) {
      this.store.dispatch(
        NodesActions.updateNodeRequest({
          nodeId: this.node.id,
          nodeProps: {
            [key]: this.form.get(key).value,
          },
        }),
      );
    }
  }

  public applyTemplate() {
    const nodeTemplateId = this.appliedTemplate.value;
    this.store.dispatch(
      NodesActions.applyTemplateToNodesRequest({
        nodeIds: [this.node.id],
        nodeTemplateId,
      }),
    );
  }

  public onSubmit(key?: keyof NodeEditableProps) {
    if (this.form.valid) {
      if (key) {
        this.updateProp(key);
      }
      this.closePropertiesPanel();
    }
  }

  public onButtonKeyDown(event, key?: keyof NodeEditableProps) {
    if (event.keyCode === ENTER) {
      event.preventDefault();
      this.onSubmit(key);
    }
  }

  public openTagsPopover() {
    const modelRef = this.modalService.open(TagsPickerComponent, {
      size: 'xl',
      backdrop: 'static',
    });
    modelRef.componentInstance.selected = this.node.tags || [];
    modelRef.result
      .then((result: TagModel[]) => {
        this.store.dispatch(
          NodeTagsActions.addMultipleTagsToNodeRequest({
            nodeId: this.node.id,
            tagIds: (result || []).map(t => t.id),
            groupId: undefined,
            replace: true,
          }),
        );
      })
      .catch(res => {});
  }

  public openTemplatesPopover() {
    const modelRef = this.modalService.open(NodeTemplatePickerComponent, {
      size: 'md',
      backdrop: 'static',
    });
    modelRef.componentInstance.types = [NodeType.assignment];
    modelRef.componentInstance.selected = this.node.allowedTemplates || [];
    modelRef.result
      .then((result: NodeTemplateModel[]) => {
        this.store.dispatch(
          NodesActions.updateNodeRequest({
            nodeId: this.node.id,
            nodeProps: {
              allowedTemplateIds: result.map(t => t.id),
            },
          }),
        );
      })
      .catch(res => {});
  }

  public changeWidgetValue(event: ChangeWidgetValueEvent, nodeId: number) {
    this.store.dispatch(
      NodeWidgetsActions.updateNodeWidgetRequest({
        nodeId,
        widgetId: event.widget.id,
        widgetProps: { value: event.value },
      }),
    );
  }

  public changePhoto(popover: NgbPopover) {
    if (this.node.profile) {
      if (popover.isOpen()) {
        popover.close();
      } else {
        popover.open();
      }
    } else {
      const modalRef = this.modalService.open(NodeAvatarUploadModalComponent, { size: 'sm' });
      modalRef.componentInstance.profile = this.node.profile;
      modalRef.componentInstance.nodeId = this.node.id;
      modalRef.componentInstance.profileIcon = this.node.nodeMeta?.profileIcon;
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
