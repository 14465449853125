import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store';

@Component({
  selector: 'app-chat-modal',
  templateUrl: './chat-modal.component.html',
  styleUrls: ['./chat-modal.component.scss'],
})
export class ChatModalComponent {
  @Input()
  public readonly readonly = true;
  public readonly modalTitle = 'Chat Channels';
  public iconUrl: string = 'assets/img/new-logo/Z-Chat.png'; // Default icon path

  constructor(public activeModal: NgbActiveModal, private store: Store<AppState>) {}
}
