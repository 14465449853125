import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-assignment-section',
  templateUrl: './assignment-section.component.html',
  styleUrls: ['./assignment-section.component.scss'],
})
export class AssignmentSectionComponent implements OnInit {
  @Input() title: string;
  @Input() headerToolbarTemplate?: TemplateRef<any>;

  constructor() {}

  ngOnInit(): void {}
}
