import { SharedLinkModel } from '../../core/models/shared-link.model';

export class WorkspaceSharesState {
  byHash: { [hash: string]: SharedLinkModel };
  hashes: string[];
}

export const initialWorkspaceSharesState = {
  byHash: {},
  hashes: [],
};
