import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { UntypedFormGroup } from '@angular/forms';
import { WidgetType } from '../../../../core/constants/widget-type';
import { NodeTemplateWidgetModel } from '../../../../core/models/node-template-widget.model';
import { WidgetEditComponent } from '../../../../shared/modals/widget-edit/widget-edit.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WidgetsPopoverActions } from '../../../../store/widgets-popover';

@Component({
  selector: 'app-leaf-widget-item',
  templateUrl: './leaf-widget-item.component.html',
  styleUrls: ['./leaf-widget-item.component.scss'],
})
export class LeafWidgetItemComponent implements OnInit {
  public readonly WidgetType = WidgetType;

  @Input()
  widget: NodeTemplateWidgetModel;

  @Input()
  customTitle?: string;

  @Input()
  form: UntypedFormGroup;

  @Input()
  isEdit: boolean;

  constructor(private store: Store, private modalService: NgbModal) {}

  ngOnInit(): void {}

  editWidget(): void {
    this.store.dispatch(
      WidgetsPopoverActions.showWidgetChangePage({
        widgetId: this.widget?.id,
      }),
    );
    const modelRef = this.modalService.open(WidgetEditComponent, {
      size: 'sm',
      backdrop: 'static',
    });
    modelRef.componentInstance.hideOptions = ['Type', 'Can be deleted'];
  }
}
