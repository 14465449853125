import { Component, OnInit } from '@angular/core';
import { FileModel } from '../../../../core/models/file.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-image-modal',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.scss'],
})
export class ImageModalComponent implements OnInit {
  public file: FileModel;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}
}
