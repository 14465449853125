import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ErrorsActions } from '../errors';
import { WidgetService } from '../../core/services/widget.service';
import { WidgetTypesActions } from './index';
import { OptionModel } from '../../core/models/option.model';

@Injectable()
export class WidgetTypesEffects {
  constructor(private actions$: Actions, private widgetService: WidgetService) {}

  loadWidgetTypesRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WidgetTypesActions.loadWidgetTypesRequest),
      mergeMap(action =>
        this.widgetService.loadWidgetTypes().pipe(
          map((widgetTypes: OptionModel[]) => {
            return WidgetTypesActions.loadWidgetTypesSuccess({ widgetTypes });
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );
}
