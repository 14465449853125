import { WidgetModel } from '../../core/models/widget.model';

export interface WidgetEditPopoverState {
  nodeId: number;
  nodeWidget: WidgetModel;
}

export const initialWidgetEditPopoverState = {
  nodeId: undefined,
  nodeWidget: undefined,
};
