import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { AlertService, Alert } from './alert.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-alert',
  templateUrl: 'alert.component.html',
  styleUrls: ['alert.component.scss'],
})
export class AlertComponent implements OnInit, OnDestroy {
  private readonly subscription: Subscription;

  @Input() scope: string;

  public alerts: Alert[] = [];

  constructor(private alertService: AlertService) {
    this.subscription = new Subscription();
    this.subscription.add(
      this.alertService.watchAlerts(this.scope).subscribe((alerts: Alert[]) => {
        this.alerts = alerts;
      }),
    );
  }

  public ngOnInit() {}

  public close(alert: Alert) {
    this.alertService.remove(alert);
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
