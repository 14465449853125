<app-modal
  [title]="modalTitle"
  [iconUrl]="iconUrl">
  <div class="container large-only">
    <div class="row">
      <div class="col-3 p-0">
        <div class="chatbar">
          <div class="chatbar__body">
            <app-chat-channels></app-chat-channels>
          </div>
        </div>
      </div>
      <div class="col-9 modal-comments">
        <app-chat></app-chat>
      </div>
    </div>
    <app-content-loader scope="chat"></app-content-loader>
  </div>

  <div class="small-only">
    <app-chatbar></app-chatbar>
  </div>
</app-modal>
