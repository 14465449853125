<div class="file-uploader-container">
  <!-- select an image from the lis -->

  <!-- title -->
  <h3 class="file-uploader__title" *ngIf="imageTypesEnabled?.length">Select a profile photo</h3>

  <div class="img-container">
    <ng-container *ngIf="imageTypesEnabled?.includes('userProfiles')">
      <div class="col-12 py-2">User profile</div>
      <div
        class="col-2 p-2 img-box"
        [ngClass]="{
          'img-box--selected': selectedImage?.id === image?.id
        }"
        *ngFor="let image of userProfilesImages"
        (click)="selectImage(image)"
      >
        <img [src]="image?.src" class="img-circle" alt="profile image" />
        <span class="img-selected fal fa-check" *ngIf="selectedImage?.id === image?.id"></span>
      </div>
    </ng-container>

    <ng-container *ngIf="imageTypesEnabled?.includes('equipmentProfiles')">
      <div class="col-12 py-2">Equipment profile</div>
      <div
        class="col-2 p-2 img-box"
        *ngFor="let image of equipmentProfilesImages"
        (click)="selectImage(image)"
        [ngClass]="{
          'img-box--selected': selectedImage?.id === image?.id
        }"
      >
        <img [src]="image?.src" class="img-circle" alt="profile image" />
        <span class="img-selected fal fa-check" *ngIf="selectedImage?.id === image?.id"></span>
      </div>
    </ng-container>

    <ng-container *ngIf="imageTypesEnabled?.includes('companyLogos')">
      <div class="col-12 py-2">Company logos</div>
      <div
        class="col-2 p-2 img-box"
        *ngFor="let image of companyLogosImages"
        (click)="selectImage(image)"
        [ngClass]="{
          'img-box--selected': selectedImage?.id === image?.id
        }"
      >
        <img [src]="image?.src" class="img-circle" alt="profile image" />
        <span class="img-selected fal fa-check" *ngIf="selectedImage?.id === image?.id"></span>
      </div>
    </ng-container>
  </div>

  <!-- upload an image -->

  <!-- title -->
  <h3 class="file-uploader__title pt-3 mb-0">Upload a new profile photo</h3>

  <ul class="file-uploader-list">
    <li class="file-uploader-list-item">
      <image-cropper
        [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="true"
        [aspectRatio]="1"
        [cropperMinWidth]="64"
        [roundCropper]="true"
        [alignImage]="'center'"
        [style.display]="showCropper ? null : 'none'"
        format="png"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()"
        (cropperReady)="cropperReady($event)"
        (loadImageFailed)="loadImageFailed()"
      ></image-cropper>
    </li>

    <li class="file-uploader-list-item add-file" [draggable]="false" *ngIf="!showCropper">
      <div class="file-uploader-list-item-preview">
        <div class="file-uploader-list-item-text d-none d-sm-block">
          Drag &amp; Drop
          <br />or Click to add
        </div>

        <input type="file" (change)="fileChangeEvent($event)" [accept]="fileTypes" />
      </div>
    </li>
  </ul>

  <button
    class="file-uploader__upload-btn btn btn-primary"
    [disabled]="disableSaveButton()"
    (click)="confirmSetProfileImage()"
  >
    <span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-1"></span>Set as Profile
    Photo
  </button>
</div>
<!-- /file-uploader-container -->
