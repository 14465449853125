import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'concatNouns',
})
export class ConcatNounsPipe implements PipeTransform {
  transform(nouns: string[]): string {
    return concatNouns(nouns);
  }
}

export const concatNouns = (nouns: string[]) => {
  if (nouns && nouns.length > 0) {
    if (nouns.length === 1) {
      return nouns[0];
    } else {
      return [nouns.slice(0, -1).join(', '), nouns.slice(-1)[0]].join(' and ');
    }
  }

  return null;
};
