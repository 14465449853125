import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { ConfirmPopupComponent } from '../confirm-popup/confirm-popup.component';
import { WidgetType } from '../../../core/constants/widget-type';
import { WidgetModel } from '../../../core/models/widget.model';
import {
  selectWidgetsPopoverSelectedWidgetType,
  selectWidgetsPopoverWidget,
} from '../../../store/widgets-popover/widgets-popover.selectors';
import { WidgetsActions } from '../../../store/widgets';
import { NodeType } from '../../../core/constants/node-type';
import { WidgetsPopoverActions } from '../../../store/widgets-popover';

@Component({
  templateUrl: 'widget-edit.component.html',
  styleUrls: ['widget-edit.component.scss'],
})
export class WidgetEditComponent implements OnInit, OnDestroy {
  @Input() public hideOptions: string[];
  private readonly subscription = new Subscription();

  public readonly WidgetType = WidgetType;

  public isSubmitted: boolean;
  public form: UntypedFormGroup;
  public type: WidgetType;
  public model: WidgetModel;
  public choices = [];

  // convenience getter for easy access to form fields
  public get f() {
    return this.form.controls;
  }

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private fb: UntypedFormBuilder,
    private store: Store,
  ) {
    this.form = this.fb.group({
      title: ['', Validators.required],
    });

    this.subscription.add(
      this.store.pipe(select(selectWidgetsPopoverSelectedWidgetType)).subscribe(type => {
        this.type = type;
      }),
    );

    this.subscription.add(
      this.store
        .pipe(
          select(selectWidgetsPopoverWidget),
          filter(model => !!model),
        )
        .subscribe(model => {
          this.model = model;
          this.type = model.widgetType;
          this.choices = this.model.choices || [];
          this.form.patchValue({
            title: model.title,
          });
        }),
    );
  }

  public ngOnInit() {}

  public onSubmit() {
    this.isSubmitted = true;

    if (this.form.valid) {
      const formData = this.form.getRawValue();
      if (this.model == null) {
        this.store.dispatch(
          WidgetsActions.addWidgetRequest({
            title: formData.title,
            widgetType: this.type,
            nodeType: NodeType.project, // todo: This should be removed
            choices: this.choices,
          }),
        );
      } else {
        this.store.dispatch(
          WidgetsActions.updateWidgetRequest({
            widgetId: this.model.id,
            widgetProps: {
              title: formData.title,
              choices: this.choices,
            },
          }),
        );
      }
      this.activeModal.dismiss();
    }
  }

  onDelete() {
    const modelRef = this.modalService.open(ConfirmPopupComponent, {
      size: 'md',
      backdrop: 'static',
    });
    modelRef.componentInstance.title = 'Delete Widget';
    modelRef.componentInstance.message = 'Do you want to Delete this Widget?';
    modelRef.result
      .then((result?: boolean) => {
        if (result) {
          this.store.dispatch(WidgetsActions.deleteWidgetRequest({ widgetId: this.model.id }));
          this.activeModal.dismiss();
        }
      })
      .catch(res => {});
  }

  onChoicesEdit(choices: string[]) {
    this.choices = choices;
  }

  public ngOnDestroy(): void {
    this.store.dispatch(WidgetsPopoverActions.resetWidgetEditPage());
    this.subscription.unsubscribe();
  }
}
