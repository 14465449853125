import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalTheme } from '../../components/_layout/modal/modal.component';

@Component({
  selector: 'app-confirm-popup',
  templateUrl: 'confirm-popup.component.html',
  styleUrls: ['confirm-popup.component.scss'],
})
export class ConfirmPopupComponent implements OnInit {
  public readonly ModalTheme = ModalTheme;

  @Input() public title: string;
  @Input() public message: string;
  @Input() public warningMessage?: string;
  @Input() public yesText: string;
  @Input() public noText: string;
  @Input() public variant?: ModalTheme = ModalTheme.Danger;
  @Input() public noCloseBtn: boolean;

  constructor(public activeModal: NgbActiveModal) {
    this.title = 'Confirm';
    this.message = 'Do you want to continue?';
    this.yesText = 'Yes';
    this.noText = 'No';
  }

  public ngOnInit() {}

  public yes(data?: any) {
    this.activeModal.close(true);
  }

  public no(data?: any) {
    this.activeModal.close(false);
  }
}
