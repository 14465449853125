import { Pipe, PipeTransform } from '@angular/core';
import { CalculationMethodType } from '../../core/constants/calculation-method.type';

@Pipe({
  name: 'calculationMethodLabel',
})
export class CalculationMethodLabelPipe implements PipeTransform {
  transform(methodType: CalculationMethodType): string {
    const type = typeof methodType === 'string' ? parseInt(methodType, 10) : methodType;

    switch (type) {
      case CalculationMethodType.sum:
        return 'A + B';
      case CalculationMethodType.subtract:
        return 'A - B';
      case CalculationMethodType.multiply:
        return 'A x B';
      case CalculationMethodType.negativeMultiple:
        return '- (A x B)';
      case CalculationMethodType.divide:
        return 'A / B';
      case CalculationMethodType.negativeDivide:
        return '- (A / B)';
      default:
        return 'unknown';
    }
  }
}
