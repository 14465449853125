import { RichSearchType } from '../constants/rich-search-type';
import { EntityModel } from './entity.model';
import { NodeTemplateModel } from './node-template.model';
import { TagModel } from './tag.model';
import { SHORT_DATE } from '../constants/date-format.constants';
import * as moment from 'moment';
import { NodeModel } from './node.model';
import { IconType } from '../constants/icon-type';
import { ColorType } from '../constants/color-type';

export interface RichSearchItemModel {
  type: RichSearchType;
  icon?: IconType;
  iconColor?: ColorType;
  entity: EntityModel; // for keyword type search, the value of both id and the title is the keyword string.
}

export const createRichSearchKeywordItem = (keyword: string): RichSearchItemModel => {
  return {
    type: RichSearchType.keyword,
    entity: {
      id: keyword,
      title: keyword,
    },
  };
};

export const createRichSearchTemplateItem = (template: NodeTemplateModel): RichSearchItemModel => {
  return {
    type: RichSearchType.template,
    entity: template,
  };
};

export const createRichSearchLayoutItem = (
  layoutTemplate: NodeTemplateModel,
): RichSearchItemModel => {
  return {
    type: RichSearchType.layout,
    entity: layoutTemplate,
  };
};

export const createRichSearchTagItem = (tag: TagModel): RichSearchItemModel => {
  return {
    type: RichSearchType.tag,
    entity: tag,
  };
};

export const createRichSearchFolderItem = (folder: NodeModel): RichSearchItemModel => {
  return {
    type: RichSearchType.folder,
    entity: folder,
  };
};

export const createRichSearchUnassignedDateItem = (dateKey: string): RichSearchItemModel => {
  return {
    type: RichSearchType.unassignedDate,
    entity: {
      id: dateKey,
      title: moment(dateKey).format(SHORT_DATE),
    },
  };
};

export const filterCategoryRichSearchItems: RichSearchItemModel[] = [
  {
    type: RichSearchType.filterCategory,
    icon: IconType['apple-alt'],
    iconColor: ColorType['color-848484'],
    entity: {
      id: RichSearchType.template,
      title: 'Templates',
    },
  },
  {
    type: RichSearchType.filterCategory,
    icon: IconType['tags'],
    iconColor: ColorType['color-9e71bd'],
    entity: {
      id: RichSearchType.tag,
      title: 'Tags',
    },
  },
  // {
  //   type: RichSearchType.filterCategory,
  //   icon: IconType['folders'],
  //   iconColor: ColorType.gray,
  //   entity: {
  //     id: RichSearchType.folder,
  //     title: 'Folders',
  //   },
  // },
  {
    type: RichSearchType.filterCategory,
    icon: IconType['tablet'],
    iconColor: ColorType['color-67c9e3'],
    entity: {
      id: RichSearchType.layout,
      title: 'Layouts',
    },
  },
  {
    type: RichSearchType.filterCategory,
    icon: IconType['calendar-day'],
    iconColor: ColorType['color-67c9e3'],
    entity: {
      id: RichSearchType.unassignedDate,
      title: 'Unassigned',
    },
  },
];
