import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { ErrorsActions } from '../errors';
import { WorkspaceUserGroupsActions } from './index';
import { WorkspaceUserGroupsService } from '../../core/services/workspace-user-groups.service';
import { convertServerWorkspaceUserGroup } from '../../core/utils/server-workspace-user-group.util';

@Injectable()
export class WorkspaceUserGroupsEffects {
  constructor(
    private workspaceUserGroupsService: WorkspaceUserGroupsService,
    private actions$: Actions,
  ) {}

  loadCurrentWorkspaceUserGroupsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceUserGroupsActions.loadCurrentWorkspaceUserGroupsRequest),
      mergeMap(action =>
        this.workspaceUserGroupsService.loadWorkspaceUserGroupsBySlug(action.workspaceSlug).pipe(
          map(serverGroups => {
            const groups = (serverGroups || []).map(convertServerWorkspaceUserGroup);
            return WorkspaceUserGroupsActions.loadCurrentWorkspaceUserGroupsSuccess({
              groups,
            });
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  addWorkspaceUserGroupRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceUserGroupsActions.addWorkspaceUserGroupRequest),
      mergeMap(action =>
        this.workspaceUserGroupsService
          .addWorkspaceUserGroup(action.workspaceSlug, {
            ...action.props,
          })
          .pipe(
            map(serverGroup => {
              const group = convertServerWorkspaceUserGroup(serverGroup);
              return WorkspaceUserGroupsActions.addWorkspaceUserGroupSuccess({ group });
            }),
            catchError(error => {
              return of(ErrorsActions.goToErrorPage({ error }));
            }),
          ),
      ),
    ),
  );

  updateWorkspaceUserGroupRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceUserGroupsActions.updateWorkspaceUserGroupRequest),
      mergeMap(action =>
        this.workspaceUserGroupsService.updateWorkspaceUserGroup(action.groupId, action.props).pipe(
          map(serverGroup => {
            const group = convertServerWorkspaceUserGroup(serverGroup);
            return WorkspaceUserGroupsActions.updateWorkspaceUserGroupSuccess({ group });
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  deleteWorkspaceUserGroupRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceUserGroupsActions.deleteWorkspaceUserGroupRequest),
      mergeMap(action =>
        this.workspaceUserGroupsService.deleteWorkspaceUserGroup(action.groupId).pipe(
          switchMap(() => {
            return EMPTY;
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );
}
