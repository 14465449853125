<div
  class="chat-comment-bubble"
  *ngIf="comment?.comment"
  [ngClass]="fromRight ? 'chat-comment-bubble--from-right' : 'chat-comment-bubble--from-left'"
  [ngClass]="{
    'chat-comment-bubble--from-right': fromRight,
    'chat-comment-bubble--from-left': !fromRight,
    'chat-comment-bubble--edit-active': comment?.id === selectedCommentForEdit?.id
  }"
>
  {{ comment?.comment }}
  <app-curved-corner
    class="chat-comment-bubble__curved-corner"
    [shape]="fromRight ? CurvedCornerType.topLeft : CurvedCornerType.topRight"
    [color]="fromRight ? CurvedCornerColorType.primary : CurvedCornerColorType.creamy"
  ></app-curved-corner>

  <app-curved-corner
    class="chat-comment-bubble__curved-corner"
    [shape]="fromRight ? CurvedCornerType.topLeft : CurvedCornerType.topRight"
    [color]="
      comment?.id === selectedCommentForEdit?.id
        ? CurvedCornerColorType.info
        : fromRight
        ? CurvedCornerColorType.primary
        : CurvedCornerColorType.creamy
    "
  ></app-curved-corner>
</div>
