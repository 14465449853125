import { Action, createReducer, on } from '@ngrx/store';
import { initialNodeWidgetRowsState, NodeWidgetRowsState } from './node-widget-rows.state';
import { NodeWidgetRowsActions } from './index';
import { NodeWidgetRowModel } from '../../core/models/node-widget-row.model';

const clearNodeWidgetRowsByNodeIdFromState = (
  state: NodeWidgetRowsState,
  nodeId: number,
): NodeWidgetRowsState => {
  let widgetsByNodeId = state.nodeWidgetRowsByNodeId[nodeId] || [];
  if (widgetsByNodeId.length === 0) {
    return state;
  }

  let nodeWidgetRowsById = { ...state.nodeWidgetRowsById };
  widgetsByNodeId.forEach(nodeWidgetRow => {
    nodeWidgetRowsById = {
      ...nodeWidgetRowsById,
      [nodeWidgetRow.nodeId]: undefined,
    };
  });

  let nodeWidgetRowsByWidgetId = Object.keys(state.nodeWidgetRowsByWidgetId).reduce((list, key) => {
    return {
      ...list,
      [key]: state.nodeWidgetRowsByWidgetId[key].filter(wr => wr.nodeId !== nodeId),
    };
  }, {});

  return {
    ...(state || {}),

    nodeWidgetRowsById: nodeWidgetRowsById,
    nodeWidgetRowsByWidgetId: nodeWidgetRowsByWidgetId,
    nodeWidgetRowsByNodeId: {
      ...state.nodeWidgetRowsByNodeId,
      [nodeId]: [],
    },
  };
};

const addNodeWidgetRowToState = (
  state: NodeWidgetRowsState,
  nodeWidgetRow: NodeWidgetRowModel,
): NodeWidgetRowsState => {
  return {
    ...(state || {}),

    nodeWidgetRowsById: {
      ...state.nodeWidgetRowsById,
      [nodeWidgetRow.id]: nodeWidgetRow,
    },

    nodeWidgetRowsByWidgetId: {
      ...state.nodeWidgetRowsByWidgetId,
      [nodeWidgetRow.widgetId]: [
        ...(state.nodeWidgetRowsByNodeId[nodeWidgetRow.widgetId] || []).filter(
          widgetRow => widgetRow.id !== nodeWidgetRow.id,
        ),
        nodeWidgetRow,
      ],
    },

    nodeWidgetRowsByNodeId: {
      ...state.nodeWidgetRowsByNodeId,
      [nodeWidgetRow.nodeId]: [
        ...(state.nodeWidgetRowsByNodeId[nodeWidgetRow.nodeId] || []).filter(
          widgetRow => widgetRow.id !== nodeWidgetRow.id,
        ),
        nodeWidgetRow,
      ],
    },
  };
};

const removeNodeWidgetRowFromState = (
  state: NodeWidgetRowsState,
  nodeWidgetRow: NodeWidgetRowModel,
): NodeWidgetRowsState => {
  return {
    ...(state || {}),

    nodeWidgetRowsById: {
      ...state.nodeWidgetRowsById,
      [nodeWidgetRow.id]: undefined,
    },

    nodeWidgetRowsByWidgetId: {
      ...state.nodeWidgetRowsByWidgetId,
      [nodeWidgetRow.widgetId]: [
        ...(state.nodeWidgetRowsByWidgetId[nodeWidgetRow.widgetId] || []).filter(
          widget => widget.id !== nodeWidgetRow.id,
        ),
      ],
    },

    nodeWidgetRowsByNodeId: {
      ...state.nodeWidgetRowsByNodeId,
      [nodeWidgetRow.nodeId]: [
        ...(state.nodeWidgetRowsByNodeId[nodeWidgetRow.nodeId] || []).filter(
          widget => widget.id !== nodeWidgetRow.id,
        ),
      ],
    },
  };
};

const reducer = createReducer<NodeWidgetRowsState>(
  initialNodeWidgetRowsState,

  on(NodeWidgetRowsActions.loadNodeWidgetRowsFromNodeTree, (state, { nodeWidgetRows }) => {
    return nodeWidgetRows.reduce((state, nodeWidgetRow) => {
      return addNodeWidgetRowToState(state, nodeWidgetRow);
    }, state);
  }),

  on(NodeWidgetRowsActions.updateNodeWidgetRowSuccess, (state, { nodeWidgetRow }) => {
    return addNodeWidgetRowToState(state, nodeWidgetRow);
  }),
);

export function nodeWidgetRowsReducer(state: NodeWidgetRowsState, action: Action) {
  return reducer(state, action);
}
