import { NodeType } from '../constants/node-type';
import { ISortable, NodeModel } from '../models/node.model';
import { NodeTagModel } from '../models/node-tag.model';
import { NodeRateModel } from '../models/node-rate.model';
import { NodeTemplateWidgetModel } from '../models/node-template-widget.model';
import { NodeTemplateModel } from '../models/node-template.model';
import { NodeWidgetValueModel } from '../models/node-widget-value.model';
import { NodeWidgetRowModel } from '../models/node-widget-row.model';
import { TagModel } from '../models/tag.model';
import { WidgetModel } from '../models/widget.model';
import { EntityModel } from '../models/entity.model';
import { NodeRateValueModel } from '../models/node-rate-value.model';
import { WorkspaceGroupModel } from '../models/workspace-group.model';
import { WorkspaceGroupType } from '../constants/workspace-group-type';
import { TagType } from '../constants/tag-type';
import { BehaviourType } from '../constants/behaviour-type';
import { WidgetType } from '../constants/widget-type';
import { NodeGroupValueModel } from '../models/node-group-value.model';
import { TimesheetModel } from '../models/timesheet.model';

const awaitingAssignmentsDateKey = 'null';

const parseNodeTree = (
  nodes: NodeModel[],
): {
  nodes: NodeModel[];
  nodeTags: NodeTagModel[];
  nodeRates: NodeRateModel[];
  nodeRateValues: NodeRateValueModel[];
  nodeWidgetValues: NodeWidgetValueModel[];
  nodeWidgetRows: NodeWidgetRowModel[];
  nodeGroupValues: NodeGroupValueModel[];
} => {
  let cleanNodes: NodeModel[] = [];
  let nodeTags: NodeTagModel[] = [];
  let nodeRates: NodeRateModel[] = [];
  let nodeRateValues: NodeRateValueModel[] = [];
  let nodeWidgetValues: NodeWidgetValueModel[] = [];
  let nodeWidgetRows: NodeWidgetRowModel[] = [];
  let nodeGroupValues: NodeGroupValueModel[] = [];

  let processNodes = (node: NodeModel) => {
    if (node.children != null && node.children.length) {
      node.children.forEach(n => {
        processNodes(n);
      });
    }

    nodeTags.push(...(node.__tags || []));
    nodeTags.push(...(node.__primaryTags || []));
    nodeTags.push(...(node.__stampTags || []));
    nodeRates.push(...(node.__rates || []));
    nodeRateValues.push(...(node.__rateValues || []));
    nodeGroupValues.push(...(node.__groups || []));

    const widgetValues = [];
    (node.__widgetRows || []).forEach(wr => {
      wr.values.forEach(wv => {
        widgetValues.push({ ...wv, rowId: wr.id } as NodeWidgetValueModel);
      });
      wr.values = [];
    });
    nodeWidgetRows.push(...(node.__widgetRows || []));
    nodeWidgetValues.push(...widgetValues);
    cleanNodes.push({
      ...node,
      children: [],
      widgets: [],
      widgetRows: [],
      tags: [],
      rates: [],
      rateValues: [],

      // todo: once refactoring complete, enable these
      // __tags: [],
      // __primaryTags: [],
      // __stampTags: [],
      // __rates: [],
      // __rateValues: [],
      // __widgets: [],
      // __widgetRows: [],
      // __groups: [],
    });
  };
  (nodes || []).forEach(n => {
    processNodes(n);
  });
  return {
    nodes: cleanNodes,
    nodeTags,
    nodeRates,
    nodeRateValues,
    nodeWidgetValues: nodeWidgetValues,
    nodeWidgetRows,
    nodeGroupValues,
  };
};

const enrichNode = (
  node: NodeModel,
  nodeTagsByNodeId: { [id: number]: NodeTagModel[] },
  nodeRatesByNodeId: { [id: number]: NodeRateModel[] },
  nodeRateValuesByNodeId: { [id: number]: NodeRateValueModel[] },
  nodeWidgetValuesByNodeId: { [id: number]: NodeWidgetValueModel[] },
  nodeWidgetRowsByNodeId: { [id: number]: NodeWidgetRowModel[] },
  nodeGroupsByNodeId: { [id: number]: NodeGroupValueModel[] },
  nodeTemplatesById: { [id: number]: NodeTemplateModel },
  nodesById: { [id: number]: NodeModel },
  nodesByParentId: { [id: number]: NodeModel[] },
  tagsById: { [id: number]: TagModel },
  widgetsById: { [id: number]: WidgetModel },
  groupsById: { [id: number]: WorkspaceGroupModel },
  groupsByTemplateId: { [id: number]: WorkspaceGroupModel[] },
  templateWidgetsByTemplateId: { [id: number]: NodeTemplateWidgetModel[] },
  timesheetsByNodeId: { [id: number]: TimesheetModel[] },
): NodeModel => {
  if (node == null) {
    return null;
  }

  let colorTheme = node.colorTheme;
  if (colorTheme == null && node.parentNodeId != null) {
    colorTheme = nodesById[node.parentNodeId]?.colorTheme;
  }

  const nodeTemplate = nodeTemplatesById[node.nodeTemplateId];

  const primaryTags = [];
  const stampTags = [];
  const tags = [];
  (nodeTagsByNodeId[node.id] || []).forEach(nt => {
    const tag = {
      ...nt,
      tag: tagsById[nt.id],
    };
    switch (tag.tagType) {
      case TagType.primary:
        primaryTags.push(tag);
        break;

      case TagType.stamp:
        stampTags.push(tag);
        break;

      case TagType.general:
      default:
        tags.push(tag);
        break;
    }
  });

  const nodeWidgetValues = (nodeId: number) => {
    return nodeWidgetValuesByNodeId[nodeId];
  };

  const nodeWidgetRows = (nodeId: number) => {
    return (nodeWidgetRowsByNodeId[nodeId] || [])
      ?.reduce((list, wr) => {
        const values = (nodeWidgetValues(nodeId) || [])?.filter(
          wv => wv?.rowId === wr?.id && wv?.id !== null,
        );
        return [...list, { ...wr, values } as NodeWidgetRowModel];
      }, [])
      ?.sort((a, b) => {
        if (a.rowNumber < b.rowNumber) {
          return -1;
        }
        if (a.rowNumber > b.rowNumber) {
          return 1;
        }
        return 0;
      });
  };

  const resolveTemplateWidget = (templateWidget: NodeTemplateWidgetModel, nodeId: number) => {
    const widgetValues = [];
    (nodeWidgetRows(nodeId) || [])?.forEach(wr => {
      const values = wr.values?.filter(wv => wv.widgetId === templateWidget.id);
      if (values?.length) {
        widgetValues.push(...values);
      }
    });

    return {
      ...templateWidget,
      widget: widgetsById[templateWidget.id],
      values: widgetValues,
      value: widgetValues[0]?.value,
    } as NodeTemplateWidgetModel;
  };

  const children = nodesByParentId[node.id] || [];

  const assetGroups: NodeTemplateModel[] = [];
  const elementGroups: NodeTemplateModel[] = [];
  if (nodeTemplate != null) {
    nodeTemplate.allowedTemplateIds.forEach(id => {
      if (nodeTemplatesById[id] == null) {
        return;
      }
      const template = {
        ...nodeTemplatesById[id],
        nodes: children
          .filter(n => {
            return n.nodeType === NodeType.assignmentElement
              ? nodesById[n.referenceNodeId]?.nodeTemplateId === id
              : n.nodeTemplateId === id;
          })
          .map(n => {
            const referenceNode = nodesById[n?.referenceNodeId];
            const widgets: NodeTemplateWidgetModel[] = templateWidgetsByTemplateId[
              referenceNode?.nodeTemplateId
            ]?.map(tw => {
              const widgetA = tw.widgetA
                ? resolveTemplateWidget(tw?.widgetA, n?.referenceNodeId)
                : null;
              const widgetB = tw.widgetB
                ? resolveTemplateWidget(tw?.widgetB, n?.referenceNodeId)
                : null;
              return {
                ...resolveTemplateWidget(tw, n?.referenceNodeId),
                widgetA,
                widgetB,
              } as NodeTemplateWidgetModel;
            });

            // getting the node widgets saved under assignmentElement id
            const widgetList: NodeWidgetValueModel[] = nodeWidgetValues(n?.id);

            const updatedWidgets = widgets?.map((item: NodeTemplateWidgetModel) => {
              const findItem = widgetList?.find(_item => _item?.widgetId === item?.id);
              if (item.values?.length && findItem?.value) {
                item.values[0] = { ...item.values[0], value: findItem?.value };
              }

              return {
                ...item,
                value: findItem?.value ? findItem.value : item?.value,
              };
            });

            return {
              ...n,
              reference: {
                ...referenceNode,
                widgets: updatedWidgets,
                rates: referenceNode?.__rates,
                widgetRows: referenceNode?.__widgetRows,
              },
            };
          }),
      };

      switch (template.nodeType) {
        case NodeType.asset:
          assetGroups.push(template);
          break;
        case NodeType.element:
        default:
          elementGroups.push(template);
          break;
      }
    });
  }

  const nodeTemplateWidgets = (templateWidgetsByTemplateId[node.nodeTemplateId] || []).map(tw => {
    const widgetA = tw.widgetA ? resolveTemplateWidget(tw.widgetA, node?.id) : null;
    const widgetB = tw.widgetB ? resolveTemplateWidget(tw.widgetB, node?.id) : null;
    return {
      ...resolveTemplateWidget(tw, node?.id),
      widgetA,
      widgetB,
    } as NodeTemplateWidgetModel;
  });

  const calculationGroups: NodeTemplateWidgetModel[] = nodeTemplateWidgets
    .filter(w => w?.widget?.widgetType === WidgetType.calculationJoin)
    .sort(NodeUtils.sortByIndex);

  const primaryTagGroups: WorkspaceGroupModel[] = [];
  const stampTagGroups: WorkspaceGroupModel[] = [];
  const checklistGroups: WorkspaceGroupModel[] = [];
  (groupsByTemplateId[node.nodeTemplateId] || []).forEach(g => {
    switch (g.type) {
      case WorkspaceGroupType.checklist:
        const checkboxes = nodeTemplateWidgets
          .filter(w => w.groupId === g.id)
          .map(w => {
            return {
              ...w,
              nodeTemplateId: g.nodeTemplateId,
            };
          })
          .sort(NodeUtils.sortByIndex);
        checklistGroups.push({
          ...g,
          widgets: checkboxes,
          value: nodeGroupsByNodeId[node.id]
            ? nodeGroupsByNodeId[node.id].find(ng => ng.groupId === g.id)
            : null,
        } as WorkspaceGroupModel);
        break;

      case WorkspaceGroupType.primaryTag:
        const primaries = primaryTags
          .filter(t => t.groupId === g.id)
          .map(t => {
            return {
              ...t,
              nodeTemplateId: g.nodeTemplateId,
            };
          })
          .sort(NodeUtils.sortByIndex);
        primaryTagGroups.push({
          ...g,
          tags: primaries,
          value: nodeGroupsByNodeId[node.id]
            ? nodeGroupsByNodeId[node.id].find(ng => ng.groupId === g.id)
            : null,
        } as WorkspaceGroupModel);
        break;

      case WorkspaceGroupType.stamp:
        const stamps = stampTags
          .filter(t => t.groupId === g.id)
          .map(t => {
            return {
              ...t,
              nodeTemplateId: g.nodeTemplateId,
            };
          })
          .sort(NodeUtils.sortByIndex);
        stampTagGroups.push({
          ...g,
          tags: stamps,
          value: nodeGroupsByNodeId[node.id]
            ? nodeGroupsByNodeId[node.id].find(ng => ng.groupId === g.id)
            : null,
        } as WorkspaceGroupModel);
        break;
    }
  });

  const allowedTemplates = (node.allowedTemplateIds || [])
    .filter(id => nodeTemplatesById[id] != null)
    .map(id => nodeTemplatesById[id]);

  const timesheets = timesheetsByNodeId[node.id] || [];

  const result = {
    ...node,
    colorTheme,
    parent: nodesById[node.parentNodeId],
    reference: nodesById[node.referenceNodeId],
    nodeTemplate: nodeTemplate,
    //nodeMeta: nodeMetasById[node.id] || null,
    tags: tags,
    primaryTags: primaryTags,
    primaryTagGroups: primaryTagGroups,
    stampTags: stampTags,
    stampTagGroups: stampTagGroups,
    children: children,
    assetGroups: assetGroups,
    elementGroups: elementGroups,
    rates: nodeRatesByNodeId[node.id] || [],
    rateValues: nodeRateValuesByNodeId[node.id] || [],
    widgetRows: nodeWidgetRows(node?.id),
    widgets: nodeTemplateWidgets,
    checklistGroups: checklistGroups,
    calculationGroups: calculationGroups,
    allowedTemplates: allowedTemplates,
    timesheets: timesheets,

    // todo: notes and notifications should be reviewed
    notes: node.__notes,
    notifications: node.__notifications,
  } as NodeModel;
  return result;
};

const filterNodesByTemplateBehaviour = (
  nodeIds: number[],
  nodesById: { [id: number]: NodeModel },
  nodeTemplatesById: { [id: number]: NodeTemplateModel },
): number[] => {
  const nodesByTemplateHash = {};
  nodeIds.forEach(id => {
    const node = nodesById[id];
    const template = node ? nodeTemplatesById[node.nodeTemplateId] : null;
    if (template == null) {
      return;
    }
    if (
      nodesByTemplateHash[template.id] == null ||
      template.behaviourType === BehaviourType.single
    ) {
      nodesByTemplateHash[template.id] = {};
    }
    nodesByTemplateHash[template.id][id] = id;
  });

  return Object.values(nodesByTemplateHash).reduce((list: number[], ids: any) => {
    return [...list, ...Object.values(ids)];
  }, []) as number[];
};

const findNodesToRemoveByTemplateBehaviour = (
  assignment: NodeModel,
  nodeTemplates: NodeTemplateModel[],
  nodesById: { [id: number]: NodeModel },
  assignmentsByParentId: { [id: number]: NodeModel[] },
) => {
  const nodes = assignmentsByParentId[assignment.id] || [];
  if (nodes.length === 0) {
    return [];
  }
  return nodes.reduce((list, assignmentElement) => {
    const referenceNode = nodesById[assignmentElement.referenceNodeId];
    const template = referenceNode
      ? nodeTemplates.find(nt => nt.id === referenceNode.nodeTemplateId)
      : null;
    if (template == null || template.behaviourType !== BehaviourType.single) {
      return list;
    }
    return [...list, assignmentElement];
  }, []);
};

const getNodeTypeLabel = (nodeType: NodeType): string => {
  switch (nodeType) {
    case NodeType.projectFolder:
      return 'Project Folder';

    case NodeType.project:
      return 'Project';

    case NodeType.assetFolder:
      return 'Asset Folder';

    case NodeType.asset:
      return 'Asset';

    case NodeType.elementFolder:
      return 'Element Folder';

    case NodeType.element:
      return 'Element';

    case NodeType.assignmentFolder:
      return 'Assignment Folder';

    case NodeType.assignment:
      return 'Assignment';

    case NodeType.assignmentElement:
      return 'Assignment Element';

    case NodeType.favouritesFolder:
      return 'Favourites Folder';

    case NodeType.folderShortcut:
      return 'Shortcut';

    default:
      return 'Unknown';
  }
  //throw new Error(`Unknown NodeType: ${nodeType}`);
};

const getChildNodeType = (nodeType: NodeType): number => {
  switch (nodeType) {
    case NodeType.projectFolder:
      return NodeType.project;

    case NodeType.assetFolder:
      return NodeType.asset;

    case NodeType.elementFolder:
      return NodeType.element;

    case NodeType.assignmentFolder:
      return NodeType.assignment;

    case NodeType.assignment:
      return NodeType.assignmentElement;

    case NodeType.favouritesFolder:
    case NodeType.folderShortcut:
      return NodeType.folderShortcut;
  }
  throw new Error(`Unknown NodeType: ${nodeType}`);
};

const getParentNodeType = (nodeType: NodeType): number => {
  switch (nodeType) {
    case NodeType.projectFolder:
    case NodeType.project:
      return NodeType.projectFolder;

    case NodeType.assetFolder:
    case NodeType.asset:
      return NodeType.assetFolder;

    case NodeType.elementFolder:
    case NodeType.element:
      return NodeType.elementFolder;

    case NodeType.assignmentFolder:
    case NodeType.assignment:
      return NodeType.assignmentFolder;

    case NodeType.assignmentElement:
      return NodeType.assignment;

    case NodeType.favouritesFolder:
    case NodeType.folderShortcut:
      return NodeType.favouritesFolder;
  }
  throw new Error(`Unknown NodeType: ${nodeType}`);
};

const getTemplateNodeType = (nodeType: NodeType): number => {
  switch (nodeType) {
    case NodeType.projectFolder:
    case NodeType.project:
      return NodeType.project;

    case NodeType.assetFolder:
    case NodeType.asset:
      return NodeType.asset;

    case NodeType.elementFolder:
    case NodeType.element:
      return NodeType.element;

    case NodeType.assignmentFolder:
    case NodeType.assignment:
    case NodeType.assignmentElement:
      return NodeType.assignment;
  }
  throw new Error(`Unknown NodeType: ${nodeType}`);
};

const isFolder = (node: NodeModel): boolean => {
  switch (node.nodeType) {
    case NodeType.projectFolder:
    case NodeType.assetFolder:
    case NodeType.elementFolder:
    case NodeType.assignmentFolder:
    case NodeType.favouritesFolder:
    case NodeType.folderShortcut:
      return true;

    case NodeType.project:
    case NodeType.asset:
    case NodeType.element:
    case NodeType.assignment:
    case NodeType.assignmentElement:
      return false;
  }
  console.error('Unknown NodeType:', node);
  throw new Error(`Unknown NodeType: ${node.nodeType}`);
};

const isShortcut = (node: NodeModel): boolean => {
  return node.nodeType === NodeType.folderShortcut;
};

const getPathIds = (node: NodeModel): string[] => {
  return node.path.split('/').filter(i => i);
};

const buildTree = (list: NodeModel[]): NodeModel[] => {
  const nodesById = {};

  list.forEach(n => {
    nodesById[n.id] = n;
  });

  const updateLeaf = (n: NodeModel, nodePath: any) => {
    // Merge previous branch data with new data
    const parent = n.parent || nodesById[n.parentNodeId] || null;
    const reference = n.reference || nodesById[n.referenceNodeId] || null;
    nodePath[n.id] = {
      ...n,
      parent,
      reference,
      children: nodePath[n.id] ? nodePath[n.id].children : {},
    };
  };

  let tree = {};
  list.forEach(n => {
    const pathIds = getPathIds(n);

    // Root
    if (pathIds.length === 0) {
      updateLeaf(n, tree);
      return;
    }

    // Branch
    let nodePath = tree;
    pathIds.forEach(id => {
      if (nodePath[id] == null) {
        nodePath[id] = {
          ...(nodesById[id] || {}),
          children: {},
        };
      }
      nodePath = nodePath[id].children;
    });
    updateLeaf(n, nodePath);
  });

  const removeKeys = (obj: any): NodeModel[] => {
    let cleanArray = [];
    const newArray = Object.values<NodeModel>({ ...obj });
    newArray.forEach(item => {
      if (item.children) {
        item.children = removeKeys(item.children);
      }
      // If the item doesn't have an 'id', we need to assume it's an invalid
      // parent node and wasn't included in the original list.
      // We'll filter/truncate these items and only leave a tree with valid 'NodeModels'
      if (item.hasOwnProperty('id')) {
        cleanArray = [...cleanArray, item];
      } else {
        cleanArray = [...cleanArray, ...item.children];
      }
    });
    return cleanArray.sort(sortByIndex);
  };

  return removeKeys(tree);
};

const flattenTree = (tree: NodeModel[]): NodeModel[] => {
  const flatTree = [];
  const flattenArray = (items: NodeModel[]) => {
    items.forEach(item => {
      flatTree.push({ ...item, children: [] });
      if (item.children) {
        flattenArray(item.children);
      }
    });
  };
  flattenArray(tree);
  return flatTree;
};

const getDescendants = (
  nodes: NodeModel[],
  nodesByParentId: { [p: number]: NodeModel[] },
): NodeModel[] => {
  return nodes.reduce((listA, nodeA) => {
    const children = (nodesByParentId[nodeA.id] || []).reduce((listB, nodeB) => {
      return [
        ...listB,
        {
          ...nodeB,
          parent: nodeA,
        },
      ];
    }, []);
    return [...listA, ...children, ...getDescendants(children, nodesByParentId)];
  }, []);
};

const getAncestors = (node: NodeModel, nodesById: { [p: number]: NodeModel[] }): NodeModel[] => {
  const pathIds = getPathIds(node);
  return pathIds.reduce((list, id) => {
    const n = nodesById[id];
    return [
      ...list,
      {
        ...n,
        parent: nodesById[n.parentNodeId],
        reference: nodesById[n.referenceNodeId],
      },
    ];
  }, []);
};

const getTagIdsAndCounts = (nodes: NodeModel[]): { tagIds: number[]; countsById: any } => {
  const countsById = {};
  let tagIdSet = new Set<number>();
  nodes.forEach(n => {
    n.tags
      .filter(x => !!x) // this is sometimes 'undefined'. Not sure why?
      .forEach((nodeTag: NodeTagModel) => {
        tagIdSet.add(nodeTag.id);
        countsById[nodeTag.id] = countsById[nodeTag.id] ? countsById[nodeTag.id] + 1 : 1;
      });
  });
  return { tagIds: Array.from(tagIdSet), countsById };
};

const getTemplateIdsAndCounts = (nodes: NodeModel[]) => {
  const countsById = {};
  let templateIdSet = new Set<number>();
  nodes.forEach(leafViewModel => {
    const nodeTemplateId = leafViewModel.nodeTemplateId;
    templateIdSet.add(nodeTemplateId);
    countsById[nodeTemplateId] = countsById[nodeTemplateId] ? countsById[nodeTemplateId] + 1 : 1;
  });

  return {
    templateIds: Array.from(templateIdSet),
    countsById,
  };
};

const sortByIndex = (a: ISortable, b: ISortable): number => {
  if (a === b) {
    return 0;
  }
  if (!b) {
    return -1;
  }
  if (!a) {
    return 1;
  }

  if (a.sortIndex < b.sortIndex) {
    return -1;
  }
  if (a.sortIndex > b.sortIndex) {
    return 1;
  }
  return 0;
};

const sortByDepth = (a: NodeModel, b: NodeModel): number => {
  if (a === b) {
    return 0;
  }
  if (!b) {
    return -1;
  }
  if (!a) {
    return 1;
  }

  if (a.depth < b.depth) {
    return -1;
  }
  if (a.depth > b.depth) {
    return 1;
  }
  return 0;
};

const sortByParentNode = (a: NodeModel, b: NodeModel): number => {
  if (a === b) {
    return 0;
  }
  if (!b) {
    return -1;
  }
  if (!a) {
    return 1;
  }

  if (a.parentNodeId === b.parentNodeId) {
    return 0;
  }
  if (!b.parent) {
    return -1;
  }
  if (!a.parent) {
    return 1;
  }

  if (a.parent.sortIndex < b.parent.sortIndex) {
    return -1;
  }
  if (a.parent.sortIndex > b.parent.sortIndex) {
    return 1;
  }

  if (a.sortIndex < b.sortIndex) {
    return -1;
  }
  if (a.sortIndex > b.sortIndex) {
    return 1;
  }

  return 0;
};

const sortByTemplate = (a: NodeModel, b: NodeModel): number => {
  if (a === b) {
    return 0;
  }
  if (!b) {
    return -1;
  }
  if (!a) {
    return 1;
  }

  if (a.nodeTemplateId < b.nodeTemplateId) {
    return -1;
  }
  if (a.nodeTemplateId > b.nodeTemplateId) {
    return 1;
  }
  return 0;
};

const sortByTitle = (a: EntityModel, b: EntityModel): number => {
  if (a === b) {
    return 0;
  }
  if (!b) {
    return -1;
  }
  if (!a) {
    return 1;
  }
  return a.title.localeCompare(b.title);
};

const sortByID = (a, b): number => {
  if (a === b) {
    return 0;
  }
  if (!b) {
    return -1;
  }
  if (!a) {
    return 1;
  }

  if (a.id < b.id) {
    return -1;
  }
  if (a.id > b.id) {
    return 1;
  }
  return 0;
};

export const NodeUtils = {
  awaitingAssignmentsDateKey,
  parseNodeTree,
  enrichNode,
  filterNodesByTemplateBehaviour,
  findNodesToRemoveByTemplateBehaviour,
  getNodeTypeLabel,
  getParentNodeType,
  getChildNodeType,
  getTemplateNodeType,
  isFolder,
  isShortcut,
  getPathIds,
  buildTree,
  flattenTree,
  getDescendants,
  getAncestors,
  getTagIdsAndCounts,
  getTemplateIdsAndCounts,
  sortByParentNode,
  sortByTemplate,
  sortByIndex,
  sortByDepth,
  sortByTitle,
  sortByID,
};
