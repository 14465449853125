import { AbstractControl } from '@angular/forms';
import { AddressModel } from '../../../core/models/address.model';

export function addressFieldRequiredValidator(
  control: AbstractControl,
): { [key: string]: any } | null {
  const value = control.value as AddressModel;
  if (value) {
    const valueString = `${value.street}${value.suburb}${value.state}${value.postcode}${value.country}`.trim();
    if (valueString !== '') {
      return null;
    }
  }
  return { required: { value: control.value } };
}
