import { Action, createReducer, on } from '@ngrx/store';
import { initialNodeTagsState, NodeTagsState } from './node-tags.state';
import { NodeTagsActions } from './index';
import { TagsActions } from '../tags';
import { NodeTagModel } from '../../core/models/node-tag.model';

const clearNodeTagsByNodeIdFromState = (state: NodeTagsState, nodeId: number): NodeTagsState => {
  let tagsByNodeId = state.nodeTagsByNodeId[nodeId] || [];
  if (tagsByNodeId.length === 0) {
    return state;
  }

  let nodeTagsById = { ...state.nodeTagsById };
  tagsByNodeId.forEach(nodeTag => {
    nodeTagsById = {
      ...nodeTagsById,
      [nodeTag.joinId]: undefined,
    };
  });
  return {
    ...(state || {}),

    nodeTagsById,

    nodeTagsByNodeId: {
      ...state.nodeTagsByNodeId,
      [nodeId]: [],
    },
  };
};

const addNodeTagToState = (state: NodeTagsState, nodeTag: NodeTagModel): NodeTagsState => {
  return {
    ...(state || {}),

    nodeTagsById: {
      ...state.nodeTagsById,
      [nodeTag.joinId]: nodeTag,
    },

    nodeTagsByNodeId: {
      ...state.nodeTagsByNodeId,
      [nodeTag.nodeId]: [
        ...(state.nodeTagsByNodeId[nodeTag.nodeId] || []).filter(
          tag => tag.joinId !== nodeTag.joinId,
        ),
        nodeTag,
      ],
    },
  };
};

const removeNodeTagFromState = (state: NodeTagsState, nodeTag: NodeTagModel): NodeTagsState => {
  return {
    ...(state || {}),

    nodeTagsById: {
      ...state.nodeTagsById,
      [nodeTag.joinId]: undefined,
    },

    nodeTagsByNodeId: {
      ...state.nodeTagsByNodeId,
      [nodeTag.nodeId]: [
        ...(state.nodeTagsByNodeId[nodeTag.nodeId] || []).filter(
          tag => tag.joinId !== nodeTag.joinId,
        ),
      ],
    },
  };
};

const reducer = createReducer<NodeTagsState>(
  initialNodeTagsState,

  on(NodeTagsActions.loadNodeTagsFromNodeTree, (state, { nodeTags }) => {
    return nodeTags.reduce((state, nodeTag) => {
      return addNodeTagToState(state, nodeTag);
    }, state);
  }),

  on(NodeTagsActions.addNodeTagSuccess, (state, { nodeTag }) => {
    return addNodeTagToState(state, nodeTag);
  }),

  on(NodeTagsActions.removeNodeTagRequest, (state, { joinId }) => {
    let nodeTag = state.nodeTagsById[joinId] || null;
    if (nodeTag != null) {
      return removeNodeTagFromState(state, nodeTag);
    }
    return state;
  }),

  on(NodeTagsActions.removeNodeTagsByTagId, (state, { tagId }) => {
    let tagsToRemove = Object.keys(state.nodeTagsById)
      .filter(key => {
        return state.nodeTagsById[key] && state.nodeTagsById[key].id === tagId;
      })
      .map(key => state.nodeTagsById[key]);

    if (tagsToRemove.length) {
      let newState = { ...state };
      tagsToRemove.forEach((tag: NodeTagModel) => {
        newState = removeNodeTagFromState(newState, tag);
      });
      return newState;
    }
    return state;
  }),

  on(NodeTagsActions.removeNodeTagsByNodeIdGroupId, (state, { nodeId, groupId }) => {
    let tagsToRemove = (state.nodeTagsByNodeId[nodeId] || []).filter((nodeTag: NodeTagModel) => {
      return nodeTag.groupId === groupId;
    });
    if (tagsToRemove.length) {
      let newState = { ...state };
      tagsToRemove.forEach((tag: NodeTagModel) => {
        newState = removeNodeTagFromState(newState, tag);
      });
      return newState;
    }
    return state;
  }),

  on(NodeTagsActions.clearNodeTagsRequest, (state, { nodeId }) => {
    const nodeTags = state.nodeTagsByNodeId[nodeId] || [];
    if (nodeTags.length) {
      let newState = { ...state };
      nodeTags.forEach((nodeTag: NodeTagModel) => {
        newState = removeNodeTagFromState(newState, nodeTag);
      });
      return newState;
    }
    return state;
  }),

  on(
    NodeTagsActions.addMultipleTagsToNodeSuccess,
    (state, { nodeId, nodeTags, groupId, replace }) => {
      let newState = { ...state };

      // todo: what about groups?
      if (replace === true) {
        newState = clearNodeTagsByNodeIdFromState(newState, nodeId);
      }

      if (nodeTags.length > 0) {
        newState = nodeTags.reduce((newState, nodeTag) => {
          return addNodeTagToState(newState, nodeTag);
        }, newState);
      }

      return newState;
    },
  ),

  on(NodeTagsActions.addTagsToMultipleNodesSuccess, (state, { nodeIds, nodeTags, replace }) => {
    let newState = { ...state };

    // todo: what about groups?
    if (replace === true) {
      nodeIds.forEach(id => {
        newState = clearNodeTagsByNodeIdFromState(newState, id);
      });
    }

    if (nodeTags.length > 0) {
      newState = nodeTags.reduce((newState, nodeTag) => {
        return addNodeTagToState(newState, nodeTag);
      }, newState);
    }

    return newState;
  }),

  on(TagsActions.deleteTagRequest, (state, { tagId }) => {
    let nodeTagsByTagId = [];
    Object.entries(state.nodeTagsById).forEach(([key, nodeTag]) => {
      if (nodeTag.id === tagId) {
        nodeTagsByTagId.push(nodeTag);
      }
    });

    if (nodeTagsByTagId.length) {
      let newState = { ...state };
      nodeTagsByTagId.forEach(nodeTag => {
        newState = removeNodeTagFromState(newState, nodeTag);
      });
      return newState;
    }

    return state;
  }),
);

export function nodeTagsReducer(state: NodeTagsState, action: Action) {
  return reducer(state, action);
}
