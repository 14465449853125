import { AppState } from '../index';
import { createSelector } from '@ngrx/store';
import { selectWidgetsById } from '../widgets/widgets.selectors';
import { NodeTemplateWidgetModel } from '../../core/models/node-template-widget.model';

const selectTemplateWidgetsState = (state: AppState) => state.templateWidgets;

export const selectTemplateWidgetsByTemplateId = createSelector(
  selectTemplateWidgetsState,
  state => state.templateWidgetsByTemplateId,
);

export const selectTemplateWidgetsById = createSelector(
  selectTemplateWidgetsState,
  state => state.templateWidgetsById,
);

export const selectTemplateWidgetsByGroupId = createSelector(
  selectTemplateWidgetsState,
  state => state.templateWidgetsByGroupId,
);

export const selectEnrichedTemplateWidgetsByTemplateId = createSelector(
  selectTemplateWidgetsByTemplateId,
  selectWidgetsById,
  (templateWidgetsByTemplateId, widgetsById) => {
    return Object.keys(templateWidgetsByTemplateId).reduce(
      (enrichedWidgetsByTemplateId, templateId) => {
        return {
          ...enrichedWidgetsByTemplateId,
          [templateId]: (templateWidgetsByTemplateId[templateId] || []).map(templateWidget => {
            const widget = widgetsById[templateWidget.id];
            const widgetA = widget?.calculationAWidgetId
              ? widgetsById[widget.calculationAWidgetId]
              : null;
            const widgetB = widget?.calculationAWidgetId
              ? widgetsById[widget.calculationBWidgetId]
              : null;
            return {
              ...templateWidget,
              widget: widget,
              // Widget A&B are not TemplateWidgets on the server, so we'll normalise them here
              widgetA:
                widgetA != null
                  ? ({
                      ...{ ...templateWidget, id: widgetA.id },
                      widget: widgetA,
                      widgetA: null,
                      widgetB: null,
                      values: [],
                    } as NodeTemplateWidgetModel)
                  : null,
              widgetB:
                widgetB != null
                  ? ({
                      ...{ ...templateWidget, id: widgetB.id },
                      widget: widgetB,
                      widgetA: null,
                      widgetB: null,
                      values: [],
                    } as NodeTemplateWidgetModel)
                  : null,
              values: [],
            } as NodeTemplateWidgetModel;
          }),
        };
      },
      {},
    );
  },
);

export const selectTemplateWidgetData = createSelector(
  selectWidgetsById,
  selectTemplateWidgetsById,
  (widgetsById, templateWidgetsById) => {
    return {
      widgetsById,
      templateWidgetsById,
    };
  },
);
