<div
  class="modal-footer"
  [ngClass]="{
    'modal-footer-footer--template': template,
    'modal--sm': parent.size === ModalSize.Small,
    'modal--md': parent.size === ModalSize.Medium,
    'modal--lg': parent.size === ModalSize.Large,
    'modal--xl': parent.size === ModalSize.XLarge,
    'theme--info': parent.theme === ModalTheme.Info,
    'theme--success': parent.theme === ModalTheme.Success,
    'theme--warning': parent.theme === ModalTheme.Warning,
    'theme--danger': parent.theme === ModalTheme.Danger,
    'justify-content-space-between': flexAlignStyle === 'space-between'
  }"
>
  <ng-container *ngTemplateOutlet="template"></ng-container>
  <ng-container *ngIf="!template">
    <ng-content></ng-content>
  </ng-container>
</div>
