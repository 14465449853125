import { WorkspaceGroupModel } from '../models/workspace-group.model';

const workspaceGroupModel = (): WorkspaceGroupModel => {
  return {
    id: null,
    workspaceId: null,
    type: null,
    title: 'Empty',
    sortIndex: -1,
  };
};

export const EmptyModelFactory = {
  workspaceGroupModel,
};
