import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AddressModel } from '../../../core/models/address.model';
import { convertGooglePlace, getFormattedAddress } from '../../../core/utils/address.util';

@Component({
  selector: 'app-google-address-field',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: GoogleAddressFieldComponent,
      multi: true,
    },
  ],
  templateUrl: './google-address-field.component.html',
  styleUrls: ['./google-address-field.component.scss'],
})
export class GoogleAddressFieldComponent implements OnInit, AfterViewInit, ControlValueAccessor {
  @Input() addressType: string;

  @Input() set value(value: AddressModel) {
    this._value = {
      street: value?.street || '',
      suburb: value?.suburb || '',
      state: value?.state || '',
      postcode: value?.postcode || '',
      country: value?.country || '',
    };
    this.autocompleteInput = getFormattedAddress(this._value);
  }

  @Input() marginYAuto = false;

  get value() {
    return this._value;
  }

  private _value: AddressModel;

  @Output() changeValue: EventEmitter<any> = new EventEmitter();
  @ViewChild('addressInput') addressInput: any;

  disabled: boolean;
  autocompleteInput;

  onChanged: any = () => {};
  onTouched: any = () => {};

  constructor() {}

  ngOnInit(): void {}

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  writeValue(value): void {
    this.value = value;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngAfterViewInit() {
    this.getPlaceAutocomplete();
  }

  private getPlaceAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(this.addressInput.nativeElement, {
      componentRestrictions: { country: 'au' },
      types: [this.addressType], // 'establishment' / 'address' / 'geocode'
    });
    autocomplete.setFields(['address_component']);

    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();

      const address = convertGooglePlace(place);
      this.onTouched();
      this.onChanged(address);

      this.changeValue.emit(address);
    });
  }

  needToUpdateValue(valueA: AddressModel, valueB: AddressModel) {
    if (!valueB) {
      return true;
    }

    if (
      valueA.street === valueB.street &&
      valueA.suburb === valueB.suburb &&
      valueA.state === valueB.state &&
      valueA.country === valueB.country &&
      valueA.postcode === valueB.postcode
    ) {
      return false;
    }

    return true;
  }
}
