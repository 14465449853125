import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import {
  selectTagsPopoverTag,
  selectTagsPopoverTagType,
} from '../../../store/tags-popover/tags-popover.selectors';
import { ColorType, colorTypeKeys } from '../../../core/constants/color-type';
import { TagModel } from '../../../core/models/tag.model';
import { filter } from 'rxjs/operators';
import { IconType, iconTypeKeys } from '../../../core/constants/icon-type';
import { TagType } from '../../../core/constants/tag-type';
import { TagsActions } from '../../../store/tags';
import { ConfirmPopupComponent } from '../confirm-popup/confirm-popup.component';
import { TagsPopoverActions } from '../../../store/tags-popover';
import { ColorPickerComponent } from '../color-picker/color-picker.component';
import { IconPickerComponent } from '../icon-picker/icon-picker.component';

@Component({
  templateUrl: 'tag-edit.component.html',
  styleUrls: ['tag-edit.component.scss'],
})
export class TagEditComponent implements OnInit, OnDestroy {
  private readonly subscription = new Subscription();

  public readonly TagType = TagType;
  public readonly colorThemes: string[] = colorTypeKeys;
  public readonly icons: string[] = iconTypeKeys;

  public isSubmitted: boolean;
  public form: UntypedFormGroup;
  public type: TagType;
  public model: TagModel;
  public selectedColour: ColorType = ColorType['color-39b49b'];
  public selectedIcon: IconType;

  // convenience getter for easy access to form fields
  public get f() {
    return this.form.controls;
  }

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private fb: UntypedFormBuilder,
    private store: Store,
  ) {
    this.form = this.fb.group({
      title: ['', Validators.required],
      searchTerms: [''],
    });

    this.subscription.add(
      this.store.pipe(select(selectTagsPopoverTagType)).subscribe(type => {
        this.type = type;
      }),
    );

    this.subscription.add(
      this.store
        .pipe(
          select(selectTagsPopoverTag),
          filter(model => !!model),
        )
        .subscribe(model => {
          this.model = model;
          this.selectedIcon = model.icon;
          this.selectedColour = model.colorTheme ? model.colorTheme : ColorType['color-39b49b'];
          this.form.patchValue({
            title: model.title,
            searchTerms: model.searchTerms,
          });
        }),
    );
  }

  public ngOnInit() {}

  public onSubmit() {
    this.isSubmitted = true;

    if (this.form.valid) {
      const formData = this.form.getRawValue();
      if (this.model == null) {
        this.store.dispatch(
          TagsActions.addTagRequest({
            title: formData.title,
            colorTheme: this.selectedColour,
            icon: this.selectedIcon,
            tagType: this.type,
            searchTerms: formData.searchTerms,
          }),
        );
      } else {
        this.store.dispatch(
          TagsActions.updateTagRequest({
            tagId: this.model.id,
            tagProps: {
              title: formData.title,
              colorTheme: this.selectedColour,
              icon: this.selectedIcon,
              searchTerms: formData.searchTerms,
            },
          }),
        );
      }
      this.activeModal.dismiss();
    }
  }

  onDelete() {
    const modelRef = this.modalService.open(ConfirmPopupComponent, {
      size: 'md',
      backdrop: 'static',
    });
    modelRef.componentInstance.title = 'Delete Tag';
    modelRef.componentInstance.message = 'Do you want to Delete this Tag?';
    modelRef.result
      .then((result?: boolean) => {
        if (result) {
          this.store.dispatch(TagsActions.deleteTagRequest({ tagId: this.model.id }));
          this.activeModal.dismiss();
        }
      })
      .catch(res => {});
  }

  onIconPicker() {
    const modelRef = this.modalService.open(IconPickerComponent, {
      size: 'md',
    });
    modelRef.componentInstance.selected = this.selectedIcon
      ? IconType[this.selectedIcon]
      : IconType['not-available'];
    modelRef.result
      .then((result?: string) => {
        if (result) {
          this.selectedIcon = IconType[result];
        }
      })
      .catch(res => {});
  }

  onColourPicker() {
    const modelRef = this.modalService.open(ColorPickerComponent, {
      size: 'sm',
    });
    modelRef.componentInstance.selected = this.selectedColour
      ? ColorType[this.selectedColour]
      : ColorType['not-available'];
    modelRef.result
      .then((result?: string) => {
        if (result) {
          this.selectedColour = ColorType[result];
        }
      })
      .catch(res => {});
  }

  public ngOnDestroy(): void {
    this.store.dispatch(TagsPopoverActions.resetTagEditPage());
    this.subscription.unsubscribe();
  }
}
