import { AppState } from '../index';
import { createSelector } from '@ngrx/store';
import { NodeViewState } from './node-view.state';
import { TemplateWidgetsSelectors } from '../template-widgets';
import { NodeModel } from '../../core/models/node.model';
import { NodeUtils } from '../../core/utils/node.util';
import {
  selectNodeRelatedData,
  selectWorkspaceRelatedData,
} from '../properties-panel/properties-panel.selectors';

const selectNodeViewState = (state: AppState) => state.nodeView;

export const selectNodeViewNodeId = createSelector<AppState, NodeViewState, number>(
  selectNodeViewState,
  (state: NodeViewState) => state.nodeId,
);

export const selectNodeViewNode = createSelector(
  selectNodeViewNodeId,
  selectNodeRelatedData,
  selectWorkspaceRelatedData,
  TemplateWidgetsSelectors.selectTemplateWidgetsByTemplateId,
  (
    selectedNodeId,
    {
      nodeMetasById,
      nodeTagsByNodeId,
      nodeRatesByNodeId,
      nodeRateValuesByNodeId,
      nodeWidgetsByNodeId,
      nodeWidgetRowsByNodeId,
      nodeGroupsByNodeId,
      nodeTemplatesById,
    },
    {
      nodesById,
      nodesByParentId,
      tagsById,
      widgetsById,
      templatesById,
      groupsById,
      groupsByTemplateId,
      timesheetsByNodeId,
    },
    widgetsByTemplateId,
  ): NodeModel => {
    return NodeUtils.enrichNode(
      nodesById[selectedNodeId],
      //nodeMetasById,
      nodeTagsByNodeId,
      nodeRatesByNodeId,
      nodeRateValuesByNodeId,
      nodeWidgetsByNodeId,
      nodeWidgetRowsByNodeId,
      nodeGroupsByNodeId,
      nodeTemplatesById,
      nodesById,
      nodesByParentId,
      tagsById,
      widgetsById,
      groupsById,
      groupsByTemplateId,
      widgetsByTemplateId,
      timesheetsByNodeId,
    );
  },
);
