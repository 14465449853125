<ng-template #rowMenu let-row="row" let-index="index">
  <ul class="popover-menu">
    <li class="popover-menu__item" (click)="onTableInsert(row, index)">Insert</li>
    <li class="popover-menu__item" (click)="onTableCopy(row, index)">Copy</li>
    <li class="popover-menu__divider"></li>
    <li class="popover-menu__item" (click)="onTableDelete(row, index)">Delete</li>
  </ul>
</ng-template>

<app-z-table
  sortBy="rateType"
  [allowNewRow]="true"
  [rowMenu]="rowMenu"
  [headerColumns]="tableColumns"
  [bodyRows]="tableBody"
  (moveGroup)="onTableGroupMove($event)"
  (moveRow)="onTableRowMove($event)"
  (endEdit)="onTableEdit($event)"
  (newRow)="onTableNewRow($event)"
  [hideSearch]="true"
>
  <h3 class="assignment-z-table__title">
    <span
      class="fal mr-2"
      [ngClass]="['fa-' + (assignment?.nodeTemplate?.icon | iconString)]"
    ></span>
    {{ assignment?.nodeTemplate?.title }}
  </h3>
</app-z-table>
