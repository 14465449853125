<section class="assignment-section">
  <header class="assignment-section__header">
    <div class="assignment-section-title">
      {{title}}
    </div>
    <div class="assignment-section__toolbar">
      <ng-container *ngTemplateOutlet="headerToolbarTemplate"></ng-container>
    </div>
  </header>
  <ng-content></ng-content>
</section>
