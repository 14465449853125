import { Component, Input, TemplateRef } from '@angular/core';
import { ModalComponent, ModalSize, ModalTheme } from '../modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-footer',
  templateUrl: './modal-footer.component.html',
  styleUrls: ['./modal-footer.component.scss'],
})
export class ModalFooterComponent {
  public readonly ModalSize = ModalSize;
  public readonly ModalTheme = ModalTheme;

  @Input()
  public template: TemplateRef<any>;

  @Input()
  public flexAlignStyle: string;

  constructor(public parent: ModalComponent, public activeModal: NgbActiveModal) {}
}
