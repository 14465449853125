import { Action, createReducer, on } from '@ngrx/store';
import { ChatsState, initialChatsState } from './chats.state';
import { ChatsActions } from './index';
import { ChatChannelCommentModel } from '../../core/models/chat-channel-comment.model';
import { ChatChannelModel } from '../../core/models/chat-channel.model';
import { ChatbarActions } from '../chatbar';

const addChannelToChatsState = (state: ChatsState, channel: ChatChannelModel): ChatsState => {
  return {
    ...state,
    channelsById: {
      ...state.channelsById,
      [channel.id]: channel,
    },
    channelIds:
      state.channelIds.indexOf(channel.id) > -1
        ? state.channelIds
        : [...state.channelIds, channel.id],
  };
};

const removeChannelFromChatsState = (state: ChatsState, channelId: number): ChatsState => {
  return {
    ...state,
    channelsById: {
      ...state.channelsById,
      [channelId]: undefined,
    },
    channelIds: [
      ...(state.channelIds || []).filter(channelIds => {
        return channelIds !== channelId;
      }),
    ],
  };
};

const addChannelsToChatsState = (state: ChatsState, channels: ChatChannelModel[]): ChatsState => {
  return (channels || []).reduce((newState, channel) => {
    return addChannelToChatsState(newState, channel);
  }, state);
};

const addCommentToChatState = (state: ChatsState, comment: ChatChannelCommentModel): ChatsState => {
  return {
    ...state,
    channelCommentsById: {
      ...state.channelCommentsById,
      [comment.id]: comment,
    },
    commentIdsByChannelId: {
      ...state.commentIdsByChannelId,
      [comment.channelId]: [
        ...(state.commentIdsByChannelId[comment.channelId] || []).filter(commentId => {
          return commentId !== comment.id;
        }),
        comment.id,
      ],
    },
  };
};

const removeCommentFromChatState = (
  state: ChatsState,
  channelId: number,
  commentId: string,
): ChatsState => {
  return {
    ...state,
    channelCommentsById: {
      ...state.channelCommentsById,
      [commentId]: undefined,
    },
    commentIdsByChannelId: {
      ...state.commentIdsByChannelId,
      [channelId]: [
        ...(state.commentIdsByChannelId[channelId] || []).filter(commentId => {
          return commentId === commentId;
        }),
        commentId,
      ],
    },
  };
};

const addCommentsToChatState = (
  state: ChatsState,
  comments: ChatChannelCommentModel[],
): ChatsState => {
  return (comments || []).reduce((newState, comment) => {
    return addCommentToChatState(newState, comment);
  }, state);
};

const reducer = createReducer(
  initialChatsState,
  on(
    ChatbarActions.openCurrentWorkspaceChannelSuccess,
    ChatbarActions.openNodeChannelSuccess,
    (state, { channel }): ChatsState => {
      return {
        ...state,
        channelsById: {
          ...state.channelsById,
          [channel.id]: channel,
        },
      };
    },
  ),
  on(
    ChatbarActions.goToWorkspaceAllChannelsPageSuccess,
    (state, { channels }): ChatsState => {
      const newState: ChatsState = {
        ...state,
        channelIds: [], // reset channelIds to empty
      };
      return addChannelsToChatsState(newState, channels);
    },
  ),
  on(
    ChatsActions.loadChannelCommentsSuccess,
    (state, { comments }): ChatsState => {
      return addCommentsToChatState(state, comments);
    },
  ),

  on(
    ChatsActions.deleteNodeChannelSuccess,
    (state, { channelId }): ChatsState => {
      return removeChannelFromChatsState(state, channelId);
    },
  ),

  on(
    ChatsActions.loadUnreadCommentsSuccess,
    (state, { comments }): ChatsState => {
      const channelMap = {};
      (comments || []).forEach(comment => {
        if (state.channelsById[comment.channelId] != null) {
          channelMap[comment.channelId] = {
            ...state.channelsById[comment.channelId],
            unreadComments: channelMap[comment.channelId]
              ? channelMap[comment.channelId].unreadComments + 1
              : 1,
          };
        }
      });

      // Update Channels with new Unread values
      let newState = addChannelsToChatsState(state, Object.values(channelMap));

      // Add the Unread Comments to the store
      return addCommentsToChatState(newState, comments);
    },
  ),

  on(
    ChatsActions.markChannelAsReadRequest,
    (state, { channelId }): ChatsState => {
      if (!channelId || state.channelsById[channelId] == null) {
        return state;
      }
      const channel = {
        ...state.channelsById[channelId],
        unreadComments: 0,
      };
      return addChannelToChatsState(state, channel);
    },
  ),

  on(
    ChatsActions.addCommentToChannelSuccess,
    ChatsActions.addAttachmentToChannelSuccess,
    ChatsActions.updateCommentInChannelSuccess,
    ChatsActions.updateAttachmentToChannelSuccess,
    (state, { comment }): ChatsState => {
      return addCommentToChatState(state, comment);
    },
  ),

  on(
    ChatsActions.deleteCommentInChannelSuccess,
    (state, { channelId, commentId }): ChatsState => {
      return removeCommentFromChatState(state, channelId, commentId);
    },
  ),
);

export function chatsReducer(state: ChatsState, action: Action) {
  return reducer(state, action);
}
