import { Component, Input, OnInit } from '@angular/core';
import { ChatChannelCommentViewModel } from '../../../../../core/models/chat-channel-comment-view.model';

@Component({
  selector: 'app-chat-comment-date',
  templateUrl: './chat-comment-date.component.html',
  styleUrls: ['./chat-comment-date.component.scss'],
})
export class ChatCommentDateComponent implements OnInit {
  @Input() comment: ChatChannelCommentViewModel;

  constructor() {}

  ngOnInit(): void {}
}
