import { Action, createReducer, on } from '@ngrx/store';
import { initialWorkspaceUrlsState, WorkspaceUrlsState } from './workspace-urls.state';
import { WorkspaceUrlsActions } from './index';
import { WorkspaceUrlModel } from '../../core/models/workspace-url.model';

const addUrlToState = (state: WorkspaceUrlsState, url: WorkspaceUrlModel): WorkspaceUrlsState => {
  return {
    ...state,
    byId: {
      ...state.byId,
      [url.id]: url,
    },
    byGroupId: {
      ...state.byGroupId,
      [url.groupId]: [...(state.byGroupId[url.groupId] || []).filter(u => u.id !== url.id), url],
    },
  };
};

const removeUrlFromState = (
  state: WorkspaceUrlsState,
  url: WorkspaceUrlModel,
): WorkspaceUrlsState => {
  return {
    ...state,
    byId: {
      ...state.byId,
      [url.id]: undefined,
    },
    byGroupId: {
      ...state.byGroupId,
      [url.groupId]: [...(state.byGroupId[url.groupId] || []).filter(u => u.id !== url.id)],
    },
  };
};

const reducer = createReducer(
  initialWorkspaceUrlsState,
  on(
    WorkspaceUrlsActions.loadCurrentWorkspaceUrlsSuccess,
    (state, { urls }): WorkspaceUrlsState => {
      return urls.reduce((state, url) => {
        return addUrlToState(state, url);
      }, initialWorkspaceUrlsState);
    },
  ),
  on(WorkspaceUrlsActions.addWorkspaceUrlSuccess, (state, { url }) => {
    return addUrlToState(state, url);
  }),

  on(WorkspaceUrlsActions.updateWorkspaceUrlSuccess, (state, { url }) => {
    return addUrlToState(state, url);
  }),

  on(
    WorkspaceUrlsActions.deleteWorkspaceUrlRequest,
    (state: WorkspaceUrlsState, { urlId, groupId }) => {
      const url = state.byId[urlId];
      if (url == null) {
        return state;
      }
      return removeUrlFromState(state, url);
    },
  ),

  on(
    WorkspaceUrlsActions.sortWorkspaceUrlsRequest,
    (state: WorkspaceUrlsState, { groupId, urlIds }) => {
      let sortIndex = 0;
      return urlIds.reduce((state, id) => {
        return addUrlToState(state, {
          ...state.byId[id],
          sortIndex: sortIndex++,
        });
      }, state);
    },
  ),
);

export function workspaceUrlsReducer(state: WorkspaceUrlsState, action: Action) {
  return reducer(state, action);
}
