import {Component, Input, OnDestroy, TemplateRef} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

export enum ModalSize {
  Small,
  Medium,
  Large,
  XLarge,
}

export enum ModalTheme {
  Info,
  Success,
  Warning,
  Danger,
}

@Component({
  selector: 'app-modal',
  templateUrl: 'modal.component.html',
  styleUrls: ['modal.component.scss'],
})
export class ModalComponent implements OnDestroy {
  public readonly ModalSize = ModalSize;
  public readonly ModalTheme = ModalTheme;

  @Input()
  public size: ModalSize;

  @Input()
  public theme: ModalTheme;

  @Input()
  public headerTemplate: TemplateRef<any>;

  @Input()
  public footerTemplate: TemplateRef<any>;

  @Input()
  public flexAlignStyle: string;

  @Input()
  public stepTemplate: TemplateRef<any>;

  @Input()
  public loaderScope: string;

  @Input()
  public title: string;

  @Input()
  public iconUrl: string;

  constructor(public activeModal: NgbActiveModal) {
    this.size = ModalSize.Medium;
    this.loaderScope = 'default';
    this.title = '';
  }

  public ngOnDestroy() {
  }
}
