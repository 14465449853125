import { createAction, props } from '@ngrx/store';
import { StatusLine, StatusLineKey } from './status-bar.state';

export const closeStatusBar = createAction('[Status Bar] close');

export const closeEmptyStatusBar = createAction('[Status Bar] close empty status bar');

export const openStatusBar = createAction('[Status Bar] open');

export const addStatusLine = createAction(
  '[Status Bar] add status line',
  props<{ statusLine: StatusLine }>(),
);

export const removeStatusLine = createAction(
  '[Status Bar] remove status line',
  props<{ key: StatusLineKey }>(),
);
