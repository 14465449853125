<ng-template #modalFooter>
  <div class="modal-picker-validation" *ngIf="validationMessage">
    <span class="modal-picker-validation__error">{{ validationMessage }}</span>
  </div>

  <div class="row m-0 w-100">
    <div class="col-6 p-0">
      <!-- <button type="button" class="btn btn-sm btn-link float-left" (click)="clearSelection()">
        Clear
      </button> -->
      <button
        *ngIf="currentStep > 1"
        type="button"
        class="btn btn-sm btn-primary ml-2"
        aria-label="Close"
        (click)="goToBack()"
      >
        Back
      </button>

      <button
        *ngIf="currentStep < stepLength"
        type="button"
        class="btn btn-sm btn-primary ml-2"
        aria-label="Close"
        (click)="goToNext()"
      >
        Next
      </button>
    </div>

    <div class="col-6 p-0 d-flex justify-content-end">
      <button
        ngbAutofocus
        type="button"
        class="btn btn-sm btn-outline-light"
        aria-label="Close"
        (click)="activeModal.dismiss(false)"
      >
        Cancel
      </button>

      <button
        type="button"
        class="btn btn-sm btn-primary ml-2"
        aria-label="Close"
        (click)="onSubmit()"
      >
        <i class="fal fa-circle-check layout-mr-sm"></i>
        Confirm
      </button>
    </div>
  </div>
</ng-template>

<!-- Template for displaying current step information -->
<ng-template #stepInfo>
  <h6 step class="modal-title ml-3" *ngIf="currentStep && stepLength">
    List {{ currentStep }} of {{ stepLength }}
  </h6>
</ng-template>

<app-modal title="Assign Status" [footerTemplate]="modalFooter" [stepTemplate]="stepInfo">
  <app-modal-instructions
    [html]="instructions"
    [template]="instructionsTemplate"
  ></app-modal-instructions>

  <div class="row mx-0 item-picked__search-bar">
    <div class="col-12">
      <app-search-box
        [searchKeyword]="keywords"
        sizeType="sm"
        [inputHeight]="'45px'"
        (searchChanged)="search($event)"
      ></app-search-box>
    </div>
  </div>

  <ol class="item-picker__list item-picker__list--flex-column list-unstyled">
    <li class="item-picker__list-item item-picker__list-item--flex-column" *ngIf="allowNew">
      <div
        class="list-item__brick list-item__btn"
        app-selectable-brick
        [icon]="IconType.plus"
        label="Add a new tag"
        (click)="add()"
      ></div>
    </li>
    <li
      *ngFor="let item of list; trackBy: trackBy"
      class="item-picker__list-item item-picker__list-item--flex-column"
    >
      <div
        class="list-item__brick list-item__brick--flex-column"
        app-selectable-brick
        [color]="item.model.colorTheme"
        [icon]="item.model.icon"
        [label]="item.model.title"
        [rightColTemplate]="allowEdit ? btnMenu : checkBox"
        [isChecked]="item.selected"
        [flexColumn]="true"
        (click)="toggle(item)"
        [id]="item.model.id"
      ></div>

      <ng-template #btnMenu>
        <app-btn-menu
          (click)="$event.stopPropagation()"
          [menuContentTemplate]="menuContent"
        ></app-btn-menu>
        <ng-container *ngTemplateOutlet="checkBox"></ng-container>
      </ng-template>

      <ng-template #menuContent>
        <ul class="popover-menu">
          <li class="popover-menu__item" (click)="edit(item)">Edit</li>
          <ng-container *ngIf="item.model.searchTerms">
            <div class="popover-menu__divider"></div>
            <h2 class="popover-menu__info-title">Keywords</h2>
            <p class="popover-menu__info-text">{{ item.model.searchTerms }}</p>
          </ng-container>
        </ul>
      </ng-template>

      <ng-template #checkBox>
        <app-selectable-input
          [id]="item.model.id"
          [checked]="item.selected"
          [type]="maxSelection === 1 ? 'radio' : 'checkbox'"
        ></app-selectable-input>
      </ng-template>
    </li>
  </ol>
</app-modal>
