import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { RichSearchType } from '../../../../core/constants/rich-search-type';
import { EntityModel } from '../../../../core/models/entity.model';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-rich-search-filter',
  templateUrl: './rich-search-filter.component.html',
  styleUrls: ['./rich-search-filter.component.scss'],
})
export class RichSearchFilterComponent implements OnInit {
  @ViewChild('filterDropdown') filterDropdown: NgbDropdown;

  @Input() id: string;
  @Input() type: RichSearchType;
  @Input() entityOptions: EntityModel[];
  @Input() selectedEntityIds: (number | string)[];

  @Output() changeOptionIds = new EventEmitter<(number | string)[]>();

  constructor() {}

  ngOnInit(): void {}

  change(ids: (string | number)[]) {
    this.changeOptionIds.emit(ids);
  }

  public open() {
    this.filterDropdown.open();
  }
}
