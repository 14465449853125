import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NodesActions, NodesSelectors } from './index';
import {
  catchError,
  concatMap,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { NodeService } from '../../core/services/node.service';
import { EMPTY, of } from 'rxjs';
import { ErrorsActions } from '../errors';
import { NodeModel } from '../../core/models/node.model';
import { select, Store } from '@ngrx/store';
import { selectWorkspacesCurrentSlug } from '../workspaces/workspaces.selectors';
import { NodeTagsActions } from '../node-tags';
import { NodeListPanelActions } from '../node-list-panel';
import { PropertiesPanelActions } from '../properties-panel';
import { WorkspaceUserMetaActions, WorkspaceUserMetaSelectors } from '../workspace-user-meta';
import { NodeUtils } from '../../core/utils/node.util';
import { NodeRatesActions } from '../node-rates';
import { NodeWidgetsActions } from '../node-widgets';
import * as moment from 'moment';
import { selectNodesById } from './nodes.selectors';
import { AssignmentService } from '../../core/services/assignment.service';
import { selectAssignmentDateRange } from '../workspace-user-meta/workspace-user-meta.selectors';
import { NodeTagModel } from '../../core/models/node-tag.model';
import { NodeTagService } from '../../core/services/node-tag.service';
import { NodeTemplatesSelectors } from '../templates';
import { AuthSelectors } from '../auth';
import { NodeRateValuesActions } from '../node-rate-values';
import { NodeWidgetRowsActions } from '../node-widget-rows';
import { NodeGroupsActions } from '../node-groups';
import { NodeNotesService } from '../../core/services/node-notes.service';
import { selectAssignmentsByAssignmentId } from './node-enrichment.selectors';

@Injectable()
export class NodesEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private nodeService: NodeService,
    private nodeNotesService: NodeNotesService,
    private nodeTagService: NodeTagService,
    private assignmentService: AssignmentService,
  ) {}

  addFolderShortcutRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NodesActions.addFolderShortcutRequest),
      mergeMap(action =>
        this.nodeService.addFolderShortcut(action.sourceId, action.targetId).pipe(
          concatMap((node: NodeModel) => [NodesActions.addFolderShortcutSuccess({ node: node })]),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  addNodeRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NodesActions.addNodeRequest),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      mergeMap(([action, slug]) =>
        this.nodeService
          .addWorkspaceNode(
            slug,
            action.nodeType,
            action.title || 'Untitled',
            action.parentNode?.id,
            action.nodeTemplate?.id,
          )
          .pipe(
            concatMap((node: NodeModel) => [
              // ...[NodesActions.addMultipleNodesSuccess({ nodes: [node] })],
              // ...(action?.disableOpenPropertyPanel
              //   ? []
              //   : [
              //       PropertiesPanelActions.openPropertiesPanel({
              //         nodeId: node.id,
              //       }),
              //     ]),

              ...[
                NodesActions.addMultipleNodesSuccess({ nodes: [node] }),
                PropertiesPanelActions.openPropertiesPanel({
                  nodeId: node.id,
                }),
              ],

              // If it's a new folder, we'll do a couple extra actions
              ...(NodeUtils.isFolder(node)
                ? [
                    NodeListPanelActions.openNodeListPanel({
                      node: action.parentNode,
                    }),
                    WorkspaceUserMetaActions.expandFolderAfterAddingChildFolderRequest({
                      nodeId: action.parentNode.id,
                    }),
                  ]
                : []),
            ]),
            catchError(error => {
              return of(ErrorsActions.goToErrorPage({ error }));
            }),
          ),
      ),
    ),
  );

  updateNodeRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NodesActions.updateNodeRequest),
      mergeMap(action =>
        this.nodeService.updateWorkspaceNode(action.nodeId, action.nodeProps).pipe(
          switchMap(node => [
            NodesActions.addMultipleNodesSuccess({
              nodes: [node],
            }),
            NodesActions.updateNodeEditedByUserDate({
              nodeIds: [action.nodeId],
            }),
          ]),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  updateNodeMetaRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NodesActions.updateNodeMetaRequest),
      mergeMap(action => {
        return this.nodeService.updateNodeMeta(action.nodeId, action.metaData).pipe(
          map(response =>
            NodesActions.updateNodeMetaSuccess({
              nodeId: action.nodeId,
              nodeMeta: response?.nodeMeta,
            }),
          ),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        );
      }),
    ),
  );

  removeNodeRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NodesActions.removeNodeRequest),
      mergeMap(action =>
        this.nodeService.removeWorkspaceNode(action.node.id).pipe(
          concatMap(() => [
            NodeTagsActions.removeNodeTagsByNodeIdGroupId({
              nodeId: action.node.id,
              groupId: null,
            }),
          ]),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  removeNodeProfileRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NodesActions.removeNodeProfileRequest),
      mergeMap(action =>
        this.nodeService.removeWorkspaceNodeProfile(action.id).pipe(
          concatMap(node => [
            NodesActions.removeNodeProfileSuccess({
              node,
            }),
          ]),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  archiveNodesRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NodesActions.archiveNodesRequest),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      mergeMap(([action, slug]) =>
        this.nodeService.archiveWorkspaceNodes(action.nodeIds, slug).pipe(
          concatMap(() => [
            NodesActions.archiveNodesSuccess({
              nodeIds: action.nodeIds,
            }),
          ]),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  sortNodesRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NodesActions.sortNodesRequest, NodesActions.sortAssignmentsRequest),
      mergeMap(action =>
        this.nodeService.sortWorkspaceNodes(action.nodeIds).pipe(
          switchMap(() => {
            return EMPTY;
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  updateNodeEditedByUserDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NodesActions.updateNodeEditedByUserDate),
      concatMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(AuthSelectors.selectCurrentUser)),
            this.store.pipe(select(selectNodesById)),
          ),
        ),
      ),
      map(([action, user, nodesById]) =>
        NodesActions.updateNodeEditedByUserDateSuccess({
          nodes: action.nodeIds
            .filter(id => !!nodesById[id])
            .map(id => {
              return nodesById[id];
            }),
          editedBy: user,
          editedDate: moment().format(),
        }),
      ),
    ),
  );

  applyTemplateToNodesRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NodesActions.applyTemplateToNodesRequest),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      concatMap(([action, slug]) =>
        this.nodeService.applyTemplateToNodes(slug, action.nodeIds, action.nodeTemplateId).pipe(
          concatMap((nodes: NodeModel[]) => [NodesActions.addMultipleNodesSuccess({ nodes })]),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  moveNodesRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NodesActions.moveNodesRequest),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      mergeMap(([action, slug]) =>
        this.nodeService
          .moveWorkspaceNodes(slug, action.nodeIds, action.toParentId, action.sortIndex)
          .pipe(
            switchMap(() => EMPTY),
            catchError(error => {
              return of(ErrorsActions.goToErrorPage({ error }));
            }),
          ),
      ),
    ),
  );

  /**
   * New Assignments store design
   */
  loadAssignmentsByProjectsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NodesActions.loadAssignmentsByProjectsRequest),
      concatMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(selectWorkspacesCurrentSlug)),
            this.store.pipe(select(WorkspaceUserMetaSelectors.selectTableProjects)),
          ),
        ),
      ),
      tap(action => {
        // Always unload before loading more
        this.store.dispatch(NodesActions.unloadAssignmentsRequest());
      }),
      mergeMap(([action, slug, projects]) => {
        if (
          (action.projects == null || action.projects.length == 0) &&
          (projects == null || projects.length == 0)
        ) {
          return EMPTY;
        }
        return this.assignmentService
          .loadAssignmentsByProjects(slug, action.projects || projects, action.includeWidgets)
          .pipe(
            concatMap((tree: NodeModel[]) => {
              const {
                nodes,
                nodeTags,
                nodeRates,
                nodeRateValues,
                nodeWidgetValues,
                nodeWidgetRows,
                nodeGroupValues,
              } = NodeUtils.parseNodeTree(tree);
              return [
                NodesActions.loadAssignmentsSuccess({ nodes: nodes }),
                NodeTagsActions.loadNodeTagsFromNodeTree({
                  nodeTags: nodeTags,
                }),
                NodeRatesActions.loadNodeRatesFromNodeTree({
                  nodeRates: nodeRates,
                }),
                NodeRateValuesActions.loadNodeRateValuesFromNodeTree({
                  nodeRateValues: nodeRateValues,
                }),
                NodeWidgetsActions.loadNodeWidgetsFromNodeTree({
                  nodeWidgets: nodeWidgetValues,
                }),

                NodeWidgetRowsActions.loadNodeWidgetRowsFromNodeTree({
                  nodeWidgetRows: nodeWidgetRows,
                }),

                NodeGroupsActions.loadNodeGroupsFromNodeTree({
                  nodeGroups: nodeGroupValues,
                }),
              ];
            }),
            catchError(error => {
              return of(ErrorsActions.goToErrorPage({ error }));
            }),
          );
      }),
    ),
  );

  loadAssignmentsByDateRangeRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NodesActions.loadAssignmentsByDateRangeRequest),
      concatMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(selectWorkspacesCurrentSlug)),
            this.store.pipe(select(selectAssignmentDateRange)),
          ),
        ),
      ),
      tap(action => {
        // Always unload before loading more
        this.store.dispatch(NodesActions.unloadAssignmentsRequest());
      }),
      mergeMap(([action, slug, dateRange]) =>
        this.assignmentService
          .loadAssignmentsByDateRange(
            slug,
            action.startDate || dateRange.start,
            action.endDate || dateRange.end,
            action.includeWidgets,
            action.nullDate || false,
          )
          .pipe(
            concatMap((tree: NodeModel[]) => {
              const {
                nodes,
                nodeTags,
                nodeRates,
                nodeRateValues,
                nodeWidgetValues,
                nodeWidgetRows,
                nodeGroupValues,
              } = NodeUtils.parseNodeTree(tree);
              return [
                NodesActions.loadAssignmentsSuccess({ nodes: nodes }),
                NodeTagsActions.loadNodeTagsFromNodeTree({
                  nodeTags: nodeTags,
                }),
                NodeRatesActions.loadNodeRatesFromNodeTree({
                  nodeRates: nodeRates,
                }),
                NodeRateValuesActions.loadNodeRateValuesFromNodeTree({
                  nodeRateValues: nodeRateValues,
                }),
                NodeWidgetsActions.loadNodeWidgetsFromNodeTree({
                  nodeWidgets: nodeWidgetValues,
                }),
                NodeWidgetRowsActions.loadNodeWidgetRowsFromNodeTree({
                  nodeWidgetRows: nodeWidgetRows,
                }),
                NodeGroupsActions.loadNodeGroupsFromNodeTree({
                  nodeGroups: nodeGroupValues,
                }),
              ];
            }),
            catchError(error => {
              return of(ErrorsActions.goToErrorPage({ error }));
            }),
          ),
      ),
    ),
  );

  addMultipleAssignmentsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NodesActions.addMultipleAssignmentsRequest),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      mergeMap(([action, slug]) =>
        this.assignmentService.addAssignmentProjects(slug, action.nodeIds, action.dateKeys).pipe(
          map(assignments =>
            NodesActions.addMultipleAssignmentsSuccess({
              nodes: assignments,
            }),
          ),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  copyAssignmentRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NodesActions.copyAssignmentRequest),
      mergeMap(action =>
        this.assignmentService
          .copyAssignment(action.assignmentId, action.dateKeys, {
            primaryTags: action.primaryTags,
            assets: action.assets,
            elements: action.elements,
            rates: action.rates,
          })
          .pipe(
            concatMap((assignments: NodeModel[]) => {
              const {
                nodes,
                nodeTags,
                nodeRates,
                nodeRateValues,
                nodeWidgetValues,
                nodeWidgetRows,
                nodeGroupValues,
              } = NodeUtils.parseNodeTree(assignments);
              return [
                NodesActions.loadAssignmentsSuccess({ nodes: nodes }),
                NodeTagsActions.loadNodeTagsFromNodeTree({
                  nodeTags: nodeTags,
                }),
                NodeRatesActions.loadNodeRatesFromNodeTree({
                  nodeRates: nodeRates,
                }),
                NodeRateValuesActions.loadNodeRateValuesFromNodeTree({
                  nodeRateValues: nodeRateValues,
                }),
                NodeWidgetsActions.loadNodeWidgetsFromNodeTree({
                  nodeWidgets: nodeWidgetValues,
                }),
                NodeWidgetRowsActions.loadNodeWidgetRowsFromNodeTree({
                  nodeWidgetRows: nodeWidgetRows,
                }),
                NodeGroupsActions.loadNodeGroupsFromNodeTree({
                  nodeGroups: nodeGroupValues,
                }),
              ];
            }),
            catchError(error => {
              return of(ErrorsActions.goToErrorPage({ error }));
            }),
          ),
      ),
    ),
  );

  copyNodeRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NodesActions.copyNodeRequest),
      mergeMap(action =>
        this.nodeService.copyNode(action.nodeId).pipe(
          concatMap((node: NodeModel) => [
            ...[
              NodesActions.addMultipleNodesSuccess({ nodes: [node] }),
              NodeWidgetsActions.loadNodeWidgetsFromNodeTree({
                nodeWidgets: node?.__widgetRows[0]?.values,
              }),
              NodeWidgetRowsActions.loadNodeWidgetRowsFromNodeTree({
                nodeWidgetRows: node?.__widgetRows,
              }),
            ],
          ]),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  applyTemplateToAssignmentsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NodesActions.applyTemplateToAssignmentsRequest),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      concatMap(([action, slug]) =>
        this.nodeService.applyTemplateToNodes(slug, action.nodeIds, action.nodeTemplateId).pipe(
          concatMap((assignments: NodeModel[]) => [
            NodesActions.addMultipleAssignmentsSuccess({ nodes: assignments }),
            NodesActions.setSelectedLayoutAssignmentRequest({
              status: action?.setSelectedLayoutAssignment ?? false,
              assignment: assignments[0],
            }),
          ]),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  applyGroupTagToAssignmentRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NodesActions.applyGroupTagToAssignmentRequest),
      mergeMap(action =>
        this.nodeTagService.addNodeTag(action.assignmentId, action.tagId, action.groupId).pipe(
          concatMap((nodeTag: NodeTagModel) => [
            NodeTagsActions.removeNodeTagsByNodeIdGroupId({
              nodeId: action.assignmentId,
              groupId: action.groupId,
            }),
            NodeTagsActions.addNodeTagSuccess({
              nodeTag,
            }),
            NodesActions.updateAssignmentEditedBy({
              nodeIds: [action.assignmentId],
            }),
            NodesActions.applyGroupTagToAssignmentSuccess({
              assignmentId: action.assignmentId,
              nodeTag: nodeTag,
            }),
          ]),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  updateAssignmentEditedBy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NodesActions.updateAssignmentEditedBy),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(AuthSelectors.selectCurrentUser)))),
      ),
      map(([action, user]) =>
        NodesActions.updateAssignmentEditedBySuccess({
          nodeIds: action.nodeIds,
          editedBy: user,
          editedDate: moment().format(),
        }),
      ),
    ),
  );

  archiveAssignmentsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NodesActions.archiveAssignmentsRequest),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      mergeMap(([action, slug]) =>
        this.nodeService.archiveWorkspaceNodes(action.nodeIds, slug).pipe(
          concatMap(() => [
            NodesActions.archiveAssignmentsSuccess({
              nodeIds: action.nodeIds,
            }),
          ]),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  updateAssignmentReadonlyRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NodesActions.updateAssignmentReadonlyRequest),
      mergeMap(action =>
        this.assignmentService.patchAssignmentReadonly(action.nodeId, action.readonly).pipe(
          concatMap(node => [
            NodesActions.updateAssignmentReadonlySuccess({
              nodeId: action.nodeId,
              readonly: node.readOnly,
            }),
            NodesActions.updateAssignmentEditedBy({
              nodeIds: [action.nodeId],
            }),
          ]),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  addAssignmentElementsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NodesActions.addAssignmentElementsRequest),
      mergeMap(action =>
        this.assignmentService.addAssignmentElements(action.assignmentId, action.nodeIds).pipe(
          concatMap(nodes => [
            NodesActions.removeAssignmentElementsByBehaviourType({
              nodeIds: action.nodeIds,
              assignmentId: action.assignmentId,
            }),
            NodesActions.loadAssignmentsSuccess({
              nodes,
            }),
            NodesActions.addAssignmentElementsSuccess({
              nodeIds: action.nodeIds,
              assignmentId: action.assignmentId,
            }),
          ]),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  replaceAssignmentElementsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NodesActions.replaceAssignmentElementsRequest),
      mergeMap(action =>
        this.assignmentService
          .replaceAssignmentElements(
            action.assignmentId,
            action.nodeIds,
            action.removeByNodeTemplateIds,
          )
          .pipe(
            concatMap(node => [
              NodesActions.removeAssignmentElementsByNodeTemplate({
                assignmentId: action.assignmentId,
                nodeTemplateIds: action.removeByNodeTemplateIds,
              }),
              NodesActions.loadAssignmentsSuccess({
                nodes: [node],
              }),
              NodesActions.replaceAssignmentElementsSuccess({
                assignmentId: action.assignmentId,
                nodeIds: action.nodeIds,
              }),
              NodesActions.setSelectedLayoutAssignmentRequest({
                status: action?.setSelectedLayoutAssignment ?? false,
                assignment: node,
              }),
            ]),
            catchError(error => {
              return of(ErrorsActions.goToErrorPage({ error }));
            }),
          ),
      ),
    ),
  );

  setSelectedLayoutAssignmentRequest$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NodesActions.setSelectedLayoutAssignmentRequest),
        concatMap(action =>
          of(action).pipe(
            withLatestFrom(
              this.store.pipe(select(selectAssignmentsByAssignmentId(action.assignment?.id))),
            ),
          ),
        ),
        map(([action, assignmentsById]) => {
          const assignment = assignmentsById;
          return {
            status: action.status,
            assignment,
          };
        }),
        tap(action => {
          if (action?.status) {
            this.nodeService.setSelectedLayoutAssignment(action?.assignment);
          }
        }),
      ),
    { dispatch: false },
  );

  removeAssignmentElementsByNodeTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NodesActions.removeAssignmentElementsByNodeTemplate),
      concatMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(NodesSelectors.selectAssignmentsByParentId)),
            this.store.pipe(select(NodesSelectors.selectNodesById)),
          ),
        ),
      ),
      map(([action, assignmentsByParentId, nodesById]) => {
        const nodesToRemove = (assignmentsByParentId[action.assignmentId] || []).reduce(
          (list, node) => {
            if (
              action.nodeTemplateIds.some(
                id => id === nodesById[node.referenceNodeId]?.nodeTemplateId,
              )
            ) {
              return [...list, node];
            }
            return list;
          },
          [],
        );
        return {
          assignmentId: action.assignmentId,
          nodesToRemove,
        };
      }),
      mergeMap(action => {
        return of(
          NodesActions.removeMultipleAssignments({
            nodes: action.nodesToRemove,
          }),
        );
      }),
    ),
  );

  removeAssignmentElementsByBehaviourType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NodesActions.removeAssignmentElementsByBehaviourType),
      concatMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(NodesSelectors.selectAssignmentsById)),
            this.store.pipe(select(NodesSelectors.selectAssignmentsByParentId)),
            this.store.pipe(select(NodesSelectors.selectNodesById)),
            this.store.pipe(select(NodeTemplatesSelectors.selectNodeTemplatesById)),
          ),
        ),
      ),
      map(([action, assignmentsById, assignmentsByParentId, nodesById, templatesById]) => {
        const assignment = assignmentsById[action.assignmentId];

        const nodeIds = NodeUtils.filterNodesByTemplateBehaviour(
          action.nodeIds,
          nodesById,
          templatesById,
        );

        const nodeTemplates = nodeIds.map(id => {
          const node = nodesById[id];
          return node ? templatesById[node.nodeTemplateId] : null;
        });

        const nodesToRemove = NodeUtils.findNodesToRemoveByTemplateBehaviour(
          assignment,
          nodeTemplates,
          nodesById,
          assignmentsByParentId,
        );

        return {
          assignmentId: action.assignmentId,
          nodeIds,
          nodesToRemove,
        };
      }),
      mergeMap(action => {
        return of(
          NodesActions.removeMultipleAssignments({
            nodes: action.nodesToRemove,
          }),
        );
      }),
    ),
  );

  moveAssignmentsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NodesActions.moveAssignmentsRequest),
      mergeMap(action =>
        this.assignmentService
          .moveAssignments(action.assignmentIds, action.toDateKey, action.sortIndex)
          .pipe(
            switchMap(() => EMPTY),
            catchError(error => {
              return of(ErrorsActions.goToErrorPage({ error }));
            }),
          ),
      ),
    ),
  );

  addNodeNoteRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NodesActions.addNodeNoteRequest),
      mergeMap(action =>
        this.nodeNotesService.addNodeNote(action.nodeId, action.note, action.noteType).pipe(
          map(note => {
            return NodesActions.addNodeNoteSuccess({
              nodeId: action.nodeId,
              note,
            });
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );
}
