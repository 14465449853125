import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AppState } from '../../../store';
import { select, Store } from '@ngrx/store';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmPopupArchiveNodeComponent } from '../../modals/confirm-popup-archive-node/confirm-popup-archive-node.component';
import { ArchivePopoverActions } from '../../../store/archive-popover';
import { WidgetType } from '../../../core/constants/widget-type';
import { Subscription } from 'rxjs';
import { PropertiesPanelActions } from '../../../store/properties-panel';
import { NodeTemplateWidgetModel } from '../../../core/models/node-template-widget.model';
import { NodeRatesComponent } from '../../modals/node-rates/node-rates.component';
import { NodeRateValuesComponent } from '../../modals/node-rate-values/node-rate-values.component';
import { NodeModel } from '../../../core/models/node.model';
import { NodesActions } from '../../../store/nodes';
import { DatePickerComponent } from '../../modals/date-picker/date-picker.component';
import { ngbDateUtil } from '../../../core/utils/ngb-date.util';
import { NodeUtils } from '../../../core/utils/node.util';
import { ConfirmPopupComponent } from '../../modals/confirm-popup/confirm-popup.component';
import { ChatbarActions } from '../../../store/chatbar';
import { AppLayoutSelectors } from '../../../store/app-layout';
import { AssignmentDisplayMode } from '../../../core/constants/assignment-display-mode';
import { AssignmentLayoutMode } from '../../../core/constants/assignment-layout-mode';
import { Router } from '@angular/router';
import { selectWorkspacesCurrentSlug } from '../../../store/workspaces/workspaces.selectors';
import { WorkspaceUserMetaActions } from '../../../store/workspace-user-meta';
import { TagType } from '../../../core/constants/tag-type';
import { TagsPickerMultipleComponent } from '../../modals/tags-picker-multiple/tags-picker-multiple.component';
import { WorkspaceGroupModel } from '../../../core/models/workspace-group.model';
// import { WorkspaceSharesActions } from '../../../store/workspace-shares';
import { ShareLinksComponent } from '../../modals/share-links/share-links.component';
import { NodeTemplateModel } from '../../../core/models/node-template.model';

@Component({
  selector: 'app-assignment-menu',
  templateUrl: './assignment-menu.component.html',
  styleUrls: ['./assignment-menu.component.scss'],
})
export class AssignmentMenuComponent implements OnInit, OnChanges, OnDestroy {
  private readonly subscription = new Subscription();
  private assignmentLayoutMode: AssignmentLayoutMode;
  private slug: string;

  public readonly awaitingAssignmentDateKey = NodeUtils.awaitingAssignmentsDateKey;
  public readonly WidgetType = WidgetType;
  public readonly AssignmentDisplayMode = AssignmentDisplayMode;

  public assignmentDisplayMode: AssignmentDisplayMode;

  @Input()
  assignment: NodeModel;

  @Input()
  linksWidgets: NodeTemplateWidgetModel[];

  @Input()
  hideLinkViewer: boolean;

  @Input()
  onlyShowLinks: boolean;

  public showLinksOptions: boolean;

  constructor(
    private store: Store<AppState>,
    private modalService: NgbModal,
    private router: Router,
  ) {
    this.subscription.add(
      this.store
        .pipe(select(AppLayoutSelectors.selectWorkspaceContent))
        .subscribe(workspaceContent => {
          this.assignmentLayoutMode = workspaceContent.mode;
          this.assignmentDisplayMode = workspaceContent.subMode;
        }),
    );

    this.subscription.add(
      this.store.pipe(select(selectWorkspacesCurrentSlug)).subscribe(slug => {
        this.slug = slug;
      }),
    );
  }

  ngOnInit() {
    //
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.linksWidgets && this.linksWidgets.length) {
      this.linksWidgets.forEach(lw => {
        if (lw.widget.widgetType === WidgetType.urlList && lw.value && lw.value.length) {
          this.showLinksOptions = true;
        }
      });
    }
  }

  openLink(url: string) {
    window.open(url, '_blank');
  }

  trackBy(widget) {
    return widget.id;
  }

  copy() {
    this.store.dispatch(
      NodesActions.copyAssignmentRequest({
        assignmentId: this.assignment.id,
        dateKeys: [this.assignment.date],
        primaryTags: true,
        assets: true,
        elements: true,
        rates: true,
      }),
    );
  }

  copyMultiple = () => {
    const modelRef = this.modalService.open(DatePickerComponent, {
      size: 'md',
      backdrop: 'static',
    });

    modelRef.componentInstance.minSelection = 1;
    modelRef.componentInstance.maxSelection = 50;
    modelRef.componentInstance.selected = this.assignment.date
      ? [ngbDateUtil.convertToNgbDate(this.assignment.date)]
      : [];
    modelRef.result
      .then((result: NgbDateStruct[]) => {
        this.store.dispatch(
          NodesActions.copyAssignmentRequest({
            assignmentId: this.assignment.id,
            dateKeys: result.map(dt => ngbDateUtil.convertToDateKey(dt)),
            primaryTags: true,
            assets: true,
            elements: true,
            rates: true,
          }),
        );
      })
      .catch(() => {});
  };

  showRates() {
    const modelRef = this.modalService.open(NodeRatesComponent, {
      size: 'xxl',
      backdrop: 'static',
    });
    modelRef.componentInstance.node = this.assignment.reference;
  }

  showRateValues(type: 'Cost' | 'Revenue') {
    const modelRef = this.modalService.open(NodeRateValuesComponent, {
      size: 'xxl',
      backdrop: 'static',
    });

    modelRef.componentInstance.project = this.assignment.reference;
    modelRef.componentInstance.assignment = this.assignment;
    modelRef.componentInstance.type = type;
  }

  editProject() {
    this.store.dispatch(
      PropertiesPanelActions.openPropertiesPanel({
        nodeId: this.assignment.reference.id,
      }),
    );
  }

  changeDate() {
    const modelRef = this.modalService.open(DatePickerComponent, {
      size: 'md',
      backdrop: 'static',
    });

    modelRef.componentInstance.minSelection = 1;
    modelRef.componentInstance.maxSelection = 1;
    modelRef.componentInstance.selected = this.assignment.date
      ? [ngbDateUtil.convertToNgbDate(this.assignment.date)]
      : [];
    modelRef.result
      .then((result: NgbDateStruct[]) => {
        this.store.dispatch(
          NodesActions.moveAssignmentsRequest({
            assignmentIds: [this.assignment.id],
            toDateKey: ngbDateUtil.convertToDateKey(result[0]),
          }),
        );
      })
      .catch(() => {});
  }

  public sendToAwaiting() {
    const modelRef = this.modalService.open(ConfirmPopupComponent, {
      size: 'sm',
      backdrop: 'static',
    });
    modelRef.componentInstance.title = `Send Assignments to Plan Board`;
    modelRef.componentInstance.message = `Do you want to send the selected Assignments to Plan Board?`;
    modelRef.result
      .then((result: boolean) => {
        if (result) {
          this.store.dispatch(
            NodesActions.moveAssignmentsRequest({
              assignmentIds: [this.assignment.id],
              toDateKey: NodeUtils.awaitingAssignmentsDateKey,
            }),
          );
        }
      })
      .catch(() => {});
  }

  archive() {
    const modelRef = this.modalService.open(ConfirmPopupArchiveNodeComponent, {
      size: 'md',
      backdrop: 'static',
    });
    modelRef.componentInstance.title = 'Archive Assignment';
    modelRef.componentInstance.message = 'Do you want to archive this Assignment?';

    this.store.dispatch(ArchivePopoverActions.noReferenceCountRequired());

    modelRef.result.then((result: boolean) => {
      if (result) {
        this.store.dispatch(
          NodesActions.archiveAssignmentsRequest({
            nodeIds: [this.assignment.id],
          }),
        );
      }
    });
  }

  openChat(): void {
    this.store.dispatch(
      ChatbarActions.clickNodeChatIcon({ nodeId: this.assignment.referenceNodeId }),
    );
  }

  createShareLink(): void {
    // this.store.dispatch(WorkspaceSharesActions.shareCurrentViewRequest());
    const modelRef = this.modalService.open(ShareLinksComponent, {
      size: 'xl',
      backdrop: 'static',
      windowClass: 'ws-links-modal',
    });

    modelRef.result
      .then((result: NodeTemplateModel[]) => {
        if (result.length === 0) {
          return;
        }
        // api call
      })
      .catch(res => {});
  }

  viewSummary(): void {
    if (this.assignmentDisplayMode === AssignmentDisplayMode.ProjectPricing) {
      this.selectLayoutOverlayOption(
        AssignmentDisplayMode.ProjectPricingSummary,
        this.assignmentLayoutMode,
      );
      // apply the project filter
      this.store.dispatch(
        WorkspaceUserMetaActions.setTableProjectIds({ ids: [this.assignment?.reference?.id] }),
      );
    } else if (this.assignmentDisplayMode === AssignmentDisplayMode.Timesheets) {
      this.selectLayoutOverlayOption(
        AssignmentDisplayMode.AssetPricingSummary,
        this.assignmentLayoutMode,
      );
      // apply the project filter
      this.store.dispatch(
        WorkspaceUserMetaActions.setTableProjectIds({
          ids: [this.assignment?.reference?.id],
        }),
      );
    }
  }

  selectStampTag(): void {
    const assignment = this.assignment as NodeModel;
    const group: WorkspaceGroupModel = this.assignment?.stampTagGroups?.length
      ? this.assignment?.stampTagGroups[0]
      : null;
    const modelRef = this.modalService.open(TagsPickerMultipleComponent, {
      size: 'sm',
      backdrop: 'static',
    });
    modelRef.componentInstance.selected = group?.tags?.length ? [group?.tags[0]] : [];
    modelRef.componentInstance.whitelist = (assignment?.nodeTemplate?.tags || []).filter(
      t => t.groupId === group?.id,
    );
    modelRef.componentInstance.assignment = assignment;
    modelRef.componentInstance.minSelection = 1;
    modelRef.componentInstance.maxSelection = 1;
    modelRef.componentInstance.allowEdit = false;
    modelRef.componentInstance.allowNew = false;
    modelRef.result
      .then((result: { groupId: number; tagId: number }) => {
        if (result?.groupId == null || result?.tagId == null) {
          return;
        }
        this.store.dispatch(
          NodesActions.applyGroupTagToAssignmentRequest({
            assignmentId: assignment?.id,
            tagId: result?.tagId,
            tagType: TagType.stamp,
            groupId: result?.groupId,
          }),
        );
      })
      .catch(() => {});
  }

  selectLayoutOverlayOption(overlayOption: AssignmentDisplayMode, layout: AssignmentLayoutMode) {
    this.router.navigate(['ws', this.slug, 'content', layout, overlayOption]);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
