export enum IconType {
  'not-available' = 0,
  'abacus',
  'acorn',
  'ad',
  'address-book',
  'address-card',
  'adjust',
  'air-conditioner',
  'air-freshener',
  'alarm-clock',
  'alarm-exclamation',
  'alarm-plus',
  'alarm-snooze',
  'album-collection',
  'album',
  'alicorn',
  'alien-monster',
  'alien',
  'align-center',
  'align-justify',
  'align-left',
  'align-right',
  'align-slash',
  'allergies',
  'ambulance',
  'american-sign-language-interpreting',
  'amp-guitar',
  'analytics',
  'anchor',
  'angel',
  'angle-double-down',
  'angle-double-left',
  'angle-double-right',
  'angle-double-up',
  'angle-down',
  'angle-left',
  'angle-right',
  'angle-up',
  'angry',
  'ankh',
  'apple-alt',
  'apple-crate',
  'archive',
  'archway',
  'arrow-alt-circle-down',
  'arrow-alt-circle-left',
  'arrow-alt-circle-right',
  'arrow-alt-circle-up',
  'arrow-alt-down',
  'arrow-alt-from-bottom',
  'arrow-alt-from-left',
  'arrow-alt-from-right',
  'arrow-alt-from-top',
  'arrow-alt-left',
  'arrow-alt-right',
  'arrow-alt-square-down',
  'arrow-alt-square-left',
  'arrow-alt-square-right',
  'arrow-alt-square-up',
  'arrow-alt-to-bottom',
  'arrow-alt-to-left',
  'arrow-alt-to-right',
  'arrow-alt-to-top',
  'arrow-alt-up',
  'arrow-circle-down',
  'arrow-circle-left',
  'arrow-circle-right',
  'arrow-circle-up',
  'arrow-down',
  'arrow-from-bottom',
  'arrow-from-left',
  'arrow-from-right',
  'arrow-from-top',
  'arrow-left',
  'arrow-right',
  'arrow-square-down',
  'arrow-square-left',
  'arrow-square-right',
  'arrow-square-up',
  'arrow-to-bottom',
  'arrow-to-left',
  'arrow-to-right',
  'arrow-to-top',
  'arrow-up',
  'arrows-alt-h',
  'arrows-alt-v',
  'arrows-alt',
  'arrows-h',
  'arrows-v',
  'arrows',
  'assistive-listening-systems',
  'asterisk',
  'at',
  'atlas',
  'atom-alt',
  'atom',
  'audio-description',
  'award',
  'axe-battle',
  'axe',
  'baby-carriage',
  'baby',
  'backpack',
  'backspace',
  'backward',
  'bacon',
  'bacteria',
  'bacterium',
  'badge-check',
  'badge-dollar',
  'badge-percent',
  'badge-sheriff',
  'badge',
  'badger-honey',
  'bags-shopping',
  'bahai',
  'balance-scale-left',
  'balance-scale-right',
  'balance-scale',
  'ball-pile',
  'ballot-check',
  'ballot',
  'ban',
  'band-aid',
  'banjo',
  'barcode-alt',
  'barcode-read',
  'barcode-scan',
  'barcode',
  'bars',
  'baseball-ball',
  'baseball',
  'basketball-ball',
  'basketball-hoop',
  'bat',
  'bath',
  'battery-bolt',
  'battery-empty',
  'battery-full',
  'battery-half',
  'battery-quarter',
  'battery-slash',
  'battery-three-quarters',
  'bed-alt',
  'bed-bunk',
  'bed-empty',
  'bed',
  'beer',
  'bell-exclamation',
  'bell-on',
  'bell-plus',
  'bell-school-slash',
  'bell-school',
  'bell-slash',
  'bell',
  'bells',
  'betamax',
  'bezier-curve',
  'bible',
  'bicycle',
  'biking-mountain',
  'biking',
  'binoculars',
  'biohazard',
  'birthday-cake',
  'blanket',
  'blender-phone',
  'blender',
  'blind',
  'blinds-open',
  'blinds-raised',
  'blinds',
  'blog',
  'bold',
  'bolt',
  'bomb',
  'bone-break',
  'bone',
  'bong',
  'book-alt',
  'book-dead',
  'book-heart',
  'book-medical',
  'book-open',
  'book-reader',
  'book-spells',
  'book-user',
  'book',
  'bookmark',
  'books-medical',
  'books',
  'boombox',
  'boot',
  'booth-curtain',
  'border-all',
  'border-bottom',
  'border-center-h',
  'border-center-v',
  'border-inner',
  'border-left',
  'border-none',
  'border-outer',
  'border-right',
  'border-style-alt',
  'border-style',
  'border-top',
  'bow-arrow',
  'bowling-ball',
  'bowling-pins',
  'box-alt',
  'box-ballot',
  'box-check',
  'box-fragile',
  'box-full',
  'box-heart',
  'box-open',
  'box-tissue',
  'box-up',
  'box-usd',
  'box',
  'boxes-alt',
  'boxes',
  'boxing-glove',
  'brackets-curly',
  'brackets',
  'braille',
  'brain',
  'bread-loaf',
  'bread-slice',
  'briefcase-medical',
  'briefcase',
  'bring-forward',
  'bring-front',
  'broadcast-tower',
  'broom',
  'browser',
  'brush',
  'bug',
  'building',
  'bullhorn',
  'bullseye-arrow',
  'bullseye-pointer',
  'bullseye',
  'burger-soda',
  'burn',
  'burrito',
  'bus-alt',
  'bus-school',
  'bus',
  'business-time',
  'cabinet-filing',
  'cactus',
  'calculator-alt',
  'calculator',
  'calendar-alt',
  'calendar-check',
  'calendar-day',
  'calendar-days',
  'calendar-edit',
  'calendar-exclamation',
  'calendar-minus',
  'calendar-plus',
  'calendar-star',
  'calendar-times',
  'calendar-week',
  'calendar-circle-user',
  'calendar',
  'camcorder',
  'camera-alt',
  'camera-home',
  'camera-movie',
  'camera-polaroid',
  'camera-retro',
  'camera',
  'campfire',
  'campground',
  'candle-holder',
  'candy-cane',
  'candy-corn',
  'cannabis',
  'capsules',
  'car-alt',
  'car-battery',
  'car-building',
  'car-bump',
  'car-bus',
  'car-crash',
  'car-garage',
  'car-mechanic',
  'car-side',
  'car-tilt',
  'car-wash',
  'car',
  'caravan-alt',
  'caravan',
  'caret-circle-down',
  'caret-circle-left',
  'caret-circle-right',
  'caret-circle-up',
  'caret-down',
  'caret-left',
  'caret-right',
  'caret-square-down',
  'caret-square-left',
  'caret-square-right',
  'caret-square-up',
  'caret-up',
  'carrot',
  'cars',
  'cart-arrow-down',
  'cart-plus',
  'cash-register',
  'cassette-tape',
  'cat-space',
  'cat',
  'cauldron',
  'cctv',
  'certificate',
  'chair-office',
  'chair',
  'chalkboard-teacher',
  'chalkboard',
  'charging-station',
  'chart-area',
  'chart-bar',
  'chart-line-down',
  'chart-line-up',
  'chart-line',
  'chart-network',
  'chart-pie-alt',
  'chart-pie',
  'chart-scatter',
  'check-circle',
  'check-double',
  'check-square',
  'check',
  'cheese-swiss',
  'cheese',
  'cheeseburger',
  'chess-bishop-alt',
  'chess-bishop',
  'chess-board',
  'chess-clock-alt',
  'chess-clock',
  'chess-king-alt',
  'chess-king',
  'chess-knight-alt',
  'chess-knight',
  'chess-pawn-alt',
  'chess-pawn',
  'chess-queen-alt',
  'chess-queen',
  'chess-rook-alt',
  'chess-rook',
  'chess',
  'chevron-circle-down',
  'chevron-circle-left',
  'chevron-circle-right',
  'chevron-circle-up',
  'chevron-double-down',
  'chevron-double-left',
  'chevron-double-right',
  'chevron-double-up',
  'chevron-down',
  'chevron-left',
  'chevron-right',
  'chevron-square-down',
  'chevron-square-left',
  'chevron-square-right',
  'chevron-square-up',
  'chevron-up',
  'child',
  'chimney',
  'church',
  'circle-notch',
  'circle',
  'city',
  'clarinet',
  'claw-marks',
  'clinic-medical',
  'clipboard-check',
  'clipboard-list-check',
  'clipboard-list',
  'clipboard-prescription',
  'clipboard-user',
  'clipboard',
  'clock',
  'clone',
  'closed-captioning',
  'cloud-download-alt',
  'cloud-download',
  'cloud-drizzle',
  'cloud-hail-mixed',
  'cloud-hail',
  'cloud-meatball',
  'cloud-moon-rain',
  'cloud-moon',
  'cloud-music',
  'cloud-rain',
  'cloud-rainbow',
  'cloud-showers-heavy',
  'cloud-showers',
  'cloud-sleet',
  'cloud-snow',
  'cloud-sun-rain',
  'cloud-sun',
  'cloud-upload-alt',
  'cloud-upload',
  'cloud',
  'clouds-moon',
  'clouds-sun',
  'clouds',
  'club',
  'cocktail',
  'code-branch',
  'code-commit',
  'code-merge',
  'code',
  'coffee-pot',
  'coffee-togo',
  'coffee',
  'coffin-cross',
  'coffin',
  'cog',
  'cogs',
  'coin',
  'coins',
  'columns',
  'comet',
  'comment-alt-check',
  'comment-alt-dollar',
  'comment-alt-dots',
  'comment-alt-edit',
  'comment-alt-exclamation',
  'comment-alt-lines',
  'comment-alt-medical',
  'comment-alt-minus',
  'comment-alt-music',
  'comment-alt-plus',
  'comment-alt-slash',
  'comment-alt-smile',
  'comment-alt-times',
  'comment-alt',
  'comment-check',
  'comment-dollar',
  'comment-dots',
  'comment-edit',
  'comment-exclamation',
  'comment-lines',
  'comment-medical',
  'comment-minus',
  'comment-music',
  'comment-plus',
  'comment-slash',
  'comment-smile',
  'comment-times',
  'comment',
  'comments-alt-dollar',
  'comments-alt',
  'comments-dollar',
  'comments',
  'compact-disc',
  'compass-slash',
  'compass',
  'compress-alt',
  'compress-arrows-alt',
  'compress-wide',
  'compress',
  'computer-classic',
  'computer-speaker',
  'concierge-bell',
  'construction',
  'container-storage',
  'conveyor-belt-alt',
  'conveyor-belt',
  'cookie-bite',
  'cookie',
  'copy',
  'copyright',
  'corn',
  'couch',
  'cow',
  'cowbell-more',
  'cowbell',
  'credit-card-blank',
  'credit-card-front',
  'credit-card',
  'cricket',
  'croissant',
  'crop-alt',
  'crop',
  'cross',
  'crosshairs',
  'crow',
  'crown',
  'crutch',
  'crutches',
  'cube',
  'cubes',
  'curling',
  'cut',
  'dagger',
  'database',
  'deaf',
  'debug',
  'deer-rudolph',
  'deer',
  'democrat',
  'desktop-alt',
  'desktop',
  'dewpoint',
  'dharmachakra',
  'diagnoses',
  'diamond',
  'dice-d4',
  'dice-d6',
  'dice-d8',
  'dice-d10',
  'dice-d12',
  'dice-d20',
  'dice-five',
  'dice-four',
  'dice-one',
  'dice-six',
  'dice-three',
  'dice-two',
  'dice',
  'digging',
  'digital-tachograph',
  'diploma',
  'directions',
  'disc-drive',
  'disease',
  'divide',
  'dizzy',
  'dna',
  'do-not-enter',
  'dog-leashed',
  'dog',
  'dollar-sign',
  'dolly-empty',
  'dolly-flatbed-alt',
  'dolly-flatbed-empty',
  'dolly-flatbed',
  'dolly',
  'donate',
  'door-closed',
  'door-open',
  'dot-circle',
  'dove',
  'download',
  'drafting-compass',
  'dragon',
  'draw-circle',
  'draw-polygon',
  'draw-square',
  'dreidel',
  'drone-alt',
  'drone',
  'drum-steelpan',
  'drum',
  'drumstick-bite',
  'drumstick',
  'dryer-alt',
  'dryer',
  'duck',
  'dumbbell',
  'dumpster-fire',
  'dumpster',
  'dungeon',
  'ear-muffs',
  'ear',
  'eclipse-alt',
  'eclipse',
  'edit',
  'egg-fried',
  'egg',
  'eject',
  'elephant',
  'ellipsis-h-alt',
  'ellipsis-h',
  'ellipsis-v-alt',
  'ellipsis-v',
  'empty-set',
  'engine-warning',
  'envelope-open-dollar',
  'envelope-open-text',
  'envelope-open',
  'envelope-square',
  'envelope',
  'equals',
  'eraser',
  'ethernet',
  'euro-sign',
  'exchange-alt',
  'exchange',
  'exclamation-circle',
  'exclamation-square',
  'exclamation-triangle',
  'exclamation',
  'expand-alt',
  'expand-arrows-alt',
  'expand-arrows',
  'expand-wide',
  'expand',
  'external-link-alt',
  'external-link-square-alt',
  'external-link-square',
  'external-link',
  'eye-dropper',
  'eye-evil',
  'eye-slash',
  'eye',
  'fan-table',
  'fan',
  'farm',
  'fast-backward',
  'fast-forward',
  'faucet-drip',
  'faucet',
  'fax',
  'feather-alt',
  'feather',
  'female',
  'field-hockey',
  'fighter-jet',
  'file-alt',
  'file-archive',
  'file-audio',
  'file-certificate',
  'file-chart-line',
  'file-chart-pie',
  'file-check',
  'file-code',
  'file-contract',
  'file-csv',
  'file-download',
  'file-edit',
  'file-excel',
  'file-exclamation',
  'file-export',
  'file-image',
  'file-import',
  'file-invoice-dollar',
  'file-invoice',
  'file-medical-alt',
  'file-medical',
  'file-minus',
  'file-music',
  'file-pdf',
  'file-plus',
  'file-powerpoint',
  'file-prescription',
  'file-search',
  'file-signature',
  'file-spreadsheet',
  'file-times',
  'file-upload',
  'file-user',
  'file-video',
  'file-word',
  'file',
  'file-chart-column',
  'files-medical',
  'fill-drip',
  'fill',
  'film-alt',
  'film-canister',
  'film',
  'filter',
  'fingerprint',
  'fire-alt',
  'fire-extinguisher',
  'fire-smoke',
  'fire',
  'fireplace',
  'first-aid',
  'fish-cooked',
  'fish',
  'fist-raised',
  'flag-alt',
  'flag-checkered',
  'flag-usa',
  'flag',
  'flame',
  'flashlight',
  'flask-poison',
  'flask-potion',
  'flask',
  'flower-daffodil',
  'flower-tulip',
  'flower',
  'flushed',
  'flute',
  'flux-capacitor',
  'fog',
  'folder-download',
  'folder-minus',
  'folder-open',
  'folder-plus',
  'folder-times',
  'folder-tree',
  'folder-upload',
  'folder',
  'folders',
  'font-awesome-logo-full',
  'font-case',
  'font',
  'football-ball',
  'football-helmet',
  'forklift',
  'forward',
  'fragile',
  'french-fries',
  'frog',
  'frosty-head',
  'frown-open',
  'frown',
  'function',
  'funnel-dollar',
  'futbol',
  'galaxy',
  'game-board-alt',
  'game-board',
  'game-console-handheld',
  'gamepad-alt',
  'gamepad',
  'garage-car',
  'garage-open',
  'garage',
  'gas-pump-slash',
  'gas-pump',
  'gavel',
  'gem',
  'genderless',
  'ghost',
  'gift-card',
  'gift',
  'gifts',
  'gingerbread-man',
  'glass-champagne',
  'glass-cheers',
  'glass-citrus',
  'glass-martini-alt',
  'glass-martini',
  'glass-whiskey-rocks',
  'glass-whiskey',
  'glass',
  'glasses-alt',
  'glasses',
  'globe-africa',
  'globe-americas',
  'globe-asia',
  'globe-europe',
  'globe-snow',
  'globe-stand',
  'globe',
  'golf-ball',
  'golf-club',
  'gopuram',
  'graduation-cap',
  'gramophone',
  'greater-than-equal',
  'greater-than',
  'grimace',
  'grin-alt',
  'grin-beam-sweat',
  'grin-beam',
  'grin-hearts',
  'grin-squint-tears',
  'grin-squint',
  'grin-stars',
  'grin-tears',
  'grin-tongue-squint',
  'grin-tongue-wink',
  'grin-tongue',
  'grin-wink',
  'grin',
  'grip-horizontal',
  'grip-lines-vertical',
  'grip-lines',
  'grip-vertical',
  'guitar-electric',
  'guitar',
  'guitars',
  'h-square',
  'h1',
  'h2',
  'h3',
  'h4',
  'hamburger',
  'hammer-war',
  'hammer',
  'hamsa',
  'hand-heart',
  'hand-holding-box',
  'hand-holding-heart',
  'hand-holding-magic',
  'hand-holding-medical',
  'hand-holding-seedling',
  'hand-holding-usd',
  'hand-holding-water',
  'hand-holding',
  'hand-lizard',
  'hand-middle-finger',
  'hand-paper',
  'hand-peace',
  'hand-point-down',
  'hand-point-left',
  'hand-point-right',
  'hand-point-up',
  'hand-pointer',
  'hand-receiving',
  'hand-rock',
  'hand-scissors',
  'hand-sparkles',
  'hand-spock',
  'hands-heart',
  'hands-helping',
  'hands-usd',
  'hands-wash',
  'hands',
  'handshake-alt-slash',
  'handshake-alt',
  'handshake-slash',
  'handshake',
  'hanukiah',
  'hard-hat',
  'hashtag',
  'hat-chef',
  'hat-cowboy-side',
  'hat-cowboy',
  'hat-santa',
  'hat-winter',
  'hat-witch',
  'hat-wizard',
  'hdd',
  'head-side-brain',
  'head-side-cough-slash',
  'head-side-cough',
  'head-side-headphones',
  'head-side-mask',
  'head-side-medical',
  'head-side-virus',
  'head-side',
  'head-vr',
  'heading',
  'headphones-alt',
  'headphones',
  'headset',
  'heart-broken',
  'heart-circle',
  'heart-rate',
  'heart-square',
  'heart',
  'heartbeat',
  'heat',
  'helicopter',
  'helmet-battle',
  'hexagon',
  'highlighter',
  'hiking',
  'hippo',
  'history',
  'hockey-mask',
  'hockey-puck',
  'hockey-sticks',
  'holly-berry',
  'home-alt',
  'home-heart',
  'home-lg-alt',
  'home-lg',
  'home',
  'hood-cloak',
  'horizontal-rule',
  'horse-head',
  'horse-saddle',
  'horse',
  'hospital-alt',
  'hospital-symbol',
  'hospital-user',
  'hospital',
  'hospitals',
  'hot-tub',
  'hotdog',
  'hotel',
  'hourglass-end',
  'hourglass-half',
  'hourglass-start',
  'hourglass',
  'house-damage',
  'house-day',
  'house-flood',
  'house-leave',
  'house-night',
  'house-return',
  'house-signal',
  'house-user',
  'house',
  'hryvnia',
  'humidity',
  'hurricane',
  'i-cursor',
  'ice-cream',
  'ice-skate',
  'icicles',
  'icons-alt',
  'icons',
  'id-badge',
  'id-card-alt',
  'id-card',
  'igloo',
  'image-polaroid',
  'image',
  'images',
  'inbox-in',
  'inbox-out',
  'inbox',
  'indent',
  'industry-alt',
  'industry',
  'infinity',
  'info-circle',
  'info-square',
  'info',
  'inhaler',
  'integral',
  'intersection',
  'inventory',
  'island-tropical',
  'italic',
  'jack-o-lantern',
  'jedi',
  'joint',
  'journal-whills',
  'joystick',
  'jug',
  'kaaba',
  'kazoo',
  'kerning',
  'key-skeleton',
  'key',
  'keyboard',
  'keynote',
  'khanda',
  'kidneys',
  'kiss-beam',
  'kiss-wink-heart',
  'kiss',
  'kite',
  'kiwi-bird',
  'knife-kitchen',
  'lambda',
  'lamp-desk',
  'lamp-floor',
  'lamp',
  'landmark-alt',
  'landmark',
  'language',
  'laptop-code',
  'laptop-house',
  'laptop-medical',
  'laptop',
  'lasso',
  'laugh-beam',
  'laugh-squint',
  'laugh-wink',
  'laugh',
  'layer-group',
  'layer-minus',
  'layer-plus',
  'leaf-heart',
  'leaf-maple',
  'leaf-oak',
  'leaf',
  'lemon',
  'less-than-equal',
  'less-than',
  'level-down-alt',
  'level-down',
  'level-up-alt',
  'level-up',
  'life-ring',
  'light-ceiling',
  'light-switch-off',
  'light-switch-on',
  'light-switch',
  'lightbulb-dollar',
  'lightbulb-exclamation',
  'lightbulb-on',
  'lightbulb-slash',
  'lightbulb',
  'lights-holiday',
  'line-columns',
  'line-height',
  'link',
  'lips',
  'lira-sign',
  'list-alt',
  'list-music',
  'list-ol',
  'list-ul',
  'list',
  'location-arrow',
  'location-circle',
  'location-slash',
  'location',
  'lock-alt',
  'lock-open-alt',
  'lock-open',
  'lock',
  'long-arrow-alt-down',
  'long-arrow-alt-left',
  'long-arrow-alt-right',
  'long-arrow-alt-up',
  'long-arrow-down',
  'long-arrow-left',
  'long-arrow-right',
  'long-arrow-up',
  'loveseat',
  'low-vision',
  'luchador',
  'luggage-cart',
  'lungs-virus',
  'lungs',
  'mace',
  'magic',
  'magnet',
  'mail-bulk',
  'mailbox',
  'male',
  'mandolin',
  'map-marked-alt',
  'map-marked',
  'map-marker-alt-slash',
  'map-marker-alt',
  'map-marker-check',
  'map-marker-edit',
  'map-marker-exclamation',
  'map-marker-minus',
  'map-marker-plus',
  'map-marker-question',
  'map-marker-slash',
  'map-marker-smile',
  'map-marker-times',
  'map-marker',
  'map-pin',
  'map-signs',
  'map',
  'marker',
  'mars-double',
  'mars-stroke-h',
  'mars-stroke-v',
  'mars-stroke',
  'mars',
  'mask',
  'meat',
  'medal',
  'medkit',
  'megaphone',
  'meh-blank',
  'meh-rolling-eyes',
  'meh',
  'memory',
  'menorah',
  'mercury',
  'meteor',
  'microchip',
  'microphone-alt-slash',
  'microphone-alt',
  'microphone-slash',
  'microphone-stand',
  'microphone',
  'microscope',
  'microwave',
  'mind-share',
  'minus-circle',
  'minus-hexagon',
  'minus-octagon',
  'minus-square',
  'minus',
  'mistletoe',
  'mitten',
  'mobile-alt',
  'mobile-android-alt',
  'mobile-android',
  'mobile',
  'money-bill-alt',
  'money-bill-wave-alt',
  'money-bill-wave',
  'money-bill',
  'money-check-alt',
  'money-check-edit-alt',
  'money-check-edit',
  'money-check',
  'monitor-heart-rate',
  'monkey',
  'monument',
  'moon-cloud',
  'moon-stars',
  'moon',
  'mortar-pestle',
  'mosque',
  'motorcycle',
  'mountain',
  'mountains',
  'mouse-alt',
  'mouse-pointer',
  'mouse',
  'mp3-player',
  'mug-hot',
  'mug-marshmallows',
  'mug-tea',
  'mug',
  'music-alt-slash',
  'music-alt',
  'music-slash',
  'music',
  'narwhal',
  'network-wired',
  'neuter',
  'newspaper',
  'not-equal',
  'notes-medical',
  'object-group',
  'object-ungroup',
  'octagon',
  'oil-can',
  'oil-temp',
  'om',
  'omega',
  'ornament',
  'otter',
  'outdent',
  'outlet',
  'oven',
  'overline',
  'page-break',
  'pager',
  'paint-brush-alt',
  'paint-brush',
  'paint-roller',
  'palette',
  'pallet-alt',
  'pallet',
  'paper-plane',
  'paperclip',
  'parachute-box',
  'paragraph-rtl',
  'paragraph',
  'parking-circle-slash',
  'parking-circle',
  'parking-slash',
  'parking',
  'passport',
  'pastafarianism',
  'paste',
  'pause-circle',
  'pause',
  'paw-alt',
  'paw-claws',
  'paw',
  'peace',
  'pegasus',
  'pen-alt',
  'pen-fancy',
  'pen-nib',
  'pen-square',
  'pen',
  'pencil-alt',
  'pencil-paintbrush',
  'pencil-ruler',
  'pencil',
  'pennant',
  'people-arrows',
  'people-carry',
  'pepper-hot',
  'percent',
  'percentage',
  'person-booth',
  'person-carry',
  'person-dolly-empty',
  'person-dolly',
  'person-sign',
  'phone-alt',
  'phone-laptop',
  'phone-office',
  'phone-plus',
  'phone-rotary',
  'phone-slash',
  'phone-square-alt',
  'phone-square',
  'phone-volume',
  'phone',
  'photo-video',
  'pi',
  'piano-keyboard',
  'piano',
  'pie',
  'pig',
  'piggy-bank',
  'pills',
  'pizza-slice',
  'pizza',
  'place-of-worship',
  'plane-alt',
  'plane-arrival',
  'plane-departure',
  'plane-slash',
  'plane',
  'planet-moon',
  'planet-ringed',
  'play-circle',
  'play',
  'plug',
  'plus-circle',
  'plus-hexagon',
  'plus-octagon',
  'plus-square',
  'plus',
  'podcast',
  'podium-star',
  'podium',
  'police-box',
  'poll-h',
  'poll-people',
  'poll',
  'poo-storm',
  'poo',
  'poop',
  'popcorn',
  'portal-enter',
  'portal-exit',
  'portrait',
  'pound-sign',
  'power-off',
  'pray',
  'praying-hands',
  'prescription-bottle-alt',
  'prescription-bottle',
  'prescription',
  'presentation',
  'print-search',
  'print-slash',
  'print',
  'procedures',
  'project-diagram',
  'projector',
  'pump-medical',
  'pump-soap',
  'pumpkin',
  'puzzle-piece',
  'qrcode',
  'question-circle',
  'question-square',
  'question',
  'quidditch',
  'quote-left',
  'quote-right',
  'quran',
  'rabbit-fast',
  'rabbit',
  'racquet',
  'radar',
  'radiation-alt',
  'radiation',
  'radio-alt',
  'radio',
  'rainbow',
  'raindrops',
  'ram',
  'ramp-loading',
  'random',
  'raygun',
  'receipt',
  'record-vinyl',
  'rectangle-landscape',
  'rectangle-portrait',
  'rectangle-wide',
  'recycle',
  'redo-alt',
  'redo',
  'refrigerator',
  'registered',
  'remove-format',
  'repeat-1-alt',
  'repeat-1',
  'repeat-alt',
  'repeat',
  'reply-all',
  'reply',
  'republican',
  'restroom',
  'retweet-alt',
  'retweet',
  'ribbon',
  'ring',
  'rings-wedding',
  'road',
  'robot',
  'rocket-launch',
  'rocket',
  'route-highway',
  'route-interstate',
  'route',
  'router',
  'rss-square',
  'rss',
  'ruble-sign',
  'ruler-combined',
  'ruler-horizontal',
  'ruler-triangle',
  'ruler-vertical',
  'ruler',
  'running',
  'rupee-sign',
  'rv',
  'sack-dollar',
  'sack',
  'sad-cry',
  'sad-tear',
  'salad',
  'sandwich',
  'satellite-dish',
  'satellite',
  'sausage',
  'save',
  'sax-hot',
  'saxophone',
  'scalpel-path',
  'scalpel',
  'scanner-image',
  'scanner-keyboard',
  'scanner-touchscreen',
  'scanner',
  'scarecrow',
  'scarf',
  'school',
  'screwdriver',
  'scroll-old',
  'scroll',
  'scrubber',
  'scythe',
  'sd-card',
  'search-dollar',
  'search-location',
  'search-minus',
  'search-plus',
  'search',
  'seedling',
  'send-back',
  'send-backward',
  'sensor-alert',
  'sensor-fire',
  'sensor-on',
  'sensor-smoke',
  'sensor',
  'server',
  'shapes',
  'share-all',
  'share-alt-square',
  'share-alt',
  'share-square',
  'share',
  'sheep',
  'shekel-sign',
  'shield-alt',
  'shield-check',
  'shield-cross',
  'shield-virus',
  'shield',
  'ship',
  'shipping-fast',
  'shipping-timed',
  'shish-kebab',
  'shoe-prints',
  'shopping-bag',
  'shopping-basket',
  'shopping-cart',
  'shovel-snow',
  'shovel',
  'shower',
  'shredder',
  'shuttle-van',
  'shuttlecock',
  'sickle',
  'sigma',
  'sign-in-alt',
  'sign-in',
  'sign-language',
  'sign-out-alt',
  'sign-out',
  'sign',
  'signal-1',
  'signal-2',
  'signal-3',
  'signal-4',
  'signal-alt-1',
  'signal-alt-2',
  'signal-alt-3',
  'signal-alt-slash',
  'signal-alt',
  'signal-slash',
  'signal-stream',
  'signal',
  'signature',
  'sim-card',
  'sink',
  'siren-on',
  'siren',
  'sitemap',
  'skating',
  'skeleton',
  'ski-jump',
  'ski-lift',
  'skiing-nordic',
  'skiing',
  'skull-cow',
  'skull-crossbones',
  'skull',
  'slash',
  'sledding',
  'sleigh',
  'sliders-h-square',
  'sliders-h',
  'sliders-v-square',
  'sliders-v',
  'smile-beam',
  'smile-plus',
  'smile-wink',
  'smile',
  'smog',
  'smoke',
  'smoking-ban',
  'smoking',
  'sms',
  'snake',
  'snooze',
  'snow-blowing',
  'snowboarding',
  'snowflake',
  'snowflakes',
  'snowman',
  'snowmobile',
  'snowplow',
  'soap',
  'socks',
  'solar-panel',
  'solar-system',
  'sort-alpha-down-alt',
  'sort-alpha-down',
  'sort-alpha-up-alt',
  'sort-alpha-up',
  'sort-alt',
  'sort-amount-down-alt',
  'sort-amount-down',
  'sort-amount-up-alt',
  'sort-amount-up',
  'sort-circle-down',
  'sort-circle-up',
  'sort-circle',
  'sort-down',
  'sort-numeric-down-alt',
  'sort-numeric-down',
  'sort-numeric-up-alt',
  'sort-numeric-up',
  'sort-shapes-down-alt',
  'sort-shapes-down',
  'sort-shapes-up-alt',
  'sort-shapes-up',
  'sort-size-down-alt',
  'sort-size-down',
  'sort-size-up-alt',
  'sort-size-up',
  'sort-up',
  'sort',
  'soup',
  'spa',
  'space-shuttle',
  'space-station-moon-alt',
  'space-station-moon',
  'spade',
  'sparkles',
  'speaker',
  'speakers',
  'spell-check',
  'spider-black-widow',
  'spider-web',
  'spider',
  'spinner-third',
  'spinner',
  'splotch',
  'spray-can',
  'sprinkler',
  'square-full',
  'square-root-alt',
  'square-root',
  'square',
  'square-kanban',
  'squirrel',
  'staff',
  'stamp',
  'star-and-crescent',
  'star-christmas',
  'star-exclamation',
  'star-half-alt',
  'star-half',
  'star-of-david',
  'star-of-life',
  'star-shooting',
  'star',
  'starfighter-alt',
  'starfighter',
  'stars',
  'starship-freighter',
  'starship',
  'steak',
  'steering-wheel',
  'step-backward',
  'step-forward',
  'stethoscope',
  'sticky-note',
  'stocking',
  'stomach',
  'stop-circle',
  'stop',
  'stopwatch-20',
  'stopwatch',
  'store-alt-slash',
  'store-alt',
  'store-slash',
  'store',
  'stream',
  'street-view',
  'stretcher',
  'strikethrough',
  'stroopwafel',
  'subscript',
  'subway',
  'suitcase-rolling',
  'suitcase',
  'sun-cloud',
  'sun-dust',
  'sun-haze',
  'sun',
  'sunglasses',
  'sunrise',
  'sunset',
  'superscript',
  'surprise',
  'swatchbook',
  'swimmer',
  'swimming-pool',
  'sword-laser-alt',
  'sword-laser',
  'sword',
  'swords-laser',
  'swords',
  'synagogue',
  'sync-alt',
  'sync',
  'syringe',
  'table-tennis',
  'table',
  'tablet-alt',
  'tablet-android-alt',
  'tablet-android',
  'tablet-rugged',
  'tablet',
  'tablets',
  'tachometer-alt-average',
  'tachometer-alt-fast',
  'tachometer-alt-fastest',
  'tachometer-alt-slow',
  'tachometer-alt-slowest',
  'tachometer-alt',
  'tachometer-average',
  'tachometer-fast',
  'tachometer-fastest',
  'tachometer-slow',
  'tachometer-slowest',
  'tachometer',
  'taco',
  'tag',
  'tags',
  'tally',
  'tanakh',
  'tape',
  'tasks-alt',
  'tasks',
  'taxi',
  'teeth-open',
  'teeth',
  'telescope',
  'temperature-down',
  'temperature-frigid',
  'temperature-high',
  'temperature-hot',
  'temperature-low',
  'temperature-up',
  'tenge',
  'tennis-ball',
  'terminal',
  'text-height',
  'text-size',
  'text-width',
  'text',
  'th-large',
  'th-list',
  'th',
  'theater-masks',
  'thermometer-empty',
  'thermometer-full',
  'thermometer-half',
  'thermometer-quarter',
  'thermometer-three-quarters',
  'thermometer',
  'theta',
  'thumbs-down',
  'thumbs-up',
  'thumbtack',
  'thunderstorm-moon',
  'thunderstorm-sun',
  'thunderstorm',
  'ticket-alt',
  'ticket',
  'tilde',
  'times-circle',
  'times-hexagon',
  'times-octagon',
  'times-square',
  'times',
  'tint-slash',
  'tint',
  'tire-flat',
  'tire-pressure-warning',
  'tire-rugged',
  'tire',
  'tired',
  'toggle-off',
  'toggle-on',
  'toilet-paper-alt',
  'toilet-paper-slash',
  'toilet-paper',
  'toilet',
  'tombstone-alt',
  'tombstone',
  'toolbox',
  'tools',
  'tooth',
  'toothbrush',
  'torah',
  'torii-gate',
  'tornado',
  'tractor',
  'trademark',
  'traffic-cone',
  'traffic-light-go',
  'traffic-light-slow',
  'traffic-light-stop',
  'traffic-light',
  'trailer',
  'train',
  'tram',
  'transgender-alt',
  'transgender',
  'transporter-1',
  'transporter-2',
  'transporter-3',
  'transporter-empty',
  'transporter',
  'trash-alt',
  'trash-restore-alt',
  'trash-restore',
  'trash-undo-alt',
  'trash-undo',
  'trash',
  'treasure-chest',
  'tree-alt',
  'tree-christmas',
  'tree-decorated',
  'tree-large',
  'tree-palm',
  'tree',
  'trees',
  'triangle-music',
  'triangle',
  'trophy-alt',
  'trophy',
  'truck-container',
  'truck-couch',
  'truck-loading',
  'truck-monster',
  'truck-moving',
  'truck-pickup',
  'truck-plow',
  'truck-ramp',
  'truck',
  'trumpet',
  'tshirt',
  'tty',
  'turkey',
  'turntable',
  'turtle',
  'tv-alt',
  'tv-music',
  'tv-retro',
  'tv',
  'typewriter',
  'ufo-beam',
  'ufo',
  'umbrella-beach',
  'umbrella',
  'underline',
  'undo-alt',
  'undo',
  'unicorn',
  'union',
  'universal-access',
  'university',
  'unlink',
  'unlock-alt',
  'unlock',
  'upload',
  'usb-drive',
  'usd-circle',
  'usd-square',
  'user-alien',
  'user-alt-slash',
  'user-alt',
  'user-astronaut',
  'user-chart',
  'user-check',
  'user-circle',
  'user-clock',
  'user-cog',
  'user-cowboy',
  'user-crown',
  'user-edit',
  'user-friends',
  'user-graduate',
  'user-hard-hat',
  'user-headset',
  'user-injured',
  'user-lock',
  'user-md-chat',
  'user-md',
  'user-minus',
  'user-music',
  'user-ninja',
  'user-nurse',
  'user-plus',
  'user-robot',
  'user-secret',
  'user-shield',
  'user-slash',
  'user-tag',
  'user-tie',
  'user-times',
  'user-unlock',
  'user-visor',
  'user',
  'users-class',
  'users-cog',
  'users-crown',
  'users-medical',
  'users-slash',
  'users',
  'utensil-fork',
  'utensil-knife',
  'utensil-spoon',
  'utensils-alt',
  'utensils',
  'vacuum-robot',
  'vacuum',
  'value-absolute',
  'vector-square',
  'venus-double',
  'venus-mars',
  'venus',
  'vest-patches',
  'vest',
  'vhs',
  'vial',
  'vials',
  'video-plus',
  'video-slash',
  'video',
  'vihara',
  'violin',
  'virus-slash',
  'virus',
  'viruses',
  'voicemail',
  'volcano',
  'volleyball-ball',
  'volume-down',
  'volume-mute',
  'volume-off',
  'volume-slash',
  'volume-up',
  'volume',
  'vote-nay',
  'vote-yea',
  'vr-cardboard',
  'wagon-covered',
  'walker',
  'walkie-talkie',
  'walking',
  'wallet',
  'wand-magic',
  'wand',
  'warehouse-alt',
  'warehouse',
  'washer',
  'watch-calculator',
  'watch-fitness',
  'watch',
  'water-lower',
  'water-rise',
  'water',
  'wave-sine',
  'wave-square',
  'wave-triangle',
  'waveform-path',
  'waveform',
  'webcam-slash',
  'webcam',
  'weight-hanging',
  'weight',
  'whale',
  'wheat',
  'wheelchair',
  'whistle',
  'wifi-1',
  'wifi-2',
  'wifi-slash',
  'wifi',
  'wind-turbine',
  'wind-warning',
  'wind',
  'window-alt',
  'window-close',
  'window-frame-open',
  'window-frame',
  'window-maximize',
  'window-minimize',
  'window-restore',
  'window',
  'windsock',
  'wine-bottle',
  'wine-glass-alt',
  'wine-glass',
  'won-sign',
  'wreath',
  'wrench',
  'x-ray',
  'yen-sign',
  'yin-yang',
}

export const iconTypeKeys: string[] = Object.keys(IconType).filter(
  k => typeof IconType[k as any] === 'number',
);
