import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { WorkspaceGroupModel } from '../../../core/models/workspace-group.model';
import { SizeType } from '../../../core/constants/size-type';

export interface RequestEditPrimaryFieldEvent {
  group: WorkspaceGroupModel;
  currentTag: number;
}

@Component({
  selector: 'app-assignment-primary-tag',
  templateUrl: './assignment-primary-tag.component.html',
})
export class AssignmentPrimaryTagComponent implements OnInit, OnChanges {
  @Input()
  group: WorkspaceGroupModel;

  @Input()
  isExpanded: boolean;

  @Input()
  readonly: boolean;

  @Input()
  size: SizeType;

  @Output() requestEdit: EventEmitter<RequestEditPrimaryFieldEvent> = new EventEmitter<
    RequestEditPrimaryFieldEvent
  >();

  get colorTheme() {
    if (this.group && this.group.tags && this.group.tags[0] && this.group.tags[0].tag) {
      return this.group.tags[0].tag.colorTheme;
    } else {
      return null;
    }
  }

  public hasPrimaryTag: boolean;

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.hasPrimaryTag = this.group.tags[0] != null && this.group.tags[0].tag != null;
  }

  requestEditAssignment(currentTag: number) {
    if (this.readonly) {
      return;
    }
    this.requestEdit.emit({
      group: this.group,
      currentTag: currentTag,
    });
  }
}
