<div
  class="modal-popover"
  [ngClass]="{
    'modal--sm': size === ModalSize.Small,
    'modal--md': size === ModalSize.Medium,
    'modal--lg': size === ModalSize.Large,
    'modal--xl': size === ModalSize.XLarge,
    'theme--info': theme === ModalTheme.Info,
    'theme--success': theme === ModalTheme.Success,
    'theme--warning': theme === ModalTheme.Warning,
    'theme--danger': theme === ModalTheme.Danger
  }"
>
  <app-modal-header
    class="modal-popover__header"
    *ngIf="headerTemplate"
    [template]="headerTemplate"
  >
  </app-modal-header>

  <app-modal-header class="modal-popover__header" *ngIf="title" [title]="title" [iconUrl]="iconUrl">
    <ng-container stepInfo *ngTemplateOutlet="stepTemplate"></ng-container>
  </app-modal-header>

  <div cdkScrollable class="modal-popover__body modal-body">
    <ng-content></ng-content>
  </div>

  <app-modal-footer
    class="modal-popover__footer"
    *ngIf="footerTemplate"
    [template]="footerTemplate"
    [flexAlignStyle]="flexAlignStyle"
  >
  </app-modal-footer>

  <app-content-loader class="modal-popover__loader" scope="modal"></app-content-loader>
</div>
