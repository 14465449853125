import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../store';
import { NodeType } from '../../../core/constants/node-type';
import { NodeModel } from '../../../core/models/node.model';
import { NodesActions, NodesSelectors } from '../../../store/nodes';
import { AbstractPickerComponent, ListItem } from '../_abstract-picker/abstract-picker.component';
import { NodeUtils } from '../../../core/utils/node.util';
import { IconType } from '../../../core/constants/icon-type';

@Component({
  templateUrl: './folder-picker.component.html',
  styleUrls: ['./folder-picker.component.scss'],
})
export class FolderPickerComponent extends AbstractPickerComponent<NodeModel>
  implements OnInit, OnDestroy {
  public readonly IconType = IconType;
  public readonly NodeType = NodeType;

  @Input()
  public types: NodeType[];

  constructor(public activeModal: NgbActiveModal, private store: Store<AppState>) {
    super(activeModal);
    this.types = [NodeType.projectFolder, NodeType.assetFolder, NodeType.elementFolder];
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.subscription.add(
      this.store
        .pipe(select(NodesSelectors.selectNodesFilteredByNodeTypeWithKeyword(this.types)))
        .subscribe((nodes: NodeModel[]) => {
          const tree = NodeUtils.buildTree(nodes);
          const flatTree = NodeUtils.flattenTree(tree);
          this.updateList(flatTree);
        }),
    );
  }

  public search(keywords) {
    this.keywords = keywords;
    this.store.dispatch(NodesActions.updateSearchKeywordRequest({ searchKeyword: this.keywords }));
  }

  public isEqual(itemA: NodeModel, itemB: NodeModel): boolean {
    return itemA.id === itemB.id;
  }

  public add() {
    throw new Error('Not implemented');
  }

  public edit(listItem: ListItem<NodeModel>) {
    throw new Error('Not implemented');
  }

  public trackBy(index, item: ListItem<NodeModel>) {
    return item.model.id;
  }

  protected sortItems() {
    // Use the default sort order of the 'flatTree'
    return;
  }

  public ngOnDestroy(): void {
    this.store.dispatch(NodesActions.updateSearchKeywordRequest({ searchKeyword: '' }));
    super.ngOnDestroy();
  }
}
