import { createAction, props } from '@ngrx/store';
import { PermissionsByRole } from './role-permissions.state';

export const loadRolePermissionsRequest = createAction(
  '[Role Permissions] load role permissions request',
);

export const loadRolePermissionsSuccess = createAction(
  '[Role Permissions] load role permissions success',
  props<{ permissionsByRole: PermissionsByRole }>(),
);
