export enum NodeType {
  unknown = 0,

  projectFolder = 10,
  project = 11,

  assetFolder = 30,
  asset = 31,

  elementFolder = 40,
  element = 41,

  assignmentFolder = 50,
  assignment = 51,
  assignmentElement = 52,

  favouritesFolder = 60,
  folderShortcut = 61,
}
