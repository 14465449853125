import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { LazyScroller, LazyScrollerService } from './lazy-scroller.service';
import { Subscription } from 'rxjs';

export enum LazyScrollerOrientation {
  horizontal = 'horizontal',
  vertical = 'vertical',
  any = 'any',
}

@Component({
  selector: 'app-lazy-scroller-element',
  template: `
    <div
      #placeholder
      class="lazy-scroller-placeholder"
      [ngClass]="'lazy-scroller-placeholder--' + orientation"
    >
      <div
        *ngIf="!isOutside"
        #container
        class="lazy-scroller-container"
        [ngClass]="'lazy-scroller-container--' + orientation"
      >
        <ng-container *ngTemplateOutlet="templateRef"></ng-container>
      </div>
    </div>
  `,
})
export class LazyScrollerElementComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  private readonly subscription = new Subscription();

  @Input()
  public templateRef: TemplateRef<any>;

  @Input()
  public width: number;

  @Input()
  public height: number;

  @Input()
  public orientation: LazyScrollerOrientation | string;

  @Input()
  public scope: string;

  @ViewChild('container')
  public container: ElementRef;

  @ViewChild('placeholder')
  public placeholder: ElementRef;

  public isOutside: boolean;

  private parentScroller: LazyScroller;

  private timeoutId: any;

  constructor(private lazyScrollerService: LazyScrollerService) {
    this.isOutside = true;
    this.width = 300;
    this.height = 300;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.orientation.previousValue != changes.orientation.currentValue) {
      this.updateElement();
    }
  }

  public ngOnInit(): void {
    this.subscription.add(
      this.lazyScrollerService.get(this.scope).subscribe(scroller => {
        this.parentScroller = scroller;
        clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(() => {
          this.update();
        }, 150);
      }),
    );
  }

  public ngAfterViewInit(): void {
    this.updateElement();
  }

  private update() {
    this.isOutside = this.isOutsideTest();
    this.updateElement();
  }

  private updateElement() {
    if (!this.placeholder || !this.parentScroller || !this.parentScroller.element) {
      return;
    }
    if (this.isOutside) {
      this.placeholder.nativeElement.style.width = `${this.width}px`;
      this.placeholder.nativeElement.style.height = `${this.height}px`;
    } else {
      this.placeholder.nativeElement.style.removeProperty('height');
      this.placeholder.nativeElement.style.removeProperty('width');
    }
  }

  private isOutsideTest(): boolean {
    if (!this.placeholder || !this.parentScroller.element) {
      return;
    }
    let childRect = this.placeholder.nativeElement.getBoundingClientRect();
    let parentRect = this.parentScroller.element.nativeElement.getBoundingClientRect();

    this.width = Math.round(childRect.width);
    this.height = Math.round(childRect.height);

    switch (this.orientation) {
      case LazyScrollerOrientation.horizontal:
        return childRect.left > parentRect.right || childRect.right < parentRect.left;
      case LazyScrollerOrientation.vertical:
        return childRect.bottom < parentRect.top || childRect.top > parentRect.bottom;
      case LazyScrollerOrientation.any:
      default:
        return (
          childRect.bottom < parentRect.top ||
          childRect.top > parentRect.bottom ||
          childRect.left > parentRect.right ||
          childRect.right <= parentRect.left
        );
    }
  }

  public ngOnDestroy(): void {
    clearTimeout(this.timeoutId);
    this.subscription.unsubscribe();
  }
}
