import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { ENTER } from '@angular/cdk/keycodes';
import {
  availableCalculationFieldWidgetTypeOptions,
  WidgetType,
} from '../../../../core/constants/widget-type';
import { availableCalculationMethodOptions } from '../../../../core/constants/calculation-method.type';
import { TemplateWidgetsActions } from '../../../../store/template-widgets';
import { selectTemplateFiguresPopoverTemplateId } from '../../../../store/template-figures-popover/template-figures-popover.selectors';

@Component({
  selector: 'app-template-calculation-create',
  templateUrl: './template-calculation-create.component.html',
  styleUrls: ['./template-calculation-create.component.scss'],
})
export class TemplateCalculationCreateComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('widgetATitleInput', { static: true }) widgetATitleRef: ElementRef;
  @Output() closeEvent = new EventEmitter();

  selectedTemplateId: number;

  form: UntypedFormGroup;
  isSubmitted: boolean;

  availableCalculationFieldWidgetTypeOptions = availableCalculationFieldWidgetTypeOptions;
  availableCalculationMethodOptions = availableCalculationMethodOptions;
  subscription = new Subscription();

  // convenience getter for easy access to form fields
  public get f() {
    return this.form.controls;
  }

  get widgetATitle() {
    return this.form.get('widgetATitle');
  }

  get widgetBTitle() {
    return this.form.get('widgetBTitle');
  }

  get widgetCTitle() {
    return this.form.get('widgetCTitle');
  }

  constructor(private fb: UntypedFormBuilder, private store: Store) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      widgetATitle: ['', Validators.required],
      widgetAType: [WidgetType.number, Validators.required],
      widgetBTitle: ['', Validators.required],
      widgetBType: [WidgetType.number, Validators.required],
      widgetCTitle: ['', Validators.required],
      calculationMethod: [undefined, Validators.required],
    });

    this.subscription.add(
      this.store.pipe(select(selectTemplateFiguresPopoverTemplateId)).subscribe(templateId => {
        this.selectedTemplateId = templateId;
      }),
    );
  }

  submit() {
    this.isSubmitted = true;
    if (this.form.valid) {
      this.store.dispatch(
        TemplateWidgetsActions.createAndAddCalculationWidgetRequest({
          templateId: this.selectedTemplateId,
          title: this.widgetCTitle.value,
          widgetATitle: this.widgetATitle.value,
          widgetAType: this.f['widgetAType'].value,
          widgetBTitle: this.widgetBTitle.value,
          widgetBType: this.f['widgetBType'].value,
          calculationMethod: this.f['calculationMethod'].value,
          sortIndex: 999,
        }),
      );
      // this.store.dispatch(
      //   WorkspaceGroupsActions.addWorkspaceGroupRequest({
      //     workspaceSlug: this.workspaceSlug,
      //     label: this.title.value,
      //     groupType: WorkspaceGroupType.assignmentWidget,
      //     templateId: this.selectedTemplateId,
      //   }),
      // );

      this.closeEvent.emit();
    }
  }

  onButtonKeyDown(event) {
    if (event.keyCode === ENTER) {
      event.preventDefault();
      this.submit();
    }
  }

  close() {
    this.closeEvent.emit();
  }

  ngAfterViewInit() {
    this.widgetATitleRef.nativeElement.focus();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
