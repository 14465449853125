import { AppState } from '../index';
import { createSelector } from '@ngrx/store';

const selectWorkspaceUserGroupsState = (state: AppState) => state.workspaceUserGroups;

export const selectWorkspaceUserGroupIds = createSelector(
  selectWorkspaceUserGroupsState,
  state => state.ids,
);

export const selectWorkspaceUserGroupsById = createSelector(
  selectWorkspaceUserGroupsState,
  state => state.byId,
);

export const selectWorkspaceUserGroups = createSelector(
  selectWorkspaceUserGroupIds,
  selectWorkspaceUserGroupsById,
  (ids, byId) => {
    return ids.map(id => byId[id]).filter(group => !!group);
  },
);
