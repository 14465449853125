import { Action, createReducer, on } from '@ngrx/store';
import { initialWidgetsState, WidgetsState } from './widgets.state';
import { WidgetsActions } from './index';
import { TemplateWidgetsActions } from '../template-widgets';
import { WidgetModel } from '../../core/models/widget.model';

const reducer = createReducer(
  initialWidgetsState,

  on(WidgetsActions.loadWidgetsSuccess, (state, { widgets }) => {
    const byId = widgets.reduce((byId, widget) => {
      return {
        ...byId,
        [widget.id]: { ...widget },
      };
    }, {});
    return {
      ...initialWidgetsState,
      byId,
    };
  }),

  on(
    WidgetsActions.addWidgetSuccess,
    TemplateWidgetsActions.createAndAddAssignmentChecklistWidgetSuccess,
    (state, { widget }) => {
      return {
        ...state,
        byId: {
          ...state.byId,
          [widget.id]: { ...widget },
        },
      };
    },
  ),

  on(WidgetsActions.addWidgetsSuccess, (state, { widgets }) => {
    return widgets.reduce((newState, widget: WidgetModel) => {
      return {
        ...newState,
        byId: {
          ...newState.byId,
          [widget.id]: { ...widget },
        },
      };
    }, state);
  }),

  on(WidgetsActions.updateWidgetRequest, (state, { widgetId, widgetProps }) => {
    return {
      ...state,
      byId: {
        ...state.byId,
        [widgetId]: { ...state.byId[widgetId], ...widgetProps },
      },
    };
  }),

  on(
    WidgetsActions.deleteWidgetRequest,
    TemplateWidgetsActions.removeTemplateGroupWidgetAndWidgetItselfRequest,
    (state, { widgetId }) => {
      return {
        ...state,
        byId: {
          ...state.byId,
          [widgetId]: undefined,
        },
      };
    },
  ),

  on(WidgetsActions.updateSearchKeywordRequest, (state, { searchKeyword }) => ({
    ...state,
    searchKeyword,
  })),
);

export function widgetsReducer(state: WidgetsState, action: Action) {
  return reducer(state, action);
}
