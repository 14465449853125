<div class="chatbar">
  <app-chat-sidebar-header
    class="chatbar__header"
    *ngIf="page === ChatbarPage.allChannels"
    [title]="'Diary Channels'"
  >
  </app-chat-sidebar-header>

  <app-chat-sidebar-header
    class="chatbar__header"
    *ngIf="page === ChatbarPage.channel"
    [title]="channel | chatChannelTitle"
    [subTitle]="channel | chatChannelTypeLabel"
    [badge]="'0 /' + (channel?.commentCount || 0)"
    [enableBack]="true"
    (backEvent)="goToAllChannels()"
  ></app-chat-sidebar-header>

  <div class="chatbar__body">
    <app-chat-channels *ngIf="page === ChatbarPage.allChannels"></app-chat-channels>
    <app-chat *ngIf="page === ChatbarPage.channel"></app-chat>
  </div>
</div>
