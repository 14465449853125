import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'progressStyleWidth',
})
export class ProgressStyleWidthPipe implements PipeTransform {
  transform(progressRate: number): number {
    return progressRate > 1 ? 100 : progressRate < 0 ? 0 : progressRate * 100;
  }
}
