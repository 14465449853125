import { LayoutTemplateModel } from '../../core/models/layout-template.model';

export class LayoutTemplateState {
  layoutTemplatesById: { [id: number]: LayoutTemplateModel };
  ids: number[];
  searchKeyword: string;
}

export const initialLayoutTemplatesState: LayoutTemplateState = {
  layoutTemplatesById: {},
  ids: [],
  searchKeyword: undefined,
};
