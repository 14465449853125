import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
// import { availableChecklistFieldWidgetTypeOptions } from '../../../../core/constants/widget-type';
import { ChecklistAddEditResult } from '../template-checklist/template-checklist.component';
import { selectAvailableWidgetTypes } from '../../../..//store/widget-types/widget-types.selectors';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../../..//store';
import { Subscription } from 'rxjs';
import { OptionModel } from '../../../..//core/models/option.model';
import { WidgetType } from '../../../..//core/constants/widget-type';
import { CurrencyMaskInputMode } from 'ngx-currency';
import { selectAssets, selectProjects } from '../../../..//store/nodes/node-enrichment.selectors';
import { WidgetSelectionList } from '../../../../core/models/widget.model';

@Component({
  selector: 'app-template-checklist-add-edit',
  templateUrl: './template-checklist-add-edit.component.html',
})
export class TemplateChecklistAddEditComponent implements OnInit, OnDestroy {
  @Input()
  public title: string;

  @Input()
  public valueTitle: string;

  @Input()
  public valueWidgetType: WidgetType;

  @Input()
  public valueWidgetChoices = [];

  @Input()
  public noCloseBtn: boolean;
  public types: OptionModel[];
  form: UntypedFormGroup;
  // readonly availableWidgetTypeOptions = availableChecklistFieldWidgetTypeOptions;
  public readonly WidgetType = WidgetType;
  public readonly CurrencyMaskInputMode = CurrencyMaskInputMode;
  private readonly subscription = new Subscription();
  public widgetOptions: WidgetSelectionList[] = [];
  public choices = [];
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private store: Store<AppState>,
  ) {
    this.title = 'Checklist';
  }

  public ngOnInit() {
    this.subscription.add(
      this.store.pipe(select(selectAvailableWidgetTypes)).subscribe(widgetTypes => {
        this.types = widgetTypes.filter(item => item.id !== WidgetType.urlList);
      }),
    );

    if (this.form?.get('selectedWidgetType').value === WidgetType.projectList) {
      this.subscription.add(
        this.store.pipe(select(selectProjects)).subscribe(allAssets => {
          this.widgetOptions = allAssets?.records?.map(item => {
            return {
              id: item?.id,
              label: item?.title,
            };
          });
        }),
      );
    }

    if (this.form?.get('selectedWidgetType').value === WidgetType.assetList) {
      this.subscription.add(
        this.store.pipe(select(selectAssets)).subscribe(allAssets => {
          this.widgetOptions = allAssets?.map(item => {
            return {
              id: item?.id,
              label: item?.title,
            };
          });
        }),
      );
    }

    this.form = this.formBuilder.group({
      title: [this.valueTitle ?? null, [Validators.required]],
      selectedWidgetType: [this.valueWidgetType ?? null, [Validators.required]],
    });

    this.choices = this.valueWidgetChoices;
  }

  public save() {
    if (this.form.invalid) {
      return;
    }
    this.activeModal.close({
      title: this.form?.get('title').value,
      widgetType: this.form?.get('selectedWidgetType').value,
      choices: this.choices,
    } as ChecklistAddEditResult);
  }

  public cancel() {
    this.activeModal.dismiss(false);
  }

  public delete(): void {
    if (this.valueTitle && this.valueWidgetType) {
      this.activeModal.close({
        title: this.form?.get('title').value,
        widgetType: this.form?.get('selectedWidgetType').value,
        isDelete: true,
      } as ChecklistAddEditResult);
    }
  }

  onChoicesEdit(choices: string[]) {
    this.choices = choices;
    this.form.markAsDirty();
  }

  get formControls(): {
    [key: string]: AbstractControl;
  } {
    return this.form.controls;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
