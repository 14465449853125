import { Component } from '@angular/core';
import { TableComponent } from '../../table.component';
import { AbstractTableEditableCellComponent } from '../abstract-table-editable-cell.component';
import { NodeTemplateModel } from '../../../../core/models/node-template.model';
import { NodeModel } from '../../../../core/models/node.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NodeType } from '../../../../core/constants/node-type';
import { FormsEditPanelComponent } from '../../../../shared/modals/forms-edit-panel/forms-edit-panel.component';

@Component({
  selector: 'app-z-table-editable-assignment-elements',
  templateUrl: './table-editable-assignment-elements.component.html',
  styleUrls: ['./table-editable-assignment-elements.component.scss'],
})
export class TableEditableAssignmentElementsComponent extends AbstractTableEditableCellComponent {
  public readonly NodeType = NodeType;
  public readonly MAX_DISPLAY = 4;

  constructor(public table: TableComponent, private modalService: NgbModal) {
    super(table);
  }

  public onStartEdit() {
    super.onStartEdit();

    // const modelRef = this.modalService.open(NodeAssetPickerComponent, {
    //   size: 'xl',
    //   backdrop: 'static',
    // });

    // const assignment = this.options?.assignment as NodeModel;
    // const template = this.options?.group as NodeTemplateModel;

    // modelRef.componentInstance.types = [template?.nodeType];
    // modelRef.componentInstance.nodeTemplates = [template];
    // modelRef.componentInstance.selected = template?.nodes.map(n => n.reference);

    // modelRef.componentInstance.maxSelection =
    //   template.behaviourType === BehaviourType.single ? 1 : 100;

    // modelRef.result
    //   .then((result: NodeModel[]) => {
    //     this.store.dispatch(
    //       NodesActions.replaceAssignmentElementsRequest({
    //         assignmentId: assignment.id,
    //         nodeIds: result.map(n => n.id),
    //         removeByNodeTemplateIds: [template.id],
    //       }),
    //     );
    //   })
    //   .catch(res => {});

    const modelRef = this.modalService.open(FormsEditPanelComponent, {
      size: 'xl',
      backdrop: 'static',
      windowClass: 'form-edit-modal',
    });

    modelRef.componentInstance.assignment = this.options?.assignment as NodeModel;
    // modelRef.componentInstance.nodeTemplates = [this.options?.group as NodeTemplateModel];

    modelRef.result
      .then((result: NodeModel[]) => {
        //
      })
      .catch(() => {});
  }

  trackBy(index, item: NodeTemplateModel) {
    return item.id;
  }
}
