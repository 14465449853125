import { createAction, props } from '@ngrx/store';
import { WidgetType } from '../../core/constants/widget-type';
import { NodeTemplateWidgetModel } from '../../core/models/node-template-widget.model';
import { CalculationMethodType } from '../../core/constants/calculation-method.type';
import { WidgetModel } from '../../core/models/widget.model';

export const addTemplateWidgetsRequest = createAction(
  '[Template Widgets] add template widgets request',
  props<{ templateId: number; widgetIds: number[]; groupId: number; replace: boolean }>(),
);

export const addTemplateWidgetsSuccess = createAction(
  '[Template Widgets] add template widgets success',
  props<{ templateWidgets: NodeTemplateWidgetModel[]; groupId: number; replace: boolean }>(),
);

export const removeTemplateWidgetRequest = createAction(
  '[Template Widgets] remove template widget request',
  props<{ templateId: number; joinId: number }>(),
);

export const removeTemplateWidgetsByWidgetId = createAction(
  '[Template Widgets] remove template widgets by widget id request',
  props<{ widgetId: number }>(),
);

export const sortTemplateWidgetsRequest = createAction(
  '[Template Widgets] sort template widgets request',
  props<{ templateId: number; joinIds: number[] }>(),
);

export const clearTemplateWidgetsRequest = createAction(
  '[Template Widgets] clear template widgets request',
  props<{ templateId: number }>(),
);

export const createAndAddAssignmentChecklistWidgetRequest = createAction(
  '[Template Widgets] create and add assignment checklist widget request',
  props<{
    title: string;
    widgetType: WidgetType;
    groupId: number;
    choices?: string[];
  }>(),
);

export const createAndAddAssignmentChecklistWidgetSuccess = createAction(
  '[Template Widgets] create and add assignment checklist widget success',
  props<{ templateWidget: NodeTemplateWidgetModel; widget: WidgetModel }>(),
);

export const createAndAddCalculationWidgetRequest = createAction(
  '[Template Widgets] create and add calculation widget request',
  props<{
    templateId: number;
    title: string;
    widgetATitle: string;
    widgetAType: WidgetType;
    widgetBTitle: string;
    widgetBType: WidgetType;
    calculationMethod: CalculationMethodType;
    sortIndex: number;
  }>(),
);

export const removeTemplateGroupWidgetAndWidgetItselfRequest = createAction(
  '[Template Widgets] remove template group widget and widget itself request',
  props<{ groupId: number; widgetId: number }>(),
);
