import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-btn-radio',
  templateUrl: './btn-radio.component.html',
  styleUrls: ['./btn-radio.component.scss'],
})
export class BtnRadioComponent implements OnInit {
  @Input() id: string;
  @Input() isChecked: boolean;
  @Output() toggleSelectionEvent = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  toggle() {
    this.toggleSelectionEvent.emit();
  }
}
