import { AppState } from '../index';
import { createSelector } from '@ngrx/store';
import { selectCurrentWorkspace } from '../workspaces/workspaces.selectors';
import { convertToChannelViewModel } from './chats.util';
import { selectCurrentWorkspaceUsersById } from '../workspace-users/workspace-users.selectors';
import { ChannelType } from '../../core/constants/channel-type';
import { ChatChannelModel } from '../../core/models/chat-channel.model';
import { NodesSelectors } from '../nodes';

const selectChatsState = (state: AppState) => state.chats;

export const selectChatChannelsById = createSelector(selectChatsState, state => state.channelsById);

export const selectChatChanelCommentsById = createSelector(
  selectChatsState,
  state => state.channelCommentsById,
);

export const selectChatCommentIdsByChannelId = createSelector(
  selectChatsState,
  state => state.commentIdsByChannelId,
);

export const selectChatChannelIds = createSelector(selectChatsState, state => state.channelIds);

export const selectChatChannels = createSelector(
  selectChatChannelIds,
  selectChatChannelsById,
  (ids, byId): ChatChannelModel[] => {
    return ids.map(id => byId[id]).filter(channel => !!channel);
  },
);

export const selectChatChannelViewModels = createSelector(
  selectChatChannels,
  NodesSelectors.selectNodesById,
  selectCurrentWorkspace,
  selectCurrentWorkspaceUsersById,
  (channels, nodesById, currentWorkspace, usersById) => {
    return channels.map(channel => {
      return convertToChannelViewModel(channel, nodesById, currentWorkspace, usersById);
    });
  },
);

export const selectChatActiveChannelNodeIdSet = createSelector(
  selectChatChannelIds,
  selectChatChannelsById,
  (ids, byId) => {
    const nodeIdSet = new Set();

    ids.forEach(id => {
      const channel = byId?.[id];
      if (channel?.channelType === ChannelType.node && channel?.commentCount > 0) {
        nodeIdSet.add(`${channel.nodeId}`);
      }
    });

    return nodeIdSet;
  },
);

export const selectChatChannelTotalUnreadCount = createSelector(selectChatChannels, channels => {
  return channels.reduce((total, channel) => {
    return total + channel.unreadComments;
  }, 0);
});
