import {
  SideBarMode,
  SideBarPosition,
  SideBarSize,
} from '../../shared/components/_layout/sidebar/sidebar.component';
import { AssignmentLayoutMode } from '../../core/constants/assignment-layout-mode';
import { AssignmentDisplayMode } from '../../core/constants/assignment-display-mode';

export interface ISideBar {
  disable?: boolean;
  collapsed?: boolean;
  fullCollapse?: boolean;
  mode?: SideBarMode;
  position?: SideBarPosition;
  size?: SideBarSize;
}

export class SideBar implements ISideBar {
  disable: boolean;
  collapsed: boolean;
  fullCollapse: boolean;
  mode: SideBarMode;
  position: SideBarPosition;
  size: SideBarSize;
  constructor() {
    this.disable = false;
    this.collapsed = false;
    this.fullCollapse = true;
    this.mode = null;
    this.position = SideBarPosition.Left;
    this.size = SideBarSize.Medium;
  }
}

export interface IWorkspaceContent {
  mode?: AssignmentLayoutMode;
  subMode?: AssignmentDisplayMode;
  calendarOpen?: boolean;
}

export class AppLayoutState {
  sidebarLeft: ISideBar;
  sidebarSubLeft: ISideBar;
  sidebarRight: ISideBar;
  sidebarSubRight: ISideBar;
  workspaceContent: IWorkspaceContent;
  workspaceCalendarOpen: boolean;
}

export const initialAppLayoutState: AppLayoutState = {
  sidebarLeft: {
    position: SideBarPosition.Left,
    size: SideBarSize.Medium,
    disable: false,
    collapsed: false,
    fullCollapse: false,
    mode: SideBarMode.FolderList,
  },
  sidebarSubLeft: {
    position: SideBarPosition.Left,
    size: SideBarSize.Large,
    disable: false,
    collapsed: true,
    fullCollapse: true,
    mode: SideBarMode.NodeList,
  },
  sidebarRight: {
    position: SideBarPosition.Right,
    size: SideBarSize.Large,
    disable: false,
    collapsed: true,
    fullCollapse: true,
    mode: SideBarMode.MainMenu,
  },
  sidebarSubRight: {
    position: SideBarPosition.Right,
    size: SideBarSize.Large,
    disable: true,
    collapsed: true,
    fullCollapse: true,
    mode: null,
  },
  workspaceContent: {
    mode: AssignmentLayoutMode.schedule,
    subMode: AssignmentDisplayMode.Calender,
    calendarOpen: false,
  },
  workspaceCalendarOpen: false,
};
