import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ColorType } from '../../../core/constants/color-type';

@Component({
  selector: 'app-selectable-input',
  templateUrl: './selectable-input.component.html',
  styleUrls: ['./selectable-input.component.scss'],
})
export class SelectableInputComponent implements OnInit {
  @Input() type: 'checkbox' | 'radio' = 'checkbox';
  @Input() id: string;
  @Input() colorTheme: ColorType;
  @Input() checked: boolean;
  @Input() readonly: boolean;
  @Output() valueChange = new EventEmitter<boolean>();

  @ViewChild('input') input;

  constructor() {}

  ngOnInit() {}

  change(event) {
    this.valueChange.emit(true);
  }
}
