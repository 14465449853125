import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
} from '@angular/core';
import { LazyScrollerService } from './lazy-scroller.service';

@Directive({
  selector: '[appLazyScroller]',
})
export class LazyScrollerDirective implements AfterViewInit, OnDestroy {
  @Input('appLazyScroller')
  public scopeName: string;

  @HostListener('scroll', ['$event'])
  onScroll($event) {
    this.lazyScrollerService.update(this.scopeName);
  }

  @HostListener('resize', ['$event'])
  onResize($event) {
    this.lazyScrollerService.update(this.scopeName);
  }

  constructor(private lazyScrollerService: LazyScrollerService, private elementRef: ElementRef) {}

  public ngAfterViewInit(): void {
    this.lazyScrollerService.set(this.scopeName, this.elementRef);
  }

  public ngOnDestroy(): void {
    this.lazyScrollerService.unset(this.scopeName);
  }
}
