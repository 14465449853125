<ng-template #modalFooter>
  <button
    ngbAutofocus
    type="button"
    class="btn btn-sm btn-outline-light"
    aria-label="Close"
    (click)="activeModal.dismiss(false)"
  >
    Cancel
  </button>

  <button
    type="button"
    class="btn btn-sm btn-primary"
    aria-label="Close"
    (click)="onSubmit()"
    [disabled]="layoutTemplateAddForm?.invalid"
  >
    Save
  </button>
</ng-template>

<app-modal [title]="modalTitle" [footerTemplate]="modalFooter">
  <form [formGroup]="layoutTemplateAddForm">
    <div class="d-flex">
      <!-- icon -->
      <app-panel-subsection
        class="properties-panel__subsection flex-grow-0"
        title="Icon"
        [backgroundTransparent]="true"
        [noVerticalPadding]="true"
      >
        <div class="properties-panel__fields">
          <div class="form-group">
            <button
              type="button"
              class="picker-button btn"
              [ngClass]="'picker-button--icon-' + (f?.icon?.value | iconString)"
              (click)="onIconPicker()"
            >
              <span class="fal" [ngClass]="'fa-' + (f?.icon?.value | iconString)"></span>
            </button>
          </div>
        </div>
      </app-panel-subsection>

      <!-- title -->
      <app-panel-subsection
        class="properties-panel__subsection"
        title="Title"
        [backgroundTransparent]="true"
        [noVerticalPadding]="true"
      >
        <input
          #titleInput
          type="text"
          class="form-control ap-form-field-width-sm"
          id="title"
          placeholder="Title"
          required
          formControlName="title"
          [ngClass]="f?.title?.invalid && f?.title?.dirty ? 'is-invalid' : ''"
        />
        <div class="invalid-feedback">
          Please provide a title
        </div>
      </app-panel-subsection>
    </div>

    <!-- description -->
    <app-panel-subsection
      class="properties-panel__subsection"
      title="Description"
      [backgroundTransparent]="true"
      [noVerticalPadding]="true"
    >
      <textarea
        cols="30"
        rows="5"
        #descriptionInput
        type="text"
        class="form-control w-100"
        id="template-assignment-layout-edit-description"
        placeholder="Description"
        required
        formControlName="description"
        [ngClass]="f?.description?.invalid && f?.description?.dirty ? 'is-invalid' : ''"
      >
      </textarea>

      <div class="invalid-feedback">
        Please provide a description
      </div>
    </app-panel-subsection>

    <!-- thumbnail -->
    <app-panel-subsection title="Template Thumbnail">
      <button
        type="button"
        class="picker-button picker-button--thumb btn"
        [ngClass]="{ 'picker-button--thumb-not-available': !objectUrl }"
        [ngStyle]="{ 'background-image': 'url(' + objectUrl + ')' }"
        (click)="fileUpload.click()"
      ></button>

      <input
        type="file"
        class="file-attachment__input d-none"
        [accept]="fileTypes"
        (change)="imagePreview($event)"
        #fileUpload
      />
    </app-panel-subsection>
  </form>
</app-modal>
