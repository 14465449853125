export enum RateType {
  Income = 1,
  Expense = 2,
  Contract = 3,
  Variation = 4,
  Rates = 5,
  Budget = 6,
  Target = 7,
}

export const RateTypeOptions = [
  RateType.Rates,
  RateType.Contract,
  RateType.Variation,
];
