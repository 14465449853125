import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store';
import { Router } from '@angular/router';

@Component({
  selector: 'app-node-templates',
  templateUrl: 'node-templates.component.html',
  styleUrls: ['node-templates.component.scss'],
})
export class NodeTemplatesComponent implements OnDestroy {
  private readonly subscription: Subscription = new Subscription();

  constructor(private router: Router, private store: Store<AppState>) {}

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
