import { createAction, props } from '@ngrx/store';

export const showRateCreatePage = createAction(
  '[Rate Edit Popover] show rate creat page',
  props<{ nodeId: number }>(),
);

export const showRateEditPage = createAction(
  '[Rate Edit Popover] show rate edit page',
  props<{ nodeId: number; rateId: number }>(),
);
