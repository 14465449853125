import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { WorkspaceUsersService } from '../../core/services/workspace-users.service';
import { catchError, concatMap, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { ErrorsActions } from '../errors';
import { WorkspaceUsersActions } from './index';
import { select, Store } from '@ngrx/store';
import { selectWorkspacesCurrentSlug } from '../workspaces/workspaces.selectors';
import { AlertService } from '../../shared/components/alert/alert.service';
import { WorkspaceUserModel } from '../../core/models/workspace-user.model';

@Injectable()
export class WorkspaceUsersEffects {
  constructor(
    private actions$: Actions,
    private workspaceUsersService: WorkspaceUsersService,
    private store: Store,
    private alertService: AlertService,
  ) {}

  loadCurrentWorkspaceUsersRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceUsersActions.loadCurrentWorkspaceUsersRequest),
      mergeMap(action =>
        this.workspaceUsersService.loadWorkspaceUsersBySlug(action.workspaceSlug).pipe(
          map(users =>
            WorkspaceUsersActions.loadCurrentWorkspaceUsersSuccess({
              users,
            }),
          ),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  inviteWorkspaceUserRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceUsersActions.inviteWorkspaceUserRequest),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      mergeMap(([action, slug]) =>
        this.workspaceUsersService.inviteWorkspaceUser(slug, action.email).pipe(
          map((user: WorkspaceUserModel) => {
            this.alertService.success(
              `We've sent you an email invitation to ${user.email} of this user.`,
            );
            return WorkspaceUsersActions.inviteWorkspaceUserSuccess({ user });
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  deleteWorkspaceUserRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceUsersActions.deleteWorkspaceUserRequest),
      mergeMap(action =>
        this.workspaceUsersService.deleteWorkspaceUser(action.id).pipe(
          switchMap(() => EMPTY),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );
  patchWorkspaceUserRoleRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceUsersActions.patchWorkspaceUserRoleRequest),
      mergeMap(action =>
        this.workspaceUsersService.patchWorkspaceUserRole(action.id, action.role).pipe(
          map(user => {
            return WorkspaceUsersActions.patchWorkspaceUserRoleSuccess({ user });
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  updateWorkspaceUserBelongedGroupsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceUsersActions.updateWorkspaceUserBelongedGroupsRequest),
      mergeMap(action =>
        this.workspaceUsersService.updateUserBelongedGroups(action.userId, action.groupIds).pipe(
          map(user => {
            return WorkspaceUsersActions.updateWorkspaceUserBelongedGroupsSuccess({ user });
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );
}
