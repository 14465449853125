import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import {
  LazyScrollerElementComponent,
  LazyScrollerOrientation,
} from './lazy-scroller-element.component';

@Directive({
  selector: '[appLazyScrollerElement]',
})
export class LazyScrollerElementDirective implements OnInit, OnChanges, OnDestroy {
  @Input('appLazyScrollerElement')
  public scopeName: string;

  @Input('appLazyScrollerElementOrientation')
  public orientation: LazyScrollerOrientation | string;

  private lazyScrollerElementComponent: ComponentRef<LazyScrollerElementComponent>;

  constructor(
    private templateRef: TemplateRef<any>,
    private container: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
  ) {}

  public ngOnInit(): void {
    const containerFactory = this.componentFactoryResolver.resolveComponentFactory(
      LazyScrollerElementComponent,
    );
    this.lazyScrollerElementComponent = this.container.createComponent(containerFactory);
    this.lazyScrollerElementComponent.instance.templateRef = this.templateRef;
    this.lazyScrollerElementComponent.instance.scope = this.scopeName;
    this.lazyScrollerElementComponent.instance.orientation = this.orientation;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.lazyScrollerElementComponent && this.lazyScrollerElementComponent.instance) {
      this.lazyScrollerElementComponent.instance.templateRef = this.templateRef;
      this.lazyScrollerElementComponent.instance.scope = this.scopeName;
      this.lazyScrollerElementComponent.instance.orientation = this.orientation;
      this.lazyScrollerElementComponent.instance.ngOnChanges(changes);
    }
  }

  public ngOnDestroy(): void {
    this.lazyScrollerElementComponent.destroy();
  }
}
