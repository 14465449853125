import { createAction, props } from '@ngrx/store';
import { WidgetEditableProps, WidgetModel } from '../../core/models/widget.model';
import { WidgetType } from '../../core/constants/widget-type';
import { NodeType } from '../../core/constants/node-type';

export const loadWidgetsRequest = createAction(
  '[Widgets] load widgets request',
  props<{ workspaceSlug: string }>(),
);
export const loadWidgetsSuccess = createAction(
  '[Widgets] load widgets success',
  props<{ widgets: WidgetModel[] }>(),
);

export const addWidgetRequest = createAction(
  '[Widgets] add widget request',
  props<{
    title: string;
    widgetType: WidgetType;
    nodeType?: NodeType;
    choices?: string[];
  }>(),
);

export const addWidgetSuccess = createAction(
  '[Widgets] add widget success',
  props<{ widget: WidgetModel }>(),
);

export const addWidgetsSuccess = createAction(
  '[Widgets] add widgets success',
  props<{ widgets: WidgetModel[] }>(),
);

export const updateWidgetRequest = createAction(
  '[Widgets] update widget request',
  props<{ widgetId: number; widgetProps: Partial<WidgetEditableProps> }>(),
);

export const deleteWidgetRequest = createAction(
  '[Widgets] delete widget request',
  props<{ widgetId: number }>(),
);

export const updateSearchKeywordRequest = createAction(
  '[Widgets] update search keyword request',
  props<{ searchKeyword: string }>(),
);
