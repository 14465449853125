<div
  class="z-table__editor-workspace-group"
  [ngClass]="{ 'z-table__editor--empty': editable && !cell?.value }"
>
  <ng-select
    class="table-editor"
    #ngSelect
    [disabled]="!editable"
    [clearable]="false"
    [items]="options.list"
    [(ngModel)]="cell.value"
    bindLabel="label"
    bindValue="value"
    appendTo="body"
    [placeholder]="editable ? 'Select ' + column.label : 'Empty'"
    (clear)="onStartEdit()"
    (open)="onStartEdit()"
    (focus)="onStartEdit()"
    (blur)="onEndEdit()"
  >
    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
      <div class="workspace-group__option">
        <div class="workspace-group__label">{{ item.label }}</div>
        <div class="workspace-group__edit">
          <button type="button" class="btn btn-link" (click)="onEditGroup($event, item)">
            <i class="fal fa-edit"></i>
          </button>
        </div>
      </div>
    </ng-template>
    <ng-template ng-footer-tmp>
      <div class="workspace-group__new">
        <button type="button" class="btn btn-link" (click)="onAddGroup($event)">
          <i class="fal fa-plus"></i> Add
        </button>
      </div>
    </ng-template>
  </ng-select>
</div>
