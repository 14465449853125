import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { dateFormatMap, DateFormatType } from '../../core/constants/date-format.constants';

@Pipe({
  name: 'momentDate',
})
export class MomentDatePipe implements PipeTransform {
  transform(value: moment.Moment | Date | string, dateFormat: DateFormatType): string {
    return momentDateFormat(value, dateFormat);
  }
}

export const momentDateFormat = (value: moment.Moment | Date | string, dateFormat: string) => {
  const format = dateFormatMap[dateFormat] || dateFormat;

  if (dateFormat === 'timeFull' || dateFormat === 'time') {
    return moment(value, format).format(format);
  }
  return moment(value).format(format);
};
