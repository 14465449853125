<div
  class="chat-channel-item"
  [ngClass]="{
    'chat-channel-item--active': activeChannel?.nodeId === channel.nodeId,
    'chat-channel-item--unread': false
  }"
>
  <!-- Flexbox container -->
  <div class="flex-container">
    <!-- Column 1: Avatar -->
    <div class="avatar-column mr-2">
      <app-comment-user-avatar
        (click)="$event.stopPropagation()"
        [user]="channel?.editedBy"
        [editedDate]="channel?.editedDate"
        [avatarSize]="'lg'"
      >
      </app-comment-user-avatar>
    </div>

    <!-- Column 2: Remaining content -->
    <div class="content-column">
      <div (click)="openChannel()">
        <div class="chat-channel-item__header">
          <div class="chat-channel-item__name">
            {{ channel | chatChannelTitle }}
          </div>
          <div
            class="chat-channel-item__count badge"
            [ngClass]="[
              channel.unreadComments ? 'badge--inverted-primary' : 'badge--inverted-secondary'
            ]"
          >
            {{ channel?.unreadComments || 0 }} / {{ channel?.commentCount || 0 }}
          </div>
        </div>
        <div class="chat-channel-item__footer">
          <div class="chat-channel-item__type text-truncate">
            {{ channel | chatChannelTypeLabel }}
          </div>
          <div class="chat-channel-item__footer-right">
            <div class="chat-channel-item__edited-time layout-mr">
              {{ channel.editedDate | niceDate: 'day-only' }}
            </div>
          </div>
        </div>
      </div>
      <!-- channel actions -->
      <span
        aria-label="Channel actions"
        class="chat-channel-item__actions"
        role="button"
        [ngbPopover]="actionPopUp"
        triggers="click contextmenu"
        [autoClose]="true"
        container="body"
        placement="left right"
      >
        <i class="fal fa-ellipsis-vertical"></i>
      </span>
      <ng-template #actionPopUp>
        <ul class="popover-menu">
          <li class="popover-menu__item" (click)="deleteChannel()">
            <span>Delete channel</span>
          </li>
        </ul>
      </ng-template>
    </div>
  </div>
</div>
