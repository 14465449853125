<div class="main-menu">
  <app-sidebar-header class="main-menu__header" [title]="'Main Menu'"></app-sidebar-header>

  <div class="main-menu__body">
    <nav class="sidebar-nav">
      <h3 class="sidebar-nav__title">Workspace Views</h3>
      <ul class="sidebar-nav__menu list-unstyled">
        <li>
          <a class="link__item" routerLink="/" (click)="closeMenu()">
            <i class="fal fa-grip-horizontal mr-2"></i> Dashboard
          </a>
        </li>
        <li>
          <a
            class="link__item"
            [routerLink]="['/ws', workspace?.slug, 'content', 'schedule', 'calender']"
            routerLinkActive="link__item--active"
            (click)="closeMenu()"
          >
            <i class="fal fa-calendar-days mr-2"></i> Schedule
          </a>
        </li>
        <li>
          <a
            class="link__item"
            [routerLink]="['/ws', workspace?.slug, 'content', 'plan-board', 'calender']"
            routerLinkActive="link__item--active"
            (click)="closeMenu()"
          >
            <i class="fal fa-game-board-simple mr-2"></i> Plan Board
          </a>
        </li>
        <li>
          <a
            class="link__item"
            [routerLink]="['/ws', workspace?.slug, 'content', 'pricing', 'timesheets']"
            routerLinkActive="link__item--active"
            (click)="closeMenu()"
          >
            <i class="fal fa-coins mr-2"></i> Financials
          </a>
        </li>
        <li>
          <a
            class="link__item"
            [routerLink]="['/ws', workspace?.slug, 'content', 'database', 'tags']"
            routerLinkActive="link__item--active"
            (click)="closeMenu()"
          >
            <i class="fal fa-database mr-2"></i> Database
          </a>
        </li>

        <li>
          <a class="link__item" routerLinkActive="link__item--active">
            <i class="fal fa-chart-gantt mr-2"></i>
            Gantt Chart
            <span class="position-relative translate-middle badge rounded-pill badge-warning"
            >Coming Soon</span
            >
          </a>
        </li>

        <!-- <div ngbDropdown class="d-inline-block layout-mr-lg">
          <button
            class="btn btn-outline-link"
            [routerLink]="['ws', currentWorkspace.slug, 'content', 'database', 'tags']"
          >
            <i class="fal fa-database d-none d-md-inline-block layout-mr-md"></i>
            Database
          </button>
        </div> -->
        <!--
        <div ngbDropdown class="d-inline-block">
          <button class="btn btn-outline-link" disabled>
            <i class="fal fa-chart-gantt d-none d-md-inline-block layout-mr-md"></i>
            Gantt Chart
            <span class="position-relative translate-middle badge rounded-pill badge-warning"
              >Coming Soon</span
            >
          </button>
        </div> -->
      </ul>

      <ng-container *ngIf="user | can: PermissionType.workspaceEditor">
        <h3 class="sidebar-nav__title">Workspace Settings</h3>
        <ul class="sidebar-nav__menu list-unstyled">
          <li *ngIf="user | can: PermissionType.workspaceEditor">
            <a
              class="link__item"
              [routerLink]="['/ws', workspace?.slug, 'settings', 'node-templates']"
              routerLinkActive="link__item--active"
              (click)="closeMenu()"
            >
              <i class="fal fa-layer-group mr-2"></i> Templates
            </a>
          </li>

          <li *ngIf="user | can: PermissionType.workspaceEditor">
            <a
              class="link__item"
              [routerLink]="['/ws', workspace?.slug, 'settings', 'tags']"
              routerLinkActive="link__item--active"
              (click)="closeMenu()"
            >
              <i class="fal fa-tags mr-2"></i> Tags
            </a>
          </li>

          <li *ngIf="user | can: PermissionType.workspaceEditor">
            <a
              class="link__item"
              [routerLink]="['/ws', workspace?.slug, 'settings', 'links']"
              routerLinkActive="link__item--active"
              (click)="closeMenu()"
            >
              <i class="fal fa-link mr-2"></i> Links
            </a>
          </li>

          <li *ngIf="user | can: PermissionType.workspaceEditor">
            <a
              class="link__item"
              [routerLink]="['/ws', workspace?.slug, 'settings', 'archives']"
              routerLinkActive="link__item--active"
              (click)="closeMenu()"
            >
              <i class="fal fa-archive mr-2"></i> Archive
            </a>
          </li>

          <li *ngIf="user | can: PermissionType.workspaceEditor">
            <a
              class="link__item"
              [routerLink]="['/ws', workspace?.slug, 'settings', 'users']"
              routerLinkActive="link__item--active"
              (click)="closeMenu()"
            >
              <i class="fal fa-user mr-2"></i> Users
            </a>
          </li>

          <!--<li *ngIf="user | can: PermissionType.workspaceEditor">
            <a class="link__item" [routerLink]="['/ws', workspace.slug, 'settings', 'user-groups']"
               routerLinkActive="link__item--active"
               (click)="closeMenu()">
              <i class="fal fa-users mr-2"></i> User Groups
            </a>
          </li>-->
        </ul>
      </ng-container>

      <h3 class="sidebar-nav__title">Recent Workspaces</h3>
      <ul class="sidebar-nav__menu list-unstyled">
        <li *ngFor="let workspace of recentWorkspaces">
          <a class="link__item" [routerLink]="['/ws', workspace.slug]" (click)="closeMenu()">
            {{ workspace.title }}
          </a>
        </li>
      </ul>
    </nav>
  </div>

  <app-sidebar-footer class="main-menu__footer">
    <app-btn-fa iconName="user" (click)="myAccount($event)">My account</app-btn-fa>
    <!-- <app-btn-fa iconName="sign-out-alt" (click)="logout($event)">Logout</app-btn-fa>-->
  </app-sidebar-footer>
</div>
