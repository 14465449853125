import { createAction, props } from '@ngrx/store';
import { NodeModel } from '../../core/models/node.model';

export const openTemplatesPopover = createAction(
  '[Templates Popover] open templates popover',
  props<{ node: NodeModel }>(),
);

export const selectTemplatePopoverTemplate = createAction(
  '[Templates Popover] select template',
  props<{ templateId: number }>(),
);

export const setTemplatePopoverTemplateChanged = createAction(
  '[Templates Popover] set template changed true',
);
