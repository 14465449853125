import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstWord',
})
export class FirstWordPipe implements PipeTransform {
  transform(str: string): string {
    if (str) {
      const firstWord = str.trim().split(' ')[0];
      return firstWord;
    } else {
      return null;
    }
  }
}
