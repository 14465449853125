<div class="root-folder-list">
  <ng-template #header>
    <app-sidebar-profile [isCollapsed]="!isSidebarOpen" [user]="user"></app-sidebar-profile>
  </ng-template>

  <app-sidebar-header class="root-folder-list__header" [template]="header"></app-sidebar-header>
  <div class="root-folder-list__body" cdkScrollable>
    <app-folder-tree-root></app-folder-tree-root>
  </div>

  <app-sidebar-footer class="root-folder-list__footer">
    <!--<app-btn-fa
      *ngIf="user | can: PermissionType.workspaceEditor"
      iconName="cog"
      (click)="openSettings($event)"
    >Settings
    </app-btn-fa>-->
    <app-btn-fa iconName="sign-out-alt" (click)="logout($event)">Logout</app-btn-fa>
  </app-sidebar-footer>

  <app-content-loader scope="root-folder-list"></app-content-loader>
</div>
