<app-inline-edit-input-layout [displayTemplate]="displayTemplate || defaultDisplayTemplate"
                              [editTemplate]="editTemplate"
                              [confirmBtnLabel]="confirmBtnLabel" [saveBtnDisabled]="!_currentValue"
                              (cancelEvent)="onCancelValue()"
                              (saveEvent)="onSaveValue()"
                              (clickOutsideDuringEdit)="onClickOutside()"
>
</app-inline-edit-input-layout>

<ng-template #defaultDisplayTemplate>
  <ng-container *ngIf="!truncateLimit">
    {{ _currentValue }}
  </ng-container>
  <ng-container *ngIf="truncateLimit">
    <app-truncate-text [value]="_currentValue" [limit]="truncateLimit"></app-truncate-text>
  </ng-container>

</ng-template>

<ng-template #editTemplate>
  <input
    [(ngModel)]="_currentValue"
    class="inline-edit-input__input inline-edit-input__input--input form-control "
    [ngClass]="[inputType === WidgetType.number ? 'text-right' : '' ]"
    appAutoFocus
    [type]="inputType | widgetInputType"
    (keydown.enter)="onSaveValue()"
    (keydown.escape)="onCancelValue()"
  />
</ng-template>


