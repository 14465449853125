import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toInitials',
})
// Extract initials (max three letters) from a name
export class ToInitialsPipe implements PipeTransform {
  transform(name: string): string {
    return getInitials(name);
  }
}

const getInitials = (name: string, maxLength = 3) => {
  if (!name) {
    return '';
  }
  const parts = name.split(' ');
  let initials = '';
  let initialsLength = 0;
  for (let i = 0; i < parts.length; i++) {
    if (parts[i].length > 0 && parts[i] !== '') {
      initials += parts[i][0];
      initialsLength++;
      if (initialsLength >= maxLength) {
        break;
      }
    }
  }
  return initials;
};
