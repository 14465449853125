<div
  #input
  class="z-table__input z-table__input--assignment-elements"
  [ngClass]="[
    editable == false ? 'z-table__input--disabled' : '',
    cell.value?.length === 0 ? 'z-table__input--empty' : ''
  ]"
>
  <ng-container *ngFor="let asset of cell.value | slice: 0:MAX_DISPLAY; trackBy: trackBy">
    <ng-container [ngSwitch]="asset.reference?.nodeType">
      <div
        *ngSwitchCase="
          asset.reference?.nodeType === NodeType.asset ||
          asset.reference?.nodeType === NodeType.element
            ? asset.reference?.nodeType
            : ''
        "
        class="assignment-asset"
      >
        <app-node-avatar
          class="assignment-asset__avatar"
          [profile]="asset.reference?.profile"
          [profileIcon]="asset.reference?.nodeMeta?.profileIcon"
          [fullName]="asset.reference?.title"
          [initials]="asset.reference?.title | toInitials"
          size="sm"
          [colorTheme]="asset.reference.colorTheme"
          [revertColor]="false"
          [hideTooltip]="true"
          [ngbPopover]="popContent"
        ></app-node-avatar>
      </div>
      <div *ngSwitchDefault class="assignment-element">
        {{ asset.reference?.title }}
      </div>
    </ng-container>
  </ng-container>

  <div class="assignment-elements__hidden" *ngIf="cell.value?.length > MAX_DISPLAY">
    <i class="fal fa-plus"></i> {{ cell.value.length - MAX_DISPLAY }}
  </div>

  <span class="assignment-elements__empty-text" *ngIf="cell.value?.length === 0">
    {{ 'Empty' }}
  </span>
</div>

<ng-template #popContent>
  <app-node-avatar-menu
    [profile]="cell?.options?.node?.profile"
    [nodeId]="cell?.options?.node?.id"
    [profileIcon]="cell?.options?.node?.nodeMeta?.profileIcon"
  ></app-node-avatar-menu>
</ng-template>
