import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { ChatChannelViewModel } from '../../../core/models/chat-channel-view.model';
import {
  selectChatbarCurrentChannelViewModel,
  selectChatbarPage,
} from '../../../store/chatbar/chatbar.selectors';
import { ChatbarPage } from '../../../core/constants/chatbar-page';
import { ChatbarActions } from '../../../store/chatbar';

@Component({
  selector: 'app-chatbar',
  templateUrl: './chatbar.component.html',
  styleUrls: ['./chatbar.component.scss'],
})
export class ChatbarComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  public readonly ChatbarPage = ChatbarPage;

  channel: ChatChannelViewModel;
  page: ChatbarPage;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.subscription.add(
      this.store.pipe(select(selectChatbarCurrentChannelViewModel)).subscribe(channel => {
        this.channel = channel;
      }),
    );

    this.subscription.add(
      this.store.pipe(select(selectChatbarPage)).subscribe(page => {
        this.page = page;
      }),
    );
  }

  goToAllChannels() {
    this.store.dispatch(ChatbarActions.goToWorkspaceAllChannelsPageRequest());
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
