// gray is the default theme color
export enum ColorType {
  'not-available' = 0,
  // blue = 1, // starting from 1 instead of default 0
  // green,
  // gold,
  // red,
  // purple,
  // cyan,
  // teal,
  // gray,
  // yellow,
  // orange,
  // 'extra-red1',
  // 'extra-red2',
  // 'extra-purple1',
  // 'extra-purple2',
  // 'extra-blue1',
  // 'extra-blue2',
  // 'extra-gold1',
  // 'extra-gold2',
  // 'extra-gold3',
  // 'extra-dark1',
  // 'extra-gray1',
  // 'river-bed',
  'color-49546a',
  'color-ff4a75',
  'color-6f41c1',
  'color-007bff',
  'color-eb842d',
  'color-39b49b',
  'color-708090',
  'color-c62f3e',
  'color-856088',
  'color-2072af',
  'color-de7c67',
  'color-70a951',
  'color-0f1f40',
  'color-d471ac',
  'color-9e71bd',
  'color-51acca',
  'color-e8b717',
  'color-79d18a',
  'color-848484',
  'color-c13ea5',
  'color-c392e0',
  'color-67c9e3',
  'color-f1dc4d',
}

export const colorTypeKeys: string[] = Object.keys(ColorType).filter(
  k => typeof ColorType[k as any] === 'number',
);
