import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { IconType } from '../../../core/constants/icon-type';
import { SizeType } from '../../../core/constants/size-type';

@Component({
  selector: '[app-icon-button]',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
})
export class IconButtonComponent implements OnInit {
  @Input() icon: IconType;
  @Input() iconSize?: SizeType;
  @Input() reverse: boolean;

  @HostBinding('class.icon-button--reverse') get isReverse() {
    return this.reverse;
  }

  constructor() {}

  ngOnInit(): void {}
}
