import { Action, createReducer, on } from '@ngrx/store';
import {
  initialTemplateFiguresPopoverState,
  TemplateFiguresPopoverState,
} from './template-figures-popover.state';
import { TemplateFiguresPopoverActions } from './index';
import { TemplateFiguresPopoverPage } from '../../core/constants/template-figures-popover-page';

const reducer = createReducer(
  initialTemplateFiguresPopoverState,
  on(
    TemplateFiguresPopoverActions.showTemplateFiguresCalculationCreatePage,
    (state, { templateId }) => {
      return {
        page: TemplateFiguresPopoverPage.calculationCreate,
        templateId,
      };
    },
  ),
  on(
    TemplateFiguresPopoverActions.showTemplateFiguresCalculationWidgetEditPage,
    (state, { widgetId }) => {
      return {
        page: TemplateFiguresPopoverPage.calculationWidgetEdit,
        widgetId,
      };
    },
  ),
);

export function templatefiguresPopoverReducer(state: TemplateFiguresPopoverState, action: Action) {
  return reducer(state, action);
}
