<ng-template #modalHeader>
  <h4 class="modal-title">
    {{ title }}
  </h4>
  <button *ngIf="!noCloseBtn" type="button" class="close" aria-label="Close" (click)="activeModal.dismiss(false)">
    <span aria-hidden="true">&times;</span>
  </button>
</ng-template>

<ng-template #modalFooter>
  <button ngbAutofocus class="btn btn-sm btn-outline-light" type="button" (click)="no()">
    {{ noText }}
  </button>

  <button
    class="btn btn-sm btn-primary"
    [ngClass]="{
         'btn-primary': variant == null,
         'btn-info': variant === ModalTheme.Info,
         'btn-success': variant === ModalTheme.Success,
         'btn-warning': variant === ModalTheme.Warning,
         'btn-danger': variant === ModalTheme.Danger
       }"
    type="button"
    (click)="yes()"
  >
    {{ yesText }}
  </button>
</ng-template>

<app-modal [headerTemplate]="modalHeader" [footerTemplate]="modalFooter" [theme]="variant">
  <div class="confirm-message">
    <p>{{ message }}</p>
    <ng-content></ng-content>
    <p><strong *ngIf="warningMessage">{{ warningMessage }}</strong></p>
  </div>
</app-modal>
