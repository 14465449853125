import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { FilePreview } from '../../../../../shared/components/file-attachment/file-attachment.component';
import { ChatChannelCommentViewModel } from '../../../../../core/models/chat-channel-comment-view.model';
import { ChatActions } from '../../../../../core/constants/chatbar-page';
import { FileModel } from '../../../../../core/models/file.model';

@Component({
  selector: 'app-chat-comment-new',
  templateUrl: './chat-comment-new.component.html',
  styleUrls: ['./chat-comment-new.component.scss'],
})
export class ChatCommentNewComponent implements OnInit, OnChanges {
  @ViewChild(CdkTextareaAutosize) autosize: CdkTextareaAutosize;
  // @Output() addCommentEvent = new EventEmitter<string>();
  // @Output() addAttachmentEvent = new EventEmitter<{ file: File; comment: string }>();

  @Input() selectedCommentForEdit: ChatChannelCommentViewModel;
  @Output() addCommentEvent = new EventEmitter<{
    comment: string;
    action: ChatActions;
  }>();
  @Output() addAttachmentEvent = new EventEmitter<{
    file: File;
    comment: string;
    action: ChatActions;
  }>();

  filePreview: FilePreview;

  public value: any = '';

  constructor() {}

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes?.selectedCommentForEdit) {
      this.value = this.selectedCommentForEdit?.comment;
      if (this.selectedCommentForEdit?.uploads?.length) {
        this.setAttachmentForEdit();
        // try {
        //   const fileCreated: File = await this.createFile();
        //   if (fileCreated) {
        //     const upload: FileModel = this.selectedCommentForEdit?.uploads[0];
        //     this.filePreview = {
        //       file: fileCreated,
        //       objectUrl: upload.src,
        //     };
        //   }
        // } catch (error) {}
      } else {
        this.filePreview = null;
      }
    }
  }

  ngOnInit(): void {}

  onCancelValue() {
    this.value = '';
  }

  getFileNameFromUrl(filename: string): string {
    return filename.substring(filename.lastIndexOf('.') + 1, filename.length) || filename;
  }

  setAttachmentForEdit(): void {
    const upload: FileModel = this.selectedCommentForEdit?.uploads[0];
    const file: File = {
      name: `${upload?.title}.${this.getFileNameFromUrl(upload.src)}`,
    } as any;
    this.filePreview = {
      file,
      objectUrl: upload.src,
      // isUrlOnly: true,
    };
  }

  preview(filePreview: FilePreview) {
    this.filePreview = filePreview;
  }

  // submit() {
  //   if (this.filePreview) {
  //     this.addAttachmentEvent.emit({ file: this.filePreview.file, comment: this.value });
  //   } else {
  //     this.addCommentEvent.emit(this.value);
  //   }
  //   this.value = '';
  //   this.autosize.reset();
  //   this.filePreview = undefined;
  // }

  submit() {
    const chatAction = this.selectedCommentForEdit ? ChatActions.edit : ChatActions.add;
    if (this.filePreview && !this.filePreview?.isUrlOnly) {
      this.addAttachmentEvent.emit({
        file: this.filePreview.file,
        comment: this.value,
        action: chatAction,
      });
    } else {
      this.addCommentEvent.emit({
        comment: this.value,
        action: chatAction,
      });
    }
    this.value = '';
    this.autosize.reset();
    this.filePreview = undefined;
  }
  removePreview() {
    this.filePreview = undefined;
  }
}
