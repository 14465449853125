import { createAction, props } from '@ngrx/store';

export const showUserGroupCreatePage = createAction(
  '[User Groups Popover] show user group create page',
);

export const showUserGroupEditPage = createAction(
  '[User Groups Popover] show user group edit page',
  props<{ groupId: number }>(),
);
