import { NodeWidgetValueModel } from '../../core/models/node-widget-value.model';

export class NodeWidgetsState {
  nodeWidgetsById: { [id: number]: NodeWidgetValueModel };
  nodeWidgetsByNodeId: {
    [nodeId: number]: NodeWidgetValueModel[];
  };
}

export const initialNodeWidgetsState: NodeWidgetsState = {
  nodeWidgetsById: {},
  nodeWidgetsByNodeId: {},
};
