import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OptionModel } from '../../../core/models/option.model';

@Component({
  selector: 'app-rich-select-menu',
  templateUrl: './rich-select-menu.component.html',
  styleUrls: ['./rich-select-menu.component.scss'],
})
export class RichSelectMenuComponent implements OnInit {
  @Input() options: OptionModel[];
  @Input() selectedOptionIds: (number | string)[];
  @Input() isSingleSelect: boolean;
  @Output() changeOptionIds = new EventEmitter<(number | string)[]>();

  constructor() {}

  ngOnInit(): void {}

  selectOption(optionId) {
    let selectedOptionIds = [...(this.selectedOptionIds || [])];
    if (this.isSingleSelect) {
      if ((selectedOptionIds || []).indexOf(optionId) >= 0) {
        this.changeOptionIds.emit([]);
      } else {
        this.changeOptionIds.emit([optionId]);
      }
    } else {
      if ((selectedOptionIds || []).indexOf(optionId) >= 0) {
        selectedOptionIds = selectedOptionIds.filter(id => id !== optionId);
      } else {
        selectedOptionIds = [...selectedOptionIds, optionId];
      }
      this.changeOptionIds.emit(selectedOptionIds);
    }
  }

  trackBy(index, item: OptionModel) {
    return item.id;
  }
}
