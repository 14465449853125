import { Component, Input, OnInit } from '@angular/core';
import { TemplateFiguresPopoverComponent } from '../../template-figures-popover/template-figures-popover.component';
import { TemplateFiguresPopoverActions } from '../../../../store/template-figures-popover';
import { OverlayPopoverService } from '../../../../shared/components/overlay-popover/overlay-popover.service';
import { Store } from '@ngrx/store';
import { ConfirmPopupComponent } from '../../../../shared/modals/confirm-popup/confirm-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WidgetsActions } from '../../../../store/widgets';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { TemplateWidgetsActions } from '../../../../store/template-widgets';
import { CalculationWidgetModel } from '../../../../core/models/calculation-widget.model';

@Component({
  selector: 'app-template-calculation',
  templateUrl: './template-calculation.component.html',
  styleUrls: ['./template-calculation.component.scss'],
})
export class TemplateCalculationComponent implements OnInit {
  @Input() calcWidget: CalculationWidgetModel;

  @Input() calIds: number[];

  constructor(
    private store: Store,
    private popoverService: OverlayPopoverService,
    private modalService: NgbModal,
  ) {}

  ngOnInit(): void {}

  editWidget(origin, widgetId: number) {
    this.popoverService.open({
      content: TemplateFiguresPopoverComponent,
      position: 'bottom',
      origin,
    });
    this.store.dispatch(
      TemplateFiguresPopoverActions.showTemplateFiguresCalculationWidgetEditPage({ widgetId }),
    );
  }

  deleteWidget(origin, widgetId: number) {
    const modelRef = this.modalService.open(ConfirmPopupComponent, {
      size: 'md',
      backdrop: 'static',
    });
    modelRef.componentInstance.title = 'Delete the Calculation';
    modelRef.componentInstance.message = 'Do you want to Delete this Calculation?';
    modelRef.componentInstance.warningMessage =
      'Warning: The calculation and its related data will be deleted permanently.';

    modelRef.result.then((result: boolean) => {
      if (result) {
        this.store.dispatch(WidgetsActions.deleteWidgetRequest({ widgetId }));
      }
    });
  }

  moveCalUp(calId) {
    let ids = [...this.calIds];
    const index = ids.indexOf(calId);
    moveItemInArray(ids, index, index - 1);
    this.store.dispatch(
      TemplateWidgetsActions.sortTemplateWidgetsRequest({
        templateId: this.calcWidget.nodeTemplateId,
        joinIds: ids,
      }),
    );
  }

  moveCalDown(calId) {
    let ids = [...this.calIds];
    const index = ids.indexOf(calId);
    moveItemInArray(ids, index, index + 1);
    this.store.dispatch(
      TemplateWidgetsActions.sortTemplateWidgetsRequest({
        templateId: this.calcWidget.nodeTemplateId,
        joinIds: ids,
      }),
    );
  }
}
