import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { select, Store } from '@ngrx/store';
import { NodeListPanelActions } from '../../../../store/node-list-panel';
import { NodeModel } from '../../../../core/models/node.model';
import { NodesActions, NodesSelectors } from '../../../../store/nodes';
import { Subscription } from 'rxjs';
import { NodeType } from '../../../../core/constants/node-type';
import { NodeUtils } from '../../../../core/utils/node.util';

@Component({
  selector: 'app-folder-tree-node',
  templateUrl: './folder-tree-node.component.html',
  styleUrls: ['./folder-tree-node.component.scss'],
})
export class FolderTreeNodeComponent implements OnInit, OnChanges, OnDestroy {
  private readonly subscriptions: Subscription[] = [];

  public readonly NodeType = NodeType;

  @Input()
  node?: NodeModel;

  @Input()
  readonly: boolean;

  public isExpanded: boolean;

  public childrenNodes: NodeModel[];
  public siblingNodeIds: string[];

  constructor(private store: Store) {
    this.isExpanded = true;
    this.childrenNodes = [];
    this.siblingNodeIds = [];
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.subscriptions.forEach(s => {
      s.unsubscribe();
    });

    this.subscriptions.push(
      this.store
        .pipe(select(NodesSelectors.selectNodesFilteredByParentId(this.node.id)))
        .subscribe((nodes: NodeModel[]) => {
          this.childrenNodes = nodes.filter(
            n => n.nodeType === this.node.nodeType || n.nodeType === NodeType.folderShortcut,
          );
        }),
    );

    // For now, folders always expanded
    // this.subscriptions.push(
    //   this.store
    //     .pipe(
    //       select(WorkspaceUserMetaSelectors.selectUserNodeMetasFilteredById(this.node.id)),
    //       filter(m => m != null),
    //     )
    //     .subscribe((nodeMeta: NodeMeta) => {
    //       this.isExpanded = nodeMeta.isExpanded;
    //     }),
    // );

    // Only allow sorting on nodes with parents
    if (this.node.parentNodeId != null) {
      this.subscriptions.push(
        this.store
          .pipe(select(NodesSelectors.selectNodesFilteredByParentId(this.node.parentNodeId)))
          .subscribe((nodes: NodeModel[]) => {
            this.siblingNodeIds = nodes
              .filter(n => n.id !== this.node.id)
              .map(n => n.id.toString());
          }),
      );
    }
  }

  public onNodeDropped(event: CdkDragDrop<NodeModel, any>) {
    //const node = event.item.data as NodeModel;
    //let nodeIds = this.childrenNodes.filter(n => n.id != node.id).map(n => n.id);
    //nodeIds.splice(event.currentIndex, 0, node.id);
    let nodeIds = this.childrenNodes.map(n => n.id);
    moveItemInArray(nodeIds, event.previousIndex, event.currentIndex);
    this.store.dispatch(NodesActions.sortNodesRequest({ nodeIds }));
  }

  openNodeListPanel(node: NodeModel) {
    this.store.dispatch(
      NodeListPanelActions.openNodeListPanel({
        node: NodeUtils.isShortcut(this.node) === true ? node.reference : node,
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => {
      s.unsubscribe();
    });
  }
}
