import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store';
import { NodeListPanelActions } from '../../../../store/node-list-panel';
import { NodeModel } from '../../../../core/models/node.model';
import { NodeType } from '../../../../core/constants/node-type';

@Component({
  selector: 'app-folder-list-item',
  templateUrl: './folder-list-item.component.html',
  styleUrls: ['./folder-list-item.component.scss'],
})
export class FolderListItemComponent implements OnInit {
  public readonly NodeType = NodeType;

  @Input()
  folder: NodeModel;

  @Input()
  readonly: boolean;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {}

  trackBy(index, element: NodeModel) {
    return element.id;
  }

  switchFolder() {
    this.store.dispatch(
      NodeListPanelActions.openNodeListPanel({
        node: this.folder,
      }),
    );
  }
}
