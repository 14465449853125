<div [formGroup]="form">
  <ng-container [ngSwitch]="widget.widget.widgetType">
    <ng-container
      *ngSwitchCase="
        (widget.widget.widgetType | hasChoicesSupport) && widget.widget.choices?.length > 0
          ? widget.widget.widgetType
          : ''
      "
    >
      <input
        [type]="widget.widget.widgetType | widgetInputType"
        class="form-control"
        [id]="'widget-field-' + widget.id"
        [placeholder]="widget.widget.title"
        [formControlName]="widget.id"
        [ngClass]="
          form.controls[widget.id]?.invalid && form.controls[widget.id]?.touched ? 'is-invalid' : ''
        "
        (blur)="updateWidgetValue()"
        [ngbTypeahead]="search"
        (focus)="focus$.next($any($event).target.value)"
        (click)="click$.next($any($event).target.value)"
        #typeaheadInstance="ngbTypeahead"
        container="body"
      />
    </ng-container>

    <ng-container
      *ngSwitchCase="
        ((widget.widget.widgetType | hasChoicesSupport) &&
          (!widget.widget.choices || widget.widget.choices.length === 0)) ||
        widget.widget.widgetType === WidgetType.number ||
        widget.widget.widgetType === WidgetType.slider ||
        widget.widget.widgetType === WidgetType.date ||
        widget.widget.widgetType === WidgetType.dateTime ||
        widget.widget.widgetType === WidgetType.time
          ? widget.widget.widgetType
          : ''
      "
    >
      <input
        [type]="widget.widget.widgetType | widgetInputType"
        class="form-control"
        [id]="'widget-field-' + widget.id"
        [placeholder]="widget.widget.title"
        [formControlName]="widget.id"
        [ngClass]="
          form.controls[widget.id]?.invalid && form.controls[widget.id]?.touched ? 'is-invalid' : ''
        "
        (blur)="updateWidgetValue()"
        (keydown)="onButtonKeyDown($event)"
      />
    </ng-container>

    <ng-container *ngSwitchCase="WidgetType.currency">
      <div class="input-group">
        <div class="input-group-prepend"><span class="input-group-text">$</span></div>
        <input
          step="0.01"
          min="0"
          aria-label="Amount"
          class="form-control"
          currencyMask
          [options]="{ inputMode: CurrencyMaskInputMode.NATURAL, prefix: '' }"
          [id]="'widget-field-' + widget.id"
          [placeholder]="widget.widget.title"
          [formControlName]="widget.id"
          [ngClass]="
            form.controls[widget.id]?.invalid && form.controls[widget.id]?.touched
              ? 'is-invalid'
              : ''
          "
          (blur)="updateWidgetValue()"
          #currencyField
          (focus)="selectInputText(currencyField)"
          (keydown)="onButtonKeyDown($event)"
        />
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="WidgetType.textarea">
      <textarea
        class="form-control"
        row="3"
        maxlength="1000"
        [id]="'widget-field-' + widget.id"
        [placeholder]="widget.widget.title"
        [formControlName]="widget.id"
        (blur)="updateWidgetValue()"
        (keydown)="onButtonKeyDown($event)"
      ></textarea>
    </ng-container>

    <ng-container *ngSwitchCase="WidgetType.checkbox">
      <div class="form-group">
        <div class="custom-control custom-checkbox">
          <input
            type="checkbox"
            class="custom-control-input layout-mb-sm"
            [id]="widget.id + '-Check'"
            [formControlName]="widget.id"
            (change)="updateWidgetValue()"
          />
          <label class="custom-control-label layout-pt-xs" [for]="widget.id + '-Check'">{{
            widget.widget.title
          }}</label>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="WidgetType.address">
      <app-google-address-field
        addressType="geocode"
        [formControlName]="widget.id"
        [value]="widget.value"
        (changeValue)="updateWidgetValue()"
        [ngClass]="
          form.controls[widget.id]?.invalid && form.controls[widget.id]?.touched ? 'is-invalid' : ''
        "
      ></app-google-address-field>
    </ng-container>

    <ng-container *ngSwitchCase="WidgetType.urlList">
      <app-link-list-field
        [widget]="widget"
        (changeValue)="changeValue($event)"
      ></app-link-list-field>
    </ng-container>

    <ng-container *ngSwitchCase="WidgetType.projectList || WidgetType.assetList">
      <!-- <div class="form-group"> -->
      <select
        class="form-control"
        id="applied-template"
        [formControlName]="widget.id"
        (change)="updateWidgetValue()"
      >
        <option *ngFor="let option of widgetOptions" [ngValue]="option.id">{{
          option.label
        }}</option>
      </select>
      <!-- </div> -->
    </ng-container>

    <ng-container *ngSwitchCase="WidgetType.heading">
      <label class="heading">{{ widget?.widget?.title }}</label>
    </ng-container>

    <ng-container *ngSwitchCase="WidgetType.lineSeparator">
      <div class="line-separator"></div>
    </ng-container>

    <ng-container *ngSwitchCase="WidgetType.subHeading">
      <label class="sub-heading">{{ widget?.widget?.title }}</label>
    </ng-container>

    <ng-container *ngSwitchCase="WidgetType.paragraph">
      <p class="paragraph">{{ widget?.widget?.title }}</p>
    </ng-container>

    <ng-container *ngSwitchDefault>Not supported widget</ng-container>
  </ng-container>
  <div class="invalid-feedback">
    <ng-container *ngIf="form.controls[widget.id]?.errors?.required">
      The {{ widget.widget.title }} is required.
    </ng-container>
    <ng-container *ngIf="!form.controls[widget.id]?.errors?.required">
      Please provide the value of the {{ widget.widget.title }} with correct format
    </ng-container>
  </div>
</div>
