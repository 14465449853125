<div
  *ngIf="profile || profileIcon || initials"
  class="node-avatar "
  [ngClass]="[
    size ? 'node-avatar--' + size : '',
    revertColor ? 'node-avatar--revert-color' : '',
    colorTheme ? 'node-avatar--' + (colorTheme | colorString) : ''
  ]"
  [ngStyle]="{
    'background-image': profile?.src
      ? 'url(' + profile?.src + ')'
      : profileIcon?.src
      ? 'url(' + profileIcon?.src + ')'
      : 'none'
  }"
  [ngbTooltip]="hideTooltip ? null : fullName"
  [title]="fullName"
  container="body"
>
  <span *ngIf="!profile && !profileIcon" class="node-avatar__initials">{{ initials }}</span>

  <div class="node-avatar__avatar" *ngIf="profile || profileIcon"></div>
</div>
