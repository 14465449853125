import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { WorkspaceGroupType } from '../../../core/constants/workspace-group-type';
import { ConfirmPopupComponent } from '../confirm-popup/confirm-popup.component';
import { WorkspaceGroupModel } from '../../../core/models/workspace-group.model';
import { WorkspaceGroupsActions } from '../../../store/workspace-groups';
import { Store } from '@ngrx/store';

@Component({
  templateUrl: 'group-edit.component.html',
  styleUrls: ['group-edit.component.scss'],
})
export class GroupEditComponent implements OnInit {
  @Input()
  public groupType: WorkspaceGroupType;

  @Input()
  public nodeId: number;

  @Input()
  public templateId: number;

  @Input()
  public childId: number;

  public isSubmitted: boolean;
  public form: UntypedFormGroup;
  public group: WorkspaceGroupModel;

  // convenience getter for easy access to form fields
  public get f() {
    return this.form.controls;
  }

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private fb: UntypedFormBuilder,
    private store: Store,
  ) {
    this.form = this.fb.group({
      title: ['', Validators.required],
    });
  }

  public ngOnInit() {
    if (this.group != null) {
      this.form.patchValue({
        title: this.group.title,
      });
    }
  }

  public onSubmit() {
    this.isSubmitted = true;

    if (this.form.valid) {
      const formData = this.form.getRawValue();
      if (this.group == null) {
        this.store.dispatch(
          WorkspaceGroupsActions.addWorkspaceGroupRequest({
            title: formData.title,
            groupType: this.groupType,
            nodeId: this.nodeId,
            templateId: this.templateId,
            attachChild: this.childId,
          }),
        );
      } else {
        this.store.dispatch(
          WorkspaceGroupsActions.updateWorkspaceGroupRequest({
            title: formData.title,
            groupId: this.group.id,
          }),
        );
      }
      this.activeModal.dismiss();
    }
  }

  onDelete() {
    const modelRef = this.modalService.open(ConfirmPopupComponent, {
      size: 'md',
      backdrop: 'static',
    });
    modelRef.componentInstance.title = 'Delete Group';
    modelRef.componentInstance.message = 'Do you want to Delete this Group?';
    modelRef.result
      .then((result?: boolean) => {
        if (result) {
          this.store.dispatch(
            WorkspaceGroupsActions.deleteWorkspaceGroupRequest({
              group: this.group,
            }),
          );
        }
        this.activeModal.dismiss();
      })
      .catch(res => {});
  }
}
