<div
  class="template-type-item"
  [ngClass]="{ 'template-type-item--selected': selectedNodeType === typeViewModel.nodeType }"
>
  <div class="template-type-item__header">
    <h2 class="template-type-item__title">{{ typeViewModel.title }}</h2>
    <button class="btn btn-sm btn-primary" (click)="createTemplate()">
      <i class="fal fa-circle-plus"></i> New
    </button>
  </div>
  <div class="template-type-item__body">
    <app-template-list
      [templateList]="typeViewModel.templates"
      [serverNodeType]="typeViewModel.nodeType"
      [selectedTemplateId]="selectedTemplateId"
      [slug]="slug"
      [defaultTemplateId]="typeViewModel.templates | defaultTemplateId"
    ></app-template-list>
  </div>
</div>
