<div class="ap ap-page ap-page--secured">
  <app-secured-header *ngIf="user"></app-secured-header>

  <main #main class="main">
    <router-outlet></router-outlet>
  </main>

  <app-content-loader scope="full"></app-content-loader>
  <app-alert class="app-alert__workspace"></app-alert>
</div>
<!--/ap-->
