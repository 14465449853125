import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-assignment-field-text',
  templateUrl: './assignment-field-text.component.html',
})
export class AssignmentFieldTextComponent implements OnInit {
  @Input() title: string;
  @Input() value: string;
  @Input() readonly: boolean;

  @Output() editEvent = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  edit() {
    this.editEvent.emit();
  }
}
