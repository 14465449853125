import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Moment} from 'moment';
import {AppState} from '../../../store';
import {select, Store} from '@ngrx/store';
import {Subscription} from 'rxjs';
import {CalendarActions} from '../../../store/calendar';
import {selectCalendarSelectedDates} from '../../../store/calendar/calendar.selectors';
import {AssignmentLayoutMode} from '../../../core/constants/assignment-layout-mode';
import {NgbDate, NgbDateStruct, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {selectRouterDerivedAssignmentLayout} from '../../../store/router/router-derived.selectors';
import {TemplatesById} from '../../../store/templates/templates.state';
import {LayoutMetricModel} from '../../../core/models/layout-metric.model';
import {AppLayoutSelectors} from '../../../store/app-layout';
import {NodePickerComponent} from '../../modals/node-picker/node-picker.component';
import {NodeType} from '../../../core/constants/node-type';
import {NodeModel} from '../../../core/models/node.model';
import {NodesActions, NodesSelectors} from '../../../store/nodes';
import {NodeUtils} from '../../../core/utils/node.util';
import {DispatchScheduleComponent} from '../../modals/dispatch-schedule/dispatch-schedule.component';
import {DateRangeModel} from '../../../core/models/date-range.model';
import {DatePickerComponent} from '../../modals/date-picker/date-picker.component';
import {ngbDateUtil} from '../../../core/utils/ngb-date.util';
import {WorkspaceUserMetaActions} from '../../../store/workspace-user-meta';
import {WorkspaceSharesActions} from '../../../store/workspace-shares';
import * as moment from 'moment';
import {selectWorkspaceSharedLinks} from '../../../store/workspace-shares/workspace-shares.selectors';
import {SharedLinkModel} from '../../../core/models/shared-link.model';
import {ShareLinksComponent} from '../../modals/share-links/share-links.component';

@Component({
  selector: 'app-calendar-row-header',
  templateUrl: './calendar-row-header.component.html',
  styleUrls: ['./calendar-row-header.component.scss'],
})
export class CalendarRowHeaderComponent implements OnInit, OnDestroy {
  private readonly subscription = new Subscription();

  @Input() day: Moment;
  @Input() dateKey: string;
  @Input() readonly: boolean;
  @Input() assignments: NodeModel[];
  @Input() templatesById: TemplatesById;
  @Input() hideInfo = false;
  @Input() type: 'assignments' | 'sharedLinks';

  awaitingAssignmentsDateKey = NodeUtils.awaitingAssignmentsDateKey;

  public readonly LayoutType = AssignmentLayoutMode;

  layout: AssignmentLayoutMode;
  routerDerivedAssignmentLayout: AssignmentLayoutMode;

  hiddenProjectNames: string[];
  hiddenDefaultElementNames: string[];
  hiddenAssetElementNames: string[];
  public selectedNodes: NodeModel[];
  public selectedNodesAwaiting: NodeModel[];
  selectedStatus: boolean = false;
  selectedDates: string[];
  private sharedLinks: SharedLinkModel[] = [];

  constructor(private store: Store<AppState>, private modalService: NgbModal) {
    this.subscription.add(
      this.store
        .pipe(select(AppLayoutSelectors.selectWorkspaceContent))
        .subscribe(workspaceContent => {
          this.layout = workspaceContent.mode;
        }),
    );
  }

  ngOnInit() {
    this.subscription.add(
      this.store.pipe(select(selectRouterDerivedAssignmentLayout)).subscribe(layout => {
        this.routerDerivedAssignmentLayout = layout;
      }),
    );

    this.subscription.add(
      this.store.pipe(select(selectCalendarSelectedDates)).subscribe(selectedDates => {
        this.selectedDates = selectedDates;
      }),
    );

    this.subscription.add(
      this.store.pipe(select(NodesSelectors.selectAssignmentSelectedNodes)).subscribe(nodes => {
        this.selectedNodes = nodes;
        this.selectedNodesAwaiting = nodes.filter(x => x?.date === null);
      }),
    );

    this.subscription.add(
      this.store
        .pipe(select(NodesSelectors.selectAssignmentsSelectedStatusByDate(this.dateKey)))
        .subscribe(selectedStatus => {
          this.selectedStatus = selectedStatus;
        }),
    );

    this.subscription.add(
      this.store.pipe(select(selectWorkspaceSharedLinks)).subscribe(sharedLinks => {
        this.sharedLinks = sharedLinks;
      }),
    );
  }

  public getAssignmentLockStatus(): boolean {
    return this.assignments.some(x => x?.readOnly);
  }

  public isAllAssignmentsLocked(status = true): boolean {
    return this.assignments.every(x => x?.readOnly === status);
  }

  public isAssignmentLockIconVisible(): boolean {
    return this.assignments.every(x => x?.readOnly) || this.assignments.every(x => !x?.readOnly);
  }

  clickCalendarDate() {
    if (this.readonly) {
      return;
    }
    if (this.dateKey !== NodeUtils.awaitingAssignmentsDateKey) {
      this.store.dispatch(CalendarActions.toggleDateRequest({date: this.dateKey}));
    }
  }

  changeDate() {
    if (this.readonly) {
      return;
    }
    const modelRef = this.modalService.open(DatePickerComponent, {
      size: 'md',
      backdrop: 'static',
    });

    modelRef.componentInstance.minSelection = 1;
    modelRef.componentInstance.maxSelection = 1;
    // modelRef.componentInstance.selected = this.assignment.date
    //   ? [ngbDateUtil.convertToNgbDate(this.assignment.date)]
    //   : [];
    modelRef.result
      .then((result: NgbDateStruct[]) => {
        this.store.dispatch(
          NodesActions.moveAssignmentsRequest({
            assignmentIds: this.selectedNodes.map(x => x.id),
            toDateKey: ngbDateUtil.convertToDateKey(result[0]),
          }),
        );
        this.store.dispatch(NodesActions.clearSelectedAssignments());
        const dateRange: DateRangeModel = {
          start: ngbDateUtil.convertToDateKey(result[0]),
          end: ngbDateUtil.convertToDateKey(result[0]),
        };
        this.store.dispatch(
          WorkspaceUserMetaActions.changeAssignmentDateRangeRequest({dateRange}),
        );
      })
      .catch(res => {
      });
  }

  copyMultiple = () => {
    if (this.readonly) {
      return;
    }
    const modelRef = this.modalService.open(DatePickerComponent, {
      size: 'md',
      backdrop: 'static',
    });

    modelRef.componentInstance.minSelection = 1;
    modelRef.componentInstance.maxSelection = 50;
    // modelRef.componentInstance.selected = this.assignment.date
    //   ? [ngbDateUtil.convertToNgbDate(this.assignment.date)]
    //   : [];
    modelRef.result
      .then((result: NgbDateStruct[]) => {
        this.selectedNodes.forEach(element => {
          this.store.dispatch(
            NodesActions.copyAssignmentRequest({
              assignmentId: element.id,
              dateKeys: result.map(dt => ngbDateUtil.convertToDateKey(dt)),
              primaryTags: true,
              assets: true,
              elements: true,
              rates: true,
            }),
          );
        });

        // Retrieve the minimum and maximum dates from the result set using the utility function
        const dates: {
          minDate: NgbDate;
          maxDate: NgbDate;
        } = ngbDateUtil.getMinMaxDates(result);

        // Convert the retrieved NgbDate objects to DateKey format for the date range model
        const dateRange: DateRangeModel = {
          start: ngbDateUtil.convertToDateKey(dates.minDate),
          end: ngbDateUtil.convertToDateKey(dates.maxDate),
        };

        // Dispatch an action to change the assignment date range in the store with the calculated date range
        this.store.dispatch(
          WorkspaceUserMetaActions.changeAssignmentDateRangeRequest({dateRange}),
        );

        this.store.dispatch(NodesActions.clearSelectedAssignments());
      })
      .catch(res => {
      });
  };

  copy() {
    if (this.readonly) {
      return;
    }
    this.selectedNodes.forEach(element => {
      this.store.dispatch(
        NodesActions.copyAssignmentRequest({
          assignmentId: element.id,
          dateKeys: [element.date],
          primaryTags: true,
          assets: true,
          elements: true,
          rates: true,
        }),
      );
      this.store.dispatch(NodesActions.clearSelectedAssignments());
    });
  }

  addAssignment() {
    const modelRef = this.modalService.open(NodePickerComponent, {
      size: 'xl',
      backdrop: 'static',
    });

    modelRef.componentInstance.types = [NodeType.project];
    modelRef.componentInstance.selected = [];
    modelRef.result
      .then((result: NodeModel[]) => {
        this.store.dispatch(
          NodesActions.addMultipleAssignmentsRequest({
            nodeIds: result.map(x => x.id),
            dateKeys: [this.dateKey],
          }),
        );
      })
      .catch(res => {
      });
  }

  dispatch(): void {
    if (this.readonly) {
      return;
    }
    // const modelRef = this.modalService.open(DispatchScheduleComponent, {
    //   size: 'xl',
    //   backdrop: 'static',
    // });

    const modelRef = this.modalService.open(ShareLinksComponent, {
      size: 'xl',
      backdrop: 'static',
      windowClass: 'ws-links-modal',
    });

    const assignmentDateRange: DateRangeModel = {
      start: this.dateKey,
      end: this.dateKey,
    };

    // Format the current day to a readable date string
    const formattedDate = moment(this.day).format('LL');
    const title = `Despatch for ${formattedDate}`;
    // Check if a shared link with the same title already exists - which means it has already been created by using this same despatch date
    const isSharedLinkALreadyCreated = this.sharedLinks?.findIndex(
      x => x.metaData?.title === title,
    );
    // if shared link is not created then create a new shared link
    if (isSharedLinkALreadyCreated === -1) {
      const payload = {assignmentDateRange, title};
      this.store.dispatch(WorkspaceSharesActions.shareCurrentViewRequest(payload));
      modelRef.componentInstance.selectedLinkTitle = title;
    } else {
      modelRef.componentInstance.selectedLink = this.sharedLinks[isSharedLinkALreadyCreated];
    }

    modelRef.result
      .then((result: any) => {
        //
      })
      .catch(res => {
      });

    // modelRef.componentInstance.dateRange = assignmentDateRange;
    // modelRef.result
    //   .then((result: NodeModel[]) => {
    //     //
    //   })
    //   .catch(res => {});
  }

  public lockUnlockAssignments(): void {
    if (this.readonly) {
      return;
    }
    const lockStatus = this.assignments[0]?.readOnly;
    for (const assignment of this.assignments) {
      this.store.dispatch(
        NodesActions.updateAssignmentReadonlyRequest({
          nodeId: assignment.id,
          readonly: !lockStatus,
        }),
      );
    }
  }

  selectAllAssignments() {
    this.store.dispatch(NodesActions.selectAllAssignmentsByDate({dateKey: this.dateKey}));
  }

  unSelectAllAssignments() {
    this.store.dispatch(NodesActions.unselectAllAssignmentsByDate({dateKey: this.dateKey}));
  }

  trackBy(index, metric: LayoutMetricModel) {
    return metric?.template?.id;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
