import { createAction, props } from '@ngrx/store';
import { NodeRateEditableProps, NodeRateModel } from '../../core/models/node-rate.model';

export const loadNodeRatesFromNodeTree = createAction(
  '[NodeRates] load node rates from node tree success',
  props<{ nodeRates: NodeRateModel[] }>(),
);

export const addNodeRateRequest = createAction(
  '[NodeRates] add node rate request',
  props<{
    nodeId: number;
    rateType: number;
    title: string;
    value: number;
    quantity: number;
    total: number;
    tooltip?: string;
    units?: string;
    groupId?: number;
    sortIndex?: number;
  }>(),
);

export const addNodeRateSuccess = createAction(
  '[NodeRates] add node rate success',
  props<{ rate: NodeRateModel }>(),
);

export const updateNodeRateRequest = createAction(
  '[NodeRates] update node rate request',
  props<{ rateId: number; rateProps: Partial<NodeRateEditableProps> }>(),
);

export const deleteNodeRateRequest = createAction(
  '[NodeRates] delete node rate request',
  props<{ nodeId: number; rateId: number }>(),
);

export const sortNodeRateRequest = createAction(
  '[NodeRates] sort node rates request',
  props<{ nodeId: number; rateIds: number[] }>(),
);
