import { AppState } from '../index';
import { createSelector } from '@ngrx/store';

const selectWorkspaceSharesState = (state: AppState) => state.workspaceShares;

export const selectWorkspaceSharesByHash = createSelector(
  selectWorkspaceSharesState,
  state => state.byHash,
);

export const selectWorkspaceSharesHashes = createSelector(
  selectWorkspaceSharesState,
  state => state.hashes,
);

export const selectWorkspaceSharedLinks = createSelector(
  selectWorkspaceSharesHashes,
  selectWorkspaceSharesByHash,
  (hashes, byHash) => {
    return hashes.map(hash => byHash[hash]);
  },
);
