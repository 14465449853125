import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ResponseMessage } from '../models/response-message.model';
import { map } from 'rxjs/operators';
import { NodeTagModel } from '../models/node-tag.model';

@Injectable({ providedIn: 'root' })
export class NodeTagService {
  constructor(private http: HttpClient) {}

  public addNodeTag(nodeId: number, tagId: number, groupId: number): Observable<NodeTagModel> {
    return this.http
      .post<ResponseMessage<NodeTagModel>>(
        `${environment.apiBaseUrl}/workspace/node/${nodeId}/tag/add/${tagId}`,
        {
          group: groupId,
        },
      )
      .pipe(map(res => res.data));
  }

  public addNodeTags(
    nodeId: number,
    tagIds: number[],
    groupId?: number,
    replace?: boolean,
  ): Observable<NodeTagModel[]> {
    return this.http
      .post<ResponseMessage<NodeTagModel[]>>(
        `${environment.apiBaseUrl}/workspace/node/${nodeId}/add-tags`,
        {
          tags: tagIds,
          group: groupId,
          replace,
        },
      )
      .pipe(map(res => res.data));
  }

  public addTagsToMultipleNodes(
    slug: string,
    nodeIds: number[],
    tagIds: number[],
    replace?: boolean,
  ): Observable<NodeTagModel[]> {
    return this.http
      .post<ResponseMessage<NodeTagModel[]>>(
        `${environment.apiBaseUrl}/workspace/${slug}/nodes/add-tags`,
        {
          nodes: nodeIds,
          tags: tagIds,
          replace,
        },
      )
      .pipe(map(res => res.data as NodeTagModel[]));
  }

  public removeNodeTag(nodeId: number, joinId: number): Observable<object> {
    return this.http.delete(
      `${environment.apiBaseUrl}/workspace/node/${nodeId}/tag/${joinId}/remove`,
    );
  }

  public clearNodeTags(nodeId: number): Observable<NodeTagModel[]> {
    return this.addNodeTags(nodeId, []);
  }
}
