import { createAction, props } from '@ngrx/store';
import {
  LayoutTemplateModel,
  LayoutTemplatePayload,
} from '../../core/models/layout-template.model';
import { FileModel } from '../../core/models/file.model';

export const loadLayoutTemplatesRequest = createAction(
  '[LayoutTemplates] load layout templates request',
);

export const loadLayoutTemplatesSuccess = createAction(
  '[LayoutTemplates] load layout templates success',
  props<{ layoutTemplates: LayoutTemplateModel[] }>(),
);

export const addLayoutTemplateRequest = createAction(
  '[LayoutTemplates] add layout template request',
  props<{
    nodeTemplateId: number;
    payload: LayoutTemplatePayload;
  }>(),
);

export const uploadLayoutTemplateProfileRequest = createAction(
  '[LayoutTemplates] upload layout template profile request',
  props<{
    layoutTemplateId: number;
    file: File;
  }>(),
);

export const uploadLayoutTemplateProfileSuccess = createAction(
  '[LayoutTemplates] update layout template profile success',
  props<{ layoutTemplateId: number; file: FileModel }>(),
);

export const updateLayoutTemplateMetaRequest = createAction(
  '[LayoutTemplates] update layout template meta request',
  props<{
    layoutTemplateId: number;
    payload: LayoutTemplatePayload;
  }>(),
);

export const addLayoutTemplateSuccess = createAction(
  '[LayoutTemplates] add layout template success',
  props<{ layoutTemplate: LayoutTemplateModel }>(),
);

export const installLayoutTemplateRequest = createAction(
  '[LayoutTemplates] install layout template request',
  props<{ slug: string; layoutTemplateId: number }>(),
);

export const installLayoutTemplateSuccess = createAction(
  '[LayoutTemplates] install layout template success',
  props<{ layoutTemplateId: number }>(),
);

export const deleteLayoutTemplateRequest = createAction(
  '[LayoutTemplates] delete layout template request',
  props<{ layoutTemplateId: number }>(),
);

export const deleteLayoutTemplateSuccess = createAction(
  '[LayoutTemplates] delete layout template success',
  props<{ layoutTemplateId: number }>(),
);

export const updateSearchKeywordRequest = createAction(
  '[LayoutTemplates] update search keyword request',
  props<{ searchKeyword: string }>(),
);
