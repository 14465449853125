<ng-template #modalFooter>
  <button *ngIf="model" type="button" class="btn btn-sm btn-danger mr-auto" (click)="onDelete()">
    Delete
  </button>

  <button type="button" class="btn btn-sm btn-outline-light" (click)="activeModal.dismiss()">
    Cancel
  </button>

  <button ngbAutofocus type="submit" class="btn btn-sm btn-primary" (click)="onSubmit()">
    Save
  </button>
</ng-template>

<app-modal title="Edit tag" [footerTemplate]="modalFooter">
  <div class="tag-edit">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">

      <div class="d-flex">

        <app-panel-subsection title="Icon">
          <button
            type="button"
            class="picker-button btn"
            [ngClass]="'picker-button--icon-' + (selectedIcon | iconString)"
            (click)="onIconPicker()">
            <span class="fal" [ngClass]="'fa-' + (selectedIcon | iconString)"></span>
          </button>
        </app-panel-subsection>

        <app-panel-subsection title="Colour">
          <button
            type="button"
            class="picker-button btn"
            [ngClass]="'background--' + (selectedColour | colorString)"
            (click)="onColourPicker()"
          ></button>
        </app-panel-subsection>

        <app-panel-subsection title="Title">
          <input
            type="text"
            class="form-control"
            placeholder="Title"
            required
            formControlName="title"
            [ngClass]="f.title.invalid && (f.title.dirty || isSubmitted) ? 'is-invalid' : ''"
          />
          <div class="invalid-feedback">
            Please provide a title
          </div>
        </app-panel-subsection>

      </div>

      <!--      <app-panel-subsection title="Keywords">-->
      <!--        <input-->
      <!--          type="text"-->
      <!--          class="form-control"-->
      <!--          placeholder="Keywords"-->
      <!--          formControlName="searchTerms"-->
      <!--        />-->
      <!--      </app-panel-subsection>-->

    </form>
  </div>
</app-modal>
