import { Component, Input, OnInit } from '@angular/core';
import { SaveStatus } from '../../../core/constants/save-status';

@Component({
  selector: 'app-save-status',
  templateUrl: './save-status.component.html',
  styleUrls: ['./save-status.component.scss'],
})
export class SaveStatusComponent implements OnInit {
  @Input() saveStatus: SaveStatus;

  SaveStatus = SaveStatus;

  constructor() {}

  ngOnInit(): void {}
}
