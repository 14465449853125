<ng-template #modalFooter>
  <div class="row m-0 w-100">
    <div class="col-3 p-0">
      <button
        type="button"
        class="btn btn-md btn-outline-light"
        aria-label="Close"
        (click)="activeModal.dismiss(false)"
      >
        Cancel
      </button>
    </div>

    <div class="col-9 p-0 d-flex justify-content-end">
      <button
        ngbAutofocus
        type="button"
        class="btn btn-md btn-primary ml-2"
        aria-label="Close"
        (click)="onSubmit()"
      >
        <i class="fal fa-circle-plus layout-mr-sm"></i>
        Add Cards
      </button>
    </div>
  </div>
</ng-template>

<app-modal
  [title]="
    types?.length === 1 && types?.includes(nodeTypeList.project)
      ? 'Select assignment cards'
      : 'Node Picker'
  "
  [footerTemplate]="modalFooter"
>
  <app-modal-instructions
    [html]="instructions"
    [template]="instructionsTemplate"
  ></app-modal-instructions>

  <div class="row mx-0 item-picked__search-bar">
    <div class="col-12">
      <app-search-box
        [searchKeyword]="keywords"
        sizeType="sm"
        [inputHeight]="'45px'"
        (searchChanged)="search($event)"
      ></app-search-box>
    </div>
  </div>

  <ol class="item-picker__list item-picker__list--flex-column list-unstyled">
    <li
      *ngFor="let item of list; trackBy: trackBy"
      class="item-picker__list-item item-picker__list-item--flex-column"
    >
      <div
        class="list-item__brick"
        [id]="item.model.id"
        app-selectable-brick
        [color]="item.model.colorTheme || item.model.parent.colorTheme"
        [icon]="item.model.icon"
        [label]="item.model.title"
        [isChecked]="item.selected"
        (click)="toggle(item)"
        [flexColumn]="true"
        [rightColTemplate]="checkBox"
      ></div>
      <ng-template #checkBox>
        <app-selectable-input
          [id]="item.model.id"
          [checked]="item.selected"
          [type]="maxSelection === 1 ? 'radio' : 'checkbox'"
        ></app-selectable-input>
      </ng-template>
    </li>
  </ol>
</app-modal>
