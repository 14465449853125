import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { TableComponent } from '../table.component';

@Component({
  selector: 'app-z-table-search',
  templateUrl: './table-search.component.html',
  styleUrls: ['./table-search.component.scss'],
})
export class TableSearchComponent implements OnDestroy {
  @ViewChild('input')
  public input: ElementRef;

  @Input()
  public keywords: string;

  @Output()
  public searchEvent = new EventEmitter<string>();

  private timer: any;

  constructor(public table: TableComponent) {
    this.keywords = '';
  }

  public onSearch(event) {
    clearTimeout(this.timer);
    this.timer = window.setTimeout(() => {
      this.searchEvent.emit(this.keywords);
    }, 500);
  }

  ngOnDestroy(): void {
    clearTimeout(this.timer);
  }
}
