import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { WorkspaceUserMetaActions, WorkspaceUserMetaSelectors } from '../workspace-user-meta';
import { catchError, concatMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { selectAssignmentDateRangeWithCurrentSlug } from '../workspace-user-meta/workspace-user-meta.selectors';
import { TimesheetService } from '../../core/services/timesheet.service';
import { TimesheetsActions } from './index';
import { TimesheetModel } from '../../core/models/timesheet.model';
import { ErrorsActions } from '../errors';
import { selectWorkspacesCurrentSlug } from '../workspaces/workspaces.selectors';

@Injectable()
export class TimesheetsEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    private timesheetService: TimesheetService,
  ) {}

  loadTimesheetsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        TimesheetsActions.loadTimesheetsRequest,
        WorkspaceUserMetaActions.changeAssignmentDateRangeRequest,
      ),
      concatMap(action =>
        of(action).pipe(
          withLatestFrom(this.store.pipe(select(selectAssignmentDateRangeWithCurrentSlug))),
        ),
      ),
      mergeMap(([action, { slug, dateRange }]) =>
        this.timesheetService
          .loadTimesheetsByDateRange(
            slug,
            dateRange.start,
            dateRange.end,
            (action as any)?.nullDate,
          )
          .pipe()
          .pipe(
            map((timesheets: TimesheetModel[]) => {
              return TimesheetsActions.loadTimesheetsSuccess({ timesheets });
            }),
          ),
      ),
    ),
  );

  loadTimesheetsByProjectsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TimesheetsActions.loadTimesheetsByProjectsRequest),
      concatMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(selectWorkspacesCurrentSlug)),
            this.store.pipe(select(WorkspaceUserMetaSelectors.selectTableProjects)),
          ),
        ),
      ),
      mergeMap(([action, slug, projects]) => {
        if (
          (action.projects == null || action.projects.length == 0) &&
          (projects == null || projects.length == 0)
        ) {
          return EMPTY;
        }

        return this.timesheetService
          .loadTimesheetsByProjects(slug, action.projects || projects)
          .pipe(
            map((timesheets: TimesheetModel[]) => {
              return TimesheetsActions.loadTimesheetsSuccess({ timesheets });
            }),
          );
      }),
    ),
  );

  addTimesheetRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TimesheetsActions.addTimesheetRequest),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      mergeMap(([action, slug]) =>
        this.timesheetService
          .addTimesheet(slug, action.assetId, action.assignmentId, action.timesheetProps)
          .pipe(
            map((timesheet: TimesheetModel) => {
              return TimesheetsActions.addTimesheetSuccess({
                timesheet,
              });
            }),
            catchError(error => {
              return of(ErrorsActions.goToErrorPage({ error }));
            }),
          ),
      ),
    ),
  );

  updateTimesheetRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TimesheetsActions.updateTimesheetRequest),
      mergeMap(action =>
        this.timesheetService.updateTimesheet(action.timesheetId, action.timesheetProps).pipe(
          map((timesheet: TimesheetModel) => {
            return TimesheetsActions.updateTimesheetSuccess({
              timesheet,
            });
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );
}
