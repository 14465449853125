import { NodeGroupValueModel } from '../../core/models/node-group-value.model';

export class NodeGroupsState {
  nodeGroupsById: { [id: number]: NodeGroupValueModel };
  nodeGroupsByNodeId: {
    [nodeId: number]: NodeGroupValueModel[];
  };
  nodeGroupsByGroupId: {
    [nodeId: number]: NodeGroupValueModel[];
  };
}

export const initialNodeGroupsState: NodeGroupsState = {
  nodeGroupsById: {},
  nodeGroupsByNodeId: {},
  nodeGroupsByGroupId: {},
};
