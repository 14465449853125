import { initialWorkspacesState, WorkspacesState } from './workspaces.state';
import { Action, createReducer, on } from '@ngrx/store';
import { WorkspacesActions } from './index';

const reducer = createReducer<WorkspacesState>(
  initialWorkspacesState,

  on(
    WorkspacesActions.loadDashboardSuccess,
    (state, { owned, shared }): WorkspacesState => {
      const ownedSlugs = owned.map(workspace => workspace.slug);
      const sharedSlugs = shared.map(workspace => workspace.slug);
      const bySlug = [...owned, ...shared].reduce((workspacesBySlug, workspace) => {
        return {
          ...workspacesBySlug,
          [workspace.slug]: workspace,
        };
      }, {});
      return {
        ...state,
        ownedSlugs,
        sharedSlugs,
        bySlug,
      };
    },
  ),

  on(
    WorkspacesActions.addWorkspaceSuccess,
    WorkspacesActions.copyWorkspaceSuccess,
    (state, { workspace }) => {
      return {
        ...state,
        bySlug: {
          ...state.bySlug,
          [workspace.slug]: workspace,
        },
        ownedSlugs: [workspace.slug, ...state.ownedSlugs],
      };
    },
  ),

  on(WorkspacesActions.deleteWorkspaceSuccess, (state, { slug }) => {
    const newBySlug = {
      ...state.bySlug,
    };
    delete newBySlug[slug];
    return {
      ...state,
      bySlug: newBySlug,
      ownedSlugs: state.ownedSlugs.filter(ownedSlug => ownedSlug !== slug),
    };
  }),

  on(WorkspacesActions.leaveWorkspaceSuccess, (state, { slug }) => {
    const newBySlug = {
      ...state.bySlug,
    };
    delete newBySlug[slug];
    return {
      ...state,
      bySlug: newBySlug,
      sharedSlugs: state.sharedSlugs.filter(sharedSlug => sharedSlug !== slug),
    };
  }),

  on(WorkspacesActions.updateWorkspaceSuccess, (state, { workspace }) => {
    return {
      ...state,
      bySlug: {
        ...state.bySlug,
        [workspace.slug]: workspace,
      },
    };
  }),

  on(
    WorkspacesActions.patchWorkspaceMetaRequest, // more responsive if on the Request action
    (state, { slug, metaData }): WorkspacesState => {
      return {
        ...state,
        bySlug: {
          ...state.bySlug,
          [slug]: {
            ...state.bySlug[slug],
            metaData: {
              ...state.bySlug[slug].metaData,
              ...metaData,
            },
          },
        },
      };
    },
  ),

  on(WorkspacesActions.setCurrentWorkspace, (state, { slug }) => {
    return {
      ...state,
      currentSlug: slug,
    };
  }),

  on(WorkspacesActions.unloadCurrentWorkspace, state => {
    return {
      ...state,
      currentSlug: undefined,
    };
  }),
);

export function workspacesReducer(state: WorkspacesState, action: Action) {
  return reducer(state, action);
}
