import { createAction, props } from '@ngrx/store';

export const showTemplateFiguresCalculationCreatePage = createAction(
  '[Template Figures Popover] show Template Figures Calculation create page',
  props<{ templateId: number }>(),
);

export const showTemplateFiguresCalculationWidgetEditPage = createAction(
  '[Template Figures Popover] show Template Figures Calculation widget edit page',
  props<{ widgetId: number }>(),
);
