import { AppState } from '../index';
import { createSelector } from '@ngrx/store';

const selectWorkspaceTemplatesState = (state: AppState) => state.workspaceTemplates;

export const selectWorkspaceTemplatesById = createSelector(
  selectWorkspaceTemplatesState,
  state => state.byId,
);

export const selectWorkspaceTemplatesIds = createSelector(
  selectWorkspaceTemplatesState,
  state => state.ids,
);

export const selectWorkspaceTemplates = createSelector(
  selectWorkspaceTemplatesById,
  selectWorkspaceTemplatesIds,
  (byId, ids) => {
    return ids.map(id => byId[id]).filter(template => !!template);
  },
);

export const selectWorkspaceTemplatesSearchKeyword = createSelector(
  selectWorkspaceTemplatesState,
  state => state.searchKeyword,
);

export const selectAllWorkspaceTemplatesWithKeyword = createSelector(
  selectWorkspaceTemplates,
  selectWorkspaceTemplatesSearchKeyword,
  (WorkspaceTemplates, keywords) => {
    if (!keywords) {
      return WorkspaceTemplates;
    }
    const lowerCaseKeyword = keywords.toLowerCase();
    return WorkspaceTemplates.filter(
      WorkspaceTemplate =>
        WorkspaceTemplate.title.toLowerCase().indexOf(lowerCaseKeyword) >= 0 ||
        (WorkspaceTemplate.searchTerms
          ? WorkspaceTemplate.searchTerms.toLowerCase().indexOf(lowerCaseKeyword) >= 0
          : false),
    );
  },
);
