<section class="sidebar-section" [ngClass]="{ 'sidebar-section--collapsed': isCollapsed }">
  <div class="sidebar-section__collapse-mode-header">
    <div class="sidebar-section__title-area" (click)="openNodeListPanel()">

      <app-node-type-icon
        *ngIf="isCollapsed"
        [node]="rootFolder"
        class="sidebar-section__icon"
      ></app-node-type-icon>

      <h1 class="sidebar-section__title">
        {{ rootFolder?.title }}
      </h1>
    </div>

    <app-context-buttons
      class="sidebar-section__menu-button"
      [node]="rootFolder"
    ></app-context-buttons>
  </div>
  <div class="sidebar-section__body" *ngIf="!isCollapsed">
    <ng-content></ng-content>
  </div>
</section>
