import { Component, Input, OnInit } from '@angular/core';
import { NodeTemplateModel } from '../../../../../core/models/node-template.model';
import { TemplatesActions } from '../../../../../store/templates';
import { Store } from '@ngrx/store';
import { ConfirmPopupComponent } from '../../../../../shared/modals/confirm-popup/confirm-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LayoutTemplateAddComponent } from '../../../../../shared/modals/layout-template-add/layout-template-add.component';
import { NodeType } from '../../../../../core/constants/node-type';

@Component({
  selector: 'app-template-menu',
  templateUrl: './template-menu.component.html',
  styleUrls: ['./template-menu.component.scss'],
})
export class TemplateMenuComponent implements OnInit {
  @Input() template: NodeTemplateModel;
  @Input() workspaceSlug: string;
  @Input() defaultTemplateId;

  public readonly NodeType = NodeType;

  constructor(private store: Store, private modalService: NgbModal) {}

  ngOnInit(): void {
    //
  }

  makeDefault() {
    this.store.dispatch(
      TemplatesActions.setDefaultNodeTemplateRequest({
        templateId: this.template.id,
        currentDefaultTemplateId: this.defaultTemplateId,
      }),
    );
  }

  copy() {
    this.store.dispatch(
      TemplatesActions.copyNodeTemplateRequest({
        templateId: this.template.id,
        serverNodeType: this.template.nodeType,
        workspaceSlug: this.workspaceSlug,
      }),
    );
  }

  saveAsLayoutTemplate(): void {
    const modelRef = this.modalService.open(LayoutTemplateAddComponent, {
      size: 'lg',
      backdrop: 'static',
    });
    modelRef.componentInstance.template = this.template;
    // modelRef.result
    //   .then((result: NodeTemplateModel[]) => {
    //     if (result.length === 0) {
    //       return;
    //     }
    //     // api call
    //   })
    //   .catch(res => {});
  }

  /*
  * todo (PatP): Delete can only be performed from the Archive Screen. Remove in future build.
  delete() {
      this.store.dispatch(
        TemplatesActions.deleteNodeTemplateRequest({
          templateId: this.template.id,
          serverNodeType: this.template.nodeType,
        }),
      );
  }
  */

  archive() {
    const modelRef = this.modalService.open(ConfirmPopupComponent, {
      size: 'md',
      backdrop: 'static',
    });
    modelRef.componentInstance.title = 'Archive Template';
    modelRef.componentInstance.message = 'Do you want to archive this Template?';
    modelRef.result.then((result: boolean) => {
      if (result) {
        this.store.dispatch(
          TemplatesActions.archiveNodeTemplateRequest({
            templateId: this.template.id,
            serverNodeType: this.template.nodeType,
          }),
        );
      }
    });
  }
}
