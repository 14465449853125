<app-modal [title]="modalTitle" [iconUrl]="iconUrl">
  <app-modal-instructions
    [html]="instructions"
    [template]="instructionsTemplate"
  ></app-modal-instructions>

  <div class="d-flex">
    <nav ngbNav #nav="ngbNav" class="nav-tabs wl-tabs" orientation="vertical">
      <!-- Tab 1 -->
      <ng-container ngbNavItem="1" [destroyOnHide]="false">
        <a ngbNavLink>
          <span>Your Links</span>
          <span class="badge badge-pill badge-secondary">{{ groups?.length ? (groups | linksCountGroups) : 0 }}</span>
        </a>
        <ng-template ngbNavContent>
          <div class="tab-content">
            <h2 class="text-center">Your Links</h2>
            <h6 class="text-center">Manage your link groups</h6>

            <div class="group-list">
              <ng-container *ngIf="groups?.length; else noData">
                <app-link-group-view
                  *ngFor="let group of groups; trackBy: trackBy"
                  [group]="group"
                ></app-link-group-view>
              </ng-container>
              <ng-template #noData>
                <app-no-data [type]="noDataTypeIds.workspaceLinks"></app-no-data>
              </ng-template>
            </div>

            <div class="manage-links">
              <app-btn-fa iconName="cog" (click)="manageLinks($event)">Manage Links</app-btn-fa>
            </div>
          </div>
        </ng-template>
      </ng-container>

      <!-- Tab 2 -->
      <ng-container ngbNavItem="2" [destroyOnHide]="false">
        <a ngbNavLink>
          <span>Your Files</span>
          <span class="badge badge-pill badge-secondary">{{ 0 }}</span>
        </a>
        <ng-template ngbNavContent>
          <div class="tab-content">
            <h2 class="text-center">Your Files</h2>
            <h6 class="text-center">Manage and access your workspace files</h6>

            <app-no-data [type]="noDataTypeIds.workspaceLinks"></app-no-data>
          </div>
        </ng-template>
      </ng-container>

      <!-- Tab 3 -->
      <ng-container ngbNavItem="3" [destroyOnHide]="false">
        <a ngbNavLink>
          <span>Zedulr Support</span>
          <span class="badge badge-pill badge-secondary">{{ 0 }}</span>
        </a>
        <ng-template ngbNavContent>
          <div class="tab-content">
            <h2 class="text-center">Zedulr Support</h2>
            <h6 class="text-center">Get live support assistance</h6>

            <div class="support-card">
              <div class="profile-section">
                <img src="https://via.placeholder.com/100" alt="Profile Picture">
                <h3>Alex Parkinson</h3>
                <p>Head of Support, StartGlobal</p>
              </div>

              <div class="support-info">
                <h2>We're here to help</h2>
                <p>Still got questions about how our process works? Want to know what kind of company suits best for
                  you? Questions about running business in the US?</p>
                <a href="#" class="contact-button">CONTACT SUPPORT</a>

                <div class="footer-section">
                  <a href="mailto:team@startglobal.co">
                    <img src="https://img.icons8.com/material-outlined/24/000000/new-post.png" alt="Email Icon">
                    team@startglobal.co
                  </a>
                  <a href="tel:+14154187725">
                    <img src="https://img.icons8.com/material-outlined/24/000000/phone.png" alt="Phone Icon">
                    +1 (415) 418 7725
                  </a>
                  <div class="support-text">
                    <img src="https://img.icons8.com/material-outlined/24/000000/support.png" alt="Support Icon">
                    SUPPORT YOU CAN COUNT ON
                  </div>
                </div>
              </div>
            </div>

          </div>
        </ng-template>
      </ng-container>

      <!-- Tab 4 -->
      <ng-container ngbNavItem="4" [destroyOnHide]="false">
        <a ngbNavLink>
          <span>Zedulr Release Notes</span>
          <span class="badge badge-pill badge-secondary">{{ 0 }}</span>
        </a>
        <ng-template ngbNavContent>
          <div class="tab-content">
            <h2 class="text-center">Zedulr Release Notes</h2>
            <h6 class="text-center">Latest updates and changes</h6>

            <div class="release-notes">
              <div class="release-item">
                <div class="date">14 Sep</div>
                <div class="description">Navigation components</div>
                <div class="status added">added</div>
              </div>

              <div class="release-item">
                <div class="date">15 Sep</div>
                <div class="description">Logo and footer mark</div>
                <div class="status updated">updated</div>
              </div>

              <div class="release-item">
                <div class="date">16 Sep</div>
                <div class="description">Support for international roadmaps</div>
                <div class="status added">added</div>
              </div>

              <div class="release-item">
                <div class="date">17 Sep</div>
                <div class="description">Duplicate documents feature</div>
                <div class="status added">added</div>
              </div>

              <div class="release-item">
                <div class="date">18 Sep</div>
                <div class="description">Missing component instances</div>
                <div class="status removed">removed</div>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-container>


    </nav>

    <div [ngbNavOutlet]="nav" class="ms-4 custom-content"></div>
  </div>
</app-modal>
