import { Action, createReducer, on } from '@ngrx/store';
import { GroupTagsPopoverState, initialGroupTagsPopoverState } from './group-tags-popover.state';
import { GroupTagsPopoverActions } from './index';
import { GroupTagsPopoverPage } from '../../core/constants/group-tags-popover-page';

const reducer = createReducer(
  initialGroupTagsPopoverState,
  on(GroupTagsPopoverActions.showTagGroupCreatePage, (state, { groupType, templateId }) => {
    return {
      ...initialGroupTagsPopoverState,
      page: GroupTagsPopoverPage.groupCreate,
      groupType,
      templateId,
    };
  }),

  on(GroupTagsPopoverActions.showTagGroupEditPage, (state, { groupId }) => {
    return {
      ...initialGroupTagsPopoverState,
      page: GroupTagsPopoverPage.groupEdit,
      groupId,
    };
  }),

  on(GroupTagsPopoverActions.showTagCreatePage, (state, { groupId, tagType }) => {
    return {
      ...initialGroupTagsPopoverState,
      page: GroupTagsPopoverPage.tagCreate,
      groupId,
      tagType,
    };
  }),

  on(GroupTagsPopoverActions.showTagEditPage, (state, { tagId, tagType, groupId }) => {
    return {
      ...initialGroupTagsPopoverState,
      page: GroupTagsPopoverPage.tagEdit,
      tagId,
      tagType,
      groupId,
    };
  }),
);

export function groupTagsPopoverReducer(state: GroupTagsPopoverState, action: Action) {
  return reducer(state, action);
}
