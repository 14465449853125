import { Action, createReducer, on } from '@ngrx/store';
import { NodeListPanelActions } from '../../store/node-list-panel/index';
import {
  NodeListPanelState,
  initialContentPanelState,
} from '../../store/node-list-panel/node-list-panel.state';
import { NodeTagsActions } from '../node-tags';
import { NodesActions } from '../nodes';
import { WorkspacesActions } from '../workspaces';

const reducer = createReducer<NodeListPanelState>(
  initialContentPanelState,

  on(WorkspacesActions.unloadCurrentWorkspace, state => {
    return initialContentPanelState;
  }),

  on(NodeListPanelActions.openNodeListPanel, (state, { node }) => {
    return {
      ...initialContentPanelState,
      selectedNode: node,
    };
  }),

  on(NodeListPanelActions.toggleSelectedNode, (state, { node }) => {
    let nodeIds;
    if (state.selectedNodeIds.indexOf(node.id) >= 0) {
      nodeIds = state.selectedNodeIds.filter(id => id !== node.id);
    } else {
      nodeIds = [...state.selectedNodeIds, node.id];
    }
    return {
      ...state,
      selectedNodeIds: nodeIds,
    };
  }),

  on(
    NodeListPanelActions.cancelSelection,
    NodesActions.removeNodeRequest,
    NodesActions.archiveNodesRequest,
    NodesActions.applyTemplateToNodesRequest,
    NodeTagsActions.addMultipleTagsToNodeRequest,
    state => {
      return {
        ...state,
        selectedNodeIds: [],
      };
    },
  ),

  on(NodeListPanelActions.startDraggingOfSelected, state => {
    return {
      ...state,
      isDraggingOfSelected: true,
    };
  }),

  on(NodeListPanelActions.endDraggingOfSelected, state => {
    return {
      ...state,
      isDraggingOfSelected: false,
    };
  }),

  on(NodeListPanelActions.updateSearchKeywordRequest, (state, { searchKeyword }) => ({
    ...state,
    searchKeyword,
  })),

  on(
    NodeListPanelActions.updateFilterTagIds,
    (state, { ids }): NodeListPanelState => ({
      ...state,
      filterTagIds: ids,
    }),
  ),

  on(
    NodeListPanelActions.updateFilterFolderIds,
    (state, { ids }): NodeListPanelState => ({
      ...state,
      filterFolderIds: ids,
    }),
  ),

  on(
    NodeListPanelActions.updateFilterTemplateIds,
    (state, { ids }): NodeListPanelState => ({
      ...state,
      filterTemplateIds: ids,
    }),
  ),

  on(
    NodeListPanelActions.updateFilterLayoutTemplateIds,
    (state, { ids }): NodeListPanelState => ({
      ...state,
      filterLayoutTemplateIds: ids,
    }),
  ),

  on(
    NodeListPanelActions.updateFilterUnassignedDateKeys,
    (state, { dateKeys }): NodeListPanelState => ({
      ...state,
      filterUnassignedDateKeys: dateKeys,
    }),
  ),
);

// Note: The exported reducer function is necessary as function calls are not supported by the AOT compiler.
export function nodeListPanelReducer(state: NodeListPanelState | undefined, action: Action) {
  return reducer(state, action);
}
