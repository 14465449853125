import { Action, createReducer, on } from '@ngrx/store';
import { initialNodeGroupsState, NodeGroupsState } from './node-groups.state';
import { NodeGroupsActions } from './index';
import { NodeGroupValueModel } from '../../core/models/node-group-value.model';

const clearNodeGroupsByNodeIdFromState = (
  state: NodeGroupsState,
  nodeId: number,
): NodeGroupsState => {
  let groupsByNodeId = state.nodeGroupsByNodeId[nodeId] || [];
  if (groupsByNodeId.length === 0) {
    return state;
  }

  let nodeGroupsById = { ...state.nodeGroupsById };
  groupsByNodeId.forEach(nodeGroup => {
    nodeGroupsById = {
      ...nodeGroupsById,
      [nodeGroup.id]: undefined,
    };
  });

  let nodeGroupsByGroupId = Object.keys(state.nodeGroupsByGroupId).reduce((list, key) => {
    return {
      ...list,
      [key]: state.nodeGroupsByGroupId[key].filter(ng => ng.parentNodeId !== nodeId),
    };
  }, {});

  return {
    ...(state || {}),

    nodeGroupsById: nodeGroupsById,
    nodeGroupsByGroupId: nodeGroupsByGroupId,
    nodeGroupsByNodeId: {
      ...state.nodeGroupsByNodeId,
      [nodeId]: [],
    },
  };
};

const addNodeGroupToState = (
  state: NodeGroupsState,
  nodeGroup: NodeGroupValueModel,
): NodeGroupsState => {
  return {
    ...(state || {}),

    nodeGroupsById: {
      ...state.nodeGroupsById,
      [nodeGroup.id]: nodeGroup,
    },

    nodeGroupsByNodeId: {
      ...state.nodeGroupsByNodeId,
      [nodeGroup.parentNodeId]: [
        ...(state.nodeGroupsByNodeId[nodeGroup.parentNodeId] || []).filter(
          group => group.id !== nodeGroup.id,
        ),
        nodeGroup,
      ],
    },

    nodeGroupsByGroupId: {
      ...state.nodeGroupsByGroupId,
      [nodeGroup.groupId]: [
        ...(state.nodeGroupsByGroupId[nodeGroup.groupId] || []).filter(
          group => group.id !== nodeGroup.id,
        ),
        nodeGroup,
      ],
    },
  };
};

const removeNodeGroupFromState = (
  state: NodeGroupsState,
  nodeGroup: NodeGroupValueModel,
): NodeGroupsState => {
  return {
    ...(state || {}),

    nodeGroupsById: {
      ...state.nodeGroupsById,
      [nodeGroup.id]: undefined,
    },

    nodeGroupsByNodeId: {
      ...state.nodeGroupsByNodeId,
      [nodeGroup.parentNodeId]: [
        ...(state.nodeGroupsByNodeId[nodeGroup.parentNodeId] || []).filter(
          group => group.id !== nodeGroup.id,
        ),
      ],
    },

    nodeGroupsByGroupId: {
      ...state.nodeGroupsByGroupId,
      [nodeGroup.groupId]: [
        ...(state.nodeGroupsByGroupId[nodeGroup.groupId] || []).filter(
          group => group.id !== nodeGroup.id,
        ),
      ],
    },
  };
};

const reducer = createReducer<NodeGroupsState>(
  initialNodeGroupsState,

  on(NodeGroupsActions.loadNodeGroupsFromNodeTree, (state, { nodeGroups }) => {
    return nodeGroups.reduce((state, nodeGroup) => {
      return addNodeGroupToState(state, nodeGroup);
    }, state);
  }),

  on(NodeGroupsActions.updateNodeGroupSuccess, (state, { nodeGroup }) => {
    return nodeGroup ? addNodeGroupToState(state, nodeGroup) : state;
  }),
);

export function nodeGroupsReducer(state: NodeGroupsState, action: Action) {
  return reducer(state, action);
}
