<div class="node-avatar-editable">
  <div class="node-avatar-editable__avatar-container">
    <app-node-avatar
      [profile]="profile"
      [profileIcon]="profileIcon"
      [fullName]="fullName"
      [initials]="initials"
      [size]="'lg'"
      [colorTheme]="colorTheme"
      [revertColor]="false"
      [hideTooltip]="true"
    ></app-node-avatar>
    <div class="node-avatar-editable__cover">
      <span class="fal fa-camera"></span>
    </div>
  </div>
</div>
