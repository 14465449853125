<ng-template #modalFooter>
  <ng-container *ngIf="!hideOptions?.includes('Can be deleted')">
    <button
      *ngIf="model?.canDelete == true"
      type="button"
      class="btn btn-sm btn-danger mr-auto"
      (click)="onDelete()"
    >
      Delete
    </button>
  </ng-container>

  <button type="button" class="btn btn-sm btn-outline-light" (click)="activeModal.dismiss()">
    Cancel
  </button>

  <button ngbAutofocus type="submit" class="btn btn-sm btn-primary" (click)="onSubmit()">
    Save
  </button>
</ng-template>

<app-modal title="Edit widget" [footerTemplate]="modalFooter">
  <div class="tag-edit">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <app-panel-subsection title="Title">
        <input
          type="text"
          class="form-control"
          placeholder="Title"
          required
          formControlName="title"
          [ngClass]="f.title.invalid && (f.title.dirty || isSubmitted) ? 'is-invalid' : ''"
        />
        <div class="invalid-feedback">
          Please provide a title
        </div>
      </app-panel-subsection>

      <!-- reference - bhAzCGjP -->
      <ng-container *ngIf="!hideOptions?.includes('Type')">
        <app-panel-subsection title="Type">
          <div class="ap-field-text">{{ type | widgetTypeLabel }}</div>
        </app-panel-subsection>
      </ng-container>

      <app-panel-subsection title="Data Choices" *ngIf="type | hasChoicesSupport">
        <app-widget-choices-editor
          [choices]="choices"
          (changedEvent)="onChoicesEdit($event)"
        ></app-widget-choices-editor>
      </app-panel-subsection>

      <!-- reference - bhAzCGjP -->
      <ng-container *ngIf="!hideOptions?.includes('Can be deleted')">
        <app-panel-subsection title="Can be deleted?" *ngIf="model">
          <div class="ap-field-text">{{ model.canDelete ? 'Yes' : 'No' }}</div>
        </app-panel-subsection>
      </ng-container>
    </form>
  </div>
</app-modal>
