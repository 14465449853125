<a
  *ngIf="displayValue"
  type="button"
  class="z-table__link z-table__input--number-link"
  [ngClass]="{
    'z-table__input--empty': cell?.value == null
  }"
  (click)="onStartEdit()"
>
  {{ displayValue }}
</a>
