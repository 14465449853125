import { Action, createReducer, on } from '@ngrx/store';
import { initialWorkspaceGroupsState, WorkspaceGroupsState } from './workspace-groups.state';
import { WorkspaceGroupsActions } from './index';
import { WorkspaceGroupModel } from '../../core/models/workspace-group.model';

const addToState = (
  state: WorkspaceGroupsState,
  group: WorkspaceGroupModel,
): WorkspaceGroupsState => {
  let byTemplate = state.byTemplate;
  if (group.nodeTemplateId) {
    byTemplate = {
      ...byTemplate,
      [group.nodeTemplateId]: [
        ...(byTemplate[group.nodeTemplateId] || []).filter(g => g.id !== group.id),
        group,
      ],
    };
  }
  let byNode = state.byNode;
  if (group.nodeId) {
    byNode = {
      ...byNode,
      [group.nodeId]: [...(byNode[group.nodeId] || []).filter(g => g.id !== group.id), group],
    };
  }

  return {
    ...state,
    byId: {
      ...state.byId,
      [group.id]: group,
    },
    byType: {
      ...state.byType,
      [group.type]: [...(state.byType[group.type] || []).filter(g => g.id !== group.id), group],
    },
    byTemplate: byTemplate,
    byNode: byNode,
  };
};

const removeFromState = (
  state: WorkspaceGroupsState,
  group: WorkspaceGroupModel,
): WorkspaceGroupsState => {
  let byTemplate = state.byTemplate;
  if (group.nodeTemplateId) {
    byTemplate = {
      ...byTemplate,
      [group.nodeTemplateId]: [
        ...(byTemplate[group.nodeTemplateId] || []).filter(g => g.id !== group.id),
      ],
    };
  }
  let byNode = state.byNode;
  if (group.nodeId) {
    byNode = {
      ...byNode,
      [group.nodeId]: (byNode[group.nodeId] || []).filter(g => g.id !== group.id),
    };
  }
  return {
    ...state,
    byId: {
      ...state.byId,
      [group.id]: undefined,
    },
    byType: {
      ...state.byType,
      [group.type]: [...(state.byType[group.type] || []).filter(g => g.id !== group.id)],
    },
    byTemplate: byTemplate,
    byNode: byNode,
  };
};

const reducer = createReducer(
  initialWorkspaceGroupsState,
  on(
    WorkspaceGroupsActions.loadWorkspaceGroupsSuccess,
    (state, { groups }): WorkspaceGroupsState => {
      return groups.reduce((state, group) => {
        return addToState(state, group);
      }, initialWorkspaceGroupsState);
    },
  ),

  on(WorkspaceGroupsActions.addWorkspaceGroupsSuccess, (state, { groups }) => {
    return groups.reduce((state, group) => {
      return addToState(state, group);
    }, state);
  }),

  on(WorkspaceGroupsActions.updateWorkspaceGroupSuccess, (state, { group }) => {
    return addToState(state, group);
  }),

  on(
    WorkspaceGroupsActions.deleteWorkspaceGroupRequest,
    (state: WorkspaceGroupsState, { group }) => {
      return removeFromState(state, group);
    },
  ),

  on(WorkspaceGroupsActions.sortWorkspaceGroupsRequest, (state, { groupIds }) => {
    let sortIndex = 0;
    return groupIds.reduce((state, groupId) => {
      return addToState(state, {
        ...state.byId[groupId],
        sortIndex: sortIndex++,
      });
    }, state);
  }),

  on(WorkspaceGroupsActions.updateSearchKeywordRequest, (state, { searchKeyword }) => ({
    ...state,
    searchKeyword,
  })),
);

export function workspaceGroupsReducer(state: WorkspaceGroupsState, action: Action) {
  return reducer(state, action);
}
