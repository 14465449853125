<ng-template #modalFooter>
  <div class="modal-picker-validation" *ngIf="validationMessage">
    <span class="modal-picker-validation__error">{{ validationMessage }}</span>
  </div>
  <div class="row m-0 w-100">
    <div class="col-6 p-0">
      <button type="button" class="btn btn-sm btn-link float-left" (click)="clearSelection()">
        Clear selection
      </button>
    </div>

    <div class="col-6 p-0 d-flex justify-content-end">
      <button
        ngbAutofocus
        type="button"
        class="btn btn-sm btn-outline-light"
        aria-label="Close"
        (click)="activeModal.dismiss(false)"
      >
        Cancel
      </button>

      <button
        type="button"
        class="btn btn-sm btn-primary ml-2"
        aria-label="Close"
        (click)="onSubmit()"
      >
        Select
      </button>
    </div>
  </div>
</ng-template>

<app-modal title="Select Activity" [footerTemplate]="modalFooter">
  <app-modal-instructions
    [html]="instructions"
    [template]="instructionsTemplate"
  ></app-modal-instructions>

  <div class="row mx-0 item-picked__search-bar">
    <div class="col-12">
      <app-search-box
        [searchKeyword]="keywords"
        sizeType="sm"
        (searchChanged)="search($event)"
      ></app-search-box>
    </div>
  </div>

  <ol class="item-picker__list item-picker__list--flex-column list-unstyled">
    <li
      *ngFor="let item of list; trackBy: trackBy"
      class="item-picker__list-item item-picker__list-item--flex-column"
    >
      <div
        class="list-item__brick"
        app-selectable-brick
        [color]="item.model.colorTheme"
        [icon]="item.model.icon"
        [label]="item.model.title"
        [isChecked]="item.selected"
        (click)="toggle(item)"
        [flexColumn]="true"
        [id]="item.model.id"
        [rightColTemplate]="checkBox"
      ></div>
      <ng-template #checkBox>
        <app-selectable-input
          [id]="item.model.id"
          [checked]="item.selected"
          [type]="maxSelection === 1 ? 'radio' : 'checkbox'"
        ></app-selectable-input>
      </ng-template>
    </li>
  </ol>
</app-modal>
