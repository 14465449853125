import { initialStatusBarState, StatusBarState } from './status-bar.state';
import { Action, createReducer, on } from '@ngrx/store';
import { StatusBarActions } from './index';

const reducer = createReducer<StatusBarState>(
  initialStatusBarState,
  on(StatusBarActions.closeStatusBar, state => ({ ...state, isOpen: false })),
  on(StatusBarActions.closeEmptyStatusBar, state => ({ ...state, isOpen: undefined })),
  on(StatusBarActions.openStatusBar, state => {
    return {
      ...state,
      isOpen: true,
    };
  }),

  on(StatusBarActions.addStatusLine, (state, { statusLine }) => {
    return {
      ...state,
      statusLines: [...state.statusLines.filter(line => line.key !== statusLine.key), statusLine],
    };
  }),
  on(StatusBarActions.removeStatusLine, (state, { key }) => {
    return {
      ...state,
      statusLines: state.statusLines.filter(line => line.key !== key),
    };
  }),
);

export function statusBarReducer(state: StatusBarState, action: Action) {
  return reducer(state, action);
}
