import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  defaultAnimationDuration,
  defaultAnimationTimingFunction,
} from '../../../core/constants/animation';

export const scaleAnimationFactory = ({
  triggerName,
  duration = defaultAnimationDuration,
}: {
  triggerName: string;
  duration?: string;
}) => {
  return trigger(triggerName, [
    state(
      'void',
      style({
        transform: 'scale(0)',
        opacity: 0,
      }),
    ),
    transition('void <=> *', [
      style({
        opacity: 1,
      }),
      animate(`${duration} ${defaultAnimationTimingFunction}`),
    ]),
  ]);
};
