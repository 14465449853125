import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, retry, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ErrorsActions } from '../errors';
import { NodeWidgetsActions } from './index';
import { WidgetService } from '../../core/services/widget.service';
import { NodesActions } from '../nodes';
import { NodeGroupsActions } from '../node-groups';
import { NodeWidgetRowsActions } from '../node-widget-rows';

@Injectable({ providedIn: 'root' })
export class NodeWidgetsEffects {
  constructor(private actions$: Actions, private widgetService: WidgetService) {}

  updateNodeWidgetRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NodeWidgetsActions.updateNodeWidgetRequest),
      mergeMap(action =>
        this.widgetService
          .updateNodeWidget(action.nodeId, action.widgetId, action.widgetProps)
          .pipe(
            retry(3),
            switchMap(data => [
              NodeWidgetsActions.updateNodeWidgetSuccess({
                nodeWidget: data.widgetValue,
              }),
              NodeGroupsActions.updateNodeGroupSuccess({
                nodeGroup: data?.groupValue,
              }),
              NodeWidgetRowsActions.updateNodeWidgetRowSuccess({
                nodeWidgetRow: {
                  id: data.widgetValue.rowId,
                  nodeId: action.nodeId,
                  rowNumber: 0,
                },
              }),
              NodeWidgetRowsActions.updateWidgetRowValueSuccess({
                nodeId: action.nodeId,
                widgetId: action.widgetId,
                rowNumber: data.widgetValue.rowId,
                widgetValue: data.widgetValue,
                groupValue: data.groupValue,
                referenceId: data.widgetValue?.referenceId,
              }),
              NodesActions.updateNodeEditedByUserDate({
                nodeIds: [action.nodeId],
              }),
            ]),
            catchError(error => {
              return of(ErrorsActions.goToErrorPage({ error }));
            }),
          ),
      ),
    ),
  );
}
