import { Component, OnInit, ViewChild } from '@angular/core';
import { TableComponent } from '../../table.component';
import { AbstractTableEditableCellComponent } from '../abstract-table-editable-cell.component';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'app-z-table-editable-workspace-rate',
  templateUrl: './table-editable-workspace-rate.component.html',
  styleUrls: ['./table-editable-workspace-rate.component.scss'],
})
export class TableEditableWorkspaceRateComponent extends AbstractTableEditableCellComponent
  implements OnInit {
  @ViewChild('ngSelect')
  public ngSelect: NgSelectComponent;

  protected defaultOptions = {
    list: [],
  };

  protected initialValue: any;

  constructor(public table: TableComponent) {
    super(table);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.cell.value = +this.cell?.value || null;
    this.initialValue = this.cell.value;
  }

  public onStartEdit() {
    this.previousValue = this.initialValue;
    this.startEdit.emit(this.buildEditEvent());
  }

  public onEndEdit() {
    if (!this.cell.value) {
      this.cell.value = this.initialValue;
    } else {
      this.initialValue = this.cell.value;
    }
    super.onEndEdit();
  }
}
