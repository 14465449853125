import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ErrorsActions } from '../errors';
import { WorkspaceTemplatesActions } from './index';
import { WorkspaceTemplatesService } from '../../core/services/workspace-templates.service';

@Injectable()
export class WorkspaceTemplatesEffects {
  constructor(
    private actions$: Actions,
    private workspaceTemplateService: WorkspaceTemplatesService,
  ) {}

  loadWorkspaceTemplatesRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceTemplatesActions.loadWorkspaceTemplatesRequest),
      mergeMap(() =>
        this.workspaceTemplateService.loadWorkspaceTemplates().pipe(
          map(templates =>
            WorkspaceTemplatesActions.loadWorkspaceTemplatesSuccess({
              templates,
            }),
          ),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  updateWorkspaceTemplateRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceTemplatesActions.updateWorkspaceTemplateRequest),
      mergeMap(action => {
        return this.workspaceTemplateService.updateWorkspaceTemplate(action.id, action.props).pipe(
          map(template =>
            WorkspaceTemplatesActions.updateWorkspaceTemplateSuccess({ id: action.id, template }),
          ),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        );
      }),
    ),
  );

  patchWorkspaceTemplateMetaRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceTemplatesActions.patchWorkspaceTemplateMetaRequest),
      mergeMap(action => {
        return this.workspaceTemplateService
          .patchWorkspaceTemplateMeta(action.id, action.metaData)
          .pipe(
            map(metaData =>
              WorkspaceTemplatesActions.patchWorkspaceTemplateMetaSuccess({
                id: action.id,
                metaData,
              }),
            ),
            catchError(error => {
              return of(ErrorsActions.goToErrorPage({ error }));
            }),
          );
      }),
    ),
  );

  deleteWorkspaceTemplateRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceTemplatesActions.deleteWorkspaceTemplateRequest),
      mergeMap(action => {
        return this.workspaceTemplateService.removeWorkspaceTemplate(action.id).pipe(
          map(responseMessage =>
            WorkspaceTemplatesActions.deleteWorkspaceTemplateSuccess({ id: action.id }),
          ),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        );
      }),
    ),
  );
}
