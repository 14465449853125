import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { ResponseMessage } from '../models/response-message.model';
import { ChatChannelCommentModel } from '../models/chat-channel-comment.model';
import { ServerChatChannelData } from '../models/server-chat-channel-data';
import { ChatChannelModel } from '../models/chat-channel.model';
import { ChatModalComponent } from '../../features/workspace-common/chatbar/chat-modal/chat-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({ providedIn: 'root' })
export class ChatService {
  constructor(private http: HttpClient, private readonly modalService: NgbModal) {}

  public openWorkspaceChatChannel(workspaceSlug: string): Observable<ServerChatChannelData> {
    return this.http
      .post<ResponseMessage<ServerChatChannelData>>(
        `${environment.apiBaseUrl}/workspace/${workspaceSlug}/chat/channel/add/workspace`,
        {},
      )
      .pipe(map(response => response.data));
  }

  public loadWorkspaceAllChannels(workspaceSlug: string): Observable<ServerChatChannelData[]> {
    return this.http
      .get<ResponseMessage<ServerChatChannelData[]>>(
        `${environment.apiBaseUrl}/workspace/${workspaceSlug}/chat/channels`,
      )
      .pipe(map(response => response.data));
  }

  public deleteChatChannel(channelId: number): Observable<ChatChannelModel> {
    return this.http
      .delete<ResponseMessage<ChatChannelModel>>(
        `${environment.apiBaseUrl}/workspace/chat/channel/${channelId}/remove`,
      )
      .pipe(map(response => response.data));
  }

  public openNodeChatChannel(
    workspaceSlug: string,
    nodeId: number,
  ): Observable<ServerChatChannelData> {
    return this.http
      .post<ResponseMessage<ServerChatChannelData>>(
        `${environment.apiBaseUrl}/workspace/${workspaceSlug}/chat/channel/add/node`,
        { node: nodeId },
      )
      .pipe(map(response => response.data));
  }

  public addCommentToChannel(
    channelId: number,
    comment: string,
  ): Observable<ChatChannelCommentModel> {
    return this.http
      .post<ResponseMessage<ChatChannelCommentModel>>(
        `${environment.apiBaseUrl}/workspace/chat/channel/${channelId}/comment/add`,
        { comment },
      )
      .pipe(map(response => response.data));
  }

  public deleteCommentInChannel(
    channelId: number,
    commentId: string,
  ): Observable<ChatChannelCommentModel> {
    return this.http
      .delete<ResponseMessage<ChatChannelCommentModel>>(
        `${environment.apiBaseUrl}/workspace/chat/channel/${channelId}/comment/${commentId}/remove`,
        {},
      )
      .pipe(map(response => response.data));
  }

  public commentUploadToChannel(
    channelId,
    fileBlob: Blob,
    comment: string,
  ): Observable<ChatChannelCommentModel> {
    const formData: FormData = new FormData();
    formData.append('file', fileBlob);
    if (comment) {
      formData.append('comment', comment);
    }
    return this.http
      .post<ResponseMessage<ChatChannelCommentModel>>(
        `${environment.apiBaseUrl}/workspace/chat/channel/${channelId}/comment/upload`,
        formData,
      )
      .pipe(map(response => response.data));
  }

  public updateCommentUploadToChannel(
    channelId: number,
    commentId: string,
    fileBlob: Blob,
    comment: string,
  ): Observable<ChatChannelCommentModel> {
    const formData: FormData = new FormData();
    formData.append('file', fileBlob);
    if (comment) {
      formData.append('comment', comment);
    }
    return this.http
      .patch<ResponseMessage<ChatChannelCommentModel>>(
        `${environment.apiBaseUrl}/workspace/chat/channel/${channelId}/comment/${commentId}/upload`,
        formData,
      )
      .pipe(map(response => response.data));
  }
  public updateComment(
    channelId: number,
    commentId: string,
    comment: string,
  ): Observable<ChatChannelCommentModel> {
    return this.http
      .patch<ResponseMessage<ChatChannelCommentModel>>(
        `${environment.apiBaseUrl}/workspace/chat/channel/${channelId}/comment/${commentId}/update`,
        { comment },
      )
      .pipe(map(response => response.data));
  }

  public searchChannelComments(
    channelId: number,
    results: number = 100,
    page: number = 0,
  ): Observable<ChatChannelCommentModel[]> {
    const options = {
      params: new HttpParams().set('results', `${results}`).set('page', `${page}`),
    };

    return this.http
      .get<ResponseMessage<ChatChannelCommentModel[]>>(
        `${environment.apiBaseUrl}/workspace/chat/channel/${channelId}/comments`,
        options,
      )
      .pipe(map(response => response.data));
  }

  public loadUnreadComments(workspaceSlug: string): Observable<ChatChannelCommentModel[]> {
    return this.http
      .get<ResponseMessage<ChatChannelCommentModel[]>>(
        `${environment.apiBaseUrl}/workspace/${workspaceSlug}/chat/unread-comments`,
      )
      .pipe(map(response => response.data));
  }

  public markChannelAsRead(channelId): Observable<ChatChannelCommentModel[]> {
    return this.http
      .post<ResponseMessage<ChatChannelCommentModel[]>>(
        `${environment.apiBaseUrl}/workspace/chat/channel/${channelId}/read`,
        {},
      )
      .pipe(map(response => response.data));
  }

  public openChatModal(): void {
    const modalRef = this.modalService.open(ChatModalComponent, {
      windowClass: 'chat-modal',
      size: 'xl',
      backdrop: 'static',
    });

    // Optional: Handling the result when the modal is closed
    modalRef.result.then(
      result => {
        // console.log(result);
      },
      reason => {
        // console.log(reason);
      },
    );
  }
}
