import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthService } from '../../core/services/auth.service';
import { AuthActions } from './index';
import {
  catchError,
  concatMap,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { ErrorsActions } from '../errors';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OverlayPopoverService } from '../../shared/components/overlay-popover/overlay-popover.service';
import { WorkspaceUsersActions } from '../workspace-users';
import { Store, select } from '@ngrx/store';
import { selectWorkspacesCurrentSlug } from '../workspaces/workspaces.selectors';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private modalService: NgbModal,
    private popoverService: OverlayPopoverService,
    private store: Store,
  ) {}

  patchCurrentUserRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.patchCurrentUserRequest),
      mergeMap(action =>
        this.authService.patchCurrentUser(action.userData).pipe(
          switchMap(() => {
            return EMPTY;
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  removeCurrentUserProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.removeCurrentUserProfileRequest),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      mergeMap(([action, slug]) =>
        this.authService.removeCurrentUserProfile().pipe(
          switchMap(() => [
            WorkspaceUsersActions.loadCurrentWorkspaceUsersRequest({ workspaceSlug: slug }),
          ]),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  patchCurrentUserMetaRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.patchCurrentUserMetaRequest),
      mergeMap(action => {
        return this.authService.patchCurrentUserMeta(action.metaData).pipe(
          map(metaData => AuthActions.patchCurrentUserMetaSuccess({ metaData })),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        );
      }),
    ),
  );

  logoutRequest$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.logoutRequest),
        tap(action => {
          this.modalService.dismissAll('Logout');
          this.popoverService.close();
        }),
      ),
    { dispatch: false },
  );
}
