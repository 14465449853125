<app-popover-layout [title]="page | templateFiguresPopoverPageTitle" (closePopover)="close()">
  <app-template-calculation-create
    *ngIf="page === TemplateFiguresPopoverPage.calculationCreate"
    (closeEvent)="close()"
  ></app-template-calculation-create>
  <app-template-calculation-widget-edit
    *ngIf="page === TemplateFiguresPopoverPage.calculationWidgetEdit"
    (closeEvent)="close()"
  ></app-template-calculation-widget-edit>
</app-popover-layout>
