import { Injectable, Injector } from '@angular/core';
import {
  ConnectionPositionPair,
  Overlay,
  OverlayConfig,
  OverlayPositionBuilder,
  PositionStrategy,
} from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { OverlayPopoverRef, PopoverContent } from './overlay-popover-ref';
import { OverlayPopoverComponent } from './overlay-popover.component';

export type PositionType = 'right' | 'top' | 'left' | 'bottom';

const top: ConnectionPositionPair = {
  originX: 'start',
  originY: 'top',
  overlayX: 'start',
  overlayY: 'bottom',
};

const right: ConnectionPositionPair = {
  originX: 'end',
  originY: 'center',
  overlayX: 'start',
  overlayY: 'center',
};

const bottom: ConnectionPositionPair = {
  originX: 'start',
  originY: 'bottom',
  overlayX: 'start',
  overlayY: 'top',
};

const left: ConnectionPositionPair = {
  originX: 'start',
  originY: 'center',
  overlayX: 'end',
  overlayY: 'center',
};

export type PopoverParams<T> = {
  origin?: HTMLElement;
  content: PopoverContent;
  data?: T;
  position?: PositionType;
  width?: string | number;
  height?: string | number;
  disableBackdropClose?: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class OverlayPopoverService {
  private popoverRef: OverlayPopoverRef;

  constructor(
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private injector: Injector,
  ) {}

  open<T>({
    origin,
    content,
    position,
    data,
    width,
    height,
    disableBackdropClose,
  }: PopoverParams<T>): OverlayPopoverRef<T> {
    const overlayRef = this.overlay.create(
      this.getOverlayConfig({ origin, width, height, position }),
    );
    const popoverRef = new OverlayPopoverRef<T>(overlayRef, content, data, {
      disableBackdropClose,
    });

    const injector = this.createInjector(popoverRef, this.injector);
    overlayRef.attach(new ComponentPortal(OverlayPopoverComponent, null, injector));

    this.popoverRef = popoverRef;
    return popoverRef;
  }

  close() {
    if (this.popoverRef) {
      this.popoverRef.close();
      this.popoverRef = undefined;
    }
  }

  private getOverlayConfig({ origin, width, height, position }): OverlayConfig {
    return new OverlayConfig({
      hasBackdrop: true,
      // width,
      height: height ? height : 'auto',
      backdropClass: 'cdk-overlay-dark-backdrop',
      positionStrategy: this.getOverlayPosition(origin, position),
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
    });
  }

  private getOverlayPosition(origin: HTMLElement, position: PositionType): PositionStrategy {
    let positionStrategy;

    if (origin) {
      positionStrategy = this.overlayPositionBuilder
        .flexibleConnectedTo(origin)
        .withPositions(this.getPositions(position))
        .withGrowAfterOpen(true)
        .withFlexibleDimensions(true)
        .withPush(true)
        .withViewportMargin(0);
    } else {
      positionStrategy = this.overlayPositionBuilder
        .global()
        .centerHorizontally()
        .centerVertically();
    }
    return positionStrategy;
  }

  createInjector(popoverRef: OverlayPopoverRef, injector: Injector) {
    const tokens = new WeakMap([[OverlayPopoverRef, popoverRef]]);
    return new PortalInjector(injector, tokens);
  }

  private getPositions(position: PositionType = 'right'): ConnectionPositionPair[] {
    if (position === 'top') {
      return [top, bottom, right, left];
    } else if (position === 'bottom') {
      return [bottom, top, right, left];
    } else if (position === 'left') {
      return [left, right, bottom, top];
    } else {
      return [right, left, bottom, top];
    }
  }
}
