import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toOptions',
})
export class ToOptionsPipe implements PipeTransform {
  transform(items: object[], bindLabel?: string): unknown {
    let options = [];
    if (items?.length > 0) {
      options = items.map(item => {
        return {
          id: item['id'],
          label: bindLabel ? item[bindLabel] : item['label'],
        };
      });
    }
    return options;
  }
}
