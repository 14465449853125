<ng-template #popContent>
  <app-node-avatar-menu
    [profile]="node?.profile"
    [nodeId]="node?.id"
    [nodeType]="node?.nodeType"
    [profileIcon]="node?.nodeMeta?.profileIcon"
  ></app-node-avatar-menu>
</ng-template>

<aside
  class="properties-panel modal-popover__header"
  [ngClass]="isPropertiesPanelOpen && node ? '' : 'properties-panel--closed'"
  cdkTrapFocus
>
  <form [formGroup]="form">
    <div class="properties-panel__content" *ngIf="node">
      <div class="modal-header modal--md">
        <h4 class="modal-title"><i class="fal fa-edit layout-mr-lg"></i>{{ node.title }}</h4>

        <button type="button" class="close" aria-label="Close" (click)="closePropertiesPanel()">
          <span aria-hidden="true">&times;</span>
        </button>

        <!-- Last Edited By
        <div class="properties-panel__status-container layout-mr-xl">
          <span class="properties-panel__status-label ap-status-label">Last Edited by</span>
          <app-edit-status
            *ngIf="node"
            [editedBy]="node.editedBy"
            [editedDate]="node?.editedDate"
          ></app-edit-status>
        </div>
        -->
      </div>

      <!-- Tab 1 -->
      <nav ngbNav #nav="ngbNav" class="properties-panel__tabs nav-tabs">
        <ng-container ngbNavItem="1" [destroyOnHide]="false">
          <a ngbNavLink>Template</a>
          <ng-template ngbNavContent>
            <div class="properties-panel__sheet">
              <!-- title -->
              <div class="properties-panel__nodetitle">
                <app-panel-subsection
                  class="properties-panel__subsection"
                  title="Title"
                  [backgroundTransparent]="true"
                >
                  <input
                    #titleInput
                    type="text"
                    class="form-control"
                    id="properties-panel-title"
                    placeholder="Title"
                    required
                    formControlName="title"
                    [ngClass]="title.invalid && title.dirty ? 'is-invalid' : ''"
                    (blur)="updateProp('title')"
                    (keydown)="onButtonKeyDown($event, 'title')"
                  />
                  <div class="invalid-feedback">
                    Please provide a title
                  </div>
                </app-panel-subsection>
              </div>
              <!-- template -->
              <app-panel-subsection
                class="properties-panel__subsection"
                [title]="
                  NodeUtils.isFolder(node)
                    ? 'Default ' +
                      (NodeUtils.getChildNodeType(node.nodeType) | nodeTypeLabel) +
                      ' template'
                    : 'Template'
                "
                [backgroundTransparent]="true"
              >
                <div class="form-group">
                  <select
                    class="form-control"
                    id="applied-template"
                    formControlName="appliedTemplate"
                    (change)="applyTemplate()"
                  >
                    <option *ngFor="let option of templateOptions" [ngValue]="option.id">{{
                      option.label
                    }}</option>
                  </select>
                </div>
              </app-panel-subsection>

              <!-- template details Projects-->
              <app-panel-subsection
                *ngIf="!NodeUtils.isFolder(node) && node.nodeType === NodeType.project"
                class="properties-panel__subsection"
                [title]="'Settings'"
                [backgroundTransparent]="true"
              >
                <div class="form-group">
                  <div class="row m-0 template-details">
                    <div class="col template-details">
                      <div class="row">
                        <div class="col-6">Allowed Activities:</div>
                        <ul class="col-6">
                          <li *ngFor="let template of node?.allowedTemplates">
                            {{ template.title }}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </app-panel-subsection>

              <!-- template details Assets-->
              <app-panel-subsection
                *ngIf="!NodeUtils.isFolder(node) && node.nodeType === NodeType.asset"
                class="properties-panel__subsection"
                [title]="'Settings'"
                [backgroundTransparent]="true"
              >
                <div class="form-group">
                  <div class="row m-0 template-details">
                    <div class="col template-details">
                      <p>
                        Allow Dispatch:
                        <span class="font-weight-bold float-right">
                          {{ node?.nodeTemplate?.allowDispatch ? 'ON' : 'OFF' }}</span
                        >
                      </p>
                      <p>
                        Cost Recording :
                        <span class="font-weight-bold float-right">
                          {{ node?.nodeTemplate?.allowRates ? 'ON' : 'OFF' }}
                        </span>
                      </p>
                      <p>
                        Highlight Duplicates:
                        <span class="font-weight-bold float-right">
                          {{ node?.nodeTemplate?.allowDuplicates ? 'ON' : 'OFF' }}</span
                        >
                      </p>
                      <p>
                        Item Assignments:
                        <span class="font-weight-bold float-right">
                          {{ node?.nodeTemplate?.behaviourType ? 'Multiple' : 'Single' }}</span
                        >
                      </p>
                      <p>
                        Resource Fields:
                        <span class="font-weight-bold float-right">
                          {{ node?.widgets | nodeWidgetsTitle }}</span
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </app-panel-subsection>
            </div>
          </ng-template>
        </ng-container>

        <!-- Tab 2 -->
        <ng-container
          ngbNavItem="3"
          [destroyOnHide]="false"
          *ngIf="NodeUtils.isFolder(node) == false"
        >
          <a ngbNavLink>Widgets</a>
          <ng-template ngbNavContent>
            <div class="properties-panel__sheet">
              <!-- widgets -->
              <ng-container *ngIf="NodeUtils.isFolder(node) == false">
                <div class="properties-panel__widget-list">
                  <app-leaf-widget-list
                    [widgets]="node.widgets"
                    (changeWidgetValueEvent)="changeWidgetValue($event, node?.id)"
                    (closeEvent)="closePropertiesPanel()"
                    [isEdit]="true"
                  ></app-leaf-widget-list>
                </div>
              </ng-container>

              <!-- announcements -->
              <ng-container *ngIf="node.nodeType === NodeType.project">
                <app-panel-subsection
                  class="properties-panel__subsection"
                  title="Announcement"
                  [backgroundTransparent]="true"
                >
                  <textarea
                    class="form-control"
                    row="4"
                    id="properties-panel-notifications"
                    placeholder="Write an announcement..."
                    formControlName="notifications"
                    (blur)="updateProp('notifications')"
                    (keydown)="onButtonKeyDown($event, 'notifications')"
                  ></textarea>
                </app-panel-subsection>
              </ng-container>
            </div>
          </ng-template>
        </ng-container>

        <!-- Tab 3 -->
        <ng-container ngbNavItem="4" [destroyOnHide]="false">
          <a ngbNavLink>Properties</a>
          <ng-template ngbNavContent>
            <div class="properties-panel__sheet">
              <!-- allowed layouts -->
              <app-panel-subsection
                class="properties-panel__subsection"
                title="Assigned Activities"
                [backgroundTransparent]="true"
                *ngIf="node.nodeType === NodeType.project"
              >
                <div class="properties-panel__brick-items">
                  <ng-container *ngFor="let template of node.allowedTemplates">
                    <span
                      app-selectable-brick
                      [label]="template.title"
                      [color]="template.colorTheme"
                      [icon]="template.icon"
                      [sizeType]="'md'"
                      class="properties-panel__brick"
                      (click)="openTemplatesPopover()"
                    ></span>
                    <sup
                      class="sup-default fa fa-badge-check"
                      *ngIf="template.isDefault"
                      ngbTooltip="This is the default template"
                    ></sup>
                  </ng-container>
                  <span
                    class="ap-no-content d-inline-block layout-padding-y layout-mr"
                    *ngIf="node.allowedTemplates?.length === 0"
                  >
                    If no Activities are selected, all Activities of relevant assignment template
                    will be allowed.
                  </span>
                  <span (click)="openTemplatesPopover()">
                    <app-square-button-add></app-square-button-add>
                  </span>
                </div>
              </app-panel-subsection>

              <!-- Profile Photo Section -->
              <div *ngIf="isProfilePhotoSectionShown">
                <app-panel-subsection
                  class="properties-panel__subsection"
                  title="Profile Photo"
                  [backgroundTransparent]="true"
                >
                  <app-node-avatar-editable
                    class="d-inline-block"
                    #popover="ngbPopover"
                    [profile]="node?.profile"
                    [profileIcon]="node.nodeMeta?.profileIcon"
                    [fullName]="node?.title"
                    [initials]="node?.title | toInitials"
                    [colorTheme]="node?.colorTheme"
                    [ngbPopover]="popContent"
                    [placement]="defaultPlacements"
                    triggers="manual"
                    container="body"
                    (click)="changePhoto(popover)"
                  ></app-node-avatar-editable>
                </app-panel-subsection>
              </div>

              <!-- Color Section -->
              <div *ngIf="isColorSectionShown">
                <app-panel-subsection
                  class="properties-panel__subsection"
                  title="Color"
                  [backgroundTransparent]="true"
                >
                  <div class="properties-panel__fields">
                    <div class="form-group">
                      <button
                        type="button"
                        [ngClass]="'background--' + (selectedColorTheme | colorString)"
                        class="picker-button btn"
                        (click)="onColourPicker()"
                      ></button>
                    </div>
                  </div>
                </app-panel-subsection>
              </div>

              <!-- tags -->
              <app-panel-subsection
                class="properties-panel__subsection"
                title="Tags"
                [backgroundTransparent]="true"
                *ngIf="NodeUtils.isFolder(node) == false"
              >
                <div class="properties-panel__brick-items">
                  <ng-container *ngFor="let nodeTag of node?.tags">
                    <span
                      app-selectable-brick
                      [label]="nodeTag.tag.title"
                      [color]="nodeTag.tag.colorTheme"
                      [icon]="nodeTag.tag.icon"
                      [sizeType]="'md'"
                      class="properties-panel__brick"
                      (click)="openTagsPopover()"
                    ></span>
                  </ng-container>
                  <span (click)="openTagsPopover()">
                    <app-square-button-add></app-square-button-add>
                  </span>
                </div>
              </app-panel-subsection>
            </div>
          </ng-template>
        </ng-container>

        <ng-container
          ngbNavItem="2"
          [destroyOnHide]="false"
          *ngIf="node.nodeType === NodeType.project || node.nodeType === NodeType.asset"
        >
          <a ngbNavLink *ngIf="node.nodeType === NodeType.project">Revenue Items</a>
          <a ngbNavLink *ngIf="node.nodeType === NodeType.asset">Cost Items</a>
          <ng-template ngbNavContent>
            <app-panel-subsection
              class="properties-panel__subsection"
              [backgroundTransparent]="true"
            >
              <div class="properties-panel__ztable">
                <app-z-table-node-rates
                  [node]="node"
                  [hideTypeColumnByDefault]="true"
                ></app-z-table-node-rates>
              </div>
            </app-panel-subsection>
          </ng-template>
        </ng-container>
      </nav>

      <div class="properties-panel__body">
        <div class="container-fluid">
          <div class="row justify-content-center">
            <div class="col-12" [ngbNavOutlet]="nav"></div>
          </div>
        </div>
      </div>

      <div class="properties-panel__footer">
        <div class="properties-panel__node-id mr-auto small">
          ID: <em>{{ node.id }}</em
          >. Create Date: <em>{{ node.createdDate | niceDate }}</em>
        </div>
        <div class="btn-group ml-auto" role="group">
          <button type="button" class="btn btn-md btn-primary" (click)="closePropertiesPanel()">
            Done
          </button>
        </div>
      </div>

      <app-content-loader scope="node-edit-panel"></app-content-loader>
    </div>
  </form>
</aside>
