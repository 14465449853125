import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AssignmentViewType } from '../../../core/constants/assignment-view-type';
import { CardLayoutType } from '../../../core/constants/card-layout-type';
import { CustomViewNodeType } from '../../../core/constants/custom-view-node-type';

@Component({
  selector: 'app-assignment-print-modal',
  templateUrl: './assignment-print-modal.component.html',
  styleUrls: ['./assignment-print-modal.component.scss'],
})
export class AssignmentPrintModalComponent implements OnInit, OnDestroy {
  private readonly subscription = new Subscription();
  public readonly CustomViewNodeType = CustomViewNodeType;
  public readonly AssignmentViewType = AssignmentViewType;
  public readonly CardLayoutType = CardLayoutType;

  @Input()
  public title: string;

  @Input()
  public header: string;

  @Input()
  public dateRange?: string;

  @Input()
  public pdfTableId?: number;

  @Input()
  public showOnlyDynamicContent?: boolean;

  constructor() {}

  ngOnInit(): void {
    //
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
