<button
  class="nav__button btn fal fa-message-pen"
  title="Diary Channels"
  [ngClass]="[totalUnreadCount > 0 ? 'nav-chat--is-active' : '']"
  (click)="openChatModal()"
>
  <span
    *ngIf="totalUnreadCount > 0"
    class="nav-chat__badge badge badge-pill badge--primary "
  >{{ totalUnreadCount }}</span
  >
</button>
