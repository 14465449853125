<section class="workspace-common">
  <app-sidebar
    class="workspace-common__sidebar"
    [disable]="sidebarLeft.disable"
    [collapsed]="sidebarLeft.collapsed"
    [position]="sidebarLeft.position"
    [size]="sidebarLeft.size"
    [fullCollapse]="sidebarLeft.fullCollapse"
    (collapsedEvent)="onSidebarLeftToggle($event)"
  >
    <ng-container [ngSwitch]="sidebarLeft.mode">
      <app-workspace-settings *ngSwitchCase="SideBarMode.Settings"></app-workspace-settings>
      <app-root-folder-list *ngSwitchDefault></app-root-folder-list>
    </ng-container>
  </app-sidebar>

  <app-sidebar
    class="workspace-common__sub-sidebar"
    [disable]="sidebarSubLeft.disable"
    [collapsed]="sidebarSubLeft.collapsed"
    [position]="sidebarSubLeft.position"
    [size]="sidebarSubLeft.size"
    [fullCollapse]="sidebarSubLeft.fullCollapse"
    (collapsedEvent)="onSidebarSubLeftToggle($event)"
  >
    <ng-container [ngSwitch]="sidebarSubLeft.mode">
      <app-node-templates *ngSwitchCase="SideBarMode.Templates"></app-node-templates>
      <app-node-list *ngSwitchDefault></app-node-list>
    </ng-container>
  </app-sidebar>

  <div class="workspace-common__content">
    <div class="workspace-common__content-scroller" appLazyScroller="workspace" cdkScrollable>
      <router-outlet></router-outlet>
    </div>

    <div class="workspace-common__content-footer">
      <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
    </div>

    <app-content-loader scope="workspace-content"></app-content-loader>
  </div>

  <app-sidebar
    [disable]="sidebarSubRight.disable"
    [collapsed]="sidebarSubRight.collapsed"
    [position]="sidebarSubRight.position"
    [size]="sidebarSubRight.size"
    [fullCollapse]="sidebarSubRight.fullCollapse"
    (collapsedEvent)="onSidebarRightToggle($event)"
  >
    <ng-container [ngSwitch]="sidebarSubRight.mode">
      <app-main-menu *ngSwitchDefault></app-main-menu>
    </ng-container>
  </app-sidebar>

  <app-sidebar
    [disable]="sidebarRight.disable"
    [collapsed]="sidebarRight.collapsed"
    [position]="sidebarRight.position"
    [size]="sidebarRight.size"
    [fullCollapse]="sidebarRight.fullCollapse"
    (collapsedEvent)="onSidebarRightToggle($event)"
  >
    <ng-container [ngSwitch]="sidebarRight.mode">
      <app-main-menu *ngSwitchDefault></app-main-menu>
    </ng-container>
  </app-sidebar>
</section>
