<div
  #input
  [ngClass]="[
    editable == false ? 'z-table__input--disabled' : '',
    cell.value?.length === 0 ? 'z-table__input--empty' : ''
  ]"
  class="z-table__input--assignment-tags"
  (click)="editable && onStartEdit()"
>
  <ng-container *ngFor="let item of cell.value; trackBy: trackBy">
    <div
      #input
      class="z-table__input z-table__input--assignment-tag"
      [ngClass]="[
        'background--' +
          (item?.tag?.colorTheme ? (item?.tag?.colorTheme | colorString) : 'transparent'),
        editable == false ? 'z-table__input--disabled' : '',
        !item?.tag ? 'z-table__input--empty' : ''
      ]"
    >
      <ng-container *ngIf="item?.tag">
        <span
          class="fal mr-1"
          [ngClass]="['fa-' + (item?.tag?.icon | iconString), item?.tag?.icon ? '' : 'invisible']"
        ></span>
        <span>{{ item?.tag?.title }}</span>
      </ng-container>

      <span class="assignment-tag__empty-text" *ngIf="!item?.tag">{{ 'Empty' }}</span>
    </div>
  </ng-container>

  <span class="z-table__input--empty" *ngIf="cell.value?.length === 0">
    {{ 'Empty' }}
  </span>
</div>
