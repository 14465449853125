import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TemplateTypeViewModel } from '../../../../../core/models/template-type-view.model';
import { NodeType } from '../../../../../core/constants/node-type';

@Component({
  selector: 'app-template-type-item',
  templateUrl: './template-type-item.component.html',
  styleUrls: ['./template-type-item.component.scss'],
})
export class TemplateTypeItemComponent implements OnInit {
  @Input() typeViewModel: TemplateTypeViewModel;
  @Input() selectedTemplateId: number;
  @Input() selectedNodeType: NodeType;
  @Input() slug: string;

  @Output() createTemplateEvent = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  createTemplate() {
    this.createTemplateEvent.emit();
  }
}
