import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { ErrorsActions } from '../errors';
import { LayoutTemplatesActions } from './index';
import { LayoutTemplateService } from '../../core/services/layout-template.service';
import { LayoutTemplateModel } from '../../core/models/layout-template.model';
import { TemplatesActions } from '../templates';
import { WorkspaceGroupsActions } from '../workspace-groups';
import { TemplateTagsActions } from '../template-tags';
import { TemplateWidgetsActions } from '../template-widgets';
import { TagsActions } from '../tags';
import { WidgetsActions } from '../widgets';
import { LoaderService } from '../../shared/components/content-loader/loader.service';
import { UploadService } from '../../core/services/upload.service';
import { environment } from '../../../environments/environment';
import { ResponseMessage } from '../../core/models/response-message.model';
import { FileModel } from '../../core/models/file.model';
import { select, Store } from '@ngrx/store';
import { selectWorkspacesCurrentSlug } from '../workspaces/workspaces.selectors';

@Injectable()
export class LayoutTemplatesEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly layoutTemplateService: LayoutTemplateService,
    private readonly loaderService: LoaderService,
    private readonly uploadService: UploadService,
    private readonly store: Store,
  ) {}

  loadLayoutTemplatesRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LayoutTemplatesActions.loadLayoutTemplatesRequest),
      tap(action => {
        this.loaderService.showScopedLoader('layout-content');
      }),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      mergeMap(([action, slug]) =>
        this.layoutTemplateService.loadLayoutTemplates(slug).pipe(
          switchMap(templates => {
            return of(
              LayoutTemplatesActions.loadLayoutTemplatesSuccess({
                layoutTemplates: templates,
              }),
            );
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  loadLayoutTemplatesSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LayoutTemplatesActions.loadLayoutTemplatesSuccess),
        tap(action => this.loaderService.hideScopedLoader('layout-content')),
      ),
    { dispatch: false },
  );

  addLayoutTemplateRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LayoutTemplatesActions.addLayoutTemplateRequest),
      mergeMap(action =>
        this.layoutTemplateService.createLayoutTemplate(action.nodeTemplateId, action.payload).pipe(
          switchMap((template: LayoutTemplateModel) => {
            if (action.payload?.profile) {
              return [
                LayoutTemplatesActions.addLayoutTemplateSuccess({ layoutTemplate: template }),
                LayoutTemplatesActions.uploadLayoutTemplateProfileRequest({
                  layoutTemplateId: template?.id,
                  file: action.payload?.profile,
                }),
              ];
            }
            return of(
              LayoutTemplatesActions.addLayoutTemplateSuccess({ layoutTemplate: template }),
            );
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  uploadLayoutTemplateProfileRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LayoutTemplatesActions.uploadLayoutTemplateProfileRequest),
      mergeMap(action =>
        this.uploadService
          .uploadFiles(
            `${environment.apiBaseUrl}/workspace/layout-template/${action.layoutTemplateId}/profile/add`,
            action.file,
            `${action?.file?.name}-${new Date().getTime()}`,
          )
          .pipe(
            switchMap((response: ResponseMessage<FileModel>) => {
              return of(
                LayoutTemplatesActions.uploadLayoutTemplateProfileSuccess({
                  layoutTemplateId: action.layoutTemplateId,
                  file: response?.data[0],
                }),
              );
            }),
            catchError(error => {
              return of(ErrorsActions.goToErrorPage({ error }));
            }),
          ),
      ),
    ),
  );

  installLayoutTemplateRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LayoutTemplatesActions.installLayoutTemplateRequest),
      mergeMap(action =>
        this.layoutTemplateService.installLayoutTemplate(action.slug, action.layoutTemplateId).pipe(
          switchMap((template: any) => {
            return [
              LayoutTemplatesActions.installLayoutTemplateSuccess({
                layoutTemplateId: action.layoutTemplateId,
              }),
              WorkspaceGroupsActions.addWorkspaceGroupsSuccess({ groups: template?.data?.groups }),
              TemplateTagsActions.addTemplateTagsSuccess({
                templateTags: template?.data?.nodeTemplateTags,
                groupId: null,
                replace: false,
              }),
              TemplateWidgetsActions.addTemplateWidgetsSuccess({
                templateWidgets: template?.data?.nodeTemplateWidgets,
                groupId: null,
                replace: false,
              }),
              TemplatesActions.addNodeTemplatesSuccess({
                templates: template?.data?.nodeTemplates,
              }),
              TagsActions.loadTagsSuccess({ tags: template?.data?.tags }),
              WidgetsActions.addWidgetsSuccess({ widgets: template?.data?.widgets }),
            ];
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  deleteLayoutTemplateRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LayoutTemplatesActions.deleteLayoutTemplateRequest),
      mergeMap(action =>
        this.layoutTemplateService.removeLayoutTemplate(action.layoutTemplateId).pipe(
          switchMap(() => {
            return of(
              LayoutTemplatesActions.deleteLayoutTemplateSuccess({
                layoutTemplateId: action.layoutTemplateId,
              }),
            );
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );
}
