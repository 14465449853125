import { createAction, props } from '@ngrx/store';
import {
  WorkspaceUrlEditableProps,
  WorkspaceUrlModel,
} from '../../core/models/workspace-url.model';

export const loadCurrentWorkspaceUrlsRequest = createAction(
  '[Workspace urls] load current workspace urls request',
  props<{ workspaceSlug: string }>(),
);

export const loadCurrentWorkspaceUrlsSuccess = createAction(
  '[Workspace urls] load current workspace urls success',
  props<{ urls: WorkspaceUrlModel[] }>(),
);

export const addWorkspaceUrlRequest = createAction(
  '[Workspace urls] add workspace url request',
  props<{
    groupId: number;
    title: string;
    url: string;
    sortIndex?: number;
  }>(),
);

export const addWorkspaceUrlSuccess = createAction(
  '[Workspace urls] add workspace url success',
  props<{
    url: WorkspaceUrlModel;
  }>(),
);

export const updateWorkspaceUrlRequest = createAction(
  '[Workspace urls] update workspace url request',
  props<{ urlId: number; props: Partial<WorkspaceUrlEditableProps> }>(),
);

export const updateWorkspaceUrlSuccess = createAction(
  '[Workspace urls] update workspace url success',
  props<{ url: WorkspaceUrlModel }>(),
);

export const deleteWorkspaceUrlRequest = createAction(
  '[Workspace urls] delete workspace url request',
  props<{ groupId: number; urlId: number }>(),
);

export const sortWorkspaceUrlsRequest = createAction(
  '[Workspace urls] sort workspace urls request',
  props<{ groupId: number; urlIds: number[] }>(),
);
