import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from './modal.component';
import { ContentLoaderModule } from '../../content-loader/content-loader.module';
import { ModalHeaderComponent } from './modal-header/modal-header.component';
import { ModalFooterComponent } from './modal-footer/modal-footer.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalInstructionsComponent } from './modal-instructions/modal-instructions.component';
import { CdkScrollableModule } from '@angular/cdk/scrolling';

@NgModule({
  declarations: [
    ModalComponent,
    ModalHeaderComponent,
    ModalFooterComponent,
    ModalInstructionsComponent,
  ],
  imports: [CommonModule, ContentLoaderModule, NgbModule, CdkScrollableModule],
  exports: [ModalComponent, ModalHeaderComponent, ModalFooterComponent, ModalInstructionsComponent],
  providers: [],
})
export class ModalModule {}
