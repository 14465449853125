import { Action, createReducer, on } from '@ngrx/store';
import { GroupLinksPopoverState, initialGroupLinksPopoverState } from './group-links-popover.state';
import { GroupLinksPopoverActions } from './index';
import { GroupLinksPopoverPage } from '../../core/constants/group-links-popover-page';

const reducer = createReducer(
  initialGroupLinksPopoverState,
  on(GroupLinksPopoverActions.showLinkGroupCreatePage, state => {
    return {
      ...initialGroupLinksPopoverState,
      page: GroupLinksPopoverPage.groupCreate,
    };
  }),
  on(GroupLinksPopoverActions.showLinkGroupEditPage, (state, { groupId }) => {
    return {
      ...initialGroupLinksPopoverState,
      page: GroupLinksPopoverPage.groupEdit,
      groupId,
    };
  }),
  on(GroupLinksPopoverActions.showLinkCreatePage, (state, { groupId }) => {
    return {
      ...initialGroupLinksPopoverState,
      page: GroupLinksPopoverPage.linkCreate,
      groupId,
    };
  }),
  on(GroupLinksPopoverActions.showLinkEditPage, (state, { groupId, linkId }) => {
    return {
      ...initialGroupLinksPopoverState,
      page: GroupLinksPopoverPage.linkEdit,
      groupId,
      linkId,
    };
  }),
);

export function groupLinksPopoverReducer(state: GroupLinksPopoverState, action: Action) {
  return reducer(state, action);
}
