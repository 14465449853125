<ng-template #assignmentMenuTemplate>
  <app-assignment-menu
    [assignment]="assignment"
    [linksWidgets]="
      assignment?.reference.widgets
        | widgetTypeFilter: [WidgetType.url, WidgetType.urlList]
        | widgetSort: [WidgetType.urlList, WidgetType.url]
    "
  ></app-assignment-menu>
</ng-template>

<div
  #input
  class="z-table__input z-table__input--assignment-menu"
  [ngClass]="[editable == false ? 'z-table__input--disabled' : '']"
>
  <div
    class="assignment-table__menu-icon fal fa-bars"
    [ngbPopover]="assignmentMenuTemplate"
    container="body"
    [placement]="defaultPlacements"
  ></div>
</div>
