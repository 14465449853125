import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store';
import { TemplatesActions } from '../../../../store/templates';
import { NodeType } from '../../../../core/constants/node-type';

@Component({
  selector: 'app-new-template',
  templateUrl: './new-template.component.html',
  styleUrls: ['./new-template.component.scss'],
})
export class NewTemplateComponent implements OnInit {
  public form: UntypedFormGroup;
  public submitted = false;
  public loading = false;

  slug: string;
  serverNodeType: NodeType;
  rootFolderId: number;

  // convenience getter for easy access to form fields
  public get f() {
    return this.form.controls;
  }

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private store: Store<AppState>,
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      title: ['', Validators.required],
    });
  }

  onSubmit() {
    this.submitted = true;
    if (this.form.valid) {
      this.loading = true;
      const title = this.f.title.value;

      this.store.dispatch(
        TemplatesActions.addNodeTemplateRequest({
          workspaceSlug: this.slug,
          title,
          serverNodeType: this.serverNodeType,
          parentFolderId: this.rootFolderId,
        }),
      );

      this.activeModal.close();
    }
  }
}
