import { Component, EventEmitter, Input, OnDestroy, Output, TemplateRef } from '@angular/core';
import { Subscription } from 'rxjs';

export enum SideBarPosition {
  Left,
  Right,
}

export enum SideBarSize {
  Small,
  Medium,
  Large,
  XLarge,
}

export enum SideBarMode {
  MainMenu,
  FolderList,
  NodeList,
  NodeSort,
  Templates,
  Settings,
  Notifications,
}

@Component({
  selector: 'app-sidebar',
  templateUrl: 'sidebar.component.html',
  styleUrls: ['sidebar.component.scss'],
})
export class SidebarComponent implements OnDestroy {
  private readonly subscription: Subscription;
  public readonly SideBarPosition = SideBarPosition;
  public readonly SideBarSize = SideBarSize;

  @Output()
  public readonly collapsedEvent: EventEmitter<boolean>;

  @Input()
  public disable: boolean;

  @Input()
  public collapsed: boolean;

  @Input()
  public position: SideBarPosition;

  @Input()
  public size: SideBarSize;

  @Input()
  public fullCollapse: boolean;

  @Input()
  public headerTemplate: TemplateRef<any>;

  @Input()
  public footerTemplate: TemplateRef<any>;

  constructor() {
    this.subscription = new Subscription();
    this.collapsedEvent = new EventEmitter<boolean>();
    this.position = SideBarPosition.Left;
    this.size = SideBarSize.Medium;
    this.collapsed = false;
    this.fullCollapse = false;
    this.disable = false;
  }

  public onToggle(collapsed: boolean) {
    this.collapsed = collapsed;
    this.collapsedEvent.emit(this.collapsed);
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
