<div class="template-calculation">
  <app-panel-subsection [title]="calcWidget.widget.title" [headerToolbarTemplate]="toolbar" [backgroundTransparent]="false">
    <table class="ap-table table table-hover">
      <thead class="thead-light">
      <tr>
        <th scope="col">#</th>
        <th scope="col">Label</th>
        <th scope="col">Calculation</th>
        <th scope="col">Type</th>
        <th scope="col ">Actions</th>
      </tr>
      </thead>
      <tbody>

      <tr
        class="template-calculation__task-row"
      >
        <td>A</td>
        <td class="template-calculation__task-col-label">

          {{ calcWidget.widgetA.widget.title }}
        </td>
        <td>
          ----
        </td>
        <td>{{ calcWidget.widgetA.widget.widgetType | widgetTypeLabel }}</td>
        <td>
            <span #widgetAOrigin>
              <app-btn-edit (click)="editWidget(widgetAOrigin, calcWidget.widgetA.id)"></app-btn-edit>
            </span>

        </td>
      </tr>
      <tr
        class="template-calculation__task-row"

      >
        <td>B</td>
        <td class="template-calculation__task-col-label">

          {{ calcWidget.widgetB.widget.title }}
        </td>
        <td>
          ----
        </td>
        <td>{{ calcWidget.widgetB.widget.widgetType | widgetTypeLabel }}</td>
        <td>
            <span #widgetBOrigin>
              <app-btn-edit (click)="editWidget(widgetBOrigin, calcWidget.widgetB.id)"></app-btn-edit>
            </span>

        </td>
      </tr>
      <tr
        class="template-calculation__task-row"

      >
        <td>C</td>
        <td class="template-calculation__task-col-label">
          {{ calcWidget.widget.title }}
        </td>
        <td>
          {{calcWidget.widget.calculationMethod | calculationMethodLabel}}
        </td>
        <td>{{ calcWidget | calculationResultTypeLabel }}</td>
        <td>
            <span #calOrigin>
              <app-btn-edit (click)="editWidget(calOrigin, calcWidget.id)"></app-btn-edit>
            </span>

        </td>
      </tr>
      </tbody>
    </table>

  </app-panel-subsection>
</div>

<ng-template #toolbar>
  <div class="template-calculation__toolbar">
    <button
      #editOrigin
      type="button"
      class="btn template-calculation__btn fal fa-edit"
      (click)="editWidget(editOrigin, calcWidget.id)"
    ></button>

    <button
      #editOrigin
      type="button"
      class="btn template-calculation__btn fal fa-times"
      (click)="deleteWidget(editOrigin, calcWidget.id)"
    ></button>

    <button
      *ngIf="calIds.indexOf(calcWidget.id) > 0"
      type="button"
      class="btn template-calculation__btn fal fa-arrow-up"
      (click)="moveCalUp(calcWidget.id)"
    ></button>
    <button
      *ngIf="calIds.indexOf(calcWidget.id) < calIds.length - 1"
      type="button"
      class="btn template-calculation__btn fal fa-arrow-down"
      (click)="moveCalDown(calcWidget.id)"
    ></button>

  </div>
</ng-template>
