import { TypedAction } from '@ngrx/store/src/models';
import { BtnType } from '../../core/constants/btn-type';

export interface StatusBarState {
  isOpen: boolean;
  statusLines: StatusLine[];
}

export const initialStatusBarState: StatusBarState = {
  isOpen: undefined,
  statusLines: [],
};

export interface StatusBarAction {
  action?: TypedAction<any>;
  call?: () => void;
  label: string;
  btnType?: BtnType;
  disabled?: boolean;
}

export type StatusLineKey =
  | 'leaf-selection'
  | 'assignment-selection'
  | 'duplicated-asset'
  | 'calendar-date-selected'
  | 'figures-info';

export interface StatusLine {
  key: StatusLineKey; // key needs to be unique in the statusLine array
  messages: string[];
  actions: StatusBarAction[];
}
