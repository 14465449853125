import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { GanttEditableProps, GanttModel } from '../models/gantt.model';
import { ResponseMessage } from '../models/response-message.model';

@Injectable({
  providedIn: 'root',
})
export class GanttService {
  constructor(private http: HttpClient) {}

  public loadGantts(workspaceSlug: string): Observable<GanttModel[]> {
    return this.http
      .get<ResponseMessage<GanttModel[]>>(
        `${environment.apiBaseUrl}/workspace/${workspaceSlug}/gantts`,
      )
      .pipe(map(response => response.data));
  }

  public createGantt(
    workspaceSlug: string,
    projectId: number,
    primaryTagId: number,
    startDate: string,
    endDate: string,
    assignmentCount: number,
    title: string,
    notes: string,
    budget?: number,
    cost?: number,
  ): Observable<GanttModel> {
    return this.http
      .post<ResponseMessage<GanttModel>>(
        `${environment.apiBaseUrl}/workspace/${workspaceSlug}/gantt/add`,
        {
          node: projectId,
          tag: primaryTagId,
          startDate,
          endDate,
          assignmentCount,
          title,
          tooltip: notes,
          budget: budget || 0,
          cost: cost || 0,
        },
      )
      .pipe(map(response => response.data));
  }

  public updateGantt(ganttId: number, ganttProps: Partial<GanttEditableProps>): Observable<object> {
    return this.http.patch<object>(`${environment.apiBaseUrl}/workspace/gantt/${ganttId}/update`, {
      ...ganttProps,
    });
  }

  public removeGantt(ganttId: number): Observable<object> {
    return this.http.delete(`${environment.apiBaseUrl}/workspace/gantt/${ganttId}/remove`);
  }
}
