import { Component, OnInit } from '@angular/core';
import { TableComponent } from '../../table.component';
import { AbstractTableEditableCellComponent } from '../abstract-table-editable-cell.component';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-z-table-number-link',
  templateUrl: './table-number-link.component.html',
  styleUrls: ['./table-number-link.component.scss'],
})
export class TableNumberLinkComponent extends AbstractTableEditableCellComponent implements OnInit {
  protected readonly specialKeys = [
    'Enter',
    'Escape',
    'Tab',
    'Backspace',
    'ArrowLeft',
    'ArrowRight',
    'Delete',
  ];

  protected defaultOptions = {
    min: -99999999.99,
    max: 99999999.99,
    decimals: 2,
  };

  public displayValue: any;

  constructor(public table: TableComponent, protected decimalPipe: DecimalPipe) {
    super(table);
  }

  protected addFormatting() {
    const _float = parseFloat(this.displayValue);
    this.displayValue =
      isNaN(_float) === false
        ? this.decimalPipe.transform(_float, `1.${this.options.decimals}-${this.options.decimals}`)
        : '';
  }

  protected removeFormatting() {
    this.displayValue = String(this.displayValue);
    if (this.displayValue == null) {
      this.displayValue = '';
      return;
    }
    const _float = parseFloat(this.displayValue.replace(/[^\d.-]/g, ''));
    this.displayValue = isNaN(_float) === false ? _float : '';
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.displayValue = String(this.cell.value);
    this.removeFormatting();
    this.addFormatting();
  }

  public onStartEdit() {
    super.onStartEdit();
  }
}
