export class ArchivePopoverState {
  isLoading: boolean;
  referenceCountRequired: boolean;
  referenceCount: number;
}

export const initialArchivePopoverState: ArchivePopoverState = {
  isLoading: false,
  referenceCountRequired: false,
  referenceCount: undefined,
};
