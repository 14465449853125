import { createSelector } from '@ngrx/store';
import { TemplateWidgetsSelectors } from '../template-widgets';
import { NodeTagsSelectors } from '../node-tags';
import { NodeRatesSelectors } from '../node-rates';
import { NodeWidgetsSelectors } from '../node-widgets';
import { NodeTemplatesSelectors } from '../templates';
import { TagsSelectors } from '../tags';
import { WidgetsSelectors } from '../widgets';
import { NodeTemplateModel } from '../../core/models/node-template.model';
import { NodeUtils } from '../../core/utils/node.util';
import {
  selectAssignmentsById,
  selectAssignmentsByParentId,
  selectNodesById,
  selectNodesFilteredByNodeType,
} from './nodes.selectors';
import { NodeRateValuesSelectors } from '../node-rate-values';
import { WorkspaceGroupsSelectors } from '../workspace-groups';
import { WorkspaceUserMetaSelectors } from '../workspace-user-meta';
import { NodesSelectors } from './index';
import { NodeModel } from '../../core/models/node.model';
import { NodeType } from '../../core/constants/node-type';
import { SortingType } from '../../core/models/sorting-option.model';
import { NodeWidgetRowsSelectors } from '../node-widget-rows';
import { NodeGroupsSelectors } from '../node-groups';
import { TimesheetsSelectors } from '../timesheets';
import { selectWorkspaceContent } from '../app-layout/app-layout.selectors';
import { AssignmentDisplayMode } from '../../core/constants/assignment-display-mode';
import { NodeTagModel } from '../../core/models/node-tag.model';
import { selectAllTagsWithKeyword } from '../tags/tags.selectors';
import { selectAllPrimaryTagsWithGroupLabel } from '../template-tags/template-primary-tags.selectors';
import { AssignmentLayoutMode } from '../../core/constants/assignment-layout-mode';

export const selectCurrentDateKeys = createSelector(
  WorkspaceUserMetaSelectors.selectAssignmentDateRange,
  dateRange => {
    const dateKeys = [];
    const start = new Date(dateRange.start);
    const end = new Date(dateRange.end);
    let loop = new Date(start);
    while (loop <= end) {
      dateKeys.push(loop.toISOString().substring(0, 10));
      let newDate = loop.setDate(loop.getDate() + 1);
      loop = new Date(newDate);
    }
    return dateKeys;
  },
);

export const selectWorkspaceEnrichmentData = createSelector(
  //WorkspaceUserMetaSelectors.selectUserNodeMetasById,
  TagsSelectors.selectTagsById,
  WidgetsSelectors.selectWidgetsById,
  NodeTemplatesSelectors.selectNodeTemplatesById,
  WorkspaceGroupsSelectors.selectWorkspaceGroupsById,
  WorkspaceGroupsSelectors.selectWorkspaceGroupsByNodeTemplateId,
  TemplateWidgetsSelectors.selectEnrichedTemplateWidgetsByTemplateId,
  TimesheetsSelectors.selectTimesheetsByNodeId,
  (
    //nodeMetasById,
    tagsById,
    widgetsById,
    nodeTemplatesById,
    groupsById,
    groupsByTemplateId,
    templateWidgetsByTemplateId,
    timesheetsByNodeId,
  ) => {
    return {
      //nodeMetasById,
      tagsById,
      widgetsById,
      nodeTemplatesById,
      groupsById,
      groupsByTemplateId,
      templateWidgetsByTemplateId,
      timesheetsByNodeId,
    };
  },
);

export const selectNodeEnrichmentData = createSelector(
  NodeTagsSelectors.selectNodeTagsByNodeId,
  NodeRatesSelectors.selectNodeRateNodes,
  NodeRateValuesSelectors.selectNodeRateValueNodes,
  NodeWidgetsSelectors.selectNodeWidgetsByNodeId,
  NodeWidgetRowsSelectors.selectNodeWidgetRowsByNodeId,
  NodeGroupsSelectors.selectNodeGroupsByNodeId,
  (
    nodeTagsByNodeId,
    nodeRatesByNodeId,
    nodeRateValuesByNodeId,
    nodeWidgetsByNodeId,
    nodeWidgetRowsByNodeId,
    nodeGroupsByNodeId,
  ) => {
    return {
      nodeTagsByNodeId,
      nodeRatesByNodeId,
      nodeRateValuesByNodeId,
      nodeWidgetsByNodeId,
      nodeWidgetRowsByNodeId,
      nodeGroupsByNodeId,
    };
  },
);

export const selectTableFilterIdsData = createSelector(
  WorkspaceUserMetaSelectors.selectTableProjectIds,
  WorkspaceUserMetaSelectors.selectTableAssetIds,
  WorkspaceUserMetaSelectors.selectTableTagIds,
  WorkspaceUserMetaSelectors.selectTableNodeTemplateIds,
  WorkspaceUserMetaSelectors.selectTableGroupIds,
  (selectedNodeIds, selectedAssetIds, selectedTagIds, selectedTemplateIds, selectTableGroupIds) => {
    return {
      selectedNodeIds,
      selectedAssetIds,
      selectedTagIds,
      selectedTemplateIds,
      selectTableGroupIds,
    };
  },
);

export const selectTableFilterData = createSelector(
  WorkspaceUserMetaSelectors.selectTableProjects,
  WorkspaceUserMetaSelectors.selectTableAssets,
  WorkspaceUserMetaSelectors.selectTableTags,
  WorkspaceUserMetaSelectors.selectTableNodeTemplates,
  WorkspaceUserMetaSelectors.selectTableGroups,
  (selectedNodes, selectedAssets, selectedTags, selectedTemplates, selectTableGroups) => {
    return {
      selectedNodes,
      selectedAssets,
      selectedTags,
      selectedTemplates,
      selectTableGroups,
    };
  },
);

export const selectAssignments = (noTagsFilter = false) =>
  createSelector(
    selectAssignmentsById,
    selectNodesById,
    selectAssignmentsByParentId,
    selectWorkspaceEnrichmentData,
    selectNodeEnrichmentData,
    selectTableFilterIdsData,
    selectWorkspaceContent,
    (
      assignmentsById,
      nodesById,
      assignmentsByParentId,
      {
        //nodeMetasById,
        tagsById,
        widgetsById,
        nodeTemplatesById,
        groupsById,
        groupsByTemplateId,
        templateWidgetsByTemplateId,
        timesheetsByNodeId,
      },
      {
        nodeTagsByNodeId,
        nodeRatesByNodeId,
        nodeRateValuesByNodeId,
        nodeWidgetsByNodeId,
        nodeWidgetRowsByNodeId,
        nodeGroupsByNodeId,
      },
      { selectedNodeIds, selectedTagIds, selectedTemplateIds, selectedAssetIds },
      selectedWorkContent,
    ): NodeModel[] => {
      let assignments = Object.values(assignmentsById);
      const result = assignments
        .filter(a => {
          // This probably means the project has been archived
          if (a == null || nodesById[a.referenceNodeId] == null) {
            return false;
          }

          // Exclude Assets and Elements
          if (a.nodeType !== NodeType.assignment) {
            return false;
          }

          // Always show new assignments without a layout or primary tags
          if (
            a.nodeTemplateId == null ||
            nodeTagsByNodeId[a.id] == null ||
            nodeTagsByNodeId[a.id].length === 0
          ) {
            return true;
          }

          if (selectedNodeIds.length > 0 && selectedNodeIds.indexOf(a.referenceNodeId) === -1) {
            return false;
          }

          // apply assets filter when display mode equals to -> Schedule View | Table Schedule View | Time sheets;
          if (
            (selectedWorkContent?.subMode === AssignmentDisplayMode.Calender &&
              selectedWorkContent?.mode === AssignmentLayoutMode.schedule) ||
            (selectedWorkContent?.subMode === AssignmentDisplayMode.Table &&
              selectedWorkContent?.mode === AssignmentLayoutMode.schedule) ||
            selectedWorkContent?.subMode === AssignmentDisplayMode.Timesheets
          ) {
            const children: NodeModel[] = assignmentsByParentId[a?.id];
            if (
              selectedAssetIds?.length &&
              !children?.some(item => selectedAssetIds?.includes(item?.referenceNodeId))
            ) {
              return false;
            }
          }

          if (
            selectedTemplateIds.length > 0 &&
            selectedTemplateIds.indexOf(a.nodeTemplateId) === -1
          ) {
            return false;
          }

          if (
            selectedWorkContent?.subMode !== AssignmentDisplayMode.AssetPricingSummary &&
            !noTagsFilter &&
            selectedTagIds.length > 0 &&
            selectedTagIds.some(id => nodeTagsByNodeId[a.id].map(t => t.id).includes(id)) === false
          ) {
            return false;
          }

          if (
            (selectedWorkContent?.mode === AssignmentLayoutMode.schedule ||
              selectedWorkContent?.subMode === AssignmentDisplayMode.ProjectPricing ||
              selectedWorkContent?.subMode === AssignmentDisplayMode.Timesheets ||
              selectedWorkContent?.subMode === AssignmentDisplayMode.AssetPricingSummary ||
              selectedWorkContent?.subMode === AssignmentDisplayMode.TimesheetsV2 ||
              selectedWorkContent?.subMode === AssignmentDisplayMode.ProjectPricingSummary) &&
            !a?.date
          ) {
            return false;
          }
          return true;
        })
        ?.map(a => {
          const enrichedAssignment = NodeUtils.enrichNode(
            a,
            nodeTagsByNodeId,
            nodeRatesByNodeId,
            nodeRateValuesByNodeId,
            nodeWidgetsByNodeId,
            nodeWidgetRowsByNodeId,
            nodeGroupsByNodeId,
            nodeTemplatesById,
            nodesById,
            assignmentsByParentId,
            tagsById,
            widgetsById,
            groupsById,
            groupsByTemplateId,
            templateWidgetsByTemplateId,
            timesheetsByNodeId,
          );

          const reference = NodeUtils.enrichNode(
            nodesById[a.referenceNodeId],
            nodeTagsByNodeId,
            nodeRatesByNodeId,
            nodeRateValuesByNodeId,
            nodeWidgetsByNodeId,
            nodeWidgetRowsByNodeId,
            nodeGroupsByNodeId,
            nodeTemplatesById,
            nodesById,
            assignmentsByParentId,
            tagsById,
            widgetsById,
            groupsById,
            groupsByTemplateId,
            templateWidgetsByTemplateId,
            timesheetsByNodeId,
          );

          let e = enrichedAssignment;
          let r = reference;

          // apply assets filter when display mode equals to -> Time sheets;
          // in timesheets separate row will appear for each asset, bcz of that,
          // we need to remove the asset item from assetGroups if the selected asset is not there.
          if (
            selectedAssetIds?.length &&
            (selectedWorkContent?.subMode === AssignmentDisplayMode.Timesheets ||
              selectedWorkContent?.subMode === AssignmentDisplayMode.AssetPricingSummary)
          ) {
            e = {
              ...enrichedAssignment,
              assetGroups: enrichedAssignment.assetGroups.map((i: NodeTemplateModel) => {
                return {
                  ...i,
                  nodes: i.nodes.filter(d => selectedAssetIds.includes(d.referenceNodeId)),
                };
              }),
            };

            r = {
              ...reference,
              assetGroups: reference.assetGroups.map((i: NodeTemplateModel) => {
                return {
                  ...i,
                  nodes: i.nodes.filter(d => selectedAssetIds.includes(d.referenceNodeId)),
                };
              }),
            };
          }

          return {
            ...e,
            reference: r,
          } as NodeModel;
        });
      return result;
    },
  );

export const selectAssignmentsByDate = createSelector(
  selectAssignments(),
  WorkspaceUserMetaSelectors.selectAssignmentSorting,
  (assignments, sortBy): { [date: string]: NodeModel[] } => {
    let assignmentsByDateHash: { [date: string]: NodeModel[] } = {};

    assignments.forEach(a => {
      const dateKey = a.date ? a.date : NodeUtils.awaitingAssignmentsDateKey;
      if (assignmentsByDateHash[dateKey] == null) {
        assignmentsByDateHash[dateKey] = [];
      }
      assignmentsByDateHash[dateKey].push(a);
    });

    Object.keys(assignmentsByDateHash).forEach(k => {
      // Note: More TERRIBLE code design... See 'sorting-option.model.ts' and refactor 'makeId(...)'
      switch (sortBy) {
        case `${SortingType.project}-`:
          assignmentsByDateHash[k].sort(NodeUtils.sortByTitle);
          break;
        case `${SortingType.layout}-`:
          assignmentsByDateHash[k].sort(NodeUtils.sortByTemplate);
          break;
        case `${SortingType.default}-`:
        default:
          assignmentsByDateHash[k].sort(NodeUtils.sortByIndex);
          break;
      }
    });
    return assignmentsByDateHash;
  },
);

export const selectPrimaryTagsByDate = createSelector(
  selectAssignments(true),
  (assignments): NodeTagModel[] => {
    let tagsIds = [];
    assignments.forEach(a => {
      tagsIds.push(...a.primaryTags);
    });

    return [...new Set(tagsIds)];
  },
);

export const selectAssignmentsByNodeTemplate = (
  useAllowRevenueFilter = false,
  useAllowCostFilter = false,
) =>
  createSelector(
    selectAssignments(),
    NodeTemplatesSelectors.selectNodeTemplatesById,
    (assignments, nodeTemplatesById): NodeTemplateModel[] => {
      let assignmentsByLayoutHash: { [nodeTemplateId: number]: NodeTemplateModel } = {};
      assignments?.forEach(assignment => {
        if (
          useAllowRevenueFilter &&
          !(assignment?.nodeTemplate?.allowRevenue || !assignment?.date)
        ) {
          return;
        } else if (
          useAllowCostFilter &&
          !(assignment?.nodeTemplate?.allowCosts || !assignment?.date)
        ) {
          return;
        }

        if (assignment.nodeTemplateId) {
          if (assignmentsByLayoutHash[assignment.nodeTemplateId] == null) {
            assignmentsByLayoutHash[assignment.nodeTemplateId] = {
              ...nodeTemplatesById[assignment.nodeTemplateId],
              nodes: [],
            } as NodeTemplateModel;
          }
          assignmentsByLayoutHash[assignment.nodeTemplateId].nodes.push(assignment);
        }
      });
      return Object.values(assignmentsByLayoutHash);
    },
  );

export const selectAssignmentsCostsByNodeId = (nodeId: number) =>
  createSelector(
    selectAssignmentsByNodeTemplate(false, true),
    (assignmentsByNodeTemplate: NodeTemplateModel[]): NodeTemplateModel[] => {
      return assignmentsByNodeTemplate
        ?.map(temp => {
          return {
            ...temp,
            nodes: temp.nodes.filter(node => node.id === nodeId),
          };
        })
        ?.filter(temp => temp.nodes.length > 0);
    },
  );

export const selectAssignmentsByNodeId = createSelector(
  selectAssignments(),
  selectNodesById,
  selectTableFilterIdsData,
  (assignments, nodesById, selectedFilters): NodeTemplateModel[] => {
    let assignmentsByLayoutHash: { [referenceNodeId: number]: NodeTemplateModel } = {};
    assignments
      ?.filter(item => selectedFilters.selectedNodeIds.some(id => id == item?.referenceNodeId))
      .forEach(assignment => {
        if (assignment.referenceNodeId) {
          if (assignmentsByLayoutHash[assignment.referenceNodeId] == null) {
            assignmentsByLayoutHash[assignment.referenceNodeId] = {
              ...nodesById[assignment.referenceNodeId],
              nodes: [],
            } as any;
          }
          assignmentsByLayoutHash[assignment.referenceNodeId].nodes.push(assignment);
        }
      });
    return Object.values(assignmentsByLayoutHash);
  },
);

export const selectAssignmentsByAssignmentId = (id: number) =>
  createSelector(
    selectAssignments(),
    (assignments): NodeModel => {
      return assignments.find(a => a.id === id);
    },
  );

export const selectAssignmentsByNodeTemplateByRateGroups = createSelector(
  selectAssignmentsByNodeTemplate(true),
  WorkspaceUserMetaSelectors.selectTableGroups,
  WorkspaceUserMetaSelectors.selectTableNodeTemplateIds,
  (assignmentsByNodeTemplate, selectedGroups, selectedNodeTemplateIds) => {
    const includeEmpty = selectedGroups.some(g => g.id == null);

    // 'assignmentsByNodeTemplate' is memoized so we need to recreate the list (don't modify)
    const result = assignmentsByNodeTemplate
      .filter(
        ant => selectedNodeTemplateIds.length === 0 || selectedNodeTemplateIds.indexOf(ant.id) > -1,
      )
      .reduce((list: NodeTemplateModel[], ant: NodeTemplateModel) => {
        const assignments = ant.nodes.filter(a => {
          if (
            includeEmpty == true &&
            (a.rateValues == null ||
              a.rateValues.length === 0 ||
              a.rateValues.some(rv => rv.groupId == null))
          ) {
            return true;
          }
          return (a.rateValues || []).some(rv => selectedGroups.some(g => g.id == rv.groupId));
        });

        return [
          ...list,
          {
            ...ant,
            assignments,
          },
        ];
      }, [])
      .filter(ant => ant.nodes != null && ant.nodes.length > 0);
    return result;
  },
);

// Is this a duplicate of "selectAssignmentsByDate"...?
export const selectDateKeyAssignmentSet = createSelector(
  NodesSelectors.selectAssignmentsById,
  (assignmentsById): { [date: string]: NodeModel[] } => {
    return Object.values(assignmentsById)
      .filter(node => node != null && node.nodeType !== NodeType.assignment)
      .reduce((list, node) => {
        return {
          ...list,
          [node.date]: [...(list[node.date] || []), node],
        };
      }, {});
  },
);

export const selectNodesByType = (type: NodeType) =>
  createSelector(
    selectNodesFilteredByNodeType([type]),
    selectNodesById,
    selectAssignmentsByParentId,
    selectWorkspaceEnrichmentData,
    selectNodeEnrichmentData,
    (
      allNodes,
      nodesById,
      assignmentsByParentId,
      {
        tagsById,
        widgetsById,
        nodeTemplatesById,
        groupsById,
        groupsByTemplateId,
        templateWidgetsByTemplateId,
        timesheetsByNodeId,
      },
      {
        nodeTagsByNodeId,
        nodeRatesByNodeId,
        nodeRateValuesByNodeId,
        nodeWidgetsByNodeId,
        nodeWidgetRowsByNodeId,
        nodeGroupsByNodeId,
      },
    ) => {
      return allNodes.map(n => {
        const reference = NodeUtils.enrichNode(
          nodesById[n.id],
          nodeTagsByNodeId,
          nodeRatesByNodeId,
          nodeRateValuesByNodeId,
          nodeWidgetsByNodeId,
          nodeWidgetRowsByNodeId,
          nodeGroupsByNodeId,
          nodeTemplatesById,
          nodesById,
          assignmentsByParentId,
          tagsById,
          widgetsById,
          groupsById,
          groupsByTemplateId,
          templateWidgetsByTemplateId,
          timesheetsByNodeId,
        );

        return {
          ...n,
          reference,
        };
      });
    },
  );

export const selectProjects = createSelector(
  selectNodesByType(NodeType.project),
  selectTableFilterIdsData,
  (allProjects, selectedFilters) => {
    const records = allProjects.filter(item => {
      if (
        selectedFilters?.selectedNodeIds.length > 0 &&
        selectedFilters?.selectedNodeIds.indexOf(item?.reference?.id) === -1
      ) {
        return false;
      }

      if (
        selectedFilters?.selectedTagIds.length > 0 &&
        selectedFilters?.selectedTagIds.some(id =>
          item?.reference?.tags?.map(t => t.id).includes(id),
        ) === false
      ) {
        return false;
      }

      if (
        selectedFilters?.selectedTemplateIds.length > 0 &&
        selectedFilters?.selectedTemplateIds.some(id =>
          item?.reference?.allowedTemplateIds?.includes(id),
        ) === false
      ) {
        return false;
      }

      return true;
    });

    return {
      records: records,
      total: allProjects?.length,
    }
  },
);

export const selectAssets = createSelector(
  selectNodesByType(NodeType.asset),
  selectTableFilterIdsData,
  (allAssets, selectedFilters) => {
    return allAssets.filter(item => {
      if (
        selectedFilters?.selectedTagIds.length > 0 &&
        selectedFilters?.selectedTagIds.some(id =>
          item?.reference?.tags?.map(t => t.id).includes(id),
        ) === false
      ) {
        return false;
      }

      if (
        selectedFilters?.selectedAssetIds.length > 0 &&
        selectedFilters?.selectedAssetIds.some(id => id === item?.id) === false
      ) {
        return false;
      }
      return true;
    });
  },
);

export const selectElements = createSelector(
  selectNodesByType(NodeType.element),
  selectTableFilterIdsData,
  NodeTemplatesSelectors.selectNodeTemplatesById,
  (allElements, selectedFilters, nodeTemplatesById) => {
    const elements = allElements.filter(item => {
      if (
        selectedFilters?.selectedTagIds.length > 0 &&
        selectedFilters?.selectedTagIds.some(id =>
          item?.reference?.tags?.map(t => t.id).includes(id),
        ) === false
      ) {
        return false;
      }

      if (!item?.parentNodeId) {
        return false;
      }
      return true;
    });

    let elementsByLayoutHash: { [nodeTemplateId: number]: NodeTemplateModel } = {};
    elements?.forEach(element => {
      if (element?.reference?.nodeTemplateId) {
        if (elementsByLayoutHash[element.reference?.nodeTemplateId] == null) {
          elementsByLayoutHash[element.reference?.nodeTemplateId] = {
            ...nodeTemplatesById[element.reference?.nodeTemplateId],
            nodes: [],
          } as NodeTemplateModel;
        }
        elementsByLayoutHash[element.reference?.nodeTemplateId].nodes.push(element);
      }
    });
    return Object.values(elementsByLayoutHash);
  },
);

export const selectTags = createSelector(
  selectAllTagsWithKeyword,
  selectTableFilterIdsData,
  selectAllPrimaryTagsWithGroupLabel,
  (tags, selectedFilters, primaryTags) => {
    return tags
      .filter(item => {
        if (
          selectedFilters?.selectedTagIds.length > 0 &&
          selectedFilters?.selectedTagIds.every(id => id !== item?.id)
        ) {
          return false;
        }
        return true;
      })
      ?.map(item => {
        const isFound = primaryTags?.find(pTag => pTag?.id === item?.id);
        if (isFound) {
          return {
            ...item,
            isPrimaryTag: true,
          };
        }
        return item;
      });
  },
);
