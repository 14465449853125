export type WidgetsPopoverPage = 'widgets' | 'widget-change' | 'widget-create';
export const WidgetsPopoverTitleByPage = {
  widgets: 'Available Widgets for Assignment Templates',
  'widget-change': 'Edit Widget',
  'widget-create': 'Create Widget',
};

export class WidgetsPopoverState {
  page: WidgetsPopoverPage;
  previousPage?: WidgetsPopoverPage;
  templateId: number;
  widgetId?: number;
  selectedWidgetType?: number;
}

export const initialWidgetsPopoverState: WidgetsPopoverState = {
  page: 'widgets',
  previousPage: undefined,
  templateId: undefined,
  widgetId: undefined,
  selectedWidgetType: undefined,
};
