import { AppState } from '../index';
import { createSelector } from '@ngrx/store';
import { NodeRateValueModel } from '../../core/models/node-rate-value.model';
import { NodeRatesSelectors } from '../node-rates';
import { NodeUtils } from '../../core/utils/node.util';
import { CumulativeNodeRateModel } from '../../core/models/node-rate.model';
import { CumulativeRatesNodeModel } from '../../core/models/node.model';
import { NodesSelectors } from '../nodes';
import { NodeType } from '../../core/constants/node-type';
import { WorkspaceUserMetaSelectors } from '../workspace-user-meta';

const selectNodeRateValuesState = (state: AppState) => state.nodeRateValues;

export const selectNodeRateValuesById = createSelector(
  selectNodeRateValuesState,
  state => state.byId,
);

export const selectNodeRateValueNodes = createSelector(
  selectNodeRateValuesState,
  NodeRatesSelectors.selectNodeRatesById,
  (state, ratesById) =>
    Object.keys(state.byNodeId).reduce((rateValuesById, key) => {
      return {
        ...rateValuesById,
        [key]: state.byNodeId[key].map(rv => {
          return {
            ...rv,
            rate: ratesById[rv.nodeRateId],
          };
        }),
      };
    }, {}),
);

export const selectCumulativeNodeRateValues = createSelector(
  selectNodeRateValuesById,
  NodeRatesSelectors.selectNodeRatesById,
  WorkspaceUserMetaSelectors.selectTableProjects,
  WorkspaceUserMetaSelectors.selectTableGroups,
  NodesSelectors.selectNodesById,
  (
    rateValuesById,
    ratesById,
    selectedNodes,
    selectedGroups,
    nodesById,
  ): CumulativeNodeRateModel[] => {
    let byRateIdHash = {};

    Object.values(ratesById)
      .filter(rate => {
        return selectedNodes.some(n => n.id == rate.nodeId);
      })
      .forEach(rate => {
        const node = nodesById[rate.nodeId];
        if (node != null && node?.nodeType === NodeType.project) {
          if (byRateIdHash[rate.id] == null) {
            byRateIdHash[rate.id] = {
              ...rate,
              quantityByGroupId: {},
              quantityTotal: 0,
            } as CumulativeNodeRateModel;
          }
        }
      });

    Object.values(rateValuesById).forEach(rateValue => {
      const rateValueObj = byRateIdHash[rateValue.nodeRateId];
      if (rateValueObj != null) {
        rateValueObj.quantityTotal += rateValue.quantity || 0;
        // console.log('RATES ', rateValue.quantity, rateValueObj.quantityTotal);
        if (selectedGroups.some(g => g.id == rateValue.groupId)) {
          rateValueObj.quantityByGroupId = {
            ...rateValueObj.quantityByGroupId,
            [rateValue.groupId]:
              (rateValueObj.quantityByGroupId[rateValue.groupId] || 0) + (rateValue.quantity || 0),
          };
        }
      }
    });
    return Object.values(byRateIdHash);
  },
);

export const selectCumulativeNodeRateValuesByNodeId = createSelector(
  selectCumulativeNodeRateValues,
  NodesSelectors.selectNodesById,
  (cumulativeRateValues, nodesById): CumulativeRatesNodeModel[] => {
    let rateValuesByNodeHash: { [nodeId: number]: CumulativeRatesNodeModel } = {};
    cumulativeRateValues.forEach(rateValue => {
      if (rateValuesByNodeHash[rateValue.nodeId] == null) {
        rateValuesByNodeHash[rateValue.nodeId] = {
          ...nodesById[rateValue.nodeId],
          cumulativeRates: [],
        } as CumulativeRatesNodeModel;
      }
      rateValuesByNodeHash[rateValue.nodeId].cumulativeRates.push(rateValue);
    });
    return Object.values(rateValuesByNodeHash);
  },
);

export const selectNodeRateValuesByNodeId = nodeId =>
  createSelector(
    selectNodeRateValueNodes,
    NodeRatesSelectors.selectNodeRatesById,
    (nodeRateValueByNodeId, ratesById): NodeRateValueModel[] => {
      return (nodeRateValueByNodeId[nodeId] || [])
        .filter(r => r != null)
        .map(rv => {
          return {
            ...rv,
            rate: ratesById[rv.nodeRateId],
          };
        })
        .sort(NodeUtils.sortByID);
    },
  );
