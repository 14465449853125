import { AppState } from '../index';
import { createSelector } from '@ngrx/store';
import { PropertiesPanelState } from './properties-panel.state';
import { NodeModel } from '../../core/models/node.model';
import {
  selectNodeTemplateIdsByNodeType,
  selectNodeTemplatesById,
} from '../templates/templates.selectors';
import { NodeType } from '../../core/constants/node-type';
import { NodeUtils } from '../../core/utils/node.util';
import { NodesSelectors } from '../nodes';
import { TemplateWidgetsSelectors } from '../template-widgets';
import { TagsSelectors } from '../tags';
import { WidgetsSelectors } from '../widgets';
import { NodeTemplatesSelectors } from '../templates';
import { WorkspaceUserMetaSelectors } from '../workspace-user-meta';
import { NodeTagsSelectors } from '../node-tags';
import { NodeRatesSelectors } from '../node-rates';
import { NodeWidgetsSelectors } from '../node-widgets';
import { NodeRateValuesSelectors } from '../node-rate-values';
import { WorkspaceGroupsSelectors } from '../workspace-groups';
import { NodeWidgetRowsSelectors } from '../node-widget-rows';
import { NodeGroupsSelectors } from '../node-groups';
import { TimesheetsSelectors } from '../timesheets';

export const selectPropertiesPanelState = (state: AppState): PropertiesPanelState =>
  state.propertiesPanel;

export const selectPropertiesPanelIsOpen = createSelector<AppState, PropertiesPanelState, boolean>(
  selectPropertiesPanelState,
  (state: PropertiesPanelState) => state.isOpen,
);

export const selectPropertiesPanelNodeId = createSelector<AppState, PropertiesPanelState, number>(
  selectPropertiesPanelState,
  (state: PropertiesPanelState) => state.nodeId,
);

export const selectPropertiesPanelInitialFocus = createSelector(
  selectPropertiesPanelState,
  state => state.initialFocus,
);

export const selectPropertiesPanelInitialFocusWithNodeId = createSelector(
  selectPropertiesPanelInitialFocus,
  selectPropertiesPanelNodeId,
  (initialFocus, nodeId) => ({
    initialFocus,
    nodeId,
  }),
);

export const selectWorkspaceRelatedData = createSelector(
  NodesSelectors.selectNodesById,
  NodesSelectors.selectNodesByParentId,
  TagsSelectors.selectTagsById,
  WidgetsSelectors.selectWidgetsById,
  NodeTemplatesSelectors.selectNodeTemplatesById,
  WorkspaceGroupsSelectors.selectWorkspaceGroupsById,
  WorkspaceGroupsSelectors.selectWorkspaceGroupsByNodeTemplateId,
  TimesheetsSelectors.selectTimesheetsByNodeId,
  (
    nodesById,
    nodesByParentId,
    tagsById,
    widgetsById,
    templatesById,
    groupsById,
    groupsByTemplateId,
    timesheetsByNodeId,
  ) => {
    return {
      nodesById,
      nodesByParentId,
      tagsById,
      widgetsById,
      templatesById,
      groupsById,
      groupsByTemplateId,
      timesheetsByNodeId,
    };
  },
);

export const selectNodeRelatedData = createSelector(
  WorkspaceUserMetaSelectors.selectUserNodeMetasById,
  NodeTagsSelectors.selectNodeTagsByNodeId,
  NodeRatesSelectors.selectNodeRateNodes,
  NodeRateValuesSelectors.selectNodeRateValueNodes,
  NodeWidgetsSelectors.selectNodeWidgetsByNodeId,
  NodeWidgetRowsSelectors.selectNodeWidgetRowsByNodeId,
  NodeGroupsSelectors.selectNodeGroupsByNodeId,
  NodeTemplatesSelectors.selectNodeTemplatesById,
  (
    nodeMetasById,
    nodeTagsByNodeId,
    nodeRatesByNodeId,
    nodeRateValuesByNodeId,
    nodeWidgetsByNodeId,
    nodeWidgetRowsByNodeId,
    nodeGroupsByNodeId,
    nodeTemplatesById,
  ) => {
    return {
      nodeMetasById,
      nodeTagsByNodeId,
      nodeRatesByNodeId,
      nodeRateValuesByNodeId,
      nodeWidgetsByNodeId,
      nodeWidgetRowsByNodeId,
      nodeGroupsByNodeId,
      nodeTemplatesById,
    };
  },
);

export const selectPropertiesPanelNode = createSelector(
  selectPropertiesPanelNodeId,
  selectNodeRelatedData,
  selectWorkspaceRelatedData,
  TemplateWidgetsSelectors.selectTemplateWidgetsByTemplateId,
  (
    selectedNodeId,
    {
      nodeMetasById,
      nodeTagsByNodeId,
      nodeRatesByNodeId,
      nodeRateValuesByNodeId,
      nodeWidgetsByNodeId,
      nodeWidgetRowsByNodeId,
      nodeGroupsByNodeId,
      nodeTemplatesById,
    },
    {
      nodesById,
      nodesByParentId,
      tagsById,
      widgetsById,
      templatesById,
      groupsById,
      groupsByTemplateId,
      timesheetsByNodeId,
    },
    widgetsByTemplateId,
  ): NodeModel => {
    return NodeUtils.enrichNode(
      nodesById[selectedNodeId],
      //nodeMetasById,
      nodeTagsByNodeId,
      nodeRatesByNodeId,
      nodeRateValuesByNodeId,
      nodeWidgetsByNodeId,
      nodeWidgetRowsByNodeId,
      nodeGroupsByNodeId,
      nodeTemplatesById,
      nodesById,
      nodesByParentId,
      tagsById,
      widgetsById,
      groupsById,
      groupsByTemplateId,
      widgetsByTemplateId,
      timesheetsByNodeId,
    );
  },
);

export const selectPropertiesPanelAvailableTemplates = createSelector(
  selectPropertiesPanelNode,
  selectNodeTemplateIdsByNodeType,
  selectNodeTemplatesById,
  (node, templateIdsByServerNodeType, templatesById) => {
    if (!node) {
      return [];
    }

    const nodeType = NodeUtils.getTemplateNodeType(node.nodeType);
    if (nodeType == null) {
      console.info('Unable to identify the ServerNodeType for AvailableTemplates');
      return [];
    }

    return (templateIdsByServerNodeType[nodeType] || [])
      .map(id => templatesById[id])
      .filter(t => !!t);
  },
);

export const selectPropertiesPanelAvailableTemplateOptions = createSelector(
  selectPropertiesPanelAvailableTemplates,
  templates => {
    return templates.map(template => ({
      id: template.id,
      label: template.title,
    }));
  },
);

export const selectIsColorSectionShown = createSelector(selectPropertiesPanelNode, node => {
  if (node == null || node.parentNodeId == null) {
    return false;
  }

  if (node.nodeType === NodeType.asset || node.nodeType === NodeType.element) {
    // non primary element leaf would not shown color section
    return false;
  } else {
    return true;
  }
});

export const selectIsProfilePhotoSectionShown = createSelector(
  selectPropertiesPanelNode,
  node => node && node.nodeType === NodeType.asset,
);
