import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../store';
import { Subscription } from 'rxjs';
import {
  selectNodeListPanelIsDraggingOfSelected,
  selectNodeListPanelDragDropConnectedLists,
  selectNodeListPanelSelectedNodeIds,
} from '../../../../store/node-list-panel/node-list-panel.selectors';
import { ColorType } from '../../../../core/constants/color-type';
import { NodeListPanelActions } from '../../../../store/node-list-panel';
import { DndActions } from '../../../../store/dnd';
import { selectDndEscPressed } from '../../../../store/dnd/dnd.selectors';
import { NodeModel } from '../../../../core/models/node.model';
import { NodesActions } from '../../../../store/nodes';
import { NodeType } from '../../../../core/constants/node-type';

export interface LeavesDropData {
  leaves: NodeModel[];
  folderId: number;
  isMultiDnD: boolean;
}

@Component({
  selector: 'app-leaf-list',
  templateUrl: './leaf-list.component.html',
  styleUrls: ['./leaf-list.component.scss'],
})
export class LeafListComponent implements OnInit, OnDestroy {
  private readonly subscription = new Subscription();

  public readonly NodeType = NodeType;

  @Input() leaves: NodeModel[];
  @Input() parentFolderId: number;
  @Input() folderColorTheme: ColorType;
  @Input() readonly: boolean;

  selectedNodeIds: number[] = [];
  isDraggingOfSelected: boolean;
  connectedLists: string[];

  // Used for passing this flag to drop event since cdkDragEnded will happen (and set isDraggingOfSelected false) before the drop event
  isMultiDnD: boolean;

  escPressed: boolean;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.subscription.add(
      this.store
        .pipe(select(selectNodeListPanelDragDropConnectedLists))
        .subscribe(connectedLists => {
          this.connectedLists = connectedLists;
        }),
    );

    this.subscription.add(
      this.store.pipe(select(selectNodeListPanelSelectedNodeIds)).subscribe(leafIds => {
        this.selectedNodeIds = leafIds;
      }),
    );

    this.subscription.add(
      this.store
        .pipe(select(selectNodeListPanelIsDraggingOfSelected))
        .subscribe(isDraggingOfSelected => {
          this.isDraggingOfSelected = isDraggingOfSelected;
        }),
    );

    this.subscription.add(
      this.store.pipe(select(selectDndEscPressed)).subscribe(escPressed => {
        this.escPressed = escPressed;
      }),
    );
  }

  trackBy(index, leaf: NodeModel) {
    return leaf.id;
  }

  drop(event: CdkDragDrop<LeavesDropData>) {
    if (this.escPressed) {
      this.store.dispatch(DndActions.dndEndDragging());
      return;
    }

    const previousContainerLeafIds = event.previousContainer.data.leaves.map(leaf => leaf.id);
    const leafId = previousContainerLeafIds[event.previousIndex];

    if (event.previousContainer === event.container && this.selectedNodeIds.indexOf(leafId) < 0) {
      const leafIds = this.leaves.map(element => element.id);
      moveItemInArray(leafIds, event.previousIndex, event.currentIndex);
      this.store.dispatch(
        NodesActions.sortNodesRequest({
          nodeIds: leafIds,
        }),
      );
    } else {
      if (this.selectedNodeIds.indexOf(leafId) >= 0) {
        this.store.dispatch(
          NodesActions.moveNodesRequest({
            nodeIds: this.selectedNodeIds,
            toParentId: event.container.data.folderId,
            sortIndex: event.currentIndex,
          }),
        );
      } else {
        this.store.dispatch(
          NodesActions.moveNodesRequest({
            nodeIds: [leafId],
            toParentId: event.container.data.folderId,
            sortIndex: event.currentIndex,
          }),
        );
      }
    }
  }

  dragStarted(event, elementId: number) {
    this.store.dispatch(DndActions.dndStartDragging());

    if (this.selectedNodeIds.indexOf(elementId) >= 0) {
      this.isMultiDnD = true;
      this.store.dispatch(NodeListPanelActions.startDraggingOfSelected());
    } else {
      this.isMultiDnD = false;
    }
  }

  dragEnded(event) {
    this.store.dispatch(NodeListPanelActions.endDraggingOfSelected());
    this.store.dispatch(DndActions.dndEndDragging());
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
