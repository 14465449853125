import { Pipe, PipeTransform } from '@angular/core';
import { WidgetType } from '../../core/constants/widget-type';
import { hasChoicesSupport } from '../../core/utils/widget-choices.util';

@Pipe({
  name: 'hasChoicesSupport',
})
export class HasChoicesSupportPipe implements PipeTransform {
  transform(widgetType: WidgetType): boolean {
    return hasChoicesSupport(widgetType);
  }
}
