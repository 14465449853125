import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileUpload } from '../file-uploader/file-uploader.service';
import { FileModel } from '../../../core/models/file.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { base64ToFile, Dimensions, ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-avatar-uploader',
  templateUrl: './avatar-uploader.component.html',
  styleUrls: ['./avatar-uploader.component.scss'],
})
export class AvatarUploaderComponent implements OnInit {
  @Input() public fileTypes: string;
  @Input() public isLoading: boolean;
  // add a input for array of image types to be enabled
  @Input() imageTypesEnabled: string[] = ['companyLogos', 'equipmentProfiles', 'userProfiles']; // ['companyLogos', 'equipmentProfiles', 'userProfiles']
  @Input() selectedProfileIcon: { id: string; src: string };

  @Output() public fileClick: EventEmitter<FileModel>;
  @Output() public fileRemove: EventEmitter<FileModel>;
  @Output() public confirmSetProfileImageEvent = new EventEmitter<Blob>();
  @Output() public confirmSetPredefineProfileIconEvent = new EventEmitter<{
    id: string;
    src: string;
  }>();

  imageChangedEvent: any = '';
  croppedImage: any = '';
  showCropper = false;
  companyLogosImages: { id: string; src: string }[] = [];
  equipmentProfilesImages: { id: string; src: string }[] = [];
  userProfilesImages: { id: string; src: string }[] = [];
  selectedImage: { id: string; src: string } = null;

  public queue: FileUpload[] = [];

  constructor(protected modalService: NgbModal) {
    this.fileTypes = '';

    this.fileClick = new EventEmitter<FileModel>();
    this.fileRemove = new EventEmitter<FileModel>();
  }

  public ngOnInit(): void {
    if (this.selectedProfileIcon) {
      this.selectedImage = this.selectedProfileIcon;
    }
    // 18 images
    this.companyLogosImages = [
      {
        id: 'cl_1',
        src: 'assets/img/profile-images/company-logos/Bluescope.png',
      },
      {
        id: 'cl_2',
        src: 'assets/img/profile-images/company-logos/Boral.png',
      },
      {
        id: 'cl_3',
        src: 'assets/img/profile-images/company-logos/Boral2.png',
      },
      {
        id: 'cl_4',
        src: 'assets/img/profile-images/company-logos/Bunnings.png',
      },
      {
        id: 'cl_5',
        src: 'assets/img/profile-images/company-logos/Cement.png',
      },
      {
        id: 'cl_6',
        src: 'assets/img/profile-images/company-logos/Coates.png',
      },
      {
        id: 'cl_7',
        src: 'assets/img/profile-images/company-logos/CSR.png',
      },
      {
        id: 'cl_8',
        src: 'assets/img/profile-images/company-logos/H&G.png',
      },
      {
        id: 'cl_9',
        src: 'assets/img/profile-images/company-logos/Hanson.png',
      },
      {
        id: 'cl_10',
        src: 'assets/img/profile-images/company-logos/Holcim.png',
      },
      {
        id: 'cl_11',
        src: 'assets/img/profile-images/company-logos/James-Hardie.png',
      },
      {
        id: 'cl_12',
        src: 'assets/img/profile-images/company-logos/Kennards.png',
      },
      {
        id: 'cl_13',
        src: 'assets/img/profile-images/company-logos/Libery-Steel.png',
      },
      {
        id: 'cl_14',
        src: 'assets/img/profile-images/company-logos/One-Steel.png',
      },
      {
        id: 'cl_15',
        src: 'assets/img/profile-images/company-logos/Reece.png',
      },
      {
        id: 'cl_16',
        src: 'assets/img/profile-images/company-logos/Toll.jpg',
      },
      {
        id: 'cl_17',
        src: 'assets/img/profile-images/company-logos/Wagners.png',
      },
      {
        id: 'cl_18',
        src: 'assets/img/profile-images/company-logos/Z-icon.png',
      },
    ];

    // 21 images
    this.equipmentProfilesImages = [
      {
        id: 'ep_1',
        src: 'assets/img/profile-images/equipment-profiles/Agi.jpg',
      },
      {
        id: 'ep_2',
        src: 'assets/img/profile-images/equipment-profiles/Anchor.jpg',
      },
      {
        id: 'ep_3',
        src: 'assets/img/profile-images/equipment-profiles/Backo.jpg',
      },
      {
        id: 'ep_4',
        src: 'assets/img/profile-images/equipment-profiles/Boggie.jpg',
      },
      {
        id: 'ep_5',
        src: 'assets/img/profile-images/equipment-profiles/Boomlift.jpg',
      },
      {
        id: 'ep_6',
        src: 'assets/img/profile-images/equipment-profiles/Concrete-Pump.jpg',
      },
      {
        id: 'ep_7',
        src: 'assets/img/profile-images/equipment-profiles/Crader.jpg',
      },
      {
        id: 'ep_8',
        src: 'assets/img/profile-images/equipment-profiles/Crane-truck.jpg',
      },
      {
        id: 'ep_9',
        src: 'assets/img/profile-images/equipment-profiles/Doser.jpg',
      },
      {
        id: 'ep_10',
        src: 'assets/img/profile-images/equipment-profiles/Dumper.jpg',
      },
      {
        id: 'ep_11',
        src: 'assets/img/profile-images/equipment-profiles/Excavator.jpg',
      },
      {
        id: 'ep_12',
        src: 'assets/img/profile-images/equipment-profiles/Flatbed.jpg',
      },
      {
        id: 'ep_13',
        src: 'assets/img/profile-images/equipment-profiles/Forklift.jpg',
      },
      {
        id: 'ep_14',
        src: 'assets/img/profile-images/equipment-profiles/Frana.jpg',
      },
      {
        id: 'ep_15',
        src: 'assets/img/profile-images/equipment-profiles/NA.jpg',
      },
      {
        id: 'ep_16',
        src: 'assets/img/profile-images/equipment-profiles/Piling.jpg',
      },
      {
        id: 'ep_17',
        src: 'assets/img/profile-images/equipment-profiles/Telehandler.jpg',
      },
      {
        id: 'ep_18',
        src: 'assets/img/profile-images/equipment-profiles/Truck.jpg',
      },
      {
        id: 'ep_19',
        src: 'assets/img/profile-images/equipment-profiles/Ute.jpg',
      },
      {
        id: 'ep_20',
        src: 'assets/img/profile-images/equipment-profiles/Van.jpg',
      },
    ];

    // 30 images
    this.userProfilesImages = [
      {
        id: 'pi_1',
        src: 'assets/img/profile-images/user-profiles/pp1.jpg',
      },
      {
        id: 'pi_2',
        src: 'assets/img/profile-images/user-profiles/pp2.jpg',
      },
      {
        id: 'pi_3',
        src: 'assets/img/profile-images/user-profiles/pp3.jpg',
      },
      {
        id: 'pi_4',
        src: 'assets/img/profile-images/user-profiles/pp4.jpg',
      },
      {
        id: 'pi_5',
        src: 'assets/img/profile-images/user-profiles/pp5.jpg',
      },
      {
        id: 'pi_6',
        src: 'assets/img/profile-images/user-profiles/pp6.jpg',
      },
      {
        id: 'pi_7',
        src: 'assets/img/profile-images/user-profiles/pp7.jpg',
      },
      {
        id: 'pi_8',
        src: 'assets/img/profile-images/user-profiles/pp8.jpg',
      },
      {
        id: 'pi_9',
        src: 'assets/img/profile-images/user-profiles/pp9.jpg',
      },
      {
        id: 'pi_10',
        src: 'assets/img/profile-images/user-profiles/pp10.jpg',
      },
      {
        id: 'pi_11',
        src: 'assets/img/profile-images/user-profiles/pp11.jpg',
      },
      {
        id: 'pi_12',
        src: 'assets/img/profile-images/user-profiles/pp12.jpg',
      },
      {
        id: 'pi_13',
        src: 'assets/img/profile-images/user-profiles/pp13.jpg',
      },
      {
        id: 'pi_14',
        src: 'assets/img/profile-images/user-profiles/pp14.jpg',
      },
      {
        id: 'pi_15',
        src: 'assets/img/profile-images/user-profiles/pp15.jpg',
      },
      {
        id: 'pi_16',
        src: 'assets/img/profile-images/user-profiles/pp16.jpg',
      },
      {
        id: 'pi_17',
        src: 'assets/img/profile-images/user-profiles/pp17.jpg',
      },
      {
        id: 'pi_18',
        src: 'assets/img/profile-images/user-profiles/pp18.jpg',
      },
      {
        id: 'pi_19',
        src: 'assets/img/profile-images/user-profiles/pp19.jpg',
      },
      {
        id: 'pi_20',
        src: 'assets/img/profile-images/user-profiles/pp20.jpg',
      },
      {
        id: 'pi_21',
        src: 'assets/img/profile-images/user-profiles/pp21.jpg',
      },
      {
        id: 'pi_22',
        src: 'assets/img/profile-images/user-profiles/pp22.jpg',
      },
      {
        id: 'pi_23',
        src: 'assets/img/profile-images/user-profiles/pp23.jpg',
      },
      {
        id: 'pi_24',
        src: 'assets/img/profile-images/user-profiles/pp24.jpg',
      },
      {
        id: 'pi_25',
        src: 'assets/img/profile-images/user-profiles/pp25.jpg',
      },
      {
        id: 'pi_26',
        src: 'assets/img/profile-images/user-profiles/pp26.jpg',
      },
      {
        id: 'pi_27',
        src: 'assets/img/profile-images/user-profiles/pp27.jpg',
      },
      {
        id: 'pi_28',
        src: 'assets/img/profile-images/user-profiles/pp28.jpg',
      },
      {
        id: 'pi_29',
        src: 'assets/img/profile-images/user-profiles/pp29.jpg',
      },
      {
        id: 'pi_30',
        src: 'assets/img/profile-images/user-profiles/pp30.jpg',
      },
    ];
  }

  selectImage(image: { id: string; src: string }) {
    this.selectedImage = image;
    this.croppedImage = null;
    this.showCropper = false;
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    this.selectedImage = null;
    this.showCropper = true;
  }

  cropperReady(sourceImageDimensions: Dimensions) {
    console.info('Cropper ready', sourceImageDimensions);
  }

  loadImageFailed() {
    console.info('Load image failed');
  }

  disableSaveButton() {
    if (this.selectedImage) {
      return false;
    }
    return !this.showCropper || this.isLoading;
  }

  confirmSetProfileImage() {
    if (this.selectedImage) {
      this.confirmSetPredefineProfileIconEvent.emit(this.selectedImage);
    } else {
      const imageFile = base64ToFile(this.croppedImage);
      this.confirmSetProfileImageEvent.emit(imageFile);
    }
  }
}
