import { WidgetType } from '../constants/widget-type';
import { CalculationMethodType } from '../constants/calculation-method.type';
import { CalculationWidgetModel } from '../models/calculation-widget.model';
import { NodeTemplateWidgetModel } from '../models/node-template-widget.model';

const getNormalizedNumericWidgetValue = (widget: NodeTemplateWidgetModel, rowNumber: number) => {
  let widgetValue = 0;
  const value = widget?.values?.[rowNumber]?.value || null;
  if (value != null) {
    const value = widget.values?.[rowNumber]?.value || 0;
    widgetValue = widget.widget.widgetType === WidgetType.currency ? value / 100 : value;
  }
  return widgetValue;
};

const getCalculationWidgetResultValue = (
  calcWidget: NodeTemplateWidgetModel,
  rowNumber: number,
): number => {
  if (!calcWidget) {
    return 0;
  }

  const aValue = getNormalizedNumericWidgetValue(calcWidget.widgetA, rowNumber);
  const bValue = getNormalizedNumericWidgetValue(calcWidget.widgetB, rowNumber);
  switch (calcWidget.widget.calculationMethod) {
    case CalculationMethodType.sum:
      return aValue + bValue;
    case CalculationMethodType.subtract:
      return aValue - bValue;
    case CalculationMethodType.multiply:
      return aValue * bValue;
    case CalculationMethodType.negativeMultiple:
      return -aValue * bValue;
    case CalculationMethodType.divide:
      return aValue / bValue || 0;
    case CalculationMethodType.negativeDivide:
      return -aValue / bValue || 0;
    default:
      return 0;
  }
};

const getCalculationResultWidgetType = (calcWidget: CalculationWidgetModel): WidgetType => {
  let widgetType = WidgetType.number;
  if (calcWidget && calcWidget.widgetA && calcWidget.widgetB) {
    if (
      calcWidget.widget.calculationMethod === CalculationMethodType.sum ||
      calcWidget.widget.calculationMethod === CalculationMethodType.subtract
    ) {
      if (calcWidget.widgetA.widget.widgetType === calcWidget.widgetB.widget.widgetType) {
        widgetType = calcWidget.widgetA.widget.widgetType;
      }
    } else if (
      calcWidget.widget.calculationMethod === CalculationMethodType.multiply ||
      calcWidget.widget.calculationMethod === CalculationMethodType.negativeMultiple
    ) {
      if (
        (calcWidget.widgetA.widget.widgetType === WidgetType.currency &&
          calcWidget.widgetB.widget.widgetType === WidgetType.number) ||
        (calcWidget.widgetA.widget.widgetType === WidgetType.number &&
          calcWidget.widgetB.widget.widgetType === WidgetType.currency)
      ) {
        widgetType = WidgetType.currency;
      }
    } else if (
      calcWidget.widget.calculationMethod === CalculationMethodType.divide ||
      calcWidget.widget.calculationMethod === CalculationMethodType.negativeDivide
    ) {
      if (
        calcWidget.widgetA.widget.widgetType === WidgetType.currency &&
        calcWidget.widgetB.widget.widgetType === WidgetType.number
      ) {
        widgetType = WidgetType.currency;
      }
    }
  }
  return widgetType;
};

export const WidgetUtils = {
  getCalculationResultWidgetType,
  getCalculationWidgetResultValue,
  getNormalizedNumericWidgetValue,
};
