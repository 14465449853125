<div class="node-view node-view--single-sheet" *ngIf="node">
  <div class="node-view__sheet node-view__sheet--top" *ngIf="node.nodeType === NodeType.asset">
    <app-node-avatar
      class="leaf-list-item__avatar"
      [profile]="node.profile"
      [profileIcon]="node?.nodeMeta?.profileIcon"
      [initials]="node.title | toInitials"
      [fullName]="node.title"
      [size]="'lg'"
      [colorTheme]="node.colorTheme"
    ></app-node-avatar>
  </div>
  <div class="node-view__sheet node-view__sheet--bottom">
    <app-data-field [fieldLabel]="'Tags'" [fieldTemplateRef]="tagTemplate"></app-data-field>
    <app-data-field
      *ngIf="node.nodeType === NodeType.project"
      [fieldLabel]="'Announcement'"
      [fieldTemplateRef]="announcementTemplate"
    ></app-data-field>
    <ng-container *ngFor="let widget of node.widgets; trackBy: trackById">
      <app-data-field
        [fieldLabel]="widget.widget.title"
        [fieldTemplateRef]="widgetTemplate"
      ></app-data-field>

      <ng-template #widgetTemplate>
        <ng-container *ngIf="widget.widget.widgetType !== WidgetType.urlList">
          <ng-container *ngIf="widget | widgetValue">
            {{ widget | widgetValue }}
          </ng-container>
          <app-data-field-empty
            *ngIf="!(widget | widgetValue)"
            [emptyMessage]="'Widget field empty'"
          ></app-data-field-empty>
        </ng-container>

        <ng-container *ngIf="widget.widget.widgetType === WidgetType.urlList">
          <ng-container *ngIf="widget.value?.length">
            <table class="node-view__table ap-table table table-hover">
              <tbody>
                <tr
                  *ngFor="let link of widget.value || []; trackBy: trackById"
                  [ngbTooltip]="link.value"
                  container="body"
                  (click)="openLink(link.value)"
                >
                  <td>
                    <app-link-item [link]="link"></app-link-item>
                  </td>
                </tr>
              </tbody>
            </table>
          </ng-container>
          <app-data-field-empty
            *ngIf="!widget.value?.length"
            [emptyMessage]="'Links field empty'"
          ></app-data-field-empty>
        </ng-container>
      </ng-template>
    </ng-container>

    <!-- This is terrible!!! Need to rebuild this popover -->
    <!--    <div class="node-view__rates mt-3">-->
    <!--      <h4 class="node-view__rates-title">-->
    <!--        Rates-->
    <!--      </h4>-->
    <!--      <div class="node-view__rates-table">-->
    <!--        <app-z-table-node-rates *ngIf="node.rates && node.rates.length"-->
    <!--                                [node]="node" [readonly]="true">-->
    <!--        </app-z-table-node-rates>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</div>

<ng-template #tagTemplate>
  <span
    *ngFor="let tag of node?.tags; trackBy: trackById"
    app-selectable-brick
    class="node-view__tag"
    [sizeType]="'sm'"
    [label]="tag.tag.title"
    [color]="tag.tag.colorTheme"
    [icon]="tag.tag.icon"
    [readonly]="true"
  ></span>
  <app-data-field-empty
    *ngIf="!node?.tags?.length"
    [emptyMessage]="'Tag field empty'"
  ></app-data-field-empty>
</ng-template>

<ng-template #announcementTemplate>
  <ng-container *ngIf="node.notifications">
    {{ node.notifications.note }}
  </ng-container>
  <app-data-field-empty
    *ngIf="!node.notifications"
    [emptyMessage]="'Announcement field empty'"
  ></app-data-field-empty>
</ng-template>
