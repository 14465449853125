import { OptionModel } from './option.model';
import { WorkspaceGroupModel } from './workspace-group.model';
import { projectsRootFolderName } from '../constants/root-folder-names';

export enum SortingType {
  default,
  primaryTag,
  project,
  layout,
}

export enum ScendingType {
  ascending,
  descending,
}

export class SortingOption implements OptionModel {
  public readonly sortingType: SortingType;
  public readonly sortingData: any;
  public readonly label;
  public readonly id;

  constructor({ sortingType, sortingData }: Partial<SortingOption>) {
    this.sortingType = sortingType;
    this.sortingData = sortingData;
    this.label = SortingOption.makeLabel(sortingType, sortingData);
    this.id = SortingOption.makeId(sortingType, sortingData);
  }

  static makeLabel(sortingType, sortingData) {
    if (sortingType === SortingType.default) {
      return 'Default';
    } else if (sortingType === SortingType.primaryTag) {
      return `Primary Tag: ${sortingData ? sortingData.label : ''}`;
    } else if (sortingType === SortingType.project) {
      return projectsRootFolderName;
    } else if (sortingType === SortingType.layout) {
      return 'Activity Layout';
    } else {
      return '';
    }
  }

  static makeId(sortingType, sortingData) {
    return `${sortingType}-${sortingData ? sortingData.id : ''}`;
  }

  // todo:  It's quick but awkward to store option data as string. Need to refactor the dropdown component to support object value
}

const makePrimaryTagSortingOption = (id: number, label: string) => {
  return new SortingOption({
    sortingType: SortingType.primaryTag,
    sortingData: {
      id,
      label,
    },
  });
};

// const makeLayoutSortingOption = (id: number, label: string) => {
//   return new SortingOption({
//     sortingType: SortingType.layout,
//     sortingData: {
//       id,
//       label,
//     },
//   });
// };

export const defaultSortingOption = new SortingOption({
  sortingType: SortingType.default,
  sortingData: undefined,
});

export const projectSortingOption = new SortingOption({
  sortingType: SortingType.project,
  sortingData: undefined,
});

export const layoutSortingOption = new SortingOption({
  sortingType: SortingType.layout,
  sortingData: undefined,
});

export const staticSortingOptions = [
  defaultSortingOption,
  projectSortingOption,
  layoutSortingOption,
];

export const extractSortingOption = (
  sorting: string,
  byId: { [id: number]: WorkspaceGroupModel },
): SortingOption => {
  if (!sorting) {
    return defaultSortingOption;
  }
  const arr = sorting.split('-');
  const type = arr[0];
  if (type === `${SortingType.default}`) {
    return defaultSortingOption;
  } else if (type === `${SortingType.project}`) {
    return projectSortingOption;
  } else if (type === `${SortingType.layout}`) {
    return layoutSortingOption;
  } else if (type === `${SortingType.primaryTag}`) {
    const id = arr[1];
    const group: WorkspaceGroupModel = byId[id];
    if (group) {
      return makePrimaryTagSortingOption(group.id, group.title);
    }
  }

  return defaultSortingOption;
};
