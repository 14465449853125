import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { IconType } from '../../../core/constants/icon-type';
import { NodeTemplateModel } from '../../../core/models/node-template.model';
import { WorkspaceGroupType } from '../../../core/constants/workspace-group-type';
import { GroupEditComponent } from '../../../shared/modals/group-edit/group-edit.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WorkspaceGroupModel } from '../../../core/models/workspace-group.model';
import { TemplatesActions } from '../../../store/templates';

@Component({
  selector: 'app-template-group-tags',
  templateUrl: './template-group-tags.component.html',
  styleUrls: ['./template-group-tags.component.scss'],
})
export class TemplateGroupTagsComponent implements OnInit, OnDestroy {
  private readonly subscription = new Subscription();

  public readonly WorkspaceGroupType = WorkspaceGroupType;
  public readonly IconType = IconType;

  @Input()
  groupType: WorkspaceGroupType;

  @Input() selectedTemplate: NodeTemplateModel;

  @Input() groups: WorkspaceGroupModel[];

  constructor(private store: Store, private modalService: NgbModal) {
    //
  }

  ngOnInit(): void {
    //
  }

  showTagGroupCreatePage() {
    const modelRef = this.modalService.open(GroupEditComponent, {
      size: 'sm',
      backdrop: 'static',
    });
    modelRef.componentInstance.groupType = this.groupType;
    modelRef.componentInstance.templateId = this.selectedTemplate?.id;
  }

  changeSwitch(groupType: WorkspaceGroupType): void {
    let templateProps = {};
    templateProps =
      groupType === WorkspaceGroupType.primaryTag
        ? {
            allowPrimaryTags: !this.selectedTemplate?.allowPrimaryTags,
          }
        : {
            allowStampTags: !this.selectedTemplate?.allowStampTags,
          };
    this.store.dispatch(
      TemplatesActions.updateNodeTemplateRequest({
        templateId: this.selectedTemplate.id,
        templateProps,
      }),
    );
  }

  trackBy(index, group) {
    return group.id;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
