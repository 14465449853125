<div class="assignment-row d-flex flex-wrap w-100">
  <ng-container *ngFor="let template of nodeTemplates; trackBy: trackBy">
    <div class="assignment-field w-100">
      <div class="assignment-field__content">
        <!-- Section for template title and icons -->
        <div class="assignment-section-title-form">
          <div class="icon-with-title">
            <!-- Displaying an icon next to the template title, defaulting to a question mark if none is provided -->
            <span
              class="icon-wrapper"
              [ngClass]="[
                template?.icon ? 'fal fa-' + (template.icon | iconString) : 'fal fa-question',
              ]"
            ></span>
            <!-- Displaying the template title -->
            <span>{{ template.title }}</span>
          </div>
        </div>

        <!-- Section for displaying template values -->
        <div
          class="assignment-field__values"
          [ngStyle]="{'background-color': template?.nodes?.length ? getBackgroundColor(template) : '#f7f8f9'}"
          (click)="requestEdit($event, template)"
        >
          <div class="assignment-field__value-item">
            <ng-container *ngIf="template?.nodes?.length; else noData">
              <label class="text-center w-100">{{ getFormStatus(template) }}</label>
            </ng-container>

            <ng-template #noData>
              <label class="text-center w-100">Select a form template</label>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
