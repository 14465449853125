<div class="template-type-list">
  <app-template-type-item
    class="template-type-list__item"
    [typeViewModel]="typeViewModel"
    [selectedTemplateId]="selectedTemplate?.id"
    [selectedNodeType]="selectedNodeType"
    [slug]="slug"
    (createTemplateEvent)="createTemplate(typeViewModel)"
    *ngFor="let typeViewModel of templateTypeViewModels; trackBy: trackBy"></app-template-type-item>
</div>

