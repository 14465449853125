import { createAction, props } from '@ngrx/store';
import { WidgetModel } from '../../core/models/widget.model';
import { NodeModel } from '../../core/models/node.model';
import { NodeTemplateModel } from '../../core/models/node-template.model';

export const loadArchivedNodeTreeRequest = createAction(
  '[Archives] load archived node tree request',
);

export const unloadArchiveRequest = createAction('[Archives] unload archive request');

export const loadArchivedNodeTreeSuccess = createAction(
  '[Archives] load archived node tree success',
  props<{ nodes: NodeModel[] }>(),
);

export const loadArchivedAssignmentTreeRequest = createAction(
  '[Archives] load archived assignment tree request',
  props<{ startDate?: string; endDate?: string }>(),
);

export const loadArchivedAssignmentTreeSuccess = createAction(
  '[Archives] load archived assignment tree success',
  props<{ nodes: NodeModel[] }>(),
);

export const loadArchivedNodeTemplateRequest = createAction(
  '[Archives] load archived node templates request',
  props<{ startDate?: string; endDate?: string }>(),
);

export const loadArchivedNodeTemplateSuccess = createAction(
  '[Archives] load archived node templates success',
  props<{ templates: NodeTemplateModel[] }>(),
);

export const loadArchivedWidgetRequest = createAction(
  '[Archives] load archived widgets request',
  props<{ startDate?: string; endDate?: string }>(),
);

export const loadArchivedWidgetSuccess = createAction(
  '[Archives] load archived widgets success',
  props<{ widgets: WidgetModel[] }>(),
);

export const restoreArchivedNodeFromArchivesPopoverRequest = createAction(
  '[Archives] restore archived node from archives popover request',
  props<{ nodeId: number; isAssignment: boolean }>(),
);

export const restoreArchivedNodeFromArchivesPopoverSuccess = createAction(
  '[Archives] restore archived node from archives popover success',
  props<{ nodeId: number }>(),
);

export const deleteArchivedNodeFromArchivesPopoverRequest = createAction(
  '[Archives] delete archived node from archives popover request',
  props<{ nodeId: number; isAssignment: boolean }>(),
);

export const emptyArchiveRequest = createAction('[Archives] empty archive request');

export const emptyArchiveSuccess = createAction('[Archives] empty archive success');
