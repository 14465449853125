<div class="link-edit">
  <form [formGroup]="form" (ngSubmit)="submit()">
    <app-popover-subsection [title]="'URL'">
      <input
        #urlInput
        type="text"
        class="form-control"
        id="link-edit-url"
        placeholder="URL"
        required
        formControlName="url"
        [ngClass]="url.invalid && (url.dirty || isSubmitted) ? 'is-invalid' : ''"
        (keydown)="onButtonKeyDown($event)"
      />
      <div class="invalid-feedback">
        {{url.errors?.required ? 'Please provide a URL' : 'Please provide the value of the URL with correct format'}}

      </div>
      <div class="mb-3"></div>
    </app-popover-subsection>
    <app-popover-subsection [title]="'Display Name'">
      <input

        type="text"
        class="form-control"
        id="link-edit-display-name"
        placeholder="Display Name"
        formControlName="displayName"
        [ngClass]="displayName.invalid && (displayName.dirty || isSubmitted) ? 'is-invalid' : ''"
        (keydown)="onButtonKeyDown($event)"
      />

      <div class="mb-3"></div>
    </app-popover-subsection>
    <div class="link-edit__actions">
      <span>
        <button type="submit" class="btn btn-primary btn-sm layout-mr">Save</button>
        <button type="button" class="btn btn-outline-light btn-sm" (click)="close()">Cancel</button>
      </span>
      <span>
        <button
          type="button"
          class="btn btn-danger btn-sm"
          (click)="onDelete()"
          *ngIf="page === GenericPage.edit"
        >
          Delete
        </button>
      </span>
    </div>
  </form>
</div>
