import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CardLayoutType } from '../../../core/constants/card-layout-type';
import { NodeModel } from '../../../core/models/node.model';
import { NodeTemplateWidgetModel } from '../../../core/models/node-template-widget.model';
import { NodeTagModel } from '../../../core/models/node-tag.model';
import { WorkspaceGroupModel } from '../../../core/models/workspace-group.model';

@Component({
  selector: 'app-assignment-additional-fields',
  templateUrl: './assignment-additional-fields.component.html',
  styleUrls: ['../assignment-field/assignment-field.component.scss'],
})
export class AssignmentAdditionalFieldsComponent {
  public readonly CardLayoutType = CardLayoutType;

  @Input() additionalFields: WorkspaceGroupModel[];
  @Input() readonly: boolean;

  @Input() cardLayout: CardLayoutType;

  @Output() editEvent = new EventEmitter<WorkspaceGroupModel>();
  @Output() nodeViewEvent = new EventEmitter<WorkspaceGroupModel>();

  constructor() {}

  requestEdit(event, item: WorkspaceGroupModel) {
    event.stopPropagation();
    if (this.readonly) {
      return;
    }
    this.editEvent.emit(item);
  }

  nodeView(event, node: WorkspaceGroupModel) {
    event.stopPropagation();
    this.nodeViewEvent.emit(node);
  }

  trackBy(item: WorkspaceGroupModel) {
    return item.id;
  }

  nodeTrackBy(item: NodeModel) {
    return item.id;
  }

  widgetsTrackBy(item: NodeTemplateWidgetModel) {
    return item.id;
  }

  tagsTrackBy(item: NodeTagModel) {
    return item.id;
  }
}
