import { WorkspaceUserGroupModel } from '../../core/models/workspace-user-group.model';

export class WorkspaceUserGroupsState {
  byId: WorkspaceUserGroupsById;
  ids: number[];
}

export const initialWorkspaceUserGroupsState: WorkspaceUserGroupsState = {
  byId: {},
  ids: [],
};

export interface WorkspaceUserGroupsById {
  [id: number]: WorkspaceUserGroupModel;
}
