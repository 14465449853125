<button
  *ngFor="let option of options; trackBy: trackBy"
  ngbDropdownItem
  class="rich-select-menu__item"
  [disabled]="option.disabled"
  (click)="selectOption(option.id)"
>
  {{ option.label }}
  <span
    class="rich-select-menu__item-tick fal fa-check"
    *ngIf=" (selectedOptionIds || []).indexOf(option.id) >= 0"
  ></span>
</button>

