import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ErrorsActions } from '../errors';
import { SharedViewActions } from './index';
import { WorkspaceSharesService } from '../../core/services/workspace-shares.service';
import { SharedViewResponseModel } from '../../core/models/shared-view-response.model';
import { extractWorkspaceGroupsFromServerNodeTemplates } from '../templates/server-node-templates.util';
import { LoaderService } from '../../shared/components/content-loader/loader.service';

@Injectable()
export class SharedViewEffects {
  constructor(
    private actions$: Actions,
    private workspaceSharesService: WorkspaceSharesService,
    private readonly loaderService: LoaderService, // private store: Store,
  ) // private timesheetService: TimesheetService,
  {}

  loadSharedViewRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedViewActions.loadSharedViewRequest),
      tap(() => {
        this.loaderService.showScopedLoader('shared-link');
      }),
      mergeMap(action =>
        this.workspaceSharesService.loadSharedView(action.hash).pipe(
          tap(() => this.loaderService.hideScopedLoader('shared-link')),
          switchMap((sharedViewData: SharedViewResponseModel) => {
            const groups = extractWorkspaceGroupsFromServerNodeTemplates(sharedViewData?.templates);
            return of(SharedViewActions.loadSharedViewSuccess({ ...sharedViewData, groups }));
            // SharedViewActions.loadSharedTimesheetsByProjectsRequest({
            //   projects: sharedViewData?.assignments,
            // });
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  // loadSharedTimesheetsByProjectsRequest$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(SharedViewActions.loadSharedTimesheetsByProjectsRequest),
  //     concatMap(action =>
  //       of(action).pipe(
  //         withLatestFrom(
  //           this.store.pipe(select(selectWorkspacesCurrentSlug)),
  //           this.store.pipe(select(WorkspaceUserMetaSelectors.selectTableProjects)),
  //         ),
  //       ),
  //     ),
  //     mergeMap(([action, slug, projects]) => {
  //       if (
  //         (action.projects == null || action.projects.length == 0) &&
  //         (projects == null || projects.length == 0)
  //       ) {
  //         return EMPTY;
  //       }

  //       return this.timesheetService
  //         .loadTimesheetsByProjects(slug, action.projects || projects)
  //         .pipe(
  //           map((timesheets: TimesheetModel[]) => {
  //             return SharedViewActions.loadSharedTimesheetsSuccess({ timesheets });
  //           }),
  //         );
  //     }),
  //   ),
  // );
}
