<div class="z-table-searchbar">
  <div class="z-table-searchbar__field">

    <!-- comment out search icon
    <span
      *ngIf="!searchKeyword?.trim()"
      class="fa fa-search form-control-feedback z-table-searchbar__input-icon"
    ></span>
    -->
    <input
      #input
      type="search"
      [ngModel]="searchKeyword"
      [placeholder]="'Search'"
      class="search-box__input form-control search-form-control"
      [ngClass]="[sizeType === 'sm' ? 'form-control-md' : '']"
      aria-label="Search"
      [id]="id"
      (keyup)="keyup($event)"
      (search)="cancelSearch()"
      [style.width]="inputWidth"
      [style.height]="inputHeight"
    />
  </div>
</div>
