import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { TemplateTagsActions } from '../../../../store/template-tags';
import { WorkspaceGroupsActions } from '../../../../store/workspace-groups';
import { WorkspaceGroupType } from '../../../../core/constants/workspace-group-type';
import { TagType } from '../../../../core/constants/tag-type';
import { TagsPickerComponent } from '../../../../shared/modals/tags-picker/tags-picker.component';
import { TagModel } from '../../../../core/models/tag.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GroupEditComponent } from '../../../../shared/modals/group-edit/group-edit.component';
import { WorkspaceGroupModel } from '../../../../core/models/workspace-group.model';

@Component({
  selector: 'app-template-tag-group',
  templateUrl: './template-tag-group.component.html',
  styleUrls: ['./template-tag-group.component.scss'],
})
export class TemplateTagGroupComponent implements OnInit {
  public readonly WorkspaceGroupType = WorkspaceGroupType;

  @Input() group: WorkspaceGroupModel;
  @Input() groups: WorkspaceGroupModel[];
  @Input() templateId: number;

  get groupIds() {
    return this.groups.map(group => group.id);
  }

  get tagType() {
    if (this.group?.type === WorkspaceGroupType.primaryTag) {
      return TagType.primary;
    } else if (this.group?.type === WorkspaceGroupType.stamp) {
      return TagType.stamp;
    }
  }

  constructor(private store: Store, private modalService: NgbModal) {}

  ngOnInit(): void {}

  editGroup() {
    const modelRef = this.modalService.open(GroupEditComponent, {
      size: 'sm',
      backdrop: 'static',
    });
    modelRef.componentInstance.group = this.group;
  }

  openTagsPopover() {
    const modelRef = this.modalService.open(TagsPickerComponent, {
      size: 'xl',
      backdrop: 'static',
    });
    modelRef.componentInstance.selected = this.group.tags || [];
    modelRef.result
      .then((result: TagModel[]) => {
        this.store.dispatch(
          TemplateTagsActions.addTemplateTagsRequest({
            templateId: this.templateId,
            groupId: this.group.id,
            tagIds: (result || []).map(t => t.id),
            replace: true,
          }),
        );
      })
      .catch(res => {});
  }

  sortTags(event) {
    const joinIds = [...this.group.tags.map(templateTag => templateTag.joinId)];
    moveItemInArray(joinIds, event.previousIndex, event.currentIndex);
    this.store.dispatch(
      TemplateTagsActions.sortTemplateTagsRequest({
        templateId: this.templateId,
        joinIds: joinIds,
      }),
    );
  }

  moveGroupUp(groupId) {
    const groupIds = this.groups.map(group => group.id);
    const index = groupIds.indexOf(groupId);
    moveItemInArray(groupIds, index, index - 1);
    this.store.dispatch(
      WorkspaceGroupsActions.sortWorkspaceGroupsRequest({
        groupIds,
      }),
    );
  }

  moveGroupDown(groupId) {
    const groupIds = this.groups.map(group => group.id);
    const index = groupIds.indexOf(groupId);
    moveItemInArray(groupIds, index, index + 1);
    this.store.dispatch(
      WorkspaceGroupsActions.sortWorkspaceGroupsRequest({
        groupIds,
      }),
    );
  }

  trackTagBy(index, tag) {
    return tag.id;
  }
}
