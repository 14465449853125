import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ROUTER_NAVIGATED } from '@ngrx/router-store';
import { catchError, concatMap, mergeMap, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { ErrorsActions } from '../errors';
import {
  selectRouterInfoAssignmentLayout,
  selectRouterInfoSharedViewHash,
} from './router.selectors';
import { WorkspaceUserMetaActions } from '../workspace-user-meta';
import { selectWorkspacesCurrentSlug } from '../workspaces/workspaces.selectors';
import { SharedViewActions } from '../shared-view';

@Injectable()
export class RouterEffects {
  constructor(private actions$: Actions, private store: Store) {}

  syncAssignmentLayoutFromRouterRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      mergeMap(action =>
        this.store.pipe(
          select(selectRouterInfoAssignmentLayout),
          take(1),
          switchMap(layout => {
            if (layout) {
              return of(WorkspaceUserMetaActions.changeLastAssignmentLayoutRequest({ layout }));
            } else {
              return EMPTY;
            }
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  // loadSharedViewData$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(ROUTER_NAVIGATED),
  //     concatMap(action =>
  //       of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
  //     ),
  //     mergeMap(([action, slug]) =>
  //       this.store.pipe(
  //         select(selectRouterInfoSharedViewHash),
  //         take(1),
  //         switchMap(hash => {
  //           // hash = 'guaYxBG18nf8YqvBe38mCgET';
  //           if (hash) {
  //             return of(SharedViewActions.loadSharedViewRequest({ hash }));
  //           } else {
  //             return EMPTY;
  //           }
  //         }),
  //         catchError(error => {
  //           return of(ErrorsActions.goToErrorPage({ error }));
  //         }),
  //       ),
  //     ),
  //   ),
  // );
}
