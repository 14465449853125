import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { ErrorsActions } from '../errors';
import { WorkspaceGroupService } from '../../core/services/workspace-group.service';
import { WorkspaceGroupsActions } from './index';
import { select, Store } from '@ngrx/store';
import { selectWorkspacesCurrentSlug } from '../workspaces/workspaces.selectors';
import { WorkspaceGroupType } from '../../core/constants/workspace-group-type';
import { WorkspaceUrlsActions } from '../workspace-urls';
import { NodeRatesActions } from '../node-rates';
import { NodeRateValuesActions } from '../node-rate-values';

@Injectable()
export class WorkspaceGroupsEffects {
  constructor(
    private actions$: Actions,
    private workspaceGroupsService: WorkspaceGroupService,
    private store: Store,
  ) {}

  loadWorkspaceGroupsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceGroupsActions.loadWorkspaceGroupsRequest),
      mergeMap(action =>
        this.workspaceGroupsService.loadWorkspaceGroups(action.workspaceSlug).pipe(
          map(groups => {
            return WorkspaceGroupsActions.loadWorkspaceGroupsSuccess({ groups });
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  addWorkspaceGroupRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceGroupsActions.addWorkspaceGroupRequest),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      mergeMap(([action, slug]) =>
        this.workspaceGroupsService
          .addWorkspaceGroup(slug, {
            title: action.title,
            type: action.groupType,
            node: action.nodeId,
            nodeTemplate: action.templateId,
          })
          .pipe(
            concatMap(group => {
              const actions = [];
              if (action.attachChild) {
                switch (action.groupType) {
                  case WorkspaceGroupType.stamp:
                    break;
                  case WorkspaceGroupType.primaryTag:
                    break;
                  case WorkspaceGroupType.checklist:
                    break;
                  case WorkspaceGroupType.workspaceLink:
                    actions.push(
                      WorkspaceUrlsActions.updateWorkspaceUrlRequest({
                        urlId: action.attachChild,
                        props: {
                          groupId: group.id,
                        },
                      }),
                    );
                    break;
                  case WorkspaceGroupType.nodeRate:
                    actions.push(
                      NodeRatesActions.updateNodeRateRequest({
                        rateId: action.attachChild,
                        rateProps: {
                          groupId: group.id,
                        },
                      }),
                    );
                    break;
                  case WorkspaceGroupType.claimPeriod:
                    actions.push(
                      NodeRateValuesActions.updateNodeRateValueRequest({
                        rateValueId: action.attachChild,
                        rateValueProps: {
                          groupId: group.id,
                        },
                      }),
                    );
                    break;
                }
              }
              actions.push(WorkspaceGroupsActions.addWorkspaceGroupsSuccess({ groups: [group] }));
              return actions;
            }),
            catchError(error => {
              return of(ErrorsActions.goToErrorPage({ error }));
            }),
          ),
      ),
    ),
  );

  sortWorkspaceGroupsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceGroupsActions.sortWorkspaceGroupsRequest),
      mergeMap(action =>
        this.workspaceGroupsService.sortWorkspaceGroups(action.groupIds).pipe(
          switchMap(group => {
            return EMPTY;
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  updateWorkspaceGroupRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceGroupsActions.updateWorkspaceGroupRequest),
      mergeMap(action =>
        this.workspaceGroupsService.updateWorkspaceGroup(action.groupId, action.title).pipe(
          map(group => {
            return WorkspaceGroupsActions.updateWorkspaceGroupSuccess({ group });
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  deleteWorkspaceGroupRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceGroupsActions.deleteWorkspaceGroupRequest),
      mergeMap(action =>
        this.workspaceGroupsService.deleteWorkspaceGroup(action.group.id).pipe(
          switchMap(() => {
            return EMPTY;
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );
}
