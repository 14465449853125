import { Component, Input, OnInit } from '@angular/core';
import { SizeType } from '../../../core/constants/size-type';
import { FileModel } from '../../../core/models/file.model';
import { ColorType } from '../../../core/constants/color-type';

@Component({
  selector: 'app-node-avatar',
  templateUrl: './node-avatar.component.html',
  styleUrls: ['./node-avatar.component.scss'],
})
export class NodeAvatarComponent implements OnInit {
  @Input()
  profile: FileModel;

  @Input()
  profileIcon: { id: string; src: string };

  @Input()
  initials: string;

  @Input()
  fullName: string;

  @Input()
  size: SizeType;

  @Input()
  revertColor: boolean;

  @Input()
  hideTooltip: boolean;

  @Input()
  colorTheme: ColorType;

  constructor() {
    this.size = 'md';
    this.revertColor = false;
    this.hideTooltip = false;
    this.colorTheme = null;
  }

  ngOnInit(): void {}
}
