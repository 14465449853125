import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TemplateTagService } from '../../core/services/template-tag.service';
import { catchError, concatMap, map, mergeMap, switchMap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { ErrorsActions } from '../errors';
import { TemplateTagsActions } from './index';
import { TemplateTagModel } from '../../core/models/template-tag.model';
import { TagsActions } from '../tags';

@Injectable()
export class TemplateTagsEffects {
  constructor(private actions$: Actions, private templateTagService: TemplateTagService) {}

  addTemplateTagsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TemplateTagsActions.addTemplateTagsRequest),
      mergeMap(action =>
        this.templateTagService
          .addTemplateTags(action.templateId, action.tagIds, action.groupId, action.replace)
          .pipe(
            map((templateTags: TemplateTagModel[]) => {
              return TemplateTagsActions.addTemplateTagsSuccess({
                templateTags,
                groupId: action.groupId,
                replace: action.replace,
              });
            }),
            catchError(error => {
              return of(ErrorsActions.goToErrorPage({ error }));
            }),
          ),
      ),
    ),
  );

  removeTemplateTagRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TemplateTagsActions.removeTemplateTagRequest),
      mergeMap(action =>
        this.templateTagService.removeTemplateTag(action.templateId, action.joinId).pipe(
          switchMap(() => {
            return EMPTY;
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  sortTemplateTagsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TemplateTagsActions.sortTemplateTagsRequest),
      mergeMap(action =>
        this.templateTagService.sortTemplateTags(action.templateId, action.joinIds).pipe(
          switchMap(() => {
            return EMPTY;
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );
}
