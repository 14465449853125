import { AppState } from '../index';
import { createSelector } from '@ngrx/store';

const selectNodeWidgetRowsState = (state: AppState) => state.nodeWidgetRows;

export const selectNodeWidgetsById = createSelector(
  selectNodeWidgetRowsState,
  state => state.nodeWidgetRowsById,
);

export const selectNodeWidgetRowsByWidgetId = createSelector(
  selectNodeWidgetRowsState,
  state => state.nodeWidgetRowsByWidgetId,
);

export const selectNodeWidgetRowsByNodeId = createSelector(
  selectNodeWidgetRowsState,
  state => state.nodeWidgetRowsByNodeId,
);
