import { createAction, props } from '@ngrx/store';
import { NodeWidgetValueModel } from '../../core/models/node-widget-value.model';
import { WidgetEditableProps } from '../../core/models/widget.model';

export const loadNodeWidgetsFromNodeTree = createAction(
  '[NodeWidgets] load node widgets from node tree success',
  props<{ nodeWidgets: NodeWidgetValueModel[] }>(),
);

export const updateNodeWidgetRequest = createAction(
  '[NodeWidgets] update node widget request',
  props<{
    nodeId: number;
    widgetId: number;
    widgetProps: Partial<WidgetEditableProps>;
  }>(),
);
export const updateNodeWidgetSuccess = createAction(
  '[NodeWidgets] update node widget success',
  props<{ nodeWidget: NodeWidgetValueModel }>(),
);
