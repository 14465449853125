<div class="workspace-settings">
  <app-sidebar-header
    class="workspace-settings__header"
    [title]="'Workspace Settings'"
    [truncateTitle]="!isSidebarOpen"
    [enableBack]="true"
    (backEvent)="openRootFolderList($event)"
  >
  </app-sidebar-header>

  <div class="workspace-settings__body">
    <nav class="sidebar-nav">
      <ul class="sidebar-nav__menu list-unstyled">
        <li>
          <a
            *ngIf="user | can: PermissionType.templateEditor"
            [routerLink]="['/ws', workspace?.slug, 'settings', 'node-templates']"
            class="link__item"
            (click)="openTemplateSettings()"
            routerLinkActive="link__item--active"
          >
            <i class="fal fa-layer-group mr-2"></i>
            <span
              [ngClass]="{
                'd-none': !isSidebarOpen
              }"
              >Templates</span
            >
          </a>
        </li>

        <li>
          <a
            *ngIf="user | can: PermissionType.tagEditor"
            [routerLink]="'/ws/' + workspace?.slug + '/settings/tags'"
            class="link__item"
            routerLinkActive="link__item--active"
          >
            <i class="fal fa-tags mr-2"></i>
            <span
              [ngClass]="{
                'd-none': !isSidebarOpen
              }"
              >Tags</span
            >
          </a>
        </li>

        <li>
          <a
            *ngIf="user | can: PermissionType.workspaceEditor"
            [routerLink]="'/ws/' + workspace?.slug + '/settings/links'"
            class="link__item"
            routerLinkActive="link__item--active"
          >
            <i class="fal fa-link mr-2"></i>
            <span
              [ngClass]="{
                'd-none': !isSidebarOpen
              }"
              >Links</span
            >
          </a>
        </li>

        <li>
          <a
            *ngIf="user | can: PermissionType.archivedEditor"
            [routerLink]="'/ws/' + workspace?.slug + '/settings/archives'"
            class="link__item"
            routerLinkActive="link__item--active"
          >
            <i class="fal fa-archive mr-2"></i>
            <span
              [ngClass]="{
                'd-none': !isSidebarOpen
              }"
              >Archive</span
            >
          </a>
        </li>

        <li>
          <a
            *ngIf="user | can: PermissionType.userEditor"
            [routerLink]="'/ws/' + workspace?.slug + '/settings/users'"
            class="link__item"
            routerLinkActive="link__item--active"
          >
            <i class="fal fa-user mr-2"></i>
            <span
              [ngClass]="{
                'd-none': !isSidebarOpen
              }"
              >Users</span
            >
          </a>
        </li>

        <!--<li>
          <a *ngIf="user | can: PermissionType.userEditor"
             [routerLink]="'/ws/' + workspace?.slug + '/settings/user-groups'" class="link__item"
             routerLinkActive="link__item--active"
          >
            <i class="fal fa-users mr-2"></i> User Groups
          </a>
        </li>-->
      </ul>

      <ng-container *ngIf="user | can: PermissionType.workspaceEditor">
        <h3 class="sidebar-nav__title">Refresh cache</h3>
        <ul class="sidebar-nav__menu list-unstyled">
          <li>
            <a
              class="link__item"
              [ngClass]="{
                link__item___collapsed: !isSidebarOpen
              }"
              href=""
              (click)="onRefreshCache(WorkspaceCacheKey.Tags, $event)"
            >
              <i class="fal fa-sync mr-2"></i>
              <span
                [ngClass]="{
                  'd-none': !isSidebarOpen
                }"
                >Refresh</span
              >
              Tags
            </a>
          </li>
          <li>
            <a
              class="link__item"
              [ngClass]="{
                link__item___collapsed: !isSidebarOpen
              }"
              href=""
              (click)="onRefreshCache(WorkspaceCacheKey.Rates, $event)"
            >
              <i class="fal fa-sync mr-2"></i>
              <span
                [ngClass]="{
                  'd-none': !isSidebarOpen
                }"
                >Refresh</span
              >
              Rates
            </a>
          </li>
          <li>
            <a
              class="link__item"
              [ngClass]="{
                link__item___collapsed: !isSidebarOpen
              }"
              href=""
              (click)="onRefreshCache(WorkspaceCacheKey.RateValues, $event)"
            >
              <i class="fal fa-sync mr-2"></i>
              <span
                [ngClass]="{
                  'd-none': !isSidebarOpen
                }"
                >Refresh</span
              >
              Rate Values
            </a>
          </li>
          <li>
            <a
              class="link__item"
              [ngClass]="{
                link__item___collapsed: !isSidebarOpen
              }"
              href=""
              (click)="onRefreshCache(WorkspaceCacheKey.Widgets, $event)"
            >
              <i class="fal fa-sync mr-2"></i>
              <span
                [ngClass]="{
                  'd-none': !isSidebarOpen
                }"
                >Refresh</span
              >
              Widgets
            </a>
          </li>
          <li>
            <a
              class="link__item"
              [ngClass]="{
                link__item___collapsed: !isSidebarOpen
              }"
              href=""
              (click)="onRefreshCache(WorkspaceCacheKey.Notes, $event)"
            >
              <i class="fal fa-sync mr-2"></i>
              <span
                [ngClass]="{
                  'd-none': !isSidebarOpen
                }"
                >Refresh</span
              >
              Notes
            </a>
          </li>
          <li>
            <a
              class="link__item"
              [ngClass]="{
                link__item___collapsed: !isSidebarOpen
              }"
              href=""
              (click)="onRefreshCache(WorkspaceCacheKey.Groups, $event)"
            >
              <i class="fal fa-sync mr-2"></i>
              <span
                [ngClass]="{
                  'd-none': !isSidebarOpen
                }"
                >Refresh</span
              >
              Groups
            </a>
          </li>
        </ul>
      </ng-container>
    </nav>
  </div>

  <app-sidebar-footer class="workspace-settings__footer">
    <app-btn-fa iconName="folder-tree" (click)="openRootFolderList($event)">Workspace</app-btn-fa>
  </app-sidebar-footer>
</div>
