import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ChannelAction } from '../../../../../core/constants/channel-type';
import {
  ChatChannelActionModel,
  ChatChannelModel,
} from '../../../../../core/models/chat-channel.model';
import { ChatChannelViewModel } from '../../../../../core/models/chat-channel-view.model';
import { selectChatbarCurrentChannel } from '../../../../../store/chatbar/chatbar.selectors';
import { Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';

@Component({
  selector: 'app-chat-channel-item',
  templateUrl: './chat-channel-item.component.html',
  styleUrls: ['./chat-channel-item.component.scss'],
})
export class ChatChannelItemComponent implements OnInit, OnDestroy {
  @Input() channel: ChatChannelViewModel;
  @Output() channelActionEvent = new EventEmitter<ChatChannelActionModel>();

  activeChannel: ChatChannelModel;
  private subscription: Subscription = new Subscription();

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.subscription.add(
      this.store.pipe(select(selectChatbarCurrentChannel)).subscribe(channel => {
        this.activeChannel = channel;
      }),
    );
  }

  deleteChannel(): void {
    this.channelActionEvent.emit({ action: ChannelAction.delete, channel: this.channel });
  }

  openChannel(): void {
    this.channelActionEvent.emit({ action: ChannelAction.open, channel: this.channel });
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
