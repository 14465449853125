export class ArchivesPopoverState {
  hasArchiveChanged: boolean;
  inProcessNodeIds: number[];
  inProcessAssignmentIds: number[];
}

export const initialArchivesPopoverState: ArchivesPopoverState = {
  hasArchiveChanged: false,
  inProcessNodeIds: [],
  inProcessAssignmentIds: [],
};
