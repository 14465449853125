import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LoaderService } from '../../shared/components/content-loader/loader.service';
import * as moment from 'moment';
import { DATE_KEY_FORMAT } from '../../core/constants/date-format.constants';
import { tap } from 'rxjs/operators';
import { ArchivesActions } from '../archives';
import { WorkspacesActions } from '../workspaces';
import { WorkspaceTemplatesActions } from '../workspace-templates';
import { NodesActions } from '../nodes';
import { NodeTagsActions } from '../node-tags';
import { NodeWidgetRowsActions } from '../node-widget-rows';

@Injectable()
export class ContentLoaderEffects {
  constructor(private actions$: Actions, private loaderService: LoaderService) {}

  loadWorkspaceLoaderRequest$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          WorkspacesActions.loadWorkspaceWithNodeTreeRequest,
          ArchivesActions.emptyArchiveRequest,
        ),
        tap(action => {
          this.loaderService.showScopedLoader('workspace-content');
          this.loaderService.showScopedLoader('chat');
          this.loaderService.showScopedLoader('root-folder-list');
          this.loaderService.showScopedLoader('node-list-panel');
          this.loaderService.showScopedLoader('node-edit-panel');
        }),
      ),
    { dispatch: false },
  );

  loadWorkspaceLoaderSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          WorkspacesActions.loadWorkspaceWithNodeTreeSuccess,
          ArchivesActions.emptyArchiveSuccess,
        ),
        tap(action => {
          this.loaderService.hideScopedLoader('workspace-content');
          this.loaderService.hideScopedLoader('chat');
          this.loaderService.hideScopedLoader('root-folder-list');
          this.loaderService.hideScopedLoader('node-list-panel');
          this.loaderService.hideScopedLoader('node-edit-panel');
        }),
      ),
    { dispatch: false },
  );

  showWorkspaceLoaderRequest$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          WorkspacesActions.updateWorkspaceRequest,
          WorkspacesActions.patchWorkspaceMetaRequest,
          WorkspacesActions.deleteWorkspaceRequest,
          WorkspacesActions.copyWorkspaceRequest,
          WorkspacesActions.deleteCurrentWorkspaceRequest,
        ),
        tap(action => this.loaderService.showScopedLoader(action.slug)),
      ),
    { dispatch: false },
  );

  hideWorkspaceLoaderRequest$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          WorkspacesActions.updateWorkspaceSuccess,
          WorkspacesActions.patchWorkspaceMetaSuccess,
          WorkspacesActions.deleteWorkspaceSuccess,
          WorkspacesActions.copyWorkspaceSuccess,
        ),
        tap(action => this.loaderService.hideScopedLoader(action.slug)),
      ),
    { dispatch: false },
  );

  showWorkspaceTemplateLoaderRequest$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          WorkspaceTemplatesActions.updateWorkspaceTemplateRequest,
          WorkspaceTemplatesActions.patchWorkspaceTemplateMetaRequest,
          WorkspaceTemplatesActions.deleteWorkspaceTemplateRequest,
        ),
        tap(action => this.loaderService.showScopedLoader(action.id.toString())),
      ),
    { dispatch: false },
  );

  hideWorkspaceTemplateLoaderRequest$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          WorkspaceTemplatesActions.updateWorkspaceTemplateSuccess,
          WorkspaceTemplatesActions.patchWorkspaceTemplateMetaSuccess,
          WorkspaceTemplatesActions.deleteWorkspaceTemplateSuccess,
        ),
        tap(action => this.loaderService.hideScopedLoader(action.id.toString())),
      ),
    { dispatch: false },
  );

  showMultiNodeIdContentLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          NodeTagsActions.addTagsToMultipleNodesRequest,
          NodesActions.applyTemplateToNodesRequest,
          NodesActions.applyTemplateToAssignmentsRequest,
          NodesActions.archiveNodesRequest,
        ),
        tap(action => {
          for (let i = 0, l = action.nodeIds.length; i < l; i++) {
            this.loaderService.showScopedLoader(action.nodeIds[i].toString());
          }
        }),
      ),
    { dispatch: false },
  );

  hideMultiNodeContentLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NodesActions.addMultipleNodesSuccess, NodesActions.addMultipleAssignmentsSuccess),
        tap(action => {
          for (let i = 0, l = action.nodes.length; i < l; i++) {
            this.loaderService.hideScopedLoader(action.nodes[i].id.toString());
          }
        }),
      ),
    { dispatch: false },
  );

  hideMultiNodeIdLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NodeTagsActions.addTagsToMultipleNodesSuccess, NodesActions.archiveNodesSuccess),
        tap(action => {
          for (let i = 0, l = action.nodeIds.length; i < l; i++) {
            this.loaderService.hideScopedLoader(action.nodeIds[i].toString());
          }
        }),
      ),
    { dispatch: false },
  );

  showCalendarRowLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NodesActions.addMultipleAssignmentsRequest),
        tap(action => {
          action.dateKeys.forEach(dk => {
            let dateKey = dk == null || dk === 'null' ? null : dk;
            this.loaderService.showScopedLoader(dateKey || 'awaiting');
          });
        }),
      ),
    { dispatch: false },
  );

  hideMultipleCalendarRowLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NodesActions.addMultipleAssignmentsSuccess),
        tap(action => {
          let node = action.nodes[0] || null;
          if (node == null) {
            this.loaderService.hideAll();
            console.info('Server did not return any Assignments.');
            return;
          }
          let dateKey = null;
          if (node.date) {
            let date = moment(node.date);
            dateKey = date.format(DATE_KEY_FORMAT);
          }
          this.loaderService.hideScopedLoader(dateKey || 'awaiting');
        }),
      ),
    { dispatch: false },
  );

  showNodeContentLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ArchivesActions.restoreArchivedNodeFromArchivesPopoverRequest,
          NodeWidgetRowsActions.updateWidgetRowValueRequest,
        ),
        tap(action => {
          this.loaderService.showScopedLoader(action.nodeId.toString());
          this.loaderService.showScopedLoader(`z-table-row-${action.nodeId}`);
        }),
      ),
    { dispatch: false },
  );

  hideNodeContentLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ArchivesActions.restoreArchivedNodeFromArchivesPopoverSuccess,
          NodeWidgetRowsActions.updateWidgetRowValueSuccess,
        ),
        tap(action => {
          this.loaderService.hideScopedLoader(action.nodeId.toString());
          this.loaderService.hideScopedLoader(`z-table-row-${action.nodeId}`);
        }),
      ),
    { dispatch: false },
  );

  showAssignmentLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          //AssignmentsActions.archiveAssignmentRequest,
          //AssignmentsActions.deleteAssignmentRequest,
          NodesActions.applyGroupTagToAssignmentRequest,
          NodesActions.addAssignmentElementsRequest,
          NodesActions.replaceAssignmentElementsRequest,
        ),
        tap(action => {
          this.loaderService.showScopedLoader(action.assignmentId.toString());
          this.loaderService.showScopedLoader(`z-table-row-${action.assignmentId}`);
        }),
      ),
    { dispatch: false },
  );

  hideAssignmentLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          //AssignmentsActions.archiveAssignmentSuccess,
          //AssignmentsActions.deleteAssignmentSuccess,
          NodesActions.applyGroupTagToAssignmentSuccess,
          NodesActions.addAssignmentElementsSuccess,
          NodesActions.replaceAssignmentElementsSuccess,
        ),
        tap(action => {
          this.loaderService.hideScopedLoader(action.assignmentId.toString());
          this.loaderService.hideScopedLoader(`z-table-row-${action.assignmentId}`);
        }),
      ),
    { dispatch: false },
  );
}
