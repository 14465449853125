import { AppState } from '../index';
import { createSelector } from '@ngrx/store';

const selectCalendarState = (state: AppState) => state.calendar;

export const selectCalendarSelectedDates = createSelector(
  selectCalendarState,
  state => state.selectedDates,
);


export const selectCalendarIsAwaitingAssignmentsPinned = createSelector(
  selectCalendarState,
  state => state.isAwaitingAssignmentsPinned,
);

// export const selectAssignmentIdsOfSelectedDate = createSelector(
//   selectCalendarSelectedDates,
//   selectAssignmentIdsByDateKey,
//   (selectedDates, assignmentIdsByDateKey) => {
//     let ids = [];
//     if (selectedDates) {
//       ids = assignmentIdsByDateKey?.[selectedDates] || [];
//     }
//
//     return ids;
//   },
// );
