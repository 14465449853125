import { Pipe, PipeTransform } from '@angular/core';
//import * as URLParse from 'url-parse';

@Pipe({
  name: 'favicon',
})
export class FaviconPipe implements PipeTransform {
  transform(url: string): string {
    // if url === 'https://' return empty string
    if (url === 'https://') {
      return '';
    }
    const urlParse = new URL(url);
    const faviconUrl = `https://www.google.com/s2/favicons?domain=${urlParse.hostname}`;
    return faviconUrl;
  }
}
