<div *ngIf="html || template" class="modal-instructions" [ngClass]="{
  'modal-header--template': template,
  'modal-header--html': html,
   'modal--sm': parent.size === ModalSize.Small,
   'modal--md': parent.size === ModalSize.Medium,
   'modal--lg': parent.size === ModalSize.Large,
   'modal--xl': parent.size === ModalSize.XLarge,
   'theme--info': parent.theme === ModalTheme.Info,
   'theme--success': parent.theme === ModalTheme.Success,
   'theme--warning': parent.theme === ModalTheme.Warning,
   'theme--danger': parent.theme === ModalTheme.Danger
}">
  <ng-container *ngTemplateOutlet="template"></ng-container>

  <ng-container *ngIf="html">
    <div [innerHTML]="html"></div>
  </ng-container>

  <ng-content></ng-content>
</div>
