import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NodeRateService } from '../../core/services/node-rate.service';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { ErrorsActions } from '../errors';
import { NodeRatesActions } from './index';
import { NodeRateModel } from '../../core/models/node-rate.model';

@Injectable()
export class NodeRatesEffects {
  constructor(private actions$: Actions, private nodeRateService: NodeRateService) {}

  addRateRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NodeRatesActions.addNodeRateRequest),
      mergeMap(action =>
        this.nodeRateService
          .addRate(
            action.nodeId,
            action.rateType,
            action.groupId,
            action.title,
            action.value,
            action.quantity,
            action.total,
            action.tooltip,
            action.units,
            action.sortIndex,
          )
          .pipe(
            map((rate: NodeRateModel) => {
              return NodeRatesActions.addNodeRateSuccess({ rate });
            }),
            catchError(error => {
              return of(ErrorsActions.goToErrorPage({ error }));
            }),
          ),
      ),
    ),
  );

  updateRateRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NodeRatesActions.updateNodeRateRequest),
      mergeMap(action =>
        this.nodeRateService.updateRate(action.rateId, action.rateProps).pipe(
          switchMap(() => {
            return EMPTY;
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  deleteRateRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NodeRatesActions.deleteNodeRateRequest),
      mergeMap(action =>
        this.nodeRateService.removeRate(action.nodeId, action.rateId).pipe(
          switchMap(() => {
            return EMPTY;
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  sortNodeRatesRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NodeRatesActions.sortNodeRateRequest),
      mergeMap(action =>
        this.nodeRateService.sortRates(action.nodeId, action.rateIds).pipe(
          switchMap(() => {
            return EMPTY;
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );
}
