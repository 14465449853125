import { Pipe, PipeTransform } from '@angular/core';
import { WidgetType } from '../../core/constants/widget-type';
import { NodeTemplateWidgetModel } from '../../core/models/node-template-widget.model';

@Pipe({
  name: 'widgetTypeFilter',
})
export class WidgetTypeFilterPipe implements PipeTransform {
  transform(
    widgets: NodeTemplateWidgetModel[],
    widgetType: WidgetType[],
    include: boolean = true,
  ): NodeTemplateWidgetModel[] {
    if (include) {
      return (widgets || []).filter(widget => widgetType.indexOf(widget.widget.widgetType) >= 0);
    } else {
      return (widgets || []).filter(widget => widgetType.indexOf(widget.widget.widgetType) < 0);
    }
  }
}
