import { Component, Input, OnInit } from '@angular/core';
import { CurvedCornerType } from '../../../core/constants/curved-corner-type';
import { CurvedCornerColorType } from '../../../core/constants/curved-corner-color-type';

@Component({
  selector: 'app-curved-corner',
  templateUrl: './curved-corner.component.html',
  styleUrls: ['./curved-corner.component.scss'],
})
export class CurvedCornerComponent implements OnInit {
  @Input() shape: CurvedCornerType;
  @Input() color: CurvedCornerColorType;

  constructor() {}

  ngOnInit(): void {}
}
