import { Pipe, PipeTransform } from '@angular/core';
import { WorkspaceUserModel } from '../../core/models/workspace-user.model';
import { roleTypeLabel } from '../../core/utils/role.util';

@Pipe({
  name: 'userRoleLabel',
})
export class UserRoleLabelPipe implements PipeTransform {
  transform(user: WorkspaceUserModel): string {
    if (!user) {
      return undefined;
    }
    if (user.isOwner) {
      return 'Owner';
    }

    return roleTypeLabel(user.role);
  }
}
