import { Component } from '@angular/core';
import { TableComponent } from '../../table.component';
import { AbstractTableEditableCellComponent } from '../abstract-table-editable-cell.component';

@Component({
  selector: 'app-z-table-editable-workspace-tag-preview',
  templateUrl: './table-editable-workspace-tag-preview.component.html',
  styleUrls: ['./table-editable-workspace-tag-preview.component.scss'],
})
export class TableEditableWorkspaceTagPreviewComponent extends AbstractTableEditableCellComponent {
  constructor(public table: TableComponent) {
    super(table);
  }
}
