import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-inline-edit-input-layout',
  templateUrl: './inline-edit-input-layout.component.html',
  styleUrls: ['./inline-edit-input-layout.component.scss'],
})
export class InlineEditInputLayoutComponent implements OnInit {
  @Input() displayTemplate: TemplateRef<any>;
  @Input() editTemplate: TemplateRef<any>;
  @Input() confirmBtnLabel: string;
  @Input() saveBtnDisabled: boolean;
  @Input() hideBtns: boolean;

  @Output() clickOutsideDuringEdit = new EventEmitter();
  @Output() saveEvent = new EventEmitter();
  @Output() cancelEvent = new EventEmitter();

  editMode = false;

  constructor() {}

  ngOnInit(): void {}

  onClickText(event) {
    this.asyncSetEditMode();
  }

  asyncSetEditMode(mode = true) {
    setTimeout(() => {
      this.editMode = mode;
    }, 0);
  }

  onClickOutside() {
    if (this.editMode) {
      this.editMode = false;
      this.clickOutsideDuringEdit.emit();
    }
  }

  onSaveValue() {
    this.saveEvent.emit();
    this.editMode = false;
  }

  onCancelValue() {
    this.editMode = false;
    this.cancelEvent.emit();
  }
}
