<ul
  class="leaf-list"
  cdkDropList
  [cdkDropListData]="{
    leaves: leaves,
    folderId: parentFolderId,
    isMultiDnD: isMultiDnD
  }"
  (cdkDropListDropped)="drop($event)"
  [cdkDropListConnectedTo]="connectedLists"
>
  <app-leaf-list-item
    class="leaf-list__item-host"
    [ngClass]="[
      isDraggingOfSelected ? 'leaf-list__item-host--dragging' : '',
      selectedNodeIds.indexOf(element.id) >= 0 ? 'leaf-list__item-host--selected' : ''
    ]"
    *ngFor="let element of leaves; trackBy: trackBy"
    [node]="element"
    [colorTheme]="element.colorTheme || folderColorTheme"
    [readonly]="readonly"
    [isDraggingOfSelected]="isDraggingOfSelected"
    cdkDrag
    [cdkDragStartDelay]="50"
    [cdkDragDisabled]="readonly"
    (cdkDragStarted)="dragStarted($event, element.id)"
    (cdkDragEnded)="dragEnded($event)"
  >
    <span class="leaf-list__item-drag-handle" cdkDragHandle *ngIf="!readonly"></span>
  </app-leaf-list-item>
</ul>
