<app-panel-subsection
  [title]="groupType | groupItemName: true"
  [titleTooltip]="
    groupType === WorkspaceGroupType.primaryTag
      ? 'Primary Tags can be used on Assignments to identify important Assignment info such as Trade.'
      : 'User can apply Stamp to an assignment to provide the status information of the assignment.'
  "
  [backgroundTransparent]="true"
  [headerRightTemplate]="
    groupType === WorkspaceGroupType.primaryTag
      ? primaryTagOnOffLayout
      : groupType === WorkspaceGroupType.stamp
      ? stampOnOffLayout
      : undefined
  "
>
  <ng-container
    *ngIf="
      (groupType === WorkspaceGroupType.primaryTag && selectedTemplate?.allowPrimaryTags) ||
      (groupType === WorkspaceGroupType.stamp && selectedTemplate?.allowStampTags)
    "
  >
    <div class="template-group-tags">
      <div class="template-group-tags__groups">
        <app-template-tag-group
          class="template-group-tags__group"
          *ngFor="let group of groups; trackBy: trackBy"
          [group]="group"
          [groups]="groups"
          [templateId]="selectedTemplate?.id"
        ></app-template-tag-group>
      </div>
    </div>
    <div class="d-flex justify-content-center" #origin>
      <button
        class="btn btn-sm btn-outline-light"
        app-icon-button
        [icon]="IconType['plus']"
        (click)="showTagGroupCreatePage()"
      >
        {{ groupType | groupItemName }} Group
      </button>
    </div>
  </ng-container>
</app-panel-subsection>

<ng-template #primaryTagOnOffLayout>
  <div
    role="button"
    class="layout-switch-btn"
    (click)="changeSwitch(groupType)"
    [ngClass]="{
      'layout-switch-btn-on': selectedTemplate?.allowPrimaryTags === true,
      'layout-switch-btn-off': !selectedTemplate?.allowPrimaryTags
    }"
  >
    <span>{{ selectedTemplate?.allowPrimaryTags ? 'ON' : 'OFF' }}</span>
  </div>
</ng-template>

<ng-template #stampOnOffLayout>
  <div
    role="button"
    class="layout-switch-btn"
    (click)="changeSwitch(groupType)"
    [ngClass]="{
      'layout-switch-btn-on': selectedTemplate?.allowStampTags === true,
      'layout-switch-btn-off': !selectedTemplate?.allowStampTags
    }"
  >
    <span>{{ selectedTemplate?.allowStampTags ? 'ON' : 'OFF' }}</span>
  </div>
</ng-template>
