import { GroupTagsPopoverPage } from '../../core/constants/group-tags-popover-page';
import { WorkspaceGroupType } from '../../core/constants/workspace-group-type';
import { TagType } from '../../core/constants/tag-type';

export class GroupTagsPopoverState {
  page: GroupTagsPopoverPage;
  groupId: number;
  groupType: WorkspaceGroupType;
  tagId: number;
  tagType: TagType;
  templateId: number;
}

export const initialGroupTagsPopoverState: GroupTagsPopoverState = {
  page: undefined,
  groupId: undefined,
  groupType: undefined,
  tagId: undefined,
  tagType: undefined,
  templateId: undefined,
};
