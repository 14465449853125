import {Component, Input, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../store';
import {WorkspacesActions} from '../../store/workspaces';
import {RolePermissionsActions} from '../../store/role-permissions';
import {
  SideBarMode,
  SideBarSize,
} from '../../shared/components/_layout/sidebar/sidebar.component';
import {Subscription} from 'rxjs';
import {AppLayoutActions, AppLayoutSelectors} from '../../store/app-layout';
import {ISideBar} from '../../store/app-layout/app-layout.state';
import {WidgetTypesActions} from '../../store/widget-types';
import {PropertiesPanelSelectors} from '../../store/properties-panel';
import {ArchivesActions} from '../../store/archives';
import {NodesActions} from '../../store/nodes';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {PropertiesPanelComponent} from './properties-panel/properties-panel.component';
import {LayoutTemplatesActions} from '../../store/layout-templates';
import {ChatbarActions} from '../../store/chatbar';

@Component({
  templateUrl: './workspace-common.component.html',
  styleUrls: ['./workspace-common.component.scss'],
})
export class WorkspaceCommonComponent implements OnInit, OnDestroy {
  private readonly subscription: Subscription = new Subscription();

  @Input()
  public set footerTemplate(value: TemplateRef<any>) {
    Promise.resolve().then(() => (this._footerTemplate = value));
  }

  public get footerTemplate(): TemplateRef<any> {
    return this._footerTemplate;
  }

  private _footerTemplate: TemplateRef<any>;

  public readonly SideBarSize = SideBarSize;
  public readonly SideBarMode = SideBarMode;

  public sidebarLeft: ISideBar;
  public sidebarSubLeft: ISideBar;
  public sidebarRight: ISideBar;
  public sidebarSubRight: ISideBar;

  constructor(private store: Store<AppState>, private modalService: NgbModal) {
    // this.store.dispatch(RolePermissionsActions.loadRolePermissionsRequest());
    // this.store.dispatch(WidgetTypesActions.loadWidgetTypesRequest());
    // this.store.dispatch(ChatbarActions.goToWorkspaceAllChannelsPageRequest());
    // this.store.dispatch(LayoutTemplatesActions.loadLayoutTemplatesRequest());
    // moved this for actions to workspace-resolver

    this.subscription.add(
      this.store.pipe(select(AppLayoutSelectors.selectAppLayoutState)).subscribe(appLayout => {
        this.sidebarLeft = appLayout.sidebarLeft;
        this.sidebarSubLeft = appLayout.sidebarSubLeft;
        this.sidebarRight = appLayout.sidebarRight;
        this.sidebarSubRight = appLayout.sidebarSubRight;
      }),
    );

    this.subscription.add(
      this.store
        .pipe(select(PropertiesPanelSelectors.selectPropertiesPanelIsOpen))
        .subscribe(isOpen => {
          if (isOpen) {
            const modelRef = this.modalService.open(PropertiesPanelComponent, {
              backdrop: 'static',
              windowClass: 'properties-panel',
              size: 'lg',
            });

            modelRef.result
              .then((result: boolean) => {
                if (result) {
                  //
                }
              })
              .catch(res => {
              });
          }
        }),
    );
  }

  ngOnInit(): void {
  }

  onSidebarLeftToggle(collapsed: boolean) {
    this.store.dispatch(AppLayoutActions.setSidebarLeftCollapsed({collapsed}));
  }

  onSidebarSubLeftToggle(collapsed: boolean) {
    this.store.dispatch(AppLayoutActions.setSidebarSubLeft({collapsed}));
  }

  onSidebarRightToggle(collapsed: boolean) {
    this.store.dispatch(AppLayoutActions.setSidebarRightCollapsed({collapsed}));
  }

  ngOnDestroy() {
    this.store.dispatch(WorkspacesActions.unloadCurrentWorkspace());
    this.store.dispatch(NodesActions.unloadNodesRequest());
    this.store.dispatch(NodesActions.unloadAssignmentsRequest());
    this.store.dispatch(ArchivesActions.unloadArchiveRequest());
    this.subscription.unsubscribe();
  }
}
