<ng-container [ngSwitch]="node.nodeType">
  <i
    *ngSwitchCase="NodeType.favouritesFolder"
    @animationTrigger
    class="node-type-icon--fa fal fa-star"
  ></i>

  <i
    *ngSwitchCase="NodeType.projectFolder"
    @animationTrigger
    class="node-type-icon--fa fal fa-rectangle-vertical-history"
  ></i>

  <i
    *ngSwitchCase="NodeType.assetFolder"
    @animationTrigger
    class="node-type-icon--fa fal fa-users"
  ></i>

  <i
    *ngSwitchCase="NodeType.elementFolder"
    @animationTrigger
    class="node-type-icon--fa fal fa-clipboard-list-check"
  ></i>
</ng-container>
