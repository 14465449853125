import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { GanttService } from '../../core/services/gantt.service';
import { catchError, concatMap, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { ErrorsActions } from '../errors';
import { GanttsActions } from './index';
import { select, Store } from '@ngrx/store';
import { selectWorkspacesCurrentSlug } from '../workspaces/workspaces.selectors';

@Injectable()
export class GanttsEffects {
  constructor(
    private actions$: Actions,
    private ganttService: GanttService,
    private store: Store,
  ) {}

  loadGanttsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GanttsActions.loadGanttsRequest),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      mergeMap(([action, slug]) =>
        this.ganttService.loadGantts(slug).pipe(
          map(gantts => {
            return GanttsActions.loadGanttsSuccess({ gantts });
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  createGanttRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GanttsActions.createGanttRequest),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      mergeMap(([action, slug]) =>
        this.ganttService
          .createGantt(
            slug,
            action.projectId,
            action.primaryTagId,
            action.startDate,
            action.endDate,
            action.assignmentCount,
            action.title,
            action.tooltip,
            action.budget,
            action.cost,
          )
          .pipe(
            map(gantt => {
              return GanttsActions.createGanttSuccess({
                gantt,
              });
            }),
            catchError(error => {
              return of(ErrorsActions.goToErrorPage({ error }));
            }),
          ),
      ),
    ),
  );

  updateGanttRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GanttsActions.updateGanttRequest),
      mergeMap(action =>
        this.ganttService.updateGantt(action.ganttId, action.ganttProps).pipe(
          switchMap(() => {
            return EMPTY;
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  deleteGanttRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GanttsActions.deleteGanttRequest),
      mergeMap(action =>
        this.ganttService.removeGantt(action.ganttId).pipe(
          switchMap(() => {
            return EMPTY;
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );
}
