import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
  transform(value: any, limit: number = 150, ellipse = true): string {
    if (value) {
      if (value?.toString()?.length <= limit) {
        return value;
      } else {
        const truncatedValue = value?.toString()?.slice(0, limit);
        return ellipse ? truncatedValue + '...' : truncatedValue;
      }
    } else {
      return null;
    }
  }
}
