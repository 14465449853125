import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-btn-view',
  templateUrl: './btn-view.component.html',
  styleUrls: ['./btn-view.component.scss'],
})
export class BtnViewComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
