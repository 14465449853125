<ng-template #assignmentMenuTemplate>
  <app-assignment-menu
    [assignment]="assignment"
    [linksWidgets]="
      assignment.reference.widgets
        | widgetTypeFilter: [WidgetType.url, WidgetType.urlList]
        | widgetSort: [WidgetType.urlList, WidgetType.url]
    "
    (openLinkEvent)="openLink($event)"
  ></app-assignment-menu>
</ng-template>

<ng-template #overlayBodyTemplate>
  <div class="assignment-overlay-card__body-header">
    <ng-container *ngTemplateOutlet="bodyHeaderTemplateRef"></ng-container>
  </div>

  <div
    class="assignment-overlay-card__body"
    [ngClass]="{ 'assignment-overlay-card__body--no-scrollbars': withoutScrollbars }"
  >
    <div class="w-100 text-center layout-padding">
      <i class="fal fa-calendar-lines-pen feedback-icon"></i>
      <h5 class="">Schedule Activity</h5>
      <p class="assignment-field__value-item">
        Choose a layout to schedule an activity for this assignment
      </p>
      <div class="d-flex justify-content-center">
        <button
          #origin
          class="btn btn-primary btn-md layout-mt-lg layout-mb-lg"
          type="button"
          [disabled]="readonly"
          (click)="setUpLayout()"
        >
          <i class="fal fa-circle-plus layout-mr-sm"></i>
          Select Activity
        </button>
      </div>
    </div>
  </div>
</ng-template>

<!-- [progress]="assignment?.progress" -->
<div
  class="assignment-overlay-card"
  [ngClass]="[
    assignment.readOnly ? 'assignment-overlay-card--locked' : '',
    (showSelectionAnimation$ | async) ? 'assignment--show-selection-animation' : ''
  ]"
>
  <app-assignment-header
    [assignment]="assignment"
    [progress]="null"
    [progressViewMode]="progressViewMode || 'hide'"
    [viewType]="CalendarViewType.relaxed"
    [isExpanded]="isExpanded"
    [showHeaderDetails]="assignmentHeaderDetailsViewMode === 'show'"
    [isSelected]="type === 'sharedLinks' ? false : isSelected"
    [isSelectable]="type === 'sharedLinks' ? false : true"
    [showUncheckedBtn]="type === 'sharedLinks' ? false : selectedAssignmentIds?.length > 0"
    [readonly]="readonly"
    [menuRef]="assignmentMenuTemplate"
    [isClickable]="true"
    (toggleSelectEvent)="toggleAssignment()"
    (clickTitleEvent)="openProjectAssignmentsPopover()"
    (changeExpanded)="toggleExpanded()"
    (toggleLockEvent)="toggleLock()"
  ></app-assignment-header>

  <app-assignment-body
    [locked]="assignment.readOnly"
    [secondCardTemplate]="overlayBodyTemplate"
    [hideFirstCard]="hideFirstCard"
  >
    <app-assignment-section
      [title]="field.title"
      *ngFor="let field of assignment.primaryTagGroups; index as index; trackBy: trackBy"
      [ngClass]="{ 'border-bottom': index < assignment.primaryTagGroups?.length - 1 }"
    >
      <app-assignment-primary-tag
        [group]="field"
        [readonly]="readonly"
        class="assignment__primary-col"
      >
      </app-assignment-primary-tag>
    </app-assignment-section>
  </app-assignment-body>

  <div class="assignment-overlay-card__footer">
    <!-- <span class="assignment-overlay-card__last-edit">
       <ng-container *ngIf="assignment.editedBy">
         <span class="assignment-overlay-card__last-edit-text">Last edited by: </span>
         <app-workspace-user
           [user]="assignment.editedBy"
           [isActive]="true"
           [size]="'sm'"
         ></app-workspace-user>
       </ng-container>
     </span>-->

    <span>
      <ng-container *ngTemplateOutlet="footerBtnTemplateRef"></ng-container>
    </span>
  </div>

  <div class="assignment-overlay-card__lock-overlay">
    <span class="assignment-overlay-card__lock-overlay-btn-area" (click)="toggleLock()"></span>
  </div>
</div>
