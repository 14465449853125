<div class="chat-comment-new">
  <div class="chat-comment-new__edit-row position-relative">
    <textarea
      placeholder="   Type a message..."
      [(ngModel)]="value"
      class="chat-comment-new__textarea chat-comment-new__textarea--control form-control form-control-sm"
      appAutoFocus
      type="textarea"
      (keydown.escape)="onCancelValue()"
      [cdkTextareaAutosize]="true"
    ></textarea>
    <app-file-attachment
      class="file-attachment-icon"
      (filePreviewEvent)="preview($event)"
    ></app-file-attachment>
    <button
      aria-label="Submit comment"
      class="submit-button fal fa-play"
      [disabled]="!this.value && !filePreview"
      (click)="submit()"
    ></button>
  </div>
  <app-chat-file-preview
    [filePreview]="filePreview"
    (removeEvent)="removePreview()"
  ></app-chat-file-preview>
</div>
