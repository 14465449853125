import { Action, createReducer, on } from '@ngrx/store';
import { initialWorkspaceSharesState, WorkspaceSharesState } from './workspace-shares.state';
import { WorkspaceSharesActions } from './index';

const reducer = createReducer(
  initialWorkspaceSharesState,
  on(WorkspaceSharesActions.loadSharedLinksSuccess, (state, { sharedLinks }) => {
    const newState = sharedLinks.reduce((newState, link) => {
      return {
        ...newState,
        byHash: {
          ...newState.byHash,
          [link.hash]: { ...link },
        },
        hashes: [...newState.hashes, link.hash],
      };
    }, initialWorkspaceSharesState);
    return newState;
  }),
  on(WorkspaceSharesActions.shareCurrentViewSuccess, (state, { sharedLink }) => {
    return {
      ...state,
      byHash: {
        ...state.byHash,
        [sharedLink.hash]: sharedLink,
      },
      hashes: [sharedLink.hash, ...state.hashes],
    };
  }),
  on(WorkspaceSharesActions.removeSharedLinkRequest, (state, { hash }) => {
    return {
      ...state,
      byHash: {
        ...state.byHash,
        [hash]: undefined,
      },
      hashes: state.hashes.filter(hashItem => hashItem !== hash),
    };
  }),
);

export function workspaceSharesReducer(state: WorkspaceSharesState, action: Action) {
  return reducer(state, action);
}
