import {
  selectSharedViewServerGroupsById,
  selectSharedViewServerNodeIds,
  selectSharedViewServerNodeTemplatesById,
  selectSharedViewServerNodesById,
  selectSharedViewServerTagsById,
  selectSharedViewServerWidgetsById,
} from './shared-view.selectors';
import { createSelector } from '@ngrx/store';
import { SlimAssignmentViewModel } from '../../core/models/slim-assignment-view.model';
import { selectSharedViewElementTemplatesDataByProjectTemplateId } from './shared-view-template-view-models.selectors';
import { sharedViewUtil } from './shared-view.util';
import { NodeTagModel } from '../../core/models/node-tag.model';
import { NodeModel } from '../../core/models/node.model';
import { UserBasicInfo, UserModel } from '../../core/models/user.model';
import { TemplateTagModel } from '../../core/models/template-tag.model';
import { WorkspaceGroupModel } from '../../core/models/workspace-group.model';
import { NodeTemplateModel } from '../../core/models/node-template.model';
import { NodeType } from '../../core/constants/node-type';
import { WorkspaceGroupType } from '../../core/constants/workspace-group-type';
import { NodeUtils } from '../../core/utils/node.util';
import { NodeWidgetValueModel } from '../../core/models/node-widget-value.model';
import { NodeTemplateWidgetModel } from '../../core/models/node-template-widget.model';
import { NodeWidgetsSelectors } from '../node-widgets';
import { TemplateWidgetsSelectors } from '../template-widgets';
import { NodeWidgetRowModel } from '../../core/models/node-widget-row.model';
import { NodeWidgetRowsSelectors } from '../node-widget-rows';

export const selectSharedViewNodeIdArraysByDateKey = createSelector(
  selectSharedViewServerNodeIds,
  selectSharedViewServerNodesById,
  (nodeIds, nodesById) => {
    let nodeIdArrayByDateKey = {};
    (nodeIds || []).forEach(nodeId => {
      const node = nodesById[nodeId];

      nodeIdArrayByDateKey = {
        ...nodeIdArrayByDateKey,
        [node.date]: [...(nodeIdArrayByDateKey[node.date] || []), nodeId],
      };
    });
    return nodeIdArrayByDateKey;
  },
);

export const selectSharedViewAssignmentViewModelsById = createSelector(
  selectSharedViewServerNodesById,
  selectSharedViewElementTemplatesDataByProjectTemplateId,
  selectSharedViewServerTagsById,
  selectSharedViewServerNodeTemplatesById,
  selectSharedViewServerGroupsById,
  selectSharedViewServerWidgetsById,
  NodeWidgetsSelectors.selectNodeWidgetsByNodeId,
  TemplateWidgetsSelectors.selectEnrichedTemplateWidgetsByTemplateId,
  NodeWidgetRowsSelectors.selectNodeWidgetRowsByNodeId,
  (
    nodesById,
    templatesDateByProjectTemplateId,
    tagsById,
    nodeTemplatesById,
    groupsById,
    widgetsById,
    nodeWidgetsByNodeId,
    templateWidgetsByTemplateId,
    nodeWidgetRowsByNodeId,
  ): { [id: number]: SlimAssignmentViewModel } => {
    let assignmentViewModelsById = {};
    Object.values(nodesById).forEach((node: NodeModel) => {
      let projectServerNode: NodeModel = node.reference;
      if (!projectServerNode) {
        console.warn('Empty assignment reference data!');
        return;
      }
      let assignmentModel = getAssignmentModel(node);
      let project = projectServerNode;

      const result: { defaultElementTemplates; assetTemplates } =
        templatesDateByProjectTemplateId[node.nodeTemplateId];
      const leavesByTemplateId = sharedViewUtil.getLeavesByTemplateId(node);
      const assetFields = result?.assetTemplates
        ? sharedViewUtil.convertToField(result.assetTemplates, leavesByTemplateId)
        : [];
      const defaultFields = result?.defaultElementTemplates
        ? sharedViewUtil.convertToField(result.defaultElementTemplates, leavesByTemplateId)
        : [];

      let tagGroups: WorkspaceGroupModel[] = [];
      if (assignmentModel?.primaryTags[0]?.groupId) {
        const item = groupsById[assignmentModel?.primaryTags[0]?.groupId];
        const tag: TemplateTagModel = {
          ...assignmentModel?.primaryTags[0],
          tag: tagsById[assignmentModel?.primaryTags[0]?.id],
        };
        const tagGroupItem: WorkspaceGroupModel = { ...item, tags: [tag] };
        tagGroups = [tagGroupItem];
      }

      let checklistGroups: WorkspaceGroupModel[] = [];
      Object.values(groupsById)
        ?.filter(g => g?.nodeTemplateId === node.nodeTemplateId)
        .forEach(g => {
          switch (g.type) {
            case WorkspaceGroupType.checklist:
              const widgets = nodeTemplatesById[node?.nodeTemplateId]?.widgets
                ?.map(tw => {
                  const wValues =
                    assignmentModel?.widgetRows[0]?.values?.find(wr => wr?.widgetId === tw?.id) ??
                    null;
                  return {
                    ...tw,
                    value: wValues?.value ?? null,
                    values: [wValues],
                    widget: widgetsById[tw.id],
                  };
                })
                ?.filter(f => f?.groupId === g.id)
                ?.sort(NodeUtils.sortByIndex);
              checklistGroups.push({
                ...g,
                widgets,
              } as WorkspaceGroupModel);
              break;
          }
        });

      const nodeWidgetValues = (nodeId: number) => {
        return nodeWidgetsByNodeId[nodeId];
      };

      const nodeWidgetRows = (nodeId: number) => {
        return (nodeWidgetRowsByNodeId[nodeId] || [])
          ?.reduce((list, wr) => {
            const values = (nodeWidgetValues(nodeId) || [])?.filter(
              wv => wv?.rowId === wr?.id && wv?.id !== null,
            );
            return [...list, { ...wr, values } as NodeWidgetRowModel];
          }, [])
          ?.sort((a, b) => {
            if (a.rowNumber < b.rowNumber) {
              return -1;
            }
            if (a.rowNumber > b.rowNumber) {
              return 1;
            }
            return 0;
          });
      };

      const resolveTemplateWidget = (templateWidget: NodeTemplateWidgetModel, nodeId: number) => {
        const widgetValues = [];
        (nodeWidgetRows(nodeId) || [])?.forEach(wr => {
          const values = wr.values?.filter(wv => wv.widgetId === templateWidget.id);
          if (values?.length) {
            widgetValues.push(...values);
          }
        });

        return {
          ...templateWidget,
          widget: widgetsById[templateWidget.id],
          values: widgetValues,
          value: widgetValues[0]?.value,
        } as NodeTemplateWidgetModel;
      };

      const assetGroups: NodeTemplateModel[] = [];
      const elementGroups: NodeTemplateModel[] = [];
      if (nodeTemplatesById[node.nodeTemplateId] != null) {
        nodeTemplatesById[node.nodeTemplateId].allowedTemplateIds.forEach(aTid => {
          if (nodeTemplatesById[aTid] == null) {
            return;
          }
          const template = {
            ...nodeTemplatesById[aTid],
            nodes: node?.children
              .filter(n => {
                return (
                  n.nodeType === NodeType.assignmentElement && n?.reference?.nodeTemplateId === aTid
                );
              })
              .map(n => {
                const widgets: NodeTemplateWidgetModel[] = templateWidgetsByTemplateId[
                  n?.reference?.nodeTemplateId
                ]?.map(tw => {
                  const widgetA = tw.widgetA
                    ? resolveTemplateWidget(tw?.widgetA, n?.referenceNodeId)
                    : null;
                  const widgetB = tw.widgetB
                    ? resolveTemplateWidget(tw?.widgetB, n?.referenceNodeId)
                    : null;
                  return {
                    ...resolveTemplateWidget(tw, n?.referenceNodeId),
                    widgetA,
                    widgetB,
                  } as NodeTemplateWidgetModel;
                });

                // getting the node widgets saved under assignmentElement id
                const widgetList: NodeWidgetValueModel[] = nodeWidgetValues(n?.id);

                const updatedWidgets = widgets?.map((item: NodeTemplateWidgetModel) => {
                  const findItem = widgetList?.find(_item => _item?.widgetId === item?.id);
                  if (item.values?.length && findItem?.value) {
                    item.values[0] = { ...item.values[0], value: findItem?.value };
                  }

                  return {
                    ...item,
                    value: findItem?.value ? findItem.value : item?.value,
                  };
                });

                return {
                  ...n,
                  reference: {
                    ...n.reference,
                    widgets: updatedWidgets,
                    rates: n.reference?.__rates,
                    widgetRows: n.reference?.__widgetRows,
                  },
                };
              }),
          };

          switch (template.nodeType) {
            case NodeType.asset:
              assetGroups.push(template);
              break;
            case NodeType.element:
            default:
              elementGroups.push(template);
              break;
          }
        });
      }

      const assignmentViewModel: SlimAssignmentViewModel = {
        ...assignmentModel,
        nodeTemplate: nodeTemplatesById[node.nodeTemplateId],
        reference: {
          ...project,
          rates: assignmentModel?.rates,
          nodeTemplateId: node?.nodeTemplateId,
          widgets: nodeTemplatesById[projectServerNode?.nodeTemplateId]?.widgets
            ?.map(tw => {
              const wValues =
                projectServerNode?.__widgetRows[0]?.values?.find(wr => wr?.widgetId === tw?.id) ??
                null;
              return {
                ...tw,
                value: wValues?.value ?? null,
                values: [wValues],
                widget: widgetsById[tw.id],
              };
            })
            .sort(NodeUtils.sortByIndex),
        },
        projectColorTheme: project.colorTheme,
        projectWidgets: [],
        primaryTagGroups: tagGroups,
        assetGroups: assetGroups,
        elementGroups: elementGroups,
        checklistGroups: checklistGroups,
        projectTags: projectServerNode.__tags.map((nodeTag: NodeTagModel) => {
          return {
            ...tagsById[nodeTag.id],
            ...nodeTag,
          };
        }),
        stampTags: assignmentModel?.stampTags.map((nodeTag: NodeTagModel) => {
          return {
            ...nodeTag,
            tag: tagsById[nodeTag.id],
          };
        }),
        assetFields,
        defaultFields,
        progress: 0, // @todo: calculate the progress which is complicated and may need backend support
        rateValues: assignmentModel?.rateValues?.map(rateValue => {
          return {
            ...rateValue,
            rate: assignmentModel?.rates?.find(rate => rate.id === rateValue.nodeRateId),
          };
        }),
      };
      assignmentViewModelsById = {
        ...assignmentViewModelsById,
        [node.id]: assignmentViewModel,
      };
    });

    return assignmentViewModelsById;
  },
);

const getAssignmentModel = (serverTreeNode: NodeModel) => {
  const dateKey = serverTreeNode.date ? serverTreeNode.date : null;
  const id = serverTreeNode.id;

  const assignment: any = {
    id,
    notifications: serverTreeNode?.reference.__notifications,
    noteList: serverTreeNode?.reference?.__notes,
    editedBy: getUserBasicInfo(serverTreeNode.editedBy),
    editedDate: serverTreeNode.editedDate,
    projectId: serverTreeNode.referenceNodeId,
    dateKey,
    status: serverTreeNode.status,
    elementIds: serverTreeNode.children.map(node => node.referenceNodeId),
    primaryTags: serverTreeNode.__primaryTags || [],
    primaryTagIds: (serverTreeNode.__primaryTags || []).map(tag => tag.id),
    stampTags: serverTreeNode.__stampTags || [],
    stampTagIds: (serverTreeNode.__stampTags || []).map(tag => tag.id),
    rates: serverTreeNode?.reference?.__rates || [],
    rateValues: serverTreeNode?.__rateValues || [],
    // widgets: serverTreeNode?.reference?.__widgets || [],
    widgetRows: serverTreeNode?.__widgetRows || [],
  };
  return assignment;
};

const getUserBasicInfo = (user: UserModel): UserBasicInfo => {
  if (!user) {
    // Just in case users are empty because they have been removed in database
    return {
      id: undefined,
      initials: '',
      username: '',
      fullName: '',
    };
  }
  return {
    id: user.id,
    initials: user.initials,
    username: user.username,
    fullName: user.fullName,
  };
};

export const selectSharedViewAssignmentViewModelArraysByDateKey = createSelector(
  selectSharedViewNodeIdArraysByDateKey,
  selectSharedViewAssignmentViewModelsById,
  (nodeIdArraysByDateKey, viewModelsById) => {
    let viewModelArraysByDateKey = {};
    Object.keys(nodeIdArraysByDateKey || {}).forEach(dateKey => {
      const nodeIds = nodeIdArraysByDateKey[dateKey] || [];
      const viewModels = nodeIds.map(nodeId => {
        const viewModel = viewModelsById?.[nodeId];
        return viewModel;
      });
      viewModelArraysByDateKey = {
        ...viewModelArraysByDateKey,
        [dateKey]: viewModels,
      };
    });

    return viewModelArraysByDateKey;
  },
);
