import { Action, createReducer, on } from '@ngrx/store';
import { initialRolePermissionState, RolePermissionsState } from './role-permissions.state';
import { RolePermissionsActions } from './index';

const reducer = createReducer(
  initialRolePermissionState,
  on(RolePermissionsActions.loadRolePermissionsSuccess, (state, { permissionsByRole }) => {
    return {
      ...state,
      permissionsByRole,
    };
  }),
);

export function rolePermissionsReducer(state: RolePermissionsState, action: Action) {
  return reducer(state, action);
}
