import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { catchError, first, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { WorkspaceService } from '../services/workspace.service';
import { Store } from '@ngrx/store';
import { WorkspaceUserMetaActions } from '../../store/workspace-user-meta';
import { ErrorsActions } from '../../store/errors';
import { WorkspacesActions } from '../../store/workspaces';
import { WorkspaceModel } from '../models/workspace.model';
import { NodeUtils } from '../utils/node.util';
import { convertServerWorkspaceUserGroup } from '../utils/server-workspace-user-group.util';
import { WorkspaceUrlsActions } from '../../store/workspace-urls';
import { WorkspaceUserGroupsActions } from '../../store/workspace-user-groups';
import { WorkspaceUsersActions } from '../../store/workspace-users';
import { WorkspaceGroupsActions } from '../../store/workspace-groups';
import { TagsActions } from '../../store/tags';
import { WidgetsActions } from '../../store/widgets';
import { WorkspaceSharesActions } from '../../store/workspace-shares';
import { TemplatesActions } from '../../store/templates';
import { NodesActions } from '../../store/nodes';
import { NodeTagsActions } from '../../store/node-tags';
import { NodeRatesActions } from '../../store/node-rates';
import { NodeWidgetsActions } from '../../store/node-widgets';
import { AppState } from '../../store';
import { NodeRateValuesActions } from '../../store/node-rate-values';
import { NodeWidgetRowsActions } from '../../store/node-widget-rows';
import { NodeGroupsActions } from '../../store/node-groups';
import { ChatbarActions } from '../../store/chatbar';
import { LayoutTemplatesActions } from '../../store/layout-templates';
import { RolePermissionsActions } from '../../store/role-permissions';
import { WidgetTypesActions } from '../../store/widget-types';

@Injectable({
  providedIn: 'root',
})
export class WorkspaceResolverService implements Resolve<WorkspaceModel | any> {
  constructor(private store: Store<AppState>, private workspaceService: WorkspaceService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<WorkspaceModel | any> {
    //const slug = route.params['slug'];
    const slug = route.paramMap.get('slug');

    this.store.dispatch(WorkspacesActions.unloadCurrentWorkspace());

    return this.workspaceService.loadWorkspace(slug).pipe(
      first(),
      map((workspace: WorkspaceModel) => {
        const {
          nodes,
          nodeTags,
          nodeRates,
          nodeRateValues,
          nodeWidgetValues,
          nodeWidgetRows,
          nodeGroupValues,
        } = NodeUtils.parseNodeTree(workspace.tree);

        this.store.dispatch(WorkspacesActions.setCurrentWorkspace({ slug }));

        const groups = (workspace.userGroups || []).map(convertServerWorkspaceUserGroup);

        this.store.dispatch(
          WorkspaceUserMetaActions.loadCurrentWorkspaceUserMetaSuccess({
            userMetaData: workspace.userMetaData,
          }),
        );

        this.store.dispatch(
          WorkspaceUrlsActions.loadCurrentWorkspaceUrlsSuccess({
            urls: workspace.urls,
          }),
        );
        this.store.dispatch(
          WorkspaceUserGroupsActions.loadCurrentWorkspaceUserGroupsSuccess({
            groups,
          }),
        );
        this.store.dispatch(
          WorkspaceUsersActions.loadCurrentWorkspaceUsersSuccess({
            users: workspace.users,
          }),
        );
        this.store.dispatch(
          WorkspaceGroupsActions.loadWorkspaceGroupsSuccess({ groups: workspace.groups }),
        );
        this.store.dispatch(TagsActions.loadTagsSuccess({ tags: workspace.tags }));
        this.store.dispatch(WidgetsActions.loadWidgetsSuccess({ widgets: workspace.widgets }));
        this.store.dispatch(
          WorkspaceSharesActions.loadSharedLinksSuccess({ sharedLinks: workspace.shares }),
        );
        this.store.dispatch(
          WorkspaceUrlsActions.loadCurrentWorkspaceUrlsSuccess({
            urls: workspace.urls,
          }),
        );
        this.store.dispatch(
          TemplatesActions.refreshNodeTemplatesSuccess({
            nodeTemplates: workspace.templates,
          }),
        );
        this.store.dispatch(NodesActions.loadNodesSuccess({ nodes }));
        this.store.dispatch(
          NodeTagsActions.loadNodeTagsFromNodeTree({
            nodeTags: nodeTags,
          }),
        );
        this.store.dispatch(
          NodeRatesActions.loadNodeRatesFromNodeTree({
            nodeRates: nodeRates,
          }),
        );
        this.store.dispatch(
          NodeRateValuesActions.loadNodeRateValuesFromNodeTree({
            nodeRateValues: nodeRateValues,
          }),
        );
        this.store.dispatch(
          NodeWidgetsActions.loadNodeWidgetsFromNodeTree({
            nodeWidgets: nodeWidgetValues,
          }),
        );
        this.store.dispatch(
          NodeWidgetRowsActions.loadNodeWidgetRowsFromNodeTree({
            nodeWidgetRows: nodeWidgetRows,
          }),
        );
        this.store.dispatch(
          NodeGroupsActions.loadNodeGroupsFromNodeTree({
            nodeGroups: nodeGroupValues,
          }),
        );
        this.store.dispatch(WorkspacesActions.loadWorkspaceWithNodeTreeSuccess());
        // this.store.dispatch(WorkspacesActions.setCurrentWorkspace({ slug }));
        this.store.dispatch(RolePermissionsActions.loadRolePermissionsRequest());
        this.store.dispatch(WidgetTypesActions.loadWidgetTypesRequest());
        this.store.dispatch(ChatbarActions.goToWorkspaceAllChannelsPageRequest());
        this.store.dispatch(LayoutTemplatesActions.loadLayoutTemplatesRequest());

        return workspace;
      }),
      catchError(error => {
        return of(ErrorsActions.goToErrorPage({ error }));
      }),
    );
  }
}
