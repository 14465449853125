import { AppState } from '../index';
import { createSelector } from '@ngrx/store';

const selectLayoutTemplatesState = (state: AppState) => state.layoutTemplates;

export const selectLayoutTemplatesById = createSelector(
  selectLayoutTemplatesState,
  state => state.layoutTemplatesById,
);

export const selectAllLayoutTemplateIds = createSelector(
  selectLayoutTemplatesState,
  state => state.ids,
);

export const selectLayoutTemplates = createSelector(
  selectLayoutTemplatesById,
  selectAllLayoutTemplateIds,
  (byId, allIds) => {
    return allIds?.map(id => byId[id]).filter(value => !!value);
  },
);

export const selectLayoutTemplatesSearchKeyword = createSelector(
  selectLayoutTemplatesState,
  state => state.searchKeyword,
);

export const selectAllLayoutTemplatesWithKeyword = createSelector(
  selectLayoutTemplates,
  selectLayoutTemplatesSearchKeyword,
  (layoutTemplates, keywords) => {
    if (!keywords) {
      return layoutTemplates;
    }
    const lowerCaseKeyword = keywords.toLowerCase();
    return layoutTemplates.filter(
      layoutTemplate =>
        layoutTemplate.title.toLowerCase().indexOf(lowerCaseKeyword) >= 0 ||
        (layoutTemplate.searchTerms
          ? layoutTemplate.searchTerms.toLowerCase().indexOf(lowerCaseKeyword) >= 0
          : false),
    );
  },
);
