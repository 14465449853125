import { TemplateFiguresPopoverPage } from '../../core/constants/template-figures-popover-page';

export class TemplateFiguresPopoverState {
  page: TemplateFiguresPopoverPage;
  templateId?: number;
  widgetId?: number;
}

export const initialTemplateFiguresPopoverState: TemplateFiguresPopoverState = {
  page: undefined,
  templateId: undefined,
  widgetId: undefined,
};
