<div
  id="print-section"
  class="calendar-row-header"
  [ngClass]="
    routerDerivedAssignmentLayout ? 'calendar-row-header--' + routerDerivedAssignmentLayout : ''
  "
>
  <div
    id="title"
    class="calendar-row-header__title-row"
    [ngClass]="[
      dateKey === awaitingAssignmentsDateKey || type === 'sharedLinks'
        ? ''
        : selectedStatus
        ? 'calendar-row-header__title-row--selected'
        : 'calendar-row-header__title-row--unselected'
    ]"
  >
    <div
      class="calendar-row-header__title"
      [ngClass]="{ 'calendar-row-header__title--awaiting': dateKey === awaitingAssignmentsDateKey }"
    >
      <app-assignment-card-add
        (addEvent)="addAssignment()"
        *ngIf="!readonly"
      ></app-assignment-card-add>

      <span class="calendar-row-header__date-text" (click)="clickCalendarDate()">
        {{
          dateKey === awaitingAssignmentsDateKey
            ? 'Coordinate assignments, activities and resources from the plan board.'
            : (day | momentDate: 'fullMediumDate')
        }}

        <ng-container *ngIf="assignments?.length">
          <span
            *ngIf="selectedStatus; else unselectedStatus"
            class="calendar-row-header__date-selection-icon fal fa-check-square"
            (click)="unSelectAllAssignments()"
          ></span>
          <ng-template #unselectedStatus>
            <span
              class="calendar-row-header__date-selection-icon fal fa-square"
              (click)="selectAllAssignments()"
            ></span
          ></ng-template>
        </ng-container>

        <span class="calendar-row-header__date-selection-icon fal fa-map-pin"></span>
      </span>
    </div>
    <div class="calendar-row-header__info" *ngIf="!hideInfo">
      <app-layout-metric-item
        class="calendar-row-header__metric-item"
        *ngFor="let metric of assignments | cardLayoutMetric: templatesById; trackBy: trackBy"
        [count]="metric.count"
        [template]="metric.template"
      ></app-layout-metric-item>

      <ng-container *ngIf="layout === LayoutType.schedule && type === 'assignments'">
        <button
          *ngIf="isAssignmentLockIconVisible()"
          class="btn btn-outline-light mr-2"
          data-toggle="tooltip"
          data-placement="top"
          title="Lock Cards"
          (click)="lockUnlockAssignments()"
        >
          <i class="fal" [ngClass]="isAllAssignmentsLocked() ? 'fa-unlock-alt' : 'fa-lock'"></i>
        </button>
        <button
          class="btn btn-outline-light"
          data-toggle="tooltip"
          data-placement="top"
          title="Dispatch schedule"
          (click)="dispatch()"
        >
          <i class="fal fa-paper-plane"></i>
        </button>
      </ng-container>

      <ng-container *ngIf="dateKey === awaitingAssignmentsDateKey">
        <button
          class="btn btn-sm btn-primary mr-2"
          (click)="changeDate()"
          [disabled]="!selectedNodesAwaiting?.length"
        >
          <i class="fal fa-calendar layout-mr-sm"></i>
          Move to date
        </button>

        <button
          class="btn btn-sm btn-primary mr-2"
          (click)="copyMultiple()"
          [disabled]="!selectedNodesAwaiting?.length"
        >
          <i class="fal fa-calendars layout-mr-sm"></i>
          Copy to schedule
        </button>

        <button
          class="btn btn-sm btn-primary mr-2"
          (click)="copy()"
          [disabled]="!selectedNodesAwaiting?.length"
        >
          <i class="fal fa-copy layout-mr-sm"></i>
          Duplicate
        </button>
      </ng-container>
    </div>
  </div>
</div>
