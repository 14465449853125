import { Action, createReducer, on } from '@ngrx/store';
import {
  initialWorkspaceTemplatesState,
  WorkspaceTemplatesState,
} from './workspace-templates.state';
import { WorkspaceTemplatesActions } from './index';
import { WorkspacesActions } from '../workspaces';

const reducer = createReducer(
  initialWorkspaceTemplatesState,
  on(WorkspaceTemplatesActions.loadWorkspaceTemplatesSuccess, (state, { templates }) => {
    return templates.reduce((newState, template) => {
      return {
        byId: {
          ...newState.byId,
          [template.id]: template,
        },
        ids: [...newState.ids, template.id],
        searchKeyword: undefined,
      };
    }, initialWorkspaceTemplatesState);
  }),

  // on(WorkspaceTemplatesActions.loadWorkspaceTemplatesSuccess, (state, { templates }) => {
  //   const byId = templates?.reduce((byId, template) => {
  //     return {
  //       ...byId,
  //       [template.id]: { ...template },
  //     };
  //   }, {});
  //   const ids = templates?.map(template => template.id);
  //   return {
  //     ...initialWorkspaceTemplatesState,
  //     workspaceTemplatesById: byId,
  //     ids,
  //   };
  // }),

  on(WorkspaceTemplatesActions.updateWorkspaceTemplateSuccess, (state, { template }) => {
    return {
      ...state,
      byId: {
        ...state.byId,
        [template.id]: template,
      },
    };
  }),

  on(
    WorkspaceTemplatesActions.patchWorkspaceTemplateMetaSuccess,
    (state, { id, metaData }): WorkspaceTemplatesState => {
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            ...state.byId[id],
            metaData: {
              ...state.byId[id].metaData,
              ...metaData,
            },
          },
        },
      };
    },
  ),

  on(WorkspaceTemplatesActions.updateSearchKeywordRequest, (state, { searchKeyword }) => ({
    ...state,
    searchKeyword,
  })),

  on(WorkspacesActions.saveWorkspaceAsTemplateSuccess, (state, { template }) => {
    return {
      ...state,
      byId: {
        ...state.byId,
        [template.id]: template,
      },
      ids: [template.id, ...state.ids],
    };
  }),

  on(
    WorkspaceTemplatesActions.deleteWorkspaceTemplateSuccess,
    (state, { id }): WorkspaceTemplatesState => {
      const newById = {
        ...state.byId,
      };
      delete newById[id];
      return {
        ...state,
        byId: newById,
        ids: state.ids.filter(templateId => templateId !== id),
      };
    },
  ),
);

export function workspaceTemplatesReducer(state: WorkspaceTemplatesState, action: Action) {
  return reducer(state, action);
}
