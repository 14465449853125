import { createAction, props } from '@ngrx/store';
import { NodeModel } from '../../core/models/node.model';

export const openNodeListPanel = createAction(
  '[Node List Panel] open node list panel',
  props<{ node: NodeModel }>(),
);

export const toggleSelectedNode = createAction(
  '[Node List Panel] toggle a node between selected or unselected ',
  props<{ node: NodeModel }>(),
);

export const cancelSelection = createAction('[Node List Panel] cancel all node selection');

export const startDraggingOfSelected = createAction(
  '[Node List Panel] start dragging of selected items',
);
export const endDraggingOfSelected = createAction(
  '[Node List Panel] end dragging of selected items ',
);

export const updateSearchKeywordRequest = createAction(
  '[Node List Panel] update search keyword request',
  props<{ searchKeyword: string }>(),
);

export const updateFilterTagIds = createAction(
  '[Node List Panel] update filter tag ids',
  props<{ ids: number[] }>(),
);

export const updateFilterFolderIds = createAction(
  '[Node List Panel] update filter folder ids',
  props<{ ids: number[] }>(),
);

export const updateFilterTemplateIds = createAction(
  '[Node List Panel] update filter template ids',
  props<{ ids: number[] }>(),
);

export const updateFilterLayoutTemplateIds = createAction(
  '[Node List Panel] update filter layout template ids',
  props<{ ids: number[] }>(),
);

export const updateFilterUnassignedDateKeys = createAction(
  '[Node List Panel] update unassigned date keys',
  props<{ dateKeys: string[] }>(),
);
