import { Pipe, PipeTransform } from '@angular/core';
import { TemplateFiguresPopoverPage } from '../../core/constants/template-figures-popover-page';

@Pipe({
  name: 'templateFiguresPopoverPageTitle',
})
export class TemplateFiguresPopoverPageTitlePipe implements PipeTransform {
  transform(page: TemplateFiguresPopoverPage): string {
    if (page === TemplateFiguresPopoverPage.calculationCreate) {
      return 'Create Calculation';
    } else if (page === TemplateFiguresPopoverPage.calculationWidgetEdit) {
      return 'Edit Calculation Widget';
    }
  }
}
