import { createAction, props } from '@ngrx/store';
import { SharedViewResponseModel } from '../../core/models/shared-view-response.model';
import { SharedLinkMetaModel } from '../../core/models/shared-link-meta.model';
// import { NodeModel } from '../../core/models/node.model';
// import { TimesheetModel } from '../../core/models/timesheet.model';

export const loadSharedViewRequest = createAction(
  '[Shared View] load request',
  props<{ hash: string }>(),
);

export const loadSharedViewSuccess = createAction(
  '[Shared View] load success',
  props<SharedViewResponseModel>(),
);

export const changeSharedViewMetaData = createAction(
  '[Shared View] change assignment layout',
  props<Partial<SharedLinkMetaModel>>(),
);

// export const loadSharedTimesheetsByProjectsRequest = createAction(
//   '[Shared View] load timesheets by projects request',
//   props<{ projects?: NodeModel[] }>(),
// );

// export const loadSharedTimesheetsSuccess = createAction(
//   '[Shared View] load timesheets success',
//   props<{ timesheets: TimesheetModel[] }>(),
// );
