import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { FileUpload, FileUploaderService } from './file-uploader.service';
import { FileModel } from '../../../core/models/file.model';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-file-uploader',
  templateUrl: 'file-uploader.component.html',
  styleUrls: ['file-uploader.component.scss'],
})
export class FileUploaderComponent implements OnInit, OnDestroy {
  @Input() public multiple: boolean;
  @Input() public removable: boolean;
  @Input() public fileTypes: string;
  @Input() public fileList: FileUpload[];

  @Output() public fileClick: EventEmitter<FileModel>;
  @Output() public fileRemove: EventEmitter<FileModel>;
  @Output() public confirmUploadEvent = new EventEmitter();

  public queue: FileUpload[] = [];

  constructor(protected modalService: NgbModal, protected fileUploader: FileUploaderService) {
    this.fileTypes = '';

    this.fileClick = new EventEmitter<FileModel>();
    this.fileRemove = new EventEmitter<FileModel>();
  }

  public ngOnInit(): void {}

  public onThumbClick(file: FileModel) {
    this.fileClick.emit(file);
  }

  public onThumbRemove(file: FileModel) {
    // todo: Confirmation modal goes here?
    //
    this.fileRemove.emit(file);
  }

  public fileDrop(e: any) {
    const files = e.target.files;
    for (let i = 0, l = files.length; i < l; i++) {
      const f = new FileUpload(files[i]);
      const fr = new FileReader();
      fr.onload = e2 => {
        f.type = this.fileUploader.getSimpleType(f.file);
        if (this.fileUploader.isImage(f.file)) {
          f.preview = fr.result;
        }
      };
      fr.readAsDataURL(f.file);

      this.queue.push(f);
    }

    if (!this.multiple) {
      this.queue = this.queue.slice(-1);
    }
  }

  public fileCancel(file: FileUpload) {
    for (let i = 0, l = this.queue.length; i < l; i++) {
      if (this.queue[i] === file) {
        this.queue[i].abort = true; // Trigger the upload to Abort
        this.queue.splice(i, 1);
        break;
      }
    }
  }

  public fileCancelAll() {
    for (let i = this.queue.length - 1; i >= 0; i--) {
      this.queue[i].abort = true; // Trigger the upload to Abort
      this.queue.splice(i, 1);
    }
  }

  public hasNewUploads() {
    return this.getNewUploads().length > 0;
  }

  public getNewUploads() {
    const list = [];
    for (let i = 0, l = this.queue.length; i < l; i++) {
      const file = this.queue[i];
      if (file.complete === false && file.abort === false && file.progress === 0) {
        list.push(this.queue[i]);
      }
    }
    return list;
  }

  public hasPendingUploads() {
    return this.getPendingUploads().length > 0;
  }

  public getPendingUploads() {
    const list = [];
    for (let i = 0, l = this.queue.length; i < l; i++) {
      if (this.queue[i].complete === false && this.queue[i].abort === false) {
        list.push(this.queue[i]);
      }
    }
    return list;
  }

  public uploadFiles(url: string): Observable<any[]> {
    const newUploads = this.getNewUploads();
    if (newUploads.length === 0) {
      return null;
    }

    return this.fileUploader.uploadFiles(url, newUploads).pipe(
      map((files: FileModel[]) => {
        this.fileList.concat(files);
        this.queue = [];
        return files;
      }),
    );
  }

  confirmUpload() {
    this.confirmUploadEvent.emit();
  }

  public ngOnDestroy(): void {
    this.fileCancelAll();
  }
}
