import { Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {
  NgbActiveModal,
  NgbDate,
  NgbDatepicker,
  NgbDatepickerI18n,
  NgbDateStruct,
  NgbModal,
} from '@ng-bootstrap/ng-bootstrap';
import { AbstractPickerComponent, ListItem } from '../_abstract-picker/abstract-picker.component';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store';

@Component({
  templateUrl: 'date-picker.component.html',
  styleUrls: ['date-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DatePickerComponent extends AbstractPickerComponent<NgbDateStruct>
  implements OnInit, OnDestroy {
  @ViewChild(NgbDatepicker, { static: true })
  public datepicker: NgbDatepicker;

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private store: Store<AppState>,
    public i18n: NgbDatepickerI18n,
  ) {
    super(activeModal);
  }

  public ngOnInit(): void {
    super.ngOnInit();
  }

  public search(keywords) {
    throw new Error('Not implemented');
  }

  public isEqual(itemA: NgbDateStruct, itemB: NgbDateStruct): boolean {
    return itemA.day === itemB.day && itemA.month === itemB.month && itemA.year === itemB.year;
  }

  public add() {
    throw new Error('Not implemented');
  }

  public edit(listItem: ListItem<NgbDateStruct>) {
    throw new Error('Not implemented');
  }

  protected sortItems() {
    return;
  }

  public trackBy(index, item: ListItem<NgbDateStruct>) {
    return `${item.model.year}-${item.model.month}-${item.model.day}`;
  }

  // NgbDatePicker uses its own internal models, so we need to manually convert to a ListItem<T>
  private makeListItem(date: NgbDate): ListItem<NgbDateStruct> {
    return { disabled: false, selected: false, model: date } as ListItem<NgbDateStruct>;
  }

  public isDateSelected(date: NgbDate) {
    const listItem = this.makeListItem(date);
    return this.isSelected(listItem);
  }

  public isDateDisabled(date: NgbDate) {
    const listItem = this.makeListItem(date);
    return this.isDisabled(listItem);
  }

  public onDateSelection(date: NgbDate) {
    const listItem = this.makeListItem(date);
    listItem.selected = this.isSelected(listItem);
    listItem.disabled = this.isDisabled(listItem);

    // Now normal Toggle
    this.toggle(listItem);
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
