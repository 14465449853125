import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { InlineEditTextboxComponent } from '../inline-edit-textbox/inline-edit-textbox.component';
import { WidgetType } from '../../../core/constants/widget-type';

@Component({
  selector: 'app-widget-choices-editor',
  templateUrl: './widget-choices-editor.component.html',
  styleUrls: ['./widget-choices-editor.component.scss'],
})
export class WidgetChoicesEditorComponent implements OnInit {
  @ViewChildren(InlineEditTextboxComponent) inputComponents: QueryList<InlineEditTextboxComponent>;

  @Input() set choices(choices: string[]) {
    if (Array.isArray(choices)) {
      this._choices = choices;
    } else {
      console.warn('choices is no array.');
      this._choices = [];
    }
  }

  get choices(): string[] {
    return this._choices;
  }

  @Output() changedEvent = new EventEmitter();

  _choices: string[];

  WidgetType = WidgetType;

  Array = Array;

  constructor() {}

  ngOnInit(): void {}

  editChoice(inlineEditComp: InlineEditTextboxComponent) {
    inlineEditComp.asyncSetEditMode();
  }

  updateChoice(index: number, value: string) {
    const choices = [...this.choices];
    choices.splice(index, 1, value);
    this.changedEvent.emit(choices);
    // this.choices = choices;
  }

  deleteChoice(index: number) {
    const choices = [...this.choices];
    choices.splice(index, 1);
    this.changedEvent.emit(choices);
    // this.choices = choices;
  }

  addChoice() {
    setTimeout(() => {
      // first timeout to wait for clickOutside finish to close any opened inline-editor
      const choices = [...this.choices, ''];
      this.changedEvent.emit(choices);
      setTimeout(() => {
        // second timeout to wait for the last added empty input to be in the view
        this.inputComponents.last.asyncSetEditMode();
      }, 0);
    }, 0);
  }

  trackBy(index, item: string) {
    return item;
  }
}
