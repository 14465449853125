import { Component, Input, OnInit } from '@angular/core';
import { FileModel } from '../../../../../core/models/file.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageModalComponent } from '../../../../../shared/components/modals/image-modal/image-modal.component';
import { ChatChannelCommentViewModel } from '../../../../../core/models/chat-channel-comment-view.model';

@Component({
  selector: 'app-chat-comment-image',
  templateUrl: './chat-comment-image.component.html',
  styleUrls: ['./chat-comment-image.component.scss'],
})
export class ChatCommentImageComponent implements OnInit {
  @Input() file: FileModel;
  @Input() selectedCommentForEdit: ChatChannelCommentViewModel;
  @Input() comment: ChatChannelCommentViewModel;

  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {}

  showImageModal() {
    const modelRef = this.modalService.open(ImageModalComponent, {
      size: 'xxl',
      backdrop: 'static',
    });
    modelRef.componentInstance.file = this.file;
  }
}
