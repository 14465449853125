<div
  class="workspace-user "
  *ngIf="user"
  [ngClass]="[isActive ? '' : 'workspace-user--inactive', size ? 'workspace-user--' + size : '']"
  [title]="user.fullName || user.firstName + ' ' + user.lastName"
>
  <div
    *ngIf="!user.profile && !user?.metaData?.profileIcon"
    class="workspace-user__initials"

  >
    {{ user.initials }}
  </div>

  <div
    *ngIf="user.profile || user?.metaData?.profileIcon"
    class="workspace-user__avatar"
    [ngStyle]="{
      'background-image':
        'url(' +
        (user.profile
          ? user.profile?.src
          : user?.metaData?.profileIcon
          ? user?.metaData?.profileIcon?.src
          : '') +
        ')'
    }"
  ></div>
</div>

<!--[ngClass]="'background-color&#45;&#45;' + (user?.metaData?.avatarColor | colorString)"-->
