<ng-template #rowMenu let-row="row" let-index="index">
  <ul class="popover-menu">
    <li class="popover-menu__item" (click)="onTableInsert(row, index)">Insert</li>
    <li class="popover-menu__item" (click)="onTableCopy(row, index)">Copy</li>
    <li class="popover-menu__divider"></li>
    <li class="popover-menu__item" (click)="onTableDelete(row, index)">Delete</li>
  </ul>
</ng-template>

<app-z-table
  sortBy="date"
  [hideSearch]="true"
  [allowNewRow]="!readonly"
  [rowMenu]="!readonly ? rowMenu : null"
  [headerColumns]="tableColumns"
  [showAssignmentLockIcon]="isAssignmentLockIconVisible()"
  [isAllAssignmentsLocked]="isAllAssignmentsLocked()"
  (lockUnlockAssignmentStatus)="onLockUnlockAssignmentStatus($event)"
  [bodyRows]="tableBody"
  (moveGroup)="onTableGroupMove($event)"
  (moveRow)="onTableRowMove($event)"
  (startEdit)="onTableStartEdit($event)"
  (endEdit)="onTableEndEdit($event)"
  (newRow)="onTableNewRow($event)"
>
  <h3 class="assignment-z-table__title">
    <span class="fal mr-2" [ngClass]="['fa-' + (assignmentIcon | iconString)]"></span>
    {{ assignmentTitle }}
  </h3>
</app-z-table>
