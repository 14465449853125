import { Action, createReducer, on } from '@ngrx/store';
import { initialTemplateTagsState, TemplateTagsState } from './template-tags.state';
import { TemplatesActions } from '../templates';
import { TemplateTagsActions } from './index';
import { TemplateTagModel } from '../../core/models/template-tag.model';

const addTemplateTagToState = (state, templateTag: TemplateTagModel): TemplateTagsState => {
  return {
    ...(state || {}),

    templateTagsById: {
      ...state.templateTagsById,
      [templateTag.joinId]: templateTag,
    },

    templateTagsByTemplateId: {
      ...state.templateTagsByTemplateId,
      [templateTag.nodeTemplateId]: [
        ...(state.templateTagsByTemplateId[templateTag.nodeTemplateId] || []).filter(
          tag => tag.joinId !== templateTag.joinId,
        ),
        templateTag,
      ],
    },

    templateTagsByTemplateByType: {
      ...state.templateTagsByTemplateByType,
      [templateTag.nodeTemplateId]: {
        ...(state.templateTagsByTemplateByType[templateTag.nodeTemplateId] || {}),
        [templateTag.tagType]: [
          ...(
            (state.templateTagsByTemplateByType[templateTag.nodeTemplateId] || {})[
              templateTag.tagType
            ] || []
          ).filter(tag => tag.joinId !== templateTag.joinId),
          templateTag,
        ],
      },
    },

    templateTagsByGroupId: {
      ...state.templateTagsByGroupId,
      [templateTag.groupId || 0]: [
        ...(state.templateTagsByGroupId[templateTag.groupId || 0] || []).filter(
          tag => tag.joinId !== templateTag.joinId,
        ),
        templateTag,
      ],
    },
  };
};

const removeTemplateTagFromState = (state, templateTag: TemplateTagModel): TemplateTagsState => {
  return {
    ...(state || {}),

    templateTagsById: {
      ...state.templateTagsById,
      [templateTag.joinId]: undefined,
    },

    templateTagsByTemplateId: {
      ...state.templateTagsByTemplateId,
      [templateTag.nodeTemplateId]: [
        ...(state.templateTagsByTemplateId[templateTag.nodeTemplateId] || []).filter(
          tag => tag.joinId !== templateTag.joinId,
        ),
      ],
    },

    templateTagsByTemplateByType: {
      ...state.templateTagsByTemplateByType,
      [templateTag.nodeTemplateId]: {
        ...(state.templateTagsByTemplateByType[templateTag.nodeTemplateId] || {}),
        [templateTag.tagType]: [
          ...(
            (state.templateTagsByTemplateByType[templateTag.nodeTemplateId] || {})[
              templateTag.tagType
            ] || []
          ).filter(tag => tag.joinId !== templateTag.joinId),
        ],
      },
    },

    templateTagsByGroupId: {
      ...state.templateTagsByGroupId,
      [templateTag.groupId || 0]: [
        ...(state.templateTagsByGroupId[templateTag.groupId || 0] || []).filter(
          tag => tag.joinId !== templateTag.joinId,
        ),
      ],
    },
  };
};

const reducer = createReducer(
  initialTemplateTagsState,
  on(
    TemplatesActions.refreshNodeTemplatesSuccess,
    (state, { nodeTemplates }): TemplateTagsState => {
      let newState = { ...initialTemplateTagsState };
      nodeTemplates.forEach(template => {
        template.tags.forEach(tag => {
          newState = addTemplateTagToState(newState, tag);
        });
      });
      return newState;
    },
  ),

  on(TemplateTagsActions.addTemplateTagsSuccess, (state, { templateTags, groupId, replace }) => {
    let newState = { ...state };
    if (replace === true) {
      // todo: What if not in Group?
      const groupTags = state.templateTagsByGroupId[groupId] || [];
      groupTags.forEach(tt => {
        newState = removeTemplateTagFromState(newState, tt);
      });
    }

    if (templateTags.length > 0) {
      newState = templateTags.reduce((newState, templateTag) => {
        return addTemplateTagToState(newState, templateTag);
      }, newState);
    }

    return newState;
  }),

  on(TemplateTagsActions.removeTemplateTagRequest, (state, { templateId, joinId }) => {
    let templateTag = state.templateTagsById[joinId] || null;
    if (templateTag != null) {
      return removeTemplateTagFromState(state, templateTag);
    }
    return state;
  }),

  on(TemplateTagsActions.removeTemplateTagsByTagId, (state, { tagId }) => {
    let tagsToRemove = Object.keys(state.templateTagsById)
      .filter(key => {
        return state.templateTagsById[key] && state.templateTagsById[key].id === tagId;
      })
      .map(key => state.templateTagsById[key]);

    if (tagsToRemove.length) {
      return tagsToRemove.reduce((newState, tag) => {
        return removeTemplateTagFromState(newState, tag);
      }, state);
    }
    return state;
  }),

  on(TemplateTagsActions.sortTemplateTagsRequest, (state, { joinIds }) => {
    let idx = 0;
    return joinIds.reduce((newState, id) => {
      return addTemplateTagToState(newState, {
        ...newState.templateTagsById[id],
        sortIndex: idx++,
      });
    }, state);
  }),
);

export function templateTagsReducer(state: TemplateTagsState, action: Action) {
  return reducer(state, action);
}
