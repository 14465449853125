import { Component, Input, OnInit } from '@angular/core';
import { CurvedCornerType } from '../../../../../core/constants/curved-corner-type';
import { CurvedCornerColorType } from '../../../../../core/constants/curved-corner-color-type';
import { ChatChannelCommentViewModel } from '../../../../../core/models/chat-channel-comment-view.model';

@Component({
  selector: 'app-chat-comment-bubble',
  templateUrl: './chat-comment-bubble.component.html',
  styleUrls: ['./chat-comment-bubble.component.scss'],
})
export class ChatCommentBubbleComponent implements OnInit {
  // @Input() comment: string;
  @Input() comment: ChatChannelCommentViewModel;
  @Input() fromRight: boolean;
  @Input() selectedCommentForEdit: ChatChannelCommentViewModel;

  CurvedCornerType = CurvedCornerType;
  CurvedCornerColorType = CurvedCornerColorType;

  constructor() {}

  ngOnInit(): void {}
}
