import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-panel-subsection',
  templateUrl: './panel-subsection.component.html',
  styleUrls: ['./panel-subsection.component.scss'],
})
export class PanelSubsectionComponent implements OnInit {
  @Input() title: string;
  @Input() titleTooltip?: string;
  @Input() backgroundTransparent?: boolean;
  @Input() boxShadow?: boolean;
  @Input() headerRightTemplate?: TemplateRef<any>;
  @Input() headerToolbarTemplate?: TemplateRef<any>;
  @Input() headerLargeToolbarTemplate?: TemplateRef<any>;
  @Input() noHorizontalPadding?: boolean;
  @Input() noVerticalPadding?: boolean;

  constructor() {}

  ngOnInit() {}
}
