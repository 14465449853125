<app-confirm-popup
  [title]="'Session Timeout'"
  [message]="undefined"
  [yesText]="'Continue Session'"
  [noText]="'Log Off'"
  [variant]="'info'"
  [noCloseBtn]="true"
>
  <p>
    You will be timed out in {{countdown}} seconds due to in-activity on this device. Please choose to continue the
    session or log off.
  </p>
  <p>Please note: continuing the session will return you to the last screen of your device.</p>
</app-confirm-popup>
