import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserEditableFields, UserModel } from '../../../core/models/user.model';
import { Subscription } from 'rxjs';
import { NgbActiveModal, NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ControlValidationService } from '../control-validation/control-validation.service';
import { AuthActions } from '../../../store/auth';
import { AvatarUploadModalComponent } from '../avatar-upload-modal/avatar-upload-modal.component';
import { defaultPlacements } from '../../../core/constants/placements';
import { selectCurrentUser } from '../../../store/auth/auth.selectors';

@Component({
  selector: 'app-account-modal',
  templateUrl: './account-modal.component.html',
  styleUrls: ['./account-modal.component.scss'],
})
export class AccountModalComponent implements OnInit, OnDestroy {
  private readonly subscription = new Subscription();

  public accountForm: UntypedFormGroup;
  public submitted = false;
  public user: UserModel;

  defaultPlacements = defaultPlacements;

  // convenience getter for easy access to form fields
  public get f() {
    return this.accountForm.controls;
  }

  constructor(
    public modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private store: Store,
    private formBuilder: UntypedFormBuilder,
  ) {}

  ngOnInit(): void {
    this.accountForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phone: [''],
      email: ['', [Validators.required, ControlValidationService.emailValidator]],
    });

    this.subscription.add(
      this.store.pipe(select(selectCurrentUser)).subscribe(user => {
        this.f.firstName.setValue(user.firstName);
        this.f.lastName.setValue(user.lastName);
        this.f.email.setValue(user.email);
        this.f.phone.setValue(user.phone);
        this.user = user;
      }),
    );
  }

  updateUserField(field: keyof UserEditableFields) {
    if (this.accountForm.controls[field].valid && this.accountForm.controls[field].dirty) {
      this.store.dispatch(
        AuthActions.patchCurrentUserRequest({
          userData: { [field]: this.accountForm.controls[field].value },
        }),
      );
    }
  }

  changePhoto(popover: NgbPopover) {
    if (this.user.profile) {
      if (popover.isOpen()) {
        popover.close();
      } else {
        popover.open();
      }
    } else {
      this.modalService.open(AvatarUploadModalComponent, { size: 'sm' });
    }
  }

  close() {
    this.activeModal.close();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
