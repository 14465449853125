import { AppState } from '../index';
import { createSelector } from '@ngrx/store';

export const selectTimesheetsState = (state: AppState) => state.timesheets;

export const selectTimesheetsById = createSelector(
  selectTimesheetsState,
  state => state.timesheetsById,
);

export const selectTimesheetsByNodeId = createSelector(
  selectTimesheetsState,
  state => state.timesheetsByNodeId,
);

export const selectTimesheetsByParentNodeId = createSelector(
  selectTimesheetsState,
  state => state.timesheetsByParentNodeId,
);
