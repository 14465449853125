import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ITableEditableCell } from './table-editable-cell.interface';
import { TableCell, TableColumn, TableRow } from '../table.common';
import { TableComponent } from '../table.component';
import { TableCellEditEvent } from './table-editors.common';

@Component({
  template: '',
})
export abstract class AbstractTableEditableCellComponent implements ITableEditableCell, OnInit {
  public readonly uuid;
  public readonly elementId;

  public get editable(): boolean {
    if (!this.tableEditable) {
      return false;
    }
    const cellEditable = this.cell && this.cell.editable !== false;
    const columnEditable = this.column && this.column.editable !== false;
    return cellEditable && columnEditable;
  }

  @ViewChild('input')
  public input: ElementRef;

  @Input()
  public tableEditable?: boolean;

  @Input()
  public field: string;

  @Input()
  public column: TableColumn;

  @Input()
  public row: TableRow;

  @Input()
  public cell: TableCell;

  @Output()
  public startEdit = new EventEmitter<TableCellEditEvent>();

  @Output()
  public endEdit = new EventEmitter<TableCellEditEvent>();

  protected previousValue: any;

  protected defaultOptions: any;

  public options: any;

  protected constructor(public table: TableComponent) {
    this.uuid = Math.floor(Math.random() * (99999 - 11111 + 1)) + 11111;
    this.elementId = `zTableInput_${this.uuid}`;
  }

  protected buildEditEvent(): TableCellEditEvent {
    return {
      type: this.constructor.name,
      field: this.field,
      row: this.row,
      cell: this.cell,
      previousValue: this.previousValue,
    } as TableCellEditEvent;
  }

  public ngOnInit(): void {
    this.previousValue = this.cell.value;
    this.options = {
      ...(this.defaultOptions || {}),
      ...(this.column.options || {}),
      ...(this.cell.options || {}),
    };
  }

  public onStartEdit() {
    this.previousValue = this.cell.value;
    this.startEdit.emit(this.buildEditEvent());
  }

  public onEndEdit() {
    this.endEdit.emit(this.buildEditEvent());
  }

  public onEnterKey() {
    this.input && this.input.nativeElement.blur();
  }

  public onEscapeKey() {
    this.cell.value = this.previousValue;
    this.input && this.input.nativeElement.blur();
  }
}
