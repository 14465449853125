<div
  *ngIf="isLoading"
  [ngClass]="[
    'loader',
    'loader-content',
    'loader-content--' + (scope || 'empty'),
    !positionAbsolute ? 'loader-content--sticky' : ''
  ]"
  (click)="onClick($event)"
>
  <ng-lottie [options]="options"></ng-lottie>
</div>
