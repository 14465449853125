import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-assignment-field',
  templateUrl: './assignment-field.component.html',
  styleUrls: ['./assignment-field.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AssignmentFieldComponent implements OnInit {
  @Input() title: string;
  @Input() readonly: boolean;

  @Output() editEvent = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  edit() {
    this.editEvent.emit();
  }
}
