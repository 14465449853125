import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SizeType } from '../../../core/constants/size-type';
import { NodeModel } from '../../../core/models/node.model';

@Component({
  selector: 'app-assignment-asset',
  templateUrl: './assignment-asset.component.html',
  styleUrls: ['./assignment-asset.component.scss'],
})
export class AssignmentAssetComponent {
  @Input() node: NodeModel;
  @Input() showProfilePhoto: boolean;
  @Input() displayAsList: boolean;
  @Input() isDuplicate: boolean;
  @Input() size: SizeType;

  @Output() viewEvent = new EventEmitter();

  constructor() {}

  view(event) {
    event.stopPropagation();
    this.viewEvent.emit();
  }
}
