<img
  *ngIf="file?.src"
  class="chat-comment-image"
  [src]="file.src"
  alt="{{ file.title }}}"
  [ngClass]="{
    'chat-comment-image--edit-active': comment?.id === selectedCommentForEdit?.id
  }"
  [title]="file.title"
  (click)="showImageModal()"
/>
