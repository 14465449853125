<li
  *ngIf="node"
  class="leaf-list-item"
  [ngClass]="[
    colorTheme ? 'leaf-list-item--' + (colorTheme | colorString) : '',
    selectedLeafIds.indexOf(node.id) >= 0 ? 'leaf-list-item--selected' : '',
    selectedLeafIds.length > 0 ? 'leaf-list-item--show-check' : '',
    isDraggingOfSelected ? 'leaf-list-item--dragging' : '',
    readonly ? 'leaf-list-item--readonly' : ''
  ]"
  (click)="openNodeView()"
>
  <ng-content></ng-content>

  <span
    *ngIf="!readonly"
    class="leaf-list-item__icon leaf-list-item__icon--move fal fa-arrows-alt-v"
  ></span>

  <app-btn-check
    class="leaf-list-item__icon leaf-list-item__icon--check"
    [isChecked]="selectedLeafIds.indexOf(node.id) >= 0"
    (click)="toggleLeaf($event)"
  ></app-btn-check>
  <div class="leaf-list-item__body">
    <div class="leaf-list-item__title-row">
      <app-node-avatar
        *ngIf="node.nodeType === NodeType.asset"
        class="leaf-list-item__avatar"
        [profile]="node.profile"
        [profileIcon]="node?.nodeMeta?.profileIcon"
        [initials]="node.title | toInitials"
        [fullName]="node.title"
        [size]="'sm'"
        [colorTheme]="colorTheme"
      ></app-node-avatar>

      <app-fa-icon
        class="leaf-list-item__avatar"
        *ngIf="node.nodeType === NodeType.element"
        [icon]="node?.nodeTemplate?.icon ?? IconType['question']"
      >
      </app-fa-icon>

      <div class="leaf-list-item__title-info-col">
        <app-truncate-text
          class="leaf-list-item__title-text"
          [value]="node.title"
          [limit]="20"
        ></app-truncate-text>
        <span class="leaf-list-item__template">{{ node.nodeTemplate?.title }} </span>
        <!-- layouts -->
        <div *ngIf="layoutTemplateViewMode === 'show'" class="leaf-list-item__layout-templates">
          <span
            *ngFor="let layout of node.allowedTemplates; trackBy: itemsTrackBy"
            app-selectable-brick
            class="leaf-list-item__layout-template"
            [sizeType]="'sm'"
            [label]="layout.title"
            [icon]="layout.icon"
            [readonly]="readonly"
          ></span>
        </div>
      </div>
    </div>

    <!-- widgets -->
    <span class="leaf-list-item__widgets" *ngIf="widgetsViewMode === 'show'">
      <ng-container *ngFor="let widget of node.widgets; trackBy: itemsTrackBy">
        <span class="leaf-list-item__widget" *ngIf="widget | widgetValue">
          <span class="leaf-list-item__widget-label">
            {{ widget.widget.title }}
          </span>
          <span class="leaf-list-item__widget-value">
            <app-truncate-text [value]="widget | widgetValue" [limit]="24"></app-truncate-text>
          </span>
        </span>
      </ng-container>
    </span>
    <!-- tags -->
    <span *ngIf="tagsViewMode === 'show' && node.tags?.length > 0" class="leaf-list-item__tags">
      <span
        *ngFor="let nodeTag of node.tags; trackBy: itemsTrackBy"
        (click)="openTagsPopover($event)"
        class="leaf-list-item__tag-wrapper"
      >
        <span
          *ngIf="nodeTag && nodeTag.tag"
          app-selectable-brick
          class="leaf-list-item__tag"
          [sizeType]="'sm'"
          [label]="nodeTag.tag.title"
          [color]="nodeTag.tag.colorTheme"
          [icon]="nodeTag.tag.icon"
          [readonly]="readonly"
        ></span>
      </span>
    </span>
  </div>

  <app-context-buttons
    class="leaf-list-item__option-icon"
    [node]="node"
    (click)="stopPropagation($event)"
  ></app-context-buttons>

  <app-content-loader [scope]="node.id?.toString()"></app-content-loader>
</li>
