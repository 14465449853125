import { Component, Input, OnDestroy, TemplateRef, OnInit } from '@angular/core';
import { AssignmentViewType } from '../../../core/constants/assignment-view-type';
import { WidgetType } from '../../../core/constants/widget-type';
import { ToggleViewMode } from '../../../core/constants/toggle-view-mode';
import { NodeModel } from '../../../core/models/node.model';
import { NodeType } from '../../../core/constants/node-type';
import { NodeTemplateModel } from '../../../core/models/node-template.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LayoutAndTagPickerComponent } from '../../modals/layout-and-tag-picker/layout-and-tag-picker.component';
import { NodesActions, NodesSelectors } from '../../../store/nodes';
import { BehaviorSubject, of, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../store';
import { delay, filter, switchMap } from 'rxjs/operators';
import { AssignmentSelectionTimerService } from '../../../core/services/assignment-selection-timer.service';
@Component({
  selector: 'app-assignment-overlay-card',
  templateUrl: './assignment-overlay-card.component.html',
  styleUrls: ['./assignment-overlay-card.component.scss'],
})
export class AssignmentOverlayCardComponent implements OnInit, OnDestroy {
  private readonly subscription = new Subscription();

  public readonly WidgetType = WidgetType;
  public readonly CalendarViewType = AssignmentViewType;

  @Input() assignment: NodeModel;
  @Input() withoutScrollbars: boolean;
  @Input() progressViewMode: ToggleViewMode;
  @Input() assignmentHeaderDetailsViewMode: ToggleViewMode;
  @Input() bodyHeaderTemplateRef: TemplateRef<any>;
  @Input() bodyTemplateRef: TemplateRef<any>;
  @Input() footerBtnTemplateRef: TemplateRef<any>;
  @Input() hideFirstCard: boolean;
  @Input() readonly?: boolean = false;
  @Input() type: 'assignments' | 'sharedLinks' = 'assignments';

  isExpanded: boolean;
  isSelected = false;
  selectedAssignmentIds: number[];
  showSelectionAnimationSubject = new BehaviorSubject(of(false));
  showSelectionAnimation$ = this.showSelectionAnimationSubject.asObservable().pipe(
    switchMap(show => show), // need switchMap to cancel out
  );

  constructor(
    private readonly modalService: NgbModal,
    private readonly store: Store<AppState>,
    private readonly assignmentSelectionTimerService: AssignmentSelectionTimerService,
  ) {
    //
  }

  ngOnInit(): void {
    this.subscription.add(
      this.store.pipe(select(NodesSelectors.selectAssignmentSelectedIds)).subscribe(selectedIds => {
        this.selectedAssignmentIds = selectedIds;
        const isCurrentlySelected = selectedIds.indexOf(this.assignment?.id) >= 0;
        if (this.isSelected !== isCurrentlySelected) {
          this.showSelectionAnimation();
          this.isSelected = selectedIds.indexOf(this.assignment?.id) >= 0;
        }
      }),
    );

    this.subscription.add(
      this.assignmentSelectionTimerService.isSelectionTimeout$
        .pipe(filter(() => this.isSelected))
        .subscribe(isTimeout => {
          if (isTimeout) {
            this.showSelectionAnimation();
          }
        }),
    );
  }

  showSelectionAnimation() {
    this.showSelectionAnimationSubject.next(of(false)); // false to remove animation class first
    this.showSelectionAnimationSubject.next(of(true).pipe(delay(0))); // delay to make it async
  }

  toggleAssignment() {
    if (this.readonly) {
      return;
    }
    this.store.dispatch(
      NodesActions.toggleSelectedAssignment({
        assignmentId: this.assignment?.id,
      }),
    );
  }

  setUpLayout() {
    if (this.readonly) {
      return;
    }
    const modelRef = this.modalService.open(LayoutAndTagPickerComponent, {
      size: 'md',
      backdrop: 'static',
    });
    modelRef.componentInstance.types = [NodeType.assignment];
    // modelRef.componentInstance.whitelist = this.assignment.reference.allowedTemplates || [];
    modelRef.componentInstance.assignment = this.assignment;
    modelRef.componentInstance.minSelection = 1;
    modelRef.componentInstance.maxSelection = 1;
    modelRef.result
      .then((result: NodeTemplateModel[]) => {
        if (result.length === 0) {
          return;
        }
      })
      .catch(res => {});
  }

  openLink(widgetId: number) {
    // this.store.dispatch(
    //   AssignmentsActions.navigateToAssignmentUrlsViewer({
    //     projectId: this.assignment.reference.id,
    //     widgetId,
    //   }),
    // );
  }

  openProjectAssignmentsPopover() {}

  toggleExpanded() {
    this.isExpanded = !this.isExpanded;
  }

  toggleLock() {
    // this.store.dispatch(
    //   AssignmentsActions.updateAssignmentReadonlyRequest({
    //     assignmentId: this.assignment.id,
    //     readonly: !this.assignment.readOnly,
    //   }),
    // );
  }

  trackBy(index, item) {
    return item.id;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
