<div class="node-avatar-upload-modal">
  <app-sticky-panel-header
    [title]="profile ? 'Change Profile Photo' : 'Add Profile Photo'"
    [isLoading]="isLoading"
    (closeEvent)="close()"
  ></app-sticky-panel-header>
  <div class="node-avatar-upload-modal__body">
    <app-avatar-uploader
      class="node-avatar-upload-modal__uploader"
      [fileTypes]="'image/*'"
      [isLoading]="isUploaderLoading"
      [selectedProfileIcon]="profileIcon"
      (confirmSetProfileImageEvent)="uploadFiles($event)"
      (confirmSetPredefineProfileIconEvent)="savePredefineProfileIcon($event)"
    >
    </app-avatar-uploader>
  </div>
</div>
