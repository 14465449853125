import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IconType } from '../../../core/constants/icon-type';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { OverlayPopoverService } from '../../../shared/components/overlay-popover/overlay-popover.service';
import { TemplateFiguresPopoverActions } from '../../../store/template-figures-popover';
import { TemplateFiguresPopoverComponent } from '../template-figures-popover/template-figures-popover.component';
import { NodeTemplateModel } from '../../../core/models/node-template.model';
import { CalculationWidgetModel } from '../../../core/models/calculation-widget.model';
import { TemplatesActions } from '../../../store/templates';

@Component({
  selector: 'app-template-figures',
  templateUrl: './template-figures.component.html',
  styleUrls: ['./template-figures.component.scss'],
})
export class TemplateFiguresComponent implements OnInit, OnDestroy {
  private readonly subscription = new Subscription();

  public readonly IconType = IconType;

  @Input() selectedTemplate: NodeTemplateModel;
  @Input() calculationWidgets: CalculationWidgetModel[];
  @Input() calIds: number[];

  constructor(private store: Store, private popoverService: OverlayPopoverService) {}

  ngOnInit(): void {
    //
  }

  showFiguresCreatePage(origin) {
    this.popoverService.open({
      content: TemplateFiguresPopoverComponent,
      position: 'bottom',
      origin,
    });
    this.store.dispatch(
      TemplateFiguresPopoverActions.showTemplateFiguresCalculationCreatePage({
        templateId: this.selectedTemplate?.id,
      }),
    );
  }

  changeSwitch(): void {
    let templateProps = {};
    templateProps = {
      allowFigures: !this.selectedTemplate?.allowFigures,
    };
    this.store.dispatch(
      TemplatesActions.updateNodeTemplateRequest({
        templateId: this.selectedTemplate.id,
        templateProps,
      }),
    );
  }

  trackBy(index, widget: CalculationWidgetModel) {
    return widget.id;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
