export enum ChannelType {
  workspace = 'workspace',
  node = 'node',
  user = 'user',
  group = 'group',
}

export enum ChannelAction {
  delete = 0,
  open,
}
