<ul class="template-list" cdkDropList (cdkDropListDropped)="sortTemplates($event)">
  <li
    cdkDrag
    class="template-list__item"
    *ngFor="let template of templateList; trackBy: trackBy; let index = index"
    [ngClass]="{ 'template-list__item--selected': selectedTemplateId === template.id }"
    (click)="selectTemplate(template.id)"
  >
    <span class="template-list__item-left-column">
      <div cdkDragHandle class="template-list__icon-move  fal fa-arrows-alt-v"></div>
      <span class="template-list__item-title">{{ template.title }}</span>
      <sup class="template-list__item-default" *ngIf="template.isDefault">Default</sup>
    </span>
    <span class="template-list__item-right-column">
      <span class="template-list__item-check-icon fal fa-check"></span>

      <button
        type="button"
        class="template-list__item-action-icon btn fal fa-bars"
        [ngbPopover]="popContent"
        container="body"
        [placement]="defaultPlacements"
        (click)="stopPropagation($event)"
      ></button>

      <ng-template #popContent>
        <app-template-menu
          [template]="template"
          [workspaceSlug]="slug"
          [defaultTemplateId]="defaultTemplateId"
        ></app-template-menu>
      </ng-template>

      <span
        class="template-list__item-number"
        [ngClass]="{ invisible: serverNodeType === ServerNodeType.project }"
        >{{ index + 1 }}</span
      >
    </span>
  </li>
</ul>
