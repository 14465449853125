<ng-template #modalFooter>
  <button *ngIf="group" type="button" class="btn btn-sm btn-danger mr-auto" (click)="onDelete()">
    Delete
  </button>

  <button type="button" class="btn btn-sm btn-outline-light" (click)="activeModal.dismiss()">
    Cancel
  </button>

  <button ngbAutofocus type="submit" class="btn btn-sm btn-primary" (click)="onSubmit()">
    Save
  </button>
</ng-template>

<app-modal title="Edit group" [footerTemplate]="modalFooter">
  <div class="tag-edit">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <app-panel-subsection title="Title">
        <input
          type="text"
          class="form-control"
          placeholder="Title"
          required
          formControlName="title"
          [ngClass]="f.title.invalid && (f.title.dirty || isSubmitted) ? 'is-invalid' : ''"
        />
        <div class="invalid-feedback">
          Please provide a title
        </div>
      </app-panel-subsection>
    </form>
  </div>
</app-modal>
