import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class ControlValidationService {
  public static getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
    const config = {
      required: 'This field is required',
      invalid: 'This field is invalid',
      invalidCreditCard: 'Is invalid credit card number',
      invalidEmailAddress: 'Invalid email address',
      invalidPassword:
        'Invalid password. Password must be at least 6 characters long, and contain a number.',
      minlength: `Minimum length ${validatorValue.requiredLength}`,
      passwordsNotEqual: `Confirm password and Password don't match`,

      // Custom
      ngbDate: 'This date is invalid',
      existingWorkspaceEmail: 'The email is already used by an activated workspace user',
      timelineOverlapped:
        'The timeline has overlapped with other timeline of selected Project and Primary Tag.',
      customError: '',
    };

    // Show passed custom error message.
    if (validatorName === 'customError') {
      return validatorValue.messages;
    }

    return config[validatorName];
  }

  public static creditCardValidator(control) {
    // Visa, MasterCard, American Express, Diners Club, Discover, JCB
    // tslint:disable-next-line:max-line-length
    if (
      control.value.match(
        // tslint:disable-next-line:max-line-length
        /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/,
      )
    ) {
      return null;
    } else {
      return { invalidCreditCard: true };
    }
  }

  public static emailValidator(control) {
    // RFC 2822 compliant regex
    if (
      control.value.match(
        // tslint:disable-next-line:max-line-length
        /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/,
      )
    ) {
      return null;
    } else {
      return { invalidEmailAddress: true };
    }
  }

  static passwordValidator(control) {
    // {6,100}           - Assert password is between 6 and 100 characters
    // (?=.*[0-9])       - Assert a string has at least one number
    if (control.value.match(/^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{6,100}$/)) {
      return null;
    } else {
      return { invalidPassword: true };
    }
  }

  // todo: this should be more generic (ie. compare any fields)
  public static RepeatPasswordValidator(group: UntypedFormGroup) {
    const password = group.controls.password.value;
    const confirmPassword = group.controls.passwordConfirm.value;
    if (password === confirmPassword) {
      return null;
    } else {
      return { passwordsNotEqual: true };
    }
  }
}
