import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { ScheduleLayoutType } from '../../../core/constants/schedule-layout-type';
import { NodeModel } from '../../../core/models/node.model';
import { SharedLinkModel } from '../../../core/models/shared-link.model';
import { selectSharedViewAssignmentViewModelArraysByDateKey } from '../../../store/shared-view/shared-view-assignment-view-models.selectors';
import {
  selectSharedViewAssignmentViewType,
  selectSharedViewAssignmentCardLayout,
  selectSharedViewServerNodeTemplatesById,
} from '../../../store/shared-view/shared-view.selectors';
import { AssignmentViewType } from '../../../core/constants/assignment-view-type';
import { CardLayoutType } from '../../../core/constants/card-layout-type';
import { CustomViewNodeType } from '../../../core/constants/custom-view-node-type';
import { NodeUtils } from '../../../core/utils/node.util';
import { WidgetType } from '../../../core/constants/widget-type';
import { NoteType } from '../../../core/constants/note-type';
import * as moment from 'moment';
import { TemplatesById } from '../../../store/templates/templates.state';

@Component({
  selector: 'app-active-link',
  templateUrl: './active-link.component.html',
  styleUrls: ['./active-link.component.scss'],
})
export class ActiveLinkComponent implements OnInit, OnDestroy, OnChanges {
  @Input() selectedLink: SharedLinkModel;
  @Input() publicView = false;

  subscription = new Subscription();
  assignmentsByDateKey: { [dateKey: string]: NodeModel[] };
  dateKeys: string[];
  scheduleLayout: ScheduleLayoutType = ScheduleLayoutType.horizontal;
  public readonly NoteType = NoteType;
  public readonly AssignmentViewType = AssignmentViewType;
  viewType: AssignmentViewType;
  cardLayout: CardLayoutType;
  day: moment.Moment;

  awaitingAssignmentsDateKey = NodeUtils.awaitingAssignmentsDateKey;

  isExpanded: any[] = [];

  CardLayoutType = CardLayoutType;
  CustomViewNodeType = CustomViewNodeType;
  WidgetType = WidgetType;
  nodeTemplatesById: TemplatesById;

  constructor(private store: Store) {
    //
  }

  ngOnChanges() {
    if (this.selectedLink) {
      this.assignmentsByDateKey = {};
      this.dateKeys = [];
    }
  }

  ngOnInit(): void {
    this.subscription.add(
      this.store.pipe(select(selectSharedViewServerNodeTemplatesById)).subscribe(items => {
        this.nodeTemplatesById = items;
      }),
    );

    this.subscription.add(
      this.store
        .pipe(select(selectSharedViewAssignmentViewModelArraysByDateKey))
        .subscribe(items => {
          this.assignmentsByDateKey = items;
          this.dateKeys = Object.keys(items);
        }),
    );

    this.subscription.add(
      this.store.pipe(select(selectSharedViewAssignmentViewType)).subscribe(viewType => {
        this.viewType = viewType;
      }),
    );

    this.subscription.add(
      this.store.pipe(select(selectSharedViewAssignmentCardLayout)).subscribe(cardLayout => {
        this.cardLayout = cardLayout;
      }),
    );
  }

  toggleExpanded(assId: number) {
    const item = this.isExpanded.find(item => +item?.id === +assId);
    if (item) {
      item.value = !item.value ?? false;
    } else {
      this.isExpanded.push({ id: assId, value: true });
    }
  }

  isAssignmentExpanded(assId: number): boolean {
    return this.isExpanded.find(item => item.id === assId)?.value;
  }

  trackBy(index, item: string) {
    return item;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
