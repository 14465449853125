import { Pipe, PipeTransform } from '@angular/core';
import { WidgetType } from '../../core/constants/widget-type';

@Pipe({
  name: 'widgetInputType',
})
export class WidgetInputTypePipe implements PipeTransform {
  transform(value: WidgetType): string {
    if (
      value === WidgetType.textbox ||
      value === WidgetType.heading ||
      value === WidgetType.subHeading ||
      value === WidgetType.lineSeparator ||
      value === WidgetType.paragraph
    ) {
      return 'text';
    } else if (value === WidgetType.number) {
      return 'number';
    } else if (value === WidgetType.email) {
      return 'email';
    } else if (value === WidgetType.phone) {
      return 'tel';
    } else if (value === WidgetType.url) {
      return 'url';
    } else if (value === WidgetType.date) {
      return 'date';
    } else if (value === WidgetType.slider) {
      return 'range';
    } else if (value === WidgetType.dateTime) {
      return 'datetime-local';
    } else if (value === WidgetType.time) {
      return 'time';
    } else {
      return 'text';
    }
  }
}
