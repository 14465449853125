import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../../store';
import { NodeType } from '../../../core/constants/node-type';
import { NodePickerComponent } from '../node-picker/node-picker.component';
import { NodesActions, NodesSelectors } from '../../../store/nodes';
import { selectNodeListPanelSelectedNode } from '../../../store/node-list-panel/node-list-panel.selectors';
import { NodeModel } from '../../../core/models/node.model';

@Component({
  templateUrl: './node-asset-picker.component.html',
  styleUrls: ['./node-asset-picker.component.scss'],
})
export class NodeAssetPickerComponent extends NodePickerComponent {
  @Input() public title: string;
  public readonly NodeType = NodeType;
  public folderNode: NodeModel;
  public assetRootFolder: NodeModel;
  @Input()
  public allowNew: boolean;

  constructor(
    public activeModal: NgbActiveModal,
    protected modalService: NgbModal,
    protected store: Store<AppState>,
  ) {
    super(activeModal, modalService, store);
    this.types = [NodeType.asset];
    this.title = 'Select Resource';
    this.allowNew = false;

    this.subscription.add(
      this.store.pipe(select(selectNodeListPanelSelectedNode)).subscribe(node => {
        this.folderNode = node;
      }),
    );

    this.subscription.add(
      this.store.pipe(select(NodesSelectors.selectRootNodes)).subscribe((nodes: NodeModel[]) => {
        nodes.map(n => {
          if (n.nodeType === NodeType.assetFolder) {
            this.assetRootFolder = n;
          }
        });
      }),
    );
  }

  public add() {
    this.store.dispatch(
      NodesActions.addNodeRequest({
        parentNode:
          this.assetRootFolder?.children?.find(c => c.nodeType === NodeType.assetFolder) || null,
        nodeType: NodeType.asset,
      }),
    );
  }
}
