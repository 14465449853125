import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-popover-subsection',
  templateUrl: './popover-subsection.component.html',
  styleUrls: ['./popover-subsection.component.scss'],
})
export class PopoverSubsectionComponent implements OnInit {
  @Input() title: string;
  @Input() transparentBackground: boolean;
  @Input() headerToolbarTemplate: TemplateRef<any>;

  constructor() {}

  ngOnInit(): void {}
}
