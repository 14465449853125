<div
  class="img-lazy-loader"
  [ngClass]="{
    'img-lazy-loader-img': isImage(),
    'img-lazy-loader-document': isDocument(),
    'img-lazy-loader-video': isVideo(),
    'img-lazy-loader-audio': isAudio(),
    'img-lazy-loader-unknown': isUnknown(),
    'img-lazy-loader-empty': !file || !file.src
  }"
>
  <div
    *ngIf="isImage() && file.src"
    class="image"
    [ngStyle]="{ 'background-image': 'url(' + file.src + ')' }"
  >
    <img #image [src]="file.src | safe" alt="{{ file.title }}" (load)="onLoadingComplete($event)" />
  </div>

  <object *ngIf="isDocument() && file.src" [data]="file.src | safe" type="{{ file.mime }}">
    <embed
      #document
      [src]="file.src | safe"
      type="{{ file.mime }}"
      (load)="onLoadingComplete($event)"
    />
  </object>

  <video
    #video
    *ngIf="isVideo() && file.src"
    width="100%"
    height="100%"
    controls
    (loadedmetadata)="onLoadingComplete($event)"
  >
    <source [src]="file.src | safe" type="{{ file.mime }}" />
    Your browser does not support the video tag.
  </video>

  <audio
    #audio
    *ngIf="isAudio() && file.src"
    width="100%"
    height="100%"
    controls
    (loadedmetadata)="onLoadingComplete($event)"
  >
    <source [src]="file.src | safe" type="{{ file.mime }}" />
    Your browser does not support the audio tag.
  </audio>

  <span *ngIf="loading" class="spinner-border spinner-border-lg"></span>
</div>
<!-- /img-lazy-loader -->
