import { OptionModel } from '../models/option.model';
import { IconType } from './icon-type';

export enum AssignmentLayoutMode {
  schedule = 'schedule',
  planBoard = 'plan-board',
  pricing = 'pricing',
  database = 'database',
  report = 'report',
  vertical = 'vertical',
  chart = 'chart',
  table = 'table',
}

export const layoutOptions: OptionModel[] = [
  {
    id: AssignmentLayoutMode.schedule,
    label: 'Schedule',
    icon: IconType['grip-horizontal'],
  },
  {
    id: AssignmentLayoutMode.table,
    label: 'Tables',
    icon: IconType['table'],
  },
  // {
  //   id: LayoutType.chart,
  //   label: 'Gantt Chart',
  //   icon: IconType['tasks-alt'],
  // },
];
