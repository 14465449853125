import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertComponent } from './alert/alert.component';
import { PaginatorComponent } from './paginator/paginator.component';
import { FileUploaderComponent } from './file-uploader/file-uploader.component';
import { FileLazyLoaderComponent } from './file-uploader/file-lazy-loader/file-lazy-loader.component';
import {
  ControlValidationComponent,
  FormValidationComponent,
} from './control-validation/control-validation.component';
import { FriendInviteModalComponent } from './modals/friend-invite/friend-invite-modal.component';
import { PrivacyPolicyModalComponent } from './modals/privacy-policy/privacy-policy-modal.component';
import { TermsServiceModalComponent } from './modals/terms-service/terms-service-modal.component';
import { RoundCheckboxComponent } from './round-checkbox/round-checkbox.component';
import { NgbAccordionModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IconRadioButtonComponent } from './icon-radio-button/icon-radio-button.component';
import { SearchBoxComponent } from './search-box/search-box.component';
import { ColorPickerComponent } from './color-picker/color-picker.component';
import { ColorDirective } from './color-picker/color.directive';
import { ColorPickerTriggerDirective } from './color-picker/color-picker-trigger.directive';
import { BidiModule } from '@angular/cdk/bidi';
import { A11yModule } from '@angular/cdk/a11y';
import { OverlayModule } from '@angular/cdk/overlay';
import { CdkTreeModule } from '@angular/cdk/tree';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayMultiSelectComponent } from './overlay-multi-select/overlay-multi-select.component';
import { OverlaySingleSelectComponent } from './overlay-single-select/overlay-single-select.component';
import { PortalModule } from '@angular/cdk/portal';
import { IconPickerComponent } from './icon-picker/icon-picker.component';
import { IconDirective } from './icon-picker/icon.directive';
import { IconPickerTriggerDirective } from './icon-picker/icon-picker-trigger.directive';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
import { ContextButtonEditComponent } from './context-button-edit/context-button-edit.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MultiSelectComponent } from './multi-select/multi-select.component';
import { MultiSelectOptionComponent } from './multi-select/multi-select-option/multi-select-option.component';
import { FileUploaderThumbComponent } from './file-uploader/file-uploader-thumb/file-uploader-thumb.component';
import { OverlayTextareaComponent } from './overlay-textarea/overlay-textarea.component';
import { ScrollingModule as ExperimentalScrollingModule } from '@angular/cdk-experimental/scrolling';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { OverlayPopoverComponent } from './overlay-popover/overlay-popover.component';
import { PopoverLayoutComponent } from './popover-layout/popover-layout.component';
import { SelectableBrickComponent } from './selectable-brick/selectable-brick.component';
import { PanelSubsectionComponent } from './panel-subsection/panel-subsection.component';
import { PopoverSubsectionComponent } from './popover-subsection/popover-subsection.component';
import { PopoverDropdownComponent } from './popover-dropdown/popover-dropdown.component';
import { BrickComponent } from './brick/brick.component';
import { IconCheckboxButtonComponent } from './icon-checkbox-button/icon-checkbox-button.component';
import { RouterModule } from '@angular/router';
import { AddressFieldComponent } from './address-field/address-field.component';
import { HeaderTitleComponent } from './_layout/secured-header/header-title/header-title.component';
import { WorkspaceUsersComponent } from './workspace-users/workspace-users.component';
import { WorkspaceUserComponent } from './workspace-user/workspace-user.component';
import { NodeTypeIconComponent } from './node-type-icon/node-type-icon.component';
import { PanelToggleComponent } from './panel-toggle/panel-toggle.component';
import { BtnEditComponent } from './btn-edit/btn-edit.component';
import { NodeAvatarComponent } from './node-avatar/node-avatar.component';
import { WorkspaceSwitchComponent } from './workspace-switch/workspace-switch.component';
import { SharedLinkComponent } from './shared-link/shared-link.component';
// import { IconStampComponent } from './icon-stamp/icon-stamp.component';
// import { NavStampComponent } from './nav-stamp/nav-stamp.component';
import { ApplyTemplateComponent } from './modals/apply-template/apply-template.component';
import { SelectStampComponent } from './modals/select-stamp/select-stamp.component';
import { BtnTooltipComponent } from './btn-tooltip/btn-tooltip.component';
import { IconInfoComponent } from './icon-info/icon-info.component';
import { ApplyTagsComponent } from './modals/apply-tags/apply-tags.component';
import { StickyPanelHeaderComponent } from './sticky-panel-header/sticky-panel-header.component';
import { AccountModalComponent } from './account-modal/account-modal.component';
import { NodeAvatarEditableComponent } from './node-avatar-editable/node-avatar-editable.component';
import { AvatarUploadModalComponent } from './avatar-upload-modal/avatar-upload-modal.component';
import { ProfilePhotoMenuComponent } from './profile-photo-menu/profile-photo-menu.component';
// import { NavFinanceComponent } from './nav-finance/nav-finance.component';
import { RichDropdownComponent } from './rich-dropdown/rich-dropdown.component';
import { RichDropdownMenuComponent } from './rich-dropdown-menu/rich-dropdown-menu.component';
import { ImageListboxComponent } from './image-listbox/image-listbox.component';
import { GoogleAddressFieldComponent } from './google-address-field/google-address-field.component';
import { TruncateTextComponent } from './truncate-text/truncate-text.component';
import { EllipseTooltipComponent } from './ellipse-tooltip/ellipse-tooltip.component';
import { CopyWorkspaceComponent } from './modals/copy-workspace/copy-workspace.component';
import { IconButtonComponent } from './icon-button/icon-button.component';
import { ExpansionButtonComponent } from './expansion-button/expansion-button.component';
// import { NavFormsComponent } from './nav-forms/nav-forms.component';
import { NodeChecklistEditPopoverComponent } from './node-checklist-edit-popover/node-checklist-edit-popover.component';
import { NodeChecklistEditComponent } from './node-checklist-edit-popover/node-checklist-edit/node-checklist-edit.component';
import { LeafWidgetListComponent } from './leaf-widget-list/leaf-widget-list.component';
import { WidgetFieldComponent } from './leaf-widget-list/widget-field/widget-field.component';
import { LeafWidgetItemComponent } from './leaf-widget-list/leaf-widget-item/leaf-widget-item.component';
import { WidgetInfoListComponent } from './widget-info-list/widget-info-list.component';
import { WidgetInfoItemComponent } from './widget-info-item/widget-info-item.component';
import { TextFieldModule } from '@angular/cdk/text-field';
import { NgxCurrencyModule } from 'ngx-currency';
import { PopoverSubsectionRowComponent } from './popover-subsection-row/popover-subsection-row.component';
import { InfoCheckboxComponent } from './info-checkbox/info-checkbox.component';
import { SecuredLayoutComponent } from './_layout/secured-layout/secured-layout.component';
import { SecuredHeaderComponent } from './_layout/secured-header/secured-header.component';
import { BtnRemoveComponent } from './btn-remove/btn-remove.component';
import { BtnMenuComponent } from './btn-menu/btn-menu.component';
import { ContentSectionComponent } from './content-section/content-section.component';
import { BtnCheckComponent } from './btn-check/btn-check.component';
import { NewTemplateComponent } from './modals/new-template/new-template.component';
import { LinkListFieldComponent } from './link-list-field/link-list-field.component';
import { LinkIconComponent } from './link-icon/link-icon.component';
import { LinkItemComponent } from './link-item/link-item.component';
import { SaveStatusComponent } from './save-status/save-status.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { ExpansionSectionComponent } from './expansion-section/expansion-section.component';
import { CardLayoutOptionsComponent } from './card-layout-options/card-layout-options.component';
import { EditStatusComponent } from './edit-status/edit-status.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AvatarUploaderComponent } from './avatar-uploader/avatar-uploader.component';
import { NodeAvatarUploadModalComponent } from './node-avatar-upload-modal/node-avatar-upload-modal.component';
import { NodeAvatarMenuComponent } from './node-avatar-menu/node-avatar-menu.component';
import { LinkGroupViewComponent } from './link-group-view/link-group-view.component';
import { BtnFaComponent } from './btn-fa/btn-fa.component';
import { RichSearchComponent } from './rich-search/rich-search.component';
import { RichSearchFilterComponent } from './rich-search/rich-search-filter/rich-search-filter.component';
import { RichSelectMenuComponent } from './rich-select-menu/rich-select-menu.component';
import { NavTimesheetComponent } from './nav-timesheet/nav-timesheet.component';
import { CardNoSetupComponent } from './overlay-no-setup/card-no-setup.component';
import { OverlayNoSetupDescriptionComponent } from './overlay-no-setup-description/overlay-no-setup-description.component';
import { ContentLoaderModule } from './content-loader/content-loader.module';
import { FaIconComponent } from './fa-icon/fa-icon.component';
import { SquareButtonAddComponent } from './square-button-add/square-button-add.component';
import { SelectableElementOptionComponent } from './selectable-element-option/selectable-element-option.component';
import { RateEditPopoverComponent } from './rate-edit-popover/rate-edit-popover.component';
import { RateEditComponent } from './rate-edit-popover/rate-edit/rate-edit.component';
import { SelectableLayoutOptionComponent } from './selectable-layout-option/selectable-layout-option.component';
import { OptionInfoItemComponent } from './selectable-layout-option/option-info-item/option-info-item.component';
import { HeroDropdownComponent } from './hero-dropdown/hero-dropdown.component';
import { BtnViewComponent } from './btn-view/btn-view.component';
import { VerticalBarComponent } from './vertical-bar/vertical-bar.component';
import { CurvedCornerComponent } from './curved-corner/curved-corner.component';
import { NavChatComponent } from './nav-chat/nav-chat.component';
import { ActiveIndicatorComponent } from './active-indicator/active-indicator.component';
import { FileAttachmentComponent } from './file-attachment/file-attachment.component';
import { ImageModalComponent } from './modals/image-modal/image-modal.component';
import { WorkspaceLayoutContentComponent } from './workspace-layout-content/workspace-layout-content.component';
import { TableCellContentComponent } from './table-cell-content/table-cell-content.component';
import { SidebarModule } from './_layout/sidebar/sidebar.module';
import { DirectivesModule } from '../directives/_directives.module';
import { PipesModule } from '../pipes/_pipes.module';
import { ModalModule } from './_layout/modal/modal.module';
import { WidgetChoicesEditorComponent } from './widget-choices-editor/widget-choices-editor.component';
import { CommentUserAvatarComponent } from './comments/comment-user-avatar/comment-user-avatar.component';

import { ContextButtonChatComponent } from './context-button-chat/context-button-chat.component';

import { InlineEditCurrencyComponent } from './inline-edit-currency/inline-edit-currency.component';
import { InlineEditNumberComponent } from './inline-edit-number/inline-edit-number.component';
import { InlineEditTextboxComponent } from './inline-edit-textbox/inline-edit-textbox.component';
import { InlineEditInputLayoutComponent } from './inline-edit-input-layout/inline-edit-input-layout.component';
import { InlineEditTimeComponent } from './inline-edit-time/inline-edit-time.component';
import { AssignmentMenuComponent } from './assignment-menu/assignment-menu.component';
import { AssignmentLockComponent } from './assignment-lock/assignment-lock.component';
import { RichDropdownMenuHeroComponent } from './rich-dropdown-menu-hero/rich-dropdown-menu-hero.component';
import { DynamicElementsEditComponent } from './dynamic-elements-edit/dynamic-elements-edit.component';
import { LayoutBlockComponent } from './layout-block/layout-block.component';
import { SelectableInputComponent } from './selectable-input/selectable-input.component';
import { BtnRadioComponent } from './btn-radio/btn-radio.component';
import { AssignmentElementFieldsComponent } from './assignment-element-fields/assignment-element-fields.component';
import { NoDataComponent } from './no-data/no-data.component';
import { BtnCustomIconComponent } from './btn-custom-icon/btn-custom-icon.component';
import { TemplateGroupTagsComponent } from '../../features/node-templates-management/template-group-tags/template-group-tags.component';
import { TemplateTagGroupComponent } from '../../features/node-templates-management/template-group-tags/template-tag-group/template-tag-group.component';
import { AllowedTemplatesComponent } from '../../features/node-templates-management/allowed-templates/allowed-templates.component';
import { TemplateChecklistsComponent } from '../../features/node-templates-management/template-checklists/template-checklists.component';
import { TemplateChecklistComponent } from '../../features/node-templates-management/template-checklists/template-checklist/template-checklist.component';
import { TemplatePricingComponent } from '../../features/node-templates-management/template-pricing/template-pricing.component';
import { TemplateFiguresComponent } from '../../features/node-templates-management/template-figures/template-figures.component';
import { TemplateCalculationCreateComponent } from '../../features/node-templates-management/template-figures-popover/template-calculation-create/template-calculation-create.component';
import { TemplateCalculationWidgetEditComponent } from '../../features/node-templates-management/template-figures-popover/template-calculation-widget-edit/template-calculation-widget-edit.component';
import { TemplateCalculationComponent } from '../../features/node-templates-management/template-figures/template-calculation/template-calculation.component';
import { AssignmentPrintModalComponent } from './assignment-print-modal/assignment-print-modal.component';
import { ActiveLinkComponent } from './active-link/active-link.component';
import { CalendarRowComponent } from './calendar-row/calendar-row.component';
import { CalendarRowHeaderComponent } from './calendar-row-header/calendar-row-header.component';
import { AssignmentOverlayCardComponent } from './assignment-overlay-card/assignment-overlay-card.component';
import { AssignmentComponent } from './assignment/assignment.component';
import { AssignmentBodyComponent } from './assignment-body/assignment-body.component';
import { AssignmentHeaderComponent } from './assignment-header/assignment-header.component';
import { AssignmentPrimaryTagComponent } from './assignment-primary-tag/assignment-primary-tag.component';
import { AssignmentCardAddComponent } from './assignment-card-add/assignment-card-add.component';
import { LayoutMetricItemComponent } from './layout-metric-item/layout-metric-item.component';
import { AssignmentFooterComponent } from './assignment-footer/assignment-footer.component';
import { AssignmentRowAnnouncementComponent } from './assignment-row-announcement/assignment-row-announcement.component';
import { AssignmentFieldTextComponent } from './assignment-field-text/assignment-field-text.component';
import { AssignmentAdditionalFieldsComponent } from './assignment-additional-fields/assignment-additional-fields.component';
import { AssignmentAssetFieldsComponent } from './assignment-asset-fields/assignment-asset-fields.component';
import { AssignmentAssetComponent } from './assignment-asset/assignment-asset.component';
import { AssignmentSectionComponent } from './assignment-section/assignment-section.component';
import { AssignmentProgressComponent } from './assignment-progress/assignment-progress.component';
import { AssignmentMenuRowComponent } from './assignment-menu-row/assignment-menu-row.component';
import { AssignmentHeaderFieldComponent } from './assignment-header-field/assignment-header-field.component';
import { FieldCarouselComponent } from './field-carousel/field-carousel.component';
import { AssignmentFieldComponent } from './assignment-field/assignment-field.component';
import { AssignmentRowNotesComponent } from './assignment-row-notes/assignment-row-notes.component';
import { ToCardLayoutComponent } from './template-options/to-card-layout/to-card-layout.component';
import { ToZAppsComponent } from './template-options/to-z-apps/to-z-apps.component';
import { ToLibraryDetailsComponent } from './template-options/to-library-details/to-library-details.component';
import { TemplateGanttChartComponent } from '../../features/node-templates-management/template-gantt-chart/template-gantt-chart.component';
import { TemplateDescriptionComponent } from './template-description/template-description.component';
import { TemplateThumbnailComponent } from './template-thumbnail/template-thumbnail.component';
import { DespatchResourcesComponent } from './despatch-resources/despatch-resources.component';

@NgModule({
  declarations: [
    AlertComponent,
    PaginatorComponent,
    FileUploaderComponent,
    FileUploaderThumbComponent,
    FileLazyLoaderComponent,
    ControlValidationComponent,
    FormValidationComponent,
    FriendInviteModalComponent,
    PrivacyPolicyModalComponent,
    TermsServiceModalComponent,
    RoundCheckboxComponent,
    SelectableInputComponent,
    IconRadioButtonComponent,
    SearchBoxComponent,
    ColorPickerComponent,
    ColorDirective,
    ColorPickerTriggerDirective,
    IconPickerComponent,
    IconDirective,
    IconPickerTriggerDirective,
    OverlayMultiSelectComponent,
    OverlaySingleSelectComponent,
    DateRangePickerComponent,
    ContextButtonEditComponent,
    MultiSelectComponent,
    MultiSelectOptionComponent,
    OverlayTextareaComponent,
    OverlayPopoverComponent,
    PopoverLayoutComponent,
    SelectableBrickComponent,
    PanelSubsectionComponent,
    PopoverSubsectionComponent,
    PopoverDropdownComponent,
    PopoverDropdownComponent,
    BrickComponent,
    IconCheckboxButtonComponent,
    AddressFieldComponent,
    HeaderTitleComponent,
    WorkspaceUsersComponent,
    WorkspaceUserComponent,
    NodeTypeIconComponent,
    PanelToggleComponent,
    SquareButtonAddComponent,
    BtnEditComponent,
    NodeAvatarComponent,
    WorkspaceSwitchComponent,
    SharedLinkComponent,
    // IconStampComponent,
    // NavStampComponent, // no usage of this component
    ApplyTemplateComponent,
    SelectStampComponent,
    BtnTooltipComponent,
    IconInfoComponent,
    ApplyTagsComponent,
    StickyPanelHeaderComponent,
    AccountModalComponent,
    AssignmentPrintModalComponent,
    NodeAvatarEditableComponent,
    AvatarUploadModalComponent,
    ProfilePhotoMenuComponent,
    // NavFinanceComponent, // no usage of this component
    RichDropdownComponent,
    RichDropdownMenuComponent,
    RichDropdownMenuHeroComponent,
    ImageListboxComponent,
    GoogleAddressFieldComponent,
    TruncateTextComponent,
    EllipseTooltipComponent,
    CopyWorkspaceComponent,
    IconButtonComponent,
    ExpansionButtonComponent,
    // NavFormsComponent, // no usage of this component
    NodeChecklistEditPopoverComponent,
    NodeChecklistEditComponent,
    LeafWidgetListComponent,
    LeafWidgetItemComponent,
    WidgetFieldComponent,
    WidgetInfoListComponent,
    WidgetInfoItemComponent,
    PopoverSubsectionRowComponent,
    InfoCheckboxComponent,
    SecuredLayoutComponent,
    SecuredHeaderComponent,
    BtnRemoveComponent,
    BtnMenuComponent,
    ContentSectionComponent,
    BtnCheckComponent,
    BtnRadioComponent,
    NewTemplateComponent,
    CommentUserAvatarComponent,
    LinkListFieldComponent,
    LinkIconComponent,
    LinkItemComponent,
    SaveStatusComponent,
    ExpansionSectionComponent,
    CardLayoutOptionsComponent,
    SharedLinkComponent,
    EditStatusComponent,
    AvatarUploaderComponent,
    NodeAvatarUploadModalComponent,
    NodeAvatarMenuComponent,
    LinkGroupViewComponent,
    BtnFaComponent,
    RichSearchComponent,
    RichSearchFilterComponent,
    RichSelectMenuComponent,
    NavTimesheetComponent,
    CardNoSetupComponent,
    OverlayNoSetupDescriptionComponent,
    FaIconComponent,
    SelectableElementOptionComponent,
    RateEditPopoverComponent,
    RateEditComponent,
    SelectableLayoutOptionComponent,
    OptionInfoItemComponent,
    HeroDropdownComponent,
    BtnViewComponent,
    BtnCustomIconComponent,
    VerticalBarComponent,
    CurvedCornerComponent,
    NavChatComponent,
    ActiveIndicatorComponent,
    FileAttachmentComponent,
    ImageModalComponent,
    WorkspaceLayoutContentComponent,
    TableCellContentComponent,
    WidgetChoicesEditorComponent,
    ContextButtonChatComponent,
    AssignmentMenuComponent,
    AssignmentLockComponent,
    InlineEditInputLayoutComponent,
    InlineEditNumberComponent,
    InlineEditCurrencyComponent,
    InlineEditTextboxComponent,
    InlineEditTimeComponent,
    DynamicElementsEditComponent,
    LayoutBlockComponent,
    AssignmentElementFieldsComponent,
    NoDataComponent,
    TemplateGroupTagsComponent,
    TemplateTagGroupComponent,
    AllowedTemplatesComponent,
    TemplateChecklistsComponent,
    TemplateChecklistComponent,
    TemplatePricingComponent,
    TemplateFiguresComponent,
    TemplateCalculationComponent,
    TemplateCalculationCreateComponent,
    TemplateCalculationWidgetEditComponent,
    ActiveLinkComponent,
    CalendarRowComponent,
    CalendarRowHeaderComponent,
    AssignmentOverlayCardComponent,
    AssignmentComponent,
    AssignmentBodyComponent,
    AssignmentPrimaryTagComponent,
    AssignmentCardAddComponent,
    LayoutMetricItemComponent,
    AssignmentFooterComponent,
    AssignmentRowAnnouncementComponent,
    AssignmentFieldTextComponent,
    AssignmentAdditionalFieldsComponent,
    AssignmentAssetComponent,
    AssignmentAssetFieldsComponent,
    AssignmentSectionComponent,
    AssignmentProgressComponent,
    AssignmentMenuRowComponent,
    AssignmentHeaderComponent,
    AssignmentHeaderFieldComponent,
    FieldCarouselComponent,
    AssignmentFieldComponent,
    AssignmentRowNotesComponent,
    ToCardLayoutComponent,
    ToZAppsComponent,
    ToLibraryDetailsComponent,
    TemplateGanttChartComponent,
    TemplateDescriptionComponent,
    TemplateThumbnailComponent,
    DespatchResourcesComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    OverlayModule,
    A11yModule,
    BidiModule,
    CdkTreeModule,
    ClipboardModule,
    ScrollingModule,
    ExperimentalScrollingModule,
    DragDropModule,
    PortalModule,
    NgSelectModule,
    TextFieldModule, // for cdkTextareaAutosize
    NgxCurrencyModule,
    SidebarModule,
    ModalModule,
    ContentLoaderModule,
    ImageCropperModule,
    DirectivesModule,
    PipesModule,
    NgbAccordionModule,
  ],
  exports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    OverlayModule,
    A11yModule,
    BidiModule,
    CdkTreeModule,
    ClipboardModule,
    ScrollingModule,
    ExperimentalScrollingModule,
    DragDropModule,
    PortalModule,
    NgSelectModule,
    NgxCurrencyModule,
    ImageCropperModule,
    SidebarModule,
    ModalModule,
    ContentLoaderModule,
    AlertComponent,
    PaginatorComponent,
    FileUploaderComponent,
    FileUploaderThumbComponent,
    FileLazyLoaderComponent,
    ControlValidationComponent,
    FormValidationComponent,
    FriendInviteModalComponent,
    PrivacyPolicyModalComponent,
    TermsServiceModalComponent,
    RoundCheckboxComponent,
    SelectableInputComponent,
    IconRadioButtonComponent,
    SearchBoxComponent,
    ColorPickerComponent,
    ColorDirective,
    ColorPickerTriggerDirective,
    IconPickerComponent,
    IconDirective,
    IconPickerTriggerDirective,
    OverlayMultiSelectComponent,
    OverlaySingleSelectComponent,
    DateRangePickerComponent,
    MultiSelectComponent,
    OverlayPopoverComponent,
    PopoverLayoutComponent,
    PanelSubsectionComponent,
    PopoverSubsectionComponent,
    SelectableBrickComponent,
    PopoverDropdownComponent,
    BrickComponent,
    IconCheckboxButtonComponent,
    AddressFieldComponent,
    HeaderTitleComponent,
    NodeTypeIconComponent,
    PanelToggleComponent,
    NodeAvatarComponent,
    WorkspaceSwitchComponent,
    // NavStampComponent, // no usage of this component
    BtnEditComponent,
    // IconStampComponent,
    BtnTooltipComponent,
    IconInfoComponent,
    ApplyTagsComponent,
    StickyPanelHeaderComponent,
    AccountModalComponent,
    AssignmentPrintModalComponent,
    NodeAvatarEditableComponent,
    AvatarUploadModalComponent,
    ProfilePhotoMenuComponent,
    // NavFinanceComponent, // no usage of this component
    RichDropdownComponent,
    RichDropdownMenuComponent,
    RichDropdownMenuHeroComponent,
    ImageListboxComponent,
    GoogleAddressFieldComponent,
    TruncateTextComponent,
    EllipseTooltipComponent,
    CopyWorkspaceComponent,
    IconButtonComponent,
    ExpansionButtonComponent,
    // NavFormsComponent, // no usage of this component
    WorkspaceUserComponent,
    NodeChecklistEditPopoverComponent,
    NodeChecklistEditComponent,
    LeafWidgetListComponent,
    LeafWidgetItemComponent,
    WidgetFieldComponent,
    WidgetInfoListComponent,
    WidgetInfoItemComponent,
    PopoverSubsectionRowComponent,
    InfoCheckboxComponent,
    OverlayTextareaComponent,
    SecuredLayoutComponent,
    SecuredHeaderComponent,
    BtnRemoveComponent,
    SquareButtonAddComponent,
    BtnMenuComponent,
    ContentSectionComponent,
    BtnCheckComponent,
    BtnRadioComponent,
    NewTemplateComponent,
    LinkListFieldComponent,
    LinkIconComponent,
    LinkItemComponent,
    SaveStatusComponent,
    ExpansionSectionComponent,
    CardLayoutOptionsComponent,
    SharedLinkComponent,
    EditStatusComponent,
    AvatarUploaderComponent,
    NodeAvatarUploadModalComponent,
    NodeAvatarMenuComponent,
    LinkGroupViewComponent,
    BtnFaComponent,
    RichSearchComponent,
    RichSearchFilterComponent,
    RichSelectMenuComponent,
    NavTimesheetComponent,
    CardNoSetupComponent,
    OverlayNoSetupDescriptionComponent,
    FaIconComponent,
    CommentUserAvatarComponent,
    SelectableElementOptionComponent,
    RateEditPopoverComponent,
    RateEditComponent,
    SelectableLayoutOptionComponent,
    OptionInfoItemComponent,
    HeroDropdownComponent,
    BtnViewComponent,
    BtnCustomIconComponent,
    VerticalBarComponent,
    CurvedCornerComponent,
    NavChatComponent,
    ActiveIndicatorComponent,
    FileAttachmentComponent,
    ImageModalComponent,
    WorkspaceLayoutContentComponent,
    TableCellContentComponent,
    WidgetChoicesEditorComponent,
    ContextButtonChatComponent,
    AssignmentMenuComponent,
    AssignmentLockComponent,
    InlineEditInputLayoutComponent,
    InlineEditNumberComponent,
    InlineEditCurrencyComponent,
    InlineEditTextboxComponent,
    InlineEditTimeComponent,
    DynamicElementsEditComponent,
    LayoutBlockComponent,
    AssignmentElementFieldsComponent,
    NoDataComponent,
    TemplateGroupTagsComponent,
    TemplateTagGroupComponent,
    AllowedTemplatesComponent,
    TemplateChecklistsComponent,
    TemplateChecklistComponent,
    TemplatePricingComponent,
    TemplateFiguresComponent,
    TemplateCalculationComponent,
    TemplateCalculationCreateComponent,
    TemplateCalculationWidgetEditComponent,
    ActiveLinkComponent,
    DespatchResourcesComponent,
    CalendarRowComponent,
    CalendarRowHeaderComponent,
    AssignmentOverlayCardComponent,
    AssignmentComponent,
    AssignmentHeaderComponent,
    AssignmentBodyComponent,
    AssignmentPrimaryTagComponent,
    AssignmentCardAddComponent,
    LayoutMetricItemComponent,
    AssignmentFooterComponent,
    AssignmentRowAnnouncementComponent,
    AssignmentFieldTextComponent,
    AssignmentAdditionalFieldsComponent,
    AssignmentAssetComponent,
    AssignmentAssetFieldsComponent,
    AssignmentSectionComponent,
    AssignmentProgressComponent,
    AssignmentMenuRowComponent,
    AssignmentHeaderFieldComponent,
    FieldCarouselComponent,
    AssignmentFieldComponent,
    AssignmentRowNotesComponent,
    ToCardLayoutComponent,
    ToZAppsComponent,
    ToLibraryDetailsComponent,
    TemplateGanttChartComponent,
    TemplateDescriptionComponent,
    TemplateThumbnailComponent,
  ],
})
export class ComponentsModule {}
