import { Component, OnInit, TemplateRef } from '@angular/core';
import { OverlayPopoverRef, PopoverContent } from './overlay-popover-ref';

@Component({
  templateUrl: './overlay-popover.component.html',
  styleUrls: ['./overlay-popover.component.scss'],
})
export class OverlayPopoverComponent implements OnInit {
  renderMethod: 'template' | 'component' | 'text' = 'component';
  content: PopoverContent;
  context;

  constructor(private popoverRef: OverlayPopoverRef) {}

  ngOnInit(): void {
    this.content = this.popoverRef.content;

    if (typeof this.content === 'string') {
      this.renderMethod = 'text';
    }

    if (this.content instanceof TemplateRef) {
      this.renderMethod = 'template';
      this.context = {
        close: this.popoverRef.close.bind(this.popoverRef),
      };
    }
  }
}
