import { Component, Input, OnInit } from '@angular/core';
import { NodeListPanelActions } from '../../../../store/node-list-panel';
import { AppState } from '../../../../store';
import { Store } from '@ngrx/store';
import { NodeModel } from '../../../../core/models/node.model';

@Component({
  selector: 'app-sidebar-section',
  templateUrl: './sidebar-section.component.html',
  styleUrls: ['./sidebar-section.component.scss'],
})
export class SidebarSectionComponent implements OnInit {
  @Input()
  rootFolder: NodeModel;

  @Input()
  isCollapsed: boolean;

  constructor(private store: Store<AppState>) {
    this.isCollapsed = false;
  }

  ngOnInit() {}

  openNodeListPanel() {
    this.store.dispatch(
      NodeListPanelActions.openNodeListPanel({
        node: this.rootFolder,
      }),
    );
  }
}
