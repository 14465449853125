<ul class="popover-menu">
  <li class="popover-menu__info-title popover-menu__info-title--primary">
    {{ node.nodeType | nodeTypeLabel }} Options
  </li>
  <li class="popover-menu__item" (click)="editProperties()"><i class="fal fa-edit layout-mr-lg popover_icon_style"></i>Edit Properties</li>
  <li class="popover-menu__item" (click)="openTagsPopover()"><i class="fal fa-tag layout-mr-lg popover_icon_style"></i>Edit Tags</li>
  <li class="popover-menu__item" (click)="moveFolder()"><i class="fal fa-up-down-left-right layout-mr-lg popover_icon_style"></i>Move to Folder</li>
  <ng-container *ngIf="node?.nodeType === NodeType.asset">
    <li class="popover-menu__item" (click)="copyAsset()"><i class="fal fa-copy layout-mr-lg popover_icon_style"></i>Copy Asset</li>
  </ng-container>
  <ng-container *ngIf="node?.nodeType === NodeType.element">
    <li class="popover-menu__item" (click)="copyElement()"><i class="fal fa-copy layout-mr-lg popover_icon_style"></i>Copy Element</li>
  </ng-container>

  <ng-container *ngIf="node.nodeType === NodeType.project || node.nodeType === NodeType.asset">
    <li class="popover-menu__item" (click)="openChat()"><i class="fal fa-comment layout-mr-lg popover_icon_style"></i>Open chat</li>
    <li class="popover-menu__item" (click)="showRates()"><i class="fal fa-dollar layout-mr-lg popover_icon_style"></i>View pricing</li>
  </ng-container>

  <li class="popover-menu__divider"></li>
  <li class="popover-menu__item" (click)="archiveElement()"><i class="fal fa-archive layout-mr-lg popover_icon_style"></i>Archive</li>
<!--   Comment out Change Template from Dropdown
  <li class="popover-menu__divider"></li>
  <li class="popover-menu__info-title popover-menu__info-title&#45;&#45;primary">
    Change Template
  </li>
  <app-rich-dropdown-menu
    [selectedOptionId]="node.nodeTemplate.id"
    [options]="templateOptions"
    [hasIndention]="true"
    (changeOptionModel)="changeTemplate($event)"
  ></app-rich-dropdown-menu>-->
</ul>
