<div class="d-flex justify-content-center">
  <div class="custom-control custom-checkbox">
    <!-- Added hidden to hide toggle-->
    <input
      class="custom-control-input"
      type="checkbox"
      id="nodeListShowTags"
      [checked]="tagsViewMode === 'show'"
      (change)="changeTagsViewMode(tagsViewMode)"
    />
    <label class="custom-control-label small pt-1 layout-mr-lg" for="nodeListShowTags">
      Show Tags
    </label>
  </div>

  <div class="custom-control custom-checkbox">
    <!-- Added hidden to hide toggle-->
    <input
      class="custom-control-input"
      type="checkbox"
      id="nodeListShowWidgets"
      [checked]="widgetsViewMode === 'show'"
      (change)="changeWidgetsViewMode(widgetsViewMode)"
    >
    <label class="custom-control-label small pt-1" for="nodeListShowWidgets">
      Widgets
    </label>
  </div>

  <div hidden="hidden" class="custom-control custom-checkbox">
    <!-- Added hidden to hide toggle-->
    <input
      class="custom-control-input"
      type="checkbox"
      id="nodeListShowLayouts"
      [checked]="layoutTemplateViewMode === 'show'"
      (change)="changeLayoutTemplateViewMode(layoutTemplateViewMode)"
    >
    <label class="custom-control-label small pt-1" for="nodeListShowLayouts">
      Layouts
    </label>
  </div>

</div>
