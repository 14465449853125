import { Pipe, PipeTransform } from '@angular/core';
import { ColorType } from '../../core/constants/color-type';

@Pipe({
  name: 'colorString',
})
export class ColorStringPipe implements PipeTransform {
  transform(value: ColorType | ''): string {
    return value ? ColorType[value] : ColorType[ColorType['not-available']];
  }
}
