<app-panel-subsection
  class="template-edit__subsection"
  title="Gantt Chart"
  [titleTooltip]="'Gantt Chart.'"
  [backgroundTransparent]="true"
  [headerRightTemplate]="figuresOnOffLayout"
>
</app-panel-subsection>

<ng-template #figuresOnOffLayout>
  <div
    role="button"
    class="layout-switch-btn"
    (click)="changeSwitch()"
    [ngClass]="{
      'layout-switch-btn-on': false,
      'layout-switch-btn-off': true
    }"
  >
    <span>{{ false ? 'ON' : 'OFF' }}</span>
  </div>
</ng-template>
