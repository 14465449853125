<ng-template #modalFooter>
  <button ngbAutofocus type="button" class="btn btn-sm btn-outline-light" (click)="activeModal.dismiss()">
    Cancel
  </button>

  <button type="button" class="btn btn-sm btn-primary" (click)="onClose()">
    Select
  </button>
</ng-template>

<app-modal title="Icon Picker" [footerTemplate]="modalFooter">
  <div class="row">
    <div class=" offset-5 col-7 offset-md-8 col-md-4">
      <app-search-box
        [searchKeyword]="keywords"
        sizeType="sm"
        (searchChanged)="search($event)"
      ></app-search-box>
    </div>
  </div>

  <div class="icon-picker">
    <div
      *ngFor="let icon of icons"
      class="icon"
      [title]="icon"
      [ngClass]="['icon--' + icon, icon === selected ? 'icon--selected' : '']"
      (click)="onChange(icon)"
    >
      <i class="fal" [ngClass]="icon ? 'fa-' + icon : ''"></i>
    </div>
  </div>
</app-modal>
