import * as moment from 'moment';
import { DATE_KEY_FORMAT } from '../constants/date-format.constants';
import { DateRangeModel } from '../models/date-range.model';
import { dateKeyCompareFn } from './date.util';

export const dateRangeUtil = {
  defaultStartDate(): string {
    return moment()
      .startOf('days')
      .format(DATE_KEY_FORMAT);
  },
  defaultEndDate(): string {
    return moment()
      .add(7, 'day')
      .endOf('days')
      .format(DATE_KEY_FORMAT);
  },
  default(): DateRangeModel {
    const start = this.defaultStartDate();
    const end = this.defaultEndDate();
    return {
      start,
      end,
    };
  },

  transformToDateRange(dateA: string, dateB: string): DateRangeModel {
    const timestampA = moment(dateA).unix();
    const timestampB = moment(dateB).unix();
    return timestampA > timestampB
      ? {
          start: dateB,
          end: dateA,
        }
      : {
          start: dateA,
          end: dateB,
        };
  },
  isValidDateRange(dateRange: DateRangeModel) {
    if (!dateRange) {
      return false;
    }
    const startDate = moment(dateRange.start);
    const endDate = moment(dateRange.end);

    return startDate.isValid() && endDate.isValid();
  },

  isDateInRange(date: string, dateRange: DateRangeModel) {
    const dateMoment = moment(date);
    if (dateRange) {
      return (
        dateMoment.isSameOrAfter(dateRange.start, 'days') &&
        dateMoment.isSameOrBefore(dateRange.end, 'days')
      );
    } else {
      return false;
    }
  },
  hasOverlay(dateRangeA: DateRangeModel, dateRangeB: DateRangeModel) {
    if (dateRangeA && dateRangeB) {
      // if one of the start dates is in range of the other dateRange, it has overlap
      return (
        this.isDateInRange(dateRangeA.start, dateRangeB) ||
        this.isDateInRange(dateRangeB.start, dateRangeA)
      );
    } else {
      return false;
    }
  },

  getMinFourWeeksDateRange(dateRange: DateRangeModel): DateRangeModel {
    if (dateRange) {
      if (this.isDateRangeMinFourWeeks(dateRange)) {
        return dateRange;
      } else {
        let endDate = moment(dateRange.start).add(28, 'days');
        return {
          start: dateRange.start,
          end: endDate.format(DATE_KEY_FORMAT),
        };
      }
    } else {
      return this.getDefaultFourWeeksDateRange();
    }
  },

  isDateRangeMinFourWeeks(dateRange: DateRangeModel): boolean {
    let isMinFourWeeks = false;
    if (dateRange) {
      const startDate = moment(dateRange.start);
      let endDate = moment(dateRange.end);
      const duration = moment.duration(endDate.diff(startDate)).get('days');
      if (duration >= 28) {
        isMinFourWeeks = true;
      }
    }
    return isMinFourWeeks;
  },

  getDefaultFourWeeksDateRange(): DateRangeModel {
    const start = moment().format(DATE_KEY_FORMAT);
    const end = moment()
      .add(28, 'days')
      .format(DATE_KEY_FORMAT);
    return {
      start,
      end,
    };
  },
  getDurationDays(startDate: string, endDate: string) {
    if (!startDate || !endDate) {
      return 0;
    }
    const diff = moment(endDate).diff(startDate, 'days', false);
    if (diff >= 0) {
      return diff + 1;
    } else {
      return 0;
    }
  },

  getDateRangeOfDateKeys(byDateKeys: { [dateKey: string]: any }): DateRangeModel {
    let dateRange: DateRangeModel = {};

    Object.keys(byDateKeys || {})
      .filter(dateKey => byDateKeys[dateKey])
      .forEach(dateKey => {
        if (dateRange.start) {
          const compareResult = dateKeyCompareFn(dateRange.start, dateKey);
          if (compareResult > 0) {
            dateRange.start = dateKey;
          }
        } else {
          dateRange.start = dateKey;
        }

        if (dateRange.end) {
          const compareResult = dateKeyCompareFn(dateRange.end, dateKey);
          if (compareResult < 0) {
            dateRange.end = dateKey;
          }
        } else {
          dateRange.end = dateKey;
        }
      });

    return dateRange;
  },

  getDateRangeWithNewStartDate(newStartDate: string, existingEndDate: string): DateRangeModel {
    const startMoment = moment(newStartDate);
    let existingEndMoment = moment(existingEndDate);
    if (startMoment.isSameOrBefore(existingEndMoment)) {
      return {
        start: newStartDate,
        end: existingEndDate,
      };
    } else {
      return {
        start: newStartDate,
        end: newStartDate,
      };
    }
  },

  getDateRangeWithNewEndDate(newEndDate: string, existingStartDate: string): DateRangeModel {
    const endMoment = moment(newEndDate);
    let existingStartMoment = moment(existingStartDate);
    if (endMoment.isSameOrAfter(existingStartMoment)) {
      return {
        start: existingStartDate,
        end: newEndDate,
      };
    } else {
      return {
        start: newEndDate,
        end: newEndDate,
      };
    }
  },
};
