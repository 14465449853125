<app-modal [title]="modalTitle" [iconUrl]="iconUrl">
  <app-modal-instructions
    [html]="instructions"
    [template]="instructionsTemplate"
  ></app-modal-instructions>

  <div class="row container-c">
    <div class="col-4 left-col p-0">
      <div class="shared-links">
        <h2 class="shared-links--title">View Shared via a private link</h2>
        <p class="shared-links--description">
          People with the private link can only see the filtered data.
        </p>
        <div class="shared-links--list">
          <app-shared-link
            *ngFor="let link of sharedLinks; trackBy: trackBy"
            [sharedLink]="link"
            [selectedLink]="selectedLink"
            (selectEvent)="selectLink(link)"
            (removeEvent)="removeLink(link)"
            #sharedLinkElement
            [attr.data-shared-link]="link.hash"
          ></app-shared-link>
          <ng-container *ngIf="!sharedLinks.length">
            <app-no-data [type]="noDataTypeIds.shareLinks"></app-no-data>
          </ng-container>
        </div>
      </div>

      <div class="shared-links--share-button">
        <app-btn-fa iconName="share-alt" (click)="share($event)">Share current view</app-btn-fa>
      </div>
    </div>

    <div class="col center-col p-0">
      <ng-container *ngIf="selectedLink; else noTemplate">
        <app-active-link [selectedLink]="selectedLink"></app-active-link>
      </ng-container>

      <ng-template #noTemplate>
        <app-no-data [type]="noDataTypeIds.selectedShareLink"></app-no-data>
      </ng-template>
    </div>

    <div class="col-3 right-col p-0" *ngIf="selectedLink?.metaData?.title">
      <app-despatch-resources [selectedLink]="selectedLink"></app-despatch-resources>
    </div>
  </div>
</app-modal>
