import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NodeModel } from '../../../../core/models/node.model';
import { WorkspaceUserModel } from '../../../../core/models/workspace-user.model';
import { PermissionType } from '../../../../core/constants/permission-type';
import { Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { selectLoggedInWorkspaceUser } from '../../../../store/workspace-users/workspace-users.selectors';
import { selectChatActiveChannelNodeIdSet } from '../../../../store/chats/chats.selectors';
import { NodeUtils } from '../../../../core/utils/node.util';
import { defaultPlacements } from '../../../../core/constants/placements';

@Component({
  selector: 'app-context-buttons',
  templateUrl: './context-buttons.component.html',
  styleUrls: ['./context-buttons.component.scss'],
})
export class ContextButtonsComponent implements OnInit, OnDestroy {
  private readonly subscription = new Subscription();
  public readonly NodeUtils = NodeUtils;
  public readonly PermissionType = PermissionType;
  public readonly defaultPlacements = defaultPlacements;

  @Input()
  node: NodeModel;

  chatActiveChannelNodeIdSet: Set<any>;

  loggedInUser: WorkspaceUserModel;

  constructor(private store: Store) {}

  ngOnInit() {
    this.subscription.add(
      this.store.pipe(select(selectLoggedInWorkspaceUser)).subscribe(loggedInUser => {
        this.loggedInUser = loggedInUser;
      }),
    );

    this.subscription.add(
      this.store
        .pipe(select(selectChatActiveChannelNodeIdSet))
        .subscribe(chatActiveChannelNodeIdSet => {
          this.chatActiveChannelNodeIdSet = chatActiveChannelNodeIdSet;
        }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
