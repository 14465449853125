import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { TableComponent } from '../../table.component';
import { TableRow, zTableProp } from '../../table.common';

@Component({
  selector: 'app-z-table-row-number',
  templateUrl: './table-row-number.component.html',
  styleUrls: ['./table-row-number.component.scss'],
})
export class TableRowNumberComponent {
  public readonly zTableProp = zTableProp;

  public readonly uuid;
  public readonly elementId;

  @ViewChild('input')
  public input: ElementRef;

  @Input()
  public index: number;

  @Input()
  public row: TableRow;

  @Input()
  public descending: boolean;

  constructor(public table: TableComponent) {
    this.uuid = Math.floor(Math.random() * (99999 - 11111 + 1)) + 11111;
    this.elementId = `zTableInput_${this.uuid}`;
  }
}
