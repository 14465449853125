import { Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ControlValidationService } from './control-validation.service';

@Component({
  selector: 'app-control-validation',
  templateUrl: 'control-validation.component.html',
})
export class ControlValidationComponent {
  @Input() control: UntypedFormControl | UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() inline?: boolean;

  constructor(private validationService: ControlValidationService) {}

  public get errorMessage() {
    if (!this.control) {
      console.error('CONTROL IS NULL: ', this.control);
      return null;
    }
    for (const propertyName in this.control.errors) {
      if (
        this.control.errors.hasOwnProperty(propertyName) &&
        (this.control.touched || this.submitted)
      ) {
        return ControlValidationService.getValidatorErrorMessage(
          propertyName,
          this.control.errors[propertyName],
        );
      }
    }
    return null;
  }
}

@Component({ selector: 'app-form-validation', templateUrl: 'form-validation.component.html' })
export class FormValidationComponent extends ControlValidationComponent {}
