import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../store';
import {
  selectContentPanelSelectedSearchItems,
  selectNodeListPanelIsReadonly,
  selectNodeListPanelIsRootNode,
  selectNodeListPanelNodesWithFilters,
  selectNodeListPanelRichSearchFilterItems,
  selectNodeListPanelRootNode,
  selectNodeListPanelSearchKeyword,
  selectNodeListPanelSelectedNode,
} from '../../../store/node-list-panel/node-list-panel.selectors';
import { NodeListPanelActions } from '../../../store/node-list-panel';
import { WorkspaceUserModel } from '../../../core/models/workspace-user.model';
import { PermissionType } from '../../../core/constants/permission-type';
import { selectLoggedInWorkspaceUser } from '../../../store/workspace-users/workspace-users.selectors';
import { RichSearchItemModel } from '../../../core/models/rich-search-item.model';
import { RichSearchType } from '../../../core/constants/rich-search-type';
import { NodeType } from '../../../core/constants/node-type';
import { NodeModel } from '../../../core/models/node.model';
import { NodeUtils } from '../../../core/utils/node.util';

@Component({
  selector: 'app-node-list',
  templateUrl: './node-list.component.html',
  styleUrls: ['./node-list.component.scss'],
})
export class NodeListComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  public readonly NodeType = NodeType;
  public readonly PermissionType = PermissionType;

  @ViewChild('searchBox', { static: true })
  public searchBox;

  public loggedInUser: WorkspaceUserModel;
  public selectedNode: NodeModel;

  public childNodes: NodeModel[];
  public childFolders: NodeModel[];

  public rootNode: NodeModel;
  public searchKeyword: string;

  public searchItems: RichSearchItemModel[];
  public selectedSearchItems: RichSearchItemModel[] = [];

  public isRootNode: boolean;
  public isReadonly: boolean;

  constructor(private store: Store<AppState>) {
    this.subscription.add(
      this.store.pipe(select(selectLoggedInWorkspaceUser)).subscribe(loggedInUser => {
        this.loggedInUser = loggedInUser;
      }),
    );

    this.subscription.add(
      this.store.pipe(select(selectNodeListPanelSelectedNode)).subscribe(node => {
        this.selectedNode = node;
      }),
    );

    this.subscription.add(
      this.store.pipe(select(selectNodeListPanelSearchKeyword)).subscribe(searchKeyword => {
        this.searchKeyword = searchKeyword;
      }),
    );

    this.subscription.add(
      this.store.pipe(select(selectNodeListPanelIsReadonly)).subscribe(isReadonly => {
        this.isReadonly = isReadonly;
      }),
    );

    this.subscription.add(
      this.store.pipe(select(selectNodeListPanelRichSearchFilterItems)).subscribe(searchItems => {
        this.searchItems = searchItems;
      }),
    );

    this.subscription.add(
      this.store
        .pipe(select(selectContentPanelSelectedSearchItems))
        .subscribe(selectedSearchItems => {
          this.selectedSearchItems = selectedSearchItems;
        }),
    );

    this.subscription.add(
      this.store.pipe(select(selectNodeListPanelRootNode)).subscribe(rootNode => {
        this.rootNode = rootNode;
      }),
    );

    this.subscription.add(
      this.store.pipe(select(selectNodeListPanelIsRootNode)).subscribe(isRootNode => {
        this.isRootNode = isRootNode;
      }),
    );

    this.childNodes = [];
    this.childFolders = [];
    this.subscription.add(
      this.store.pipe(select(selectNodeListPanelNodesWithFilters)).subscribe(nodes => {
        this.childNodes = [];
        this.childFolders = [];
        nodes.forEach(n => {
          if (NodeUtils.isFolder(n)) {
            this.childFolders.push(n);
          } else {
            this.childNodes.push(n);
          }
        });
      }),
    );
  }

  ngOnInit() {
    if (this.selectedNode && this.searchBox) {
      this.searchBox.setFocus();
    }
  }

  // public addLeaf() {
  //   this.store.dispatch(
  //     NodesActions.addNodeRequest({
  //       parentNode: this.selectedNode,
  //       nodeType: NodeUtils.getChildNodeType(this.selectedNode.nodeType),
  //     }),
  //   );
  // }

  public search(searchKeyword) {
    this.store.dispatch(NodeListPanelActions.updateSearchKeywordRequest({ searchKeyword }));
  }

  changeTagsFilter(ids: number[]) {
    this.store.dispatch(NodeListPanelActions.updateFilterTagIds({ ids }));
  }

  public richSearch(richSearchItems: RichSearchItemModel[]) {
    let filterTagIds = [];
    let filterTemplateIds = [];
    let filterLayoutTemplateIds = [];
    let filterUnassignedDateKeys = [];
    let searchKeyword = undefined;
    (richSearchItems || []).forEach(item => {
      if (item.type === RichSearchType.tag) {
        filterTagIds = [...filterTagIds, item.entity.id];
      } else if (item.type === RichSearchType.template) {
        filterTemplateIds = [...filterTemplateIds, item.entity.id];
      } else if (item.type === RichSearchType.layout) {
        filterLayoutTemplateIds = [...filterLayoutTemplateIds, item.entity.id];
      } else if (item.type === RichSearchType.unassignedDate) {
        filterUnassignedDateKeys = [item.entity.id]; // unassigned date key  is single selection
      } else if (item.type === RichSearchType.keyword) {
        searchKeyword = item.entity.id;
      }
    });
    this.store.dispatch(NodeListPanelActions.updateSearchKeywordRequest({ searchKeyword }));
    this.store.dispatch(NodeListPanelActions.updateFilterTagIds({ ids: filterTagIds }));
    this.store.dispatch(NodeListPanelActions.updateFilterTemplateIds({ ids: filterTemplateIds }));
    this.store.dispatch(
      NodeListPanelActions.updateFilterLayoutTemplateIds({ ids: filterLayoutTemplateIds }),
    );
    this.store.dispatch(
      NodeListPanelActions.updateFilterUnassignedDateKeys({ dateKeys: filterUnassignedDateKeys }),
    );
  }

  public switchToRootNode() {
    this.store.dispatch(
      NodeListPanelActions.openNodeListPanel({
        node: this.rootNode,
      }),
    );
  }

  trackBy(index, node: NodeModel) {
    return node.id;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
