import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../store';
import {NodeTemplateModel} from '../../../core/models/node-template.model';
import {NodeType} from '../../../core/constants/node-type';
import {TemplatesActions, NodeTemplatesSelectors} from '../../../store/templates';
import {AbstractPickerComponent, ListItem} from '../_abstract-picker/abstract-picker.component';
import {TagsActions, TagsSelectors} from '../../../store/tags';
import {TagsPopoverActions} from '../../../store/tags-popover';
import {TagModel} from '../../../core/models/tag.model';
import {NodeModel} from '../../../core/models/node.model';
import {NodesActions} from '../../../store/nodes';
import {NodeService} from '../../../core/services/node.service';
import {TagType} from '../../../core/constants/tag-type';

@Component({
  templateUrl: './layout-and-tag-picker.component.html',
  styleUrls: ['./layout-and-tag-picker.component.scss'],
})
export class LayoutAndTagPickerComponent
  extends AbstractPickerComponent<NodeTemplateModel | TagModel>
  implements OnInit, OnDestroy {
  // used in layout
  @Input()
  public types: NodeType[];

  @Input() assignment: NodeModel;

  // used in primary tags
  @Input()
  public allowEdit = false;
  // used in primary tags
  @Input()
  public allowNew = false;

  public currentStep = 1;
  public stepLength = 2;
  public modalTitle = 'Select Activity';

  constructor(
    public activeModal: NgbActiveModal,
    private store: Store<AppState>,
    private nodeService: NodeService,
  ) {
    super(activeModal);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.subscription.add(
      this.store
        .pipe(select(NodeTemplatesSelectors.selectNodeTemplatesByNodeTypeWithKeyword(this.types)))
        .subscribe(templates => {
          this.updateList(templates);
        }),
    );
    // this should be improved
    this.subscription.add(
      this.nodeService.getSelectedLayoutAssignment().subscribe((assignment: NodeModel) => {
        if (assignment?.primaryTagGroups[0] && this.assignment?.id === assignment?.id) {
          this.assignment = assignment;
          this.setPrimaryTagsStep();
        }
      }),
    );
  }

  public search(keywords: string) {
    this.keywords = keywords;
    if (this.currentStep === 1) {
      // when search remove the items from the whitelist
      // this.whitelist = this.keywords?.trim()
      //   ? []
      //   : this.assignment.reference.allowedTemplates || [];

      // layout
      this.store.dispatch(
        TemplatesActions.updateSearchKeywordRequest({
          searchKeyword: this.keywords,
        }),
      );
    } else if (this.currentStep === 2) {
      // primary tag
      this.store.dispatch(TagsActions.updateSearchKeywordRequest({searchKeyword: this.keywords}));
    }
  }

  public getRecommendedActivities() {
    return this.list?.filter(
      item =>
        this.assignment.reference.allowedTemplates.some(j =>
          this.isEqual(item?.model as NodeTemplateModel, j),
        ) == true,
    );
  }

  public getOtherActivities() {
    return this.list?.filter(
      item =>
        this.assignment.reference.allowedTemplates.some(j =>
          this.isEqual(item?.model as NodeTemplateModel, j),
        ) == false,
    );
  }

  public isEqual(itemA: NodeTemplateModel, itemB: NodeTemplateModel): boolean {
    return itemA.id === itemB.id;
  }

  public add() {
    throw new Error('Not implemented');
  }

  public edit(listItem: ListItem<NodeTemplateModel>) {
    throw new Error('Not implemented');
  }

  public trackBy(index, item: ListItem<NodeTemplateModel>) {
    return item.model.id;
  }

  public onSubmit() {
    if (this.verifySelection() === false) {
      return;
    }

    if (this.currentStep === 1) {
      this.store.dispatch(
        NodesActions.applyTemplateToAssignmentsRequest({
          nodeTemplateId: this.selected[0]?.id,
          nodeIds: [this.assignment?.id],
          setSelectedLayoutAssignment: true,
        }),
      );
    } else if (this.currentStep === 2) {
      this.store.dispatch(
        NodesActions.applyGroupTagToAssignmentRequest({
          assignmentId: this.assignment?.id,
          tagId: this.selected[0]?.id,
          tagType: TagType.primary,
          groupId: this.assignment?.primaryTagGroups[0]?.id,
        }),
      );
      this.activeModal.close();
    }
    this.keywords = '';
  }

  private setPrimaryTagsStep(): void {
    this.currentStep = 2;
    this.modalTitle = 'Select a tag';

    this.whitelist = (this.assignment?.nodeTemplate?.tags?.length
      ? this.assignment?.nodeTemplate?.tags?.filter(
        t => t.groupId === this.assignment?.primaryTagGroups[0]?.id,
      )
      : []) as any;
    this.minSelection = 1;
    this.maxSelection = 1;
    this.loadPrimaryTags();
  }

  private loadPrimaryTags(): void {
    this.subscription.add(
      this.store.pipe(select(TagsSelectors.selectAllTagsWithKeyword)).subscribe(tags => {
        this.updateList(tags);
      }),
    );
  }

  public ngOnDestroy(): void {
    // layout
    this.store.dispatch(TemplatesActions.updateSearchKeywordRequest({searchKeyword: ''}));
    // primary tags
    this.store.dispatch(TagsPopoverActions.resetTagEditPage());
    this.store.dispatch(TagsActions.updateSearchKeywordRequest({searchKeyword: ''}));
    super.ngOnDestroy();
  }
}
