import { createAction, props } from '@ngrx/store';
import { TagEditableProps, TagModel } from '../../core/models/tag.model';
import { ColorType } from '../../core/constants/color-type';
import { IconType } from '../../core/constants/icon-type';
import { TagType } from '../../core/constants/tag-type';

export const loadTagsRequest = createAction(
  '[Tags] load node-tags request',
  props<{ workspaceSlug: string }>(),
);

export const loadTagsSuccess = createAction(
  '[Tags] load node-tags success',
  props<{ tags: TagModel[] }>(),
);

export const addTagRequest = createAction(
  '[Tags] add tag request',
  props<{
    title: string;
    colorTheme: ColorType;
    icon: IconType;
    tagType?: TagType;
    searchTerms?: string;
    sortIndex?: number;
  }>(),
);

export const addTagSuccess = createAction('[Tags] add tag success', props<{ tag: TagModel }>());

export const updateTagRequest = createAction(
  '[Tags] update tag request',
  props<{ tagId: number; tagProps: Partial<TagEditableProps> }>(),
);

export const deleteTagRequest = createAction(
  '[Tags] delete tag request',
  props<{ tagId: number }>(),
);

export const sortTagsRequest = createAction(
  '[Tags] sort tags request',
  props<{ tagIds: number[] }>(),
);

export const updateSearchKeywordRequest = createAction(
  '[Tags] update search keyword request',
  props<{ searchKeyword: string }>(),
);
