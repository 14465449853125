<form [formGroup]="form">
  <ng-template #modalHeader>
    <h4 class="modal-title">
      {{ title }}
    </h4>
    <button
      *ngIf="!noCloseBtn"
      type="button"
      class="close"
      aria-label="Close"
      (click)="activeModal.dismiss(false)"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </ng-template>

  <ng-template #modalFooter>
    <button
      *ngIf="valueTitle && valueWidgetType"
      class="btn btn-sm btn-danger"
      type="button"
      (click)="delete()"
    >
      Delete
    </button>

    <button ngbAutofocus class="btn btn-sm btn-outline-light" type="button" (click)="cancel()">
      Cancel
    </button>

    <button
      class="btn btn-sm btn-success"
      type="button"
      (click)="save()"
      [disabled]="form?.invalid || form?.pristine"
    >
      Save
    </button>
  </ng-template>

  <app-modal [headerTemplate]="modalHeader" [footerTemplate]="modalFooter">
    <div class="confirm-message">
      <label class="popover-subsection__title">Title</label>
      <input
        #titleInput
        type="text"
        class="form-control"
        id="tc-title"
        placeholder="title"
        formControlName="title"
        appInputEmptyToNull
        [ngClass]="{
          'is-invalid': formControls?.title?.errors && formControls?.title?.touched
        }"
      />
      <div
        class="invalid-feedback d-block"
        *ngIf="formControls?.title?.errors?.required && formControls?.title?.touched"
      >
        *Please provide a title!
      </div>
      <div class="mb-3"></div>
    </div>
    <div class="confirm-message">
      <label class="popover-subsection__title">Widget type</label>
      <select
        class="form-control"
        id="tc-widget-type"
        [formControlName]="'selectedWidgetType'"
        placeholder="type"
        [ngClass]="{
          'is-invalid':
            formControls?.selectedWidgetType?.errors && formControls?.selectedWidgetType?.touched
        }"
      >
        <option *ngFor="let type of types" [ngValue]="type.id">
          {{ type.id | widgetTypeLabel }}</option
        >
      </select>
      <div
        class="invalid-feedback d-block"
        *ngIf="
          formControls?.selectedWidgetType?.errors?.required &&
          formControls?.selectedWidgetType?.touched
        "
      >
        *Please select a widget type!
      </div>
      <div class="mb-3"></div>
    </div>
    <div
      class="confirm-message"
      *ngIf="formControls?.selectedWidgetType?.value | hasChoicesSupport"
    >
      <label class="popover-subsection__title">Data Choices</label>
      <app-panel-subsection>
        <app-widget-choices-editor
          [choices]="choices"
          (changedEvent)="onChoicesEdit($event)"
        ></app-widget-choices-editor>
      </app-panel-subsection>
      <div class="mb-3"></div>
    </div>
  </app-modal>
</form>
