import {Component, Input, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../store';
import {NodeType} from '../../../core/constants/node-type';
import {LayoutTemplatesActions, LayoutTemplatesSelectors} from '../../../store/layout-templates';
import {LayoutTemplateModel} from '../../../core/models/layout-template.model';
import {Subscription} from 'rxjs';
import {NoDataTypeIds} from '../../../core/constants/no-data-settings';
import {selectNodeTemplatesByNodeTypeWithKeyword} from '../../../store/templates/templates.selectors';
import {NodeTemplateModel} from '../../../core/models/node-template.model';
import {TemplatesActions} from '../../../store/templates';

@Component({
  templateUrl: './layout-picker.component.html',
  styleUrls: ['./layout-picker.component.scss'],
})
export class LayoutPickerComponent implements OnInit, OnDestroy {
  @Input()
  public instructions: string;

  @Input()
  public instructionsTemplate: TemplateRef<any>;
  @Input()
  public types: NodeType[];
  public readonly readonly = true;
  public iconUrl: string = 'assets/img/new-logo/Z-Chat.png'; // Default icon path
  public readonly modalTitle = 'Activity Library';
  public selectedItem: LayoutTemplateModel | NodeTemplateModel = null;
  public selectedItemType: 'workspace' | 'layout' = null;
  protected readonly subscription = new Subscription();

  public workspaceTemplates: NodeTemplateModel[];
  public filteredTemplates: LayoutTemplateModel[];
  public keywords: string;
  public noDataTypeIds = NoDataTypeIds;

  constructor(private store: Store<AppState>) {
  }

  ngOnInit(): void {
    this.subscription.add(
      this.store
        .pipe(select(LayoutTemplatesSelectors.selectAllLayoutTemplatesWithKeyword))
        .subscribe(layoutTemplates => {
          this.filteredTemplates = layoutTemplates;
          if (this.selectedItem) {
            this.selectedItem = this.filteredTemplates.find(
              template => template.id === this.selectedItem.id,
            );
          }
        }),
    );

    let nodeTypes: NodeType[] = [NodeType.assignment];
    this.subscription.add(
      this.store
        .pipe(select(selectNodeTemplatesByNodeTypeWithKeyword(nodeTypes)))
        .subscribe(templates => {
          this.workspaceTemplates = templates;
          // if selected item is there, then update the selected item
          if (this.selectedItem) {
            this.selectedItem = this.workspaceTemplates.find(
              template => template.id === this.selectedItem.id,
            );
          }
        }),
    );
  }

  public search(keywords) {
    this.keywords = keywords;
    this.store.dispatch(
      LayoutTemplatesActions.updateSearchKeywordRequest({searchKeyword: this.keywords}),
    );
    this.store.dispatch(
      TemplatesActions.updateSearchKeywordRequest({searchKeyword: this.keywords}),
    );
  }

  public selectItem(item: LayoutTemplateModel | NodeTemplateModel, type: 'workspace' | 'layout') {
    if (this.selectedItem === item) {
      this.selectedItem = null;
      this.selectedItemType = null;
      return;
    }
    this.selectedItem = item;
    this.selectedItemType = type;
  }

  public trackBy(index, item: LayoutTemplateModel | NodeTemplateModel) {
    return item?.id;
  }

  public ngOnDestroy(): void {
    this.store.dispatch(LayoutTemplatesActions.updateSearchKeywordRequest({searchKeyword: ''}));
    this.store.dispatch(TemplatesActions.updateSearchKeywordRequest({searchKeyword: ''}));
    this.subscription.unsubscribe();
  }
}
