import { NodeTagModel } from '../models/node-tag.model';
import { NodeModel } from '../models/node.model';

const getUsedTagIdsAndCounts = (folderViewModels: NodeModel[]) => {
  const countsById = {};
  let tagIds = [];
  if (folderViewModels) {
    let tagIdSet = new Set<number>();
    folderViewModels.forEach(viewModel => {
      viewModel.children.forEach(leafViewModel => {
        leafViewModel.tags
          .filter(x => !!x) // this is sometimes 'undefined'. Not sure why?
          .forEach((nodeTag: NodeTagModel) => {
            tagIdSet.add(nodeTag.id);
            countsById[nodeTag.id] = countsById[nodeTag.id] ? countsById[nodeTag.id] + 1 : 1;
          });
      });
    });
    tagIds = [...tagIdSet];
  }
  return {
    countsById,
    tagIds,
  };
};

const getUsedTemplateIdsAndCounts = (folderViewModels: NodeModel[]) => {
  const countsById = {};
  let templateIds = [];
  if (folderViewModels) {
    let templateIdSet = new Set<number>();
    folderViewModels.forEach(viewModel => {
      viewModel.children.forEach(leafViewModel => {
        const nodeTemplateId = leafViewModel.nodeTemplateId;
        templateIdSet.add(nodeTemplateId);
        countsById[nodeTemplateId] = countsById[nodeTemplateId]
          ? countsById[nodeTemplateId] + 1
          : 1;
      });
    });
    templateIds = [...templateIdSet];
  }
  return {
    countsById,
    templateIds,
  };
};

const getUsedLayoutTemplateIds = (folderViewModels: NodeModel[]) => {
  const countsById = {};
  let templateIds = [];
  if (folderViewModels) {
    let idSet = new Set<number>();
    folderViewModels.forEach(viewModel => {
      viewModel.children.forEach(leafViewModel => {
        const layoutTemplateIds = (leafViewModel.allowedTemplates || []).map(
          template => template.id,
        );
        idSet = new Set([...idSet, ...layoutTemplateIds]);
      });
    });
    templateIds = [...idSet];
  }
  return {
    templateIds,
  };
};

const getUsedTagIdsAndCountsByLeafIds = (leafIds: number[], nodeTagsByNodeId) => {
  const countsById = {};
  let tagIds = [];
  if (leafIds) {
    let tagIdSet = new Set<number>();
    leafIds.forEach(leafId => {
      (nodeTagsByNodeId[leafId] || []).forEach((nodeTag: NodeTagModel) => {
        tagIdSet.add(nodeTag.id);
        countsById[nodeTag.id] = countsById[nodeTag.id] ? countsById[nodeTag.id] + 1 : 1;
      });
    });
    tagIds = [...tagIdSet];
  }
  return {
    countsById,
    tagIds,
  };
};

export const folderViewUtil = {
  getUsedTagIdsAndCounts,
  getUsedTemplateIdsAndCounts,
  getUsedLayoutTemplateIds,
  getUsedTagIdsAndCountsByLeafIds,
};
