import { AppState } from '../index';
import { AuthState } from './auth.state';
import { createSelector } from '@ngrx/store';
import { ColorType } from '../../core/constants/color-type';
import { UserModel } from '../../core/models/user.model';

export const selectAuthState = (state: AppState) => state.auth;

export const selectCurrentUser = createSelector(
  selectAuthState,
  (state: AuthState) => state.currentUser,
);

export const selectCurrentUserId = createSelector(selectCurrentUser, (user: UserModel) => user?.id);

export const selectUserIsLogin = createSelector(selectCurrentUser, currentUser => {
  return !!currentUser;
});

export const selectUserMetaData = createSelector(selectCurrentUser, user => user?.metaData);

export const selectUserAvatarColor = createSelector(selectUserMetaData, metaData =>
  metaData?.avatarColor ? metaData.avatarColor : ColorType['not-available'],
);
