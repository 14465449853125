<div
  class="rich-search-filter"
  [ngClass]="{ 'rich-search-filter__has-selection': (selectedEntityIds || []).length > 0 }"
>
  <div
    class="rich-search-filter__dropdown"
    ngbDropdown
    container="body"
    [autoClose]="true"
    #filterDropdown="ngbDropdown"
  >
    <button
      type="button"
      class="btn btn-sm rich-search-filter__toggle"
      [id]="id"
      [ngClass]="[
        (selectedEntityIds || []).length > 0 ? 'rich-search-filter__toggle--highlight' : ''
      ]"
      ngbDropdownToggle
    >
      <span class="rich-search-filter__toggle-text"
        >{{ type | richSearchTypeLabel }}
        {{
          (selectedEntityIds || []).length > 0 ? '(' + (selectedEntityIds || []).length + ')' : ''
        }}</span
      >
    </button>
    <div ngbDropdownMenu [attr.aria-labelledby]="id">
      <app-rich-select-menu
        [options]="entityOptions | toOptions: 'title'"
        [selectedOptionIds]="selectedEntityIds"
        (changeOptionIds)="change($event)"
      ></app-rich-select-menu>
    </div>
  </div>
</div>
