import { TemplateFieldViewModel } from '../../core/models/template-field-view.model';
import { NodeTagModel } from '../../core/models/node-tag.model';
import { TagModel } from '../../core/models/tag.model';
import { NodeWidgetValueModel } from '../../core/models/node-widget-value.model';
import { NodeModel } from '../../core/models/node.model';
import { NodeTemplateModel } from '../../core/models/node-template.model';

const getLeavesByTemplateId = (assignmentNode: NodeModel) => {
  let leavesByTemplateId: { [id: number]: NodeModel[] } = {};
  (assignmentNode?.children || []).forEach(child => {
    const leaf: NodeModel = convertToLeafViewModel(child.reference);
    leavesByTemplateId = {
      ...leavesByTemplateId,
      [leaf.nodeTemplateId]: [...(leavesByTemplateId[leaf.nodeTemplateId] || []), leaf],
    };
  });
  return leavesByTemplateId;
};

const convertToField = (templates: NodeTemplateModel[], leavesByTemplateId) => {
  let fields: TemplateFieldViewModel[] = templates.map(template => {
    const leaves = leavesByTemplateId[template.id] || [];
    const field: TemplateFieldViewModel = {
      ...template,
      leaves,
    };
    return field;
  });
  return fields;
};

const convertToLeafViewModel = (serverNode: NodeModel): NodeModel => {
  const node = serverNode;

  //todo: we need to get this from the store???
  let tag: TagModel = {
    colorTheme: undefined,
    icon: undefined,
    id: 0,
    tagType: undefined,
    title: '',
  };

  return {
    ...node,
    widgets: [],
    //   node.__widgets.map((nodeWidget: NodeWidgetValueModel) => {
    //   return {
    //     id: nodeWidget.id,
    //     joinId: null,
    //     sortIndex: 0, //nodeWidget.sortIndex,
    //     canDelete: true,
    //     widget: {
    //       id: nodeWidget.id,
    //       nodeType: node.nodeType,
    //       widgetType: 0, //nodeWidget.widgetType,
    //       title: '**Deprecated**', //nodeWidget.title,
    //     },
    //   };
    // }),
    tags: node.__tags.map((nodeTag: NodeTagModel) => {
      return { ...tag, ...nodeTag };
    }),
  };
};

export const sharedViewUtil = { getLeavesByTemplateId, convertToField, convertToLeafViewModel };
