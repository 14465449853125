<button
  #input
  type="button"
  class="btn z-table__input z-table__input--icon"
  [ngClass]="'picker-button--icon-' + (cell.value | iconString)"
  (click)="onIconPicker()"
  (keyup.enter)="onIconPicker()"
  [disabled]="!editable"
>
  <span class="fal" [ngClass]="'fa-' + (cell.value | iconString)"></span>
</button>
