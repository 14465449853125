import { Pipe, PipeTransform } from '@angular/core';
import { WidgetType } from '../../core/constants/widget-type';
import { NodeTemplateWidgetModel } from '../../core/models/node-template-widget.model';

@Pipe({
  name: 'widgetSort',
})
export class WidgetSortPipe implements PipeTransform {
  transform(
    widgets: NodeTemplateWidgetModel[],
    orderedWidgetTypes: WidgetType[],
  ): NodeTemplateWidgetModel[] {
    return [...(widgets || [])].sort((widgetA, widgetB) => {
      const weightA = orderedWidgetTypes.indexOf(widgetA.widget.widgetType);
      const weightB = orderedWidgetTypes.indexOf(widgetB.widget.widgetType);
      if (weightA === weightB) {
        return widgetA.widget.title.localeCompare(widgetB.widget.title);
      } else {
        return weightA - weightB;
      }
    });
  }
}
