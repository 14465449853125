import { createAction, props } from '@ngrx/store';
import { NodeTagModel } from '../../core/models/node-tag.model';
import { TagType } from '../../core/constants/tag-type';

export const loadNodeTagsFromNodeTree = createAction(
  '[NodeTags] load node tags from node tree success',
  props<{ nodeTags: NodeTagModel[] }>(),
);

export const addNodeTagRequest = createAction(
  '[NodeTags] add node tag request',
  props<{ nodeId: number; tagId: number; tagType: TagType; groupId?: number }>(),
);

export const addNodeTagSuccess = createAction(
  '[NodeTags] add node tag success',
  props<{ nodeTag: NodeTagModel }>(),
);

export const addMultipleTagsToNodeRequest = createAction(
  '[NodeTags] apply multiple tags to node request',
  props<{ nodeId: number; tagIds: number[]; groupId: number; replace: boolean }>(),
);

export const addMultipleTagsToNodeSuccess = createAction(
  '[NodeTags] apply multiple tags to node success',
  props<{ nodeId: number; nodeTags: NodeTagModel[]; groupId: number; replace?: boolean }>(),
);

export const addTagsToMultipleNodesRequest = createAction(
  '[NodeTags] apply tags to multiple nodes request',
  props<{ nodeIds: number[]; tagIds: number[]; replace: boolean }>(),
);

export const addTagsToMultipleNodesSuccess = createAction(
  '[NodeTags] apply tags to multiple nodes success',
  props<{ nodeIds: number[]; nodeTags: NodeTagModel[]; replace: boolean }>(),
);

export const removeNodeTagRequest = createAction(
  '[NodeTags] remove node tag request',
  props<{ nodeId: number; joinId: number }>(),
);

export const removeNodeTagsByTagId = createAction(
  '[NodeTags] remove node tags by tag id request',
  props<{ tagId: number }>(),
);

export const removeNodeTagsByNodeIdGroupId = createAction(
  '[NodeTags] remove node tag by nodeId and groupId request',
  props<{ nodeId: number; groupId: number }>(),
);

export const clearNodeTagsRequest = createAction(
  '[NodeTags] clear node tags request',
  props<{ nodeId: number }>(),
);
