import PlaceResult = google.maps.places.PlaceResult;
import { AddressModel } from '../models/address.model';

interface GoogleAddressData {
  street_number?: string;
  route?: string;
  locality?: string;
  administrative_area_level_1?: string;
  country?: string;
  postal_code?: string;
}

const supportedAddressTypes: (keyof GoogleAddressData)[] = [
  'street_number',
  'route',
  'locality',
  'administrative_area_level_1',
  'country',
  'postal_code',
];

const convertGooglePlace = (place: PlaceResult): AddressModel => {
  let address: AddressModel;
  let data: GoogleAddressData = {};
  place?.address_components?.forEach(comp => {
    if (supportedAddressTypes.indexOf(comp.types[0] as keyof GoogleAddressData) >= 0) {
      data[comp.types[0]] =
        comp.types[0] === 'administrative_area_level_1' ? comp.short_name : comp.long_name;
    }
  });

  address = {
    street: `${data.street_number ? data.street_number + ' ' : ''}${data.route}`,
    suburb: data.locality || '',
    state: data.administrative_area_level_1 || '',
    postcode: data.postal_code || '',
    country: data.country || '',
  };

  return address;
};

const getFormattedAddress = (address: AddressModel) => {
  if (!address) {
    return '';
  }
  const addressParts = [
    address.street,
    address.suburb,
    `${address.state} ${address.postcode}`,
    address.country,
  ];
  return addressParts
    .filter(part => !!part)
    .join(', ')
    .trim();
};

export { convertGooglePlace, getFormattedAddress };
