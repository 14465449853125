import { Action, createReducer, on } from '@ngrx/store';
import { DndState, initialDndState } from './dnd.state';
import { DndActions } from './index';

const reducer = createReducer(
  initialDndState,

  on(
    DndActions.dndStartDragging,
    (state): DndState => {
      return {
        isDragging: true,
        escPressed: false,
      };
    },
  ),

  on(DndActions.dndEndDragging, state => ({
    isDragging: false,
    escPressed: false,
  })),

  on(DndActions.setEscPressed, state => ({
    ...state,
    escPressed: true,
  })),
);

export function dndReducer(state: DndState, action: Action) {
  return reducer(state, action);
}
