import { OptionModel } from '../models/option.model';

export enum WidgetType {
  textbox = 1,
  textarea = 2,
  number = 3,
  checkbox = 4,
  slider = 5,
  email = 6,
  phone = 7,
  url = 8,
  address = 9,
  date = 10,
  list = 11,
  currency = 12,
  checkboxList = 13,
  urlList = 14,
  dateTime = 15,
  time = 16,
  heading = 17,
  lineSeparator = 18,
  subHeading = 19,
  paragraph = 20,
  // Special Widgets
  calculationJoin = 101,
  projectList = 102,
  assetList = 103,
}

export const availableCalculationFieldWidgetTypeOptions: OptionModel[] = [
  { id: WidgetType.number, label: 'Number' },
  { id: WidgetType.currency, label: 'Currency' },
];

// export const availableChecklistFieldWidgetTypeOptions: OptionModel[] = [
//   { id: WidgetType.number, label: 'Number' },
//   { id: WidgetType.textbox, label: 'Text' },
//   { id: WidgetType.date, label: 'Date' },
//   { id: WidgetType.checkbox, label: 'Checkbox' },
// ];
