import { createAction, props } from '@ngrx/store';
import { NodeWidgetValueModel } from '../../core/models/node-widget-value.model';
import { WidgetEditableProps } from '../../core/models/widget.model';
import { NodeWidgetRowModel } from '../../core/models/node-widget-row.model';
import { NodeGroupValueModel } from '../../core/models/node-group-value.model';

export const loadNodeWidgetRowsFromNodeTree = createAction(
  '[NodeWidgetRows] load node widget rows from node tree success',
  props<{ nodeWidgetRows: NodeWidgetRowModel[] }>(),
);

export const updateNodeWidgetRowRequest = createAction(
  '[NodeWidgetRows] update node widget row request',
  props<{
    nodeId: number;
    widgetId: number;
    widgetProps: Partial<WidgetEditableProps>;
  }>(),
);

export const updateWidgetRowValueRequest = createAction(
  '[Widget Values] update widget row value request',
  props<{
    nodeId: number;
    widgetId: number;
    rowNumber: number;
    value: any;
    referenceId?: number;
  }>(),
);

export const updateWidgetRowValueSuccess = createAction(
  '[Widget Values] update widget row value success',
  props<{
    nodeId: number;
    widgetId: number;
    rowNumber: number;
    widgetValue: NodeWidgetValueModel;
    groupValue: NodeGroupValueModel;
    referenceId?: number;
  }>(),
);

export const updateNodeWidgetRowSuccess = createAction(
  '[NodeWidgetRows] update node widget row success',
  props<{ nodeWidgetRow: NodeWidgetRowModel }>(),
);
