import { Component, Input, OnInit } from '@angular/core';
import { UserModel } from '../../../core/models/user.model';
import { SizeType } from '../../../core/constants/size-type';

@Component({
  selector: 'app-workspace-user',
  templateUrl: './workspace-user.component.html',
  styleUrls: ['./workspace-user.component.scss'],
})
export class WorkspaceUserComponent implements OnInit {
  @Input() user: UserModel;
  @Input() isActive: boolean;
  @Input() size: SizeType;

  constructor() {}

  ngOnInit(): void {}
}
