import { WidgetType } from '../constants/widget-type';

const hasChoicesSupport = (widgetType: WidgetType) => {
  return (
    widgetType === WidgetType.textbox ||
    widgetType === WidgetType.url ||
    widgetType === WidgetType.email ||
    widgetType === WidgetType.phone
  );
};

export { hasChoicesSupport };
