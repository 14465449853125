<div class="content-panel">
  <app-sidebar-header
    class="content-panel__header"
    [title]="selectedNode?.title"
    [enableBack]="!isRootNode"
    (backEvent)="switchToRootNode()"
  >
  </app-sidebar-header>

  <div class="content-panel__search">
    <app-rich-search
      [itemOptions]="searchItems"
      [selectedSearchItems]="selectedSearchItems"
      (richSearchEvent)="richSearch($event)"
    ></app-rich-search>

    <div class="content-panel__controls">
      <app-multi-select-controls></app-multi-select-controls>
    </div>
  </div>

  <div class="content-panel__body" cdkScrollable>
    <div class="content-panel__canvas-wrapper">
      <app-leaf-list
        *ngIf="childNodes && childNodes.length"
        class="folder-list-item__leaf-list-host"
        [leaves]="childNodes"
        [folderColorTheme]="selectedNode.colorTheme"
        [parentFolderId]="selectedNode.id"
      ></app-leaf-list>

      <app-folder-list
        *ngIf="childFolders && childFolders.length"
        class="content-panel__folder-list"
        [nodes]="childFolders"
      ></app-folder-list>
    </div>
  </div>
  <app-sidebar-footer class="content-panel__footer">
    <app-node-list-display-settings></app-node-list-display-settings>
  </app-sidebar-footer>

  <app-content-loader scope="node-list-panel"></app-content-loader>
</div>
