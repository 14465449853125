import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-popover-subsection-row',
  templateUrl: './popover-subsection-row.component.html',
  styleUrls: ['./popover-subsection-row.component.scss'],
})
export class PopoverSubsectionRowComponent implements OnInit {
  @Input() title: string;

  constructor() {}

  ngOnInit(): void {}
}
