<div class="link-group-view">
  <h3 class="link-group-view__title">{{ group.title }}</h3>
  <div class="link-group-view__body">
    <table class="link-group-view__table ap-table table table-hover">
      <tbody>
        <tr
          class="link-group-view__tr"
          *ngFor="let link of group.links; trackBy: trackBy"
          [ngbTooltip]="link.url"
          container="body"
          (click)="goToLink(link.url)"
        >
          <td>
            <app-link-item [link]="link | toLinkModel"></app-link-item>
          </td>
        </tr>
        <tr *ngIf="group?.links?.length === 0">
          <td class="ap-no-content">
            There are no links in this group yet.
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
