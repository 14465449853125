import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { TableComponent } from './table.component';
import { TableSearchComponent } from './table-search/table-search.component';
import { TableRowMenuComponent } from './table-row-menu/table-row-menu.component';
import { TableColumnClassesPipe } from './table-pipes/table-column-classes.pipe';
import { TableEditorsModule } from './table-editors/table-editors.module';
import { FormsModule } from '@angular/forms';
import { ComponentsModule } from '../components/_components.module';
import { NgxPrintModule } from 'ngx-print';

@NgModule({
  declarations: [
    TableComponent,
    TableSearchComponent,
    TableRowMenuComponent,
    TableColumnClassesPipe,
  ],
  imports: [
    CommonModule,
    DragDropModule,
    NgbPopoverModule,
    TableEditorsModule,
    FormsModule,
    // Custom Imports
    ComponentsModule,
    NgxPrintModule,
  ],
  exports: [TableComponent, TableEditorsModule],
  providers: [],
})
export class TableModule {}
