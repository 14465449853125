import { NodeModel } from '../../core/models/node.model';

export class ArchivesState {
  archivedNodeTree: NodeModel[];
  archivedAssignmentTree: NodeModel[];
}

export const initialArchivesState: ArchivesState = {
  archivedNodeTree: [],
  archivedAssignmentTree: [],
};
