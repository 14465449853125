import { Pipe, PipeTransform } from '@angular/core';
import { LinkModel } from '../../core/models/link.model';
import { IconType } from '../../core/constants/icon-type';
import { NodeTemplateWidgetModel } from '../../core/models/node-template-widget.model';

@Pipe({
  name: 'urlWidgetToLink',
})
export class UrlWidgetToLinkPipe implements PipeTransform {
  transform(widget: NodeTemplateWidgetModel): LinkModel {
    if (widget) {
      return {
        value: widget.value,
        label: undefined,
        icon: IconType['not-available'],
      };
    }
    return null;
  }
}
