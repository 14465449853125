<div
  class="form-group"
  [ngClass]="{
    'my-2': marginYAuto
  }"
>
  <input
    class="form-control"
    type="text"
    [disabled]="disabled"
    [ngModel]="autocompleteInput"
    #addressInput
  />
</div>
