import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToggleViewMode } from '../../../../core/constants/toggle-view-mode';
import { Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import {
  selectContentPanelLayoutTemplateViewMode,
  selectContentPanelTagsViewMode,
  selectContentPanelWidgetsViewMode,
} from '../../../../store/workspace-user-meta/workspace-user-meta.selectors';
import { WorkspaceUserMetaActions } from '../../../../store/workspace-user-meta';

@Component({
  selector: 'app-node-list-display-settings',
  templateUrl: 'node-list-display-settings.component.html',
  styleUrls: ['node-list-display-settings.component.scss'],
})
export class NodeListDisplaySettingsComponent implements OnInit, OnDestroy {
  private readonly subscription = new Subscription();

  tagsViewMode: ToggleViewMode;
  widgetsViewMode: ToggleViewMode;
  layoutTemplateViewMode: ToggleViewMode;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.subscription.add(
      this.store.pipe(select(selectContentPanelTagsViewMode)).subscribe(mode => {
        this.tagsViewMode = mode;
      }),
    );

    this.subscription.add(
      this.store.pipe(select(selectContentPanelWidgetsViewMode)).subscribe(mode => {
        this.widgetsViewMode = mode;
      }),
    );

    this.subscription.add(
      this.store.pipe(select(selectContentPanelLayoutTemplateViewMode)).subscribe(mode => {
        this.layoutTemplateViewMode = mode;
      }),
    );
  }

  changeTagsViewMode(mode: ToggleViewMode) {
    this.store.dispatch(
      WorkspaceUserMetaActions.setContentPanelTagsViewModeRequest({
        mode: mode === 'show' ? 'hide' : 'show',
      }),
    );
  }

  changeWidgetsViewMode(mode: ToggleViewMode) {
    this.store.dispatch(
      WorkspaceUserMetaActions.setContentPanelWidgetsViewModeRequest({
        mode: mode === 'show' ? 'hide' : 'show',
      }),
    );
  }

  changeLayoutTemplateViewMode(mode: ToggleViewMode) {
    this.store.dispatch(
      WorkspaceUserMetaActions.setContentPanelLayoutTemplateViewModeRequest({
        mode: mode === 'show' ? 'hide' : 'show',
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
