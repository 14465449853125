import { createAction, props } from '@ngrx/store';
import { TagsPopoverPage } from '../../core/constants/tags-popover-page';
import { TagType } from '../../core/constants/tag-type';

export const showNodeTagsPage = createAction(
  '[Tags Popover] show node tags page',
  props<{ nodeId: number; templateId: number; tagType: TagType }>(),
);

export const showNodeGroupTagsPage = createAction(
  '[Tags Popover] show node group tags page',
  props<{ nodeId: number; templateId: number; tagType: TagType; groupId: number }>(),
);

export const switchNodeTagGroup = createAction(
  '[Tags Popover] switch node tag group',
  props<{ groupId: number }>(),
);

export const showTemplateTagsPage = createAction(
  '[Tags Popover] show template tags page',
  props<{ templateId: number; tagType: TagType; groupId?: number }>(),
);

export const goBackPreviousPage = createAction('[Tags Popover] go back previous page');

export const showTagCreatePage = createAction(
  '[Tags Popover] show tag-create page',
  props<{
    nodeId: number;
    templateId: number;
    tagType?: TagType;
    previousPage: TagsPopoverPage;
  }>(),
);

export const showTagEditPage = createAction(
  '[Tags Popover] show tag-edit page',
  props<{
    nodeId: number;
    templateId: number;
    tagId: number;
    tagType: TagType;
    previousPage: TagsPopoverPage;
    allowDelete?: boolean;
  }>(),
);

export const updateSearchKeywordRequest = createAction(
  '[Tags Popover] update search keyword request',
  props<{ searchKeyword: string }>(),
);

export const resetTagEditPage = createAction('[Tags Popover] reset tag-edit page');
