import { createSelector } from '@ngrx/store';
import {
  selectAssignmentNodeTemplateList,
  selectNodeTemplateIdsByNodeType,
  selectNodeTemplatesById,
  selectProjectNodeTemplateList,
} from './templates.selectors';
import { NodeType } from '../../core/constants/node-type';
import { TemplateViewModel } from '../../core/models/template-view.model';
import { NodeTemplateModel } from '../../core/models/node-template.model';
import { NodesSelectors } from '../nodes';

// for element and asset types
export const selectElementTemplateViewModelsByServerNodeType = createSelector(
  selectNodeTemplateIdsByNodeType,
  selectNodeTemplatesById,
  NodesSelectors.selectNodesByTemplateId,
  (
    idsByServerNodeType,
    templatesById,
    nodesByTemplateId,
  ): { [key in NodeType]?: TemplateViewModel[] } => {
    const serverNodeTypes = [NodeType.element, NodeType.asset];
    let viewModelsByServerNodeType = {};
    serverNodeTypes.forEach(type => {
      const models: TemplateViewModel[] = (idsByServerNodeType[type] || [])
        .map(id => templatesById[id])
        .filter(template => !!template)
        .map(template => {
          return {
            ...template,
            leaves: (nodesByTemplateId[template.id] || []).filter(
              n => serverNodeTypes.indexOf(n.nodeType) !== -1,
            ),
          };
        });

      viewModelsByServerNodeType = {
        ...viewModelsByServerNodeType,
        [type]: models,
      };
    });
    return viewModelsByServerNodeType;
  },
);

export const selectAssetTemplateViewModels = createSelector(
  selectElementTemplateViewModelsByServerNodeType,
  viewModelsByServerNodeType => {
    return viewModelsByServerNodeType[NodeType.asset] || {};
  },
);

export const selectElementTemplateViewModelsData = createSelector(
  selectElementTemplateViewModelsByServerNodeType,
  (
    viewModelsByServerNodeType,
  ): {
    defaultElementTemplateViewModels: TemplateViewModel[];
    assetTemplateViewModels: TemplateViewModel[];
  } => {
    const defaultElementTemplateViewModels = viewModelsByServerNodeType[NodeType.element] || [];
    const assetTemplateViewModels = viewModelsByServerNodeType[NodeType.asset] || [];
    return {
      defaultElementTemplateViewModels,
      assetTemplateViewModels,
    };
  },
);

export const selectElementTemplateViewModelsDataByProjectAndAssignmentTemplateId = createSelector(
  selectElementTemplateViewModelsData,
  selectAssignmentNodeTemplateList,
  selectProjectNodeTemplateList,
  (data, assignmentTemplates, projectTemplates) => {
    let dataByTemplateId = {};
    [...projectTemplates, ...assignmentTemplates].forEach((template: NodeTemplateModel) => {
      dataByTemplateId = {
        ...dataByTemplateId,
        [template.id]: {
          defaultElementTemplateViewModels: data.defaultElementTemplateViewModels.filter(
            model =>
              template.allowedTemplateIds
                .map(id => (typeof id === 'string' ? parseInt(id, 10) : id))
                .indexOf(model.id) >= 0,
          ),
          assetTemplateViewModels: data.assetTemplateViewModels.filter(
            model =>
              template.allowedTemplateIds
                .map(id => (typeof id === 'string' ? parseInt(id, 10) : id))
                .indexOf(model.id) >= 0,
          ),
        },
      };
    });

    return dataByTemplateId;
  },
);
