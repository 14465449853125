import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { TableCellEditEvent, TableEditor } from './table-editors.common';
import { TableCell, TableColumn, TableGroup, TableRow } from '../table.common';

@Component({
  selector: 'app-z-table-editors',
  templateUrl: 'table-editors.component.html',
  styleUrls: ['table-editors.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TableEditorsComponent implements OnInit, OnChanges, OnDestroy {
  public readonly TableEditor = TableEditor;

  @Input()
  public tableEditable?: boolean;

  @Input()
  public rowIndex: number;

  @Input()
  public group: TableGroup;

  @Input()
  public row: TableRow;

  @Input()
  public column: TableColumn;

  @Input()
  public cell: TableCell;

  @Input()
  public sortDescending: boolean;

  @Output()
  public startEdit = new EventEmitter<TableCellEditEvent>();

  @Output()
  public endEdit = new EventEmitter<TableCellEditEvent>();

  constructor() {}

  public ngOnInit(): void {}

  public ngOnChanges(changes: SimpleChanges): void {}

  public onStartEdit(event: TableCellEditEvent) {
    this.startEdit.emit(event);
  }

  public onEndEdit(event: TableCellEditEvent) {
    this.endEdit.emit(event);
  }

  public ngOnDestroy() {}
}
