import { createAction, props } from '@ngrx/store';
import { UserEditableFields, UserModel } from '../../core/models/user.model';
import { FileModel } from '../../core/models/file.model';

export const loginRequest = createAction(
  '[Auth] login request',
  props<{ username: string; password: string; rememberMe: boolean }>(),
);

export const loginSuccess = createAction('[Auth] login success', props<{ user: UserModel }>());

export const getUserSuccess = createAction('[Auth] get user success', props<{ user: UserModel }>());

export const getOpenServerSessionSuccess = createAction(
  '[Auth] get open server session success',
  props<{ user: UserModel }>(),
);

export const logoutRequest = createAction('[Auth] logout request');
export const getOpenServerSessionError = createAction('[Auth] get open server session error');

export const patchCurrentUserRequest = createAction(
  '[Auth] patch current user',
  props<{ userData: Partial<UserEditableFields> }>(),
);

export const patchCurrentUserProfile = createAction(
  '[Auth] patch current user profile',
  props<{ profile: FileModel; currentUserId: number }>(),
);

export const removeCurrentUserProfileRequest = createAction(
  '[Auth] remove current user profile request',
);

export const patchCurrentUserMetaRequest = createAction(
  '[Auth] patch current user meta request',
  props<{ metaData: object }>(),
);

export const patchCurrentUserMetaSuccess = createAction(
  '[Auth] patch current user meta success',
  props<{ metaData: object }>(),
);
