import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { NodeTemplateModel } from '../../../../../core/models/node-template.model';
import { TemplatesActions } from '../../../../../store/templates';
import { NodeType } from '../../../../../core/constants/node-type';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { defaultPlacements } from '../../../../../core/constants/placements';

import { Router } from '@angular/router';

@Component({
  selector: 'app-template-list',
  templateUrl: './template-list.component.html',
  styleUrls: ['./template-list.component.scss'],
})
export class TemplateListComponent implements OnInit {
  public readonly ServerNodeType = NodeType;
  public readonly defaultPlacements = defaultPlacements;

  @Input() templateList: NodeTemplateModel[];
  @Input() serverNodeType: NodeType;

  @Input() selectedTemplateId: number;

  @Input() slug: string;
  @Input() defaultTemplateId: number;

  constructor(private store: Store, private router: Router) {}

  ngOnInit(): void {}

  selectTemplate(templateId: number) {
    this.router.navigate(['ws', this.slug, 'settings', 'node-templates', templateId]);
  }

  // deleteTemplate(templateId: number, event) {
  //   this.store.dispatch(
  //     TemplatesActions.deleteNodeTemplateRequest({
  //       templateId,
  //       serverNodeType: this.serverNodeType,
  //     }),
  //   );
  //   event.stopPropagation();
  // }

  sortTemplates(event) {
    const templateIds = this.templateList.map(template => template.id);
    if (event.currentIndex !== event.previousIndex) {
      moveItemInArray(templateIds, event.previousIndex, event.currentIndex);
      this.store.dispatch(
        TemplatesActions.sortNodeTemplatesRequest({
          templateIds,
          serverNodeType: this.serverNodeType,
        }),
      );
    }
  }

  // copyTemplate(templateId: number, event) {
  //   this.store.dispatch(
  //     TemplatesActions.copyNodeTemplateRequest({
  //       templateId,
  //       serverNodeType: this.serverNodeType,
  //       workspaceSlug: this.slug,
  //     }),
  //   );
  //   event.stopPropagation();
  // }

  stopPropagation(event) {
    event.stopPropagation();
  }

  trackBy(index, template: NodeTemplateModel) {
    return template.id;
  }
}
