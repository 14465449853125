export class FilePaths {
  // old icons
  // public static appLogo = 'assets/img/zedulr-logo.png';
  // public static appLogo_2 = 'assets/img/zedulr-logo-web@2x.png';
  // public static appIcon = 'assets/img/zedulr-logo-web-icon-only@2x.png';

  // new icons
  public static appLogo = 'assets/img/new-logo/Zedulr-LOGO-2022-SML.png';
  public static appLogo_2 = 'assets/img/new-logo/Zedulr-LOGO-2022-SML.png';
  public static appIcon = 'assets/img/new-logo/Zedulr-ICON-2022-SML.png';
}
