import { Pipe, PipeTransform } from '@angular/core';
import { RichSearchItemModel } from '../../core/models/rich-search-item.model';
import { RichSearchType } from '../../core/constants/rich-search-type';
import { EntityModel } from '../../core/models/entity.model';

@Pipe({
  name: 'richSearchFilterOptions',
})
export class RichSearchFilterOptionsPipe implements PipeTransform {
  transform(searchOptions: RichSearchItemModel[], types: RichSearchType[]): EntityModel[] {
    return (searchOptions || [])
      .filter(option => types.indexOf(option.type) >= 0)
      .map(option => option.entity);
  }
}
