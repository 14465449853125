import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserModel } from '../../../../core/models/user.model';
import { WorkspacesActions } from '../../../../store/workspaces';
import { AppState } from '../../../../store';
import { select, Store } from '@ngrx/store';
import { selectCurrentUser } from '../../../../store/auth/auth.selectors';
import { selectDndIsDragging } from '../../../../store/dnd/dnd.selectors';
import { DndActions } from '../../../../store/dnd';
import { WorkspaceTemplatesActions } from '../../../../store/workspace-templates';
import { AuthService } from '../../../../core/services/auth.service';
import { first, take } from 'rxjs/operators';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmPopupSessionTimeoutComponent } from '../../../modals/confirm-popup-session-timeout/confirm-popup-session-timeout.component';
import { environment } from '../../../../../environments/environment';

@Component({
  templateUrl: 'secured-layout.component.html',
  styleUrls: ['secured-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SecuredLayoutComponent implements OnInit, OnDestroy {
  private readonly subscription: Subscription;

  @ViewChild('main') mainElement: ElementRef;

  private isDragging = false;
  public user: UserModel;
  intervalId;

  @HostListener('window:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape' && this.isDragging) {
      this.store.dispatch(DndActions.setEscPressed());
      document.dispatchEvent(new Event('mouseup'));
    }
  }

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private authService: AuthService,
    private idle: Idle,
    private modalService: NgbModal,
  ) {
    this.subscription = new Subscription();
  }

  public ngOnInit() {
    // this.idle.setIdle(3);
    // this.idle.setTimeout(5);
    const idleSeconds = environment.production ? 14400 : 28800; // longer idle time which is easier for development
    console.info('idleSeconds: ', idleSeconds);
    this.idle.setIdle(idleSeconds);
    this.idle.setTimeout(20);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.subscription.add(
      this.idle.onIdleEnd.subscribe(() => {
        console.info('no longer idle.');
      }),
    );

    this.subscription.add(
      this.idle.onIdleStart.subscribe(() => {
        console.info('become idle.');
        this.idle.clearInterrupts();
        const modelRef = this.modalService.open(ConfirmPopupSessionTimeoutComponent, {
          size: 'md',
          backdrop: 'static',
          windowClass: 'ap-modal--high-zindex',
        });
        modelRef.result
          .then((result: boolean) => {
            if (result) {
              this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
              this.idle.watch();
            } else {
              this.logout();
            }
          })
          .catch(error => {
            console.info('Session Timeout modal dismissed.');
          });
      }),
    );

    this.subscription.add(
      this.idle.onTimeout.subscribe(() => {
        console.info('timeout...');
        this.logout();
      }),
    );

    this.idle.watch();

    this.intervalId = setInterval(() => {
      this.authService
        .hasOpenServerSession()
        .pipe(take(1))
        .subscribe(user => {
          console.info('User has open server session.');
        });
    }, 1200000); // check user open session every 20 mins

    // Scroll to top on route change
    this.subscription.add(
      this.router.events.subscribe(event => {
        if (event instanceof NavigationStart) {
          this.mainElement.nativeElement.scrollTop = 0;
        }
      }),
    );

    this.subscription.add(
      this.store.pipe(select(selectCurrentUser)).subscribe(user => {
        this.user = user;
      }),
    );

    this.subscription.add(
      this.store.pipe(select(selectDndIsDragging)).subscribe(isDragging => {
        this.isDragging = isDragging;
      }),
    );

    this.store.dispatch(WorkspacesActions.loadDashboardRequest());
    this.store.dispatch(WorkspaceTemplatesActions.loadWorkspaceTemplatesRequest());
  }

  logout() {
    this.authService
      .logout()
      .pipe(first())
      .subscribe(
        data => {
          this.router.navigate(['/public/login']);
        },
        error => {
          console.error('error: ', error);
          this.router.navigate(['/public/error-page']);
        },
      );
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
    this.idle.stop();
    clearInterval(this.intervalId);
  }
}
