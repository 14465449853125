import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Router, NavigationStart } from '@angular/router';

export class AlertScope {
  public scopeName: string;
  public list: Alert[];
  public subject: BehaviorSubject<Alert[]>;

  constructor() {
    this.scopeName = '';
    this.list = [];
    this.subject = new BehaviorSubject<Alert[]>(this.list);
  }
}

export class Alert {
  public static readonly SUCCESS: string = 'success';
  public static readonly WARNING: string = 'warning';
  public static readonly DANGER: string = 'danger';
  public static readonly ERROR: string = 'danger';
  public static readonly INFO: string = 'info';

  public type: string = Alert.INFO;
  public classes = 'alert alert-info';
  public message = '';
  public scopeName = '';
  public timer: any = null;
}

@Injectable({ providedIn: 'root' })
export class AlertService implements OnDestroy {
  public static ALERT_TIMEOUT = 5000;
  private readonly DEFAULT_SCOPE: string = '__default';

  private readonly subscription: Subscription;
  private readonly alerts: any;

  constructor(private router: Router) {
    this.alerts = {};

    // clear alert messages on route change
    this.subscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.clear();
      }
    });
  }

  private getAlertScope(scopeName?: string): AlertScope {
    scopeName = scopeName || this.DEFAULT_SCOPE;
    if (!this.alerts[scopeName]) {
      const model = new AlertScope();
      model.scopeName = scopeName;
      model.list = [];
      this.alerts[scopeName] = model;
    }
    return this.alerts[scopeName];
  }

  public watchAlerts(scopeName?: string): BehaviorSubject<Alert[]> {
    const scope = this.getAlertScope(scopeName);
    return scope.subject;
  }

  public alert(message: string[] | string, type: string = 'info', scopeName: string = null) {
    // 'message' can be a string or an array
    // We'll convert to an array for consistent processing
    if (!Array.isArray(message)) {
      message = [message];
    }

    const scope = this.getAlertScope(scopeName);
    for (const msg of message) {
      const alert = new Alert();
      alert.type = type;
      alert.message = msg;
      alert.scopeName = scope.scopeName;

      if (AlertService.ALERT_TIMEOUT > 0) {
        alert.timer = setTimeout(() => {
          this.remove(alert);
        }, AlertService.ALERT_TIMEOUT);
      }

      scope.list.push(alert);
    }
    scope.subject.next(scope.list);
  }

  public success(message: string[] | string, scopeName?: string) {
    this.alert(message, Alert.SUCCESS, scopeName);
  }

  public warning(message: string[] | string, scopeName?: string) {
    this.alert(message, Alert.WARNING, scopeName);
  }

  public error(message: string[] | string, scopeName?: string) {
    this.alert(message, Alert.ERROR, scopeName);
  }

  public info(message: string[] | string, scopeName?: string) {
    this.alert(message, Alert.INFO, scopeName);
  }

  public remove(alert: Alert) {
    const scope = this.getAlertScope(alert.scopeName);
    const idx = scope.list.indexOf(alert);
    if (idx >= 0) {
      clearTimeout(alert.timer);
      scope.list.splice(idx, 1);
      scope.subject.next(scope.list);
    }
  }

  public clear(scopeName?: string) {
    // Clear all scopes
    if (!scopeName) {
      for (const key in Object.keys(this.alerts)) {
        if (this.alerts.hasOwnProperty(key)) {
          this.clear(key);
        }
      }
    }

    // Clear single scope
    else {
      const scope = this.getAlertScope(scopeName);
      scope.list = [];
      scope.subject.next(scope.list);
    }
  }

  public ngOnDestroy(): void {
    if (this.subscription instanceof Subscription) {
      this.subscription.unsubscribe();
    }
  }
}
