import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AppState } from '../../../store';
import { select, Store } from '@ngrx/store';
import {
  selectModalArchiveIsLoading,
  selectModalArchiveReferenceCount,
  selectModalArchiveReferenceCountRequired,
} from '../../../store/archive-popover/archive-popover.selectors';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-confirm-popup-archive-node',
  templateUrl: './confirm-popup-archive-node.component.html',
  styleUrls: ['./confirm-popup-archive-node.component.scss'],
})
export class ConfirmPopupArchiveNodeComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() message: string;
  public referenceCountRequired;

  public warningMessage =
    'The items will be archived. And you can restore or delete archived items in Archives menu';

  public isLoading = true;
  public referenceCount: number;

  subscription = new Subscription();

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.subscription
      .add(
        this.store.pipe(select(selectModalArchiveIsLoading)).subscribe(isLoading => {
          this.isLoading = isLoading;
        }),
      )
      .add(
        this.store.pipe(select(selectModalArchiveReferenceCount)).subscribe(referenceCount => {
          this.referenceCount = referenceCount;
        }),
      )
      .add(
        this.store.pipe(select(selectModalArchiveReferenceCountRequired)).subscribe(isRequired => {
          this.referenceCountRequired = isRequired;
        }),
      );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
