import { AppState } from '../index';
import { createSelector } from '@ngrx/store';
import { WidgetType } from '../../core/constants/widget-type';

const selectWidgetsState = (state: AppState) => state.widgets;

export const selectWidgetsById = createSelector(selectWidgetsState, state => state.byId);

export const selectAllWidgets = createSelector(selectWidgetsById, byId => {
  return Object.values(byId).filter(value => !!value);
});

export const selectWidgetsSearchKeyword = createSelector(
  selectWidgetsState,
  state => state.searchKeyword,
);

export const selectAllNonSpecialWidgetsWithKeyword = createSelector(
  selectWidgetsById,
  selectWidgetsSearchKeyword,
  (byId, keywords) => {
    const widgets = Object.values(byId).filter(
      widget =>
        widget != null &&
        widget.groupId == null &&
        widget.widgetType != WidgetType.calculationJoin &&
        widget.parentAWidgetId == null &&
        widget.parentBWidgetId == null,
    );
    if (!keywords) {
      return widgets;
    }
    const lowerCaseKeyword = keywords.toLowerCase();
    return widgets.filter(widget => widget.title.toLowerCase().indexOf(lowerCaseKeyword) >= 0);
  },
);
