import { FileUpload } from '../../shared/components/file-uploader/file-uploader.service';

export class FileModel extends FileUpload {
  public id: number;
  public mime: string;
  public type: string;
  public title: string;
  public src: string;

  constructor() {
    super();
    this.id = null;
    this.mime = '';
    this.type = '';
    this.title = '';
    this.src = '';
  }

  public static make(obj: object): FileModel {
    if (!obj || Object.keys(obj).length === 0) {
      return null; // Object is null or empty
    }
    const model = new FileModel();
    return { ...model, ...obj };
  }
}
