import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { GenericPage } from '../../../../core/constants/generic-page';
import { select, Store } from '@ngrx/store';
import { selectGenericPopoverData } from '../../../../store/generic-popover/generic-popover.selectors';
import { LinkEditModel } from '../../../../core/models/link-edit.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ENTER } from '@angular/cdk/keycodes';
import { LinkModel } from '../../../../core/models/link.model';
import { OverlayPopoverRef } from '../../../../shared/components/overlay-popover/overlay-popover-ref';
import { IconType } from '../../../../core/constants/icon-type';
import { urlValidationPattern } from '../../../../core/constants/validation-patterns';

@Component({
  selector: 'app-link-edit',
  templateUrl: './link-edit.component.html',
  styleUrls: ['./link-edit.component.scss'],
})
export class LinkEditComponent implements OnInit, OnDestroy {
  @ViewChild('urlInput', { static: true }) urlRef: ElementRef;

  page: GenericPage;
  links: LinkModel[];
  selectedLink: LinkModel;
  form: UntypedFormGroup;
  isSubmitted: boolean;

  GenericPage = GenericPage;

  get url() {
    return this.form.get('url');
  }

  get displayName() {
    return this.form.get('displayName');
  }

  subscription = new Subscription();

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store,
    private popoverRef: OverlayPopoverRef,
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      url: ['', [Validators.required, Validators.pattern(urlValidationPattern)]],
      displayName: [''],
    });
    this.urlRef.nativeElement.focus();

    this.subscription.add(
      this.store.pipe(select(selectGenericPopoverData)).subscribe((data: LinkEditModel) => {
        this.page = data.page;
        this.links = data.links;
        if (data.page === GenericPage.edit) {
          this.selectedLink = data.selectedLink;
          this.url.setValue(this.selectedLink.value);
          this.displayName.setValue(this.selectedLink.label);
        }
      }),
    );
  }

  submit() {
    this.isSubmitted = true;
    if (this.form.valid) {
      const newLink = {
        value: this.url.value,
        label: this.displayName.value,
        icon: IconType['not-available'],
      };
      if (this.page === GenericPage.create) {
        const links = [...this.links, newLink];
        this.popoverRef.updateAndClose(links);
      } else if (this.page === GenericPage.edit) {
        let hasFound: boolean; // used to only change the first found link.
        const links = [
          ...this.links.map(link => {
            if (
              link.value === this.selectedLink.value &&
              link.label === this.selectedLink.label &&
              !hasFound
            ) {
              hasFound = true;
              return newLink;
            } else {
              return link;
            }
          }),
        ];
        this.popoverRef.updateAndClose(links);
      }
    }
  }

  close() {
    this.popoverRef.close();
  }

  onDelete() {
    const links = [
      ...this.links.filter(link => {
        return link.value !== this.selectedLink.value;
      }),
    ];
    this.popoverRef.updateAndClose(links);
  }

  onButtonKeyDown(event) {
    if (event.keyCode === ENTER) {
      event.preventDefault();
      this.submit();
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
