import { AppState } from '../index';
import { createSelector } from '@ngrx/store';
import { CardLayoutType } from '../../core/constants/card-layout-type';

export const selectWorkspacesState = (state: AppState) => state.workspaces;

export const selectWorkspacesBySlug = createSelector(selectWorkspacesState, state => state.bySlug);

export const selectWorkspacesOwnedSlugs = createSelector(
  selectWorkspacesState,
  state => state.ownedSlugs,
);

export const selectWorkspacesSharedSlugs = createSelector(
  selectWorkspacesState,
  state => state.sharedSlugs,
);

export const selectWorkspacesCurrentSlug = createSelector(
  selectWorkspacesState,
  state => state.currentSlug,
);

export const selectWorkspacesOwned = createSelector(
  selectWorkspacesBySlug,
  selectWorkspacesOwnedSlugs,
  (bySlug, ownedSlugs) => ownedSlugs.map(slug => bySlug[slug]),
);

export const selectWorkspacesShared = createSelector(
  selectWorkspacesBySlug,
  selectWorkspacesSharedSlugs,
  (bySlug, sharedSlugs) => sharedSlugs.map(slug => bySlug[slug]),
);

export const selectAllWorkspaces = createSelector(
  selectWorkspacesBySlug,
  selectWorkspacesOwnedSlugs,
  selectWorkspacesSharedSlugs,
  (bySlug, ownedSlugs, sharedSlugs) => {
    return (
      [...ownedSlugs, ...sharedSlugs]
        .map(slug => bySlug[slug])

        // todo: This is wrong. Need to be by User Edited Date
        .sort((a, b) => {
          if (a.editedDate > b.editedDate) {
            return -1;
          } else if (a.editedDate < b.editedDate) {
            return 1;
          }
          return 0;
        })
    );
  },
);

export const selectCurrentWorkspace = createSelector(
  selectWorkspacesBySlug,
  selectWorkspacesCurrentSlug,
  (bySlug, slug) => bySlug[slug],
);

export const selectRecentWorkspaceSlugs = createSelector(selectWorkspacesOwnedSlugs, slugs =>
  slugs.slice(0, 10),
);

export const selectRecentWorkspaces = createSelector(
  selectRecentWorkspaceSlugs,
  selectWorkspacesBySlug,
  selectWorkspacesCurrentSlug,
  (slugs, bySlug, currentSlug) =>
    // current workspace may not be recorded as the latest opened workspace yet.
    [currentSlug, ...slugs.filter(slug => slug !== currentSlug)]
      .map(slug => {
        return bySlug[slug];
      })
      .filter(workspace => !!workspace),
);

export const selectCurrentWorkspaceCardLayout = createSelector(
  selectCurrentWorkspace,
  workspace => {
    // return workspace?.metaData?.assignmentCardLayout
    //   ? workspace?.metaData?.assignmentCardLayout
    //   : CardLayoutType.twoColumns;
    return CardLayoutType.oneColumn; // only use oneColumn for the new Assignment Card style
  },
);

export const selectAssignmentCardFieldTextTruncationLimit = createSelector(
  selectCurrentWorkspaceCardLayout,
  layout => {
    if (layout === CardLayoutType.oneColumn) {
      return 40;
    } else {
      return 20;
    }
  },
);
